import { styled } from '@abyss/web/tools/styled';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { handleLinkAndModalTrack } from '../../../../../common/Utils/adobeTrackUtils';
import {
  CompareProvider,
  SelectedProviderList,
} from '../../../../../models/Provider';
import { DrawerMenu } from '../CompareDrawer/Components/Buttons/DrawerMenu';
import { PrintView } from '../Print/PrintView';
import { ConfirmationModalShare } from './ConfirmationModal/ConfirmationModalShare';
import { ShareContainerMobile } from './ShareDrawer.styled';
import { adobeShareDrawerLinkLocation } from './utils';

const ContainerHeading = styled(Heading, {
  '&.abyss-heading-root': {
    paddingLeft: '$md',
    fontSize: '20.25px !important',
    lineHeight: '24px !important',
    fontWeight: '700 !important',
    fontFamily: 'AbyssUHCSans !important',
  },
});

type Props = {
  selected: number;
  openBoxContents: boolean;
  setOpenShare: (a: boolean) => void;
  setOpenBoxContents: (a: boolean) => void;
  setSelectedCheckbox: (a: { checked: {} }) => void;
  setSelectedItems: (a: CompareProvider[]) => void;
  total: number;
  selectedProviderList: SelectedProviderList;
};

export const ShareContainerSection = ({
  setOpenShare,
  selected,
  total,
  openBoxContents,
  setOpenBoxContents,
  setSelectedCheckbox,
  setSelectedItems,
  selectedProviderList,
}: Props) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  return (
    <ShareContainerMobile>
      <Box
        css={{
          'abyss-box-root': {
            backgroundColor: 'transparent',
            padding: '0px',
          },
        }}
      >
        <Flex
          alignItems="left"
          css={{
            'abyss-flex-root': {
              marginTop: '17px',
            },
          }}
          justify="space-between"
        >
          <Layout.Group>
            <ContainerHeading color="$gray8" offset={2}>
              {selected} {t('of')} {total} {t('SELECTED')}
            </ContainerHeading>
            <IconMaterial
              data-auto-testid="icon-button-to-collapse-providers"
              data-testid="icon-button-to-collapse-providers"
              icon={
                openBoxContents ? 'keyboard_arrow_down' : 'keyboard_arrow_up'
              }
              onClick={() => {
                adobeLinkTrackEvent({
                  name: 'share details',
                  location: `modal:${adobeShareDrawerLinkLocation}`,
                  type: `${
                    openBoxContents ? 'accordion close' : 'accordion open'
                  }`,
                });
                setOpenBoxContents(!openBoxContents);
              }}
              size="$md"
            />
          </Layout.Group>
          <div>
            <PrintView selectedProviderList={selectedProviderList} />
            <DrawerMenu
              isDisplayShare={false}
              isMenuOpen={isMenuOpen}
              locationForAnalytics="modal:more actions"
              setIsMenuOpen={setIsMenuOpen}
            />
            <Button
              css={{
                'abyss-button-root': {
                  paddingLeft: 0,
                  paddingRight: 0,
                },
              }}
              variant="tertiary"
            >
              <IconMaterial
                css={{
                  marginRight: '4px',
                }}
                data-auto-testid="icon-button-to-close"
                data-testid="icon-button-to-close"
                icon="close"
                onClick={() => {
                  handleLinkAndModalTrack(
                    'close',
                    `modal:${adobeShareDrawerLinkLocation}`,
                    'close confirmation'
                  );
                  setIsOpen(true);
                }}
                size="$md"
              />
            </Button>
          </div>
        </Flex>
      </Box>
      <ConfirmationModalShare
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setOpenBoxContents={setOpenBoxContents}
        setOpenShare={setOpenShare}
        setSelectedCheckbox={setSelectedCheckbox}
        setSelectedItems={setSelectedItems}
      />
    </ShareContainerMobile>
  );
};
