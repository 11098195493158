import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import React from 'react';

import { DirectoryLink } from '../DirectoryLink/DirectoryLink';
import { searchProviderName } from '../helper';

type props = {
  physician: {
    providerId?: string | undefined;
    providerName?: string;
    specialities: string;
  };
  linkOnClick: (a: any) => void;
  search: string;
  isTablet: boolean;
};

export const DirectoryListMobile = ({
  physician,
  linkOnClick,
  search,
  isTablet,
}: props) => {
  if (searchProviderName(physician, search)) {
    if (isTablet) {
      return (
        <Grid style={{ flexDirection: 'column', margin: 0 }}>
          <Grid.Col
            css={{
              'abyss-grid-col': {
                padding: '0',
                width: '100%',
              },
            }}
          >
            <DirectoryLink
              linkOnClick={linkOnClick}
              physician={physician}
              search={search}
            />
          </Grid.Col>
        </Grid>
      );
    }
    return (
      <Layout.Stack css={{ div: { margin: 0 } }} grow>
        <DirectoryLink
          linkOnClick={linkOnClick}
          physician={physician}
          search={search}
        />
      </Layout.Stack>
    );
  }
  return null;
};
