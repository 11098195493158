import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';

export const StyledConfirmPCPHeading = styled(Heading, {
  margin: '16px 0 8px 0',
});

export const StyledConfirmPCPContainer = styled(Layout.Stack, {
  marginBottom: '-8px !important',
});

export const StyledNewPCPCard = styled('div', {
  backgroundColor: 'white',
  borderRadius: '12px',
  border: '1px solid $primary1',
  boxShadow: '0px 3px 6px 0px rgba(0, 0, 0, 0.15)',
  margin: '0 12px 8px 0',
  padding: '16px 24px 24px 24px',
  width: '407px',
  '@screen < $sm': {
    margin: '0 0 24px 0',
    width: '100%',
  },
});

export const StyledPreviousPCPCard = styled('div', {
  backgroundColor: '$gray1',
  borderRadius: '12px',
  margin: '0 0 8px 12px',
  padding: '16px 24px 24px 24px',
  width: '407px',
  '@screen < $sm': {
    margin: '0 0 0 0',
    width: '100%',
  },
});

export const StyledConfirmPCPInfoHeading = styled(Text, {
  '&.abyss-text-root': {
    lineHeight: '24px',
    display: 'inline-block',
  },
});

export const StyledConfirmPCPInfo = styled('div', {
  backgroundColor: 'white',
  borderRadius: '12px',
  border: '1px solid $gray3',
  boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.05)',
  padding: '12px',
});

export const StyledConfirmButton = styled(Button, {
  '&.abyss-button-content-container': {
    '>span': {
      '>span': {
        paddingLeft: '4px',
      },
    },
  },
});

export const StyledConfirmPCPNameCardText = styled('div', {
  width: '267px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

export const StyledConfirmPCPAddressCardText = styled('div', {
  width: '331px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});
