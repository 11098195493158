import { Button } from '@abyss/web/ui/Button';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { BtnWrapper } from './MapViewUpdateLocationButton.style';

type Props = {
  isSameLocationSelected: boolean;
  handleSaveLocation: () => void;
};

export const MapViewUpdateLocationButton = ({
  isSameLocationSelected,
  handleSaveLocation,
}: Props) => {
  const { t } = useTranslation();
  return (
    <BtnWrapper>
      <Button
        css={{
          'abyss-button-content-container': {
            color: !isSameLocationSelected ? '' : '#6E7072',
            lineHeight: '20px',
          },
          'abyss-button-root': {
            height: '42px',
            background: !isSameLocationSelected ? '' : '#F2F2F2 !important',
            fontSize: '16px',
            fontWeight: '$semibold',
            borderWidth: '2px',
            borderColor: !isSameLocationSelected ? '' : '#F2F2F2 !important',
            '.abyss-insert-element-after': {
              paddingLeft: 0,
            },
          },
        }}
        data-auto-testid="map-view-save-location"
        data-testid="map-view-save-location"
        isDisabled={isSameLocationSelected}
        onClick={handleSaveLocation}
      >
        {t('UPDATE_LOCATION')}
      </Button>
    </BtnWrapper>
  );
};
