import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useCoverageType } from '../../../../hooks/useCoverageType';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import {
  capitalizeAllFirstCharacters,
  capitalizeFirstLetters,
} from '../../../../utils/providerNames.utils';
import { ReverseCoverageTypesCodes } from '../../../Constants';
import { mobileOnly, phoneOnly } from '../../../ConstantsStyles';
import { ContentWrapper } from '../../../ContentWrapper';
import { ContainerForAllSectionsStyled } from '../../ProviderDetailTabs.styled';

const textContainer = (phoneScreen) => ({
  maxWidth: '839px',
  fontWeight: '$medium',
  fontSize: '$lg',
  lineHeight: '24px',
  color: '#4B4D4F ',
  ...(phoneScreen && {
    fontSize: '$md',
    fontWeight: '500',
    lineHeight: '20px',
  }),
});

const subTextStyle = (phoneScreen) => ({
  height: '20px',
  fontWeight: '$medium',
  fontSize: '$md',
  lineHeight: '20px',
  color: '#4B4D4F',
  ...(phoneScreen && {
    fontSize: '14.22px',
  }),
});

const Dot = styled('div', {
  width: '4px',
  height: '4px',
  background: '#D9D9D9',
  margin: '$sm $xs $xs $xs',
  borderRadius: '50%',
  color: '#4B4D4F',
});

type Props = {
  heading: string;
  content?: string;
  gender: string;
  language: string[] | null;
  ethnicity?: string;
};
export const BiographySection = ({
  heading,
  content,
  gender,
  language,
  ethnicity,
}: Props) => {
  const { t } = useTranslation();
  const coverageType = useCoverageType();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = useMediaQuery(phoneOnly) || isWidget;
  const mobileScreen = useMediaQuery(mobileOnly) || isWidget;

  return (
    <ContainerForAllSectionsStyled css={{ marginBottom: '8px' }}>
      <ContentWrapper isWidget={isWidget}>
        <Layout.Stack
          alignItems="left"
          css={{ gap: '4px', lineHeight: phoneScreen ? '20px' : '24px' }}
          data-auto-testid="about-tab-biography-section-container"
          data-testid="about-tab-biography-section-container"
        >
          <Heading
            css={
              mobileScreen
                ? {
                    '&.abyss-heading-root': {
                      fontSize: '20.25px',
                      lineHeight: '24px',
                    },
                  }
                : {}
            }
            data-auto-testid="biography-heading"
            data-testid="biography-heading"
            display="h4"
            offset={1}
          >
            {t(heading)}
          </Heading>
          <Text
            css={textContainer(phoneScreen)}
            dangerouslySetInnerHTML={{
              __html: content || t('BIOGRAPHY_SECTION.BIOGRAPHY_NOT_AVAILABLE'),
            }}
            data-auto-testid="biography-text"
            data-testid="biography-text"
          />
          <Flex css={{ flexWrap: 'inherit' }} justify="space-evenly">
            <Text
              css={subTextStyle(phoneScreen)}
              data-auto-testid="biography-gender"
              data-testid="biography-gender"
            >
              {gender.toLowerCase() === 'male'
                ? capitalizeFirstLetters(t('BIOGRAPHY_SECTION.GENDER.MALE'))
                : capitalizeFirstLetters(t('BIOGRAPHY_SECTION.GENDER.FEMALE'))}
            </Text>
            <Dot />
            {language && (
              <Text
                css={subTextStyle(phoneScreen)}
                data-auto-testid="biography-language"
                data-testid="biography-language"
              >
                {capitalizeAllFirstCharacters(language?.join(', '))}
              </Text>
            )}
          </Flex>
          {coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] &&
          ethnicity ? (
            <Text
              css={subTextStyle(phoneScreen)}
              data-auto-testid="ethnicity-text"
              data-testid="ethnicity-text"
            >
              {capitalizeFirstLetters(ethnicity)}
            </Text>
          ) : null}
        </Layout.Stack>
      </ContentWrapper>
    </ContainerForAllSectionsStyled>
  );
};
