import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import React, { useContext, useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { adobeStandardSearchEvent } from '../../../../common/AdobeTagging/adobeStandardSearchEvent';
import { CardWrapperDetailsPage } from '../../../../common/CardWrapper';
import {
  AdobeEventMethods,
  Constants,
  ProviderTypes,
  SHOULD_GET_HG_DATA,
} from '../../../../common/Constants';
import { ConstantsRoutes } from '../../../../common/ConstantsRoutes';
import { phoneOnly } from '../../../../common/ConstantsStyles';
import { DetailsPageHeader } from '../../../../common/DetailsPageHeader/DetailsPageHeader';
import { LoadingSpinnerComp } from '../../../../common/Loader';
import { ProviderDetailTabs } from '../../../../common/ProviderDetailTabs/ProviderDetailTabs';
import { getGeoLocationFromStorage } from '../../../../common/PSXHeader/SearchBar/utils';
import { convertObjectToUrlParams } from '../../../../common/Utils';
import {
  getSearchLocation,
  internalConvertProviderTypeToAdobeType,
} from '../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { useInitializeStore } from '../../../../hooks/useInitializeStore';
import { useProviderDetails } from '../../../../hooks/useProviderDetails';
import { useProviderResults } from '../../../../hooks/useProviderSearch';
import { ProviderType } from '../../../../models/ProviderDetails';
import { useAnalyticsStore } from '../../../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../../../store/useDetailsStore';
import { DetailsStore } from '../../../../store/useDetailsStore/detailsStore';
import { usePCPStore } from '../../../../store/usePCPStore';
import { PCPSearchStore } from '../../../../store/usePCPStore/pcpStore';
import { useTypeaheadStore } from '../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../store/useTypeaheadStore/typeaheadStore';
import {
  getNetworkStatus,
  getSelectedLocationIndex,
  getNormalizedProviderId,
  isDoLookupByPcpId,
} from '../../../../utils/providerDetails.utils';
import { PortalContext } from '../../../../context/PortalContext';
import { getNameAttrs } from '../../../../utils/providerNames.utils';
import { getCurrentMember } from '../../../../utils/user.utils';

const isProviderDetailsEmpty = (
  providerDetails: any,
  initiateViewEstimate?: boolean
) => providerDetails?.length === 0 && initiateViewEstimate;

export const ProviderDetailsView = () => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const { navigate } = useRouter();

  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { coverageType, chipValue } = chipStore;

  const { searchTerm, searchFromTypeAhead } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      searchTerm: state.typeaheadSearchStore.searchTerm,
      searchFromTypeAhead: state.typeaheadSearchStore.searchFromTypeAhead,
    }))
  );

  const { linkName } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      linkName: state.analyticsState.linkName,
    }))
  );

  const {
    providerId: storeProviderId,
    locationId,
    source,
  } = useInitializeStore(ConstantsRoutes.PROVIDER_DETAILS.key);

  const {
    avatar = 'heart',
    showDirection,
    isTieredSnackCard,
    isTieredDataCard,
    isOHBS,
    providerId,
  } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      avatar: state.detailsState.avatar,
      showDirection: state.detailsState.showDirection,
      isTieredSnackCard: state.detailsState.isTieredSnackCard,
      isTieredDataCard: state.detailsState.isTieredDataCard,
      isOHBS: state.detailsState.isOHBS,
      providerId: state.detailsState.providerId,
    }))
  );

  const { dependentSeqNbr } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
    }))
  );

  const [navigateToDirections, setNavigateToDirections] = useState(
    Boolean(showDirection)
  );
  const [isMapView, setIsMapView] = useState<boolean>(false);
  const [hasQueried, setHasQueried] = useState<boolean>(false);

  const storedLocation = getGeoLocationFromStorage();

  const shouldGetHGData = SHOULD_GET_HG_DATA;

  const currentMember = getCurrentMember();
  const lob = currentMember.lineOfBusiness;
  const normalizedProviderId = getNormalizedProviderId(storeProviderId, lob);

  const { data, isLoading } = useProviderDetails({
    providerType: ProviderType.PRACTITIONER,
    providerId: normalizedProviderId,
    isOHBS,
    locationId,
    shouldGetHGData,
  });

  const typeaheadState = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      initiateViewEstimate: state.typeaheadSearchStore.initiateViewEstimate,
    }))
  );

  const initiateViewEstimate = typeaheadState?.initiateViewEstimate;

  const { portalData } = useContext(PortalContext);

  const providerDetails = data?.providerDetails?.providerDetails || {};
  const providerLocations = providerDetails?.providerLocations || [];
  const nameAttrs = getNameAttrs(providerDetails);
  const [selectedId] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    ''
  );

  const [, setDirectionsTriggeredFrom] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.DIRECTIONS_TRIGGERED_FROM,
    ''
  );

  useEffect(() => {
    if (isProviderDetailsEmpty(providerDetails, initiateViewEstimate)) {
      navigate(`${portalData?.portalConfig?.homePage}`);
    }
    if (providerDetails?.providerName && searchFromTypeAhead) {
      adobeStandardSearchEvent({
        linkName,
        term: searchTerm,
        numberOfResults: 1,
        method: AdobeEventMethods.Predictive,
        customAttributesBlock: {
          searchLocation: getSearchLocation(storedLocation),
          providerType: internalConvertProviderTypeToAdobeType(
            ProviderTypes.PRACTITIONER
          ),
        },
      });
    }
  }, [providerDetails?.providerName, searchFromTypeAhead]);

  useEffect(() => {
    if (showDirection) {
      setDirectionsTriggeredFrom('');
      setNavigateToDirections(true);
    }
  }, [showDirection]);

  // For lookup by PCP ID, if needed
  const [providerByPcpId, getProviderByPcpId] = useProviderResults({});
  const providerIdByPcpId = providerByPcpId?.data?.providers?.[0]?.providerId;

  useEffect(() => {
    const doLookupByPcpId = isDoLookupByPcpId(
      hasQueried,
      isLoading,
      providerDetails?.providerId,
      source
    );
    if (doLookupByPcpId) {
      getProviderByPcpId({
        providerType: ProviderTypes.PRACTITIONER,
        pcpID: storeProviderId,
      });
    } else if (isLoading) {
      setHasQueried(true);
    }
  }, [isLoading]);

  // Redirect to proper providerId if lookup by PCP ID succeeded
  useEffect(() => {
    if (!providerDetails?.providerId && providerIdByPcpId) {
      const urlParams = convertObjectToUrlParams(chipStore, {
        providerId: providerIdByPcpId,
        locationId,
      });
      navigate(`${ConstantsRoutes.PROVIDER_DETAILS.path}${urlParams}`);
    }
  }, [providerIdByPcpId]);

  const selectedLocationIndex = getSelectedLocationIndex(
    providerLocations,
    selectedId
  );
  const selectedLocation = providerLocations?.[selectedLocationIndex];
  const networkStatus = getNetworkStatus(selectedLocation?.network?.status);

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingSpinnerComp isLoading={isLoading} />
      ) : (
        <React.Fragment>
          <CardWrapperDetailsPage backgroundColor="$bgHeaderColor">
            {!(isMapView && mobileScreen) ? (
              <DetailsPageHeader
                avatar={avatar}
                coverageType={coverageType}
                dependentSeqNbr={dependentSeqNbr}
                imageLocation={providerDetails?.imageLocation}
                inNetwork={networkStatus}
                nameAttrs={nameAttrs}
                npi={providerDetails?.npi}
                practiceId={selectedLocation?.docasapPracticeId}
                primarySpeciality={providerDetails?.specialties}
                professionalDesignation={providerDetails?.primaryDegrees?.[0]}
                providerId={providerId || ''}
                providerLocations={providerLocations}
                providerName={providerDetails?.providerName}
                providerType={ProviderType.PRACTITIONER}
                rating={providerDetails?.healthGradeRating}
                setNavigateToDirections={setNavigateToDirections}
                surveyCount={providerDetails?.surveyCount}
                virtualCareOffered={selectedLocation?.virtualCareOffered}
              />
            ) : null}
          </CardWrapperDetailsPage>
          <CardWrapperDetailsPage>
            <ProviderDetailTabs
              chipValue={chipValue}
              coverageType={coverageType}
              isMapView={isMapView}
              isTiered={isTieredDataCard || isTieredSnackCard}
              locationKey={selectedLocation?.locationKey}
              navigateToDirections={navigateToDirections}
              providerDetails={providerDetails}
              setIsMapView={setIsMapView}
              setNavigateToDirections={setNavigateToDirections}
            />
          </CardWrapperDetailsPage>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
