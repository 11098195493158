import { IconMaterial } from '@abyss/web/ui/IconMaterial';

type Props = {
  param: number;
  sectionTypeDerived: string;
  ratingIndex: number;
  providerId: string;
  healthGradeRating: string;
  'data-testid'?: string;
};

export const StarRating = ({ param, healthGradeRating }: Props) => {
  const ratingAsInt = parseInt(healthGradeRating, 10);
  return (
    <span aria-hidden="true" key={param}>
      <IconMaterial
        css={{
          'abyss-icon': {
            position: 'relative',
            top: param < ratingAsInt ? '0px' : '1px',
            fill: param < ratingAsInt ? '$secondary2' : '$black',
            stroke: param < ratingAsInt ? '$starBorder' : '',
          },
        }}
        icon={param < ratingAsInt ? 'star_rate' : 'star_border'}
        size={param < ratingAsInt ? '16px' : '16px'}
      />
    </span>
  );
};
