import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';

export const groupHeading = {
  'abyss-heading-root': {
    fontWeight: '700 !important',
    fontSize: '24px !important',
    lineHeight: '28.8px !important',
    fontFamily: 'AbyssEnterpriseSansVF,Arial Bold, sans-serif !important',
    color: '#002677 !important',
  },
};
export const mainGroupHeading = {
  'abyss-heading-root': {
    fontWeight: '700 !important',
    fontSize: '29px !important',
    lineHeight: '34.8px !important',
    fontFamily: 'AbyssEnterpriseSansVF,Arial Bold, sans-serif !important',
    color: '#002677 !important',
  },
};

export const subGroupHeading = {
  'abyss-heading-root': {
    fontWeight: '400 !important',
    fontSize: '20px !important',
    lineHeight: '24px !important',
    fontFamily: 'AbyssEnterpriseSansVF,Arial Bold, sans-serif !important',
    color: '#002677 !important',
  },
};

export const AccordionButton = styled(Button, {
  border: 'none !important',
  fontSize: '14px !important',
  margin: '0 5px 0 5px',
  '.abyss-button-content-container': {
    color: '#196ECF !important',
  },
});
