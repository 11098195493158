import { Tooltip } from '@abyss/web/ui/Tooltip';

import { Address as ProviderAddress } from '../../models/ProviderDetails';
import {
  AddressText,
  AddressWrapper,
  Dot,
  addressTextToolTipStyles,
} from './DataCard.style';

type Props = {
  address?: ProviderAddress;
  id?: string;
  sectionType?: string;
  isCTADisplayLogic?: boolean;
};

export const Address = ({
  address,
  id,
  sectionType,
  isCTADisplayLogic = false,
}: Props) => {
  const { line } = address || {};
  const addressLine = line?.join(', ');
  if (!line) return null;
  return (
    <AddressWrapper>
      {!isCTADisplayLogic && <Dot />}
      <Tooltip
        content={addressLine}
        css={addressTextToolTipStyles}
        position="top"
        positionOffset={8}
      >
        <AddressText
          color={isCTADisplayLogic ? '$interactive1' : '$gray7'}
          css={{ marginTop: '0px !important' }}
          data-auto-testid={`data-card-address-${sectionType}-${id}`}
          data-testid={`data-card-address-${sectionType}-${id}`}
          fontWeight={isCTADisplayLogic ? '$bold' : '$small'}
        >
          {addressLine}
        </AddressText>
      </Tooltip>
      {isCTADisplayLogic && <Dot />}
    </AddressWrapper>
  );
};
