import isEmpty from 'lodash/isEmpty';

import { EducationInformation } from '../../../../../../models/Provider';
import { NULL_ATTRIBUTE_STATE } from './compareDrawerConstants';

export const getSchoolsList = (educationInfo: EducationInformation) => {
  if (isEmpty(educationInfo) || isEmpty(educationInfo[0]?.schoolName)) {
    return NULL_ATTRIBUTE_STATE.EDUCATION_SCHOOLS;
  }
  const schoolList: String[] = [];
  educationInfo?.forEach((school) => {
    schoolList.push(school.schoolName);
  });

  let schoolSplit;
  const schools: string[] = [];
  schoolList?.forEach((school) => {
    schoolSplit = school.split(';');
    schools.push(schoolSplit[0]);
  });

  return schools;
};

export const getDegreesList = (educationInfo: EducationInformation) => {
  if (
    isEmpty(educationInfo) ||
    isEmpty(educationInfo[0]?.degrees[0]?.degreeCode)
  ) {
    return NULL_ATTRIBUTE_STATE.EDUCATION_DEGREES;
  }

  const degreeList: any[] = [];
  educationInfo?.forEach((school) => {
    school.degrees.forEach((degree) => {
      if (degree.isPrimary) {
        degreeList.push(degree.degreeCode);
      }
    });
  });

  return degreeList;
};

export const getEducation = (
  educationInfo: EducationInformation
): {
  degreeCode: string;
  schoolName: string;
} => {
  let degreeCode = '';
  let schoolName = '';
  let totalSchool = 0;

  if (!isEmpty(educationInfo)) {
    // find primary degree information
    const primaryDegreeInfo = educationInfo.find((info) =>
      info.degrees.some((degree) => degree.isPrimary)
    );
    const nonEmptySchools = educationInfo.filter(
      (info) => info.schoolName !== ''
    );
    totalSchool = nonEmptySchools.length;

    if (primaryDegreeInfo) {
      // Assign primary degree code and school name
      degreeCode =
        primaryDegreeInfo.degrees.find((degree) => degree.isPrimary)
          ?.degreeCode || '';
      schoolName = primaryDegreeInfo.schoolName.slice(
        0,
        primaryDegreeInfo.schoolName.indexOf(';')
      );
    } else {
      // If no primary degree is found then show first degree's code and school name
      degreeCode = educationInfo[0].degrees[0].degreeCode;
      schoolName = educationInfo[0].schoolName.includes(';')
        ? educationInfo[0].schoolName.slice(
            0,
            educationInfo[0].schoolName.indexOf(';')
          )
        : educationInfo[0].schoolName;
    }
  }
  if (schoolName && totalSchool > 1) {
    schoolName += ` (+${totalSchool - 1})`;
  }

  if (isEmpty(schoolName)) {
    schoolName = NULL_ATTRIBUTE_STATE.EDUCATION_SCHOOLS;
  }

  if (isEmpty(degreeCode)) {
    degreeCode = NULL_ATTRIBUTE_STATE.EDUCATION_DEGREES;
  }

  return { degreeCode, schoolName };
};
