import { event } from '@abyss/web/tools/event';
import { adobeAnalytics } from '../listeners/adobeAnalytics';
import { adobeTags } from './tags/adobe';

const maskAdobeClickTracking = () => {
  event.waitFor(
    () => window.s && window.s.ActivityMap,
    () => {
      window.s.ActivityMap.link = () => null;
    }
  );
};

export const registerEvents = (metaData) => {
  maskAdobeClickTracking();
  let isLocalhost = false;

  if (typeof window !== 'undefined') {
    isLocalhost = window.location.hostname === 'localhost';
  }

  event.listener(
    adobeAnalytics({
      id: 'adobe-analytics-psx',
      enabled: !isLocalhost,
      logging: isLocalhost,
      events: adobeTags,
      metadata: metaData,
    })
  );
};