import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Label } from '@abyss/web/ui/Label';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { Text } from '@abyss/web/ui/Text';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProviderType } from '../../models/ProviderDetails';
import {
  DayLabelStyled,
  LabelStyled,
  TextInputContainer,
  TextInputStyled,
} from './CorrectInformation.styled';

export type Props = {
  providerType: ProviderType;
  providerName: string;
  inNetwork: string;
  selectedLocationValue: any;
};

const getLabel = (item) =>
  `${item.address.line[0]},${item.address.city}, ${item.address.state} ${item.address.postalCode}`;

export const CorrectInformation = ({
  providerType,
  providerName,
  inNetwork,
  selectedLocationValue,
}: Props) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    providerName,
    Address: getLabel(selectedLocationValue),
    PhoneNumber: selectedLocationValue?.phones?.phone[0],
    Website: selectedLocationValue.website,
  });

  useEffect(() => {
    setFormData({
      ...formData,
      Address: getLabel(selectedLocationValue),
      PhoneNumber: selectedLocationValue?.phones?.phone[0],
      Website: selectedLocationValue.website,
    });
  }, [selectedLocationValue]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const convertTime = (time) => {
    if (time) {
      const [hour, minute] = time.split(':');
      if (hour === '00') {
        return `12:${minute} am`;
      }
      if (hour < '12') {
        return `${parseInt(hour, 10)}:${minute} am`;
      }
      if (hour === '12') {
        return `12:${minute} pm`;
      }
      return hour !== '' && minute !== ''
        ? `${parseInt(hour, 10) - 12}:${minute} pm`
        : '';
    }
    return '';
  };
  return (
    <Flex
      alignContent="flex-start"
      css={{
        'abyss-flex-root': {
          gap: '20px',
          marginTop: '$md',
        },
      }}
      direction="column"
    >
      <Text
        color="$primary1"
        css={{
          'abyss-text-root': {
            fontWeight: '$bold',
          },
        }}
        fontWeight={24}
        size="$lg"
      >
        {providerType === ProviderType.PRACTITIONER
          ? t('SUGGEST_EDIT.PROVIDERS_INFO')
          : t('SUGGEST_EDIT.FACILITY_INFO')}
      </Text>
      <TextInputContainer>
        <LabelStyled>
          {providerType === ProviderType.PRACTITIONER
            ? t('SUGGEST_EDIT.PROVIDER_NAME')
            : t('SUGGEST_EDIT.FACILITY_NAME')}
        </LabelStyled>
        <TextInputStyled
          data-auto-testid="provider-facility-name"
          data-testid="provider-facility-name"
          label={
            providerType === ProviderType.PRACTITIONER
              ? t('SUGGEST_EDIT.PROVIDER_NAME')
              : t('SUGGEST_EDIT.FACILITY_NAME')
          }
          name="providerName"
          onChange={handleChange}
          value={formData.providerName}
        />
      </TextInputContainer>

      {providerType === ProviderType.PRACTITIONER && (
        <TextInputContainer>
          <LabelStyled>{t('SUGGEST_EDIT.SPECIALTY')}</LabelStyled>
          <TextInputStyled
            data-auto-testid="provider-facility-speciality"
            data-testid="provider-facility-speciality"
            defaultValue={providerName}
            label={t('SUGGEST_EDIT.SPECIALTY')}
            name="Specality"
          />
        </TextInputContainer>
      )}
      {providerType === ProviderType.PRACTITIONER && (
        <RadioGroup
          data-auto-testid="provider-facility-network"
          data-testid="provider-facility-network"
          display="row"
          label={t('SUGGEST_EDIT.INNETWORK_STATUS')}
          model="InNetwork"
          size="sm"
          value={inNetwork}
        >
          <RadioGroup.Radio label="In-Network" value="In-Network" />
          <RadioGroup.Radio label="Out-of-network" value="Out-of-network" />
        </RadioGroup>
      )}
      {providerType === ProviderType.PRACTITIONER && (
        <TextInputContainer>
          <Label size="14.22">{t('SUGGEST_EDIT.AFFILIATION')}</Label>
          <TextInputStyled
            data-auto-testid="provider-facility-affliation"
            data-testid="provider-facility-affliation"
            model="Affiliation"
            value={providerName}
          />
        </TextInputContainer>
      )}
      <TextInputContainer>
        <Text
          color="$primary1"
          css={{
            'abyss-text-root': {
              fontWeight: '$bold',
              marginBottom: '12px',
              display: 'block',
            },
          }}
          fontWeight={24}
          size="$lg"
        >
          {t('SUGGEST_EDIT.LOCATION_INFO')}
        </Text>
        <LabelStyled>{t('SUGGEST_EDIT.ADDRESS')}</LabelStyled>
        <TextInputStyled
          data-auto-testid="provider-facility-address"
          data-testid="provider-facility-address"
          name="Address"
          onChange={handleChange}
          value={formData.Address}
        />
      </TextInputContainer>

      <TextInputContainer>
        <LabelStyled>{t('SUGGEST_EDIT.HOURS')}</LabelStyled>
        {selectedLocationValue?.hoursOfOperations?.map((days: any) => (
          <Grid>
            <Grid.Col
              css={{
                'abyss-grid-col': {
                  display: 'flex',
                  alignItems: 'center',
                },
              }}
              span={{ xs: 3, md: 3, lg: 3, xl: 3 }}
            >
              <DayLabelStyled fontWeight="$medium" size="14.22px">
                {days.day[0].toUpperCase() + days.day.substring(1)}
              </DayLabelStyled>
            </Grid.Col>
            <Grid.Col span={{ xs: 9, md: 9, lg: 9 }}>
              <TextInputStyled
                data-auto-testid="provider-facility-phone-number"
                data-testid="provider-facility-phone-number"
                name="hours"
                value={`${convertTime(days.openingTime)} - ${convertTime(
                  days.closingTime
                )}`}
              />
            </Grid.Col>
          </Grid>
        ))}
      </TextInputContainer>

      <TextInputContainer>
        <LabelStyled>{t('SUGGEST_EDIT.PHONE_NO')}</LabelStyled>
        <TextInputStyled
          data-auto-testid="provider-facility-phone-number"
          data-testid="provider-facility-phone-number"
          name="PhoneNumber"
          onChange={handleChange}
          value={formData?.PhoneNumber}
        />
      </TextInputContainer>
      <TextInputContainer>
        <LabelStyled>{t('SUGGEST_EDIT.WEBSITE')}</LabelStyled>
        <TextInputStyled
          data-auto-testid="provider-facility-WEBSITE"
          data-testid="provider-facility-WEBSITE"
          name="Website"
          onChange={handleChange}
          value={formData?.Website}
        />
      </TextInputContainer>
      <TextInputContainer>
        <LabelStyled>{t('SUGGEST_EDIT.OPEN_BUSINESS')}</LabelStyled>
        <RadioGroup
          data-auto-testid="provider-facility-open-for-business"
          data-testid="provider-facility-open-for-business"
          display="row"
          model="openForBusiness"
          size="sm"
          value="open"
        >
          <RadioGroup.Radio
            css={{
              'abyss-radio-label': {
                fontSize: '14px',
                fontWeight: '$medium',
                lineHeight: '18px',
              },
            }}
            label="Open"
            value="open"
          />
          <RadioGroup.Radio
            css={{
              'abyss-radio-label': {
                fontSize: '14px',
                fontWeight: '$medium',
                lineHeight: '18px',
              },
            }}
            label="Not open"
            value="not-open"
          />
        </RadioGroup>
      </TextInputContainer>
    </Flex>
  );
};
