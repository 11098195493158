import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import escapeRegExp from 'lodash/escapeRegExp';

import { useLagoon } from '../../../../hooks/useLagoon';
import { ConstantsLagoon } from '../../../ConstantsLagoon';
import { phoneOnly } from '../../../ConstantsStyles';
import { getFeatureFlag } from '../../../Utils';
import { keywordSearchDropdownIconColor } from './KeywordSearchDropdown.styled';

type Props = {
  keywordSearchTerm: string;
  onClick: any;
};
export const UserTextSearch = ({ keywordSearchTerm, onClick }: Props) => {
  const escapedKeywordSearchTerm = escapeRegExp(keywordSearchTerm);
  const isPhone = useMediaQuery(phoneOnly);
  const featureFlags = useLagoon('feature-flags')();
  const userTextSearchEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.AUTOCOMPLETE_PARALLEL_SEARCHFLOW
  );

  type LagoonData = {
    key: string;
    value: string | number;
  };

  const getLagoonContent = (data: LagoonData[], key: string) =>
    data?.filter((row) => row?.key === key)[0]?.value || '';

  const lagoonTitleTable = useLagoon('typeahead-params')();
  const userQueryLabel = getLagoonContent(
    lagoonTitleTable,
    ConstantsLagoon.TYPEAHEAD_PARAMS.USER_TEXT_SEARCH_LABEL
  );

  if (userTextSearchEnabled) {
    return (
      <Link
        before={
          <IconMaterial color={keywordSearchDropdownIconColor} icon="search" />
        }
        data-testid="user-text-search-common-suggestion"
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          onClick(escapedKeywordSearchTerm);
        }}
        style={{ whiteSpace: isPhone ? '' : 'pre' }}
        tabIndex={-1}
      >
        <div
          style={{
            ...{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            },
          }}
        >
          <Text
            color="$interactive1"
            size="18px"
            style={{ marginRight: '8px' }}
          >
            {keywordSearchTerm}
            <Text color="$gray7" fontWeight="500">
              {` - ${userQueryLabel}`}
            </Text>
          </Text>
        </div>
      </Link>
    );
  }
  return null;
};
