import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import isEmpty from 'lodash/isEmpty';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsOverflow } from '../../../../../../../../../hooks/useIsOverflow';
import { HoursOfOperation } from '../../../../../../../../../models/Provider';
import {
  StyledHoursOfOperationDiv,
  StyledNullAttributeDiv,
  TextRoot,
  popoverStyle,
} from '../../../../utility/compareDrawer.styled';
import { NULL_ATTRIBUTE_STATE } from '../../../../utility/compareDrawerConstants';
import { getHoursOfOperaion } from '../../../../utility/getHoursOfOperation';

type Props = {
  hoursOfOperation: HoursOfOperation;
  mobile: boolean;
};

export const HoursOfOperationText = ({ hoursOfOperation, mobile }: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);

  const { daysWithTime, possibleHours } = getHoursOfOperaion(hoursOfOperation);

  const isAttributeEmpty =
    isEmpty(daysWithTime) ||
    isEmpty(possibleHours) ||
    isEmpty(hoursOfOperation);

  if (isAttributeEmpty) {
    return (
      <React.Fragment>
        {mobile ? (
          <Popover
            content={t(NULL_ATTRIBUTE_STATE.HOURS_OF_OPERATION)}
            css={popoverStyle}
            showClose={false}
            tabIndex="-1"
            width={150}
          >
            <StyledNullAttributeDiv>
              {t(NULL_ATTRIBUTE_STATE.HOURS_OF_OPERATION)}
            </StyledNullAttributeDiv>
          </Popover>
        ) : (
          <StyledNullAttributeDiv>
            {t(NULL_ATTRIBUTE_STATE.HOURS_OF_OPERATION)}
          </StyledNullAttributeDiv>
        )}
      </React.Fragment>
    );
  }

  return (
    <StyledHoursOfOperationDiv
      cssProps={{ isOverflow, isAttributeEmpty }}
      ref={containerRef}
    >
      <Layout.Group alignItems="bottom" space={5}>
        <IconMaterial
          color="$primary1"
          icon="access_time"
          isScreenReadable
          size={20}
          title="Hours"
        />
        <TextRoot css={{ fontWeight: 'bold' }}>{daysWithTime} :</TextRoot>
        <TextRoot>{possibleHours}</TextRoot>
      </Layout.Group>
    </StyledHoursOfOperationDiv>
  );
};
