import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Box } from '@abyss/web/ui/Box';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import {
  Constants,
  CoverageTypesCodes,
  ReverseCoverageTypesCodes,
} from '../../../../../common/Constants';
import { ConstantsLagoon } from '../../../../../common/ConstantsLagoon';
import { ConstantsRoutes } from '../../../../../common/ConstantsRoutes';
import { phoneOnly } from '../../../../../common/ConstantsStyles';
import { ContentWrapper } from '../../../../../common/ContentWrapper';
import { ImageComponent } from '../../../../../common/ImageComponent';
import { LinkWithTracking } from '../../../../../common/LinkWithTracking/LinkWithTracking';
import { ChoosePCPPhoto } from '../../../../../common/PrimaryCareProvider/PrimaryCareProvider.styled';
import { Breadcrumb } from '../../../../../common/PSXHeader/Breadcrumb';
import { convertObjectToUrlParams } from '../../../../../common/Utils';
import { dateFormatCurrent } from '../../../../../common/Utils/datesUtils/formatDate';
import { useAdobeBrowserBackButtonTrack } from '../../../../../hooks/adobeHook/useAdobeBrowserBackButtonTrack';
import { useAdobePageTrackEvent } from '../../../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useCurrentMemberIsPreEffective } from '../../../../../hooks/useCurrentMemberIsPreEffective';
import { useLagoon } from '../../../../../hooks/useLagoon';
import { BreadcrumbSessionStorage } from '../../../../../models/BreadcrumbSessionStorage';
import { useChipStore } from '../../../../../store/useChipStore';
import { ChipState } from '../../../../../store/useChipStore/chipStore';
import { usePCPStore } from '../../../../../store/usePCPStore';
import { PCPSearchStore } from '../../../../../store/usePCPStore/pcpStore';
import { useTypeaheadStore } from '../../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../../store/useTypeaheadStore/typeaheadStore';
import { shortenZipCode } from '../../../../../utils/providerDetails.utils';
import { getMemberHealthCoverageType } from '../../../../../utils/providerSearch.utils';
import { getCurrentMember, isMNRMember } from '../../../../../utils/user.utils';
import {
  ChooseLocationCardsSuccessContainer,
  ClinicNameAddress,
  ClinicSubAddressSuccessPage,
  ContentContainer,
  LocationsDropdown,
  PCPHeaderContainer,
  SubSuccessProvider,
  SubSuccessProviderSpeciality,
  SuccessCheckMark,
  SuccessMessageHeading,
  SuccessPageBackButton,
  SuccessPageYourPCPHeader,
  SuccessPageYourPCPLocationHeader,
  SuccessSelectedPCPBox,
  TextContainer,
} from './ChoosePCPSuccess.styled';
import { SuccesssMapViewWrap } from './SuccessMapViewWrap';

export const ChoosePCPSuccess = () => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const { navigate } = useRouter();
  const { t } = useTranslation();
  const currentMember = getCurrentMember();
  const isPreEffective = useCurrentMemberIsPreEffective();
  const medicalCoverage = getMemberHealthCoverageType(
    currentMember?.eligibility,
    CoverageTypesCodes.MEDICAL
  );

  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );
  const {
    setPCPSearchState,
    dependentSeqNbr,
    formattedProviderName,
    pcpEffectiveDate,
    selectedLocation,
    providerType,
    primarySpeciality,
    imageLocation,
  } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      formattedProviderName: state.pcpSearchState.formattedProviderName,
      pcpEffectiveDate: state.pcpSearchState.pcpEffectiveDate,
      selectedLocation: state.pcpSearchState.selectedLocation,
      providerType: state.pcpSearchState.providerType,
      primarySpeciality: state.pcpSearchState.primarySpeciality,
      imageLocation: state.pcpSearchState.imageLocation,
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
      setPCPSearchState: state.setPCPSearchState,
    }))
  );
  const preEffectivePcpDate = dateFormatCurrent(
    medicalCoverage?.coverageEffDateRange?.startDate
  );

  const choosePCPSuccess: string = 'choose pcp success';
  const choosePCPSuccessRef = useRef<HTMLDivElement>(null);
  const imgRef = useRef<HTMLImageElement>(null);
  const uiMessaging = useLagoon('ui-messaging')();
  const pcpSuccessMessage = find(uiMessaging, {
    key: ConstantsLagoon.PCP_CHANGE_MESSAGE.PCP_SUCCESS_MESSAGE,
  });
  const { adobeBrowserBackButtonTrack } = useAdobeBrowserBackButtonTrack();

  const handleBackToFindCare = () => {
    adobeLinkTrackEvent({
      name: 'back to find care',
      location: `body:${choosePCPSuccess}`,
      type: 'internal',
    });

    const selectedCoverageType =
      find(Constants.MOCK_CARE_CATEGORIES, {
        value: Constants.CHIPS_CATEGORIES.PRIMARY_CARE,
      })?.coverageType || CoverageTypesCodes.MEDICAL;

    navigate(
      `/?chipValue=${Constants.CHIPS_CATEGORIES.PRIMARY_CARE}&coverageType=${ReverseCoverageTypesCodes[selectedCoverageType]}`
    );
  };

  const handleBackToChangeLocation = () => navigate(-1);

  const handleBackToChangePCP = () => {
    const typeaheadStoreValues = {
      search: Constants.RESULT_SECTION.PRIMARY_CARE_PROVIDERS,
      searchType: Constants.SEARCH_TYPES.SPECIALTY,
      providerType: '',
    };
    setTypeaheadSearchStore({
      ...typeaheadStoreValues,
      resultType: Constants.RESULT_SECTION.ALL,
      acceptingNewPatients: true,
    });
    const pcpStoreValues = {
      choosePCP: true,
      dependentSeqNbr,
      pcpIndicator: true,
    };
    setPCPSearchState(pcpStoreValues);
    const urlParams = convertObjectToUrlParams(chipStore, {
      ...typeaheadStoreValues,
      ...pcpStoreValues,
    });
    navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
  };

  const selectedProviderLocation = selectedLocation
    ? JSON.parse(selectedLocation)
    : {};

  const {
    line = '',
    city = '',
    state = '',
    postalCode = '',
  } = selectedLocation ? selectedProviderLocation?.address : {};

  const addressString = `${line}, ${city}, ${state} ${shortenZipCode(
    postalCode
  )}`;

  const [breadcrumbUrls] = useSessionStorage<BreadcrumbSessionStorage>(
    Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
    {}
  );

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: choosePCPSuccess,
    sitesectionLevel1: 'pcp',
  });

  useEffect(() => {
    adobePageTrackEvent();
  }, []);

  useEffect(() => {
    choosePCPSuccessRef?.current?.focus();
  }, []);

  useEffect(() => {
    adobeBrowserBackButtonTrack();
  }, []);

  const parseBreadcrumbs = (urls) => {
    const breadcrumbs = [
      {
        title: t('BC Results'),
        href: `${breadcrumbUrls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key]}`,
      },
      {
        title: t('DETAILS_PAGE.PROVIDER_BREADCRUMB'),
        href: `${breadcrumbUrls[ConstantsRoutes.PROVIDER_DETAILS.key]}`,
      },
      {
        title: t('PCP_LOCATION.PCP Chosen'),
        href: '',
      },
    ];
    if (!urls[ConstantsRoutes.PROVIDER_DETAILS.key]) {
      breadcrumbs.splice(1, 1);
    } else if (!urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key]) {
      breadcrumbs.splice(0, 1);
    }
    return breadcrumbs;
  };

  const breadcrumbs = parseBreadcrumbs(breadcrumbUrls);

  const choosePCPSuccessPageDetail = (
    <ChooseLocationCardsSuccessContainer>
      <Flex direction="column">
        <SuccessPageYourPCPHeader>
          <Heading
            css={{
              'abyss-heading-root': {
                fontSize: '$mediumText !important',
                marginBottom: '12px',
                lineHeight: '32px !important',
              },
            }}
            display="h6"
            offset={1}
          >
            <IconMaterial
              color="$success1"
              css={{
                'abyss-icon': {
                  marginRight: '6.5px',
                },
              }}
              icon="check"
              size="15px"
            />
            {t('PCP_LOCATION.YOUR_PCP')}
          </Heading>
          <div style={{ marginLeft: 'auto' }}>
            <LinkWithTracking
              analyticsInfo={{
                location: `body:${choosePCPSuccess}`,
              }}
              aria-label={`${t('Change PCP for')} ${formattedProviderName}`}
              before={<IconMaterial icon="edit" size="$sm" />}
              data-auto-testid="success-page-change-pcp-link"
              data-testid="success-page-change-pcp-link"
              isStandardAnchor
              onClick={handleBackToChangePCP}
            >
              <Text
                css={{
                  color: '$info1',
                  fontSize: '$extraSmallText',
                  fontWeight: '$bold',
                  lineHeight: '16px',
                }}
              >
                {t('PCP_LOCATION.CHANGE_PCP')}
              </Text>
            </LinkWithTracking>
          </div>
        </SuccessPageYourPCPHeader>
        <LocationsDropdown>
          <Flex alignItems="center">
            <ChoosePCPPhoto>
              {ImageComponent({ imgRef, providerType, imgSrc: imageLocation })}
            </ChoosePCPPhoto>
            <Text
              css={{
                lineHeight: '24px',
                'abyss-text-root': {
                  marginLeft: '8px',
                  marginTop: '10px',
                  '@screen < $md': {
                    fontSize: '14.22px',
                  },
                },
              }}
            >
              <SubSuccessProvider>{formattedProviderName}</SubSuccessProvider>
              <SubSuccessProviderSpeciality>
                {primarySpeciality}
              </SubSuccessProviderSpeciality>
            </Text>
          </Flex>
        </LocationsDropdown>
        <SuccessPageYourPCPLocationHeader>
          <Flex direction="row">
            <Heading
              css={{
                'abyss-heading-root': {
                  fontSize: '$mediumText !important',
                  marginBottom: '8px',
                  lineHeight: '32px !important',
                },
              }}
              display="h6"
              offset={1}
            >
              <IconMaterial
                color="$success1"
                css={{
                  'abyss-icon': {
                    marginRight: '6.5px',
                  },
                }}
                icon="check"
                size="15px"
              />
              {t('PCP_LOCATION.YOUR_PCP_LOCATION')}
            </Heading>

            <Layout.Group style={{ marginLeft: 'auto' }}>
              <LinkWithTracking
                analyticsInfo={{
                  location: `body:${choosePCPSuccess}`,
                }}
                aria-label={`${t('Change location for')} ${addressString}`}
                before={<IconMaterial icon="edit" size="$sm" />}
                data-auto-testid="success-page-change-location-link"
                data-testid="success-page-change-location-link"
                onClick={handleBackToChangeLocation}
              >
                <Text
                  css={{
                    color: '$info1',
                    fontSize: '$extraSmallText',
                    fontWeight: '$bold',
                    lineHeight: '16px',
                  }}
                >
                  {t('PCP_LOCATION.CHANGE_LOCATION')}
                </Text>
              </LinkWithTracking>
            </Layout.Group>
          </Flex>
        </SuccessPageYourPCPLocationHeader>
        <ClinicNameAddress>
          <Flex alignItems="center" justify="space-between">
            <ContentContainer>
              <TextContainer>
                <ClinicSubAddressSuccessPage>
                  {addressString}
                </ClinicSubAddressSuccessPage>
              </TextContainer>
            </ContentContainer>
          </Flex>
        </ClinicNameAddress>
      </Flex>
      <div
        style={{
          justifyContent: 'center',
          flexDirection: 'row',
          marginTop: '31px',
        }}
      >
        <SuccessPageBackButton
          after={
            <IconMaterial
              color="$white"
              css={{ margin: '15px 0 15px -10px' }}
              icon="chevron_right"
              size="24px"
            />
          }
          aria-describedby="reverify"
          data-auto-testid="choose-pcp-back-home"
          data-testid="choose-pcp-back-home"
          onClick={handleBackToFindCare}
        >
          {t('Back to Find Care')}
        </SuccessPageBackButton>
      </div>
    </ChooseLocationCardsSuccessContainer>
  );
  return (
    <React.Fragment>
      <PCPHeaderContainer data-auto-testid="choose-pcp-success-container">
        <ContentWrapper>
          <SuccessSelectedPCPBox color="#FBFCFE">
            {!mobileScreen ? <Breadcrumb breadcrumbs={breadcrumbs} /> : null}
            <SuccessCheckMark>
              <div>
                <IconBrand
                  color="#FFFFFF"
                  icon="checkmark"
                  size={!mobileScreen ? '40' : '32'}
                  variant={
                    !mobileScreen ? 'twotonelightcircle' : 'twotonedarkcircle'
                  }
                />
              </div>
              <SuccessMessageHeading
                data-auto-testid="success-message-heading"
                data-testid="success-message-heading"
                ref={choosePCPSuccessRef}
                tabIndex={-1}
              >
                <h1
                  aria-label={`${t(
                    'PCP_LOCATION.SUCCESS_LOCATION'
                  )} ${'for'} ${formattedProviderName}`}
                >
                  {t('PCP_LOCATION.SUCCESS_LOCATION')}
                </h1>
                {pcpEffectiveDate ? (
                  <span>
                    {t('PCP_LOCATION.SUCCESS_LOCATION_INFO5')}{' '}
                    {isPreEffective ? preEffectivePcpDate : pcpEffectiveDate}.
                  </span>
                ) : null}
                {!isMNRMember && (
                  <div data-testid="pcp-change-message">
                    {pcpSuccessMessage?.message}
                  </div>
                )}
              </SuccessMessageHeading>
            </SuccessCheckMark>
          </SuccessSelectedPCPBox>
        </ContentWrapper>
      </PCPHeaderContainer>
      <ContentWrapper>
        <Box
          color="white"
          css={{ position: 'relative' }}
          height="unset"
          padding={0}
        >
          <SuccesssMapViewWrap
            providerLocations={[selectedProviderLocation]}
            successPageDetails={choosePCPSuccessPageDetail}
          />
        </Box>
      </ContentWrapper>
    </React.Fragment>
  );
};
