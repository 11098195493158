import { event } from '@abyss/web/tools/event';

export const adobeImpressionTrackEvent = (input) => {
  const { customAttributesBlock } = input;

  event('IMPRESSION_TRACK_EVENT', {
    impression: {
      type: input.type?.toLowerCase(),
      message: input.message ?? '',
    },
    search: {
      term: input.searchTerm?.toLowerCase() ?? '',
      method: input.method?.toLowerCase() ?? '',
    },

    ...(customAttributesBlock && { customAttributes: customAttributesBlock }),
  });
};
