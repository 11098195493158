import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Alert } from '@abyss/web/ui/Alert';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { usePrimaryCare } from '../../hooks/usePrimaryCare';
import {
  getCurrentMember,
  getCurrentMemberPCPInfo,
} from '../../utils/user.utils';
import { phoneOnly } from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper/ContentWrapper';
import { convertUrlParamsToObject } from '../Utils';
import { getFormattedDate } from '../Utils/datesUtils/formatDate';
import {
  AlertMessage,
  PCPAlertMessage,
  PCPAlertMessageMobile,
} from './DetailsPageHeader.styled';

export type Props = {
  providerId: string;
  dependentSeqNbr?: string;
  displayFutureTerminationDate: boolean;
};

export const DetailsPagePcpAlerts = ({
  providerId,
  dependentSeqNbr,
  displayFutureTerminationDate,
}: Props) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const { data } = usePrimaryCare();
  const { primaryCare } = data;
  const urlParams = new URLSearchParams(window.location.search);
  const { displayBanner }: any = convertUrlParamsToObject(urlParams.entries());
  const currentMember = getCurrentMember();
  const memberData = getCurrentMemberPCPInfo(
    dependentSeqNbr || currentMember?.demographics?.dependentSeqNbr,
    primaryCare
  );
  const pcpAlertCSS = (mobileScreen: boolean) =>
    mobileScreen ? PCPAlertMessageMobile : PCPAlertMessage;
  const providerDetailsProviderId = providerId;
  const futurePCPInfo = memberData?.futurePCP;
  const futurePCPAlertTitle =
    getFormattedDate(futurePCPInfo?.startDate) &&
    (displayBanner || futurePCPInfo?.providerID === providerDetailsProviderId)
      ? `${t(
          'PROVIDER_DETAILS.VISIT_YOUR_FUTURE_PCP_ALERT'
        )} ${getFormattedDate(futurePCPInfo?.startDate)}.`
      : null;
  const currentPCPAlertTitle =
    getFormattedDate(memberData?.endDate) &&
    memberData?.providerID === providerDetailsProviderId &&
    !displayBanner
      ? `${t(
          'PROVIDER_DETAILS.VISIT_YOUR_CURRENT_PCP_ALERT'
        )} ${getFormattedDate(memberData?.endDate)}.`
      : null;
  return (
    <React.Fragment>
      {!displayFutureTerminationDate &&
      (currentPCPAlertTitle || futurePCPAlertTitle) ? (
        <div style={AlertMessage}>
          <ContentWrapper pcpAlert={true}>
            <Alert
              css={pcpAlertCSS(mobileScreen)}
              data-testid="details-page-pcp-banner"
              title={
                currentPCPAlertTitle
                  ? currentPCPAlertTitle
                  : futurePCPAlertTitle
              }
              variant="info"
            />
          </ContentWrapper>
        </div>
      ) : null}
    </React.Fragment>
  );
};
