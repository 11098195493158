import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ChooseLocationHeading,
  ChooseLocationHeadingWrapper,
} from './ChoosePCPLocation.styled';

type Props = {
  formattedProviderName: string;
  hasMultipleLocations: boolean;
  isMobileMapView: boolean;
};

export const ChoosePCPLocationHeading = ({
  formattedProviderName,
  hasMultipleLocations,
  isMobileMapView,
}: Props) => {
  const { t } = useTranslation();

  return !isMobileMapView ? (
    <ChooseLocationHeadingWrapper
      data-auto-testid="choose-location-heading"
      data-testid="choose-location-heading"
    >
      {hasMultipleLocations ? (
        <React.Fragment>
          <ChooseLocationHeading display="h3" offset={0}>
            {t('CHOOSE_PCP_FLOW.MULTIPLE_LOCATIONS_HEADING')}
          </ChooseLocationHeading>
          <Text color="#4B4D4F" fontWeight="$medium" size={18}>
            {formattedProviderName}{' '}
            {t('CHOOSE_PCP_FLOW.MULTIPLE_LOCATIONS_SUBHEADING')}
          </Text>
        </React.Fragment>
      ) : (
        <ChooseLocationHeading display="h3" offset={0}>
          {t('CHOOSE_PCP_FLOW.VERIFY_LOCATION')}
        </ChooseLocationHeading>
      )}
    </ChooseLocationHeadingWrapper>
  ) : null;
};
