import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import React, { useEffect } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { Constants } from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../../../common/Utils';
import { useLagoon } from '../../../../hooks/useLagoon';
import { usePlanSelector } from '../../../../hooks/usePlanSelector';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { loginNotRequired } from '../../../../utils/session.utils';
import { IChildrenProp } from '../../types';
import { getPlanSelectorVariables } from './helpers';

export const PlanSelector = ({ children }: IChildrenProp) => {
  const [planSelector, setPlanSelector] = usePlanSelector({});
  const [, setMemberId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MEMBER_ID,
    0
  );
  const featureFlags = useLagoon('feature-flags')();
  const planSelectorFeatureFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_PLAN_SELECTION_V2
  );

  const { planToken, accountId } = getPlanSelectorVariables();

  const setPlanSelectorState = useStore(StoreKeys.SET_PLAN_SELECTOR);
  const { isLoading } = planSelector;
  const isLocal = loginNotRequired();

  useEffect(() => {
    if (!isLocal) {
      setPlanSelector({
        variables: {
          planToken,
          accountId,
          enablePlanSelectorV2: planSelectorFeatureFlag,
        },
      });
    }
  }, []);

  useEffect(() => {
    if (planSelector?.data) {
      setPlanSelectorState(planSelector.data?.planSelector);
      const { MemberNumber } = planSelector.data?.planSelector?.bigN;
      /** MNR and ENI will have MemberNumber from PlanSelector , no need for userName as alternate check.
       */
      setMemberId(parseInt(MemberNumber || '0', 10));
    }
  }, [planSelector]);

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingSpinner
          ariaLoadingLabel="Loading page"
          isLoading={isLoading}
          size="$sm"
        />
      ) : (
        <React.Fragment>{children}</React.Fragment>
      )}
    </React.Fragment>
  );
};
