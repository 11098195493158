export enum Locale {
  enUS = 'en-US', // English
  esUS = 'es-US', // Spanish
}

export type LanguageObject = {
  name: string;
  title: string;
  locale: Locale;
  code: string;
};

export type Languages = {
  [code: string]: LanguageObject;
};

export const LANGUAGES: Languages = {
  en: {
    name: 'English',
    title: 'English',
    locale: Locale.enUS,
    code: 'en',
  },
  es: {
    name: 'Spanish',
    title: 'Español',
    locale: Locale.esUS,
    code: 'es',
  },
};

export const DefaultLanguage = LANGUAGES.en;
