import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';

export const RatingContainer = styled(Flex, {
  height: '14px',
  lineHeight: '16px',
  textAlign: 'center',
  gap: '8px',
});

export const RatingText = styled(Text, {
  fontWeight: 700,
  fontSize: '$extraSmallText !important',
  '@screen < $sm': {
    fontWeight: 500,
  },
});

export const RatingsStar = styled(Flex, {
  display: 'inline-block',
  gap: '2px',
});

export const VoiceOnlyText = styled('span', {
  border: '0',
  clip: 'rect(0 0 0 0)',
  height: '1px',
  overflow: 'hidden',
  padding: 0,
  position: 'absolute',
  userSelect: 'none',
  width: '1px',
});

export const SpanStyle = styled('span', {
  textDecoration: 'underline',
  '&:hover': {
    textDecoration: 'underline',
  },
  gap: '4px',
});
