import { storage } from '@abyss/web/tools/storage';
import { PVRCCodeTypes } from '../common/Constants';

export const getMessagesForMember = (customMesssges, memberPVRC, memberPolicyNumber) => customMesssges?.filter(message => {
    const { id, active, pvrcCodes, policyNumber } = message;
    const cleanedPVRCodes = pvrcCodes?.trim().toLowerCase();
    const shownAlreadyInSession = storage.session.get(id);
    const isPolicyNumberMatch = policyNumber === memberPolicyNumber;
    return isPolicyNumberMatch && !shownAlreadyInSession && active && (cleanedPVRCodes ? cleanedPVRCodes === PVRCCodeTypes.ALL || cleanedPVRCodes.includes(memberPVRC) : policyNumber.includes(memberPolicyNumber));
  })



