import { useRouter } from '@abyss/web/hooks/useRouter';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Modal } from '@abyss/web/ui/Modal';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import { useHealthgradesGetBulkInfo } from '../../../../hooks/useHealthgrades';
import { useLagoon } from '../../../../hooks/useLagoon';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { HealthGradesBulkInfo, Survey } from '../../../../models/Healthgrades';
import {
  ProviderDetails,
  ProviderLocation,
} from '../../../../models/ProviderDetails';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../../../store/useDetailsStore';
import { DetailsStore } from '../../../../store/useDetailsStore/detailsStore';
import {
  getNetworkStatus,
  getPatientCardLength,
} from '../../../../utils/providerDetails.utils';
import { formatProviderNameWithFirstMiddleLastName } from '../../../../utils/providerNames.utils';
import { Constants } from '../../../Constants';
import { ConstantsLagoon } from '../../../ConstantsLagoon';
import { ConstantsRoutes } from '../../../ConstantsRoutes';
import { h2SmallerFontForResponsive } from '../../../ConstantsStyles';
import { ContentWrapper } from '../../../ContentWrapper';
import { convertObjectToUrlParams, getFeatureFlag } from '../../../Utils';
import { handleLinkAndModalTrack } from '../../../Utils/adobeTrackUtils/adobeTrackUtils';
import { PatientReviewDetailsSection } from './PatientReviewDetailsSection';
import { PatientReviewMobileCarousel } from './PatientReviewMobileCarousel';
import { PatientReviewsModal } from './PatientReviewModal';
import {
  Container,
  ContentContainer,
  RatingSectionContainerStyles,
  StyledIcon,
  modalStyles,
  verbatimContainerStyles,
  verbatimHeader,
} from './PatientReviews.styled';
import { PatientReviewsViewAll } from './PatientReviewsViewAll';
import { PatientVerbatimCard } from './PatientVerbatimCard';

type Props = {
  selectedLocation?: ProviderLocation;
  providerDetails: ProviderDetails;
};

export const PatientReviews = ({
  selectedLocation,
  providerDetails,
}: Props) => {
  const patientReviewsDataTestId: string = 'patient-reviews-section';
  const sectionHeading: string =
    Constants.PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION
      .WHAT_PATIENTS_ARE_SAYING;
  const featureFlags = useLagoon('feature-flags')();
  const displayPatientReviewsDynamicHeader: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.PATIENT_REVIEWS_DYNAMIC_HEADER
  );

  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const { navigate } = useRouter();
  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { providerId, locationId, npi, setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
      providerId: state.detailsState.providerId,
      locationId: state.detailsState.locationId,
      npi: state.detailsState.npi,
    }))
  );
  const [healthgradesReview, setHealthgradesReview] =
    useState<HealthGradesBulkInfo>({});
  const [isLoading, setIsLoading] = useState(false);
  const [reviewModal, setReviewModal] = useState<Survey | null>(null);
  const [, healthgradesGetBulkInfo] = useHealthgradesGetBulkInfo({
    onCompleted: (result) => {
      setIsLoading(false);
      if (result?.data?.healthgradesGetBulkInfo) {
        setHealthgradesReview(result.data.healthgradesGetBulkInfo[0]);
      }
    },
    onError: (error) => {
      setIsLoading(false);
      throw error;
    },
  });

  const networkStatus = getNetworkStatus(selectedLocation?.network?.status);
  const { surveys } = healthgradesReview || {};

  const handleViewAllOnClick = () => {
    const detailsStoreValues = {
      showDirection: false,
      avatar: providerDetails?.imageLocation || 'heart',
      inNetwork: networkStatus,
      providerName: providerDetails.providerName,
      primarySpeciality: providerDetails.specialties?.join('|'),
      professionalDesignation: providerDetails.primaryDegrees?.[0],
      rating: providerDetails?.healthGradeRating,
      surveyCount: providerDetails?.surveyCount,
      npi: providerDetails.npi,
      providerDetails: JSON.stringify(providerDetails),
      imageLocation: providerDetails.imageLocation,
    };
    setDetailsStore(detailsStoreValues);
    const urlParams = convertObjectToUrlParams(chipStore, {
      providerId,
      locationId,
      npi,
    });
    navigate(`${ConstantsRoutes.ALL_PATIENT_REVIEWS.path}${urlParams}`);
  };
  useEffect(() => {
    if (providerDetails?.npi) {
      setIsLoading(true);
      healthgradesGetBulkInfo({
        variables: {
          npis: [providerDetails.npi],
        },
      });
    }
  }, []);

  const reviewsLength = surveys?.length || 0;
  const displayCarousel = getPatientCardLength(reviewsLength, isWidget);

  const displayViewAll = reviewsLength > 4 ? true : false;

  useEffect(() => {
    if (reviewModal) {
      const linkName: string = patientReviewsDataTestId;
      const linkLocation: string = `body:${sectionHeading}`;
      const modalName: string = 'patient review';
      handleLinkAndModalTrack(linkName, linkLocation, modalName);
    }
  }, [reviewModal]);

  const patientReviewsHeader = () => {
    if (displayPatientReviewsDynamicHeader) {
      return `${t(
        'PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.WHAT_PATIENTS_ARE_SAYING_ABOUT'
      )} ${formatProviderNameWithFirstMiddleLastName(providerDetails)}${
        providerDetails.primaryDegrees
          ? `, ${providerDetails.primaryDegrees}`
          : ''
      }`;
    }
    return t('PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.PATIENT_REVIEWS');
  };

  return (
    <Container>
      <ContentWrapper>
        <ContentContainer data-testid="patient-review-section">
          {reviewModal ? (
            <Modal
              css={modalStyles}
              isOpen={!!reviewModal}
              onClose={() => setReviewModal(null)}
            >
              <Modal.Section
                data-auto-testId={patientReviewsDataTestId}
                data-testId={patientReviewsDataTestId}
              >
                <PatientReviewsModal review={reviewModal} />
              </Modal.Section>
            </Modal>
          ) : null}
          <StyledIcon
            icon="healthgrades"
            isScreenReadable
            size={132}
            title={t('HEALTH_GRADES_LOGO_TITLE')}
          />
          <Heading
            css={h2SmallerFontForResponsive}
            data-auto-testid="patient-review-heading"
            data-testid="patient-review-heading"
            display="h4"
            offset={1}
          >
            {patientReviewsHeader()}
          </Heading>
          {isLoading ? (
            <LoadingSpinner
              ariaLoadingLabel="loading results"
              isLoading={isLoading}
              size="$sm"
            />
          ) : (
            <div
              data-auto-testid="reviews-container"
              data-testid="reviews-container"
              style={RatingSectionContainerStyles}
            >
              {healthgradesReview ? (
                <div>
                  <PatientReviewDetailsSection
                    healthgradesReview={healthgradesReview}
                  />
                  {surveys?.length ? (
                    <>
                      <Flex css={verbatimHeader}>
                        <Heading
                          css={{
                            '@screen < $md': {
                              '&.abyss-heading-root': {
                                height: '20px !important',
                                fontSize: '16px !important',
                                lineHeight: '20px !important',
                              },
                            },
                          }}
                          data-auto-testid="what-patients-are-saying-subtitle"
                          data-testid="what-patients-are-saying-subtitle"
                          display="h6"
                          offset={2}
                        >
                          {t(
                            'PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.WHAT_PATIENTS_ARE_SAYING'
                          )}
                        </Heading>
                        <PatientReviewsViewAll
                          displayViewAll={displayViewAll}
                          handleViewAllOnClick={handleViewAllOnClick}
                          locationForAnalytics={sectionHeading}
                        />
                      </Flex>
                      {displayCarousel ? (
                        <PatientReviewMobileCarousel
                          healthgradesReview={healthgradesReview}
                          setReviewModal={setReviewModal}
                        />
                      ) : (
                        <Flex
                          alignItems="flex-end"
                          css={verbatimContainerStyles}
                        >
                          {surveys.slice(0, 4).map((survey) => (
                            <PatientVerbatimCard
                              key={survey.id}
                              setReviewModal={setReviewModal}
                              survey={survey}
                            />
                          ))}
                        </Flex>
                      )}
                    </>
                  ) : null}
                </div>
              ) : null}
            </div>
          )}
        </ContentContainer>
      </ContentWrapper>
    </Container>
  );
};
