import { styled } from '@abyss/web/tools/styled';
import { Accordion } from '@abyss/web/ui/Accordion';
import { Markdown } from '@abyss/web/ui/Markdown';

export const accordionHeader = {
  'abyss-accordion-header': {
    marginTop: '16px',
    marginBottom: '16px',
    fontWeight: '$bold !important',
    lineHeight: '19.2px !important',
  },
};

export const AccordionItem = styled(Accordion.Item, {
  gap: '16px !important',
  '.abyss-accordion-trigger': {
    borderBottomColor: '#CBCCCD !important',
    borderBottomWidth: '0.5px !important',
    backgroundColor: 'white',
    '&:hover, &:focus, &.active, &.selected': {
      backgroundColor: '#EDF3FB !important',
    },
    '&.open': {
      backgroundColor: '#EDF3FB !important',
    },
    '&[data-state="open"]': {
      backgroundColor: '#EDF3FB !important',
    },
  },
  '.abyss-chevron-icon': {
    color: '#002677 !important',
  },
});

export const accordionContent = {
  'abyss-accordion-content-text': {
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '22.4px',
    color: '#4B4D4F !important',
    padding: '16px !important',
  },
  'abyss-accordion-content-container': {
    borderBottom: '0.5px solid #CBCCCD !important',
  },
};

export const StyledMarkdown = styled(Markdown, {
  'abyss-layout-stack': {
    paddingTop: '8px',
  },
  '.abyss-icon-symbol': {
    fontSize: '18px !important',
    paddingTop: '2px',
    color: '#196ECF !important',
  },
  '.abyss-link-root': {
    fontSize: '14px',
    lineHeight: '18px',
    borderColor: 'white !important',
  },
  '.abyss-flex-root': {
    paddingLeft: '5px',
  },
  '.abyss-accordion-trigger': {
    border: 'none',
    padding: '0px',
  },
});
export const healthPremiumLinkStyle = {
  'abyss-link-root': {
    fontSize: '14px',
    lineHeight: '18px',
    borderColor: 'white !important',
  },
};

export const accordionItemImages = {
  '.abyss-accordion-trigger': {
    backgroundColor: 'white',
    border: 'none',
    padding: '0 0 0 5px',
    width: 'fit-content',
    '&:hover': {
      backgroundColor: 'white !important',
    },
  },
  '.abyss-chevron-icon': {
    color: '#002677 !important',
  },
};
