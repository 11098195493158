import { storage } from '@abyss/web/tools/storage';

// Do not translate this json file
import uesSpecialityCode from '../../../../translation/data/en/uesSpecialityTranslations.json';
import {
  CareCategories,
  Constants,
  L2_CATEGORIES,
  RecentSearchesSearchTypes,
  TypeaheadChipCategoriesMapping,
  TypeaheadProviderTypes,
  TypeaheadProviderTypesMapping,
} from '../Constants';
import { ConstantsRoutes } from '../ConstantsRoutes';
import { convertObjectToUrlParams } from '../Utils';

export const handleRecentSearchesChipClick = (
  item,
  navigate,
  analyticsLinkName,
  {
    chipStore,
    setTypeaheadSearchStore,
    setAnalyticsState,
    setChipValue,
    setCoverageType,
    setDetailsStore,
    setSearchStore,
  }
) => {
  if (
    item?.searchType === RecentSearchesSearchTypes.SPECIALITY ||
    item?.searchType === RecentSearchesSearchTypes.NAME ||
    item?.searchType === RecentSearchesSearchTypes.VISION
  ) {
    setAnalyticsState({
      originLinkNameForAnalytics: analyticsLinkName,
    });
    const chipStoreValues = {
      chipValue: item?.category,
      coverageType: item?.coverageType,
    };
    setChipValue(chipStoreValues.chipValue);
    setCoverageType(chipStoreValues.coverageType);
    const typeaheadStoreValues = {
      search: item?.psxSearchTerm ?? '',
      pesKeyword: getPesSearchTerm(item),
      providerType: item?.providerType ?? '',
      searchType: item?.searchType,
      includeSpecialityRollupCodes: getPesSearchTerm(item),
      isUserTextSearch: item?.isUserTextSearch,
      pseudoVerticals: item?.pseudoVerticals,
      isMixedSuggestions: item?.isMixedSearchSuggestion,
      gender: item?.gender?.toLowerCase(),
      virtualCare: item?.virtualCare,
      condition: item?.conditions,
      specialtyCategory: item?.specialtyCategories,
      bhProgramFacilityAgg: item?.bhProgramFacilityAgg,
    };
    setSearchStore({
      onlineRetailers: item?.searchType === RecentSearchesSearchTypes.VISION,
    });
    setTypeaheadSearchStore({
      ...typeaheadStoreValues,
      keyword: item?.psxSearchTerm,
    });
    const urlParams = convertObjectToUrlParams(chipStore, {
      ...chipStoreValues,
      ...typeaheadStoreValues,
    });
    navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
  } else {
    const detailRoutesPath = () => {
      switch (item?.searchType.toLowerCase()) {
        case RecentSearchesSearchTypes.MEDICAL_GROUP_DETAIL.toLowerCase():
          return ConstantsRoutes.PROVIDER_GROUP_DETAILS.path;
        case RecentSearchesSearchTypes.PRACTITIONER_DETAIL.toLowerCase():
          return ConstantsRoutes.PROVIDER_DETAILS.path;
        default:
          return ConstantsRoutes.FACILITY_DETAILS.path;
      }
    };
    const detailsRoute = detailRoutesPath();
    const providerId = item?.pesSearchTerm;

    const detailsStoreValues = {
      providerId,
    };
    setDetailsStore(detailsStoreValues);
    const chipStoreValues = {
      chipValue: item?.category,
      coverageType: item?.coverageType,
    };
    setChipValue(chipStoreValues.chipValue);
    setCoverageType(chipStoreValues.coverageType);

    storage.session.set(
      Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_ID,
      providerId
    );
    const urlParams = convertObjectToUrlParams(chipStore, {
      ...detailsStoreValues,
      ...chipStoreValues,
    });
    navigate(`${detailsRoute}${urlParams}`);
  }
};

export const mappingRollUpCodeToCategory = (code) => {
  if (code) {
    const L2Code = code
      .split(',')
      ?.find((item) => item?.split('-')[0] !== '52');
    return L2_CATEGORIES[L2Code?.split('-')[0]] || '';
  }
  return '';
};

export const getOrgL2Category = (code) => {
  if (code && code.includes('-')) {
    const L2Code = code
      .split(',')
      ?.find(
        (item) => item?.split('-')?.length === 5 && item?.split('-')[0] === '52'
      );
    const parseL2Code = L2Code
      ? L2Code.split('-')[3].concat('-', L2Code.split('-')[4])
      : '';
    const L2Key =
      Object.keys(uesSpecialityCode).find((key) => key.includes(parseL2Code)) ||
      '';
    return L2_CATEGORIES[L2Key?.split('-')[0]] || '';
  }
  return '';
};

export const mappingSpecialityRollUpCodeToCategory = (
  speciality,
  providerType
) => {
  if (speciality?.length > 0) {
    const categories = [] as any;

    speciality.forEach((item) => {
      const category =
        providerType === TypeaheadProviderTypes.PROVIDER_GROUP
          ? getOrgL2Category(item?.orgCode)
          : TypeaheadProviderTypesMapping[item?.providerType];
      if (!categories.includes(category)) {
        categories.push(category);
      }
    });

    return categories.join();
  }
  return '';
};

export const getCategoryFromChipCategoriesCount = (chipCategories) => {
  if (Array.isArray(chipCategories)) {
    const categories = [] as string[];
    chipCategories?.forEach((chipCategory) => {
      if (chipCategory?.chipEnabled)
        categories.push(
          TypeaheadChipCategoriesMapping[chipCategory?.chipCategory]
        );
    });
    return categories.join() || CareCategories.ALL;
  }
  return chipCategories || 'category';
};

const getPesSearchTerm = (item) =>
  item?.isUserTextSearch || item?.conditions || item?.specialtyCategories
    ? ''
    : item?.pesSearchTerm;
