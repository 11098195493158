import { styled } from '@abyss/web/tools/styled';

import { mobileOnly, notMobile } from '../../../ConstantsStyles';

const lightGrey = '#d0d0d0';
export const keywordSearchDropdownIconColor = '#6F6F6F';
const errorMessageColor = '#C40000';

export const Divider = styled('div', {
  backgroundColor: lightGrey,
  width: '100%',
  height: '1px',
  [`@media ${mobileOnly}`]: {
    marginTop: '8px',
  },
});

export const DropdownContainer = styled('ul', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  overflow: 'hidden',
  width: '100%',
  gap: '16px',
  [`@media ${notMobile}`]: {
    padding: `16px 24px 24px`,
  },
  [`@media ${mobileOnly}`]: {
    padding: `16px 23px 20px`,
  },
  background: 'white',
  zIndex: 1,
  '& li': {
    display: 'inherit',
  },
});

export const SuggestionListContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  [`@media ${notMobile}`]: {
    gap: '12px',
  },
  [`@media ${mobileOnly}`]: {
    gap: '16px',
  },
  '.no-results': {
    fontWeight: 500,
    color: errorMessageColor,
  },
});

export const ParagraphSkeletonContainer = styled('div', {
  width: '100%',
  [`@media ${notMobile}`]: {
    marginTop: '4px',
  },
});

export const isPrimaryActiveSuggestion = {
  boxShadow:
    'white 0px 0px 0px 2px, var(--abyss-colors-interactive1) 0px 0px 2px 4px',
};

export const CircleIcon = {
  'abyss-icon-material': {
    width: '4px',
    fill: '$gray4',
    height: '4px',
    marginLeft: '4px',
  },
};

export const DistanceIcon = {
  'abyss-icon-material': {
    width: '16px',
    fill: '$gray7',
    height: '16px',
    marginBottom: '-3px',
  },
};

export const CoverageType = {
  fontWeight: '700',
  fontSize: '12px',
  color: '#007000',
  display: 'flex',
  marginRight: '8px',
};

export const InactiveCoverage = {
  fontWeight: '700',
  fontSize: '12px',
  color: '#4B4D4F',
  display: 'flex',
  gap: '8px',
};

export const ProviderIcon = {
  marginBottom: '-6px',
  marginRight: '8px',
};

export const CoverageIcon = { marginBottom: '-4px', marginRight: '2px' };
