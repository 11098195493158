type Props = {
  callPingFederate: boolean;
  encryptedPayload: string;
  partnerSpId?: string;
  pfidAdapterId?: string;
  ssoOutTargetUrl: string;
  ssoOutUrl: string;
  vendorId?: string;
  vsid?: string;
};

export const ScheduleModalHiddenForm = (values: Props) => {
  const {
    callPingFederate,
    vendorId,
    partnerSpId,
    encryptedPayload,
    ssoOutTargetUrl,
    pfidAdapterId,
    vsid,
  } = values;
  return (
    <>
      {!callPingFederate && (
        <input
          data-testid="docasap-vendorid"
          name="vendorId"
          type="hidden"
          value={vendorId}
        />
      )}
      {!callPingFederate && (
        <input
          data-testid="rally-caller-input"
          name="rally-caller"
          type="hidden"
          value="myuhc_web.psx"
        />
      )}
      {callPingFederate && (
        <input
          data-testid="docasap-partnerspid"
          name="PartnerSpId"
          type="hidden"
          value={partnerSpId}
        />
      )}
      <input
        data-testid={
          callPingFederate ? 'pingfederate-payload' : 'annex-payload'
        }
        name={callPingFederate ? 'REF' : 'annex'}
        type="hidden"
        value={encryptedPayload}
      />
      <input
        data-testid="docasap-target-url"
        name={callPingFederate ? 'TARGET' : 'targetUrl'}
        type="hidden"
        value={ssoOutTargetUrl}
      />
      {callPingFederate && (
        <input
          data-testid="ping-federate-pfidpadapterid"
          name="pfidpadapterid"
          type="hidden"
          value={pfidAdapterId}
        />
      )}
      {callPingFederate && (
        <input
          data-testid="ping-federate-vsid"
          name="vsid"
          type="hidden"
          value={vsid}
        />
      )}
    </>
  );
};
