import { config } from '@abyss/web/tools/config';
import { Accordion } from '@abyss/web/ui/Accordion';
import { Flex } from '@abyss/web/ui/Flex';
import { Link } from '@abyss/web/ui/Link';
import { Markdown } from '@abyss/web/ui/Markdown';
import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PremiumCareConstants } from '../../../common/Constants';
import { LinkWithTracking } from '../../../common/LinkWithTracking/LinkWithTracking';
import { getLanguage } from '../../../frontends/ProviderSearch/context/Internationalization/helpers';
import {
  AccordionItem,
  StyledMarkdown,
  accordionContent,
  accordionHeader,
  accordionItemImages,
  healthPremiumLinkStyle,
} from './Accordion.style';

type Props = {
  content: any;
  expandAll: boolean;
  closeAll: boolean;
  setExpandAll: (expandAll: boolean) => void;
  setCloseAll: (closeAll: boolean) => void;
};

export const FAQAccordion = ({
  content,
  expandAll,
  closeAll,
  setExpandAll,
  setCloseAll,
}: Props) => {
  const { t } = useTranslation();
  const [headerValues, setHeaderValues] = useState<string[]>([]);
  const [singleValue, setSingleValue] = useState<boolean>(false);
  const smartChoice_Image = `${config(
    'CDN_BASE_URL'
  )}/cdn/assets/images/smart-choice.png`;
  const locale = getLanguage().code;
  const unitedHealthPremiumData = PremiumCareConstants[locale];
  const navigationURL = unitedHealthPremiumData?.popoverURL ?? '';
  const unitedHealthPremiumLinkText =
    unitedHealthPremiumData?.disclaimerLinkText ?? '';

  useEffect(() => {
    if (closeAll) {
      setHeaderValues([]);
      setCloseAll(false);
    }
    if (expandAll) {
      setHeaderValues(content.map((x) => x.title));
      setExpandAll(false);
    }
  }, [closeAll, expandAll]);

  const onValueChangeSingle = (e) => {
    if (e === 'images') {
      setSingleValue(true);
    } else {
      setSingleValue(false);
    }
  };

  const renderAccordionImages = (section) => {
    const value = section?.content.split('<br><br>');
    const combinedValue = value.join('<br><br>');
    return (
      <Flex>
        <Flex>
          <Markdown>{value[0]}</Markdown>
          <Accordion
            isCollapsible
            onValueChange={onValueChangeSingle}
            type="single"
          >
            <Accordion.Item css={accordionItemImages} value="images">
              <Accordion.Trigger>
                <Accordion.Header>
                  {singleValue
                    ? t('FAQ_PAGE.CLOSE_IMAGES')
                    : t('FAQ_PAGE.EXPAND_IMAGES')}
                </Accordion.Header>
              </Accordion.Trigger>
              <Accordion.Content
                css={{
                  'abyss-accordion-content-text': {
                    width: '50%',
                    padding: '0px !important',
                  },
                }}
              >
                <Flex direction="row">
                  <img src={smartChoice_Image}></img>
                </Flex>
              </Accordion.Content>
            </Accordion.Item>
          </Accordion>
        </Flex>
        <Markdown>{combinedValue.replace(value[0], '')}</Markdown>
      </Flex>
    );
  };

  return (
    <React.Fragment>
      <Accordion
        css={{
          'abyss-accordion-root': {
            width: '100vw !important',
          },
        }}
        isCollapsible
        onValueChange={setHeaderValues}
        type={'multiple'}
        value={headerValues}
      >
        {content.length &&
          content.map((section) => (
            <AccordionItem data-testid="accordion-item" value={section?.title}>
              <Accordion.Trigger>
                <Accordion.Header
                  css={accordionHeader}
                  data-testid="accordion-header"
                >
                  {section?.title}
                </Accordion.Header>
              </Accordion.Trigger>
              <Accordion.Content
                css={accordionContent}
                data-testid="accordion-content"
              >
                {section?.key === '3' ? (
                  renderAccordionImages(section)
                ) : (
                  <StyledMarkdown
                    options={{
                      overrides: { useState, Link, Flex },
                    }}
                  >
                    {section?.content}
                  </StyledMarkdown>
                )}
                {section?.key === '18' && (
                  <LinkWithTracking
                    analyticsInfo={{
                      location: 'body:accordion-united-health-premium',
                      type: 'pdf',
                    }}
                    css={healthPremiumLinkStyle}
                    data-auto-testid="accordion-united-health-premium-link-text"
                    data-testid="accordion-united-health-premium-link-text"
                    href={navigationURL}
                    openNewWindow
                    size={18}
                  >
                    {unitedHealthPremiumLinkText}
                  </LinkWithTracking>
                )}
              </Accordion.Content>
            </AccordionItem>
          ))}
      </Accordion>
    </React.Fragment>
  );
};
