import { event } from '@abyss/web/tools/event';

export const widgetClickEvent = (input) => {
  const {
    componentId,
    componentLocation,
    componentName,
    componentType,
    destination,
  } = input;
  event('PSX_DETAILS_WIDGET_CLICK', {
    componentName: componentName?.toLowerCase().replaceAll('-', ''),
    componentId: componentId?.toLowerCase().replaceAll('-', ''),
    componentLocation: componentLocation?.toLowerCase().replaceAll('-', ''),
    componentType: componentType?.toLowerCase().replaceAll('-', ''),
    destination: destination?.toLowerCase().replaceAll('-', ''),
  });
};

export const widgetModalEvent = (input) => {
  const { modalName } = input;
  event('PSX_DETAILS_WIDGET_MODAL', {
    modalName: modalName?.toLowerCase().replaceAll('-', ''),
  });
};

export const widgetPageLoadEvent = (input) => {
  const { pageName } = input;
  event('PSX_DETAILS_WIDGET_PAGE_LOAD', {
    pageName: pageName?.toLowerCase().replaceAll('-', ''),
  });
};
