type RouteDefinition = {
  [key: string]: {
    key: string;
    name: string;
    path: string;
  };
};

export const ConstantsRoutes: RouteDefinition = {
  LANDING_PAGE: {
    key: 'landing_page',
    name: 'LandingPage',
    path: '/',
  },
  PROVIDER_SEARCH_RESULTS: {
    key: 'provider_search_results',
    name: 'ProviderSearchResults',
    path: '/results/',
  },
  NULL_SEARCH_RESULTS: {
    key: 'null_search_results',
    name: 'NullSearchResults',
    path: '/results/',
  },
  NULL_SPECIALTY_RESULTS: {
    key: 'no-specialty-results',
    name: 'NullResultPageForSpecialty',
    path: '/no-specialty-results/',
  },
  PROVIDER_SEARCH_RESULTS_VIEW_ALL: {
    key: 'provider_search_results_view_all',
    name: 'ProviderSearchResultsViewAll',
    path: '/results/viewAll/',
  },
  PROVIDER_SEARCH_RESULTS_MAP_VIEW: {
    key: 'provider_search_results_map_view',
    name: 'ProviderSearchResultsMapView',
    path: '/results/mapview/',
  },
  ALL_PATIENT_REVIEWS: {
    key: 'all_patient_reviews',
    name: 'AllPatientReviews',
    path: '/provider-details/reviews/',
  },
  FACILITY_DETAILS: {
    key: 'facility_details',
    name: 'FacilityDetails',
    path: '/facility-details/',
  },
  PROVIDER_DETAILS: {
    key: 'provider_details',
    name: 'ProviderDetails',
    path: '/provider-details/',
  },
  PROVIDER_GROUP_DETAILS: {
    key: 'provider_group_details',
    name: 'ProviderGroupDetails',
    path: '/group-details/',
  },
  VIRTUAL_PROVIDER_GROUP_DETAILS: {
    key: 'virtual_provider_group_details',
    name: 'VirtualProviderGroupDetails',
    path: '/virtual-group-details/',
  },
  CHOOSE_PCP_LOCATION: {
    key: 'choose_pcp_location',
    name: 'ChoosePCPLocation',
    path: '/choose-pcp/locations/',
  },
  CHOOSE_PCP_SUCCESS: {
    key: 'choose_pcp_success',
    name: 'ChoosePCPSuccess',
    path: '/choose-pcp/success/',
  },
};
