import { Link } from '@abyss/web/ui/Link';
import React from 'react';

import { findAllMatches } from '../../../Utils/findAllMatches/findAllMatches';
import {
  ProviderSpeciality,
  physicianLinkStyles,
} from '../PhysicianDirectory.styled';

type Props = {
  physician: {
    providerId?: string | undefined;
    providerName?: string;
    specialities: string;
  };
  linkOnClick: (a: any) => void;
  search: string;
};

export const DirectoryLink = ({ physician, linkOnClick, search }: Props) => {
  const name = physician.providerName?.replace(/,\s*$/, '');
  const specialitiesValid = physician.specialities
    ? physician.specialities.match('.*[a-zA-Z]+.*')
    : false;
  return (
    <Link
      css={physicianLinkStyles}
      data-auto-testid="physician-link"
      data-testid="physician-link"
      isStandardAnchor
      key={physician.providerId}
      onClick={() => linkOnClick(physician)}
    >
      {search.length > 0
        ? findAllMatches(search, name).map((x) =>
            x.isMatch ? <mark>{x.text}</mark> : x.text
          )
        : name}

      {specialitiesValid ? (
        <ProviderSpeciality>{physician.specialities}</ProviderSpeciality>
      ) : null}
    </Link>
  );
};
