import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Badge } from '@abyss/web/ui/Badge';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import find from 'lodash/find';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { removeLeadingZeroes } from '../../utils/string.utils';
import { getCurrentMember, getNetworkIdsForPES } from '../../utils/user.utils';
import { Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { PSXIcon } from '../PSXIcon';
import {
  PopoverContainer,
  TierOneSnackCardPopoverStyles,
} from '../SnackCard/SnackCard.styles';
import { BenefitsBadgeDrawerStyles } from '../TierBenefitsCard/TierBenefitsCard.style';
import { BadgeContainer } from './DetailsPageHeader.styled';

export type Props = {
  badgeType: BenefitsBadgeType;
  showInfoIcon?: boolean;
};

export enum BenefitsBadgeType {
  TIER_1 = 'TIER_1',
  PREFERRED_FACILITY = 'PREFERRED_FACILITY',
  GREEN_DIAMOND = 'GREEN_DIAMOND',
}

export const BenefitsBadge = ({ badgeType, showInfoIcon = true }: Props) => {
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = useMediaQuery(phoneOnly);
  const featureFlags = useLagoon('feature-flags')();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { t } = useTranslation();

  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { coverageType, isOHBS } = tokenData;

  const uiMessaging = useLagoon('ui-messaging')();
  const customPreferredPoliciesData = useLagoon('custom-preferred-policies')();
  const customPreferredPolicies = customPreferredPoliciesData?.map(
    (policy) => ({ ...policy, policyId: removeLeadingZeroes(policy.policyId) })
  );

  const currentMember = getCurrentMember();
  const reciprocityId = isOHBS
    ? '100'
    : getNetworkIdsForPES(currentMember, coverageType, featureFlags);

  const tierOneContent = find(uiMessaging, {
    key: ConstantsLagoon.MEDICAL_SPECIALISTS.TIER_ONE_BENEFITS_ONE,
  });
  const preferredFacilityContent = find(uiMessaging, {
    key: ConstantsLagoon.PREFERRED_FACILITY.PREFERRED_FACILITY_CONTENT,
  });
  const preferredProviderContent =
    find(customPreferredPolicies, {
      policyId: removeLeadingZeroes(currentMember?.policyNumber),
      reciprocityId,
    })?.popoverContent ?? Constants.PREFERRED_PROVIDER_BENEFITS.CONTENT;

  const preferredProviderHeading =
    find(customPreferredPolicies, {
      policyId: removeLeadingZeroes(currentMember?.policyNumber),
      reciprocityId,
    })?.badgeLabel ?? Constants.PREFERRED_PROVIDER_BENEFITS.HEADING;

  const providerPreferredIcon =
    find(customPreferredPolicies, {
      policyId: removeLeadingZeroes(currentMember?.policyNumber),
      reciprocityId: currentMember?.reciprocityId,
    })?.icon ?? Constants.PREFERRED_PROVIDER_BENEFITS.GREEN_DIAMOND;

  const tierOneContentText =
    tierOneContent?.message ?? t('TIERED_BENEFITS.TIER_CONTENT');
  const preferredFacilityContentText =
    preferredFacilityContent?.message ??
    t('PREFERRED_FACILITY_BENEFITS.CONTENT');
  const preferredProviderContentText =
    preferredProviderContent ?? t('PREFERRED_PROVIDER_BENEFITS.CONTENT');

  const page = isWidget ? 'widget' : 'detail';

  let backgroundColor, content, icon, testId, title;
  switch (badgeType) {
    case BenefitsBadgeType.PREFERRED_FACILITY:
      backgroundColor = '$info2';
      content = preferredFacilityContentText;
      testId = 'preferred-facility';
      title = t('Preferred Facility');
      break;
    case BenefitsBadgeType.GREEN_DIAMOND:
      backgroundColor = '$success2';
      content = t(preferredProviderContentText);
      icon = providerPreferredIcon;
      testId = 'preferred-provider';
      title = t(preferredProviderHeading);
      break;
    case BenefitsBadgeType.TIER_1:
    default:
      backgroundColor = '$success2';
      content = tierOneContentText;
      icon = 'Group 204976975';
      testId = 'tier-one';
      title = t('Tier 1 Provider');
      break;
  }

  testId = `${testId}-${page}-header`;

  const renderIcon = icon && <PSXIcon icon={icon} size={16} />;

  const WebBenefitsBadge = () => (
    <PopoverContainer>
      <Popover
        content={content}
        css={TierOneSnackCardPopoverStyles}
        data-auto-testid={`${testId}-popover`}
        data-testid={`${testId}-popover`}
        title={title}
        width={214}
      >
        <Layout.Group space="4px">
          <Badge
            ariaText={title}
            css={{ backgroundColor }}
            data-testid={`${testId}-badge`}
            icon={renderIcon}
            variant="neutral"
          >
            {title}
          </Badge>

          {showInfoIcon ? (
            <IconMaterial
              data-auto-testid={`${testId}-popover-info`}
              data-testid={`${testId}-popover-info`}
              icon="info"
              size={16}
              variant="outlined"
            />
          ) : null}
        </Layout.Group>
      </Popover>
    </PopoverContainer>
  );
  const PhoneBenefitsBadge = () => (
    <React.Fragment>
      <BenefitsBadgeDrawerStyles
        isOpen={isDrawerOpen}
        onClose={() => setDrawerOpen(!isDrawerOpen)}
        position="bottom"
        size="200"
        title={title}
      >
        {content}
      </BenefitsBadgeDrawerStyles>
      <Layout.Group space="4">
        <Badge
          ariaText={title}
          css={{ backgroundColor }}
          data-auto-testid={`${testId}-drawer`}
          data-testid={`${testId}-drawer`}
          icon={renderIcon}
          onClick={() => setDrawerOpen(!isDrawerOpen)}
          variant="neutral"
        >
          {title}
        </Badge>

        {showInfoIcon ? (
          <IconMaterial
            data-auto-testid={`${testId}-drawer-info`}
            data-testid={`${testId}-drawer-info`}
            icon="info"
            size={16}
            variant="outlined"
          />
        ) : null}
      </Layout.Group>
    </React.Fragment>
  );

  return (
    <BadgeContainer>
      {phoneScreen && !isWidget ? <PhoneBenefitsBadge /> : <WebBenefitsBadge />}
    </BadgeContainer>
  );
};
