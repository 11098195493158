import { styled } from '@abyss/web/tools/styled';

export const ShareBoxStyled = {
  'abyss-box-root': {
    backgroundColor: 'transparent',
    border: '2px dashed #929496',
    minWidth: '262px',
    height: '96px',
    borderRadius: '8px',
    justifyContent: 'left',
    alignItems: 'center',
    display: 'flex',
    marginBottom: '$md',
  },
};

export const MessageLabel = {
  'abyss-text-root': {
    fontWeight: '$medium',
    fontSize: '$md',
    lineHeight: '20px',
    color: '$gray9',
  },
};

export const MessageLabelLink = {
  'abyss-link-root': {
    fontWeight: '$bold',
    fontSize: '12.64px',
    lineHeight: '16px',
  },
};

export const CopyLabelLink = {
  'abyss-text-root': {
    fontWeight: '$bold',
    fontSize: '12.64px',
    lineHeight: '16px',
    color: '$success1',
    marginLeft: '5.5px',
  },
};

export const AddAnotherEmailLink = {
  'abyss-link-root': {
    fontWeight: '$bold',
    fontSize: '12.64px',
    lineHeight: '16px',
    marginTop: 20,
    marginBottom: 5,
  },
};

export const SendEmailButton = {
  'abyss-button-root': {
    marginTop: 15,
    height: '42px',
  },
  'abyss-button-content-container': {
    fontWeight: '$semibold',
    fontSize: '$md',
    lineHeight: '20px',
  },
};

export const CancelButton = {
  'abyss-button-root': {
    marginTop: 15,
    marginLeft: 15,
    height: '42px',
    borderWidth: 'revert',
  },
  'abyss-button-content-container': {
    fontWeight: '$semibold',
    fontSize: '$md',
    lineHeight: '20px',
  },
};

export const StyledDiv = styled('div', {
  width: '100%',
});

export const EmailBarContainer = styled('div', {
  maxHeight: '150px',
  padding: '0 6px 6px 0',
  overflowY: 'auto',
});

export const ShareContainer = styled('div', {
  paddingRight: '25px',
});

export const ShareContainerInner = styled('div', {
  paddingRight: '',
});

export const ShareBoxText = {
  'abyss-text-root': {
    fontSize: '$md',
    paddingLeft: '5px',
    fontWeight: '$bold',
    lineHeight: '18px',
    textAlign: 'center',
    color: '$accent1',
  },
};

export const PlaceHolderText = {
  'abyss-text-root': {
    fontSize: '$md',
    paddingLeft: '12%',
    alignContent: 'center',
    justifyContent: 'center',
    fontWeight: '$medium',
    lineHeight: '20px',
    textAlign: 'center',
    color: '$black',
  },
};

export const providerShareContentDrawerDesktop = {
  'abyss-floating-section-root': {
    borderRadius: '20px 20px 0px 0px',
    boxShadow: '0px -6px 20px rgba(25, 25, 26, 0.16)',
    width: '100%',
    padding: '24px 30px',
    position: 'fixed',
    bottom: '0',
    zIndex: '1',
    overflowY: 'auto',
    backgroundColor: '#FAFCFF',
  },
};

export const InputBoxCss = {
  'abyss-text-input-area-root': {
    marginLeft: '30px',
  },
  'abyss-text-input-area-input': {
    boxSizing: 'border-box',
    width: '100%',
    height: '191px',
    padding: '24px, 30px, 24px, 30px',
    display: 'flex',
    flexDirection: 'column',
  },
  'abyss-text-input-area-label': {
    marginBottom: '5px',
    width: '100%',
  },
  'abyss-text-input-area-label-wrapper': {
    justifyContent: 'space-between',
    width: '100%',
  },
};

export const EmailInputStyles = {
  'abyss-text-input': {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '40px',
    padding: '11px',
    borderRadius: '4px',
    border: '1px solid #4B4D4F',
  },
  'abyss-text-input-label': {
    fontWeight: '$semibold',
    fontSize: '14.22px',
    color: '#323334',
    lineHeight: '16px',
    marginBottom: '8px',
    span: {
      display: 'none',
    },
  },
};
