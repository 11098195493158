import { getCurrentMember } from '../../../../../../utils/user.utils';
import { getDefaultCompareAttributes } from '../../../ViewAll/utils/getDefaultCompareAttributes';
import { CHECKBOX_ITEM_NAMES } from './compareDrawerConstants';

export function getDefaultCheckedCompareAttributes(
  coverageType: string,
  suppressPremiumCare: boolean,
  suppressPatientReviews: boolean
): string[] {
  const currentMember = getCurrentMember();
  const isCns = currentMember?.isCnSMember;
  const defaultCompareAttribute = getDefaultCompareAttributes(coverageType);
  const attributeList: string[] = [];
  defaultCompareAttribute
    .filter((item) => {
      if (item.name === CHECKBOX_ITEM_NAMES.PREMIUM_CARE_PROVIDER) {
        return !suppressPremiumCare;
      }
      if (item.name === CHECKBOX_ITEM_NAMES.REVIEWS) {
        return !suppressPatientReviews;
      }
      if (item.name === CHECKBOX_ITEM_NAMES.TIER_PROVIDER) {
        return !isCns;
      }
      return true;
    })
    .map((index) => {
      if (index.isChecked === true) {
        attributeList.push(index.name);
      }
    });

  return attributeList;
}
