export const capitalizeFirstLetter = (string) =>
  string?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());
// From Lagoon table the column is coming as string, API expects a boolean.
export const convertTypeStringToBoolean = (str: string) => str?.trim().toLowerCase() === 'true';
// As at Lagoon table, the value is string, so we need to convert it to array of strings to send to API response and it can be . or - when business wants lagoon column not to have any value entered.
const checkForSpecialCase = (str: string) => {
  const trimmedStr = str.trim();
  if (trimmedStr?.includes(',')) {
    return str.split(',');
  } else {
    return trimmedStr === '.' || trimmedStr === '-' ? [] : [trimmedStr];
  }
};
export const convertTypeStringToArray = (str: string) => {
  if (str) {
    return checkForSpecialCase(str);
  }
  return [];
};

