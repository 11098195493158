import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import {
  convertUrlParamsToObject,
  getOnlyDefinedValuesFromObject,
} from '../../common/Utils';
import { SearchState, SearchStore, StoreKeys } from './searchStore';

const store = (set) => ({
  [StoreKeys.SEARCH_STORE]: {},
  [StoreKeys.SET_SEARCH_STORE]: (values: SearchStore) =>
    set((state) => ({
      [StoreKeys.SEARCH_STORE]: { ...state[StoreKeys.SEARCH_STORE], ...values },
    })),
  [StoreKeys.CLEAR_SEARCH_STORE]: (defaultValues = {}) =>
    set({ [StoreKeys.SEARCH_STORE]: { ...defaultValues } }),
});

const searchStore = persist(store, {
  name: 'searchStore',
  storage: createJSONStorage(() => sessionStorage),
  merge: (
    persistedState: SearchState,
    currentState: SearchState
  ): SearchState => {
    const urlParams = new URLSearchParams(window.location.search);
    const {
      totalCount,
      onlineRetailers,
      searchRadius,
      includeOrgTypeCodes,
      excludeOrgTypeCodes,
      requestType,
    }: any = convertUrlParamsToObject(urlParams.entries());

    const values = getOnlyDefinedValuesFromObject({
      totalCount,
      onlineRetailers,
      searchRadius,
      includeOrgTypeCodes,
      excludeOrgTypeCodes,
      requestType,
    });

    return {
      ...currentState,
      ...persistedState,
      searchStore: {
        ...currentState?.searchStore,
        ...persistedState?.searchStore,
        ...values,
      },
    };
  },
}) as any;

export const useSearchStore = create(devtools(searchStore));
