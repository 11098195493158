import { config } from '@abyss/web/tools/config';

import { useCustomQuery } from '../useCustomQuery';
import GetBulkInfo from './BulkInfo.graphql';

export const useHealthgradesGetBulkInfo = (options) =>
  useCustomQuery(GetBulkInfo, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'healthgradesGetBulkInfo',
  });
