import { config } from '@abyss/web/tools/config';
import { Divider } from '@abyss/web/ui/Divider';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { ThemeProvider } from '@abyss/web/ui/ThemeProvider';
import { groupBy } from 'lodash';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { FAQAccordion } from './Accordion/Accordion';
import {
  AccordionButton,
  groupHeading,
  mainGroupHeading,
  subGroupHeading,
} from './FAQPage.style';

export const FAQPage = () => {
  const { t } = useTranslation();
  const lagoonData = useLagoon('stand-alone-faq-page')();
  const groupData = groupBy(lagoonData, 'groupId') || {};
  const FAQ_Image = `${config('CDN_BASE_URL')}/cdn/assets/images/FAQ.png`;
  const [expandAll, setExpandAll] = useState<boolean>(false);
  const [closeAll, setCloseAll] = useState<boolean>(false);

  return (
    <ThemeProvider>
      <Flex css={{ paddingBottom: '48px' }} direction="column">
        <Flex css={{ padding: '16px 16px 0 16px' }}>
          <img src={FAQ_Image}></img>
        </Flex>
        <Flex>
          {Object.keys(groupData).map((key, index) => (
            <Fragment>
              <Flex
                css={{
                  padding: groupData[key][0]?.subcategory
                    ? '$lg $md $md $md !important'
                    : '40px $md $md $md !important',
                }}
              >
                <Heading
                  css={
                    groupData[key][0]?.subcategory
                      ? subGroupHeading
                      : groupData[key][0]?.groupId === '1'
                      ? mainGroupHeading
                      : groupHeading
                  }
                >
                  {groupData[key][0]?.groupName}
                </Heading>
              </Flex>
              {index === 0 ? (
                <Flex
                  alignItems="center"
                  css={{
                    marginBottom: '16px',
                  }}
                  direction="row"
                >
                  <AccordionButton
                    data-testid="expand-all"
                    onClick={() => setExpandAll(true)}
                    variant="outline"
                  >
                    {t('FAQ_PAGE.EXPAND_ALL')}
                  </AccordionButton>
                  <Divider
                    height={32}
                    margin={0}
                    orientation="vertical"
                    width={1}
                  />
                  <AccordionButton
                    data-testid="close-all"
                    onClick={() => setCloseAll(true)}
                    variant="outline"
                  >
                    {t('FAQ_PAGE.CLOSE_ALL')}
                  </AccordionButton>
                </Flex>
              ) : (
                []
              )}

              <Flex>
                <FAQAccordion
                  closeAll={closeAll}
                  content={groupData[key]}
                  expandAll={expandAll}
                  setCloseAll={setCloseAll}
                  setExpandAll={setExpandAll}
                />
              </Flex>
            </Fragment>
          ))}
        </Flex>
      </Flex>
    </ThemeProvider>
  );
};
