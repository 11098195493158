import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import find from 'lodash/find';

import { useLagoon } from '../../../hooks/useLagoon';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import {
  CostHeader,
  CostTopSection,
  InNetworkContainer,
  InNetworkTextContainer,
  SectionText,
} from './Cost.styled';

type Props = {
  inNetwork: string;
};
export const InNetworkSection = ({ inNetwork }: Props) => {
  const data = useLagoon('ui-messaging')();
  const inNetworkText = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.COST_TAB_IN_NETWORK_TEXT,
  });
  const inNetworkHeading = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.COST_TAB_IN_NETWORK_HEADING,
  });

  if (inNetwork === 'In-network') {
    return (
      <CostTopSection>
        <InNetworkContainer alignItems="top">
          <IconMaterial color="$success1" icon="check_circle" />
          <InNetworkTextContainer>
            <Heading
              css={CostHeader}
              data-auto-testid="cost-tab-in-network-heading"
              data-testid="cost-tab-in-network-heading"
              display="h6"
              offset={1}
            >
              {inNetworkHeading?.message ?? ''}
            </Heading>
            <SectionText>{inNetworkText?.message ?? ''}</SectionText>
          </InNetworkTextContainer>
        </InNetworkContainer>
      </CostTopSection>
    );
  }
  return null;
};
