import { Flex } from '@abyss/web/ui/Flex';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { ConstantsLagoon } from '../../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../../common/Utils';
import { useAdobePageTrackEvent } from '../../../hooks/adobeHook/useAdobePageTrackEvent';
import { useLagoon } from '../../../hooks/useLagoon';
import { FacilityDetails } from '../../../models/FacilityDetails';
import { ProviderDetails } from '../../../models/ProviderDetails';
import { useAnalyticsStore } from '../../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../../store/useAnalyticsStore/analyticsStore';
import { useTypeaheadStore } from '../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../store/useTypeaheadStore/typeaheadStore';
import { Constants } from '../../Constants';
import { collapseContainer } from '../../ProviderDetailTabs/About/About.styled';
import {
  convertCoverageType,
  convertProviderTypeToAdobeType,
  convertSpecialtiesList,
  getSearchBlock,
} from '../../Utils/adobeTrackUtils/adobeTrackUtils';
import {
  CollapseProviderDetailsSection,
  ProviderListItem,
} from './CollapseProviderDetailsSection/CollapseProviderDetailsSection';

type Props = {
  providerDetails: FacilityDetails | ProviderDetails;
  tabTitle?: string;
  coverageType: string;
  locationKey?: string;
};

const {
  CULTURAL_COMPLETENCE,
  NATIONAL_PROVIDER_IDENTIFIER,
  PROVIDER_GROUP_ID,
} = Constants.PROVIDER_GROUP_DETAILS.ABOUT_TAB.DETAILS_SECTION;

export const ProviderGroupAbout = ({
  providerDetails,
  tabTitle,
  coverageType,
  locationKey,
}: Props) => {
  const { t } = useTranslation();
  const featureFlags = useLagoon('feature-flags')();
  const enableCost: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.COST_ENABLED
  );
  const isEnableL2CulturalCompetencies = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_L2_CULTURAL_COMPETENCIES
  );
  const notAvailable = [t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')];
  const selectedLocation = providerDetails?.providerLocations?.find(
    (x) => x.locationKey === locationKey
  );
  const { typeaheadState } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      typeaheadState: state.typeaheadSearchStore,
    }))
  );
  const { linkName } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      linkName: state.analyticsState.linkName,
    }))
  );

  const getProviderGroupRole = () => {
    let role = 'group';
    if (selectedLocation?.hasPCPServiceAvailability) role += '|pcp';
    return role;
  };

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: tabTitle,
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `${convertProviderTypeToAdobeType(
      providerDetails.providerType
    )} details`,
    impressionBlock: {
      type: convertProviderTypeToAdobeType(providerDetails.providerType),
      indicator:
        enableCost && selectedLocation?.costIndicator
          ? Constants.ADOBE_TRACKING.COST_ESTIMATE_DISPLAYED
          : '',
    },
    providerBlock: {
      type: convertCoverageType(coverageType),
      specialty:
        convertSpecialtiesList(
          providerDetails?.defaultTranslatedValues?.specialties
        ) || '',
      role: getProviderGroupRole() || '',
    },
    searchBlock: getSearchBlock(typeaheadState, linkName),
  });

  useEffect(() => {
    if (selectedLocation) {
      adobePageTrackEvent();
    }
  }, [selectedLocation !== undefined]);

  const specialities = useMemo(() => {
    if (providerDetails?.specialties?.length)
      return providerDetails.specialties;
    return notAvailable;
  }, providerDetails?.providerLocations);

  const cultComp = isEnableL2CulturalCompetencies
    ? providerDetails?.culturalCompetencyCategories?.map(
        (culturalCompetence) => (
          <React.Fragment>
            <div>{`${culturalCompetence?.category}: ${culturalCompetence?.description}`}</div>
          </React.Fragment>
        )
      )
    : providerDetails?.culturalCompetencies?.map((culturalCompetence) => (
        <React.Fragment>
          <div>{culturalCompetence}</div>
        </React.Fragment>
      ));

  const providerGroupDetails: Array<ProviderListItem> = useMemo(
    () => [
      {
        title: CULTURAL_COMPLETENCE,
        value: cultComp || notAvailable,
        canCopy: false,
        translation: {
          title: t('PROVIDER_GROUP_DETAILS.ABOUT_TAB.CULTURAL_COMPLETENCE'),
        },
      },
      {
        title: NATIONAL_PROVIDER_IDENTIFIER,
        value: providerDetails?.npi || notAvailable,
        canCopy: Boolean(providerDetails?.npi),
        translation: {
          title: t(
            'PROVIDER_GROUP_DETAILS.ABOUT_TAB.NATIONAL_PROVIDER_IDENTIFIER'
          ),
        },
      },
      {
        title: PROVIDER_GROUP_ID,
        value: providerDetails?.providerId || notAvailable,
        canCopy: Boolean(providerDetails?.providerId),
        translation: {
          title: t('PROVIDER_GROUP_DETAILS.ABOUT_TAB.PROVIDER_GROUP_ID'),
        },
      },
    ],
    [providerDetails]
  );

  return (
    <div style={{ overflow: 'auto hidden' }}>
      <Flex css={collapseContainer(false)}>
        <CollapseProviderDetailsSection
          accordionItemValue={'specialities'}
          collapseContent={specialities}
          collapseHeader={t('PROVIDER_GROUP_DETAILS.ABOUT_TAB.SPECIALTIES')}
        />
      </Flex>
      <Flex css={collapseContainer(false)}>
        <CollapseProviderDetailsSection
          accordionItemValue={'groupDetails'}
          collapseHeader={t(
            'PROVIDER_GROUP_DETAILS.ABOUT_TAB.PROVIDER_GROUP_DETAILS_TITLE'
          )}
          providerGroupDetail={providerGroupDetails}
        />
      </Flex>
    </div>
  );
};
