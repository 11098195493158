export const TerminationDateIndicator = {
  border: 'none',
  height: '22px',
  '@screen < $sm': {
    height: '20px',
  },
  justifyContent: 'start',
};

export const TerminationDateIndicatorIcon = {
  height: '16px',
  width: '16px',
  padding: '0px',
};

export const TerminationDateIndicatorText = {
  fontSize: '$sm',
  color: '#BD5000',
  padding: '0px',
  marginLeft: '4px',
  maxWidth: '272px',
  '@screen < $sm': {
    maxWidth: '192px',
    fontSize: '$xs',
  },
};
