import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { convertUrlParamsToObject } from '../../common/Utils';
import { ChipState, StoreKeys, initialState } from './chipStore';

const store = (set) => ({
  ...initialState,
  [StoreKeys.SET_CHIP_VALUE]: (chipValue: string) => set({ chipValue }),
  [StoreKeys.SET_COVERAGE_TYPE]: (coverageType: string) =>
    set({ coverageType }),
  [StoreKeys.SET_LOB]: (lob: string) => set({ lob }),
  [StoreKeys.SET_LOCALE]: (locale: string) => set({ locale }),
  [StoreKeys.SET_OPEN_RECENT_ACTIVITY]: (openRecentActivity: boolean) =>
    set({ openRecentActivity }),
  [StoreKeys.SET_OPEN_SPECIALTY_DRAWER]: (openSpecialtyDrawer: boolean) =>
    set({ openSpecialtyDrawer }),
  [StoreKeys.SET_OPEN_ALL_SPECIALTY_DRAWER]: (
    openAllSpecialtyDrawer: boolean
  ) => set({ openAllSpecialtyDrawer }),
});

const chipStore = persist(store, {
  name: 'chipStore',
  storage: createJSONStorage(() => sessionStorage),
  merge: (persistedState: ChipState, currentState: ChipState): any => {
    const urlParams = new URLSearchParams(window.location.search);
    const { chipValue, coverageType, lob, locale }: any =
      convertUrlParamsToObject(urlParams.entries());

    return {
      ...currentState,
      ...persistedState,
      ...(chipValue && { chipValue }),
      ...(coverageType && { coverageType }),
      ...(lob && { lob }),
      ...(locale && { locale }),
    };
  },
});

export const useChipStore = create(devtools(chipStore));
