import { Button } from '@abyss/web/ui/Button';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import { TextInput } from '@abyss/web/ui/TextInput';
import { useTranslation } from 'react-i18next';

import { ProviderType } from '../../models/ProviderDetails';

type Props = {
  isChecked: boolean;
  page: number;
  providerType: ProviderType;
  setPage: (value: number) => void;
  setChecked: (value: boolean) => void;
};

export const SuggestEditFooter = ({
  isChecked,
  page,
  providerType,
  setPage,
  setChecked,
}: Props) => {
  const { t } = useTranslation();

  const handleBackButton = () => {
    setChecked(false);
    setPage(page - 1);
  };
  return (
    <Flex
      alignItems="flex-start"
      css={{
        'abyss-flex-root': {
          background: '$white',
          gap: '12px',
          padding: '0px $md 32px',
        },
      }}
      direction="column"
    >
      <Flex
        alignItems="flex-start"
        css={{
          'abyss-flex-root': {
            gap: '4px',
            padding: 0,
          },
        }}
        direction="column"
      >
        {page === 1 && (
          <Flex
            alignItems="flex-start"
            css={{
              'abyss-flex-root': {
                gap: '8px',
                padding: 0,
                flexWrap: 'nowrap',
                transform: 'rotate(-0.18deg)',
              },
            }}
            direction="row"
            justify="center"
          >
            <Flex
              alignItems="flex-start"
              css={{
                'abyss-flex-root': {
                  gap: '10px',
                  padding: 0,
                  width: '26px',
                },
              }}
              direction="row"
            >
              <Checkbox
                css={{
                  'abyss-checkbox-root': {
                    width: '26px',
                  },
                }}
                data-auto-testid="notify-provider-details"
                data-testid="notify-provider-details"
                hideLabel
                isChecked={isChecked}
                onChange={(e) => setChecked(e.target.checked)}
              />
            </Flex>
            <Flex
              alignItems="flex-start"
              css={{
                'abyss-flex-root': {
                  gap: '10px',
                  padding: '1px 0px',
                  transform: 'rotate(-0.18deg)',
                },
              }}
              direction="column"
            >
              <Text
                css={{
                  'abyss-text-root': {
                    color: '#6F6F6F',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    fontSize: '14px',
                    lineHeight: '18px',
                    transform: 'rotate(-0.18deg)',
                  },
                }}
              >
                {providerType === ProviderType.PRACTITIONER
                  ? t('SUGGEST_EDIT.PROVIDER_VERIFICATION_TEXT')
                  : t('SUGGEST_EDIT.FACILITY_VERIFICATION_TEXT')}
              </Text>
            </Flex>
          </Flex>
        )}
        {isChecked && page === 1 && (
          <TextInput
            css={{
              'abyss-text-input': {
                paddingLeft: '35px',
              },
            }}
            inputLeftElement={
              <IconMaterial
                color="$gray6"
                icon="email"
                size="15px"
                variant="outlined"
              />
            }
            isClearable
            label={`${t('SUGGEST_EDIT.EMAIL_ADDRESS')}`}
            model="email"
            placeholder="membersemailonfile@gmail.com"
          />
        )}
      </Flex>

      <Flex
        alignItems="center"
        css={{
          'abyss-flex-root': {
            padding: 0,
            alignSelf: 'stretch',
          },
        }}
        direction="row"
        justify={`${page !== 0 ? 'space-between' : 'left'}`}
      >
        {page !== 0 && (
          <Button
            data-auto-testid="suggest-edit-back"
            data-testid="suggest-edit-back"
            onClick={handleBackButton}
            size="$md"
            variant="outline"
          >
            <IconMaterial
              color="$primary1"
              css={{
                'abyss-icon-material': {
                  marginRight: '8px',
                },
              }}
              icon="arrow_back"
              isScreenReadable
              size="16px"
            />
            Back
          </Button>
        )}
        {page === 1 ? (
          <Button size="$md" type="submit">
            Submit my edits
            <IconMaterial
              color="$white"
              css={{
                'abyss-icon-material': {
                  marginLeft: '8px',
                },
              }}
              icon="arrow_forward_ios"
              isScreenReadable
              size="16px"
            />
          </Button>
        ) : (
          <Button
            css={{
              'abyss-button-root': {
                height: '42px',
                paddingLeft: '24px',
                paddingRight: '20px',
              },
            }}
            data-auto-testid="suggest-edit-next"
            data-testid="suggest-edit-next"
            onClick={() => setPage(page + 1)}
          >
            {t('SUGGEST_EDIT.NEXT')}
            <IconMaterial
              color="$white"
              css={{
                'abyss-icon-material': {
                  marginLeft: '8px',
                },
              }}
              icon="arrow_forward"
              isScreenReadable
              size="16px"
            />
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
