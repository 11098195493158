import { useForm } from '@abyss/web/hooks/useForm';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import { t } from 'i18next';
import React from 'react';

import { AllPatientReviewsSortDesktop } from '../../../../../common/AllPatientReviews/AllPatientReviewsSort';
import { phoneOnly } from '../../../../../common/ConstantsStyles';
import { PatientReviewDetailsSection } from '../../../../../common/ProviderDetailTabs/About/PatientReviews/PatientReviewDetailsSection';
import {
  HealthGradesBulkInfo,
  Survey,
} from '../../../../../models/Healthgrades';
import { AllPatientReviewsSortMobile } from '../../../../AllPatientReviews/AllPatientReviewsSortMobile';
import { PatientReviewsSortOrder } from '../../../../AllPatientReviews/AllPatientReviewsSortOrder';
import {
  sortReviewsHighestRating,
  sortReviewsLowestRating,
  sortReviewsMostRecent,
} from '../../../../AllPatientReviews/RatingsSection';
import { PatientVerbatimCard } from '../../../../ProviderDetailTabs/About/PatientReviews/PatientVerbatimCard';
import {
  PaginationComponentStyled,
  RatingSectionContainerStyles,
  TextSubHeadingWhatPatientsAreSaying,
  sortButtonMobileStyles,
  viewAllVerbatimFlexStyles,
} from './RatingsSection.styled';

type Props = {
  healthgradesReview: HealthGradesBulkInfo;
  handleOnClick: () => void;
  setReviewModal: (a: Survey) => void;
  isOpen: boolean;
  setIsOpen: (a: boolean) => void;
  currentPage: number;
  pageIndex: number;
  pageCount: number;
  gotoPage: (a: number) => void;
  pageSize: number;
  setPageSize: (a: number) => void;
  paginationProps: any;
  setSortOrder: (a: PatientReviewsSortOrder) => void;
  sortOrder: PatientReviewsSortOrder;
};
export const RatingsSection = ({
  healthgradesReview,
  handleOnClick,
  setReviewModal,
  isOpen,
  setIsOpen,
  currentPage,
  pageIndex,
  pageCount,
  gotoPage,
  pageSize,
  setPageSize,
  paginationProps,
  setSortOrder,
  sortOrder,
}: Props) => {
  const form = useForm({
    defaultValues: {
      'dropdown-menu-radios': 10,
    },
  });

  const mobileScreen = useMediaQuery(phoneOnly);
  const sortFn: (a: Survey, b: Survey) => number = (() => {
    if (sortOrder === PatientReviewsSortOrder.MOST_RECENT)
      return sortReviewsMostRecent;
    if (sortOrder === PatientReviewsSortOrder.HIGHEST_RATING)
      return sortReviewsHighestRating;
    return sortReviewsLowestRating;
  })();

  const sortedReviews: Survey[] = (healthgradesReview?.surveys || []).sort(
    sortFn
  );

  return (
    <div style={RatingSectionContainerStyles}>
      {healthgradesReview ? (
        <div>
          <PatientReviewDetailsSection
            healthgradesReview={healthgradesReview}
          />
          {healthgradesReview?.surveys?.length ? (
            <>
              <Flex
                alignItems="center"
                css={{ marginTop: '16px' }}
                direction="row"
                justify="space-between"
              >
                <TextSubHeadingWhatPatientsAreSaying>
                  {t(
                    'PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.WHAT_PATIENTS_ARE_SAYING'
                  )}
                </TextSubHeadingWhatPatientsAreSaying>

                {!mobileScreen ? (
                  <AllPatientReviewsSortDesktop
                    handleOnClick={handleOnClick}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                    setSortOrder={setSortOrder}
                    sortOrder={sortOrder}
                  />
                ) : (
                  <div>
                    <Button
                      before={<IconMaterial icon="swap_vert" />}
                      css={sortButtonMobileStyles}
                      data-auto-testid="result-sort-mobile"
                      data-testid="result-sort-mobile"
                      onClick={handleOnClick}
                      variant="outline"
                    >
                      <Text color="$primary1" fontWeight={600}>
                        {t('Sort')}
                      </Text>
                    </Button>
                    <AllPatientReviewsSortMobile
                      isOpen={isOpen}
                      onApply={setIsOpen}
                      setIsOpen={setIsOpen}
                      setSortOrder={setSortOrder}
                      sortOrder={sortOrder}
                    />
                  </div>
                )}
              </Flex>
              <Flex
                alignItems="flex-end"
                css={{
                  marginBottom: '0px',
                }}
                direction="row"
                justify="space-between"
              >
                <div>
                  <Flex alignItems="center" css={{ gap: '$sm' }}>
                    <FormProvider
                      data-auto-testid="set-page-size-reviews"
                      data-testid="set-page-size-reviews"
                      onSubmit={setPageSize}
                      state={form}
                    />
                  </Flex>
                </div>
              </Flex>

              <Flex css={viewAllVerbatimFlexStyles}>
                {sortedReviews
                  .slice(pageIndex * pageSize, currentPage * pageSize)
                  .map((survey) => (
                    <PatientVerbatimCard
                      key={survey.id}
                      setReviewModal={setReviewModal}
                      survey={survey}
                    />
                  ))}
              </Flex>
              {pageCount >= 1 && (
                <PaginationComponentStyled
                  {...paginationProps}
                  data-auto-testid="pagination-component-mobile"
                  data-testid="pagination-component-mobile"
                  gotoPage={gotoPage}
                  pageSize={pageSize}
                  variant="extended"
                />
              )}
            </>
          ) : null}
        </div>
      ) : (
        <Text>
          {t('PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.NO_REVIEWS_FOUND')}
        </Text>
      )}
    </div>
  );
};
