import isEmpty from 'lodash/isEmpty';

import { ProviderType } from '../models/ProviderDetails';

export const formatProviderNameWithFirstMiddleLastName = (provider: any) =>
  [provider?.firstName, provider?.middleName, provider?.lastName]
    .filter((item) => !isEmpty(item) && item?.trim()?.length > 0)
    .map((item) => item.trim())
    .join(' ');

export const getNameAttrs = (provider) => ({
  firstName: provider.firstName,
  middleName: provider.middleName,
  lastName: provider.lastName,
});

export const parseProviderName = (
  providerName: string,
  providerType: ProviderType,
  primaryDegree?: string,
  nameAttrs?
): string => {
  if (providerType === ProviderType.PRACTITIONER) {
    return `${formatProviderNameWithFirstMiddleLastName(nameAttrs)}${
      primaryDegree ? `, ${primaryDegree}` : ''
    }`;
  }

  if (providerName || providerType === ProviderType.MEDICAL_GROUP) {
    return providerName;
  }

  return '';
};

/**
 * @desc this function will captalize All first characters of words inside string
 * @param words String which needs to be capitalized
 * @returns Words
 */
export function capitalizeAllFirstCharacters(words) {
  return words?.replace(/(^\w|\s\w)/g, (w) => w.toUpperCase());
}

/**
 * @desc This function will capitalize first letter or string/word
 * @param words String which needs to be capitalized first character
 * @returns words
 */
export function capitalizeFirstLetters(words) {
  return words?.replaceAll(
    /\S*/g,
    (word) => `${word.slice(0, 1).toUpperCase()}${word.slice(1).toLowerCase()}`
  );
}

/**
 *
 * @param words String which needs to be make first words as a small character
 * @returns words
 */
export function capitalizeEachWord(words) {
  return words?.replaceAll(
    /\S*/g,
    (word) => `${word.slice(0, 1)}${word.slice(1).toLowerCase()}`
  );
}
