import { useRouter } from '@abyss/web/hooks/useRouter';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import { t } from 'i18next';

export const BackButton = () => {
  const { navigate } = useRouter();
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <Link
      css={{
        marginBottom: '12px',
      }}
      data-auto-testid="back-to-provider-details"
      data-testid="back-to-provider-details"
      href="#"
      isStandardAnchor
      onClick={handleGoBack}
    >
      <IconMaterial
        color="$interactive1"
        css={{ width: '24px', height: '24px', marginRight: '4px' }}
        icon="arrow_back"
      />
      <Text color="$interactive1" fontWeight="$bold">
        {t('BACK')}
      </Text>
    </Link>
  );
};
