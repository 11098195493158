import { config } from '@abyss/web/tools/config';

import { useCustomQuery } from '../useCustomQuery';
import GET_PF_ENCRYPTED_PAYLOAD from './PingFederateEncryptedPayload.graphql';

export const usePingFederateEncryptedPayload = (options) =>
  useCustomQuery(GET_PF_ENCRYPTED_PAYLOAD, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    requestPolicy: 'no-cache',
    accessor: 'pingFederate',
  });
