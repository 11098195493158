import { config } from '@abyss/web/tools/config';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { useContext } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { Constants } from '../../../../common/Constants';
import { PortalContext } from '../../../../context/PortalContext';
import { LagoonProvider } from '../../../../lagoon';
import { getLOBTargetInCookies } from '../../../../lagoon/lagoonUtil';
import { IChildrenProp } from '../../types';
import { getLanguage } from '../Internationalization/helpers';

const lagoonEnvironment = config('LAGOON_ENV');

const parsedApp = (lineOfBusiness) => {
  switch (lineOfBusiness?.toLowerCase()) {
    case 'e&i':
      return 'psx-web';
    case 'm&r':
      return 'psx-web-mnr';
    case 'obh':
      return 'psx-web-obh';
    case 'c&s':
      return 'psx-web-cns';
    case 'ifp':
      return 'psx-web-ifp';
    default:
      return '';
  }
};

const parsedLob = (lineOfBusiness) => {
  switch (lineOfBusiness?.toLowerCase()) {
    case 'e&i':
      return 'eni';
    case 'm&r':
      return 'mnr';
    case 'c&s':
      return 'cns';
    case 'ifp':
      return 'ifp';
    case 'obh':
      return 'obh';
    default:
      return '';
  }
};

const mapPortalToLob = (portalName) => {
  switch (portalName?.toLowerCase()) {
    case 'laww':
      return 'obh';
    default:
      return '';
  }
};

export const Configuration = ({ children }: IChildrenProp) => {
  const localeCode = getLanguage()?.code || 'en';

  const [lob] = useSessionStorage<string>(
    Constants.STORAGE_KEYS.SESSION.LOB,
    ''
  );
  const { portalData } = useContext(PortalContext);
  const portalLob = mapPortalToLob(portalData?.portalName);
  // Reading lob from session storage when lob has been changed from demoheader - on LOWER ENVIRONMENTS. For higher environments, it will be picked from cookie LOB-Target which is set when user logs in via uhc.
  const targetLob = portalLob || lob || getLOBTargetInCookies();

  return (
    <LagoonProvider
      app={parsedApp(targetLob)}
      env={lagoonEnvironment}
      loadingIcon={
        <LoadingSpinner ariaLoadingLabel="Importing data" isLoading />
      }
      lob={parsedLob(targetLob)}
      locale={localeCode}
    >
      {children}
    </LagoonProvider>
  );
};
