import { styled } from '@abyss/web/tools/styled';
export const ThingsToConsiderModalStyle = {
  'abyss-modal-content-container': {
    width: '784px',
    height: '586px',
  },
  'abyss-modal-content-title': {
    fontSize: '28.83px !important',
    fontWeight: '600 !important',
    lineHeight: '36px !important',
    textAlign: 'center',
    marginTop: '100px',
  },
  'abyss-modal-footer': {
    marginTop: '65px',
    marginLeft: '-55px',
  },
  'abyss-modal-body': {
    overflowY: 'visible',
  },
};

export const ThingsToConsiderMobileModalStyle = {
  'abyss-modal-content-container': {
    width: '343px',
    height: '726px',
    overflow: 'hidden',
  },
  'abyss-modal-content-title': {
    fontSize: '28.83px !important',
    fontWeight: '600 !important',
    lineHeight: '36px !important',
    textAlign: 'center',
    marginTop: '85px',
  },
  'abyss-modal-body': {
    overflowY: 'visible',
  },
  'abyss-modal-footer': {
    marginTop: '220px',
    marginLeft: '-15px',
  },
};

export const ModalContent = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: '8px 16px',
  marginTop: '25px',
  height: '236px',
});

export const MedicalAvatar = styled('div', {
  width: '70px',
  height: '70px',
  borderRadius: '50%',
  position: 'absolute',
  top: '30px',
  marginTop: '8px',
});

export const ModalSubTitle = styled('div', {
  fontSize: '18px',
  fontWeight: '400',
  lineHeight: '24px',
  textAlign: 'center',
  marginTop: '-50px',
  color: '$gray7',
});

export const ModalHeading = styled('div', {
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '24px',
  textAlign: 'left',
  margin: '10px 16px 10px 16px',
});

export const ModalDataContent = styled('div', {
  margin: '0 16px',
  fontSize: '18px',
  fontWeight: '400',
  color: '$gray7',
  lineHeight: '24px',
  padding: '0px 0px 24px 0px',
});
