import { Carousel, Slide } from '@abyss/web/ui/Carousel';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useSessionStorage } from 'usehooks-ts';

import { ResponseHeaders } from '../../models/ResponseHeaders';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../Constants';
import { DataCardContainer } from '../DataCard/DataCardContainer';
import { DataCardStyle } from '../ResultSection/ResultSection.style';
import { MapCarousel } from './MapDisplay.styled';

type Data = {
  geometry?: Geomentry;
  id?: string;
  properties?: Properties;
};
type Geomentry = {
  coordinates?: string[];
  type?: string;
};
type Properties = {
  description: string;
  providerId: string;
  type: string;
};
type CarouselProps = {
  data: Data[];
  disableCost?: boolean;
  headers?: ResponseHeaders;
  selectLocation?: (locationId, locationLng, locationLat) => void;
  sectionType?: string;
  search?: string;
};

export const ClusterCarousel = ({
  data,
  disableCost,
  headers,
  selectLocation,
  sectionType,
  search,
}: CarouselProps) => {
  const [popupContainer, setPopupContainer] = useState<any>(null);
  const [currentIndex, setCurrentindex] = useState(-1);
  const [, setHighlightId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_HIGHLIGHT_ID,
    { providerId: '', from: '' }
  );

  const handleHiglightId = (index: number) => {
    if (currentIndex >= 1 && data.length && index <= data.length) {
      const currentItem = data[index - 1];
      const selectedProviderId = JSON.parse(
        currentItem.properties.description
      ).providerId;

      setHighlightId({ providerId: selectedProviderId, from: 'mapView' });
    }
  };
  useEffect(() => {
    if (data) {
      const rootElem = document.querySelector('.datacard-popup');
      if (rootElem) {
        (rootElem as HTMLElement).style.height = 'fit-content';
        (rootElem as HTMLElement).style.width = 'fit-content';
        (rootElem as HTMLElement).style.visibility = 'visible';
      }

      handleHiglightId(1);
      setCurrentindex(1);
      setPopupContainer(rootElem);
    }
  }, [data]);

  useEffect(() => {
    handleHiglightId(currentIndex);
  }, [currentIndex]);

  const handlecurrentIndex = (type: string, index?: number) => {
    adobeLinkTrackEvent({
      name: type,
      location: `body:map:${sectionType} results for ${search} card`,
    });
    if (type === 'nextslide') {
      if (currentIndex < data.length) {
        setCurrentindex((prev) => prev + 1);
      }
    } else if (type === 'prevslide') {
      setCurrentindex((prev) => prev - 1);
    } else {
      setCurrentindex(index + 1);
    }
  };

  const handleDirectionClick = (provider) => {
    if (selectLocation)
      selectLocation(
        provider.locationId,
        provider.longitude,
        provider.latitude
      );
  };
  const slides = data?.map((item: any) => (
    <React.Fragment key={`mapcarousal${item.id}`}>
      <Slide>
        <DataCardContainer
          css={DataCardStyle}
          disableCost={disableCost}
          handleDirectionClick={() =>
            handleDirectionClick(JSON.parse(item.properties.description || {}))
          }
          headers={headers}
          practitioner={JSON.parse(item.properties.description || {})}
          usedAt="Mapview"
        />
      </Slide>
    </React.Fragment>
  ));
  return (
    <React.Fragment>
      {popupContainer !== null &&
        createPortal(
          <MapCarousel>
            <Carousel
              data-auto-testid="map-cluster-carousel"
              data-testid="map-cluster-carousel"
              minimal
              nextSlideOnClick={() => handlecurrentIndex('nextslide')}
              noLoop
              prevSlideOnClick={() => handlecurrentIndex('prevslide')}
              slideIndexOnClick={(index) => {
                handlecurrentIndex('slideindex', index);
              }}
              slides={slides}
            />
          </MapCarousel>,
          popupContainer
        )}
    </React.Fragment>
  );
};
