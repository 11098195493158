export interface ErrorState {
  message?: string;
  code?: string;
  source?: string;
  uiSection?: string;
}

export interface ErrorStore {
  errorState: ErrorState;
  setErrorStore: (values: ErrorState) => void;
  clearErrorStore: (defaultValues?: ErrorState) => void;
}

export enum StoreKeys {
  MESSAGE = 'message',
  CODE = 'code',
  SOURCE = 'source',
  UI_SECTION = 'uiSection',
  ERROR_STATE = 'errorState',
  SET_ERROR_STORE = 'setErrorStore',
  CLEAR_ERROR_STORE = 'clearErrorStore',
}