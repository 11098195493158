import { styled } from '@abyss/web/tools/styled';
import { Popover } from '@abyss/web/ui/Popover';

export const PremiumCareDrawerLinkStyle = {
  fontSize: '16px',
  'abyss-link-root': {
    marginTop: '16px',
    lineHeight: '20px',
    fontWeight: '700',
    padding: '8px 0',
  },
  'abyss-link-icon-wrapper': {
    marginLeft: '4px',
  },
  'abyss-link-icon': {
    height: '24px',
    width: '24px',
  },
};

export const PremiumCareDrawerStyles = {
  'abyss-modal-content-container': {
    borderRadius: '20px 20px 0 0',
    height: 'auto !important',
  },
  'abyss-modal-body': {
    margin: '16px 24px 16px 24px',
    color: '$gray8',
    whiteSpace: 'pre-line',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
  },
  'abyss-modal-close-button': { margin: '8px 10px 0 0' },
  'abyss-modal-content-title': {
    paddingTop: '0px',
    fontFamily: '$primary !important',
    fontSize: '20.25px !important',
    color: '#323334 !important',
    lineHeight: '24px !important',
    fontWeight: '700 !important',
  },
  'abyss-modal-header-container': {
    padding: '16px 24px 0px 24px',
  },
};

export const PremiumCarePopoverLinkStyle = {
  fontSize: '16px',
  'abyss-link-root': { lineHeight: '20px', fontWeight: '700', margin: '4px 0' },
  'abyss-link-icon-wrapper': {
    marginLeft: '4px',
  },
  'abyss-link-icon': {
    height: '24px',
    width: '24px',
  },
};

export const PremiumCareSnackCardPopoverStyles = styled(Popover, {
  display: 'flex',
  dynamic: ({ cssProps: { landingPage } }) => ({
    '&.abyss-popover-trigger': {
      marginRight: 'auto',
      marginLeft: landingPage ? 'auto' : '12px',
    },
  }),
  '.abyss-popover-content-container': {
    whiteSpace: 'pre-line',
    color: '$gray8',
  },
  '&.abyss-popover-content': {
    borderTop: 0,
    borderBottom: 0,
  },
});

export const PremiumInfoBox = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px 8px 0px 0px',
  backgroundColor: '$info2',
  height: '24px',
  padding: ' 4px $sm ',
  minWidth: '120px',
  width: 'fit-content',
  fontWeight: '700',
  dynamic: ({ cssProps: { landingPage } }) => ({
    '@screen < $sm': {
      marginRight: 'auto',
      marginLeft: landingPage ? 'auto' : '12px',
    },
  }),
});

export const PremiumPopoverText = styled('div', {
  marginBottom: '8px',
});
