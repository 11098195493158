import { styled } from '@abyss/web/tools/styled';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { Text } from '@abyss/web/ui/Text';

export const NetworkCheckbox = styled(Checkbox, {
  '&.abyss-checkbox-label': {
    fontSize: '$sm',
    lineHeight: '18px',
    color: '$gray8',
    fontWeight: '$medium',
  },
});

export const NetworkCheckboxTitle = styled('div', {
  fontWeight: '$bold',
  fontSize: '$md',
  color: '#424242',
});

export const LocationTitle = styled('div', {
  fontWeight: '500',
  fontSize: '$md',
  color: '#4B4D4F',
});

export const TextInputStyled = styled('input', {
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: '4px',
  width: '100%',
  fontSize: '$sm',
  backgroundColor: '$white',
  borderColor: '$gray6',
  lineHeight: '20px',
  appearance: 'none',
  resize: 'none',
  paddingLeft: '$sm',
  minHeight: '36px',
  color: '$neutralGray',
});
export const LabelStyled = styled(Text, {
  '&.abyss-text-root': {
    fontHeight: '20px',
    fontWeight: '$bold',
    fontSize: '14.22px',
    paddingBottom: '$xs',
    color: '$neutralGray7',
  },
});
export const DayLabelStyled = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '14.22px',
    color: '$neutralGray7',
  },
});

export const TextInputContainer = styled('div', {
  width: '100%',
});
