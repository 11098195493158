import { Layout } from '@abyss/web/ui/Layout';
import find from 'lodash/find';
import React from 'react';

import { useLagoon } from '../../hooks/useLagoon';
import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { CareCategories, Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ContentWrapper } from '../ContentWrapper';
import { DisclaimerContainer } from '../ProviderDetailTabs/ProviderDetailTabs.styled';
import {
  HealthGradesStyled,
  StyledMarkdown,
} from './DisclaimersContainer.styled';
import { HealthGradesDisclaimers } from './HealthGradesDisclaimers';

interface Props {
  showPremiumCareDisclaimer?: boolean;
  ratingSection?: boolean;
  selectedChipValue?: string;
  isViewAllDisclaimer?: boolean;
  isLandingPage?: boolean;
  search?: string;
  sectionType?: string;
}

export const MNRDisclaimersContainer = ({
  ratingSection = false,
  selectedChipValue,
  isViewAllDisclaimer,
  isLandingPage,
  sectionType,
  search,
}: Props) => {
  const lagoonData = useLagoon('ui-messaging')();
  const isWidget = useStore(StoreKeys.IS_WIDGET);

  const modalDisclaimerFull = find(lagoonData, {
    key: ConstantsLagoon.HEALTHGRADES.MODAL_DISCLAIMER_FULL,
  });

  const dentalMnrDisclaimer = find(lagoonData, {
    key: ConstantsLagoon.MNR_DISCLAIMER.DENTAL_MNR_DISCLAIMER,
  });

  const medicalFacilityMnrDisclaimer = find(lagoonData, {
    key: ConstantsLagoon.MNR_DISCLAIMER.MEDICAL_FACILITY_MNR_DISCLAIMER,
  });

  const visionMnrDisclaimer = find(lagoonData, {
    key: ConstantsLagoon.MNR_DISCLAIMER.VISION_MNR_DISCLAIMER,
  });

  const healthGradeMnrDisclaimerContent = find(lagoonData, {
    key: ConstantsLagoon.MNR_DISCLAIMER.HEALTH_GRADES_MNR_DISCLAIMER_CONTENT,
  });

  const directoryMnrDisclaimer = find(lagoonData, {
    key: ConstantsLagoon.MNR_DISCLAIMER.DIRECTORY_MNR_DISCLAIMER,
  });

  const renderMnrDisclaimer = () => {
    switch (selectedChipValue) {
      case CareCategories.DENTAL:
        return dentalMnrDisclaimer?.message;
      case CareCategories.MEDICAL_SPECIALISTS:
      case CareCategories.FACILITIES:
        return medicalFacilityMnrDisclaimer?.message;
      case CareCategories.VISION:
        return visionMnrDisclaimer?.message;
      case CareCategories.ALL:
        return directoryMnrDisclaimer?.message;
      default:
        return '';
    }
  };

  const renderLandingPageDisclaimer = () => {
    switch (selectedChipValue) {
      case CareCategories.ALL:
        return directoryMnrDisclaimer?.message;
      default:
        return '';
    }
  };

  const renderHealthGradesDisclaimer = () => {
    const renderDisclaimers = () => (
      <HealthGradesDisclaimers
        disclaimerContent={healthGradeMnrDisclaimerContent?.message}
        modalContent={modalDisclaimerFull?.message}
      />
    );

    const isFacilitySection = sectionType === Constants.FACILITY;
    const isOnlineProviderSearch =
      search?.toLocaleLowerCase() ===
      Constants.ONLINE_PROVIDERS.toLocaleLowerCase();

    const isSelectedChipValueInCategories = [
      CareCategories.FACILITIES,
      CareCategories.HEALTH_SUPPLIES,
      CareCategories.ALL,
    ].includes(selectedChipValue as CareCategories);

    if (isFacilitySection && isOnlineProviderSearch) return renderDisclaimers();
    if (isFacilitySection || isSelectedChipValueInCategories) return '';

    return renderDisclaimers();
  };

  return (
    <DisclaimerContainer cssProps={{ ratingSection }}>
      <HealthGradesStyled
        cssProps={{ isWidget }}
        data-auto-testid="standard-disclaimer-content"
        data-testid="standard-disclaimer-content-for-mnr"
      >
        <ContentWrapper isWidget={isWidget}>
          <Layout.Stack alignItems="left" space="0">
            {isViewAllDisclaimer ? (
              <StyledMarkdown data-testid="viewall-mnr-standard-disclaimer">
                {renderMnrDisclaimer()}
              </StyledMarkdown>
            ) : (
              ''
            )}

            {isLandingPage ? (
              <StyledMarkdown data-testid="landing-mnr-standard-disclaimer">
                {renderLandingPageDisclaimer()}
              </StyledMarkdown>
            ) : (
              ''
            )}
            {renderHealthGradesDisclaimer()}
          </Layout.Stack>
        </ContentWrapper>
      </HealthGradesStyled>
    </DisclaimerContainer>
  );
};
