import { Drawer } from '@abyss/web/ui/Drawer';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { getMemberHealthCoverageType } from '../../utils/providerSearch.utils';
import { getCurrentMember } from '../../utils/user.utils';
import { CoverageTypesCodes } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { landingDrawerIcon } from '../ConstantsStyles';
import { dateFormatToDate } from '../Utils/datesUtils/formatDate';

export const SchedulePopover = ({ scheduleText, mobileScreen }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const data = useLagoon('ui-messaging')();
  const popoverContent = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS
      .PRE_EFFECTIVE_ONLINE_SCHEDULE_POPOVER_CONTENT,
  });
  const currentMember = getCurrentMember();
  const medicalCoverage = getMemberHealthCoverageType(
    currentMember?.eligibility,
    CoverageTypesCodes.MEDICAL
  );
  return (
    <React.Fragment>
      {!mobileScreen ? (
        <Popover
          aria-label={`${t('INFORMATION_ABOUT')} ${popoverContent?.title}`}
          content={`${popoverContent?.message}${dateFormatToDate(
            medicalCoverage?.coverageEffDateRange?.startDate
          )}.`}
          css={{
            'abyss-popover-content': {
              zIndex: '9',
              width: '220px',
              height: 'auto',
            },
            'abyss-popover-trigger': {
              marginTop: '3px',
            },
          }}
          data-auto-testid="data-card-provider-scheduling-popover"
          data-testid="data-card-provider-scheduling-popover"
          onOpenChange={() => {
            setIsOpen(!isOpen);
          }}
          open={isOpen}
          title={popoverContent?.title}
        >
          {scheduleText}

          <IconMaterial
            css={{ marginLeft: '4px', marginBottom: '-4px' }}
            data-auto-testid="data-card-provider-scheduling-popover"
            data-testid="data-card-provider-scheduling-popover"
            icon="info"
            onClick={() => setIsOpen(!isOpen)}
            size={18}
            variant="outlined"
          />
        </Popover>
      ) : (
        <React.Fragment>
          <Drawer
            css={landingDrawerIcon}
            data-auto-testid="data-card-provider-scheduling-popover"
            data-testid="data-card-provider-scheduling-popover"
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            position="bottom"
            title={popoverContent?.title}
            titleAlign="left"
          >
            {`${popoverContent?.message}${dateFormatToDate(
              medicalCoverage?.coverageEffDateRange?.startDate
            )}.`}
          </Drawer>
          <Layout.Group>
            <Layout.Group>
              <Text
                css={{ marginBottom: '4px' }}
                data-auto-testid="data-card-schedule-text"
                data-testid="data-card-schedule-text"
                onClick={() => setIsOpen(true)}
              >
                {scheduleText}
              </Text>
            </Layout.Group>
            <IconMaterial
              css={{ marginBottom: '2px', marginLeft: '-4px' }}
              data-auto-testid="data-card-provider-scheduling-popover"
              data-testid="data-card-provider-scheduling-popover"
              icon="info"
              onClick={() => setIsOpen(true)}
              size={16}
              variant="outlined"
            />
          </Layout.Group>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
