export const getLagoonValueByKey = <
  T extends { key: string; heading: string; drawer: string }
>(
  tableNameParam: T[] = [],
  key: string = '',
  colName: string = ''
) => {
  if (!key) {
    return '';
  }
  return tableNameParam?.find((e) => e.key === key)?.[colName] ?? '';
};
