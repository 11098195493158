import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import { Pagination, ResultCount } from '@abyss/web/ui/Pagination';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ChoosePCPAlert } from '../../../../common/Alert/ChoosePCPAlert';
import {
  Constants,
  CoverageTypesCodes,
  ReverseCoverageTypesCodes,
} from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { FilterButtons } from '../../../../common/ContentFilters/FilterButtons';
import { DataCardContainer } from '../../../../common/DataCard/DataCardContainer';
import { EAPCode } from '../../../../common/EAPCode';
import { getFeatureFlag } from '../../../../common/Utils';
import { useCoverageType } from '../../../../hooks/useCoverageType';
import { useLagoon } from '../../../../hooks/useLagoon';
import { CompareProvider } from '../../../../models/Provider';
import { ResponseHeaders } from '../../../../models/ResponseHeaders';
import { AllPaginationProps } from '../MapView/AllPaginationProps';
import { DataCardList, DataCardListStyled } from '../MapView/DataCardList';

const EAPStyle = styled('div', {
  padding: '4px 0 12px 0',
});

const TitleContainer = styled('div', {
  alignItems: 'center',
});

const ListViewWrapper = styled('div', {
  dynamic: ({ cssProps: { openMapview, navigateToDirections } }) => ({
    display: openMapview || navigateToDirections ? 'none' : 'block',
  }),
});

type Props = {
  headers: ResponseHeaders;
  openCompare?: boolean;
  isLoading?: boolean;
  openShare?: boolean;
  pageSize?: number;
  paginationProps?: any;
  gotoPage?: any;
  nextPage?: any;
  previousPage?: any;
  results?: any[];
  search?: string | null;
  searchTerm?: string;
  sectionType?: string;
  selectedCheckbox?: any;
  totalResultsCount?: number;
  addSelectedProvider: (a: any) => void;
  selectedFilters?: string;
  setOpenCompare: (a: boolean) => void;
  setOpenMapview: (a: boolean) => void;
  setOpenShare: (a: boolean) => void;
  setSelectedCheckbox: (a: { checked: {} }) => void;
  setSelectedItems: (a: CompareProvider[]) => void;
  allPaginationProps: AllPaginationProps;
  navigateToDirections?: boolean;
  setNavigateToDirections?: (a: boolean) => void;
  map: any;
  openMapview: boolean;
  mobileRouteView?: boolean;
  selectLocation?: (locationId, locationLng, locationLat) => void;
  isMNR?: boolean;
  setOpenBoxContents: (a: boolean) => void;
};

export const MobileListView = ({
  headers,
  openCompare = false,
  openShare = false,
  isLoading = false,
  pageSize = 10,
  paginationProps,
  results = [],
  search,
  sectionType,
  selectedCheckbox = { checked: {} },
  totalResultsCount = 0,
  gotoPage,
  nextPage,
  previousPage,
  addSelectedProvider,
  selectedFilters,
  setOpenCompare,
  setOpenShare,
  setOpenMapview,
  setSelectedCheckbox,
  setSelectedItems,
  allPaginationProps,
  searchTerm,
  navigateToDirections = false,
  setNavigateToDirections,
  map,
  openMapview = false,
  mobileRouteView = false,
  selectLocation,
  isMNR,
  setOpenBoxContents,
}: Props) => {
  const { t } = useTranslation();
  const pageHeader = `${t(`${sectionType}-results-for`)} ${
    t(`${search}`) || ''
  }`;

  const { pageNumber } = allPaginationProps;
  const coverageType = useCoverageType();
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const eapCodeFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.EAP_CODE
  );
  const isBHCare: boolean =
    coverageType ===
    ReverseCoverageTypesCodes[CoverageTypesCodes.BEHAVIORAL_HEALTH];

  useEffect(() => {
    if (openMapview && map?.current) map.current.resize();
  }, [openMapview]);

  return (
    <ListViewWrapper
      cssProps={{
        openMapview,
        navigateToDirections,
      }}
    >
      <ChoosePCPAlert isMapView />
      <TitleContainer>
        <Heading
          css={{
            '&.abyss-heading-root': {
              fontSize: '$mediumText',
              lineHeight: '$lg',
            },
          }}
          data-auto-testid="results-heading"
          data-testid="results-heading"
          offset={3}
          textAlign="left"
        >
          {pageHeader}
        </Heading>
      </TitleContainer>
      <FilterButtons
        openCompare={openCompare}
        openShare={openShare}
        sectionType={sectionType || ''}
        setOpenBoxContents={setOpenBoxContents}
        setOpenCompare={setOpenCompare}
        setOpenMapview={setOpenMapview}
        setOpenShare={setOpenShare}
        setSelectedCheckbox={setSelectedCheckbox}
        setSelectedItems={setSelectedItems}
      />
      <Layout.Stack alignItems="left" grow="true">
        {eapCodeFlag && isBHCare ? (
          <EAPStyle>
            <EAPCode />
          </EAPStyle>
        ) : null}
        {isLoading ? (
          <DataCardListStyled
            data-auto-testid="skeleton-wrapper"
            data-testid="skeleton-wrapper"
          >
            {Array.from(Array(10).keys()).map((item) => (
              <DataCardContainer
                fromMobileListView
                isLoading={isLoading}
                key={item}
              />
            ))}
          </DataCardListStyled>
        ) : (
          <React.Fragment>
            <ResultCount
              {...paginationProps}
              css={{
                'abyss-pagination-results-root': {
                  fontWeight: '$semibold',
                },
              }}
              customNoResultsLabel={t('PAGINATION.NO_RESULTS_LABEL')}
              customOfLabel={t('PAGINATION.OF_LABEL')}
              customResultLabel={t('PAGINATION.ONE_RESULT_LABEL')}
              customResultsLabel={t('PAGINATION.RESULTS_LABEL')}
              customShowingLabel={t('PAGINATION.SHOWING_LABEL')}
              pageSize={pageSize}
              resultsTotalCount={totalResultsCount}
            />
            <DataCardList
              addSelectedProvider={addSelectedProvider}
              fromMobileListView
              headers={headers}
              isLoading={isLoading}
              isMNR={isMNR}
              locationForAnalytics={`${sectionType} results for ${search}`}
              map={map}
              mobileRouteView={mobileRouteView}
              navigateToDirections={navigateToDirections}
              pageNumber={pageNumber}
              providerResults={results}
              searchTerm={searchTerm}
              sectionType={sectionType || ''}
              selectLocation={selectLocation}
              selectedCheckbox={selectedCheckbox}
              selectedFilters={selectedFilters}
              setNavigateToDirections={setNavigateToDirections}
              setSelectedCheckbox={setSelectedCheckbox}
              showCheckboxes={openShare || openCompare}
              updatePin={() => null}
              usedAt={Constants.MOBILE_LIST_VIEW}
            />
          </React.Fragment>
        )}
        <Layout.Space space={20} />
        <Flex alignItems="center" css={{ gap: '21px' }} direction="column">
          <Pagination
            {...paginationProps}
            css={{
              'abyss-pagination-page-button': {
                minWidth: '20px',
              },
              'abyss-pagination-arrow': {
                paddingLeft: '4px',
                paddingRight: '4px',
              },
            }}
            customNoResultsLabel={t('PAGINATION.NO_RESULTS_LABEL')}
            customOfLabel={t('PAGINATION.OF_LABEL')}
            customResultLabel={t('PAGINATION.ONE_RESULT_LABEL')}
            customResultsLabel={t('PAGINATION.RESULTS_LABEL')}
            customShowingLabel={t('PAGINATION.SHOWING_LABEL')}
            data-auto-testid="pagination-component"
            data-testid="pagination-component"
            gotoPage={gotoPage}
            nextPage={nextPage}
            pageSize={pageSize}
            previousPage={previousPage}
          />
        </Flex>
      </Layout.Stack>
    </ListViewWrapper>
  );
};
