import { Drawer } from '@abyss/web/ui/Drawer';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { FacilityLocationsResponse } from '../../../models/FacilityDetails';
import { ProviderLocation } from '../../../models/ProviderDetails';
import { LocationsContentForPopOverDesktop } from './LocationsContentForPopOverDesktop';
import { LocationsDrawerHeaderMobile } from './ProviderLocationsDetails.style';

type Props = {
  setSelectedLocation: (a: any, b: any) => void;
  selectedLocation: any;
  locationsDetails: ProviderLocation[] | FacilityLocationsResponse[];
  setIsOpen: (a: boolean) => void;
  selectedIndex: number;
  isOpen: boolean;
  mobileScreen: boolean;
};

export const LocationsContentForPopOver = ({
  setSelectedLocation,
  selectedLocation,
  locationsDetails,
  setIsOpen,
  selectedIndex,
  isOpen,
  mobileScreen,
}: Props) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {mobileScreen ? (
        <Drawer
          css={{
            'abyss-modal-close-button': {
              marginTop: '4px',
              marginRight: '16px',
            },
            'abyss-modal-header-container': {
              padding: '16px',
            },
            'abyss-modal-content-container': {
              borderRadius: '20px 20px 0px 0px',
              height: '318px !important',
            },
            'abyss-modal-content-title': {
              padding: '0',
            },
            'abyss-modal-body': {
              padding: '0 16px 16px',
            },
          }}
          isOpen={isOpen}
          locationsDetails={locationsDetails}
          onClose={() => setIsOpen(false)}
          position="bottom"
          selectedIndex={selectedIndex}
          selectedLocation={selectedLocation}
          setIsOpen={setIsOpen}
          setSelectedLocation={setSelectedLocation}
          title={
            <LocationsDrawerHeaderMobile>
              {t('PROVIDER_DETAILS.LOCATIONS_TAB.CHANGE_LOCATION')}
            </LocationsDrawerHeaderMobile>
          }
        >
          <LocationsContentForPopOverDesktop
            isOpen={isOpen}
            locationsDetails={locationsDetails}
            selectedIndex={selectedIndex}
            selectedLocation={selectedLocation}
            setIsOpen={setIsOpen}
            setSelectedLocation={setSelectedLocation}
          />
        </Drawer>
      ) : (
        <LocationsContentForPopOverDesktop
          isOpen={isOpen}
          locationsDetails={locationsDetails}
          selectedIndex={selectedIndex}
          selectedLocation={selectedLocation}
          setIsOpen={setIsOpen}
          setSelectedLocation={setSelectedLocation}
        />
      )}
    </React.Fragment>
  );
};
