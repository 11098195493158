import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { Constants } from '../../Constants';

export const isValidDate = (dateObject: string): boolean => {
  if (dateObject) {
    return new Date(dateObject).toString() !== 'Invalid Date';
  }
  return false;
};

export const dateKey = 'lagoon.updatedAt';
export interface RecentActivity {
  id: string;
  category: string;
  key: string;
  score?: string;
  psxKeyword: string;
  'lagoon.updatedAt': string;
  'lagoon.updatedBy': string;
  type: string;
  searchType: string;
  pesKeyword: string;
  icon: string;
  keyword?: string;
}
export const formatDate = (newDate: Date) => {
  const months = {
    0: 'January',
    1: 'February',
    2: 'March',
    3: 'April',
    4: 'May',
    5: 'June',
    6: 'July',
    7: 'August',
    8: 'September',
    9: 'October',
    10: 'November',
    11: 'December',
  };

  const year = newDate?.getFullYear();
  const day = newDate?.getDate();
  const monthIndex = newDate?.getMonth();
  const monthIndexUTC = newDate?.getUTCMonth();
  const monthNumber = monthIndexUTC + 1;
  const monthName = months[newDate?.getMonth()];
  const time = newDate?.toLocaleTimeString('en-US').replace(/:\d+ /, ' ');
  const dayUTC = newDate?.getUTCDate();
  const yearUTC = newDate?.getUTCFullYear();
  const formattedMonth = monthNumber < 10 ? `0${monthNumber}` : monthNumber;
  const formattedDay = dayUTC < 10 ? `0${dayUTC}` : dayUTC;

  return {
    monthIndex,
    monthNumber,
    formattedMonth,
    monthName,
    formattedDay,
    day,
    year,
    time,
    dayUTC,
    monthIndexUTC,
    yearUTC,
  };
};

export const datesGroupByMonthAndYear = (dates: RecentActivity[]) =>
  dates?.reduce((val, obj) => {
    if (obj[dateKey]) {
      const isValid = isValidDate(obj[dateKey]);
      if (!isValid) {
        return val;
      }
      const date: Date = new Date(obj[dateKey]);
      const { monthName, year } = formatDate(date);
      // eslint-disable-next-line no-param-reassign
      val[`${monthName} ${year}`] = val[`${monthName} ${year}`] || [];
      val[`${monthName} ${year}`].push(obj);
    }
    return val;
  }, {});

export const sortedTimeStamps = (arr: RecentActivity[]) =>
  [...arr].sort((a, b) => {
    const c: number = new Date(a[dateKey]).getTime();
    const d: number = new Date(b[dateKey]).getTime();
    return d - c;
  });

export const transformDate = (date: string) => {
  const isValid = isValidDate(date);
  if (!isValid) {
    return Constants.INVALID_DATE;
  }
  const newDate = new Date(date);
  const { monthName, day, time } = formatDate(newDate);
  return `${monthName} ${day}, ${time}`;
};

export const dateFormat = (date: string) => {
  if (date) {
    const datePart = date.split('T')[0];
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (regex.test(datePart)) {
      return datePart;
    }
    const separator = date.includes('-') ? '-' : '/';
    const [month, day, year] = date.split(separator);
    return `${year}-${month}-${day}`;
  }
  return date;
};

export const dateGroupByYearMonthDay = (date: string) => {
  const dateFormatted = dateFormat(date);
  const isValid = isValidDate(dateFormatted);
  if (!isValid) {
    return Constants.INVALID_DATE;
  }
  const newDate = new Date(dateFormatted);
  const { yearUTC, formattedMonth, formattedDay } = formatDate(newDate);
  return `${yearUTC}-${formattedMonth}-${formattedDay}`;
};

export const dateFormatUTC = (
  date: string,
  formatPattern: string = 'MM/dd/yyyy'
) => {
  const isValid = isValidDate(date);
  if (!isValid) {
    return Constants.INVALID_DATE;
  }
  const newDate = new Date(date);
  const { monthIndexUTC, dayUTC, yearUTC } = formatDate(newDate);
  return format(new Date(yearUTC, monthIndexUTC, dayUTC), formatPattern);
};

export const formatedDateUTC = (
  date: string,
  formatPattern: string = 'MM/dd/yyyy'
) => {
  const isValid = isValidDate(date);
  if (!isValid) {
    return 'Not Available';
  }
  const newDate = new Date(date);
  const { monthIndexUTC, dayUTC, yearUTC } = formatDate(newDate);
  return format(new Date(yearUTC, monthIndexUTC, dayUTC), formatPattern);
};

export const dateFormatCurrent = (date: string) => {
  const isValid = isValidDate(date);
  if (!isValid) {
    return Constants.INVALID_DATE;
  }

  if (date.includes('-')) {
    const [year, month, day] = date.split('-');
    return `${month}/${day}/${year}`;
  }
  return date;
};

export const getFormattedDate = (date) => {
  const formatted = dateFormatCurrent(date);
  if (formatted !== Constants.INVALID_DATE && !date.startsWith('9999')) {
    return formatted;
  }

  return undefined;
};

export const dateTimeFormatToDate = (date: string) => {
  const { t } = useTranslation();
  const isValid = isValidDate(date);
  if (!isValid) {
    return Constants.INVALID_DATE;
  }
  const formattedDate = new Date(date).toLocaleDateString({} as any, {
    timeZone: 'UTC',
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  });
  const sp = formattedDate.split(' ');
  return `${t(sp[0])} ${sp[1]} ${sp[2]}`;
};

export const subtractMonths = (date: Date, _num: number) => {
  date.setMonth(date.getMonth() - _num);

  return date.toLocaleDateString();
};

export const addDaysToDate = (date: Date, numberOfDays: number) => {
  const newDate = new Date(date); // Set to date in param for test purposes
  newDate.setDate(date.getDate() + numberOfDays);

  return newDate.toLocaleDateString('en-US');
};
export const getMonthAndYear = (date: string) => {
  const isValid = isValidDate(date);
  if (!isValid) {
    return Constants.INVALID_DATE;
  }
  const newDate = new Date(date);
  const { monthName, year } = formatDate(newDate);
  return `${monthName} ${year}`;
};

export const calculateDateOffset = (numberOfDays: number) => {
  const currDate: Date = new Date();
  currDate.setDate(currDate.getDate() + numberOfDays); //numberOfDays is added to currDate to get future or past dates
  return currDate.toISOString().slice(0, 10);
};

export const getCurrentUTCYear = () => new Date().getUTCFullYear();

export const  dateFormatToDate = (date: string = '') => {
  const isValid = isValidDate(date);
  if (!isValid) {
    return Constants.INVALID_DATE;
  }

  const dateParts = date.split('-').map(part => parseInt(part, 10));
  const dateObject = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);

  const formattedDate = dateObject.toLocaleDateString({} as any, {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });
  const sp = formattedDate && formattedDate.split(' ');
  return sp?.length ? ` ${(sp[0])}. ${sp[1]} ${sp[2]}` : '';
};
