import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Button } from '@abyss/web/ui/Button';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { Text } from '@abyss/web/ui/Text';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { StoreKeys } from '../../../hooks/useStore/state';
import { useStore } from '../../../hooks/useStore/useStore';
import { useAnalyticsStore } from '../../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../../store/useChipStore';
import { ChipState } from '../../../store/useChipStore/chipStore';
import { usePCPStore } from '../../../store/usePCPStore';
import { PCPSearchStore } from '../../../store/usePCPStore/pcpStore';
import { useTypeaheadStore } from '../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../store/useTypeaheadStore/typeaheadStore';
import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../Constants';
import { ConstantsRoutes } from '../../ConstantsRoutes';
import { phoneOnly } from '../../ConstantsStyles';
import { convertObjectToUrlParams } from '../../Utils';
import { getFormattedDate } from '../../Utils/datesUtils/formatDate';
import {
  StyledChoosePCPAlertDrawerButton,
  StyledChoosePCPAlertDrawerButtonRow,
  StyledChoosePCPAlertDrawerContainer,
  StyledChoosePCPAlertDrawerSection,
  StyledChoosePCPAlertDrawerTitle,
  StyledChoosePCPAlertDrawerTitleContainer,
  StyledChoosePCPAlertModal,
  StyledChoosePCPAlertModalButtonRow,
  StyledChoosePCPAlertModalGroup,
  StyledChoosePCPAlertModalRadioGroup,
  StyledChoosePCPAlertModalSection,
  StyledChoosePCPAlertModalTitle,
} from './ChoosePCPAlert.styled';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

export const ChoosePCPAlertModal = ({ isOpen, setIsOpen }: Props) => {
  const { navigate } = useRouter();

  const { setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );
  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { setPCPSearchState } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      setPCPSearchState: state.setPCPSearchState,
    }))
  );
  const { setAnalyticsState } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      setAnalyticsState: state.setAnalyticsState,
    }))
  );
  const [selectedDepSeqNbr, setSelectedDepSeqNbr] = useState<string>('00');

  const { t } = useTranslation();
  const isMobile = useMediaQuery(phoneOnly);
  const members = useStore(StoreKeys.OBAPI_MEMBERS);

  const handleSelectDependent = (dependentSeqNbr, relationshipTypeCode) => {
    adobeLinkTrackEvent({
      name: relationshipTypeCode,
      location: 'modal:choose pcp alert',
      type: 'radio',
    });
    setSelectedDepSeqNbr(dependentSeqNbr);
  };

  const handleChooseDependent = () => {
    adobeLinkTrackEvent({
      name: 'choose pcp button',
      location: 'modal:choose pcp alert',
    });
    setIsOpen(false);

    setAnalyticsState({
      originLinkNameForAnalytics: 'add-dependent-pcp',
    });

    const typeaheadStoreValues = {
      providerType: '',
      searchType: Constants.SEARCH_TYPES.SPECIALTY,
      search: Constants.RESULT_SECTION.PRIMARY_CARE_PROVIDERS,
    };

    setTypeaheadSearchStore({
      ...typeaheadStoreValues,
      acceptingNewPatients: true,
    });

    const pcpStoreValues = {
      choosePCP: true,
      dependentSeqNbr: selectedDepSeqNbr,
      pcpIndicator: true,
    };
    setPCPSearchState(pcpStoreValues);

    const urlParams = convertObjectToUrlParams(chipStore, {
      originLinkNameForAnalytics: 'add-dependent-pcp',
      ...typeaheadStoreValues,
      ...pcpStoreValues,
    });
    navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
  };

  const DependentRadioGroup = (
    <StyledChoosePCPAlertModalRadioGroup
      data-testid="dependent-radio-group"
      value={selectedDepSeqNbr}
    >
      {members.map((member) => {
        const { name, dateOfBirth, dependentSeqNbr, relationshipTypeCode } =
          member.demographics;
        const { firstName, lastName } = name;
        const fontWeight =
          selectedDepSeqNbr === dependentSeqNbr ? 'bold' : 'normal';
        const fontSize = isMobile ? 'md' : 'lg';
        return (
          <React.Fragment key={`dependent-radio-${dependentSeqNbr}`}>
            <StyledChoosePCPAlertModalGroup
              alignItems="top"
              data-testid={`dependent-radio-${dependentSeqNbr}`}
              onClick={() =>
                handleSelectDependent(
                  dependentSeqNbr,
                  relationshipTypeCode?.code
                )
              }
              space="4"
            >
              <RadioGroup.Radio value={dependentSeqNbr} />
              <Layout.Stack alignItems="left" space="0">
                <Text fontWeight={fontWeight} size={fontSize}>
                  {firstName} {lastName}
                </Text>
                <Text fontWeight={fontWeight} size={fontSize}>
                  {getFormattedDate(dateOfBirth)}
                </Text>
              </Layout.Stack>
            </StyledChoosePCPAlertModalGroup>
          </React.Fragment>
        );
      })}
    </StyledChoosePCPAlertModalRadioGroup>
  );

  return (
    <React.Fragment>
      {isMobile ? (
        <StyledChoosePCPAlertDrawerContainer
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          position="bottom"
        >
          <StyledChoosePCPAlertDrawerTitleContainer>
            <StyledChoosePCPAlertDrawerTitle fontWeight="bold">
              {t('PRIMARY_CARE_PROVIDER.CHOOSE_PCP_FOR')}
            </StyledChoosePCPAlertDrawerTitle>
          </StyledChoosePCPAlertDrawerTitleContainer>
          <StyledChoosePCPAlertDrawerSection>
            {DependentRadioGroup}
          </StyledChoosePCPAlertDrawerSection>
          <StyledChoosePCPAlertDrawerButtonRow>
            <StyledChoosePCPAlertDrawerButton
              data-testid="drawer-choose-dependent"
              onClick={handleChooseDependent}
            >
              {t('Choose PCP')}
            </StyledChoosePCPAlertDrawerButton>
          </StyledChoosePCPAlertDrawerButtonRow>
        </StyledChoosePCPAlertDrawerContainer>
      ) : (
        <StyledChoosePCPAlertModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          size="558"
        >
          <Modal.Section>
            <StyledChoosePCPAlertModalTitle>
              {t('PRIMARY_CARE_PROVIDER.CHOOSE_PCP_FOR')}
            </StyledChoosePCPAlertModalTitle>
          </Modal.Section>
          <StyledChoosePCPAlertModalSection>
            {DependentRadioGroup}
          </StyledChoosePCPAlertModalSection>
          <StyledChoosePCPAlertModalButtonRow>
            <Button
              data-testid="modal-choose-dependent"
              onClick={handleChooseDependent}
            >
              {t('Choose PCP')}
            </Button>
          </StyledChoosePCPAlertModalButtonRow>
        </StyledChoosePCPAlertModal>
      )}
    </React.Fragment>
  );
};
