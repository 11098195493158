import { t } from 'i18next';

import { PatientReviewSuggestionCard } from './PateintReviewSuggestionCard';
import { SuggestionWrapper } from './PatientReviews.styled';

type Answers = {
  count: number;
  title: string;
};
type Props = {
  suggestion: { negative: Answers[]; positive: Answers[] };
};
export const PatientReviewSuggeston = ({ suggestion }: Props) => (
  <SuggestionWrapper>
    <PatientReviewSuggestionCard
      dataTestId="positive-suggestion"
      heading={t('What went well')}
      surveyAnswers={suggestion?.positive}
    />
    <PatientReviewSuggestionCard
      dataTestId="negative-suggestion"
      heading={t('What could be improved')}
      surveyAnswers={suggestion?.negative}
    />
  </SuggestionWrapper>
);
