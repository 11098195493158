import { config } from '@abyss/web/tools/config';

import { useCustomQuery } from '../useCustomQuery';
import GET_USER_PROFILE_INFORMATION from './MemberInfo.graphql';

export const useOBAPI = (options) =>
  useCustomQuery(GET_USER_PROFILE_INFORMATION, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'memberInfo',
  });
