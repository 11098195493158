import { styled } from '@abyss/web/tools/styled';
import { Text } from '@abyss/web/ui/Text';

const baseFontSize = '14.22px';

export const compareEmptyCarouselExpandedStyle = {
  'abyss-box-root': {
    backgroundColor: 'transparent',
    border: '2px dashed #929496',
    width: '262px',
    borderRadius: '8px',
    justifyContent: 'left',
    alignItems: 'center',
    display: 'flex',
    '@screen < $sm': {
      width: '191px',
      padding: '12px 6px 12px 6px',
    },
  },
};

export const AddressDiv = styled('div', {
  dynamic: ({ cssProps: { isOverflow, isAttributePresent } }) => ({
    whiteSpace: isOverflow && 'nowrap',
    fontStyle: !isAttributePresent && 'italic',
  }),
  fontSize: baseFontSize,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '230px',
  height: '24px',
  '@screen < $sm': {
    width: '162px',
  },
});

export const StyledEducationContainerDiv = styled('div', {
  height: '60px',
});

export const StyledEducationUniversityDiv = styled('div', {
  dynamic: ({ cssProps: { isOverflow, isUniversityEmpty } }) => ({
    whiteSpace: isOverflow ? 'nowrap' : 'normal',
    fontStyle: isUniversityEmpty && 'italic',
    fontWeight: !isUniversityEmpty && 'bold',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '150px',
    height: '24px',
  }),
  fontSize: baseFontSize,
  marginBottom: '0px',
  ...AddressDiv,
});

export const StyledEducationDegreeDiv = styled('div', {
  dynamic: ({ cssProps: { isDegreeEmpty } }) => ({
    whiteSpace: isDegreeEmpty && 'nowrap',
    fontStyle: isDegreeEmpty && 'italic',
  }),
  fontSize: baseFontSize,
  marginTop: '0px',
  ...AddressDiv,
});

export const StyledSingleAttributeContainerDiv = styled('div', {
  height: '34px',
});

export const StyledHoursOfOperationDiv = styled('div', {
  dynamic: ({ cssProps: { isOverflow } }) => ({
    whiteSpace: isOverflow && 'nowrap',
  }),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '240px',
  height: '24px',
  '@screen < $sm': {
    width: '162px',
  },
});

export const StyledLanguagesSpokenDiv = styled('div', {
  dynamic: ({ cssProps: { isOverflow, isAttributeEmpty } }) => ({
    whiteSpace: isOverflow ? 'nowrap' : 'normal',
    fontStyle: isAttributeEmpty && 'italic',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    width: '150px',
    height: '24px',
  }),
  fontSize: baseFontSize,
  ...AddressDiv,
});

export const StyledAvoidWrappingDiv = styled('div', {
  dynamic: ({ cssProps: { isOverflow, isAttributeEmpty } }) => ({
    whiteSpace: isOverflow && 'nowrap',
    fontStyle: isAttributeEmpty && 'italic',
  }),
  fontSize: baseFontSize,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '230px',
  height: '24px',
  '@screen < $sm': {
    width: '162px',
  },
});

export const StyledAddressDistanceContainerDiv = styled('div', {
  height: '54px',
  '@screen < $sm': {
    height: '60px',
  },
});

export const StyledDistanceDiv = styled('div', {
  dynamic: ({ cssProps: { isOverflow } }) => ({
    whiteSpace: isOverflow && 'nowrap',
  }),
  fontSize: baseFontSize,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '230px',
  height: '20px',
  '@screen < $sm': {
    width: '162px',
  },
});

export const popoverStyle = {
  'abyss-popover-content': {
    width: '150px',
  },
};

export const OverflowTooltipCapableDiv = styled('div', {
  dynamic: ({ cssProps: { isAttributeEmpty } }) => ({
    fontStyle: isAttributeEmpty && 'italic',
    fontSize: baseFontSize,
  }),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '230px',
  height: '24px',
  whiteSpace: 'nowrap',
  '@screen < $sm': {
    width: '162px',
    fontSize: '$xs',
  },
});

export const GenderAttribute = styled('div', {
  dynamic: ({ cssProps: { isAttributeEmpty } }) => ({
    fontStyle: isAttributeEmpty && 'italic',
  }),
  fontSize: baseFontSize,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '@screen < $sm': {
    width: '162px',
  },
});

export const italicFontStyle = { fontStyle: 'italic' };

export const nullPhoneNumberStyle = {
  fontStyle: 'italic',
  fontSize: '$xs',
};

export const specialtyNullStyle = {
  fontStyle: 'italic',
  fontSize: '$sm',
  '@screen < $sm': {
    fontSize: '11px',
  },
};

export const distanceNullStyle = {
  fontStyle: 'italic',
  fontSize: baseFontSize,
};

export const StyledNullAttributeDiv = styled('div', {
  whiteSpace: 'nowrap',
  fontStyle: 'italic',
  fontSize: baseFontSize,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: '230px',
  height: '24px',
  '@screen < $sm': {
    width: '162px',
  },
});

export const CompareNameDetailsDiv = styled('div', {
  dynamic: ({ cssProps: { isOverflow } }) => ({
    whiteSpace: isOverflow && 'nowrap',
  }),
  display: 'inline-block',
  width: '130px',
  height: '24px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '@screen < $sm': {
    height: '20px',
  },
});

export const TextRoot = styled(Text, {
  '&.abyss-text-root': {
    fontSize: baseFontSize,
  },
});

export const textRoot = {
  fontSize: baseFontSize,
};

export const StyledPremiumCareProviderDiv = styled('div', {
  fontSize: baseFontSize,
  minHeight: '24px',
  width: '230px',
  '@screen < $sm': {
    minHeight: '42px',
    width: '159px',
  },
});

export const StyledPremiumCareProviderNullStateDiv = styled('div', {
  dynamic: ({ cssProps: { isOverflow, mobile } }) => ({
    whiteSpace: isOverflow && !mobile && 'nowrap',
    overflow: isOverflow && 'hidden',
    textOverflow: isOverflow && 'ellipsis',
  }),
  fontStyle: 'italic',
  fontSize: 'inherit',
  overflow: 'wrap',
});

export const ReviewsText = styled(Text, {
  marginLeft: '3px',
  marginTop: '4.0px',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
});

export const CompareCardNibbleStyle = {
  'abyss-box-root': {
    backgroundColor: 'transparent',
    border: '2px solid #E5E5E6',
    boxShadow: '0px 2px 4px 0px #0000001F',
    width: '262px',
    height: '96px',
    borderRadius: '8px',
    justifyContent: 'left',
    alignItems: 'center',
    display: 'flex',
    '&.compare-card-container': {
      width: '265px !important',
      height: '416px !important',
    },
    '&.compare-card-container-mobile': {
      width: '150px',
      height: '529px',
      padding: '$md',
    },
    '@screen < $sm': {
      display: 'flex',
      flexDirection: 'column',
      width: '146px',
      height: '136px',
      padding: '12px 6px 12px 6px',
    },
  },
};

export const CompareCardExpandedStyle = {
  'abyss-box-root': {
    backgroundColor: 'transparent',
    border: '2px solid #E5E5E6',
    boxShadow: '0px 2px 4px 0px #0000001F',
    width: '262px',
    borderRadius: '8px',
    justifyContent: 'left',
    alignItems: 'center',
    display: 'flex',
    '&.compare-card-container': {
      width: '265px !important',
    },
    '&.compare-card-container-mobile': {
      width: '191px',
      height: '100%',
      padding: '$md',
    },
    '@screen < $sm': {
      display: 'flex',
      flexDirection: 'column',
      width: '191px',
      height: '136px',
      padding: '12px 6px 12px 6px',
    },
  },
};

export const CompareDrawerHeader = styled('div', {
  variants: {
    view: {
      mobile: {
        paddingLeft: '10px',
        paddingRight: '10px',
      },
      desktop: {
        paddingLeft: '30px',
        paddingRight: '30px',
      },
    },
  },
});

export const compareCardRemoveTextStyle = {
  'abyss-text-root': {
    fontSize: '$md',
    fontWeight: '$bold',
    lineHeight: '18px',
    textAlign: 'left',
    color: ' $interactive1',
    cursor: 'pointer',
  },
};

export const CompareCardTextStyle = {
  'abyss-text-root': {
    ...compareCardRemoveTextStyle['abyss-text-root'],
  },
};

export const CompareCardCollapsedTextStyle = {
  'abyss-text-root': {
    ...CompareCardTextStyle['abyss-text-root'],
    width: '140px',
    '@screen < $sm': {
      textAlign: 'center',
      width: '135px',
      lineHeight: '22px',
      padding: '7px',
      wordBreak: 'break-word',
    },
  },
};

export const StyledMiniCompareCardAddressCotainer = styled('div', {
  display: 'flex',
});
export const StyledMiniCompareCardAddressDiv = styled('div', {
  dynamic: ({ cssProps: { isOverflow } }) => ({
    whiteSpace: isOverflow && 'nowrap',
    padding: '2px 6px ',
  }),
  display: 'inline-block',
  width: '120px',
  height: '24px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  textAlign: 'left',
});

export const StyledMiniCompareCardAddressText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '$sm',
    color: '$info1',
    textAlign: 'center',
    cursor: 'auto',
    fontWeight: '600',
  },
});

export const placeHolderTextMobileStyle = {
  'abyss-text-root': {
    fontSize: '$md',
    alignContent: 'center',
    justifyContent: 'center',
    fontWeight: '$medium',
    lineHeight: '20px',
    textAlign: 'center',
    color: '#6E7072',
  },
};

export const AvatarCompareCard = styled('div', {
  width: '64px',
  height: '64px',
  minWidth: '64px',
  minHeight: '64px',
  borderRadius: '50%',
  overflow: 'hidden',
});

export const HighlightedBorderStyle = { border: '2px solid #D0F1F5' };

export const editCompareCheckboxStyle = {
  'abyss-checkbox-root': {
    width: '25%',
    '@screen < $lg': {
      width: '33%',
    },
    '@screen < $md': {
      width: '50%',
    },
    padding: '8px',
  },
  'abyss-checkbox-icon': {
    backgroundColor: '$primary1',
  },
};

export const editCompareButtonsApplyCancelStyle = {
  '&.abyss-button-root': {
    width: 80,
    height: 42,
  },
};

export const editCompareDrawerMobileStyle = {
  'abyss-modal-content-container': {
    borderRadius: '20px 20px 0px 0px',
  },
  'abyss-modal-close-button': {
    marginTop: '18px',
    marginRight: '18px',
  },
};

export const editCompareCheckboxMobileStyle = {
  'abyss-checkbox-root': {
    paddingBottom: '10px',
  },
  'abyss-checkbox-icon': {
    backgroundColor: '$primary1',
  },
};

export const editCompareFloatingSectionStyle = {
  'abyss-floating-section-root': {
    borderRadius: '20px 20px 0px 0px',
    boxShadow: '0px -6px 20px rgba(25, 25, 26, 0.16)',
    width: '100%',
    padding: '24px 30px',
    position: 'fixed',
    bottom: '0',
    zIndex: '1',
    overflowY: 'auto',
    backgroundColor: '#FAFCFF',
  },
};

export const compareModalButtonStyle = {
  'abyss-button-root': {
    width: 'auto',
    height: '40px',
    lineHeight: '20px',
    padding: '$sm',
    overflow: 'hidden',
  },
};

export const StyledOverflowAttributeDiv = styled('div', {
  dynamic: ({ cssProps: { isOverflow, isAttributeEmpty } }) => ({
    whiteSpace: isOverflow && 'nowrap',
    fontStyle: isAttributeEmpty && 'italic',
  }),
  fontSize: baseFontSize,
  ...AddressDiv,
});
