import { Popover } from '@abyss/web/ui/Popover';
import React, { ReactNode } from 'react';

import { adobeModalTrackEvent } from '../AdobeTagging/adobeModalTrackEvent';
import { Constants } from '../Constants';
import {
  CustomAttributesBlock,
  handleLinkAndModalTrack,
} from '../Utils/adobeTrackUtils/adobeTrackUtils';

type AnalyticsInfo = {
  destinationUrl?: string | null;
  location?: string;
  modalName: string;
  linkName?: string | null;
  customAttributesBlock?: CustomAttributesBlock | null;
};

type Props = {
  align: string;
  alignOffset: number;
  analyticsInfo: AnalyticsInfo;
  children: ReactNode;
  content: string;
  css: any;
  'data-auto-testid': string;
  onOpenChange: Function;
  open: Boolean;
  position: string;
  positionOffset: number;
  showClose: boolean;
  title: string;
  width: string | number;
  trackingType: string;
};

export const PopoverWithTracking = ({
  align = 'center',
  alignOffset,
  analyticsInfo,
  children,
  content,
  css = '',
  'data-auto-testid': dataAutoTestId = '',
  onOpenChange = () => {},
  open,
  position,
  positionOffset,
  showClose,
  title,
  width,
  trackingType,
  ...props
}: Props) => {
  if (open) {
    if (trackingType === Constants.ADOBE_TRACKING.MODAL_WITH_LINK_TRACKING) {
      handleLinkAndModalTrack(
        analyticsInfo?.linkName || dataAutoTestId,
        analyticsInfo?.location || dataAutoTestId,
        analyticsInfo?.modalName || dataAutoTestId,
        analyticsInfo?.customAttributesBlock || undefined
      );
    } else {
      adobeModalTrackEvent(analyticsInfo?.modalName || dataAutoTestId);
    }
  }
  return (
    <React.Fragment>
      <Popover
        align={align}
        alignOffset={alignOffset}
        content={content}
        css={css}
        onOpenChange={onOpenChange}
        open={open}
        position={position}
        positionOffset={positionOffset}
        showClose={showClose}
        title={title}
        width={width}
        {...props}
      >
        {children}
      </Popover>
    </React.Fragment>
  );
};
