import { styled } from '@abyss/web/tools/styled';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Modal } from '@abyss/web/ui/Modal';

export const ChoosePCPLocationAvatar = styled(Flex, {
  width: '42px',
  height: '42px',
  minWidth: '42px',
  minHeight: '42px',
  borderRadius: '50%',
  overflow: 'hidden',
  marginRight: '8px',
});

export const PCPHeaderContainer = styled('div', {
  borderBottom: 'solid',
  borderColor: '#E5E5E6',
  backgroundColor: '#FBFCFE',
  height: 'auto',
  marginBottom: '8px',
  '@screen < $sm': {
    marginBottom: '0',
  },
});

export const PCPCardWrapper = styled(Box, {
  '&.abyss-box-root': {
    backgroundColor: '#FBFCFE',
    padding: 0,
    '@screen < $sm': {
      display: 'flex',
      alignItems: 'center',
      '.mobile-nav-back-button': {
        height: '24px',
        marginTop: 0,
        marginLeft: 0,
      },
    },
  },
});

export const SelectedPCPCard = styled(Box, {
  borderRadius: '12px',
  boxShadow: '0px 2px 6px 0px rgba(25, 25, 26, 0.16)',
  '&.abyss-box-root': {
    backgroundColor: '#FFFFFF',
    width: '408px',
    '@screen < $md': {
      width: '307px',
      backgroundColor: '$primary1',
    },
    '@screen < $sm': { margin: 0 },
  },
});

export const ChooseLocationHeadingWrapper = styled('div', {
  marginBottom: '32px',
  '@screen < $sm': {
    '.abyss-text-root': {
      fontSize: '$md',
      lineHeight: '20px',
    },
  },
});

export const ChooseLocationHeading = styled(Heading, {
  margin: '-8px 0 8px 0',
  '&.abyss-heading-root': {
    '@screen < $sm': {
      fontSize: '22.78px !important',
      lineHeight: '28px !important',
    },
  },
});
export const ChooseLocationRow = styled(Flex, {
  maxWidth: '408px',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const ChooseLocationLabel = styled(Heading, {
  '&.abyss-heading-root': {
    lineHeight: '$md !important',
    '@screen < $sm': {
      fontSize: '$mediumText !important',
    },
  },
});

export const MobileViewMapButton = styled(Button, {
  '@screen >= $sm': {
    display: 'none',
  },
  color: '$interactive1',
  '&.abyss-button-root': {
    height: '20px',
    padding: 0,
  },

  '&.abyss-button-content-container': {
    fontSize: '$xs',
    lineHeight: '16px',
  },
  '.abyss-insert-element-before': {
    paddingRight: '6px !important',
  },
});

export const ChooseLocationCardsContainer = styled(Flex, {
  maxHeight: '400px',
  overflow: 'auto',
  gap: '12px',
  '.location-cards-container': {
    width: '100%',
  },
});

export const NewPatientDisclaimer = styled('div', {
  margin: '20px 0',
  maxWidth: '408px',
  '.abyss-text-root': {
    display: 'block',
    lineHeight: '16px',
  },
});

export const ButtonRowBox = styled(Box, {
  '@screen < $md': {
    bottom: '0',
    boxShadow: '0px -4px 12px rgba(25, 25, 26, 0.16)',
    marginBottom: '-$md',
    position: 'sticky',
    zIndex: 100,

    '&.abyss-box-root': {
      minHeight: '74px',
    },
  },

  '@screen < $sm': {
    '&.abyss-box-root': {
      padding: '$md',
      marginTop: '12px',
    },
  },
});

export const ErrorBox = styled(Box, {
  marginBottom: '$md',
});

export const SaveAndCancelButton = styled(Button, {
  '&.abyss-button-root': {
    height: '42px',
    fontWeight: '$semibold',
    borderWidth: '2px',
    padding: '$sm $md $sm $lg',
    '.abyss-insert-element-after': {
      paddingLeft: 0,
    },
    '&.cancel-button': {
      paddingRight: '$lg',
    },
  },
});

export const LocationsWrapColumn = styled('div', {
  height: '583px',
  position: 'absolute',
  display: 'inline-block',
  overflow: 'visible',
  backgroundColor: '$primary2',
  zIndex: 10,
  boxShadow: '10px 0px 5px -5px rgba(0, 0, 0, 0.2)',
  paddingRight: '$lg',
  '@screen >= $sm': { maxWidth: '432px' },
  '@screen < $sm': {
    width: '100%',
    height: '532px',
    boxShadow: 'unset',
    padding: '0',
  },
});

export const MapViewWrap = styled('div', {
  dynamic: ({ cssProps: { closeSidePanel, mobileRouteView } }) => ({
    '.abyss-card-root': {
      width: closeSidePanel ? '100%' : 'calc(100% - 400px)',
      marginLeft: closeSidePanel ? '0' : '400px',
      '@screen < $sm': {
        height: mobileRouteView ? '100vh' : '532px',
      },
    },
  }),
  '.abyss-card-root': {
    height: '583px',
    border: 'none',
    boxShadow: 'unset',
    '.map-container': {
      height: '583px',
      width: '100%',
    },
  },
  '@screen < $sm': {
    '.abyss-card-root ': {
      width: '100% !important',
      marginLeft: '0 !important',
      '.map-container': {
        height: '100vh',
      },
    },
  },
  '.mapboxgl-ctrl-bottom-left': {
    display: 'none',
  },
  '.mapboxgl-ctrl-bottom-right': {
    display: 'none',
  },
});

export const SidePaneCloseButton = styled(Flex, {
  right: '-26px',
  top: '24px',
  position: 'absolute',
  zIndex: 1,
  height: '42px',
  width: '26px',
  backgroundColor: 'white',
  borderRadius: '0 4px 4px 0',
  border: '1px solid #E5E5E5',
  borderLeft: 'none',
  boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.2)',
  cursor: 'pointer',
  '@screen < $sm': {
    display: 'none',
  },
});

export const MobileMapViewWrap = styled('div', {
  dynamic: ({ cssProps: { mobileRouteView } }) => ({
    position: 'relative',
    '&.show-mobile-map-view': {
      position: 'relative',
      paddingRight: 0,
      '.locations-wrap-column': {
        width: '100%',
        background: 'transparent',
        bottom: 0,
        height: mobileRouteView ? '0' : '160px',
        padding: 0,
        '.choose-location-container': {
          maxWidth: 'unset',
          flexWrap: 'unset',
        },
      },

      '.abyss-card-root .map-container': {
        borderRadius: '0px',
      },
    },
  }),
});

export const StyledUpdateErrorModal = styled(Modal, {
  '&.abyss-modal-body': {
    maxHeight: '100%',
  },
});

export const StyledTopModalSection = styled(Modal.Section, {
  '&.abyss-modal-section-root': {
    padding: '16px 16px 12px',
  },
});

export const StyledMiddleModalSection = styled(Modal.Section, {
  '&.abyss-modal-section-root': {
    padding: '0 16px 16px',

    '@screen < $sm': {
      padding: '0 16px 12px',
    },
  },
});

export const StyledLowerModalSection = styled(Modal.Section, {
  '&.abyss-modal-section-root': {
    padding: '16px 24px',

    '@screen < $sm': {
      padding: '12px 16px 16px',
    },
  },
});

export const StyledLowerFlex = styled(Flex, {
  '&.abyss-flex-root': {
    margin: '0 -4px',

    '@screen < $sm': {
      margin: '-4px 0',
    },
  },
});

export const StyledUpdateErrorIcon = styled(IconBrand, {
  margin: '71px 0 5px 0',

  '@screen < $sm': {
    margin: '48px 0 5px 0',
  },
});
export const StyledTopModalSectionUSP = styled(Modal.Section, {
  alignItems: 'center',
  '&.abyss-modal-section-root': {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 16px',
    marginTop: '16px',
    '@screen < $sm': {
      marginTop: 0,
      padding: 0,
    },
  },
});

export const StyledLowerModalSectionUSP = styled(Modal.Section, {
  display: 'flex',
  outline: '1px solid #E5E5E6',
  flexDirection: 'column',
  gap: '16px',
  '&.abyss-modal-section-root': {
    padding: '16px 24px',
    alignItems: 'baseline',
  },
});

export const StyledLowerContentFlex = styled(Flex, {
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '16px',
  '&.abyss-flex-root': {
    margin: '0',
    '@screen < $sm': {
      margin: ' 0',
    },
  },
});
export const UpdateErrorIcon = styled(IconBrand, {
  margin: '0',
  '@screen < $sm': {
    margin: '48px 0 5px 0',
  },
});
export const ErrorHeaderContainer = styled('div', {
  width: '100%',
  fontWeight: '700',
  fontSize: '22.78px',
  lineHeight: '32px',
  textAlign: 'center',
  '@screen < $sm': {
    fontSize: '18px',
    lineHeight: '24px',
    textAlign: 'left',
  },
});

export const ChatAndCallContainer = {
  margin: '2px 2px 2px 0',
  fontWeight: '700',
  fontSize: '14.22px',
  lineHeight: '20px',
};

export const StyledLocationItems = styled(Layout.Stack, {
  gap: '4px',
  '@screen < $sm': {
    padding: '16px',
    gap: '8px',
  },
});

export const ErrorContentContainer = styled('div', {
  width: '100%',
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '18px',
  textAlign: 'center',
  '@screen < $sm': {
    textAlign: 'left',
    marginTop: '3px',
  },
});

export const ErrorButtonContainer = styled('div', {
  height: '68px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '0px, 0px, 8px, 8px',
  Padding: '12px, 16px, 16px, 16px',
  Gap: '16px',
});

export const ReturnToSearchResultsButton = styled(Button, {
  '&.abyss-button-root': {
    padding: '10px 24px 10px 24px',
    width: '284px',
    lineHeight: '20px',
    '@screen < $sm': {
      width: '288px',
    },
  },
});

export const TopTitleStyle = styled('div', {
  height: '62px',
  padding: '16px',
  borderRadius: '8px 8px 0 0',
  '@screen < $sm': {
    height: '52px',
  },
});

export const StyledRestartSearchButton = styled(Button, {
  '&.abyss-button-root': {
    margin: '0',
    width: '280px',

    '@screen < $sm': {
      margin: '18px 0 10px',
      width: '288px',
    },
  },
});

export const StyledContactUsLink = styled(Link, {
  '&.abyss-link-root': {
    display: 'inline',
  },
});

export const StyledContactUsButton = styled(Button, {
  '&.abyss-button-root': {
    margin: '0 4px',
    width: '284px',

    '@screen < $sm': {
      margin: '4px 0',
      width: '288px',
    },
  },
});

export const StyledProviderName = styled('div', {
  maxWidth: '335px',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '@screen < $md': {
    maxWidth: '235px',
  },
});
