import { event } from '@abyss/web/tools/event';

import { isExternalDomain } from '../Utils/adobeTrackUtils/commonUtils';

export const adobeLinkTrackEvent = (input) => {
  const { searchBlock, customAttributesBlock } = input;

  const getDestinationUrl = () => input?.destinationUrl || '';

  const getType = () => {
    const url = getDestinationUrl();
    if (url?.startsWith('tel')) {
      return 'phone';
    }
    if (url?.startsWith('email')) {
      return 'email';
    }
    if (url?.startsWith('phone:')) {
      return 'phone';
    }
    if (isExternalDomain(url)) {
      return 'external';
    }
    return 'internal';
  };

  event('LINK_TRACK_EVENT', {
    link: {
      name: input.name?.toLowerCase().replaceAll('-', ' '),
      location: input.location?.toLowerCase().replaceAll('-', ' '),
      type: input.type?.toString().toLowerCase() || getType(),
      destinationUrl:
        input.destinationUrl?.toLowerCase() || getDestinationUrl(),
    },
    component: {
      name: '',
    },
    ...(searchBlock && { search: searchBlock }),
    ...(customAttributesBlock && { customAttributes: customAttributesBlock }),
  });
};
