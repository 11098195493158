import React, { useEffect } from 'react';

import { adobeLinkTrackEvent } from '../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../../common/Constants';
import { useLagoon } from '../../../../hooks/useLagoon';

declare global {
  interface Window {
    lpTag: any;
  }
}

const initializeClickTracking = () => {
  const trackChatButtonClick = () => {
    adobeLinkTrackEvent({
      name: Constants.LIVE_PERSON_MESSAGE_US,
      location: 'global chat',
      type: 'internal',
    });
  };
  let timesRun = 0;
  const interval = setInterval(() => {
    timesRun += 1;
    if (window.lpTag) {
      window.lpTag?.events?.bind?.('*', 'OFFER_CLICK', trackChatButtonClick);
      clearInterval(interval);
    }
    if (timesRun === 5) {
      clearInterval(interval);
    }
  }, 1000);
};

export const LivePerson = () => {
  const livePerson = useLagoon('global-chat')();

  useEffect(() => {
    // Live Person script checks
    if (livePerson[0]?.script && livePerson[0]?.active) {
      const livePersonScript = document.createElement('script');
      livePersonScript.type = 'text/javascript';
      livePersonScript.async = true;
      livePersonScript.src = livePerson[0]?.script;
      livePersonScript.onload = () => {
        initializeClickTracking();
      };
      document.head.appendChild(livePersonScript);
    }
  }, []);

  return <React.Fragment />;
};
