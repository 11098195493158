import { styled } from '@abyss/web/tools/styled';
import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';

export const StyledVisionCareBox = styled(Box, {
  borderRadius: '8px',
});

export const StyledVisionCareButton = styled(Button, {
  '&.abyss-button-root': {
    marginTop: '$md',
  },
});
