import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { t } from 'i18next';

export const PatientReviewsErrorHandlingWidget = () => (
  <Layout.Stack alignItems="normal" space={8}>
    <Text color="$primary1" fontWeight="200" size="16px">
      {`${t('ERROR_PAGE.500.heading')} ${t('ERROR_PAGE.500.subHeading')}`}
    </Text>
    <Text>{t('ERROR_PAGE.500.body1')}</Text>
  </Layout.Stack>
);
