import { Text } from '@abyss/web/ui/Text';
import { t } from 'i18next';

import { HealthGradesBulkInfo } from '../../../../models/Healthgrades';
import { PatientRatingReviewsPercentage } from './PatientRatingReviewsPercentage';
import { PatientReviewsOverviewSection } from './PatientReviewsOverviewSection';
import { PatientReviewSuggeston } from './PatientReviewSuggestion';

type Props = {
  healthgradesReview: HealthGradesBulkInfo;
};

export const PatientReviewDetailsSection = ({ healthgradesReview }: Props) => {
  const { aggregatedSurvey } = healthgradesReview;
  return (
    <div>
      <PatientReviewsOverviewSection healthgradesReview={healthgradesReview} />
      {aggregatedSurvey?.commentCount ? (
        <div>
          <PatientRatingReviewsPercentage
            healthgradesReview={healthgradesReview}
          />

          <PatientReviewSuggeston suggestion={aggregatedSurvey?.answers} />
        </div>
      ) : (
        <Text data-testid="no-reviews-found">
          {t('PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.NO_REVIEWS_FOUND')}
        </Text>
      )}
    </div>
  );
};
