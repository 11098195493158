import { styled } from '@abyss/web/tools/styled';

export const TabContainer = styled('div', {
  color: '$primary1',
});

export const LeapFrogContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
});

export const leapFrogTextStyle = (phoneScreen: boolean) => ({
  'abyss-text-root': {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '$medium',
    margin: '20px 0 0 0',
    ...(phoneScreen && {
      fontSize: '14.22px',
      lineHeight: '16px',
    }),
    color: '$neutralGray',
  },
});
