/* eslint-disable react/no-unused-prop-types */
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Accordion } from '@abyss/web/ui/Accordion';
import { Badge } from '@abyss/web/ui/Badge';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { adobeModalTrackEvent } from '../../../../common/AdobeTagging/adobeModalTrackEvent';
import { LinkContainer } from '../../../../common/LinkContainer/LinkContainer';
import { useLagoon } from '../../../../hooks/useLagoon';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import {
  CulturalCompetence,
  Degrees,
  Education,
  Identifiers,
} from '../../../../models/ProviderDetails';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../../../store/useDetailsStore';
import { DetailsStore } from '../../../../store/useDetailsStore/detailsStore';
import { useTypeaheadStore } from '../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../store/useTypeaheadStore/typeaheadStore';
import { capitalizeEachWord } from '../../../../utils/providerNames.utils';
import { getCurrentMember } from '../../../../utils/user.utils';
import { Constants, ReverseCoverageTypesCodes } from '../../../Constants';
import { ConstantsLagoon } from '../../../ConstantsLagoon';
import {
  cardHeaderBrowseStyle,
  h2SmallerFontForResponsive,
  mobileOnly,
  phoneOnly,
  tabletOnly,
} from '../../../ConstantsStyles';
import { getFeatureFlag } from '../../../Utils';
import {
  CollapseCardHeaderStyle,
  HospitalAffiliationsContainerStyle,
  collapseSection,
} from '../About.styled';
import { handleHospitalAffiliationOnClick } from './helper';
import { HospitalAffiliationDrawer } from './HospitalAffiliationDrawer';
import { ListCulturalCompetencies } from './ListCulturalCompetencies';
import { ListOtherProviderDetails } from './ListOtherProviderDetails';

function listDegrees(t, school: string, degrees: Degrees[]) {
  const getDegree = (degree) =>
    `${degree?.description ? capitalizeEachWord(degree?.description) : ''} (${
      degree?.degreeCode || ''
    })`;

  return school === t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA') ? (
    degrees?.map((degree) => getDegree(degree))
  ) : (
    <React.Fragment>
      <b> {school ? capitalizeEachWord(school) : ''}</b>
      <Layout.Stack alignItems="left" space={2}>
        {degrees?.map((degree) => getDegree(degree))}
      </Layout.Stack>
    </React.Fragment>
  );
}

interface referralRequiredSpecialties {
  name: string;
}

type Props = {
  collapseConstantKey: string;
  collapseContent?: string[];
  collapseHeader: string;
  culturalCompetencies?: CulturalCompetence[] | string[] | null;
  education?: Education[] | null;
  hospitalAffiliations?: Identifiers[] | null;
  licenseNumber?: string[];
  npi?: string;
  referralRequiredSpecialties?: referralRequiredSpecialties[] | null;
  showCulturalCompetencies?: Boolean;
  accordionItemValue: string;
};

export const CollapseProviderDetailsSection = ({
  collapseConstantKey,
  collapseContent,
  collapseHeader,
  culturalCompetencies,
  education,
  hospitalAffiliations,
  licenseNumber,
  npi,
  referralRequiredSpecialties = [],
  showCulturalCompetencies = true,
  accordionItemValue,
}: Props) => {
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = useMediaQuery(phoneOnly);
  const mobileScreen = useMediaQuery(mobileOnly);
  const tabletScreen = useMediaQuery(tabletOnly) || isWidget;
  const widgetPhoneScreen = useMediaQuery(phoneOnly) && isWidget;
  const WidgetDesktopScreen = !useMediaQuery(phoneOnly) && isWidget;
  const WidgetPages = widgetPhoneScreen || WidgetDesktopScreen;

  const { navigate } = useRouter();
  const currentMember = getCurrentMember();
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const referralRequiredEnabled = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.REFERRAL_REQUIRED_BANNER
  );

  const doShowReferralBanner =
    currentMember.isReferralRequired && referralRequiredEnabled;

  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  const chipStore = useChipStore(useShallow((state: ChipState) => state));

  const { setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );
  const { setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );

  function listHospitalAffiliationsResponsive(
    name: string,
    providerId: string
  ) {
    return (
      <Layout.Stack alignItems="left" space={2}>
        {isWidget ? (
          <Text
            color="#4B4D4F"
            data-testid="hospital-affiliations-widget"
            fontWeight="$medium"
            size={mobileScreen ? '$md' : '18px'}
          >
            {capitalizeEachWord(name)}
          </Text>
        ) : (
          <Link
            data-auto-testid="hospital-affiliations-content-desktop"
            data-testid="hospital-affiliations-content-desktop"
            fontWeight="$bold"
            isStandardAnchor
            onClick={() => {
              handleHospitalAffiliationOnClick(providerId, name, navigate, {
                chipStore,
                setTypeaheadSearchStore,
                setDetailsStore,
              });
            }}
          >
            {capitalizeEachWord(name)}
          </Link>
        )}
      </Layout.Stack>
    );
  }

  function hospitalAffiliationLink(name, hospitalAffiliationProviderId) {
    return (
      <Link
        css={{
          'abyss-link-root': {
            maxWidth: '155px',
          },
        }}
        data-auto-testid="hospital-affiliations-content-desktop"
        data-testid="hospital-affiliations-content-desktop"
        fontWeight="$bold"
        isStandardAnchor
        onClick={() =>
          handleHospitalAffiliationOnClick(
            hospitalAffiliationProviderId,
            name,
            navigate,
            {
              chipStore,
              setTypeaheadSearchStore,
              setDetailsStore,
            }
          )
        }
      >
        <Text
          color="$info1"
          css={{
            'abyss-text-root': {
              maxWidth: '127px',
              fontWeight: '$semibold',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            },
          }}
        >
          {capitalizeEachWord(name)}
        </Text>
        <IconMaterial css={{ marginLeft: '4px' }} icon="chevron_right" />
      </Link>
    );
  }

  function hospitalAffiliationsChips(
    name: string,
    hospitalAffiliationProviderId: string,
    tooltip: boolean
  ) {
    return (
      <Flex css={HospitalAffiliationsContainerStyle}>
        {tooltip ? (
          <Tooltip
            align="center"
            content={capitalizeEachWord(name)}
            css={{
              'abyss-text-root': {
                fontSize: '14.22px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '16px',
              },
            }}
            position="top"
            positionOffset={8}
          >
            {hospitalAffiliationLink(name, hospitalAffiliationProviderId)}
          </Tooltip>
        ) : (
          hospitalAffiliationLink(name, hospitalAffiliationProviderId)
        )}
      </Flex>
    );
  }

  const getAboutDetailResponsive = (detailCollapseHeaderResponsive) => {
    switch (detailCollapseHeaderResponsive) {
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.EDUCATION:
        return (
          <React.Fragment>
            {education?.map((item) => (
              <Layout.Stack
                alignItems="left"
                css={{ color: '#4B4D4F', fontSize: '16px' }}
                key={`collapse-section-${item?.schoolName}`}
                space={2}
              >
                {listDegrees(t, item.schoolName, item.degrees)}
              </Layout.Stack>
            ))}
            {showCulturalCompetencies && (
              <Layout.Stack
                alignItems="left"
                css={{ color: '#4B4D4F', fontSize: '16px', marginTop: '12px' }}
                key="collapse-section-cultural-competence"
                space={2}
              >
                <ListCulturalCompetencies
                  culturalCompetencies={culturalCompetencies}
                  mobileScreen={mobileScreen}
                />
              </Layout.Stack>
            )}
          </React.Fragment>
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .HOSPITAL_AFFILIATIONS:
        return hospitalAffiliations?.slice(0, 4)?.map((item) =>
          item.value === t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA') ? (
            <Text
              color="#4B4D4F"
              fontWeight="$medium"
              key={`hospital-affiliations-na`}
              size={mobileScreen ? '$md' : '18px'}
            >
              {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA')}
            </Text>
          ) : (
            listHospitalAffiliationsResponsive(item.value, item.id)
          )
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .OTHER_PROVIDER_DETAILS:
        return (
          <Layout.Stack alignItems="left" space={12}>
            <ListOtherProviderDetails
              idName={t(
                'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NATIONAL_PROVIDER_IDENTIFIER'
              )}
              idNumber={npi}
            />
            <ListOtherProviderDetails
              idName={t(
                'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.LICENSE_NUMBER'
              )}
              idNumber={licenseNumber?.join(', ')}
            />
          </Layout.Stack>
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .PROVIDER_SPECIALTIES:
        return collapseContent?.map((item) => {
          const referralRequired =
            doShowReferralBanner &&
            referralRequiredSpecialties?.find(
              (rrs) => rrs?.name?.toLowerCase() === item?.toLowerCase()
            );
          return (
            <Layout.Stack key={`collapse-section-${item}`}>
              {capitalizeEachWord(item)}
              {referralRequired ? (
                <Badge data-testid={`${item}-ref-req-mobile`} variant="info">
                  {t('Referral required')}
                </Badge>
              ) : null}
            </Layout.Stack>
          );
        });
      default:
        return collapseContent?.map((item) => (
          <Layout.Stack
            css={{ color: '#4B4D4F', fontSize: '16px' }}
            key={`collapse-section-${item}`}
          >
            {capitalizeEachWord(item)}
          </Layout.Stack>
        ));
    }
  };

  const getAboutDetail = (detailCollapseHeader) => {
    switch (detailCollapseHeader) {
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.EDUCATION:
        return (
          <span data-testid="education-label">
            {education?.map((item) => (
              <Text
                color="#4B4D4F"
                fontWeight="$medium"
                key={`expand-section-${item?.schoolName}`}
                size={18}
              >
                <Layout.Stack alignItems="left" space={2}>
                  {listDegrees(t, item.schoolName, item.degrees)}
                </Layout.Stack>
              </Text>
            ))}
            {showCulturalCompetencies && (
              <Text
                color="#4B4D4F"
                fontWeight="$medium"
                key="expand-section-cultural-competence"
                size={18}
              >
                <Layout.Stack
                  alignItems="left"
                  space={2}
                  style={{ marginTop: '12px' }}
                >
                  <ListCulturalCompetencies
                    culturalCompetencies={culturalCompetencies}
                    mobileScreen={mobileScreen}
                  />
                </Layout.Stack>
              </Text>
            )}
          </span>
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .HOSPITAL_AFFILIATIONS:
        return (
          <Layout.Group>
            {hospitalAffiliations?.slice(0, 6).map((item) =>
              item.value ===
              t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA') ? (
                <Text
                  color="#4B4D4F"
                  fontWeight="$medium"
                  key={`hospital-affiliations-na`}
                  size={mobileScreen ? '$md' : '18px'}
                >
                  {t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NA')}
                </Text>
              ) : (
                hospitalAffiliationsChips(item.value, item.id, !tabletScreen)
              )
            )}
          </Layout.Group>
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .OTHER_PROVIDER_DETAILS:
        return (
          <Layout.Stack alignItems="left" space={24}>
            <ListOtherProviderDetails
              idName={t(
                'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.NATIONAL_PROVIDER_IDENTIFIER'
              )}
              idNumber={npi}
            />
            <ListOtherProviderDetails
              idName={t(
                'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.LICENSE_NUMBER'
              )}
              idNumber={licenseNumber?.join(', ')}
            />
          </Layout.Stack>
        );
      case Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
        .PROVIDER_SPECIALTIES:
        return collapseContent?.map((item) => {
          const referralRequired =
            doShowReferralBanner &&
            referralRequiredSpecialties?.find(
              (rrs) => rrs?.name?.toLowerCase() === item?.toLowerCase()
            );
          return (
            <Layout.Group
              data-testid="provider-with-specialities"
              key={`expand-section-${item}`}
            >
              <Text
                color="#4B4D4F"
                data-testid="provider-with-sub-specialities"
                fontWeight="$medium"
                size={18}
              >
                {capitalizeEachWord(item)}
              </Text>
              {referralRequired ? (
                <Badge data-testid="referral-required" outline variant="info">
                  {t('Referral required')}
                </Badge>
              ) : null}
            </Layout.Group>
          );
        });
      default:
        return collapseContent?.map((item) => (
          <Text
            color="#4B4D4F"
            data-testid="provider-aoe-speciality"
            fontWeight="$medium"
            key={`expand-section-${item}`}
            size={18}
          >
            {capitalizeEachWord(item)}
          </Text>
        ));
    }
  };

  const hospitalAffiliationsViewAll = () => {
    if (
      t(collapseHeader) ===
        t('PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.HOSPITAL_AFFILIATIONS') &&
      (chipStore.coverageType === ReverseCoverageTypesCodes.MEDICAL ||
        ReverseCoverageTypesCodes['BEHAVIORAL HEALTH']) &&
      (mobileScreen
        ? (hospitalAffiliations?.length || 0) > 4
        : (hospitalAffiliations?.length || 0) > 6)
    ) {
      return (
        <Flex>
          <LinkContainer
            analyticsInfo={{
              location: 'body:hospital affiliation',
            }}
            aria-label={`${t('View all')} ${t(collapseHeader)}`
              .replace(/ /g, '-')
              .toLocaleLowerCase()}
            css={{ marginTop: mobileScreen ? '8px' : '' }}
            data-auto-testid="hospital-affiliation-section-container-view-all"
            data-testid="hospital-affiliation-section-container-view-all"
            iconSize="$sm"
            onClick={() => {
              adobeModalTrackEvent(
                Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION
                  .HOSPITAL_AFFILIATIONS_DRAWER
              );
              setIsOpen(true);
            }}
            size={mobileScreen ? '12.64px' : '16px'}
            text={t('View all')}
          />
          <HospitalAffiliationDrawer
            hospitalAffiliations={hospitalAffiliations}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        </Flex>
      );
    }

    return null;
  };

  return (
    <React.Fragment>
      {phoneScreen || isWidget ? (
        <Layout.Group
          css={collapseSection(
            true,
            mobileScreen,
            phoneScreen,
            widgetPhoneScreen,
            WidgetDesktopScreen,
            WidgetPages
          )}
        >
          <Accordion css={CollapseCardHeaderStyle(isWidget)} isCollapsible>
            <Accordion.Item value={accordionItemValue}>
              <Accordion.Trigger>
                <Accordion.Header>{t(collapseHeader)}</Accordion.Header>
              </Accordion.Trigger>
              <Accordion.Content>
                {getAboutDetailResponsive(collapseConstantKey)}
                {hospitalAffiliationsViewAll()}
              </Accordion.Content>
            </Accordion.Item>
          </Accordion>
        </Layout.Group>
      ) : (
        <Layout.Group
          css={collapseSection(false, mobileScreen, phoneScreen)}
          isFullWidth={
            collapseHeader ===
            t(
              'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.HOSPITAL_AFFILIATIONS'
            )
          }
        >
          <Layout.Stack alignItems="left">
            <Flex css={cardHeaderBrowseStyle} justify="start">
              <Heading
                css={h2SmallerFontForResponsive}
                data-testid={`${t(collapseHeader)}`
                  .replace(/ /g, '-')
                  .toLocaleLowerCase()}
                display="h4"
                offset={1}
              >
                {t(collapseHeader)}
              </Heading>
              {hospitalAffiliationsViewAll()}
            </Flex>
            {getAboutDetail(collapseConstantKey)}
          </Layout.Stack>
        </Layout.Group>
      )}
    </React.Fragment>
  );
};
