import { useForm } from '@abyss/web/hooks/useForm';
import { Button } from '@abyss/web/ui/Button';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { CheckboxGroup } from '@abyss/web/ui/CheckboxGroup';
import { Drawer } from '@abyss/web/ui/Drawer';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../../../../../common/ConstantsLagoon';
import { useCoverageType } from '../../../../../../../hooks/useCoverageType';
import { useFeatureFlag } from '../../../../../../../hooks/useFeatureFlag';
import { StoreKeys } from '../../../../../../../hooks/useStore/state';
import { useStore } from '../../../../../../../hooks/useStore/useStore';
import {
  returnSuppressFlag,
  suppressCostEstimatesForPolicies,
} from '../../../../../../../utils/featureSuppress';
import {
  editCompareCheckboxMobileStyle,
  editCompareDrawerMobileStyle,
} from '../../utility/compareDrawer.styled';
import {
  CompareCheckboxInfo,
  MAX_NUM_OF_ATTRIBUTES,
} from '../../utility/compareDrawerConstants';
import {
  getInitialCheckedCount,
  itemToShow,
} from '../../utility/editCompareDrawer.util';
import { getDefaultCheckedCompareAttributes } from '../../utility/getDefaultCheckedCompareAttributes';
import { editCompareButtonRules } from './EditCompareButtonRules';

type Props = {
  items: CompareCheckboxInfo[];
  setChangedTempItems: Function;
  isEditCompareOpen: boolean;
  setOpenEdit: (a: boolean) => void;
  nameOfButtonClickedLast: string;
  setNameOfButtonClickedLast: Function;
};

export const EditCompareDrawerMobile = ({
  items,
  setChangedTempItems,
  isEditCompareOpen,
  setOpenEdit,
  nameOfButtonClickedLast,
  setNameOfButtonClickedLast,
}: Props) => {
  const { t } = useTranslation();
  const itemsToShowInCheckboxes = useMemo(
    () => cloneDeep(items),
    [JSON.stringify(items)]
  );
  const [initialCheckCount, setInitialCheckCount] = useState<number>(0);
  const numChecked = useRef<number>(0);
  const [costEnableFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.COST_ENABLED,
  ]);
  const coverageType = useCoverageType();
  const suppressPremiumCare: boolean = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );

  const suppressPatientReviews: boolean = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );

  const suppressCostEstimates = suppressCostEstimatesForPolicies(
    coverageType,
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.COST_ESTIMATES,
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.COST_ESTIMATES_EMPIRE
  );
  const costOption = costEnableFlag && !suppressCostEstimates;

  const [tierOneFilterCompareFeatureFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.TIER_ONE_FILTER_COMPARE,
  ]);
  const isTier1Plan = useStore(StoreKeys.TIER1_PLAN_FLAG);
  // If the feature_flag TIER_ONE_FILTER_COMPARE is ON, then suppress Tier1
  // identifier based on 'isTier1Plan' field from snackCard api response
  const showTierOnePlan = tierOneFilterCompareFeatureFlag ? isTier1Plan : true;

  const setNumChecked = (count) => {
    numChecked.current = count;
  };

  const defaultAttributes = getDefaultCheckedCompareAttributes(
    coverageType,
    suppressPremiumCare,
    suppressPatientReviews
  );
  const form = useForm({
    defaultValues: {
      'checkbox-form': defaultAttributes,
    },
    values: {
      'checkbox-form': itemsToShowInCheckboxes
        .filter(
          (checkbox) =>
            itemToShow(checkbox.name, costOption, showTierOnePlan) &&
            checkbox.isChecked
        )
        .map((checkbox) => checkbox.name),
    },
  });

  const onSubmit = (data) => {
    if (isEditCompareOpen) {
      const changedTempItems = cloneDeep(items);
      const formData = data['checkbox-form'];
      changedTempItems.forEach((checkbox, index) => {
        const tempCheckBox = checkbox;
        tempCheckBox.isChecked = formData.includes(checkbox.name) || false;
        changedTempItems[index] = tempCheckBox;
      });
      setChangedTempItems(changedTempItems);
    }
  };
  const closeEditCompare = () => {
    setNameOfButtonClickedLast('cancel');
    numChecked.current = initialCheckCount;
  };

  useEffect(() => {
    editCompareButtonRules(
      items,
      setChangedTempItems,
      setOpenEdit,
      setNumChecked,
      nameOfButtonClickedLast,
      setNameOfButtonClickedLast,
      itemsToShowInCheckboxes,
      form,
      defaultAttributes
    );
  }, [nameOfButtonClickedLast]);

  const getApplyButtonState = (useCompareCheckboxes) =>
    !useCompareCheckboxes.some(
      (checkbox) => checkbox.name !== 'inNetwork' && checkbox.isChecked === true
    );

  const handleCheckBoxClick = (checkbox: CompareCheckboxInfo) => {
    // eslint-disable-next-line no-param-reassign
    checkbox.isChecked = !checkbox.isChecked;

    const newNumChecked = getInitialCheckedCount(
      costOption,
      showTierOnePlan,
      itemsToShowInCheckboxes
    );

    setNumChecked(newNumChecked);
  };

  useEffect(() => {
    const newNumChecked = getInitialCheckedCount(
      costOption,
      showTierOnePlan,
      itemsToShowInCheckboxes
    );

    setInitialCheckCount(newNumChecked);
    setNumChecked(newNumChecked);
  }, [costOption, showTierOnePlan, itemsToShowInCheckboxes]);

  return isEditCompareOpen ? (
    <FormProvider
      data-auto-testid="compare-providers-edit-compare-apply-edits-button"
      data-testid="compare-providers-edit-compare-apply-edits-button"
      onSubmit={onSubmit}
      state={form}
    >
      <Drawer
        css={editCompareDrawerMobileStyle}
        footer={
          <Drawer.Section
            css={{
              boxShadow: '0px -1px 6px 0px rgba(0, 0, 0, 0.15)',
            }}
          >
            <Layout.Group alignLayout="center" grow>
              <Button
                css={{ width: '100%' }}
                data-auto-testid="edit-compare-mobile-apply"
                data-testid="edit-compare-mobile-apply"
                isDisabled={getApplyButtonState(itemsToShowInCheckboxes)}
                onClick={() => {
                  setNameOfButtonClickedLast('apply');
                }}
              >
                {t('Apply')}
              </Button>
            </Layout.Group>
          </Drawer.Section>
        }
        isOpen={isEditCompareOpen}
        onClose={() => closeEditCompare()}
        position="bottom"
        size="$md"
        title={
          <Heading color="$gray7" offset={4}>
            {t('Edit comparison')}
          </Heading>
        }
      >
        <Drawer.Section css={{ paddingTop: '0px', paddingBottom: '$sm' }}>
          <Button
            css={{ paddingLeft: '0px' }}
            data-auto-testid="compare-providers-edit-compare-mobile-deselect-all"
            data-testid="compare-providers-edit-compare-mobile-deselect-all"
            onClick={() => {
              setNameOfButtonClickedLast('deselect-all');
            }}
            variant="tertiary"
          >
            {t('Deselect all')}
          </Button>
          <Button
            data-auto-testid="compare-providers-edit-compare-mobile-reset"
            data-testid="compare-providers-edit-compare-mobile-reset"
            onClick={() => {
              setNameOfButtonClickedLast('reset');
            }}
            variant="tertiary"
          >
            {t('Reset')}
          </Button>
        </Drawer.Section>
        <Drawer.Section>
          <CheckboxGroup model="checkbox-form">
            {itemsToShowInCheckboxes
              .filter((checkbox) =>
                itemToShow(checkbox.name, costOption, showTierOnePlan)
              )
              .map((checkbox) => (
                <Checkbox
                  css={editCompareCheckboxMobileStyle}
                  data-auto-testid={`compare-providers-edit-compare-mobile-checkbox-${checkbox.name}`}
                  data-testid={`compare-providers-edit-compare-mobile-checkbox-${checkbox.name}`}
                  isChecked={checkbox.isChecked}
                  isDisabled={
                    numChecked.current >= MAX_NUM_OF_ATTRIBUTES &&
                    !checkbox?.isChecked
                  }
                  key={`CheckBoxKey-${checkbox.name}`}
                  label={t(checkbox.label || '')}
                  onChange={() => handleCheckBoxClick(checkbox)}
                  value={checkbox.name}
                />
              ))}
          </CheckboxGroup>
        </Drawer.Section>
      </Drawer>
    </FormProvider>
  ) : null;
};
