import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Button } from '@abyss/web/ui/Button';
import { Drawer } from '@abyss/web/ui/Drawer';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { Text } from '@abyss/web/ui/Text';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../AdobeTagging/adobeLinkTrackEvent';
import { Constants, PhysicianDirectorySortOrder } from '../../Constants';
import { notMobile, phoneOnly } from '../../ConstantsStyles';
import { handleLinkAndModalTrack } from '../../Utils/adobeTrackUtils';
import {
  DesktopSortText,
  MobileText,
  StyledPopover,
  radioGroupStyles,
  swapIconStyles,
} from './PhysicianDirectory.styled';

const SortingRadioGroup = ({
  setSortOrder,
  sortOrder,
  setIsOpen,
}: {
  setSortOrder: (val: PhysicianDirectorySortOrder) => void;
  sortOrder: PhysicianDirectorySortOrder;
  setIsOpen: (val: boolean) => void;
}) => {
  const { t } = useTranslation();
  const [temporarySortOrder, setTemporarySortOrder] =
    useState<PhysicianDirectorySortOrder>(sortOrder);
  const sortBtnName: string = 'sort-button';
  const azSortingList: string = 'az-sorting-list';

  useEffect(() => {
    const linkName: string = 'Physician sort button';
    const linkLocation: string = `body:${Constants.FACILITY_DETAILS.PHYSICIAN_DIRECTORY}`;
    const modalName: string = `Physician sort`;
    handleLinkAndModalTrack(linkName, linkLocation, modalName);
  }, []);
  return (
    <React.Fragment>
      <RadioGroup
        css={radioGroupStyles}
        data-auto-testid={azSortingList}
        data-testid={azSortingList}
        label={t('SORTBY')}
        onChange={(e: React.ChangeEvent<any>) => {
          setTemporarySortOrder(e.target.value);
          adobeLinkTrackEvent({
            name: `${azSortingList} ${e.target.value}`,
            location: `modal:physician sort`,
            type: 'internal',
            destinationUrl: '',
          });
        }}
        value={temporarySortOrder}
      >
        <RadioGroup.Radio
          css={{
            'abyss-radio-label': {
              color:
                temporarySortOrder === PhysicianDirectorySortOrder.ASCENDING
                  ? '#323334'
                  : '#4B4D4F',
              fontWeight:
                temporarySortOrder === PhysicianDirectorySortOrder.ASCENDING
                  ? 700
                  : 500,
            },
            'abyss-radio-root': {
              padding: '10px 0',
            },
          }}
          label={t(
            'FACILITY_DETAILS.PHYSICIAN_DIRECTORY_TAB.SORT_POPOVER.PROVIDER_ASCENDING'
          )}
          value={PhysicianDirectorySortOrder.ASCENDING}
        />
        <RadioGroup.Radio
          css={{
            'abyss-radio-label': {
              color:
                temporarySortOrder === PhysicianDirectorySortOrder.DESCENDING
                  ? '#323334'
                  : '#4B4D4F',
              fontWeight:
                temporarySortOrder === PhysicianDirectorySortOrder.DESCENDING
                  ? 700
                  : 500,
            },
            'abyss-radio-root': {
              padding: '12px 0',
            },
          }}
          label={t(
            'FACILITY_DETAILS.PHYSICIAN_DIRECTORY_TAB.SORT_POPOVER.PROVIDER_DESCENDING'
          )}
          value={PhysicianDirectorySortOrder.DESCENDING}
        />
      </RadioGroup>
      <Button
        data-auto-testid={sortBtnName}
        data-testid={sortBtnName}
        onClick={() => {
          setSortOrder(temporarySortOrder);
          setIsOpen(false);
          adobeLinkTrackEvent({
            name: `${sortBtnName}`,
            location: `modal:physician sort`,
            type: 'internal',
            destinationUrl: '',
          });
        }}
      >
        {t('Sort')}
      </Button>
    </React.Fragment>
  );
};

export const SortPopover = ({
  setSortOrder,
  sortOrder,
}: {
  setSortOrder: (val: PhysicianDirectorySortOrder) => void;
  sortOrder: PhysicianDirectorySortOrder;
}) => {
  const { t } = useTranslation();
  const desktopScreen = useMediaQuery(notMobile);
  const mobileScreen = useMediaQuery(phoneOnly);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const properties = {
    drawerSize: 'fit-content',
    drawerPosition: 'bottom',
  };
  const textStyles = {
    'abyss-text-root': {
      backgroundColor: 'transparent',
      borderColor: '$gray4',
      height: '42px',
      width: mobileScreen ? '98px' : 'auto',
      display: 'inline-block',
      border: 'solid 1px $gray4',
      borderRadius: '24px',
      cursor: 'pointer',
      '&:hover': {
        background: '#D9E9FA',
      },
      '@screen < $md': {
        border: '2px solid $primary1',
      },
      padding: '$sm $lg $sm $md',
      fontWeight: '$semibold',
      '& div': {
        '&:first-child': {
          marginLeft: 0,
        },
        '&:last-child': {
          marginLeft: 0,
          '& > span': {
            marginRight: '3px',
            color: mobileScreen ? '$primary1' : '#19191A !important',
          },
        },
        '& svg': {
          fill: mobileScreen ? '$primary1' : '#19191A !important',
        },
      },
    },
  };
  const drawerStyles = {
    'abyss-modal-content-container': {
      borderRadius: '20px 20px 0px 0px',
      height: 'auto !important',
      padding: '$md',
      '.abyss-radio-root': {
        '&:hover': {
          backgroundColor: '$tint2',
        },
      },
    },
    'abyss-modal-close-button': {
      top: '12px',
      right: '12px',
    },
    'abyss-modal-header-container': {
      padding: '0',
      marginBottom: '$md',
    },
    'abyss-modal-content-title': {
      fontFamily: '$primary !important',
      fontSize: '$mediumText !important',
      fontWeight: '$bold !important',
      lineHeight: '24px !important',
      color: '$gray-07 !important',
      padding: '0',
    },
  };
  return (
    <React.Fragment>
      <Drawer
        css={drawerStyles}
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        position={properties.drawerPosition}
        size={properties.drawerSize}
        title=""
      >
        <SortingRadioGroup
          setIsOpen={setIsDrawerOpen}
          setSortOrder={setSortOrder}
          sortOrder={sortOrder}
        />
      </Drawer>
      {mobileScreen ? (
        <React.Fragment>
          <Text
            css={textStyles}
            data-auto-testid="physician-directory-sort-button"
            data-testid="physician-directory-sort-button"
            fontWeight={600}
            onClick={() => setIsDrawerOpen(true)}
            size="$md"
            variant="outline"
          >
            <Layout.Group>
              <IconMaterial css={swapIconStyles} icon="swap_vert" />
              {!desktopScreen && (
                <MobileText color="$primary1">{t('Sort')}</MobileText>
              )}
              {desktopScreen && (
                <React.Fragment>
                  <DesktopSortText>
                    {sortOrder === PhysicianDirectorySortOrder.ASCENDING
                      ? t(
                          'FACILITY_DETAILS.PHYSICIAN_DIRECTORY_TAB.SORT_POPOVER.PROVIDER_ASCENDING'
                        )
                      : t(
                          'FACILITY_DETAILS.PHYSICIAN_DIRECTORY_TAB.SORT_POPOVER.PROVIDER_DESCENDING'
                        )}
                  </DesktopSortText>
                  <IconMaterial icon="expand_more" />
                </React.Fragment>
              )}
            </Layout.Group>
          </Text>
        </React.Fragment>
      ) : (
        <StyledPopover
          align="center"
          content={
            <SortingRadioGroup
              setIsOpen={setIsPopoverOpen}
              setSortOrder={setSortOrder}
              sortOrder={sortOrder}
            />
          }
          css={{
            zIndex: 10,
            '.abyss-radio-group-label': {
              marginLeft: '$sm',
              marginRight: '16px',
            },
            '.abyss-radio-root': {
              marginLeft: '$sm',
            },
          }}
          data-auto-testid="sort-popover"
          data-testid="sort-popover"
          onOpenChange={() => {
            setIsPopoverOpen(!isPopoverOpen);
          }}
          open={isPopoverOpen}
          position="right"
          title=""
          width="210px"
          zIndex="99"
        >
          <Text
            css={textStyles}
            data-auto-testid="physician-directory-sort-button"
            data-testid="physician-directory-sort-button"
            fontWeight={600}
            size="$md"
            variant="outline"
          >
            <Layout.Group>
              <IconMaterial css={swapIconStyles} icon="swap_vert" />
              {!desktopScreen && (
                <MobileText color="$primary1">{t('Sort')}</MobileText>
              )}
              {desktopScreen && (
                <React.Fragment>
                  <DesktopSortText>
                    {sortOrder === PhysicianDirectorySortOrder.ASCENDING
                      ? t(
                          'FACILITY_DETAILS.PHYSICIAN_DIRECTORY_TAB.SORT_POPOVER.PROVIDER_ASCENDING'
                        )
                      : t(
                          'FACILITY_DETAILS.PHYSICIAN_DIRECTORY_TAB.SORT_POPOVER.PROVIDER_DESCENDING'
                        )}
                  </DesktopSortText>
                  <IconMaterial icon="expand_more" />
                </React.Fragment>
              )}
            </Layout.Group>
          </Text>
        </StyledPopover>
      )}
    </React.Fragment>
  );
};
