import { useShallow } from 'zustand/react/shallow';

import { useAnalyticsStore } from '../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../store/useAnalyticsStore/analyticsStore';
import { useDetailsStore } from '../../store/useDetailsStore';
import { DetailsStore } from '../../store/useDetailsStore/detailsStore';
import { useErrorStore } from '../../store/useErrorStore';
import { ErrorStore } from '../../store/useErrorStore/errorStore';
import { usePCPStore } from '../../store/usePCPStore';
import { PCPSearchStore } from '../../store/usePCPStore/pcpStore';
import { useSearchStore } from '../../store/useSearchStore';
import { SearchState } from '../../store/useSearchStore/searchStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';

export const useClearStore = () => {
  const { clearAnalyticsState } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      clearAnalyticsState: state.clearAnalyticsState,
    }))
  );
  const { clearDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      clearDetailsStore: state.clearDetailsStore,
    }))
  );
  const { clearErrorStore } = useErrorStore(
    useShallow((state: ErrorStore) => ({
      clearErrorStore: state.clearErrorStore,
    }))
  );
  const { clearPCPSearchState } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      clearPCPSearchState: state.clearPCPSearchState,
    }))
  );
  const { clearSearchStore } = useSearchStore(
    useShallow((state: SearchState) => ({
      clearSearchStore: state.clearSearchStore,
    }))
  );
  const { clearTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      clearTypeaheadSearchStore: state.clearTypeaheadSearchStore,
    }))
  );

  const clearAllStores = () => {
    clearAnalyticsState();
    clearDetailsStore();
    clearErrorStore();
    clearPCPSearchState();
    clearSearchStore();
    clearTypeaheadSearchStore();
  };

  return {
    clearAllStores,
    clearAnalyticsState,
    clearDetailsStore,
    clearErrorStore,
    clearPCPSearchState,
    clearSearchStore,
    clearTypeaheadSearchStore,
  };
};
