import { useMemo } from 'react';

import { Constants } from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../common/Utils';
import {
  LagoonData,
  getValueFromPseudoRollupCodeTable,
} from '../../utils/common';
/* eslint-disable no-sequences */
import { useLagoon } from '../useLagoon';

export const useTypeaheadPseudoRollup = (
  pseudoVertical: string,
  dntDescription: string = '',
  isOXMember: boolean
) => {
  const featureFlags = useLagoon('feature-flags')();
  const oxfordPlnFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.OXFORD_PLN
  );

  const pseudoRollupCodeTable: LagoonData[] = useTypeaheadPseudoRollupTable();
  const pseudoRollupCodeDetails: LagoonData | undefined = useMemo(
    () =>
      getValueFromPseudoRollupCodeTable(
        pseudoVertical,
        pseudoRollupCodeTable,
        dntDescription,
        isOXMember,
        oxfordPlnFlag
      ),
    [pseudoVertical, dntDescription]
  );

  return pseudoRollupCodeDetails;
};

export const useTypeaheadPseudoRollupTable = (): LagoonData[] =>
  useLagoon(Constants.LAGOON_TABLE.TYPEAHEAD_PSEUDO_ROLLUP_CODE)().map(
    (row: any) => {
      const modifiedObject = {
        ...row,
        ['lagoon.updatedAt']: undefined,
        ['lagoon.updatedBy']: undefined,
      };
      return modifiedObject;
    }
  );
