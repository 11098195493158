import React from 'react';

import { useCoverageType } from '../../../../hooks/useCoverageType';
import {
  FacilityDetails,
  FacilityLocationsResponse,
} from '../../../../models/FacilityDetails';
import {
  ProviderDetails,
  ProviderLocation,
} from '../../../../models/ProviderDetails';
import { ReverseCoverageTypesCodes } from '../../../Constants';
import { AllOtherInfosContainer } from './AllOtherInfosContainer';
import { DentalCareAllOtherInfosContainer } from './DentalCareAllOtherInfosContainer';
import { VirtualProviderGroupAllOtherInfo } from './VirtualProviderGroupAllOtherInfo';
import { VisionCareAllOtherInfosContainer } from './VisionCareAllOtherInfosContainer';

export type Props = {
  isFromVirtualProviderGroup?: boolean;
  providerDetails: ProviderDetails | FacilityDetails;
  selectedLocation: ProviderLocation | FacilityLocationsResponse;
};

export const AllOtherInfos = ({
  isFromVirtualProviderGroup = false,
  providerDetails,
  selectedLocation,
}: Props) => {
  const coverageType = useCoverageType();

  const getAllOtherInfos = () => {
    if (isFromVirtualProviderGroup) {
      return (
        <VirtualProviderGroupAllOtherInfo
          providerDetails={providerDetails as ProviderDetails}
          selectedLocation={selectedLocation as ProviderLocation}
        />
      );
    }
    if (coverageType === ReverseCoverageTypesCodes.VISION) {
      return (
        <VisionCareAllOtherInfosContainer
          providerDetails={providerDetails}
          selectedLocation={selectedLocation}
        />
      );
    }
    if (coverageType === ReverseCoverageTypesCodes.DENTAL) {
      return (
        <DentalCareAllOtherInfosContainer
          selectedLocation={selectedLocation as ProviderLocation}
        />
      );
    }

    return (
      <AllOtherInfosContainer
        providerDetails={providerDetails}
        selectedLocation={selectedLocation}
      />
    );
  };

  return <React.Fragment>{getAllOtherInfos()}</React.Fragment>;
};
