import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../Constants';
import { notMobile, phoneOnly } from '../ConstantsStyles';
import {
  DesktopSortText,
  MobileText,
  radioGroupStyles,
  swapIconStyles,
} from '../ProviderDetailTabs/PhysicianDirectory/PhysicianDirectory.styled';
import { AllPatientReviewsSortMobile } from './AllPatientReviewsSortMobile';
import { PatientReviewsSortOrder } from './AllPatientReviewsSortOrder';

export const AllPatientReviewsSortingRadioGroup = ({
  setSortOrder,
  sortOrder,
  setIsOpen,
}: {
  setSortOrder: (val: PatientReviewsSortOrder) => void;
  sortOrder: PatientReviewsSortOrder;
  setIsOpen: (val: boolean) => void;
}) => {
  const { t } = useTranslation();
  const [temporarySortOrder, setTemporarySortOrder] =
    useState<PatientReviewsSortOrder>(sortOrder);

  return (
    <React.Fragment>
      <RadioGroup
        css={radioGroupStyles}
        data-auto-testid="all-patient-reviews-sort-order"
        data-testid="all-patient-reviews-sort-order"
        label={t('SORT_BUTTONS.SORT_REVIEWS')}
        onChange={(e: React.ChangeEvent<any>) => {
          setTemporarySortOrder(e.target.value);
          adobeLinkTrackEvent({
            name: `all-patient-reviews-sort-order ${e.target.value}`,
            location: `modal:all patient reviews sort`,
            type: 'internal',
            destinationUrl: '',
          });
        }}
        value={temporarySortOrder}
      >
        <RadioGroup.Radio
          css={{
            'abyss-radio-label': {
              color:
                temporarySortOrder === PatientReviewsSortOrder.MOST_RECENT
                  ? '#323334'
                  : '#4B4D4F',
              fontSize: '18px',
            },
            'abyss-radio-root': {
              alignItems: 'center',
              padding: '12px 0',
            },
          }}
          label={t(PatientReviewsSortOrder.MOST_RECENT)}
          value={PatientReviewsSortOrder.MOST_RECENT}
        />
        <RadioGroup.Radio
          css={{
            'abyss-radio-label': {
              color:
                temporarySortOrder === PatientReviewsSortOrder.HIGHEST_RATING
                  ? '#323334'
                  : '#4B4D4F',
              fontSize: '18px',
            },
            'abyss-radio-root': {
              padding: '12px 0',
              alignItems: 'center',
              marginTop: '0px !important',
            },
          }}
          label={t(PatientReviewsSortOrder.HIGHEST_RATING)}
          value={PatientReviewsSortOrder.HIGHEST_RATING}
        />
        <RadioGroup.Radio
          css={{
            'abyss-radio-label': {
              color:
                temporarySortOrder === PatientReviewsSortOrder.LOWEST_RATING
                  ? '#323334'
                  : '#4B4D4F',
              fontSize: '18px',
            },
            'abyss-radio-root': {
              padding: '12px 0',
              alignItems: 'center',
              marginTop: '0px !important',
            },
          }}
          label={t(PatientReviewsSortOrder.LOWEST_RATING)}
          value={PatientReviewsSortOrder.LOWEST_RATING}
        />
      </RadioGroup>
      <Button
        css={{
          marginTop: '24px',
          height: '42px',
          width: 'auto',
          fontWeight: 600,
        }}
        data-auto-testid="all-patient-reviews-sort-order"
        data-testid="all-patient-reviews-sort-order"
        onClick={() => {
          setSortOrder(temporarySortOrder);
          setIsOpen(false);
          adobeLinkTrackEvent({
            name: `all-patient-reviews-sort-order`,
            location: `modal:all patient reviews sort`,
            type: 'internal',
            destinationUrl: '',
          });
        }}
      >
        {t('Sort')}
      </Button>
    </React.Fragment>
  );
};

export const StyledPopover = styled(Popover, {
  '&.abyss-popover-content-container': {
    padding: '8px',
    zIndex: '999 !important',
  },
  '&.abyss-popover-close-button': {
    marginTop: '20px',
    marginRight: '$md',
  },
  '&.abyss-popover-close-icon': {
    height: '24px',
    width: '24px',
  },
});

export const AllPatientReviewsSortDesktop = ({
  setSortOrder,
  sortOrder,
  setIsOpen,
  isOpen,
  handleOnClick,
}: {
  setSortOrder: (val: PatientReviewsSortOrder) => void;
  sortOrder: PatientReviewsSortOrder;
  setIsOpen: (val: boolean) => void;
  isOpen: boolean;
  handleOnClick: () => void;
}) => {
  const { t } = useTranslation();
  const desktopScreen = useMediaQuery(notMobile);
  const mobileScreen = useMediaQuery(phoneOnly);
  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutsidePopover = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        !event.target.closest('.abyss-popover-content')
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutsidePopover);
    document.addEventListener('keyup', handleClickOutsidePopover);
    return () => {
      document.removeEventListener('mousedown', handleClickOutsidePopover);
      document.removeEventListener('keyup', handleClickOutsidePopover);
    };
  }, []);

  return (
    <div ref={popoverRef}>
      <StyledPopover
        align="center"
        content={
          <AllPatientReviewsSortingRadioGroup
            setIsOpen={setIsOpen}
            setSortOrder={setSortOrder}
            sortOrder={sortOrder}
          />
        }
        css={{
          zIndex: 9999,
        }}
        data-auto-testid="sort-popover"
        data-testid="sort-popover"
        onClick={(event) => {
          if (event.target.matches('.abyss-popover-close-icon')) {
            setIsOpen(false);
          }
        }}
        open={isOpen}
        position="bottom"
        title=""
        width="277px"
      >
        <Button
          css={{
            'abyss-button-root': {
              marginRight: '$md',
              backgroundColor: 'transparent',
              borderColor: '$gray4',
              height: '42px',
              width: 'auto',
              '@screen < $md': {
                border: '2px solid $primary1',
              },
              padding: '$sm $lg $sm $md',
              fontWeight: '$semibold',
              '& div': {
                '&:first-child': {
                  marginLeft: 0,
                },
                '&:last-child': {
                  marginLeft: 0,
                  '& > span': {
                    marginRight: '3px',
                    color: '#19191A',
                  },
                },
                '& svg': {
                  fill: mobileScreen ? '$primary1' : '#19191A !important',
                },
              },
            },
          }}
          data-auto-testid="all-patient-reviews-sort-button"
          data-testid="all-patient-reviews-sort-button"
          fontWeight={600}
          onClick={() => {
            if (isOpen) {
              setIsOpen(false);
            } else {
              handleOnClick();
            }
          }}
          size="$md"
          variant="outline"
        >
          <Layout.Group>
            <IconMaterial css={swapIconStyles} icon="swap_vert" />
            {!desktopScreen && (
              <MobileText color="$primary1">
                {Constants.SORT_BUTTONS.SORT}
              </MobileText>
            )}
            {desktopScreen && (
              <React.Fragment>
                <DesktopSortText>{t(sortOrder)}</DesktopSortText>
                <IconMaterial icon="expand_more" />
              </React.Fragment>
            )}
          </Layout.Group>
        </Button>
      </StyledPopover>
    </div>
  );
};

export const AllPatientReviewsSortPopover = ({
  setSortOrder,
  sortOrder,
  setIsOpen,
  isOpen,
  handleOnClick,
}: {
  setSortOrder: (val: PatientReviewsSortOrder) => void;
  sortOrder: PatientReviewsSortOrder;
  setIsOpen: (val: boolean) => void;
  isOpen: boolean;
  handleOnClick: () => void;
}) => {
  const mobileScreen = useMediaQuery(phoneOnly);

  return !mobileScreen ? (
    <AllPatientReviewsSortDesktop
      handleOnClick={handleOnClick}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      setSortOrder={setSortOrder}
      sortOrder={sortOrder}
    />
  ) : (
    <AllPatientReviewsSortMobile
      isOpen={isOpen}
      onApply={setIsOpen}
      setIsOpen={setIsOpen}
      setSortOrder={setSortOrder}
      sortOrder={sortOrder}
    />
  );
};
