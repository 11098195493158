import { config } from '@abyss/web/tools/config';

export const nationalVirtualProviderGroups = {
  oshiHealth: {
    imageLocation: `${config(
      'CDN_BASE_URL'
    )}/cdn/assets/images/oshi_health.png`,
    alt: 'oshi health',
  },
  oshiHealthSnack: {
    imageLocation: `${config(
      'CDN_BASE_URL'
    )}/cdn/assets/images/oshi_health_snack.png`,
    alt: 'oshi health',
  },
  cove: {
    imageLocation: `${config('CDN_BASE_URL')}/cdn/assets/images/cove.png`,
    alt: 'cove',
  },
  drOnDemand: {
    imageLocation: `${config(
      'CDN_BASE_URL'
    )}/cdn/assets/images/dr_on_demand.png`,
    alt: 'doctor on demand',
  },
  kaiaHealth: {
    imageLocation: `${config(
      'CDN_BASE_URL'
    )}/cdn/assets/images/kaia_health.png`,
    alt: 'kaia health',
  },
  expressable: {
    imageLocation: `${config(
      'CDN_BASE_URL'
    )}/cdn/assets/images/expressable.png`,
    alt: 'expressable',
  },
  dialCare: {
    imageLocation: `${config('CDN_BASE_URL')}/cdn/assets/images/dial_care.png`,
    alt: 'dial care',
  },
};
