import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Fullscreen } from '@abyss/web/ui/Fullscreen';
import { Text } from '@abyss/web/ui/Text';

export const ResultFullscreen = styled(Fullscreen, {
  'abyss-modal-close-button': {
    marginTop: '$md',
    marginRight: '$sm',
  },
  'abyss-modal-header-container': {
    paddingBottom: '$md',
    borderBottom: 'solid 1px $gray4',
  },
});

export const HeaderWrapper = styled(Flex, {
  alignItems: 'baseline',
  marginBottom: '8px',
  color: '$info1',
  flexWrap: 'nowrap',
  '@screen < $md': {
    marginBottom: '0px',
    padding: '16px $md 12px $md',
    justifyContent: 'space-between',
  },
});

export const ResultWrapper = styled(Flex, {
  gap: '$xs',
  alignItems: 'center',
  '@screen < $md': {
    gap: '4px',
    alignItems: 'start',
  },
});

export const NoResultsFoundContainer = styled('div', {
  '@screen < $md': {
    marginLeft: '16px',
  },
});

export const headerTextStyle = {
  '&.abyss-heading-root': {
    display: 'contents !important',
    '@screen < $sm': {
      fontSize: '$md !important',
      lineHeight: '$md !important',
    },
  },
};

export const HeadingContainer = styled('div', {
  alignItems: 'center',
  display: 'block',

  '& div': {
    display: 'inline-flex',
    marginLeft: '4px',
  },
});

export const ResultText = styled(Text, {
  color: '#323334',
  fontWeight: '$medium',
  fontSize: '14.22px !important',
});

export const ButtonStyle = {
  '@screen < $sm': {
    top: '2px',
    left: '4px',
  },
};

export const viewAllTextStyle = {
  color: '$info1',
  fontWeight: '$bold',
  fontSize: '12.64px',
  '@screen < $md': {
    width: 'max-content',
  },
  '@screen < $sm': {
    fontWeight: '$medium',
    fontSize: '14.22px',
  },
};

export const LinkWrapper = styled(Flex, {
  marginLeft: '$md',
  '@screen < $md': {
    marginLeft: '0px',
  },
});

export const ListWrapper = styled(Flex, {
  gap: '$lg',
  overflowX: 'auto',
  '@screen < $md': {
    gap: '$md',
  },
});

export const DataCardStyle = {
  width: '408px',
  '@screen < $sm': {
    width: '320px',
  },
};

export const CheckBoxDataCardStyle = {
  width: '100%',
  '@media (max-width: 420px)': {
    width: '320px',
  },
  '@media (max-width: 390px)': {
    width: '300px',
  },
};

export const InfoPopoverDrawerStyle = {
  'abyss-modal-close-button': {
    marginTop: '$md',
    marginRight: '8px',
  },
  'abyss-modal-header-container': {
    marginLeft: '$sm',
  },
  'abyss-modal-content-container': {
    borderRadius: '20px 20px 0px 0px',
    height: 'auto !important',
  },
  'abyss-modal-body': {
    marginLeft: '$lg',
    marginRight: '$lg',
    marginBottom: '$md',
  },
};

export const mixedResultCarouselStyle = (
  count: number,
  width: number,
  isLoading?: boolean
) => ({
  'abyss-carousel-root': {
    width: '100%',
  },
  'abyss-carousel-swiper-container': {
    display: 'flex',
    willChange: 'transform',
    zIndex: 0,
    width: count === 2 ? '1200px' : '100%',
  },
  'abyss-carousel-slide-container': {
    position: 'relative',
    marginLeft: isLoading && '-18px',
    width: count <= 2 ? '100%' : '830px',
    alignItems: 'end',
    '@screen < $sm': {
      width: '320px',
      marginLeft: '16px',
    },
  },
  'abyss-carousel-minimal-controls': {
    visibility:
      (count <= 2 && width > 1250) ||
      (count <= 1 && width < 1250) ||
      (count === 2 && width < 1250 && width > 872)
        ? 'hidden'
        : 'visible',
    marginTop: '12px',
    padding: '0px',
    height: '20px',
    'button.abyss-carousel-minimal-pagination-container': {
      padding: ' 0 10px 0 10px',
      display: 'block',
    },
    zIndex: 0,
  },
  'abyss-carousel-minimal-nav-icon-right': {
    width: '20px',
    height: '20px',
  },
  'abyss-carousel-minimal-nav-container-right': {
    width: '20px',
    height: '20px',
    padding: '0px',
    top: '-3px',
  },
  'abyss-carousel-minimal-nav-container-left': {
    width: '20px',
    height: '20px',
    padding: '0px',
    top: '-3px',
  },

  'abyss-carousel-minimal-nav-icon-left': {
    width: '20px',
    height: '20px',
  },
  'abyss-carousel-minimal-pagination-container': {
    padding: '0 19px 0 19px',
  },
});
export const BottomDataCardStyle = {
  '@screen < $sm': {
    width: '343px',
    pointerEvents: 'auto',
  },
};
