import { Layout } from '@abyss/web/ui/Layout';

import { LocationItem } from './LocationItem';

export const LocationSuggestionStack = ({
  suggestions,
  onSuggestionClick,
  activeSuggestion,
}) => (
  <Layout.Stack
    alignItems="left"
    data-testid="location-suggestions-container-option-list"
  >
    <ul id="suggested-locations-list" role="listbox">
      {suggestions?.map((location, index) => (
        <LocationItem
          activeSuggestion={activeSuggestion}
          data-testid="LocationSuggestionStack-suggested-location"
          index={index}
          key={index}
          location={location}
          onClick={onSuggestionClick}
          suggestions={suggestions}
        />
      ))}
    </ul>
  </Layout.Stack>
);
