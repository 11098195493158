import { styled } from '@abyss/web/tools/styled';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';

export const DefaultErrorWrapper = styled('div', {
  background: '#FFF',
  height: '363px',
});

export const LayoutStackStyles = styled(Layout.Stack, {
  padding: '24px 16px',
});

export const ProviderDetailsStyles = styled(Text, {
  '&.abyss-text-root': {
    lineHeight: '32px',
  },
});

export const ErrorMsgStyles = styled(Text, {
  '&.abyss-text-root': {
    lineHeight: '24px',
  },
});
