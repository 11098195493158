import find from 'lodash/find';
import React from 'react';

import * as LazyComponents from '../../frontends/ProviderSearch/routes/PSXHome/lazyComponents';
import { useLagoon } from '../../hooks/useLagoon';
import { getCurrentMember, isOHCNSMember } from '../../utils/user.utils';
import { CareCategories } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { DisclaimersContainer } from '../DisclaimersContainer/DisclaimersContainer';
import { isMnrMember } from '../Utils/memberUtils/memberUtils';

export type Props = {
  selectedChipValue: string;
};

export const DisclaimerContent = ({ selectedChipValue }: Props) => {
  const { lineOfBusiness = '' } = getCurrentMember();
  const isMNR = isMnrMember(lineOfBusiness);
  const uiMessaging = useLagoon('ui-messaging')();

  const OHCNSpolicyIds = find(uiMessaging, {
    key: ConstantsLagoon.UI_MESSAGING.STATE_SPECIFIC_DISCLAIMER,
  })?.policyIDs;

  const isOHCNS = isOHCNSMember(OHCNSpolicyIds);

  const getDisclaimer = (selectValue) => {
    switch (selectValue) {
      case CareCategories.PRIMARY_CARE:
      case CareCategories.MEDICAL_SPECIALISTS:
      case CareCategories.BEHAVIORAL_HEALTH:
      case CareCategories.DENTAL:
        return <LazyComponents.DisclaimersContainer />;
      case CareCategories.VISION:
        return <LazyComponents.DisclaimersContainer isVisionCare />;
      default:
        return null;
    }
  };

  if (isOHCNS) {
    return <DisclaimersContainer />;
  }

  return isMNR ? (
    <LazyComponents.DisclaimersContainer isLandingPage />
  ) : (
    getDisclaimer(selectedChipValue)
  );
};
