import mapboxgl from 'mapbox-gl';

import { Constants } from '../../../../common/Constants';

export const getSidePanelArrowIcon = (closeSidePanel: boolean) =>
  closeSidePanel ? 'keyboard_arrow_right' : 'keyboard_arrow_left';

export const getDataCardMinHeight = (mobileScreen: boolean) =>
  mobileScreen ? '180px' : '196px';

export const getFinalProviderResults = (selectedItems: any[], results: any[]) =>
  selectedItems.length > 0 ? selectedItems : results;

export const mapViewupdatePin = (
  practitioner,
  flyToPin,
  popupContent,
  setPopupContent,
  map
) => {
  const popup = document.getElementsByClassName('mapboxgl-popup');
  if (
    practitioner &&
    practitioner.providerId === popupContent?.providerId &&
    popup.length
  ) {
    return;
  }
  if (popup.length) {
    for (let i = 0; i < popup.length; i++) {
      popup[i].remove();
    }
  }
  setPopupContent({ ...practitioner });
  if (!practitioner) return;
  const dataCardHTML = document.querySelector(
    `[id^=data-card-for-map-${practitioner.providerId}]`
  );
  const height = (dataCardHTML as HTMLElement).offsetHeight || 198;
  const width = (dataCardHTML as HTMLElement).offsetWidth || 408;
  if (practitioner?.address?.line?.[0]) {
    new mapboxgl.Popup({
      closeButton: false,
    })
      .setLngLat([+practitioner.longitude, +practitioner.latitude])
      .setHTML(
        `<div class="datacard-popup" style="height:${height}px;width:${width}px;border:none;visibility:hidden;"></div>`
      )
      .addTo(map.current);
  } else {
    new mapboxgl.Popup({
      closeButton: false,
    })
      .setLngLat(map.current.getCenter())
      .setHTML(
        `<div class="datacard-popup" style="height:${height}px;width:${width}px;border:none;visibility:hidden;"></div>`
      )
      .addTo(map.current);
  }

  document.querySelectorAll('.mapboxgl-popup-tip').forEach((el) => {
    // eslint-disable-next-line no-param-reassign
    (el as HTMLElement).style.display = 'none';
  });

  if (flyToPin) {
    map.current?.flyTo({
      center: [+practitioner.longitude, +practitioner.latitude],
      zoom: Constants.DEVICE_LOCATION.ZOOM_HOVER,
    });
  }
};
