import { styled } from '@abyss/web/tools/styled';
import { Badge } from '@abyss/web/ui/Badge';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';

export const LocationCardWrapper = styled('button', {
  background: '$primary2',
  border: '1px solid #E5E5E6',
  boxShadow: '0px 1px 4px rgba(25, 25, 26, 0.16)',
  borderRadius: '12px',
  cursor: 'pointer',
  width: '408px',
  textAlign: 'left',
  padding: '12px $md 12px 12px',
  '@screen < $sm': {
    width: '100%',
  },
  '&.highlight': {
    border: '3px solid $secondary1',
  },
  '&.location-card-map-view': {
    minWidth: '320px',
    '.location-details': {
      '>div': {
        marginBottom: 0,
        '&:last-child': {
          marginTop: '10px',
        },
      },
    },
  },
});

export const LocationCardGroup = styled(Layout.Group, {
  '&.abyss-layout-group': {
    alignItems: 'flex-start',
  },
});

export const LocationDetailsStack = styled(Flex, {
  flex: 1,
  gap: '7px',
  '>span': {
    overflowWrap: 'anywhere',
    width: '100%',
  },
});

export const LocationBadgeRow = styled(Flex, {
  flex: 1,
  height: '20px',
});

export const MilesButton = styled('button', {
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});

export const AcceptingNewPatientsBadge = styled(Badge, {
  fontSize: '12px',
});
