import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';

export const BtnWrapper = styled(Flex, {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '32px',
  alignSelf: 'stretch',
  marginTop: '32px',
});
