import { usePagination } from '@abyss/web/hooks/usePagination';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import { Modal } from '@abyss/web/ui/Modal';
import { Text } from '@abyss/web/ui/Text';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';

import { useAdobePageTrackEvent } from '../../../../../hooks/adobeHook/useAdobePageTrackEvent';
import {
  HealthGradesBulkInfo,
  Survey,
} from '../../../../../models/Healthgrades';
import { PatientReviewsSortOrder } from '../../../../AllPatientReviews/AllPatientReviewsSortOrder';
import { Constants } from '../../../../Constants';
import { PatientReviewsModal } from '../../../../ProviderDetailTabs/About/PatientReviews/PatientReviewModal';
import {
  StyledIcon,
  StyledIconContainer,
  modalStyles,
} from '../../../../ProviderDetailTabs/About/PatientReviews/PatientReviews.styled';
import { handleLinkAndModalTrack } from '../../../../Utils/adobeTrackUtils';
import { RatingsSection } from './RatingsSection';
import {
  AllPatientReviewsContainer,
  OuterContainer,
} from './RatingsSection.styled';

type Props = {
  healthgradesReview: HealthGradesBulkInfo;
  setViewAllReviews(val: boolean): void;
};

export const PatientReviewsAll = ({
  healthgradesReview,
  setViewAllReviews,
}: Props) => {
  const [openReview, setOpenReview] = useState<Survey | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [sortOrder, setSortOrder] = useState<PatientReviewsSortOrder>(
    PatientReviewsSortOrder.MOST_RECENT
  );
  const [pageSize, setPageSize] = useState<number>(10);

  const { gotoPage, state, ...paginationProps } = usePagination({
    pages: Math.ceil((healthgradesReview?.surveys?.length || 0) / pageSize),
  });
  const { currentPage, pageIndex, pageCount } = state;

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: 'about:patient reviews',
    sitesectionLevel1: Constants.ADOBE_TRACKING.DETAILS_SITESECTION1,
    sitesectionLevel2: `provider details`,
    impressionBlock: {
      type: 'provider',
      indicator: '',
    },
  });

  useEffect(() => {
    adobePageTrackEvent();
  }, []);

  const modalRef = useRef<HTMLElement>();
  useEffect(() => {
    if (openReview) {
      const linkName: string = 'patient-reviews-section';
      const linkLocation: string = `body:${Constants.PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.WHAT_PATIENTS_ARE_SAYING}:page ${currentPage}`;
      const modalName: string = 'patient review';
      handleLinkAndModalTrack(linkName, linkLocation, modalName);
      modalRef?.current?.focus();
      setIsModalOpen(true);
    }
  }, [openReview]);

  const handleOnClick = () => {
    handleLinkAndModalTrack(
      `all-patient-reviews-sort-order`,
      `modal:all patient reviews sort`,
      'all patient reviews sort'
    );
    setIsOpen(true);
  };

  return (
    <React.Fragment>
      <Modal
        css={modalStyles}
        data-testid="provider-details-patient-reviews-all"
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setOpenReview(null);
        }}
        ref={modalRef}
        scrollableFocus={true}
      >
        <Modal.Section>
          <PatientReviewsModal review={openReview} />
        </Modal.Section>
      </Modal>
      <OuterContainer>
        <AllPatientReviewsContainer>
          <Link
            css={{
              marginBottom: '12px',
            }}
            data-auto-testid="back-to-provider-details"
            data-testid="back-to-provider-details"
            isStandardAnchor
            onClick={() => setViewAllReviews(false)}
          >
            <IconMaterial
              color="$interactive1"
              css={{ width: '24px', height: '24px', marginRight: '4px' }}
              icon="arrow_back"
            />
            <Text color="$interactive1" fontWeight="$bold">
              {t('BACK')}
            </Text>
          </Link>
          <StyledIconContainer>
            <StyledIcon
              icon="healthgrades"
              isScreenReadable
              size={132}
              title={t('HEALTH_GRADES_LOGO_TITLE')}
            />
          </StyledIconContainer>
          <RatingsSection
            currentPage={currentPage}
            gotoPage={gotoPage}
            handleOnClick={handleOnClick}
            healthgradesReview={healthgradesReview}
            isOpen={isOpen}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            paginationProps={paginationProps}
            setIsOpen={setIsOpen}
            setPageSize={setPageSize}
            setReviewModal={setOpenReview}
            setSortOrder={setSortOrder}
            sortOrder={sortOrder}
          />
        </AllPatientReviewsContainer>
      </OuterContainer>
    </React.Fragment>
  );
};
