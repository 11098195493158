import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { AnalyticsState, AnalyticsStore, StoreKeys } from './analyticsStore';

const store = (set) => ({
  [StoreKeys.ANALYTICS_STATE]: {},
  [StoreKeys.SET_ANALYTICS_STATE]: (values: AnalyticsState) =>
    set((state) => ({
      analyticsState: { ...state.analyticsState, ...values },
    })),
  [StoreKeys.CLEAR_ANALYTICS_STATE]: (defaultValues = {}) =>
    set({ analyticsState: { ...defaultValues } }),
});

const analyticsStore = persist(store, {
  name: 'analyticsStore',
  storage: createJSONStorage(() => sessionStorage),
  merge: (
    persistedState: AnalyticsStore,
    currentState: AnalyticsStore
  ): AnalyticsStore => ({
    ...currentState,
    ...persistedState,
  }),
}) as any;

export const useAnalyticsStore = create(devtools(analyticsStore));
