import { Layout } from '@abyss/web/ui/Layout';
import React from 'react';

import { ChoosePCPAlert } from '../../../../common/Alert/ChoosePCPAlert';
import { CardWrapper } from '../../../../common/CardWrapper';
import {
  CareCategories,
  Constants,
  ProviderTypes,
} from '../../../../common/Constants';
import { DisclaimerContent } from '../../../../common/DisclaimerContent';
import { EAPCode } from '../../../../common/EAPCode';
import { EAPMixResultsContainer } from '../../../../common/EAPCode/EAPCode.styled';
import { ResultSection } from '../../../../common/ResultSection/ResultSection';
import { HealthGradesContainerStyled } from '../PSXHome/HealthGradesContainer.styled';
import { SearchResultsTitle } from './SearchResultsTitle';

type Props = {
  acceptingNewPatients: boolean;
  choosePCP: boolean;
  contextValue: string;
  data;
  dependentSeqNbr: string;
  eapCodeFlag: boolean;
  facilitiesHeaders;
  isBHCare: boolean;
  isMixedSuggestions: boolean;
  isPageTracked: boolean;
  isNameSearchEnabled?: boolean;
  isSpecialtySearchEnabled?: boolean;
  medGrpIsLoading: boolean;
  medicalGroupHeaders;
  onSpecialtyResultClicked(isSpecialtySearch: boolean, title: string): void;
  orgIsLoading: boolean;
  preferredFacility: string[];
  providerHeaders;
  providerIsLoading: boolean;
  resultType: string;
  searchMethod: string;
  searchTerm: string;
  searchTitle: string;
  searchType: string;
  showFacilities: boolean;
  showProviders: boolean;
  showProviderGroups: boolean;
  specialtyCode: string;
  specialtySearch: boolean;
  userZip: string;
  virtualCare: boolean;
  hasOnlyPseudoRollupCodes: boolean;
  llmFlag?: boolean;
  areaOfExpertise: string[];
  specialtyCodeFromQuery: string[];
  genderCode: string[];
  virtualCareCode: string[];
};

export const SearchedResultSectionData = ({
  acceptingNewPatients,
  dependentSeqNbr,
  preferredFacility,
  providerHeaders,
  medicalGroupHeaders,
  facilitiesHeaders,
  searchTitle,
  searchMethod,
  searchTerm,
  searchType,
  contextValue,
  specialtyCode,
  userZip,
  choosePCP,
  isMixedSuggestions,
  isNameSearchEnabled,
  isSpecialtySearchEnabled,
  onSpecialtyResultClicked,
  resultType,
  specialtySearch,
  data,
  eapCodeFlag,
  isBHCare,
  providerIsLoading,
  medGrpIsLoading,
  orgIsLoading,
  isPageTracked,
  showFacilities,
  showProviders,
  showProviderGroups,
  virtualCare,
  hasOnlyPseudoRollupCodes,
  llmFlag,
  areaOfExpertise,
  specialtyCodeFromQuery,
  genderCode,
  virtualCareCode,
}: Props) => {
  const ResultsSectionMain = (
    res,
    secType,
    count,
    sectionProviderType,
    radius,
    isLoading,
    headers
  ) => (
    <ResultSection
      acceptingNewPatients={acceptingNewPatients}
      areaOfExpertise={areaOfExpertise}
      dependentSeqNbr={dependentSeqNbr}
      genderCode={genderCode}
      headers={headers}
      isLoading={isLoading}
      llmFlag={llmFlag}
      preferredFacility={preferredFacility}
      providerType={sectionProviderType}
      results={res}
      search={searchTitle}
      searchMethod={searchMethod}
      searchRadius={radius}
      searchTerm={searchTerm}
      searchType={searchType}
      sectionType={secType}
      selectedChip={contextValue}
      specialtyCode={specialtyCode}
      specialtyCodeFromQuery={specialtyCodeFromQuery}
      totalCount={count}
      userZip={userZip}
      virtualCare={virtualCare}
      virtualCareCode={virtualCareCode}
    />
  );

  return (
    <React.Fragment>
      <CardWrapper>
        <Layout.Stack grow space={0}>
          <ChoosePCPAlert />
          <SearchResultsTitle
            choosePCP={choosePCP}
            hasOnlyPseudoRollupCodes={hasOnlyPseudoRollupCodes}
            isMixedSuggestions={isMixedSuggestions}
            isNameSearchEnabled={isNameSearchEnabled}
            isSpecialtySearchEnabled={isSpecialtySearchEnabled}
            offset={1}
            onSpecialtyClick={onSpecialtyResultClicked}
            resultType={resultType}
            searchTitle={searchTitle}
            specialtySearch={specialtySearch}
          />
          {contextValue === CareCategories.BEHAVIORAL_HEALTH &&
            eapCodeFlag &&
            isBHCare &&
            isPageTracked && (
              <EAPMixResultsContainer>
                <EAPCode />
              </EAPMixResultsContainer>
            )}
          {contextValue !== CareCategories.FACILITIES &&
          contextValue !== CareCategories.HEALTH_SUPPLIES &&
          showProviders
            ? ResultsSectionMain(
                data?.providers,
                Constants.SEARCH_TYPES.PROVIDER,
                data?.totalCountPractitioners,
                ProviderTypes.PRACTITIONER,
                data?.practitionerSearchRadius,
                providerIsLoading,
                providerHeaders
              )
            : null}
          {contextValue !== CareCategories.FACILITIES &&
          contextValue !== CareCategories.HEALTH_SUPPLIES &&
          showProviderGroups
            ? ResultsSectionMain(
                data?.medicalGroup,
                Constants.SEARCH_TYPES.PROVIDER_GROUP,
                data?.totalCountMedicalGroup,
                ProviderTypes.MEDICAL_GROUP,
                data?.medicalGroupSearchRadius,
                medGrpIsLoading,
                medicalGroupHeaders
              )
            : null}
          {!choosePCP && showFacilities
            ? ResultsSectionMain(
                data?.facilities,
                Constants.SEARCH_TYPES.FACILITY,
                data?.totalCountOrganizations,
                ProviderTypes.ORGANIZATION,
                data?.organizationSearchRadius,
                orgIsLoading,
                facilitiesHeaders
              )
            : null}
        </Layout.Stack>
      </CardWrapper>
      <HealthGradesContainerStyled>
        <DisclaimerContent selectedChipValue={contextValue} />
      </HealthGradesContainerStyled>
    </React.Fragment>
  );
};
