import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { SaveAndCancelButton } from './ChoosePCPLocation.styled';

type Props = {
  handleCancel(): void;
  handleSaveLocation(): void;
  isLocationSelected: boolean | null;
  isUpdatingPCP: boolean;
};

export const ChoosePCPLocationButtonRow = ({
  handleCancel,
  handleSaveLocation,
  isLocationSelected,
  isUpdatingPCP,
}: Props) => {
  const { t } = useTranslation();

  return isUpdatingPCP ? (
    <LoadingSpinner
      ariaLoadingLabel="Updating PCP"
      isLoading={isUpdatingPCP}
      size="$sm"
    />
  ) : (
    <React.Fragment>
      <Layout.Group space={16}>
        <SaveAndCancelButton
          className="cancel-button"
          data-auto-testid="choose-pcp-cancel"
          data-testid="choose-pcp-cancel"
          onClick={handleCancel}
          variant="outline"
        >
          {t('CANCEL')}
        </SaveAndCancelButton>
        <SaveAndCancelButton
          after={
            <IconMaterial
              color={!isLocationSelected ? '$gray5' : '$white'}
              icon="chevron_right"
            />
          }
          className="save-button"
          data-auto-testid="choose-pcp-save-location"
          data-testid="choose-pcp-save-location"
          isDisabled={!isLocationSelected}
          onClick={handleSaveLocation}
        >
          {t('CHOOSE_PCP_FLOW.SAVE_LOCATION')}
        </SaveAndCancelButton>
      </Layout.Group>
    </React.Fragment>
  );
};
