import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Pagination } from '@abyss/web/ui/Pagination';
import { Text } from '@abyss/web/ui/Text';

import { PSXIcon } from '../../../PSXIcon';

export const Container = styled('div', {
  border: '1px solid #E5E5E6',
  borderLeft: 'none',
  borderRight: 'none',
  marginBottom: '$sm',

  '@screen < $md': {
    marginBottom: '0px',
    boxSizing: 'border-box',
  },
});

export const StyledIconContainer = styled('div', {
  '@screen < $sm': {
    marginBottom: '12px',
  },
});
export const StyledIcon = styled(PSXIcon, {
  height: '28px !important',
});

export const ContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '12px',
  '@screen < $md': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '100%',
    height: 'auto',
    background: '$white',
    flex: 'none',
    order: '3',
    flexGrow: '0',
  },
});

export const RatingSectionContainerStyles = {
  width: '100%',
};

export const verbatimContainerStyles = {
  'abyss-flex-root': {
    flexWrap: 'nowrap',
    overflowX: 'auto',
  },
};

export const modalStyles = {
  'abyss-modal-content-container': {
    width: '820px',
  },
};

export const sortButtonMobileStyles = {
  'abyss-button-root': {
    borderWidth: '2px',
    padding: '$sm $lg $sm $md',
    '.abyss-icon-material': {
      '@screen < $sm': {
        marginTop: '-4px',
        marginBottom: '-4px',
        marginRight: '4px',
      },
    },
    div: {
      margin: 0,
    },
  },
};
export const verbatimHeader = {
  marginTop: '12px',
  '@screen < $md': {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  '@screen >= $md': {
    display: 'inline-flex',
    justifyContent: 'start',
    alignItems: 'baseline',
    alignSelf: 'stretch',
    gap: '16px',
  },
};

export const RatingSectionHeader = styled(Text, {
  '&.abyss-text-root': {
    fontStyle: 'normal',
    fontWeight: '$bold',
    lineHeight: '16px',
    marginBottom: '3.33px',
    '@screen < $md': {
      fontSize: '12.64px',
      marginBottom: '3.33px',
      lineHeight: '16px',
    },
  },
});

export const RatingOverviewSection = styled(Flex, {
  marginBottom: '24px',
  width: '100%',
});
export const RatingReviewsPercentageSection = styled(Flex, {
  marginBottom: '20px',
  paddingRight: '10px',
  width: '100%',
});

export const OverallRatingNumberStyles = {
  '&.abyss-heading-root': {
    fontSize: '28.83px',
    lineHeight: '36px',
  },
};
export const OverallRatingContainer = {
  '&.abyss-flex-root': {
    '@screen < $sm': {
      marginBottom: '$xs',
    },
  },
};

export const numberOfReviewsTextStyles = (mobileScreen: boolean) => ({
  '&.abyss-text-root': {
    marginLeft: '4px',
    marginTop: '12px',
    marginBottom: '6px',
    fontSize: '14.22px',
    ...(mobileScreen && {
      fontSize: '12.64px',
    }),
  },
});

export const RatingDescriptionWithStarsStyles = {
  gap: '$sm',
  marginRight: '$sm',
  '@screen < $md': {
    flexDirection: 'column',
  },
};

export const verbatimCard = (mobileScreen: boolean) => ({
  minWidth: '282px',
  maxWidth: '282px',
  height: '132px',
  paddingTop: '16px',
  paddingBottom: '16px',
  paddingLeft: '24px',
  paddingRight: '24px',
  borderRadius: '8px',
  cursor: 'pointer',
  '&.abyss-card-root': {
    marginTop: '$md',
    marginRight: '18px',
    ...(mobileScreen && {
      marginTop: '11px',
      height: '112px',
      lineHeight: '16px',
      maxWidth: '100%',
    }),
  },
});

export const ratingCardSuggestion = {
  width: '340px',
  minHeight: '176px',
  padding: '16px 24px',
  borderRadius: '8px',
  border: '1px solid $gray3',
};

export const UnOrderList = styled('ul', {
  listStyle: 'disc',
  marginLeft: '16px',
  li: {
    marginBottom: '7px',
  },
});

export const SuggestionHeading = styled(Text, {
  '&.abyss-text-root': {
    fontWeight: 700,
    color: '$gray8',
  },
});

export const SuggestionWrapper = styled(Flex, {
  gap: '48px',
  '@screen < $md': {
    gap: '12px',
  },
});

export const SuggestioContent = styled(Text, {
  '&.abyss-text-root': {
    fontWeight: 600,
    color: '$gray7',
  },
});

export const SuggestioContentModal = styled(Text, {
  '&.abyss-text-root': {
    fontWeight: 400,
    color: '$gray7',
  },
});

export const ViewMoreButton = styled('button', {
  gap: '4px',
  display: 'flex',
  alignItems: 'center',
});
export const verbatimTextStyles = (mobileScreen: boolean) => ({
  '&.abyss-text-root': {
    fontSize: '14.22px',
    lineHeight: '16px',
    color: '$neutralGray',
    ...(mobileScreen && {
      fontSize: '12.64px',
    }),
  },
});

export const DateTextStyles = {
  '&.abyss-text-root': {
    fontSize: '12.64px',
    color: '#4B4D4F',
    fontWeight: '500',
    lineHeight: '16px',
  },
};

export const viewAllVerbatimFlexStyles = {
  '&.abyss-flex-root': {
    marginBottom: '$lg',
    '@screen < $md': {
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '@screen >= $md': {
      alignItems: 'flex-end',
    },
  },
  '&.abyss-flex-content-root': {
    alignItems: 'center',
  },
};

export const AllPatientReviewsContainer = styled('div', {
  maxWidth: '1200px',
  marginLeft: 'auto',
  marginRight: 'auto',
  padding: '$lg 0',
  '@media (max-width: 1248px)': {
    marginLeft: '24px',
    marginRight: '24px',
  },
  '@screen < $md': {
    marginLeft: '16px',
    marginRight: '16px',
    padding: '16px 0',
  },
});

export const SectionContainer = styled('div', {});

export const showingVerbatimCountTextStyles = {
  'abyss-text-root': {
    fontSize: '$sm',
    color: '$gray7',
    fontWeight: '$semibold',
  },
};

export const showingResultsCountTextStyles = {
  'abyss-text-root': {
    fontSize: '$sm',
    color: '$gray7',
    lineHeight: '18px',
    fontWeight: '$semibold',
  },
};

export const ContainerSubHeading = styled('div', {
  '@screen < $sm': {
    display: 'flex',
    width: '176px',
  },
  '@screen >= $md': {
    display: 'flex',
    width: 'auto',
  },
});

export const TextHeadingPatientReviews = styled(Text, {
  '&.abyss-text-root': {
    color: '$primary1',
    fontStyle: 'normal',
    fontWeight: '700',
    '@screen < $md': {
      fontSize: '20.25px',
      lineHeight: '24px',
    },
    '@screen >= $md': {
      fontSize: '25.63px',
      lineHeight: '32px',
    },
  },
});

export const TextSubHeadingWhatPatientsAreSaying = styled(Text, {
  '&.abyss-text-root': {
    '@screen < $md': {
      color: '#000',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '20px',
      fontSize: '16px',
    },
    '@screen >= $md': {
      color: '#002677',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '24px',
      fontSize: '20.25px',
    },
  },
});

export const PaginationComponentStyled = styled(Pagination, {
  '&.abyss-pagination-root': {
    justifyContent: 'space-between',
    '@screen < $md': {
      width: '100%',
      marginBottom: '32px',
    },
    '@screen >= $md': {
      width: 'fit-content',
    },
  },
});
