import { createContext, useState } from 'react';

export const CountySearchContext = createContext({
  isCountySearch: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setIsCountySearch: (val: boolean) => {},
});

export const CountySearchContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [isCountySearch, setIsCountySearch] = useState(false);
  return (
    <CountySearchContext.Provider value={{ isCountySearch, setIsCountySearch }}>
      {children}
    </CountySearchContext.Provider>
  );
};
