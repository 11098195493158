export interface AnalyticsState {
  originLinkNameForAnalytics?: string;
  linkName?: string;
}

export interface AnalyticsStore {
  analyticsState: AnalyticsState;
  setAnalyticsState: (values: AnalyticsState) => void;
  clearAnalyticsState: (defaultValues?: AnalyticsState) => void;
}

export enum StoreKeys {
  ANALYTICS_STATE = 'analyticsState',
  SET_ANALYTICS_STATE = 'setAnalyticsState',
  CLEAR_ANALYTICS_STATE = 'clearAnalyticsState',
}
