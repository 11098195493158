import { storage } from '@abyss/web/tools/storage';
import { Fragment, useContext, useEffect, useState } from 'react';

import { Constants } from '../../../../common/Constants';
import { convertUrlParamsToObject } from '../../../../common/Utils';
import { I18nextProvider, i18n } from '../../../../common/Utils/i18n/i18n';
import { PortalContext } from '../../../../context/PortalContext';
import { IChildrenProp } from '../../types';
import { initializeLanguage } from './helpers';

export const Internationalization = ({ children }: IChildrenProp) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { portalData } = useContext(PortalContext);

  const urlParams = new URLSearchParams(window.location.search);
  const { locale: urlLocale }: any = convertUrlParamsToObject(
    urlParams.entries()
  );

  useEffect(() => {
    const language = initializeLanguage(null, urlLocale);
    i18n.changeLanguage(language.locale);
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    if (portalData?.portalConfig?.language) {
      selectlangaugeFromPortalData(portalData);
    }
  }, [portalData?.portalConfig?.language]);

  const selectlangaugeFromPortalData = (portalData) => {
    if (portalData?.portalConfig?.language) {
      const language = initializeLanguage(portalData, null);
      i18n.changeLanguage(language.locale);
      storage.cookie.set(Constants.PSLOCALE, language.locale);
      setIsLoaded(true);
    }
  };

  if (!isLoaded) return null;

  return (
    <I18nextProvider i18n={i18n}>
      <Fragment>{children}</Fragment>
    </I18nextProvider>
  );
};
