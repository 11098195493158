import {
  CareCategories,
  ReverseCoverageTypesCodes,
} from '../../common/Constants';

export interface ChipState {
  chipValue: string;
  coverageType: string;
  lob: string;
  locale: string;
  openRecentActivity: boolean;
  openSpecialtyDrawer: boolean;
  openAllSpecialtyDrawer: boolean;
  setChipValue: (chipValue: string) => void;
  setCoverageType: (coverageType: string) => void;
  setLob: (lob: string) => void;
  setLocale: (locale: string) => void;
  setOpenRecentActivity: (openRecentActivity: boolean) => void;
  setOpenSpecialtyDrawer: (openSpecialtyDrawer: boolean) => void;
  setOpenAllSpecialtyDrawer: (openAllSpecialtyDrawer: boolean) => void;
}

export enum StoreKeys {
  CHIP_VALUE = 'chipValue',
  COVERAGE_TYPE = 'coverageType',
  LOB = 'lob',
  LOCALE = 'locale',
  OPEN_RECENT_ACTIVITY = 'openRecentActivity',
  OPEN_SPECIALTY_DRAWER = 'openSpecialtyDrawer',
  OPEN_ALL_SPECIALTY_DRAWER = 'openAllSpecialtyDrawer',
  SET_CHIP_VALUE = 'setChipValue',
  SET_COVERAGE_TYPE = 'setCoverageType',
  SET_LOB = 'setLob',
  SET_LOCALE = 'setLocale',
  SET_OPEN_RECENT_ACTIVITY = 'setOpenRecentActivity',
  SET_OPEN_SPECIALTY_DRAWER = 'setOpenSpecialtyDrawer',
  SET_OPEN_ALL_SPECIALTY_DRAWER = 'setOpenAllSpecialtyDrawer',
}

export const initialState = {
  [StoreKeys.CHIP_VALUE]: CareCategories.ALL,
  [StoreKeys.COVERAGE_TYPE]: ReverseCoverageTypesCodes.MEDICAL,
};

export const PERSIST_KEYS = [
  StoreKeys.CHIP_VALUE,
  StoreKeys.COVERAGE_TYPE,
  StoreKeys.LOB,
  StoreKeys.LOCALE,
];
