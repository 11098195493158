import { useForm } from '@abyss/web/hooks/useForm';
import { storage } from '@abyss/web/tools/storage';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Heading } from '@abyss/web/ui/Heading';
import { TextInput } from '@abyss/web/ui/TextInput';
import { ToggleSwitch } from '@abyss/web/ui/ToggleSwitch';
import React, { useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { useLagoon } from '../../../hooks/useLagoon';
import { useOBAPI } from '../../../hooks/useOBAPI';
import { StoreKeys } from '../../../hooks/useStore/state';
import { useStore } from '../../../hooks/useStore/useStore';
import { modifyRulesPackageKey } from '../../../utils/user.utils';
import { Constants, LOB } from '../../Constants';
import { ConstantsLagoon } from '../../ConstantsLagoon';
import { dateGroupByYearMonthDay } from '../../Utils/datesUtils/formatDate';
import { getFeatureFlag } from '../../Utils/getFeatureFlag';
import {
  findLoggedInMember,
  isCnsMember,
  isMnrMember,
} from '../../Utils/memberUtils/memberUtils';
import { MemberInfo, MemberProfile } from './memberProfile';

const { ACTIVE_MEMBER_INDEX, DEMO_MEMBER_INFO, LOGGED_IN_MEMBER_INDEX } =
  Constants.STORAGE_KEYS.SESSION;

const StyledTextInput = styled(TextInput, {
  '.abyss-text-input-label': {
    color: '$primary1',
  },
});

type Props = {
  setMemberProfiles: (a: MemberInfo[]) => void;
  setMemberId?: (a: number) => void;
};

export const setProfileData = (
  result: { data: MemberProfile },
  setMemberProfiles: (a: MemberInfo[]) => void,
  setMemberState: (a: MemberInfo[]) => void,
  firstName: string,
  lastName: string,
  groupNumber: string,
  memberId: string,
  dob: string,
  id: string,
  userName: string,
  portalSource: string,
  lob: LOB,
  usePreProd: boolean,
  setMemberId: (x: string | number) => void
) => {
  if (result?.data?.memberInfo) {
    const loggedInMemberIndex = findLoggedInMember(result?.data?.memberInfo, {
      FirstName: firstName,
      LastName: lastName,
      DOB: dob,
      GroupNumber: groupNumber,
      MemberNumber: memberId,
      id,
      userName,
      portalSource,
    });
    setMemberState(result?.data?.memberInfo);
    storage.session.set(ACTIVE_MEMBER_INDEX, loggedInMemberIndex.toString());
    storage.session.set(LOGGED_IN_MEMBER_INDEX, loggedInMemberIndex);
    setMemberProfiles(
      result?.data?.memberInfo?.filter(
        (member) =>
          !!member.eligibility[0]?.memberHealthCoverage?.coverageType?.length
      )
    );
    storage.session.set(DEMO_MEMBER_INFO, {
      ...((lob === LOB.ENI || lob === LOB.CNS) && {
        firstName,
        lastName,
        groupNumber,
        memberId,
        dob,
      }),
      ...(lob === LOB.MNR && {
        id,
        userName,
        portalSource,
      }),
      lob,
      usePreProd,
    });
    setMemberId(isCnsMember(lob) ? memberId : parseInt(memberId, 10));
  }
};

export const ObapiDemo = ({
  setMemberProfiles,
  setMemberId = () => {},
}: Props) => {
  const form = useForm();
  const featureFlags = useLagoon('feature-flags')();
  const suppressionLagoonData = useLagoon('feature-supression')();
  const includeLagoonData = useLagoon('feature-inclusion')();
  const tierBenefits = useLagoon('tier-benefit')();
  const setUIFeatureSuppressionState = useStore(
    StoreKeys.SET_UI_FEATURE_SUPPRESSION
  );
  const setUIFeatureInclusionState = useStore(
    StoreKeys.SET_UI_FEATURE_INCLUSION
  );
  const requestReciprocityId = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.REQUEST_RECIPROCITY_ID
  );
  const enableMnrCosmos = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_MNR_COSMOS
  );
  const showIsPreEffectiveIndicator = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.SHOW_ISPREEFFECTIVE_INDICATOR
  );

  const policyFilterToggle = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.MEMBER_INFO_POLICY_FILTER
  );

  const enableCns = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_CNS
  );
  const { USE_PRE_PROD, IS_PREPROD_USER } = Constants.STORAGE_KEYS.SESSION;

  const [enablePreProd, setEnablePreProd] = useState(
    storage.session.get(IS_PREPROD_USER, false)
  );
  const [, setLob] = useSessionStorage<string | undefined>(
    Constants.STORAGE_KEYS.SESSION.LOB,
    ''
  );
  enablePreProd === null ? setEnablePreProd(() => false) : '';

  useEffect(() => {
    storage.session.set(USE_PRE_PROD, enablePreProd);
  }, [enablePreProd]);

  const fpcPsxRedirection = useLagoon(
    Constants.LAGOON_TABLE.FPC_PSX_REDIRECTION
  )();

  const eligiblePolicyIds =
    fpcPsxRedirection[0]?.eligiblePolicyNumbers?.split(',') || [];

  const {
    firstName,
    lastName,
    groupNumber,
    memberId,
    dob,
    id,
    userName,
    portalSource,
    lob,
  } = form.getValues();

  const setMemberState = useStore(StoreKeys.SET_OBAPI_MEMBERS);
  const isUserMNRCosmosMember = enableMnrCosmos && isMnrMember(lob);

  const [, getUserProfile] = useOBAPI({
    onCompleted: (result: { data: MemberProfile }) => {
      const memberInfo: MemberInfo[] = modifyRulesPackageKey(
        result?.data?.memberInfo,
        tierBenefits
      );
      const {
        demographics,
        policyNumber,
        lineOfBusiness: memberLob,
      } = memberInfo?.[0] || {};
      const { dateOfBirth = '', name } = demographics;
      const { firstName, lastName } = name;
      storage.cookie.set('LOB-Target', memberLob);

      const mnrMemberKey =
        result?.data?.memberInfo?.[0]?.eligibility?.[0]?.memberHealthCoverage?.coverageType?.[0]?.coverageID.filter(
          (subcId) => subcId?.sourceSysCode === Constants.MEMBER_KEY
        );
      const mnrMemberId = mnrMemberKey?.[0]?.id;

      // setting lob in session storage when lob is changed from demoheader - to pick correct lagoon instance on LOWER ENVIRONMENTS. For higher environments, it will be picked from cookie LOB-Target which is set when user logs in via uhc.
      setLob(memberLob);
      storage.session.set(DEMO_MEMBER_INFO, {});
      setProfileData(
        { data: { memberInfo } },
        setMemberProfiles,
        setMemberState,
        firstName,
        lastName,
        policyNumber || groupNumber,
        isUserMNRCosmosMember ? mnrMemberId : memberId,
        dateGroupByYearMonthDay(dateOfBirth),
        id,
        userName,
        portalSource,
        lob,
        enablePreProd,
        setMemberId
      );

      setUIFeatureSuppressionState({
        suppressionLagoonData,
        memberPolicy: groupNumber,
      });
      setUIFeatureInclusionState({
        includeLagoonData,
        memberPolicy: groupNumber,
      });
    },
    onError: () => {},
  });

  const handleOnClick = () => {
    getUserProfile({
      variables: {
        ...((lob === LOB.ENI || lob === LOB.CNS) && {
          firstName,
          lastName,
          groupNumber,
          memberId,
          dob: dateGroupByYearMonthDay(dob),
        }),
        ...(lob === LOB.MNR && {
          id,
          userName,
          portalSource,
        }),
        lob,
        requestReciprocityId,
        eligiblePolicyIds,
        usePreProd: enablePreProd,
        enableMnrCosmos,
        showIsPreEffectiveIndicator,
        policyFilterToggle,
        enableCns,
      },
    });
    storage.session.set(IS_PREPROD_USER, enablePreProd);
  };

  const headerName = enablePreProd ? 'OBAPI - Pre Prod Flag Enabled' : 'OBAPI';

  return (
    <React.Fragment>
      <Heading color="$primary1" offset={5}>
        {headerName}
      </Heading>
      <div style={{ width: '100%', padding: '6px 4px' }}>
        <ToggleSwitch
          css={{
            'abyss-toggle-switch-label': {
              color: '$primary1',
              fontWeight: 'bold',
            },
          }}
          data-testid="obapi-preprod-toggle"
          isChecked={enablePreProd}
          label="Pre Prod Toggle"
          onChange={(e: { target: { checked: any } }) =>
            setEnablePreProd(e.target.checked)
          }
        />
      </div>
      {/* E&I */}
      {lob === LOB.ENI || lob === LOB.CNS ? (
        <>
          <StyledTextInput
            data-auto-testid="obapi-firstName"
            data-testid="obapi-firstName"
            label="First Name"
            model="firstName"
            placeholder="First Name"
          />
          <StyledTextInput
            data-auto-testid="obapi-lastName"
            data-testid="obapi-lastName"
            label="Last Name"
            model="lastName"
            placeholder="Last Name"
          />
          <StyledTextInput
            data-auto-testid="obapi-dob"
            data-testid="obapi-dob"
            label="Date of birth (MM/DD/YYYY)"
            mask="##-##-####"
            model="dob"
            placeholder="Date of birth"
          />
          <StyledTextInput
            data-auto-testid="obapi-groupNumber"
            data-testid="obapi-groupNumber"
            label="Group Number"
            model="groupNumber"
            placeholder="Group Number"
          />
          <StyledTextInput
            data-auto-testid="obapi-memberId"
            data-testid="obapi-memberId"
            label="Member ID"
            model="memberId"
            placeholder="Member ID"
          />
        </>
      ) : null}
      {/* MNR */}
      {isUserMNRCosmosMember ? (
        <>
          <StyledTextInput
            data-auto-testid="obapi-id"
            data-testid="obapi-id"
            label="ID"
            model="id"
            placeholder="ID"
          />
          <StyledTextInput
            data-auto-testid="obapi-userName"
            data-testid="obapi-userName"
            label="User Name"
            model="userName"
            placeholder="User Name"
          />
          <StyledTextInput
            data-auto-testid="obapi-portalSource"
            data-testid="obapi-portalSource"
            label="Portal Source"
            model="portalSource"
            placeholder="Portal Source"
          />
        </>
      ) : null}
      <Button
        css={{ marginTop: '$sm', marginBottom: '$md' }}
        data-auto-testid="obapi-submit-button"
        data-testid="obapi-submit-button"
        onClick={handleOnClick}
        variant="outline"
      >
        Submit
      </Button>
    </React.Fragment>
  );
};
