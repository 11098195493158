import { Icon } from '@abyss/web/ui/Icon';
import React from 'react';

export const IconZoomOut = () => (
  <Icon size="18px">
    <svg
      fill="none"
      height="3"
      viewBox="0 0 18 3"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.642238 2.57088C0.471984 2.57062 0.308776 2.50288 0.188389 2.38249C0.0680012 2.2621 0.000255062 2.09889 0 1.92864V0.642238C0 0.287368 0.287368 0 0.642238 0H17.3578C17.7126 0 18 0.287368 18 0.642238V1.92864C17.9997 2.09889 17.932 2.2621 17.8116 2.38249C17.6912 2.50288 17.528 2.57062 17.3578 2.57088H0.642238Z"
        fill="#6E7072"
      />
    </svg>
  </Icon>
);

export const IconZoomIn = () => (
  <Icon size="18px">
    <svg
      fill="none"
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 8.3568V9.64321C17.9998 9.81346 17.932 9.97667 17.8116 10.0971C17.6912 10.2174 17.528 10.2852 17.3578 10.2854H10.2854V17.3578C10.2852 17.528 10.2174 17.6912 10.0971 17.8116C9.97667 17.932 9.81346 17.9998 9.64321 18H8.3568C8.18655 17.9998 8.02334 17.932 7.90295 17.8116C7.78257 17.6912 7.71482 17.528 7.71457 17.3578V10.2854H0.642238C0.471984 10.2852 0.308777 10.2174 0.188389 10.0971C0.0680013 9.97667 0.000255062 9.81346 0 9.64321V8.3568C0 8.00193 0.287368 7.71457 0.642238 7.71457H7.71457V0.642238C7.71457 0.287368 8.00193 0 8.3568 0H9.64321C9.99808 0 10.2854 0.287368 10.2854 0.642238V7.71457H17.3578C17.7126 7.71457 18 8.00193 18 8.3568Z"
        fill="#6E7072"
      />
    </svg>
  </Icon>
);
