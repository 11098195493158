import { Flex } from '@abyss/web/ui/Flex';
import { Skeleton } from '@abyss/web/ui/Skeleton';

import { FilterAccordianItem } from './ProviderFilters.style';

type Props = {
  index: number;
};

export const AllFilterSkeleton = ({ index }: Props) => (
  <FilterAccordianItem key={index} value={index}>
    <Flex justify="space-between">
      <Skeleton height={10} variant="rounded" width={200} />{' '}
      <Skeleton height={10} variant="rounded" width={50} />
    </Flex>
  </FilterAccordianItem>
);
