import { useContext } from 'react';

import { getLagoonTable } from '../../lagoon/lagoonUtil';
import { LagoonContext } from './LagoonContext';

export const useLagoon = (tablePath) => {
  const lagoonContext = useContext(LagoonContext);
  if (lagoonContext && tablePath) {
    return getLagoonTable(lagoonContext, tablePath);
  }

  return lagoonContext;
};
