import { Box } from '@abyss/web/ui/Box';
import React, { useEffect, useState } from 'react';

import { CssProps } from '../Types/CssProps';
import { SectionCardProps } from '../Types/SectionCardProps';
import { merge } from '../Utils/mergeUtil/merge';

const cardCss: CssProps = {
  'abyss-box-root': {
    margin: '0 0 $sm 0',
    border: '1px solid $cardBorder',
  },
};

export const CardSection = ({
  children,
  'data-testid': testId = '',
  bgColor = '$white',
  css = {},
  height = '100%',
}: SectionCardProps) => {
  const [mergedCss, setMergedCss] = useState(cardCss);
  useEffect(() => {
    setMergedCss(merge(cardCss, css));
  }, [css]);

  return (
    <Box
      color={bgColor}
      css={mergedCss}
      data-auto-testid={testId}
      data-testid={testId}
      height={height}
    >
      {children}
    </Box>
  );
};
