import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Card } from '@abyss/web/ui/Card';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { useRef } from 'react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Provider } from '../../models/Provider';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { phoneOnly } from '../ConstantsStyles';
import { NetworkWrapper } from '../DataCard/DataCard.style';
import { DataCardSkeleton } from '../DataCard/DataCardSkeleton';
import { CardInfo } from '../SnackCard/CardInfo';
import {
  BottomWrapper,
  CardContainer,
  DataContainer,
  HeadingWrapper,
  ProviderImage,
  ResponsiveText,
  VirtualProviderGroupImage,
  VirtualVisitWrapper,
  displayVirtualVisitStyle,
} from './NationalGroupProvidersVirtualDataCard.style';
import { nationalVirtualProviderGroups } from './nationalVirtualProviderGroups';

type Props = {
  practitioner?: Provider;
  css?: any;
  sectionType?: string;
  isLoading: boolean;
  fromMobileListView: boolean;
  locationForAnalytics?: string;
  headers?: ResponseHeaders;
  index?: number;
};

export const NationalGroupProvidersVirtualDataCard = ({
  practitioner,
  css = { width: '100%' },
  sectionType,
  isLoading,
  locationForAnalytics,
  headers,
  index = 0,
  fromMobileListView,
}: Props) => {
  const cardRef = useRef<HTMLElement>(null);
  const mobileScreen = useMediaQuery(phoneOnly);

  const { networkStatus, providerId = '', speciality } = practitioner ?? {};
  const { t } = useTranslation();

  const inOrOutOfNetwork = networkStatus
    ? t('In-network')
    : t('Not-In-Network');

  const inOrOutOfNetworkColor = networkStatus ? '$success1' : '';

  const provider = nationalVirtualProviderGroups.oshiHealthSnack;

  const getLinkPositionIndex = (linkPosition?: number) => {
    if (linkPosition !== undefined) {
      return linkPosition + 1;
    }
    return linkPosition;
  };

  const linkLocationName = `body:${locationForAnalytics}`;

  if (isLoading) {
    return (
      <DataCardSkeleton
        fromMobileListView={fromMobileListView}
        mobileScreen={mobileScreen}
      />
    );
  }

  return (
    <DataContainer>
      <CardInfo
        cardType="national-group-snack-card"
        headers={headers}
        indexForAnalytics={getLinkPositionIndex(index)}
        landingPage={false}
        locationForAnalytics={linkLocationName}
        practitioner={practitioner}
      />
      <CardContainer
        css={css}
        data-auto-testid="generic-data-card"
        data-testid="generic-data-card"
        id={`data-card-for-map-${providerId}`}
        onClick={() => {}}
        ref={cardRef}
        style={{ cursor: 'pointer' }}
      >
        <Card.Section
          css={{
            padding: ' 12px 0px 8px',
            height: '140px',
            width: '100%',
            '@screen < $sm': {
              height: '118px',
            },
          }}
        >
          <Flex>
            <Flex
              css={{
                flex: 1,
                padding: '0px 12px 0px 12px',
                gap: '8px',
              }}
              direction="column"
            >
              <HeadingWrapper
                data-auto-testid={`data-card-heading-${sectionType}-${providerId}`}
                data-testid={`data-card-heading-${sectionType}-${providerId}`}
              >
                <VirtualProviderGroupImage>
                  <ProviderImage
                    alt={provider.alt}
                    src={provider.imageLocation}
                  />
                </VirtualProviderGroupImage>
                <IconMaterial
                  className="arrow_forward"
                  css={{
                    width: '16px',
                    height: '24px',
                  }}
                  data-auto-testid={`data-card-arrow-forward-icon-${sectionType}-${providerId}`}
                  data-testid={`data-card-arrow-forward-icon-${sectionType}-${providerId}`}
                  icon="arrow_forward_ios"
                />
              </HeadingWrapper>
              <Flex
                css={{
                  gap: '4px',
                  '@screen < $sm': {
                    gap: '10px',
                  },
                }}
                direction="column"
              >
                <Flex
                  css={{
                    gap: '0px 24px',
                  }}
                  direction="row"
                  justify="space-between"
                >
                  <Flex
                    alignContent="center"
                    css={{ width: 'auto', height: '20px' }}
                    direction="column"
                  >
                    {speciality && (
                      <ResponsiveText
                        data-auto-testid={`data-card-specialty-${sectionType}-${providerId}`}
                        data-testid={`data-card-specialty-${sectionType}-${providerId}`}
                      >
                        {speciality}
                      </ResponsiveText>
                    )}
                  </Flex>
                  <Flex alignItems="end" direction="column">
                    <NetworkWrapper>
                      <IconMaterial
                        className="check_circle"
                        classNames
                        color={inOrOutOfNetworkColor}
                        css={{
                          '&.abyss-icon-material': {
                            width: '20px',
                            height: '20px',
                            '@screen < $sm': {
                              width: '16px',
                              height: '16px',
                            },
                          },
                        }}
                        data-auto-testid={`data-card-check-circle-icon-${sectionType}-${providerId}`}
                        data-testid={`data-card-check-circle-icon-${sectionType}-${providerId}`}
                        icon="check_circle"
                      />
                      <ResponsiveText
                        data-auto-testid={`data-card-network-${sectionType}-${providerId}`}
                        data-testid={`data-card-network-${sectionType}-${providerId}`}
                      >
                        {inOrOutOfNetwork}
                      </ResponsiveText>
                    </NetworkWrapper>
                  </Flex>
                </Flex>
                <VirtualVisitWrapper>
                  <Layout.Group>
                    <Flex color="$primary1" css={displayVirtualVisitStyle}>
                      <IconMaterial
                        color="white"
                        icon="videocam"
                        size={16}
                        variant="filled"
                      />
                    </Flex>
                    <ResponsiveText>
                      Virtual visits <b>only</b>
                    </ResponsiveText>
                  </Layout.Group>
                </VirtualVisitWrapper>
              </Flex>
            </Flex>
          </Flex>
        </Card.Section>
        <BottomWrapper justify="flex-start">
          <Link
            css={{
              'abyss-link-root': {
                padding: '8px 0px 8px',
                height: '36px',
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '20px',
                '@screen < $sm': {
                  fontSize: '12.64px',
                  lineHeight: '16px',
                },
              },
            }}
            data-auto-testid="get-started"
            data-testid="get-started"
            openNewWindow
          >
            {t('DONT_KNOW_WHERE_TO_START_CARD.GET_STARTED')}
          </Link>
        </BottomWrapper>
      </CardContainer>
    </DataContainer>
  );
};
