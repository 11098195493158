import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Skeleton } from '@abyss/web/ui/Skeleton';
import React from 'react';

import { phoneOnly } from '../ConstantsStyles';
import { Wrapper } from '../ContentWrapper/ContentWrapper.styled';
import { SnackCardSkeleton } from '../SnackCard/SnackCardSkeleton';

export const SectionSkeleton = () => {
  const isMobile = useMediaQuery(phoneOnly);
  const shimmerArray = isMobile ? [1, 2] : [1, 2, 3, 4, 5, 6];
  return (
    <Wrapper>
      <Skeleton height={29} variant="rounded" width={325} />
      <div style={{ display: 'flex', gap: '24px', margin: '16px' }}>
        {shimmerArray.map((num) => (
          <SnackCardSkeleton key={`shimmer-${num}`} mobileScreen={isMobile} />
        ))}
      </div>
    </Wrapper>
  );
};
