import { Popover } from '@abyss/web/ui/Popover';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsOverflow } from '../../../../../../../../../hooks/useIsOverflow';
import { getStatusText } from '../../../../../ShareDrawer/utils';
import {
  StyledAvoidWrappingDiv,
  popoverStyle,
} from '../../../../utility/compareDrawer.styled';
import {
  ATTRIBUTE_STATUS_TEXT,
  NULL_ATTRIBUTE_STATE,
} from '../../../../utility/compareDrawerConstants';

type Props = {
  isPcpServiceAvaliable?: boolean;
  mobile: boolean;
};

export const PcpServicesAvaliable = ({
  isPcpServiceAvaliable,
  mobile,
}: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);

  const pcpServiceAvaliableText = getStatusText(
    isPcpServiceAvaliable,
    ATTRIBUTE_STATUS_TEXT.PCP_SERVICES_AVAILABILE.TRUE,
    ATTRIBUTE_STATUS_TEXT.PCP_SERVICES_AVAILABILE.FALSE,
    NULL_ATTRIBUTE_STATE.PCP_SERVICES_AVAILABILE
  );

  const isAttributeEmpty =
    pcpServiceAvaliableText === NULL_ATTRIBUTE_STATE.PCP_SERVICES_AVAILABILE;

  const pcpServiceAttribute = (
    <StyledAvoidWrappingDiv
      cssProps={{ isOverflow, isAttributeEmpty }}
      data-auto-testid="pcp-service-available-text"
      data-testid="pcp-service-available-text"
      ref={containerRef}
    >
      {t(pcpServiceAvaliableText)}
    </StyledAvoidWrappingDiv>
  );

  if (mobile) {
    return (
      <React.Fragment>
        {isOverflow ? (
          <Popover
            content={pcpServiceAvaliableText}
            css={popoverStyle}
            showClose={false}
            tabIndex="-1"
            width={150}
          >
            {pcpServiceAttribute}
          </Popover>
        ) : (
          pcpServiceAttribute
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {isOverflow ? (
        <Tooltip
          content={pcpServiceAvaliableText}
          position="top"
          positionOffset={8}
        >
          {pcpServiceAttribute}
        </Tooltip>
      ) : (
        pcpServiceAttribute
      )}
    </React.Fragment>
  );
};
