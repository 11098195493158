import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Drawer } from '@abyss/web/ui/Drawer';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { Text } from '@abyss/web/ui/Text';

export const StyledChoosePCPAlertGroup = styled(Layout.Group, {
  '&.abyss-layout-group': {
    justifyContent: 'space-between !important',
  },
});

export const StyledChoosePCPAlertIcon = styled(IconMaterial, {
  marginRight: '4px',
});

export const StyledChoosePCPAlertClose = styled(IconMaterial, {
  cursor: 'pointer',
});

export const StyledChoosePCPAlertModalRadioGroup = styled(RadioGroup, {
  '&.abyss-radio-group-root': {
    border: 'none',
  },
});

export const StyledChoosePCPAlertModalGroup = styled(Layout.Group, {
  cursor: 'pointer',
  marginBottom: '24px',

  '.abyss-radio-label': {
    padding: '0',
  },

  '&:last-child': {
    marginBottom: '0',
  },

  '@screen < $sm': {
    lineHeight: '20px',
  },
});

export const StyledChoosePCPAlertModal = styled(Modal, {
  '&.abyss-modal-content-container': {
    borderRadius: '12px',
  },

  '&.abyss-modal-body': {
    padding: '$sm',
  },

  '&.abyss-modal-close-button': {
    margin: '$md',
  },
});

export const StyledChoosePCPAlertModalTitle = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '25.63px',
    fontWeight: 'bold',
    lineHeight: '32px',
  },
});

export const StyledChoosePCPAlertModalSection = styled(Modal.Section, {
  '&.abyss-modal-section-root': {
    maxHeight: '199px',
    overflow: 'auto',
    paddingBottom: '0',
  },
});

export const StyledChoosePCPAlertModalButtonRow = styled(Modal.Section, {
  '&.abyss-modal-section-root': {
    marginTop: '32px',
  },
});

export const StyledChoosePCPAlertDrawerContainer = styled(Drawer, {
  '&.abyss-modal-content-container': {
    borderRadius: '20px 20px 0 0',
    height: 'inherit',
  },
  '&.abyss-modal-body': {
    padding: '16px',
  },
});

export const StyledChoosePCPAlertDrawerTitleContainer = styled('div', {
  marginBottom: '26px',
});

export const StyledChoosePCPAlertDrawerTitle = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '20.25px',
  },
});

export const StyledChoosePCPAlertDrawerSection = styled('div', {
  maxHeight: '172px',
  overflowY: 'auto',
  overflowX: 'hidden',
});

export const StyledChoosePCPAlertDrawerButtonRow = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  marginTop: '28px',
});

export const StyledChoosePCPAlertDrawerButton = styled(Button, {
  width: '100%',
});
