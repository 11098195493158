import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';

import { phoneOnly } from '../ConstantsStyles';
import { SnackInfoCard } from '../SnackCard/SnackCard.styles';
import { SnackCardSkeleton } from '../SnackCard/SnackCardSkeleton';
import { nationalVirtualProviderGroups } from './nationalVirtualProviderGroups';

const ByteCardContainer = styled(Flex, {
  cursor: 'pointer',
  height: 'auto',
  minHeight: '198px',
  width: '192px',
  borderRadius: '8px',
  border: '1px solid $gray3',
  background: '$white',
  boxShadow: '0px 1px 4px 0px rgba(25, 25, 26, 0.16)',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '8px',
  flex: '1 0 0',
  variants: {
    isFixes: {
      true: {
        boxShadow: 'none',
        border: 'none',
      },
    },
  },
  maxWidth: '180px',
  padding: '16px 8px',
  '@screen < $sm': {
    height: '176px',
    width: '146px',
  },
});

const VirtualProviderGroupImage = styled(Flex, {
  alignItems: 'center',
  height: '64px',
  '@screen < $md': {
    height: '59px',
  },
});

const VirtualSpeciality = styled(Text, {
  textAlign: 'center',
});

const VirtualCareIcon = styled(Flex, {
  padding: '4px',
  margin: '0px',
  borderRadius: '100px',
  backgroundColor: '$primary1',
  height: '24px',
  justifyContent: 'flex-end',
  alignItems: 'center',
  alignSelf: 'flex-end',
});

export type NationalGroupProvidersVirtualBiteCardProps = {
  providerName?: string;
  speciality?: string;
  isFixes: boolean;
  isLoading: boolean;
  handleOnClick: () => void;
};

export const NationalGroupProvidersVirtualBiteCard = ({
  providerName = 'oshiHealth',
  speciality,
  isFixes,
  isLoading,
  handleOnClick,
}: NationalGroupProvidersVirtualBiteCardProps) => {
  const provider = nationalVirtualProviderGroups[providerName];
  const mobileScreen = useMediaQuery(phoneOnly);

  return (
    <React.Fragment>
      {isLoading ? (
        <SnackInfoCard
          alignContent="center"
          alignItems="center"
          direction="column"
          isLoading={isLoading}
          justify="space-between"
        >
          <SnackCardSkeleton mobileScreen={mobileScreen} />
        </SnackInfoCard>
      ) : (
        <ByteCardContainer
          data-auto-testid="virtual-provider-group-byte-card"
          data-testid="virtual-provider-group-byte-card"
          isFixes={isFixes}
          onClick={handleOnClick}
        >
          <VirtualCareIcon>
            <IconMaterial color="$white" icon="videocam" size={16} />
          </VirtualCareIcon>
          <VirtualProviderGroupImage>
            <img alt={provider.alt} src={provider.imageLocation} />
          </VirtualProviderGroupImage>
          <VirtualSpeciality color="$gray6" size={12.64}>
            {speciality || 'Virtual Back, Joint & Muscle Care'}
          </VirtualSpeciality>
        </ByteCardContainer>
      )}
    </React.Fragment>
  );
};
