import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useToast } from '@abyss/web/hooks/useToast';
import { storage } from '@abyss/web/tools/storage';
import { styled } from '@abyss/web/tools/styled';
import { Alert } from '@abyss/web/ui/Alert';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';

import { useCoverageType } from '../../hooks/useCoverageType';
import { useLagoon } from '../../hooks/useLagoon';
import { getMessagesForMember } from '../../utils/customMessageNotification.utils';
import {
  getCurrentMember,
  getPlanVariationCode,
  getPolicyNumber,
  getReportingCode,
} from '../../utils/user.utils';
import { CUSTOMN_MESSAGE_TRUNCATE_CHARACTERS } from '../Constants';
import { phoneOnly } from '../ConstantsStyles';
import { ContentWrapper } from '../ContentWrapper';
import { CtaTextLink, ExpandCollapse } from './ExpandCollapse/ExpandCollapse';

export const toastMessagesStyle = {
  'abyss-toast-container': {
    maxHeight: 'calc(abyss-toast-description + 10)',
    backgroundColor: '$info2',
    borderWidth: '1px 1px 1px 3px',
    borderStyle: 'solid',
    borderRadius: '3px',
    borderColor: '$info1',
  },
  'abyss-toast-close-button-icon': {
    fill: '$gray8',
  },
  'abyss-toast-title': {
    textOverflow: 'ellipsis',
    color: 'black',
    fontWeight: 'bold',
    margin: '0px',
    overflow: 'hidden',
  },
  'abyss-toast-body': {
    color: 'black',
  },
  'abyss-toast-description': {
    color: '$gray8',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
  },
  'abyss-toast-close-button': {
    marginTop: '8px',
    color: '$black',
  },
};

export type ToastViewProps = {
  onClose: () => void;
  toast: any;
  toastMessage: {
    linkText?: string | null;
    title: string;
    message: string;
    link: string;
  };
};

export const showToastView = ({
  onClose,
  toast,
  toastMessage,
}: ToastViewProps) => {
  const { title, message, linkText, link } = toastMessage;

  toast.show({
    title,
    message: (
      <ExpandCollapse
        linkText={linkText}
        linkUrl={link}
        text={message}
        truncateLength={CUSTOMN_MESSAGE_TRUNCATE_CHARACTERS}
      />
    ),
    icon: <IconMaterial color="$info1 !important" icon="info" />,
    css: toastMessagesStyle,
    autoClose: false,
    onClose,
  });
};

const MessageAlertContainer = styled(Alert, {
  '&.abyss-alert-root': {
    '.abyss-alert-container': {
      '.abyss-alert-icon-container': {
        marginRight: '$sm',
        marginTop: '4px',
      },
      'abyss-alert-close-button': {
        marginTop: '4px',
      },
    },
  },
});

export const CustomMessageNotification = ({ setCustomMessageFn }) => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const currentMember = getCurrentMember();

  const customMesssgeAll = useLagoon('custom-messages')();

  const coverageType = useCoverageType();

  const memberPolicy = getPolicyNumber(currentMember, coverageType);

  const memberReportingCode = getReportingCode(currentMember, coverageType);
  const memberPlanVariationCode = getPlanVariationCode(
    currentMember,
    coverageType
  );

  const memberPVRC = memberPlanVariationCode?.concat(memberReportingCode);

  const [messagesForMember, setMessagesForMember] = useState([]);
  const { toast } = useToast();

  const showNextMessage = () => {
    const customMsgArr = getMessagesForMember(
      customMesssgeAll,
      memberPVRC,
      memberPolicy
    );
    setMessagesForMember(customMsgArr);
    setCustomMessageFn(customMsgArr[0]?.dnt_title?.toLowerCase());
  };

  useEffect(() => {
    showNextMessage();
  }, [memberPolicy]);

  const memberHasMessages = messagesForMember?.length > 0;

  useEffect(() => {
    if (mobileScreen && toast && memberHasMessages) {
      showToastView({
        toast,
        toastMessage: messagesForMember[0],
        onClose: () => {
          storage.session.set(get(messagesForMember, '[0].id'), true);
          showNextMessage();
        },
      });
    }
    return () => {
      toast?.clean();
    };
  }, [mobileScreen, showNextMessage, memberPolicy]);
  if (!customMesssgeAll || !memberHasMessages) {
    return null;
  }

  const { id, title, message, linkText, link } = messagesForMember[0];

  return (
    <React.Fragment>
      {!mobileScreen && (
        <ContentWrapper>
          <MessageAlertContainer
            data-auto-testid={`custom-message-container-${id}`}
            data-testid={`custom-message-container-${id}`}
            icon={<IconMaterial icon="info" size="16" />}
            onClose={() => {
              storage.session.set(id, true);
              showNextMessage();
            }}
            title={title}
            variant="info"
          >
            <Layout.Stack alignItems="left">
              {message}
              {linkText && !isEmpty(linkText) && (
                <CtaTextLink
                  data-auto-testid="custom-message-link"
                  data-testid="custom-message-link"
                  href={link}
                >
                  {linkText}
                </CtaTextLink>
              )}
            </Layout.Stack>
          </MessageAlertContainer>
        </ContentWrapper>
      )}
    </React.Fragment>
  );
};
