import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Box } from '@abyss/web/ui/Box';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { phoneOnly } from '../../../ConstantsStyles';
import {
  SuggestioContent,
  SuggestionHeading,
  ViewMoreButton,
  ratingCardSuggestion,
} from './PatientReviews.styled';

type SurveyAnswers = {
  count: number;
  title: string;
};
type Props = {
  heading: string;
  surveyAnswers: SurveyAnswers[];
  dataTestId: string;
};

export const PatientReviewSuggestionCard = ({
  heading,
  surveyAnswers,
  dataTestId,
}: Props) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const [viewMore, setViewMore] = useState<Boolean>(false);
  const showItems = viewMore ? surveyAnswers?.length : 3;
  const buttonTextColor = '$info1';
  const handleViewMore = () => {
    setViewMore((prev) => !prev);
  };
  const allCountIsZero = surveyAnswers?.every((item) => item.count === 0);
  const sortedData = surveyAnswers?.sort((a, b) => b.count - a.count);
  return (
    <Box color={'$white'} css={ratingCardSuggestion}>
      <Flex css={{ gap: '12px' }} direction={'column'}>
        <SuggestionHeading
          data-testid={`heading-${heading.replace(/ /g, '-')}`}
          size="$sm"
        >
          {heading}
        </SuggestionHeading>
        {!allCountIsZero && sortedData?.length ? (
          sortedData.slice(0, showItems).map((item) => (
            <SuggestioContent data-testid={dataTestId} size="$md">
              {t(item.title)} ({item.count})
            </SuggestioContent>
          ))
        ) : (
          <SuggestioContent data-testid="no-results" size="$md">
            {' '}
            {t('No Results')}
          </SuggestioContent>
        )}
        {sortedData?.length > 3 && !allCountIsZero && !viewMore ? (
          <ViewMoreButton data-testid="view-more" onClick={handleViewMore}>
            <Text color={buttonTextColor} size="$md">
              {`+${surveyAnswers.length - 3}  ${t('More')}`}
            </Text>
            {mobileScreen ? (
              <IconMaterial
                color={buttonTextColor}
                icon={'keyboard_arrow_down'}
                size={18}
              />
            ) : null}
          </ViewMoreButton>
        ) : null}
      </Flex>
    </Box>
  );
};
