import { useRouter } from '@abyss/web/hooks/useRouter';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import find from 'lodash/find';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { useLagoon } from '../../hooks/useLagoon';
import { ProviderLocation } from '../../models/ProviderDetails';
import { useAnalyticsStore } from '../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { usePCPStore } from '../../store/usePCPStore';
import { PCPSearchStore } from '../../store/usePCPStore/pcpStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { getLagoonConfigValue } from '../../utils/providerSearch.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ConstantsRoutes } from '../ConstantsRoutes';
import { convertObjectToUrlParams } from '../Utils';
import {
  PCPNotChosenButtonCSS,
  PCPNotChosenCSS,
  PCPNotChosenContentCSS,
  StyledPCPNotChosenHeader,
  StyledPCPNotChosenText,
} from './DetailsPageHeader.styled';

export type Props = {
  selectLocation: ProviderLocation[];
};

export const PcPNotChosenBanner = ({ selectLocation }: Props) => {
  const { navigate } = useRouter();
  const { t } = useTranslation();

  const allConfigs = useLagoon('config');

  const uiMessaging = useLagoon('ui-messaging')();

  const eligibleSpecialties = getLagoonConfigValue(
    allConfigs,
    'PCP_ELIGIBLE_SPECIALTIES_ID'
  );

  const bannerHeader = find(uiMessaging, {
    key: ConstantsLagoon.PRIMARY_CARE.PCP_NOT_CHOSEN_ACCEPTANCE,
  })?.title;

  const acceptanceContent = find(uiMessaging, {
    key: ConstantsLagoon.PRIMARY_CARE.PCP_NOT_CHOSEN_ACCEPTANCE,
  })?.message;

  const eligibilityContent = find(uiMessaging, {
    key: ConstantsLagoon.PRIMARY_CARE.PCP_NOT_CHOSEN_ELIGIBILITY,
  })?.message;

  const findPCPButtonTestId: string = 'find-pcp-button';
  const providerSpecialtiesWithIds = selectLocation?.[0]?.specialtiesWithId;
  const isEligibleSpecialties = providerSpecialtiesWithIds?.some((specialty) =>
    eligibleSpecialties?.includes(specialty?.id)
  );

  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { setAnalyticsState } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      setAnalyticsState: state.setAnalyticsState,
      clearAnalyticsState: state.clearAnalyticsState,
    }))
  );
  const { setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
      clearTypeaheadSearchStore: state.clearTypeaheadSearchStore,
    }))
  );
  const { setPCPSearchState } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      setPCPSearchState: state.setPCPSearchState,
      clearPCPSearchState: state.clearPCPSearchState,
    }))
  );

  const handleSwitchPCP = () => {
    setAnalyticsState({
      originLinkNameForAnalytics: findPCPButtonTestId,
    });
    const typeaheadStoreValues = {
      search: Constants.RESULT_SECTION.PRIMARY_CARE_PROVIDERS,
      searchType: Constants.SEARCH_TYPES.SPECIALTY,
      providerType: '',
    };
    setTypeaheadSearchStore({
      ...typeaheadStoreValues,
      resultType: Constants.RESULT_SECTION.ALL,
      acceptingNewPatients: true,
    });
    const pcpStoreValues = {
      choosePCP: true,
      pcpIndicator: true,
    };
    setPCPSearchState(pcpStoreValues);
    const urlParams = convertObjectToUrlParams(chipStore, {
      ...typeaheadStoreValues,
      ...pcpStoreValues,
    });
    navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
  };

  const handleAdobeLinkTrackEvent = (
    linkTrackName: string,
    locationSection: string
  ) => {
    adobeLinkTrackEvent({
      destinationUrl: '',
      name: linkTrackName,
      location: `${locationSection}:${'body:details header'}`,
      type: 'internal',
    });
  };

  const hasFalseAcceptingNewPatients = Boolean(
    selectLocation?.some((location) => location?.acceptingNewPatients === false)
  );
  const hasFalsePCPServiceAvailability = Boolean(
    selectLocation?.some(
      (location) => location?.hasPCPServiceAvailability === false
    )
  );

  let bannerContent = '';

  if (hasFalseAcceptingNewPatients) {
    bannerContent = acceptanceContent;
  } else if (hasFalsePCPServiceAvailability || !isEligibleSpecialties) {
    bannerContent = eligibilityContent;
  }

  return (
    <Layout.Group css={PCPNotChosenCSS} data-testid={'pcp_not_chosen_group'}>
      <IconMaterial color="#196ECF" icon="error" size="20px" />
      <Layout.Group css={PCPNotChosenContentCSS}>
        <StyledPCPNotChosenHeader data-testid={'pcp_not_chosen_header'}>
          {t(bannerHeader)}
        </StyledPCPNotChosenHeader>
        <StyledPCPNotChosenText data-testid={'pcp_not_chosen_content'}>
          {t(bannerContent)}
        </StyledPCPNotChosenText>

        <Button
          css={PCPNotChosenButtonCSS}
          data-testid={findPCPButtonTestId}
          onClick={() => {
            handleSwitchPCP();
            handleAdobeLinkTrackEvent(
              findPCPButtonTestId,
              'body:details header'
            );
          }}
          variant="tertiary"
        >
          {t('Find Eligible PCPs')}
        </Button>
      </Layout.Group>
    </Layout.Group>
  );
};
