import { useCallback } from 'react';

import { Constants } from '../../../../common/Constants';
import { useCategoriesCount } from '../../../../hooks/useCategoriesCount';
import { GetCategoryCountProps } from './categoryCountTypes';
import {
  getPayloadWithRecriprocityId,
  groupSpecialtyRollupCodes,
} from './searchUtils';
import {
  compareAndReturn,
  getModifiedPayloadForCategoriesCount,
} from './utils';

export const useSearchResultsCategoriesCount = (customParams: any) => {
  const [categoryCount, getResults] = useCategoriesCount();

  const getCategoryCountResults = useCallback(
    ({
      currentMember,
      onlineRetailers,
      featureFlags,
      pseudoVerticalsDetails,
      pseudoVerticals,
      includeSpecialityRollupCodes,
      searchType,
      specialtySearch,
      uesTranslation,
      coverageTypes,
      keyword,
      search,
      searchTerm,
      isUserTextSearch,
      llmFlag,
    }: GetCategoryCountProps) => {
      const payloadWithRecriprocityId = getPayloadWithRecriprocityId({
        currentMember,
        onlineRetailers,
        featureFlags,
        pseudoVerticalsDetails,
        pseudoVerticals,
      });
      const codeByGroup = groupSpecialtyRollupCodes(
        includeSpecialityRollupCodes,
        searchType,
        specialtySearch
      );
      const payload = getModifiedPayloadForCategoriesCount(
        payloadWithRecriprocityId,
        codeByGroup,
        uesTranslation,
        search,
        keyword,
        pseudoVerticalsDetails?.isPseudoRollupSearch,
        isUserTextSearch,
        coverageTypes
      );

      getResults({
        ...customParams,
        payloadwithName: payload,
        searchTerm: compareAndReturn(searchTerm, search),
        invokeLlmModel:
          (isUserTextSearch ||
            searchType == Constants.SEARCH_TYPES.SPECIALTY) &&
          llmFlag,
        isUserTextSearch,
      });
    },
    [getResults]
  );

  return [categoryCount, getCategoryCountResults];
};
