import includes from 'lodash/includes';
import toUpper from 'lodash/toUpper';

import { Constants } from '../Constants';

export const isOutOfCounty = (
  isCountySearch: any,
  name: string | undefined,
  stateCode: string | undefined,
  snackCardProvider: any
) => {
  const district: string = toUpper(
    snackCardProvider?.address?.district
  ).includes('COUNTY')
    ? snackCardProvider?.address?.district
    : `${snackCardProvider?.address?.district} COUNTY`;
  return (
    isCountySearch &&
    !!snackCardProvider?.address?.district &&
    !(
      includes(toUpper(name), toUpper(district)) &&
      includes(toUpper(stateCode), toUpper(snackCardProvider?.address?.state))
    )
  );
};

export const isCounty = (id: string | undefined) =>
  includes(toUpper(id), toUpper(Constants.DISTRICT));

export const shouldAddSpaceForTierBadge = (
  providers,
  suppressPremiumCare: boolean,
  showTierProviderTag: boolean
) =>
  (providers?.some((e) => e.isTieredProvider === true) &&
    showTierProviderTag) ||
  (providers?.some((e) => e.isPremiumCarePhysician === 'YES') &&
    !suppressPremiumCare);
