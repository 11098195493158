import { Layout } from '@abyss/web/ui/Layout';
import find from 'lodash/find';
import React from 'react';

import { useLagoon } from '../../hooks/useLagoon';
import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ContentWrapper } from '../ContentWrapper';
import { DisclaimerContainer } from '../ProviderDetailTabs/ProviderDetailTabs.styled';
import { VisionDirectoryDataDisclaimer } from '../VisionCare/VisionDirectoryDataDisclaimer';
import {
  HealthGradesStyled,
  StyledMarkdown,
} from './DisclaimersContainer.styled';
import { HealthGradesDisclaimers } from './HealthGradesDisclaimers';
import { PremiumCareDisclaimer } from './PremiumCareDisclaimer';

interface Props {
  showPremiumCareDisclaimer?: boolean;
  isVisionCare?: boolean;
  ratingSection?: boolean;
  sectionType?: string;
  selectedChipValue?: string;
  search?: string;
}

export const ENIDisclaimersContainer = ({
  showPremiumCareDisclaimer = false,
  isVisionCare = false,
  ratingSection = false,
  sectionType,
  search,
}: Props) => {
  const lagoonData = useLagoon('ui-messaging')();
  const isWidget = useStore(StoreKeys.IS_WIDGET);

  const modalDisclaimerShort = find(lagoonData, {
    key: ConstantsLagoon.HEALTHGRADES.MODAL_DISCLAIMER_SHORT,
  });

  const modalDisclaimerFull = find(lagoonData, {
    key: ConstantsLagoon.HEALTHGRADES.MODAL_DISCLAIMER_FULL,
  });

  const disclaimerInPage = find(lagoonData, {
    key: ConstantsLagoon.HEALTHGRADES.DISCLAIMER_IN_PAGE,
  });

  const visionDirectoryDisclaimer = find(lagoonData, {
    key: ConstantsLagoon.VISION_DICTIONARY.DISCLAIMER_CONTENT,
  });

  const visionDirectoryDisclaimerAddlContent = find(lagoonData, {
    key: ConstantsLagoon.VISION_DICTIONARY.DISCLAIMER_ADDL_CONTENT,
  });

  const renderHealthGradesDisclaimer = () => {
    const isFacility = sectionType === Constants.FACILITY;
    const isOnlineProvider =
      search?.toLocaleLowerCase() ===
      Constants.ONLINE_PROVIDERS.toLocaleLowerCase();

    if (isFacility && !isOnlineProvider) return null;

    return (
      <HealthGradesDisclaimers
        disclaimerContent={modalDisclaimerShort?.message}
        modalContent={modalDisclaimerFull?.message}
      />
    );
  };

  return (
    <DisclaimerContainer cssProps={{ ratingSection }}>
      <HealthGradesStyled
        cssProps={{ isWidget }}
        data-auto-testid="standard-disclaimer-content"
        data-testid="standard-disclaimer-content"
      >
        <ContentWrapper isWidget={isWidget}>
          <Layout.Stack alignItems="left" space="0">
            <StyledMarkdown data-testid="standard-disclaimer">
              {disclaimerInPage?.message}
            </StyledMarkdown>
            {isVisionCare && (
              <VisionDirectoryDataDisclaimer
                visionDirectoryDisclaimerAddlContentMessage={
                  visionDirectoryDisclaimerAddlContent?.message
                }
                visionDirectoryDisclaimerMessage={
                  visionDirectoryDisclaimer?.message
                }
              />
            )}
            <PremiumCareDisclaimer
              showPremiumCareDisclaimer={showPremiumCareDisclaimer}
            />
            {renderHealthGradesDisclaimer()}
          </Layout.Stack>
        </ContentWrapper>
      </HealthGradesStyled>
    </DisclaimerContainer>
  );
};
