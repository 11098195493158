import find from 'lodash/find';
import React from 'react';

import { Constants } from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { DisclaimersContainer } from '../../../../common/DisclaimersContainer/DisclaimersContainer';
import { isMnrMember } from '../../../../common/Utils/memberUtils/memberUtils';
import { useLagoon } from '../../../../hooks/useLagoon';
import { getCurrentMember, isOHCNSMember } from '../../../../utils/user.utils';
import { HealthGradesContainerStyled } from '../PSXHome/HealthGradesContainer.styled';

type Props = {
  sectionType: string;
  isVisionCare: boolean;
};

export const ViewAllDisclaimers = ({ sectionType, isVisionCare }: Props) => {
  const { lineOfBusiness } = getCurrentMember();
  const isMNR = isMnrMember(lineOfBusiness);

  const uiMessaging = useLagoon('ui-messaging')();

  const OHCNSpolicyIds = find(uiMessaging, {
    key: ConstantsLagoon.UI_MESSAGING.STATE_SPECIFIC_DISCLAIMER,
  })?.policyIDs;

  const isOHCNS = isOHCNSMember(OHCNSpolicyIds);

  if (isOHCNS) return <DisclaimersContainer />;

  return isMNR ? (
    sectionType !== Constants.RESULT_SECTION.PROVIDER_GROUPS && (
      <HealthGradesContainerStyled>
        <DisclaimersContainer isViewAllDisclaimer />
      </HealthGradesContainerStyled>
    )
  ) : (
    <React.Fragment>
      {sectionType !== Constants.RESULT_SECTION.FACILITY &&
        sectionType !== Constants.RESULT_SECTION.PROVIDER_GROUPS && (
          <HealthGradesContainerStyled>
            <DisclaimersContainer isVisionCare={isVisionCare} />
          </HealthGradesContainerStyled>
        )}
      {isVisionCare &&
      (sectionType === Constants.RESULT_SECTION.FACILITY ||
        sectionType === Constants.RESULT_SECTION.PROVIDER_GROUPS) ? (
        <HealthGradesContainerStyled>
          <DisclaimersContainer isVisionCare={isVisionCare} />
        </HealthGradesContainerStyled>
      ) : null}
    </React.Fragment>
  );
};
