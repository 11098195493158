import { config } from '@abyss/web/tools/config';

import { useCustomQuery } from '../useCustomQuery';
import CARE_PATH_QUERY from './CarePath.graphql';

export const useCarePath = (options) =>
  useCustomQuery(CARE_PATH_QUERY, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    clearCache: [
      {
        key: 'carePath',
        variables: [
          'carePathStepCategory',
          'claimSystemTypeCode',
          'coverageType',
          'eligibilitySystemTypeCode',
          'lob',
          'locale',
          'membershipCategory',
          'pageNumber',
          'pageSize',
          'providerLocationKey',
          'providerType',
          'reciprocityId',
          'sortOrder',
        ],
      },
    ],
    requestPolicy: 'no-cache',
    accessor: 'carePath',
  });
