import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import isEmpty from 'lodash/isEmpty';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsOverflow } from '../../../../../../../../../../hooks/useIsOverflow';
import { PracticeLimitation } from '../../../../../../../../../../models/Provider';
import {
  StyledOverflowAttributeDiv,
  popoverStyle,
} from '../../../../../utility/compareDrawer.styled';
import {
  ATTRIBUTE_STATUS_TEXT,
  NULL_ATTRIBUTE_STATE,
} from '../../../../../utility/compareDrawerConstants';

type Props = { practiceLimitations: PracticeLimitation; mobile: boolean };

export const PracticeLimitations = ({ practiceLimitations, mobile }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);
  const { t } = useTranslation();
  const isAttributeEmpty = isEmpty(practiceLimitations);

  const practiceLimitationsText: string = (() => {
    if (isAttributeEmpty) return t(NULL_ATTRIBUTE_STATE.PRACTICE_LIMITATIONS);
    if (practiceLimitations?.startAge !== '0')
      return t(ATTRIBUTE_STATUS_TEXT.PRACTICE_LIMITATIONS.TRUE);
    return t(ATTRIBUTE_STATUS_TEXT.PRACTICE_LIMITATIONS.FALSE);
  })();

  const PracticeLimitations = (
    <StyledOverflowAttributeDiv
      cssProps={{ isOverflow, isAttributeEmpty }}
      ref={containerRef}
    >
      {practiceLimitations?.startAge ? (
        <Layout.Group space={4}>
          <IconMaterial color="$primary1" icon="back_hand" size={20} />
          {practiceLimitations?.startAge === '0'
            ? practiceLimitationsText
            : `${practiceLimitationsText}: ${practiceLimitations?.startAge}+`}
        </Layout.Group>
      ) : (
        practiceLimitationsText
      )}
    </StyledOverflowAttributeDiv>
  );

  if (mobile) {
    return (
      <React.Fragment>
        {isOverflow ? (
          <Popover
            content={practiceLimitationsText}
            css={popoverStyle}
            showClose={false}
            tabIndex="-1"
            width={150}
          >
            {PracticeLimitations}
          </Popover>
        ) : (
          PracticeLimitations
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {isOverflow ? (
        <Tooltip
          content={practiceLimitationsText}
          position="top"
          positionOffset={8}
        >
          {PracticeLimitations}
        </Tooltip>
      ) : (
        PracticeLimitations
      )}
    </React.Fragment>
  );
};
