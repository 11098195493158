import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { ProviderType } from '#/models/ProviderDetails';
import { useTranslation } from 'react-i18next';

import {
  SnackCardWithExternalLinkIcon,
  SnackCardWithExternalLinkText,
  SnackCardWithExternalLinkType,
  SnackCardsWithExternalLink,
  SnackContainer,
  SnackInfoCard,
} from './SnackCard.styles';

type Props = {
  displayName: string;
  providerSpeciality: string;
  providerType: ProviderType;
  isFixes: boolean;

  handleClick: any;
};

export const SnackCardWithExternalLink = ({
  displayName,
  providerSpeciality,
  providerType,
  isFixes,
  handleClick,
}: Props) => {
  const { t } = useTranslation();

  return (
    <SnackContainer
      data-auto-testid="snack-card-with-link-container"
      data-testid="snack-card-with-link-container"
      onClick={handleClick}
    >
      <SnackInfoCard
        alignContent="center"
        alignItems="center"
        data-auto-testid={`snack-card-${providerType}`.replace(/ /g, '-')}
        data-testid={`snack-card-${providerType}`.replace(/ /g, '-')}
        direction="column"
        isFixes={isFixes}
        isSnackCardWithExternalLink
        justify="space-between"
      >
        <Flex
          css={{
            'abyss-flex-root': {
              alignSelf: 'stretch',
            },
          }}
          data-testid="providers-container"
          direction="column"
        >
          <SnackCardWithExternalLinkIcon>
            <IconMaterial
              color="$info1"
              data-testid="providers-speciality-link"
              icon="open_in_new"
              size="18px"
            />
          </SnackCardWithExternalLinkIcon>
          <SnackCardsWithExternalLink>
            <Tooltip content={displayName}>
              <SnackCardWithExternalLinkText
                aria-label={`${displayName}, ${providerSpeciality}. ${t(
                  'OPENS_NEW_WINDOW'
                )}`}
                data-testid="providers-speciality"
                variant="outline"
              >
                {displayName}
              </SnackCardWithExternalLinkText>
            </Tooltip>
          </SnackCardsWithExternalLink>
          <Tooltip content={providerSpeciality}>
            <SnackCardWithExternalLinkType>
              {providerSpeciality}
            </SnackCardWithExternalLinkType>
          </Tooltip>
        </Flex>
      </SnackInfoCard>
    </SnackContainer>
  );
};
