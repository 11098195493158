import { Constants } from '../../../../../common/Constants';
import { ConstantsRoutes } from '../../../../../common/ConstantsRoutes';

export const parseBreadcrumbs = (urls, sectionType, t) => {
  const sectionTypeCondition =
    sectionType === Constants.RESULT_SECTION.PROVIDER
      ? t('Provider results')
      : t('Provider group results');
  const breadcrumbs = [
    {
      title:
        sectionType === Constants.RESULT_SECTION.FACILITY
          ? t('Facility results')
          : sectionTypeCondition,
      href: '',
    },
  ];
  if (urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key]) {
    breadcrumbs.unshift({
      title: t('BC Results'),
      href: `${urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key]}`,
    });
  }
  return breadcrumbs;
};
