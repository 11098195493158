import { FloatingSection } from '@abyss/web/ui/FloatingSection';

import {
  providerFloatDesktopClosed,
  providerFloatDesktopOpen,
  providerFloatDesktopOpenSelected,
  providerFloatMobile,
} from '../../../../common/ConstantsStyles';
import { ShareDrawer } from '../MapView/ShareDrawer/ShareDrawer';
import { ShareDrawerMobile } from '../MapView/ShareDrawer/ShareDrawerMobile';

type Props = {
  openShare: boolean;
  mobileScreen: boolean;
  openBoxContents: any;
  selectedItems: any;
  items: any;
  handleDetailsOnClickCb: any;
  isShareAllResults: boolean;
  removeItem: any;
  map: any;
  setIsShareAllResults: any;
  setOpenBoxContents: any;
  setOpenShare: any;
  setSelectedCheckbox: any;
  setSelectedItems: any;
  closeAllDrawersAndOpenSuccessAlert: any;
};

const selectedItemFloatSize = (mobileScreen, openBoxContents, selected) => {
  if (mobileScreen) {
    return providerFloatMobile;
  }
  if (openBoxContents && selected.length === 0) {
    return providerFloatDesktopOpen;
  }
  if (openBoxContents && selected.length > 0) {
    return providerFloatDesktopOpenSelected;
  }
  if (!openBoxContents) {
    return providerFloatDesktopClosed;
  }
  return null;
};

export const ShowShareFloatingSection = ({
  openShare,
  mobileScreen,
  openBoxContents,
  selectedItems,
  items,
  handleDetailsOnClickCb,
  isShareAllResults,
  removeItem,
  map,
  setIsShareAllResults,
  setOpenBoxContents,
  setOpenShare,
  setSelectedCheckbox,
  setSelectedItems,
  closeAllDrawersAndOpenSuccessAlert,
}: Props) =>
  openShare ? (
    <FloatingSection
      alwaysFloat
      css={selectedItemFloatSize(mobileScreen, openBoxContents, selectedItems)}
      data-auto-testid="mapview-share-drawer"
      data-testid="mapview-share-drawer"
      position="bottom"
    >
      {mobileScreen ? (
        <ShareDrawerMobile
          compareCheckboxes={items}
          data-auto-testid="drawer-share-mobile"
          data-testid="drawer-share-mobile"
          handleDetailsOnClick={handleDetailsOnClickCb}
          isShareAllResults={isShareAllResults}
          openBoxContents={openBoxContents}
          openShare={openShare}
          removeItem={removeItem}
          selected={selectedItems.length}
          selectedProviderList={selectedItems}
          setIsShareAllResults={setIsShareAllResults}
          setOpenBoxContents={setOpenBoxContents}
          setOpenShare={setOpenShare}
          setSelectedCheckbox={setSelectedCheckbox}
          setSelectedItems={setSelectedItems}
          showSuccessAlertCallback={closeAllDrawersAndOpenSuccessAlert}
          total={5}
        />
      ) : (
        <ShareDrawer
          compareCheckboxes={items}
          data-auto-testid="drawer-share-desktop"
          data-testid="drawer-share-desktop"
          isShareAllResults={isShareAllResults}
          map={map}
          openBoxContents={openBoxContents}
          openShare={openShare}
          removeItem={removeItem}
          selected={selectedItems.length}
          selectedProviderList={selectedItems}
          setIsShareAllResults={setIsShareAllResults}
          setOpenBoxContents={setOpenBoxContents}
          setOpenShare={setOpenShare}
          setSelectedCheckbox={setSelectedCheckbox}
          setSelectedItems={setSelectedItems}
          showSuccessAlertCallback={closeAllDrawersAndOpenSuccessAlert}
        />
      )}
    </FloatingSection>
  ) : null;
