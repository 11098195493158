export interface DetailsState {
  providerId?: string;
  locationId?: string;
  showDirection?: boolean;
  isTieredDataCard?: boolean;
  specialtySearch?: string;
  avatar?: string;
  inNetwork?: string;
  providerName?: string;
  primarySpeciality?: string[];
  professionalDesignation?: string;
  rating?: string;
  surveyCount?: number;
  npi?: string;
  providerDetails?: string;
  imageLocation?: string;
  searchByProvider?: boolean;
  isOHBS?: boolean;
  isTieredSnackCard?: boolean;
  organizationCode?: string[];
}

export interface DetailsStore {
  detailsState: DetailsState;
  setDetailsStore: (values: DetailsState) => void;
  clearDetailsStore: (defaultValues?: DetailsState) => void;
}

export enum StoreKeys {
  PROVIDER_ID = 'providerId',
  LOCATION_ID = 'locationId',
  SHOW_DIRECTION = 'showDirection',
  IS_TIERED_DATA_CARD = 'isTieredDataCard',
  SPECIALTY_SEARCH = 'specialtySearch',
  AVATAR = 'avatar',
  IN_NETWORK = 'inNetwork',
  PROVIDER_NAME = 'providerName',
  PRIMARY_SPECIALTY = 'primarySpeciality',
  PROFESSIONAL_DESIGNATION = 'professionalDesignation',
  RATING = 'rating',
  NPI = 'npi',
  PROVIDER_DETAILS = 'providerDetails',
  IMAGE_LOCATION = 'imageLocation',
  SEARCH_BY_PROVIDER = 'searchByProvider',
  IS_OHBS = 'isOHBS',
  IS_TIERED_SNACK_CARD = 'isTieredSnackCard',
  ORGANIZATION_CODE = 'organizationCode',
  DETAILS_STATE = 'detailsState',
  SET_DETAILS_STORE = 'setDetailsStore',
  CLEAR_DETAILS_STORE = 'clearDetailsStore',
}
