import { Popover } from '@abyss/web/ui/Popover';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsOverflow } from '../../../../../../../../../../hooks/useIsOverflow';
import { getStatusText } from '../../../../../../ShareDrawer/utils';
import {
  StyledOverflowAttributeDiv,
  popoverStyle,
} from '../../../../../utility/compareDrawer.styled';
import {
  ATTRIBUTE_STATUS_TEXT,
  NULL_ATTRIBUTE_STATE,
} from '../../../../../utility/compareDrawerConstants';

type Props = {
  isEap: boolean;
  mobile: boolean;
};

export const EapAccepted = ({ isEap, mobile }: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);
  const eapAcceptedText = t(
    getStatusText(
      isEap,
      ATTRIBUTE_STATUS_TEXT.EAP_ACCEPTED.TRUE,
      ATTRIBUTE_STATUS_TEXT.EAP_ACCEPTED.FALSE,
      NULL_ATTRIBUTE_STATE.EAP_ACCEPTED
    )
  );

  const isAttributeEmpty =
    eapAcceptedText === NULL_ATTRIBUTE_STATE.EAP_ACCEPTED;

  const EapAccepted = (
    <StyledOverflowAttributeDiv
      cssProps={{ isOverflow, isAttributeEmpty }}
      data-testid="compare-provider-eapAccepted"
      ref={containerRef}
    >
      {t(eapAcceptedText)}
    </StyledOverflowAttributeDiv>
  );

  if (mobile) {
    return (
      <React.Fragment>
        {isOverflow ? (
          <Popover
            content={t(eapAcceptedText)}
            css={popoverStyle}
            data-testid="compare-provider-eapAccepted"
            showClose={false}
            tabIndex="-1"
            width={150}
          >
            {EapAccepted}
          </Popover>
        ) : (
          EapAccepted
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {isOverflow ? (
        <Tooltip content={eapAcceptedText} position="top" positionOffset={8}>
          {EapAccepted}
        </Tooltip>
      ) : (
        EapAccepted
      )}
    </React.Fragment>
  );
};
