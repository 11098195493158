import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  RatingContainer,
  RatingText,
  RatingsStar,
  SpanStyle,
  VoiceOnlyText,
} from './Rating.styles';
import { NoRatingText } from './SnackCard.styles';

type Props = {
  hideNumber?: boolean;
  outOf: number;
  rating?: number;
  size?: number;
  navigateToSnackDetails?: () => void;
  surveyCount?: number;
};

export const Rating = ({
  hideNumber,
  outOf,
  rating,
  navigateToSnackDetails,
  surveyCount,
}: Props) => {
  const { t } = useTranslation();

  const surveyRating = surveyCount ? ` (${surveyCount})` : '';
  const roundedRating = Math.round(rating || 0);

  if (rating === undefined || rating === null) {
    return (
      <NoRatingText color="$neutralGray" data-testid="rating-container">
        {t('Ratings not available')}
      </NoRatingText>
    );
  }

  const ratingTextComponent = (
    <RatingText color="$accent1">
      <SpanStyle aria-hidden="true">
        {`${rating.toFixed(1)}${surveyRating}`}
      </SpanStyle>
      <VoiceOnlyText>{`${rating.toFixed(1)} stars`}</VoiceOnlyText>
    </RatingText>
  );

  return (
    <RatingContainer
      alignContent="center"
      alignItems="center"
      data-auto-testid="rating-container"
      data-testid="rating-container"
      onClick={navigateToSnackDetails}
    >
      <Flex.Content aria-hidden="true">
        {[...new Array(outOf)].map((item, index) => {
          const starNum: number = index + 1;
          const filled: boolean = starNum <= roundedRating;
          return (
            <RatingsStar
              color="$accent1"
              data-testid={`rating-star-${filled}`}
              fontWeight="$normal"
              key={`rating-star-${starNum}`}
              size="$extraSmallText"
              variant="native"
            >
              <IconMaterial
                css={{
                  'abyss-icon': {
                    position: 'relative',
                    top: filled ? '0px' : '1.5px',
                    fill: filled ? '$secondary2' : '$black',
                    stroke: filled ? '$starBorder' : '',
                    height: filled ? '16px' : '17.5px',
                    width: filled ? '16px' : '17.5px',
                  },
                }}
                icon={filled ? 'star_rate' : 'star_border'}
                size="16"
              />
            </RatingsStar>
          );
        })}
      </Flex.Content>
      <Flex.Content>{!hideNumber ? ratingTextComponent : null}</Flex.Content>
    </RatingContainer>
  );
};
