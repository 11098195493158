import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';
import { TextInput } from '@abyss/web/ui/TextInput';

export const ShareThisProviderRoot = styled(Flex, {
  gap: '24px',
  padding: '$md $md 0px',
});

export const ShareThisProviderHeadingWrapper = styled(Flex, {
  gap: '8px',
  padding: '$md',
  background: '$tint2',
  borderRadius: '8px',
  width: '100%',
});

export const ShareThisProviderHeadingName = styled(Heading, {
  '&.abyss-heading-root': {
    fontSize: '20.25px !important',
    lineHeight: '24px !important',
  },
});

export const ShareThisProviderText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '$lg',
    fontWeight: '$medium',
    fontStyle: 'normal',
    lineHeight: '24px',
  },
});

export const ShareThisProviderTextInput = styled(TextInput, {
  '&.abyss-text-input-label': {
    fontSize: '14.22px',
    fontWeight: '$semibold',
    lineHeight: '16px',
    color: '$neutralGray7',
    marginBottom: '$sm',
    span: {
      display: 'none',
    },
  },
});

export const ShareThisProviderTextInputURL = styled(TextInput, {
  '&.abyss-form-input-wrapper-container': {
    borderRadius: '8px',
    background: '$white',
    border: 'none',
  },
  '&.abyss-text-input': {
    fontSize: '12.64px !important',
    boxShawdow: 'none',
    borderStyle: 'none',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
    color: '$gray7',
    textOverflow: 'ellipsis ellipsis',
  },
});

export const ShareThisProviderButton = styled(Button, {
  '&.abyss-button-root': {
    width: '146px',
  },
});

export const ShareThisProviderButtonWrapper = styled(Flex, {
  '&.abyss-flex-root': {
    gap: '16px',
    padding: 0,
  },
});

export const toastSuccessStyle = {
  'abyss-toast-description': {
    marginTop: 0,
  },
  'abyss-toast-close-button': {
    marginTop: '8px',
  },
};
