import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Divider } from '@abyss/web/ui/Divider';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { NetworkStatus } from '../../../../../../../../common/Constants';
import { ConstantsLagoon } from '../../../../../../../../common/ConstantsLagoon';
import { phoneOnly } from '../../../../../../../../common/ConstantsStyles';
import { ProviderRating } from '../../../../../../../../common/DataCard/ProviderRating';
import { PortalContext } from '../../../../../../../../context/PortalContext';
import { useCoverageType } from '../../../../../../../../hooks/useCoverageType';
import { useFeatureFlag } from '../../../../../../../../hooks/useFeatureFlag';
import { StoreKeys } from '../../../../../../../../hooks/useStore/state';
import { useStore } from '../../../../../../../../hooks/useStore/useStore';
import { CompareProvider } from '../../../../../../../../models/Provider';
import { ResponseHeaders } from '../../../../../../../../models/ResponseHeaders';
import { suppressCostEstimatesForPolicies } from '../../../../../../../../utils/featureSuppress';
import { getShowCost } from '../../../../../../../../utils/showCost.utils';
import {
  ReviewsText,
  StyledAddressDistanceContainerDiv,
  StyledEducationContainerDiv,
  StyledSingleAttributeContainerDiv,
  TextRoot,
} from '../../../utility/compareDrawer.styled';
import { CompareCheckboxInfo } from '../../../utility/compareDrawerConstants';
import { AcceptingNewPatientsAttribute } from './Attributes/AcceptingNewPatientsAttribute';
import { EapAccepted } from './Attributes/BehavioralHealth/EapAccepted';
import { OffersEveningAppointments } from './Attributes/BehavioralHealth/OffersEveningAppointments';
import { OffersWeekendAppointments } from './Attributes/BehavioralHealth/OffersWeekendAppointments';
import { PracticeLimitations } from './Attributes/BehavioralHealth/PracticeLimitations';
import { ProviderEthnicity } from './Attributes/BehavioralHealth/ProviderEthnicity';
import { CostAttribute } from './Attributes/Cost/CostAttribute';
import { EducationAttributeDesktop } from './Attributes/Education/EducationAttributeDesktop';
import { EducationAttributeMobile } from './Attributes/Education/EducationAttributeMobile';
import { GenderText } from './Attributes/GenderText';
// import { HealthGradeRating } from './Attributes/HealthGradeRatings';
import { HoursOfOperationText } from './Attributes/HoursOfOperation';
import { LanguagesSpokenAttributeDesktop } from './Attributes/LanguagesSpoken/LanguagesSpokenAttributeDesktop';
import { LanguagesSpokenAttributeMobile } from './Attributes/LanguagesSpoken/LanguagesSpokenAttributeMobile';
import { Address } from './Attributes/Location/Address';
import { Distance } from './Attributes/Location/Distance';
import { PcpServicesAvaliable } from './Attributes/PcpServicesAvaliable';
import { PremiumCareProviderText } from './Attributes/PremiumCareProviderText';
import { SpecialtyAttribute } from './Attributes/SpecialtyAttribute';
import { TierOneProviderText } from './Attributes/TierOneProviderText';
import { VirtualCareOfferedAttribute } from './Attributes/VirtualCareOfferedAttribute';
import { ServicesOffered } from './Attributes/Vision/ServicesOffered';

type Props = {
  compareCheckboxes: CompareCheckboxInfo[];
  selectedProvider: CompareProvider;
  adobeIndex?: number;
  headers: ResponseHeaders;
  surveyCount?: number;
};

export const CompareExpandedCard = ({
  compareCheckboxes,
  selectedProvider,
  adobeIndex,
  headers,
}: Props) => {
  const { t } = useTranslation();
  const isTier1Plan = useStore(StoreKeys.TIER1_PLAN_FLAG);
  const mobile = useMediaQuery(phoneOnly);
  const dividerWidth = mobile ? 159 : 233;
  const { portalData } = useContext(PortalContext);
  const portalShowCost = getShowCost(portalData);

  const [costEnableFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.COST_ENABLED,
  ]);

  const coverageType = useCoverageType();

  const suppressCostEstimates = suppressCostEstimatesForPolicies(
    coverageType,
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.COST_ESTIMATES,
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.COST_ESTIMATES_EMPIRE
  );

  return (
    <React.Fragment>
      <React.Fragment>
        {/* NETWORK STATUS */}
        <Divider height={1} width={dividerWidth} />
        <Layout.Group
          data-auto-testid="compare-provider-status"
          data-testid="compare-provider-status"
          direction="row"
        >
          <IconMaterial
            color={selectedProvider.networkStatus ? '$success1' : ''}
            data-auto-testid={`compare-card-check-circle-icon-${selectedProvider.providerId}`}
            data-testid={`compare-card-check-circle-icon-${selectedProvider.providerId}`}
            icon="check_circle"
            size="$sm"
          />
          <TextRoot
            data-auto-testid={`compare-card-network-status-${selectedProvider.providerId}`}
            data-testid={`compare-card-network-status-${selectedProvider.providerId}`}
          >
            {selectedProvider.networkStatus
              ? t(NetworkStatus.INN)
              : t(NetworkStatus.OON)}
          </TextRoot>
        </Layout.Group>
      </React.Fragment>

      {/* SPECIALTY */}
      {compareCheckboxes.find((item) => item.name === 'specialties')
        ?.isChecked && (
        <StyledSingleAttributeContainerDiv>
          <Divider height={1} width={dividerWidth} />
          <Layout.Group data-testid="provider-specialty">
            <SpecialtyAttribute
              mobile={mobile}
              specialty={selectedProvider.speciality}
            />
          </Layout.Group>
        </StyledSingleAttributeContainerDiv>
      )}

      {/* REVIEWS */}
      {compareCheckboxes.find((item) => item.name === 'reviews')?.isChecked && (
        <StyledSingleAttributeContainerDiv>
          <Divider height={1} width={dividerWidth} />
          <Layout.Group
            css={{ minHeight: '24px' }}
            data-auto-testid="compare-card-rating"
            data-testid="compare-card-rating"
            space=""
          >
            <ReviewsText>
              <ProviderRating
                data-testid="rating-container"
                healthGradeRating={selectedProvider.healthGradeRating}
                hideNumber
                providerId={selectedProvider.providerId}
                sectionTypeDerived={selectedProvider.providerType}
                size={mobile ? 12 : 13}
                surveyCount={selectedProvider.surveyCount}
                underline={false}
              />
            </ReviewsText>
          </Layout.Group>
        </StyledSingleAttributeContainerDiv>
      )}

      {/* ADDRESS / DISTANCE */}
      {compareCheckboxes.find((item) => item.name === 'distance')
        ?.isChecked && (
        <StyledAddressDistanceContainerDiv>
          <Divider height={1} width={dividerWidth} />
          <Address
            address={selectedProvider.address}
            key={`${selectedProvider.providerId}-compareAttribute-address`}
            mobile={mobile}
          />
          <Distance
            distance={selectedProvider.distance}
            headers={headers}
            indexForAnalytics={adobeIndex}
            key={`${selectedProvider.providerId}-compareAttribute-distance`}
            mobile={mobile}
            providerId={selectedProvider.providerId}
            providerType={selectedProvider.providerType}
            selectedProvider={selectedProvider}
          />
        </StyledAddressDistanceContainerDiv>
      )}

      {/* Cost */}
      {compareCheckboxes.find((item) => item.name === 'cost')?.isChecked &&
        portalShowCost &&
        costEnableFlag &&
        !suppressCostEstimates && (
          <StyledSingleAttributeContainerDiv>
            <Divider height={1} width={dividerWidth} />
            <CostAttribute
              costIndicator={selectedProvider.costIndicator}
              key={`${selectedProvider.providerId}-compareAttribute-costIndicator`}
              mobileScreen={mobile}
            />
          </StyledSingleAttributeContainerDiv>
        )}

      {/* TIER 1 PROVIDER */}
      {compareCheckboxes.find((item) => item.name === 'tierOneProvider')
        ?.isChecked &&
        isTier1Plan && (
          <StyledSingleAttributeContainerDiv>
            <Divider height={1} width={dividerWidth} />
            <TierOneProviderText
              indexForAnalytics={adobeIndex}
              isTieredProvider={selectedProvider.isTieredProvider}
            />
          </StyledSingleAttributeContainerDiv>
        )}

      {/* EDUCATION */}
      {compareCheckboxes.find((item) => item.name === 'education')
        ?.isChecked && (
        <StyledEducationContainerDiv>
          <Divider height={1} width={dividerWidth} />
          {mobile ? (
            <EducationAttributeMobile
              educationInfo={selectedProvider.educationInfo}
            />
          ) : (
            <EducationAttributeDesktop
              educationInfo={selectedProvider.educationInfo}
            />
          )}
        </StyledEducationContainerDiv>
      )}

      {/* LANGUAGES SPOKEN */}
      {compareCheckboxes.find((item) => item.name === 'languagesSpoken')
        ?.isChecked && (
        <StyledSingleAttributeContainerDiv>
          <Divider height={1} width={dividerWidth} />
          {mobile ? (
            <LanguagesSpokenAttributeMobile
              languagesSpoken={selectedProvider.languagesSpoken}
            />
          ) : (
            <LanguagesSpokenAttributeDesktop
              languagesSpoken={selectedProvider.languagesSpoken}
            />
          )}
        </StyledSingleAttributeContainerDiv>
      )}

      {/* ACCEPTING NEW PATIENTS */}
      {compareCheckboxes.find((item) => item.name === 'acceptingNewPatients')
        ?.isChecked && (
        <StyledSingleAttributeContainerDiv>
          <Divider height={1} width={dividerWidth} />
          <AcceptingNewPatientsAttribute
            acceptingNewPatients={selectedProvider.acceptingNewPatients}
            mobile={mobile}
          />
        </StyledSingleAttributeContainerDiv>
      )}

      {/* GENDER */}
      {compareCheckboxes.find((item) => item.name === 'gender')?.isChecked && (
        <React.Fragment>
          <Divider height={1} width={dividerWidth} />
          <GenderText gender={selectedProvider.gender} mobile={mobile} />
        </React.Fragment>
      )}

      {/* VIRTUAL CARE OFFERED */}
      {compareCheckboxes.find((item) => item.name === 'virtualCareOffered')
        ?.isChecked && (
        <StyledSingleAttributeContainerDiv>
          <Divider height={1} width={dividerWidth} />
          <VirtualCareOfferedAttribute
            mobile={mobile}
            virtualCareOffered={selectedProvider.virtualCareOffered}
          />
        </StyledSingleAttributeContainerDiv>
      )}

      {/* HOURS OF OPERATION */}
      {compareCheckboxes.find((item) => item.name === 'hoursOfOperation')
        ?.isChecked && (
        <StyledSingleAttributeContainerDiv>
          <Divider height={1} width={dividerWidth} />
          <HoursOfOperationText
            hoursOfOperation={selectedProvider.hoursOfOperation}
            mobile={mobile}
          />
        </StyledSingleAttributeContainerDiv>
      )}

      {/* PREMIUM CARE PROVIDER */}
      {compareCheckboxes.find((item) => item.name === 'premiumCareProvider')
        ?.isChecked && (
        <React.Fragment>
          <Divider height={1} width={dividerWidth} />
          <PremiumCareProviderText
            mobile={mobile}
            premiumCareProvider={selectedProvider.premiumCareProvider}
          />
        </React.Fragment>
      )}

      {/* PCP SERVICES */}
      {compareCheckboxes.find(
        (item) => item.name === 'hasPCPServiceAvailability'
      )?.isChecked && (
        <StyledSingleAttributeContainerDiv>
          <Divider height={1} width={dividerWidth} />
          <PcpServicesAvaliable
            isPcpServiceAvaliable={selectedProvider.hasPCPServiceAvailability}
            mobile={mobile}
          />
        </StyledSingleAttributeContainerDiv>
      )}

      {/* Provider Ethnicity */}
      {compareCheckboxes.find(
        (item) => item.name === 'providerEthnicityExpress'
      )?.isChecked && (
        <StyledSingleAttributeContainerDiv>
          <Divider height={1} width={dividerWidth} />
          <ProviderEthnicity
            mobile={mobile}
            providerEthnicity={selectedProvider.providerEthnicityExpress}
          />
        </StyledSingleAttributeContainerDiv>
      )}

      {/* EAP ACCEPTED */}
      {compareCheckboxes.find((item) => item.name === 'eapAccepted')
        ?.isChecked && (
        <StyledSingleAttributeContainerDiv>
          <Divider height={1} width={dividerWidth} />
          <EapAccepted isEap={selectedProvider.isEap} mobile={mobile} />
        </StyledSingleAttributeContainerDiv>
      )}

      {/* Offers evening appointments */}
      {compareCheckboxes.find((item) => item.name === 'hasEveningAppointments')
        ?.isChecked && (
        <StyledSingleAttributeContainerDiv>
          <Divider height={1} width={dividerWidth} />
          <OffersEveningAppointments
            hasEveningAppointments={selectedProvider.hasEveningAppointments}
            mobile={mobile}
          />
        </StyledSingleAttributeContainerDiv>
      )}

      {/* Offers weekend appointments */}
      {compareCheckboxes.find((item) => item.name === 'hasWeekendAppointment')
        ?.isChecked && (
        <StyledSingleAttributeContainerDiv>
          <Divider height={1} width={dividerWidth} />
          <OffersWeekendAppointments
            hasWeekendAppointment={selectedProvider.hasWeekendAppointment}
            mobile={mobile}
          />
        </StyledSingleAttributeContainerDiv>
      )}

      {/* Practice Limitations */}
      {compareCheckboxes.find((item) => item.name === 'practiceLimitations')
        ?.isChecked && (
        <StyledSingleAttributeContainerDiv>
          <Divider height={1} width={dividerWidth} />
          <PracticeLimitations
            mobile={mobile}
            practiceLimitations={selectedProvider.practiceLimitations}
          />
        </StyledSingleAttributeContainerDiv>
      )}

      {/* Serives Offered */}
      {compareCheckboxes.find((item) => item.name === 'servicesOffered')
        ?.isChecked && (
        <React.Fragment>
          <Divider height={1} width={dividerWidth} />
          <ServicesOffered
            mobile={mobile}
            servicesOffered={selectedProvider.servicesOffered}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
