export const formatEmails = (
  emailOne: string,
  emailTwo: string,
  emailThree: string,
  emailFour: string,
  emailFive: string
): string[] => {
  const emails = [emailOne];
  if (emailTwo) emails.push(emailTwo);
  if (emailThree) emails.push(emailThree);
  if (emailFour) emails.push(emailFour);
  if (emailFive) emails.push(emailFive);
  return emails;
};
