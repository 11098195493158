import { storage } from '@abyss/web/tools/storage';

import { Constants } from '../../../../common/Constants';

const { Buffer } = require('buffer/');

export const getPlanSelectorVariables = () => {
  const PLAN_SELECTOR_COOKIE = Constants.COOKIES.PLAN_SELECTOR;
  const cookieValue = storage.cookie.get(PLAN_SELECTOR_COOKIE);

  if (cookieValue) {
    const cookieString = Buffer.from(cookieValue, 'base64').toString();
    const cookieJSON = JSON.parse(cookieString);
    return cookieJSON;
  }

  return {
    planToken: null,
    accountId: null,
    enablePlanSelectorV2: false,
  };
};
