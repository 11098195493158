import { config } from '@abyss/web/tools/config';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import {
  CoverageTypesCodes,
  DEFAULT_SORT_BY,
  ReverseCoverageTypesCodes,
} from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../common/Utils';
import { isMnrMember } from '../../common/Utils/memberUtils/memberUtils';
import { getLanguage } from '../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useCurrentMemberIsPreEffective } from '../../hooks/useCurrentMemberIsPreEffective';
import { CostCareCategory, CustomPreferredPolicy } from '../../models/Lagoon';
import { ProviderType } from '../../models/ProviderDetails';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../store/useDetailsStore';
import { DetailsStore } from '../../store/useDetailsStore/detailsStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { conditionalValueRender } from '../../utils/common';
import { getCarePathCategoriesSortOrder } from '../../utils/providerDetails.utils';
import {
  getLob,
  getMemberHealthCoverageType,
} from '../../utils/providerSearch.utils';
import {
  getClaimOrEligibilitySystemTypeCode,
  getCoverageEffDateRange,
  getCoverageTypes,
  getCurrentMember,
  getCurrentPlanYear,
  getIsCustomPreferredPolicy,
  getMemberEffectivePlanYear,
  getNetworkIdsForPES,
  getPlanVariationCode,
  getRulesPackageKey,
} from '../../utils/user.utils';
import { useCustomQuery } from '../useCustomQuery';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import { useLagoon } from '../useLagoon';
import GET_FACILITY_DETAILS from './FacilityDetails.graphql';
import GET_PROVIDER_DETAILS from './ProviderDetails.graphql';

export const useProviderDetailsSearch = (
  options,
  providerType = ProviderType.PRACTITIONER
) => {
  const query =
    providerType === ProviderType.PRACTITIONER
      ? GET_PROVIDER_DETAILS
      : GET_FACILITY_DETAILS;

  const accessor =
    providerType === ProviderType.PRACTITIONER
      ? 'providerDetails'
      : 'facilityDetails';

  const [providerDetails, setProviderDetails] = useState({});
  const [queryResult, queryAction] = useCustomQuery(query, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor,
    onCompleted: (result) => {
      if (options?.onCompleted) {
        options.onCompleted(result?.data);
      }
      setProviderDetails(result?.data);
    },
  });

  const parsedResult = {
    ...queryResult,
    data: providerDetails,
  };

  return [parsedResult, queryAction];
};

export const useProviderDetails = (
  variables,
  setSelectedPCP?: Dispatch<SetStateAction<boolean>>
) => {
  const locale = getLanguage()?.code || 'en';
  const {
    providerId = '',
    providerType,
    isOHBS,
    locationId,
    shouldGetHGData,
  } = variables;
  const { coverageType, storeLob } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
      storeLob: state.lob,
    }))
  );
  const { searchFromTypeAhead } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      searchFromTypeAhead: state.typeaheadSearchStore.searchFromTypeAhead,
    }))
  );
  const { organizationCode } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      organizationCode: state.detailsState.organizationCode,
    }))
  );
  const { longitude, latitude, stateCode } = useGeoLocationStorage();

  const currentMember = getCurrentMember();

  const [providerDetails, getProviderDetails] = useProviderDetailsSearch(
    {},
    providerType
  );

  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const uspTierOneFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_TIER1_ENABLE
  );

  const uspToggleFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_ENABLE
  );

  const isFutureTerminationDemo: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.FUTURE_TERMINATION_DEMO
  );

  const allConfigs = useLagoon('config')();
  const terminationProviderIdsDemo = allConfigs?.find(
    (row) => row.key === 'TERMINATION_PROVIDER_IDS_DEMO'
  )?.value;

  const customPreferredPolicies: CustomPreferredPolicy[] = useLagoon(
    'custom-preferred-policies'
  )();
  const costCareCategories: CostCareCategory[] = useLagoon(
    'cost-care-categories'
  )();
  const tierBenefits = useLagoon('tier-benefit')();
  const facilityAttributes = useLagoon('facility-attributes')();
  const category = facilityAttributes?.find(
    (row) => row?.facilitySubCategoryCode === organizationCode?.[0]
  );

  const {
    demographics,
    lineOfBusiness: memberLob,
    population,
    policyNumber: policyId,
  } = currentMember;

  const lob = getLob(memberLob, storeLob);
  const isMnr = isMnrMember(lob);

  const reciprocityId = isOHBS
    ? '100'
    : getNetworkIdsForPES(currentMember, coverageType, featureFlags);
  const isCustomPreferredPolicy: boolean = getIsCustomPreferredPolicy(
    customPreferredPolicies,
    policyId,
    reciprocityId
  );

  const medicalCoverage = getMemberHealthCoverageType(
    currentMember?.eligibility,
    CoverageTypesCodes.MEDICAL
  );

  const isPreEffective = useCurrentMemberIsPreEffective();
  const currentMemberEffectiveDate =
    getCoverageEffDateRange(currentMember).startDate;
  useEffect(() => {
    if (!providerId) {
      return;
    }

    if (
      typeof setSelectedPCP !== 'undefined' &&
      demographics?.primaryCare?.providerID === providerId
    ) {
      setSelectedPCP(true);
    }

    const memCatergory = currentMember?.membershipCategory || '';
    const membershipCategory = conditionalValueRender(
      uspToggleFlag,
      memCatergory,
      null
    );
    getProviderDetails({
      variables: {
        latitude,
        longitude,
        stateCode,
        locale,
        lob,
        population,
        membershipCategory,
        coverages: getCoverageTypes(currentMember),
        planYear: !isPreEffective
          ? getCurrentPlanYear()
          : getMemberEffectivePlanYear(new Date(currentMemberEffectiveDate)),
        memberEffectiveDate: isPreEffective ? currentMemberEffectiveDate : null,
        planVariationCode: getPlanVariationCode(currentMember, coverageType),
        claimSystemTypeCode: getClaimOrEligibilitySystemTypeCode(
          currentMember,
          coverageType
        )?.claimSystemTypeCode,
        eligibilitySystemTypeCode: getClaimOrEligibilitySystemTypeCode(
          currentMember,
          coverageType
        )?.eligibilitySystemTypeCode,
        policyId,
        policyID: policyId,
        providerId,
        providerType: providerType ?? ProviderType.PRACTITIONER,
        reciprocityId,
        rulesPackageKey: getRulesPackageKey(
          currentMember,
          tierBenefits,
          providerType,
          uspTierOneFlag
        ),
        snpType:
          coverageType == ReverseCoverageTypesCodes.MEDICAL
            ? medicalCoverage?.snpType
            : '',
        coverageType,
        locationId: locationId || undefined,
        carePathCategoriesSortOrder: getCarePathCategoriesSortOrder(
          ProviderType.PRACTITIONER === providerType,
          locale,
          costCareCategories,
          isMnr
        ),
        shouldGetHGData,
        isCustomPreferredPolicy,
        isFutureTerminationDemo,
        terminationProviderIdsDemo,
        cesProductCode: medicalCoverage?.productCode,
        memberMarketType: medicalCoverage?.marketType,
        shouldGetLFData: !!searchFromTypeAhead || !!category,
      },
    });
  }, [latitude, longitude, stateCode, providerId, coverageType]);

  return providerDetails;
};

export const useProviderDetailsWidget = (variables) => {
  const locale = getLanguage()?.code || 'en';
  const {
    claimSystemTypeCode,
    coverageType,
    eligibilitySystemTypeCode,
    latitude,
    lob,
    population,
    locationId,
    longitude,
    reciprocityId,
    planVariationCode,
    planYear,
    policyId,
    providerId,
    providerType,
    shouldGetHGData,
    membershipCategory,
  } = variables;
  const { stateCode } = useGeoLocationStorage();

  const [providerDetails, getProviderDetails] = useProviderDetailsSearch(
    {},
    providerType
  );

  useEffect(() => {
    getProviderDetails({
      variables: {
        latitude,
        longitude,
        stateCode,
        locale,
        lob,
        population,
        coverages: [coverageType],
        planYear,
        planVariationCode,
        claimSystemTypeCode,
        eligibilitySystemTypeCode,
        policyId,
        policyID: policyId,
        providerId,
        providerType: providerType ?? ProviderType.PRACTITIONER,
        reciprocityId,
        coverageType,
        locationId,
        shouldGetHGData,
        shouldGetLFData: providerType === ProviderType.ORGANIZATION,
        membershipCategory,
        sortBy: DEFAULT_SORT_BY,
      },
    });
  }, [latitude, longitude, stateCode, providerId, coverageType]);

  return providerDetails;
};
