import { styled } from '@abyss/web/tools/styled';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';

export const TabContainer = styled('div', {
  color: '$primary1',
  overflow: 'auto hidden',
});

export const premiumTextIconStyle = (mobileScreen: boolean) => ({
  '&.abyss-icon-material': {
    marginRight: '12px',
    ...(mobileScreen && {
      marginRight: '$sm',
    }),
  },
});

export const StackDiv = styled('div', {});

export const stackDivStyle = (
  mobileScreen: boolean,
  widgetDesktopScreen?: boolean,
  last: boolean = false
) => ({
  borderBottom: '1px solid $cardBorder',
  borderTop: '1px solid $cardBorder',
  marginBottom: last ? '0' : '8px',
  marginTop: '8px',

  padding: '$lg calc((100% - 1200px) / 2)',
  '@media (max-width: 1248px)': {
    padding: '$lg',
  },
  ...(mobileScreen && {
    padding: '$md !important',
  }),
  ...(widgetDesktopScreen && {
    padding: '$md $lg !important',
  }),
});

export const premiumCareSection = (phoneScreen: boolean) => ({
  padding: '0 0 8px 0',
  ...(phoneScreen && {
    paddingBottom: '4px',
    height: '20px',
  }),
});

export const headerText = (mobileScreen) => ({
  ...(mobileScreen && {
    '&.abyss-heading-root': {
      fontSize: '20.25px !important',
      lineHeight: '24px !important',
    },
  }),
});

export const qualityText = (mobileScreen) => ({
  '&.abyss-text-root': {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: '500',

    ...(mobileScreen && {
      fontSize: '16px',
      lineHeight: '20px',
    }),
  },
});

export const PopoverContentContainer = styled('div', {
  '.abyss-icon-material': {
    width: '24px',
    height: '24px',
    marginLeft: '8px',
  },
});
export const ContentText = styled('div', {
  paddingBottom: '8px',
  '@screen < $sm': {
    paddingBottom: '16px',
  },
});

export const drawerStyle = {
  'abyss-icon-material': {
    marginBottom: '-0.5px',
    '@screen < $md': {
      margin: '0 0 0 4px',
    },
  },
};

export const PopoverIcon = styled(IconMaterial, {
  margin: '6px 0 0 4px',
});
