import { useEffect, useState } from 'react';

import { getGeoLocationFromStorage } from '../../common/PSXHeader/SearchBar/utils';

export const useGeoLocationStorage = () => {
  const [geoLocation, setGeoLocation] = useState(getGeoLocationFromStorage());
  useEffect(() => {
    const getGeoLocation = () => {
      const geoLocation = getGeoLocationFromStorage();
      setGeoLocation(geoLocation);
    };
    window.addEventListener('storage', getGeoLocation);
    return () => {
      window.removeEventListener('storage', getGeoLocation);
    };
  }, []);

  return geoLocation;
};
