import { config } from '@abyss/web/tools/config';

import { useCustomQuery } from '../useCustomQuery';
import COMPARE_PROVIDERS from './CompareProviders.graphql';

export const useCompareProviders = (options) =>
  useCustomQuery(COMPARE_PROVIDERS, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'compare',
  });
