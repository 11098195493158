import { Layout } from '@abyss/web/ui/Layout';
import { useTranslation } from 'react-i18next';

import { EducationInformation } from '../../../../../../models/Provider';
import { NULL_ATTRIBUTE_STATE } from '../../CompareDrawer/utility/compareDrawerConstants';
import { getEducation } from '../../CompareDrawer/utility/getEducationText';
import { PrintViewProviderDetailsXSText } from './PrintExpandedCard.styled';

type Props = {
  educationInfo: EducationInformation;
};

export const PrintEducationAttribute = ({ educationInfo }: Props) => {
  const { t } = useTranslation();
  const education = getEducation(educationInfo);
  const isUniversityEmpty =
    education.schoolName === NULL_ATTRIBUTE_STATE.EDUCATION_SCHOOLS;
  const schoolNameToDisplay = isUniversityEmpty
    ? t(education.schoolName)
    : education.schoolName;
  return (
    <Layout.Stack alignItems="left" space={0}>
      {/* UNIVERSITY */}
      <PrintViewProviderDetailsXSText>
        {schoolNameToDisplay}
      </PrintViewProviderDetailsXSText>

      {/* DEGREE */}
      <PrintViewProviderDetailsXSText>
        {education.degreeCode}
      </PrintViewProviderDetailsXSText>
    </Layout.Stack>
  );
};
