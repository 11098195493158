import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { usePagination } from '@abyss/web/hooks/usePagination';
import { Button } from '@abyss/web/ui/Button';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Popover } from '@abyss/web/ui/Popover';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { Text } from '@abyss/web/ui/Text';
import find from 'lodash/find';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { INITIAL_PAGE, PAGE_SIZE } from '../../../../common/Constants';
import { useLagoon } from '../../../../hooks/useLagoon';
import { CarePathsResponse } from '../../../../models/CarePaths';
import { ProviderLocation } from '../../../../models/ProviderDetails';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { adobeModalTrackEvent } from '../../../AdobeTagging/adobeModalTrackEvent';
import { ConstantsLagoon } from '../../../ConstantsLagoon';
import { phoneOnly } from '../../../ConstantsStyles';
import { CommonCostProceduresHeader } from '../Cost.styled';
import {
  CarePathFlexContainer,
  carePathButtonStyles,
  carePathFlexStyles,
  carePathPopoverStyles,
  carePathResponsiveDrawerStyles,
  carePathTextStyles,
} from './CarePaths.styled';
import { CarePathsPagination } from './CarePathsPagination';
import { CarePathsResults } from './CarePathsResults';

type Props = {
  carePaths: CarePathsResponse;
  selectedLocation: ProviderLocation;
  setCarePathDataAvailable: (a: boolean) => void;
  setCarePathPageNum: (num: number) => void;
  setCarePathStepCategory: (category: string) => void;
};

export const CarePaths = ({
  carePaths,
  selectedLocation,
  setCarePathDataAvailable,
  setCarePathPageNum,
  setCarePathStepCategory,
}: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(phoneOnly);

  const [carePathsData, setCarePathsData] = useState<CarePathsResponse>();

  const data = useLagoon('ui-messaging')();
  const careTypeTitle = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.CARE_TYPE_TITLE,
  });
  const selectCareTypeTitle = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.SELECT_CARE_TYPE_TITLE,
  });

  const { carePathCategorySelected, carePathSteps } = carePaths;

  const [carePathTypeSelected, setCarePathTypeSelected] = useState(
    carePathCategorySelected
  );
  const [carePathStepCategoryList, setCarePathStepCategoryList] = useState<
    string[]
  >([]);
  const [radioValue, setRadioValue] = useState<string>('');
  const [isOpen, setIsOpen] = useState(false);

  const [pageNumber, setPageNumber] = useState<number>(INITIAL_PAGE);
  const [pageSize] = useState(PAGE_SIZE);
  const [resultsCount, setResultsCount] = useState<number>(0);

  useEffect(() => {
    const {
      carePathCategorySelected: newCategorySelected,
      carePathStepCategoryList: newCategoryList = [],
      totalCount = 0,
    } = carePaths || {};

    setCarePathsData(carePaths);
    setCarePathDataAvailable(totalCount > 0);
    setRadioValue(newCategorySelected || '');
    setResultsCount(totalCount);

    if (carePathStepCategoryList.length === 0) {
      setCarePathStepCategoryList(newCategoryList);
    }
  }, [carePathSteps]);

  const changeCareCategorySelected = (newCareCategory) => {
    if (newCareCategory !== carePathTypeSelected) {
      setCarePathsData({});
      setCarePathTypeSelected(newCareCategory);
      firstPage();
    }
  };

  const carePathHeaderList = carePathStepCategoryList?.sort((a, b) => {
    if (a > b) {
      return 1;
    }
    if (a < b) {
      return -1;
    }
    return 0;
  });

  const carePathCount = carePathStepCategoryList?.length == 1;

  const onCategoryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value: string = event.target?.value;
    setRadioValue(value);
    adobeLinkTrackEvent({
      name: value,
      location: `modal:care path category`,
      type: 'radio',
    });
  };

  const selectCareTypeHeading = (
    <Heading
      css={CommonCostProceduresHeader}
      data-auto-testid="cost-tab-select-care-heading"
      data-testid="cost-tab-select-care-heading"
      display="h4"
      id="selectCareType"
      offset={1}
    >
      {selectCareTypeTitle?.message || ''}
    </Heading>
  );

  const pagesCount = Math.ceil(resultsCount / pageSize);

  const {
    state,
    gotoPage,
    nextPage,
    previousPage,
    canNextPage,
    ...paginationProps
  } = usePagination({
    pages: pagesCount,
    start: pageNumber,
  });

  const { currentPage } = state;
  const { firstPage } = paginationProps;

  useEffect(() => {
    if (pageNumber !== currentPage) {
      setPageNumber(currentPage);
      setCarePathPageNum(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    setCarePathStepCategory(carePathTypeSelected);
  }, [carePathTypeSelected]);

  const carePathContent = (
    <CarePathFlexContainer data-testid="care-path-category-content">
      <RadioGroup
        data-testid="care-path-category-radio-group"
        defaultValue={carePathTypeSelected}
        onChange={onCategoryChange}
        value={radioValue}
      >
        {carePathHeaderList?.map((carePathCategory) => (
          <RadioGroup.Radio
            css={{
              'abyss-radio-label': {
                color: carePathCategory === radioValue ? '#323334' : '#4B4D4F',
                fontSize: '16px',
                fontWeight: carePathCategory === radioValue ? '700' : '500',
                lineHeight: '20px',
              },
              'abyss-radio-root': {
                alignItems: 'center',
                padding: '12px 4px',
                marginTop: '0px !important',
              },
            }}
            data-testid="care-path-category-input-radio"
            key={`care-path-category-${carePathCategory}`}
            label={carePathCategory}
            value={carePathCategory}
          />
        ))}
      </RadioGroup>
      <Button
        css={carePathButtonStyles}
        data-testid="care-path-category-change"
        onClick={() => {
          adobeLinkTrackEvent({
            name: 'change',
            location: `modal:care path category`,
          });
          setIsOpen(false);
          changeCareCategorySelected(radioValue);
        }}
      >
        {t('Change')}
      </Button>
    </CarePathFlexContainer>
  );

  return (
    <React.Fragment>
      {carePathStepCategoryList?.length > 0 && (
        <React.Fragment>
          {!isMobile ? (
            <React.Fragment>
              {selectCareTypeHeading}
              <Popover
                aria-labelledby="selectCareType"
                content={carePathContent}
                css={carePathPopoverStyles}
                data-auto-testid="care-path-category-popover-content"
                data-testid="care-path-category-popover-content"
                onOpenChange={() => {
                  setIsOpen(!isOpen);
                }}
                open={carePathCount ? false : isOpen}
                title={careTypeTitle?.message || ''}
                width={313}
              >
                <Flex
                  css={carePathFlexStyles}
                  data-testid="care-path-category-popover-label"
                  onClick={() => {
                    adobeLinkTrackEvent({
                      name: 'care path popover',
                      location: 'body:cost estimates at this location',
                      type: 'popover',
                    });
                    adobeModalTrackEvent('care path category');
                    setIsOpen(true);
                  }}
                >
                  <Text css={carePathTextStyles}>
                    {carePathTypeSelected === ''
                      ? radioValue
                      : carePathCategorySelected}
                  </Text>
                  {!carePathCount && (
                    <IconMaterial color="#323334" icon="keyboard_arrow_down" />
                  )}
                </Flex>
              </Popover>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {selectCareTypeHeading}
              <Flex
                aria-labelledby="selectCareType"
                css={carePathFlexStyles}
                data-testid="care-path-category-drawer-label"
                onClick={() => {
                  adobeLinkTrackEvent({
                    name: 'care path drawer',
                    location: `modal: care path mobile drawer`,
                    type: 'modal',
                  });
                  setIsOpen(true);
                }}
              >
                <Text css={carePathTextStyles}>
                  {carePathTypeSelected === ''
                    ? radioValue
                    : carePathCategorySelected}
                </Text>
                {!carePathCount && (
                  <IconMaterial color="#323334" icon="keyboard_arrow_down" />
                )}
              </Flex>
              <Drawer
                css={carePathResponsiveDrawerStyles}
                isOpen={carePathCount ? false : isOpen}
                onClose={() => setIsOpen(false)}
                position="bottom"
                title={careTypeTitle?.message || ''}
              >
                {carePathContent}
              </Drawer>
            </React.Fragment>
          )}
          <CarePathsResults
            carePathsData={carePathsData || {}}
            selectedLocation={selectedLocation}
          />
          {pagesCount > 1 && resultsCount > pageSize && (
            <CarePathsPagination
              canNextPage={canNextPage}
              gotoPage={gotoPage}
              nextPage={nextPage}
              pageSize={pageSize}
              pagesCount={pagesCount}
              paginationProps={paginationProps}
              previousPage={previousPage}
              resultsCount={resultsCount}
              setCarePathsData={setCarePathsData}
            />
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
