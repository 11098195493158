import { Badge } from '@abyss/web/ui/Badge';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';

import { useLagoon } from '../../hooks/useLagoon';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { getFeatureFlag } from '../Utils';
import { getFormattedDate } from '../Utils/datesUtils/formatDate';
import { checkTerminationDate } from '../Utils/terminateDateIndicatorUtils';
import { TerminationBannerWrapper } from './TerminationBadge.styled';

type Props = {
  endDate: string;
  message: string;
};

export const TerminationBadge = ({ endDate = '', message = '' }: Props) => {
  const featureFlags = useLagoon('feature-flags')();

  const displayTerminationDateEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISPLAY_FUTURE_TERMINATION_DATE
  );

  const displayFutureTerminationDate = checkTerminationDate(
    displayTerminationDateEnabled,
    endDate
  );

  return displayFutureTerminationDate ? (
    <TerminationBannerWrapper data-testid="termination-badge">
      <Badge
        icon={
          <IconMaterial
            color="$warning1"
            icon="warning"
            size="$sm"
            variant="filled"
          />
        }
        variant="warning"
      >
        {message} {getFormattedDate(endDate)}
      </Badge>
    </TerminationBannerWrapper>
  ) : null;
};
