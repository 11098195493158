import { Flex } from '@abyss/web/ui/Flex';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';

export interface Props {
  isLoading: boolean;
}

export const LoadingSpinnerComp = ({ isLoading }: Props) => (
  <Flex alignContent="center" css={{ height: '80vh' }} justify="center">
    <LoadingSpinner
      ariaLoadingLabel="loading results"
      isLoading={isLoading}
      size="$lg"
    />
  </Flex>
);
