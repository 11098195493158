import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { config } from '@abyss/web/tools/config';
import { Button } from '@abyss/web/ui/Button';
import { Divider } from '@abyss/web/ui/Divider';
import { Flex } from '@abyss/web/ui/Flex';
import React, { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import { LAWW_PORTAL_NAME, LAWW_USER_TIER1, LAWW_USER_TIER2 } from '../../../../common/Constants';
import { CostIndicatorPopover } from '../../../../common/DataCard/CostIndicatorPopover';
import { PortalContext } from '../../../../context/PortalContext';
import { useCoverageType } from '../../../../hooks/useCoverageType';
import { useCurrentMemberIsPreEffective } from '../../../../hooks/useCurrentMemberIsPreEffective';
import { useGeoLocationStorage } from '../../../../hooks/useGeoLocationStorage';
import { useLagoon } from '../../../../hooks/useLagoon';
import { CarePathsResponse } from '../../../../models/CarePaths';
import { ProviderLocation } from '../../../../models/ProviderDetails';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { ConstantsLagoon } from '../../../ConstantsLagoon';
import { phoneOnly } from '../../../ConstantsStyles';
import { CostIndicator } from '../../../CostIndicator';
import { getFeatureFlag } from '../../../Utils/getFeatureFlag';
import {
  CarePathCost,
  CarePathCostContainer,
  CarePathCostText,
  CarePathEstimationButtonStyles,
  CarePathResultContainer,
  CarePathResultName,
  CarePathResultsList,
  CostIndicatorStyled,
  CostIndicatorText,
} from './CarePaths.styled';
import { TypeaheadState } from '../../../../store/useTypeaheadStore/typeaheadStore';
import { useTypeaheadStore } from '../../../../store/useTypeaheadStore';

type Props = {
  carePathsData: CarePathsResponse | undefined;
  selectedLocation: ProviderLocation;
};

const viewCostEstimate = (
  costEstimateLink: string,
  portalName: string,
  initiateViewEstimate: boolean,
  isGuestUser: boolean,
  basePath: string,
  userTier?: string,
  signInUrl?: string,
  carePathStepId?: string,
  legacySSOUrl?: string
) => {
  if (isGuestUser && signInUrl) {
    const carePathIdParam = carePathStepId
      ? `&carePathId=${carePathStepId}`
      : '';
    const carePathStepIdParam = carePathStepId
      ? `&carePathStepId=${carePathStepId}_1`
      : '';
    const callBackUrl = `${window.location.pathname}${window.location.search}${carePathIdParam}${carePathStepIdParam}&initiateViewEstimate=${initiateViewEstimate}`;
    const newSignInUrl= new URL(
      signInUrl.replace('LANDING_PAGE', callBackUrl),
      window.location.origin
    ).href;

    window.open(newSignInUrl, '_self');
  } else if (portalName === LAWW_PORTAL_NAME && isTier2User(userTier) && legacySSOUrl) {
    const SSOUrl = legacySSOUrl.replace('FPC_URL', encodeURIComponent(costEstimateLink));
    const newSSOUrl = new URL(
      basePath.replace('/provider-search', SSOUrl),
      window.location.origin
    ).href;
    window.open(newSSOUrl, '_blank');
  } else {
    window.open(costEstimateLink, '_blank');
  }
};

const isTier2User = (userTier?: string) => userTier === LAWW_USER_TIER2;

export const CarePathsResults = ({
  carePathsData,
  selectedLocation,
}: Props) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery(phoneOnly);
  const coverageType = useCoverageType();
  const isPreEffective = useCurrentMemberIsPreEffective();
  const { longitude, latitude, zipCode } = useGeoLocationStorage();

  const locationKey = selectedLocation?.locationKey || '';
  const locationLatitude = selectedLocation?.latitude || latitude;
  const locationLongitude = selectedLocation?.longitude || longitude;
  const locationZipcode =
    selectedLocation?.address?.postalCode.split('-')[0] || zipCode;

  const featureFlags = useLagoon('feature-flags')();
  const { portalData } = useContext(PortalContext);
  const typeaheadState = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      initiateViewEstimate: state.typeaheadSearchStore.initiateViewEstimate,
      carePathId: state.typeaheadSearchStore.carePathId
    }))
  );
  const carePathsButtonRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const initiateViewEstimate = typeaheadState?.initiateViewEstimate;
  useEffect(() => {
    if (initiateViewEstimate && isTier2User(portalData?.portalConfig?.userTier)) {
      const carePathId = typeaheadState?.carePathId;
      if (carePathId !== undefined && carePathsButtonRefs.current[carePathId]) {
        carePathsButtonRefs.current[carePathId]?.click();
      }
    }
  }, [initiateViewEstimate, portalData, typeaheadState]);
  const isGuestUser = portalData.portalConfig?.isGuestUser ?? false;
  const signInUrl = portalData.portalConfig?.signInUrl;
  const userTier = portalData?.portalConfig?.userTier;

  const enableCEX: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_CEX_GET_FULL_COST_ESTIMATE
  );

  const getCostIndicatorText = (indicatorValue) => {
    if (indicatorValue === '$')
      return t('PROVIDER_DETAILS.COST_TAB.COST_AVERAGES.BELOW_AVERAGE');
    if (indicatorValue === '$$')
      return t('PROVIDER_DETAILS.COST_TAB.COST_AVERAGES.AVERAGE');
    if (indicatorValue === '$$$')
      return t('PROVIDER_DETAILS.COST_TAB.COST_AVERAGES.ABOVE_AVERAGE');

    return '';
  };

  const getCostIndicatorColor = (indicatorValue) => {
    if (indicatorValue === '$') return '$success1';
    if (indicatorValue === '$$') return '#0248AC';
    if (indicatorValue === '$$$') return '#AA2E23';

    return '';
  };

  const getCoverageType = (coverageType) => {
    if (coverageType === 'M') return 'medical';
    if (coverageType === 'V') return 'vision';
    if (coverageType === 'D') return 'dental';
    if (coverageType === 'B') return 'behavioral';

    return '';
  };

  const getProviderId = (providerId) => {
    if (coverageType === 'V' && providerId.startsWith('0')) {
      const providerIntValue = parseInt(providerId, 10);
      return (
        providerIntValue.toString().replace('P', '') ||
        providerIntValue.toString().replace('O', '')
      );
    }
    if (coverageType === 'M' && providerId.startsWith('0')) {
      const providerIntValue = parseInt(providerId, 10);
      return providerIntValue.toString();
    }

    return providerId;
  };
  const getLocationId = (locationId) => {
    if (coverageType === 'V') {
      if (locationId.charAt(locationId.length - 1) === 'P') {
        locationId = locationId.replace(
          locationId.substring(locationId.length - 1),
          ''
        );
        return locationId;
      }
    }
    return locationId;
  };

  const getCostEstimateLink = (carePath: any) =>
    isTier2User(portalData?.portalConfig?.userTier)
      ? `/carePath/${
          carePath.carePathIdentifier
        }?coverageType=${getCoverageType(coverageType)}&providerId=${getProviderId(
          locationKey.split('-')[0]
        )}&locationId=${getLocationId(
          locationKey.split('-')[3]
        )}&zipCode=${locationZipcode}&providerType=person&carePathStepId=${
          carePath.carePathIdentifier
        }_1&lat=${locationLatitude}&long=${locationLongitude}&source=psx`
      : `${config('BASE_URL')}/${
          enableCEX ? 'costestimate' : 'care'
        }/carePath/${
          carePath.carePathIdentifier
        }?coverageType=${getCoverageType(coverageType)}&providerId=${getProviderId(
          locationKey.split('-')[0]
        )}&locationId=${getLocationId(
          locationKey.split('-')[3]
        )}&zipCode=${locationZipcode}&providerType=person&carePathStepId=${
          carePath.carePathIdentifier
        }_1&lat=${locationLatitude}&long=${locationLongitude}`;

  const listOfCarePaths = carePathsData?.carePathSteps?.map(
    (carePath, index) => (
      <li
        data-testid={`care-path-${carePath.carePathName}`}
        key={`care-path-${carePath.carePathName}-${index}`}
      >
        {!isMobile && index === 0 && (
          <Divider color="#E5E5E6" margin="$lg" orientation="horizontal" />
        )}
        <CarePathResultContainer
          data-testid="care-path-result-container"
          direction="row"
          justify="space-between"
        >
          <CarePathResultName data-testid="care-path-result-container-heading">
            {carePath.carePathName}
          </CarePathResultName>
          <CarePathCostContainer data-testid={`care-path-avg-cost-${index}`}>
            <CarePathCostText>
              {t('PROVIDER_DETAILS.COST_TAB.CARE_PATH.ESTIMATED_AVG_COST')}
              <CarePathCost data-testid={`care-path-cost`}>
                ${carePath?.cost || ''}
              </CarePathCost>
            </CarePathCostText>
            <Flex css={CostIndicatorStyled}>
              <CostIndicator indicatorData={carePath?.costIndicator || ''} />
              <CostIndicatorText
                color={getCostIndicatorColor(carePath?.costIndicator)}
              >
                {getCostIndicatorText(carePath?.costIndicator)}
              </CostIndicatorText>
              <CostIndicatorPopover
                callFrom="carepathresults"
                costIndicator={carePath?.costIndicator || ''}
                displayCostIndicator={false}
              />
            </Flex>
          </CarePathCostContainer>
          {!isPreEffective &&
            !(portalData?.portalName == LAWW_PORTAL_NAME && userTier === LAWW_USER_TIER1) && (
              <Button
                aria-label={`get-full-cost-estimate-for ${
                  carePath.carePathName
                } estimated-average-cost-is ${
                  carePath?.cost + 'dollars' || 'unavailable'
                }`}
                css={CarePathEstimationButtonStyles}
                data-testid={`full-cost-estimate`}
                onClick={() => {
                  adobeLinkTrackEvent({
                    name: 'full cost estimate',
                    location: `body:${carePath.carePathName}`,
                    type: 'external',
                  });
                  viewCostEstimate(getCostEstimateLink(carePath), portalData?.portalName, true, isGuestUser, portalData?.portalConfig?.basePath, userTier, signInUrl, carePath.carePathIdentifier, portalData?.portalConfig?.legacySSOUrl);
                }}
                ref={(el) => (carePathsButtonRefs.current[carePath.carePathIdentifier as string] = el)}
                variant="outline"
              >
                {isGuestUser && portalData?.portalName === LAWW_PORTAL_NAME
                  ? t('PROVIDER_DETAILS.COST_TAB.CARE_PATH.SIGN_IN')
                  : t(
                      'PROVIDER_DETAILS.COST_TAB.CARE_PATH.GET_FULL_COST_ESTIMATE'
                    )}
              </Button>
            )}
        </CarePathResultContainer>
        {carePathsData?.carePathSteps && index <= carePathsData?.carePathSteps.length - 1 && (
          <Divider color="#E5E5E6" margin="$lg" orientation="horizontal" />
        )}
      </li>
    )
  );

  return (
    <React.Fragment>
      <CarePathResultsList data-testid="single-referral-banner">
        {listOfCarePaths}
      </CarePathResultsList>
    </React.Fragment>
  );
};
