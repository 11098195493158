import { styled } from '@abyss/web/tools/styled';
import { Accordion } from '@abyss/web/ui/Accordion';

export const FilterAccordianItem = styled(Accordion.Item, {
  '&.abyss-accordion-item': {
    backgroundColor: '$white',
    borderBottom: '1px solid $gray4',
    padding: '$lg',
  },
});

export const FilterAccordianContent = styled(Accordion.Content, {
  '&.abyss-accordion-content-container': {
    backgroundColor: '$white',
    marginTop: '$md',
  },
  '&.abyss-accordion-content-text': {
    padding: '0',
  },
});

export const FilterAccordianTrigger = styled(Accordion.Trigger, {
  '&.abyss-accordion-trigger': {
    fontSize: '$md',
    fontWeight: '$medium',
    lineHeight: '20px',
    color: '$gray7',
    height: '20px',
    minHeight: 'unset',
    padding: '0',
    border: '0px !important',
    backgroundColor: '$white',
    '&:hover': {
      backgroundColor: 'white',
    },
  },
});
