export const isSixtyDaysRemaining = (endDateStr: string) => {
  if (endDateStr === '') {
    return false;
  }

  const endDate = new Date(endDateStr);
  const today = new Date();
  const diffTime = endDate.getTime() - today.getTime();
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  return diffDays <= 60;
};

export const isEvergreenDate = (endDateStr: string) => {
  if (endDateStr === '') {
    return false;
  }

  const endDate = new Date(endDateStr);
  return endDate.getFullYear() === 9999;
};
