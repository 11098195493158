import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Popover } from '@abyss/web/ui/Popover';
import { Text } from '@abyss/web/ui/Text';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { phoneOnly } from '../ConstantsStyles';
import {
  distanceInfoIcon,
  distanceInfoIconText,
  distancePopover,
  drawerStyle,
} from './DistanceInfoIcon.styled';

export const DistanceInfoIcon = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const popOverContent = t('DISTANCE_POPOVER_CONTENT');
  const title = t('CONTENT_FILTERS.DISTANCE');
  const content = () => (
    <Flex
      css={{
        'abyss-flex-root': {
          padding: '16px 0px 0px 0px',
        },
      }}
      data-testid="distance-info-icon-popover"
      onClick={() => setIsOpen(true)}
    >
      <IconMaterial
        css={distanceInfoIcon}
        icon="info"
        size={24}
        variant="outlined"
      />
      <Text css={distanceInfoIconText}>
        {t('CONTENT_FILTERS.ALL_FILTERS_DRAWER.DISTANCE_INFO_ICON')}
      </Text>
    </Flex>
  );

  return (
    <React.Fragment>
      {!mobileScreen ? (
        <Popover
          content={popOverContent}
          css={distancePopover}
          data-testid="distance-info-icon-popover"
          onOpenChange={() => {
            setIsOpen(!isOpen);
          }}
          open={isOpen}
          title={title}
          width={220}
        >
          {content()}
        </Popover>
      ) : (
        <React.Fragment>
          {content()}
          <Drawer
            css={drawerStyle}
            data-auto-testid="distance-info-icon-popover"
            data-testid="distance-info-icon-popover"
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            position="bottom"
            title={title}
            titleAlign="left"
          >
            <Drawer.Section>{popOverContent}</Drawer.Section>
          </Drawer>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
