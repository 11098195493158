import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { RefObject, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  StyledPremiumCareProviderDiv,
  StyledPremiumCareProviderNullStateDiv,
  TextRoot,
} from '../../../../utility/compareDrawer.styled';
import {
  ATTRIBUTE_STATUS_TEXT,
  NULL_ATTRIBUTE_STATE,
} from '../../../../utility/compareDrawerConstants';

const isOverflow = (ref: RefObject<HTMLElement>): boolean => {
  const { current } = ref;
  return !!(current && current.scrollHeight > current.clientHeight);
};

type Props = {
  mobile: boolean;
  premiumCareProvider: string;
};

export const PremiumCareProviderText = ({
  mobile,
  premiumCareProvider,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  if (premiumCareProvider === 'YES') {
    return (
      <StyledPremiumCareProviderDiv>
        <Layout.Group
          alignItems="top"
          data-testid="premium-care-provider-badge"
          space={4}
        >
          <Layout.Group space={0}>
            <IconMaterial color="$primary1" icon="favorite" />
            <IconMaterial color="$primary1" icon="favorite" />
          </Layout.Group>
          {t(ATTRIBUTE_STATUS_TEXT.PREMIUM_CARE_PROVIDER.TRUE)}
        </Layout.Group>
      </StyledPremiumCareProviderDiv>
    );
  }

  if (premiumCareProvider === 'NO') {
    return (
      <StyledPremiumCareProviderDiv>
        <TextRoot>
          {t(ATTRIBUTE_STATUS_TEXT.PREMIUM_CARE_PROVIDER.FALSE)}
        </TextRoot>
      </StyledPremiumCareProviderDiv>
    );
  }

  return (
    <StyledPremiumCareProviderDiv>
      <Tooltip
        content={t(NULL_ATTRIBUTE_STATE.PREMIUM_CARE_PROVIDER)}
        position="top"
        positionOffset={8}
      >
        <StyledPremiumCareProviderNullStateDiv
          cssProps={{ isOverflow, mobile }}
          ref={containerRef}
        >
          {t(NULL_ATTRIBUTE_STATE.PREMIUM_CARE_PROVIDER)}
        </StyledPremiumCareProviderNullStateDiv>
      </Tooltip>
    </StyledPremiumCareProviderDiv>
  );
};
