import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import { useTranslation } from 'react-i18next';

import { LinkWithTracking } from '../../../../../common/LinkWithTracking/LinkWithTracking';
import { handleLinkAndModalTrack } from '../../../../../common/Utils/adobeTrackUtils';
import {
  ShareAllResultsLink,
  ShareButtonStyle,
  ShareButtonStyleNotSelected,
} from './ShareDrawer.styled';
import { adobeShareDrawerLinkLocation, adobeShareProviders } from './utils';

type ShareButtonContainerProps = {
  selected: number;
  openShareContents: boolean;
  setOpenShareContents: (a: boolean) => void;
  setIsShareAllResults: (a: boolean) => void;
  setOpenBoxContents: (a: boolean) => void;
};

export const ShareButtonContainer = ({
  selected,
  openShareContents,
  setOpenBoxContents,
  setOpenShareContents,
  setIsShareAllResults,
}: ShareButtonContainerProps) => {
  const { t } = useTranslation();
  const smallScreen = useMediaQuery(`(max-width: 600px)`);
  const shareAllResultsDataTestId: string = 'share-all-search-results-link';
  const locationForAnalytics: string = `modal:${adobeShareDrawerLinkLocation}`;

  return (
    <Flex
      css={
        smallScreen
          ? {
              'abyss-flex-root': {
                flexDirection: 'column',
                gap: '$md',
                alignItems: 'center',
              },
            }
          : {
              'abyss-flex-root': {
                marginTop: selected ? '10px' : '',
              },
            }
      }
    >
      <Button
        align="start"
        css={selected > 0 ? ShareButtonStyle : ShareButtonStyleNotSelected}
        data-auto-testid="share-drawer-content-button"
        data-testid="share-drawer-content-button"
        onClick={
          selected
            ? () => {
                handleLinkAndModalTrack(
                  adobeShareProviders,
                  locationForAnalytics,
                  adobeShareProviders
                );
                setIsShareAllResults(false);
                setOpenShareContents(!openShareContents);
                setOpenBoxContents(false);
              }
            : ''
        }
        variant={selected > 0 ? 'solid' : 'ghost'}
      >
        <Text
          css={{
            'abyss-text-root': {
              fontSize: '$md',
              paddingLeft: '5px',
              fontWeight: '$semibold',
              lineHeight: '18px',
              textAlign: 'center',
              color: selected > 0 ? '$primary2' : '$gray5',
            },
          }}
        >
          {t('Share')} ({selected})
        </Text>
      </Button>
      <LinkWithTracking
        analyticsInfo={{
          name: shareAllResultsDataTestId,
          location: locationForAnalytics,
        }}
        css={ShareAllResultsLink}
        data-auto-testid={shareAllResultsDataTestId}
        data-testid={shareAllResultsDataTestId}
        onClick={() => {
          setIsShareAllResults(true);
          setOpenShareContents(!openShareContents);
        }}
      >
        {t('SHARE_DRAWER_TEXT.SHARE_ALL_RESULTS')}
      </LinkWithTracking>
    </Flex>
  );
};
