export const itemToShow = (name, costOption, showTierOnePlan) =>
  name !== 'inNetwork' &&
  (name === 'cost' ? costOption : true) &&
  (name === 'tierOneProvider' ? showTierOnePlan : true);

export const getCheckboxLabel = (label) => {
  const checkboxLabel = label || '';
  return checkboxLabel;
};

export const isTieredOrVisionOrDental = (
  isTierOne: boolean,
  isDental: boolean,
  isVision: boolean
): boolean => isTierOne || isDental || isVision;

export const getInitialCheckedCount = (
  costOption,
  showTierOnePlan,
  items
): number =>
  items?.filter(
    (e) =>
      itemToShow(e.name, costOption, showTierOnePlan) && e.isChecked === true
  ).length;
