import { createScript } from '@abyss/web/tools/script';
import { logger } from '@abyss/web/tools/logger';
import { event } from '@abyss/web/tools/event';
import { config } from '@abyss/web/tools/config';

const queue = [];
let queueIntervalIsActive = false;

const triggerQueueInterval = () => {
  if (queue.length && !queueIntervalIsActive) {
    queueIntervalIsActive = true;
    const queueInterval = setInterval(() => {
      const tag = queue.shift();
      if (tag) {
        window.adobeDataLayer.push({
          event: tag.event,
          ...tag.data,
        });
      } else {
        clearInterval(queueInterval);
        queueIntervalIsActive = false;
      }
    }, 200);
  }
};

export const adobeAnalytics = (options) => {
  if (options.enabled !== false) {
    createScript('adobe-query-script', config('ADOBE_QUERY_SCRIPT'));

    event.waitFor(
      () => window._satellite,
      () => {
        window._satellite.pageBottom();
      }
    );
  }

  const pushToQueue = (tag) => {
    if (window.adobeDataLayer) {
      queue.push(tag);
      triggerQueueInterval();
    } else {
      window.pageDataLayer = tag.data;
      if (window._satellite) {
        window._satellite.track(tag.event);
      }
    }
  };

  return {
    id: 'adobe-analytics',
    events: options.events,
    metadata: options.metadata,
    trigger: (tag) => {
      if (tag.event === 'link track' && tag.data?.link?.type === 'radio') {
        // This is a workaround for an abyss bug where the onChange of RadioGroup
        // is fired twice everytime an option is selected.
        // Remove once abyss fixes it.
        // The debounce should be ok here - it is highly unlikely that user will be
        // selecting different radio options within 100 ms
        event.debounce(tag, 100, () => {
          if (options.logging) {
            logger.debug(`Adobe Analytics (debounced): ${tag.event}`, tag.data);
          }

          event.waitFor(
            () => window.adobe,
            () => {
              pushToQueue(tag);
            }
          );
        });
      }
      else {
        if (options.logging) {
          logger.debug(`Adobe Analytics: ${tag.event}`, tag.data);
        }

        pushToQueue(tag);
      }
    },
  };
};
