import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';

import { HiddenFormSubmission } from '../../../../common/ScheduleModal/ScheduleModal.style';
import { ScheduleModalHiddenForm } from '../../../../common/ScheduleModal/ScheduleModalHiddenForm';
import { useAnnexEncryptedPayload } from '../../../../hooks/useArcade';
import { useLagoon } from '../../../../hooks/useLagoon';
import { usePingFederateEncryptedPayload } from '../../../../hooks/usePingFederate';
import { getLagoonConfigValue } from '../../../../utils/providerSearch.utils';
import {
  getAvailabilityText,
  getDocAsapTargetUrl,
} from '../../../../utils/scheduling.utils';
import {
  getCurrentMember,
  getLoggedInMember,
} from '../../../../utils/user.utils';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../Constants';
import { getFeatureFlag } from '../../../Utils/getFeatureFlag';
import {
  AvailabilityContainer,
  AvailabilityText,
  InteractButton,
} from '../ProviderLocation.style';

type Props = {
  availabilityDates: string[];
  practiceId: string;
  npi: string;
};

export const OnlineScheduling = ({
  availabilityDates,
  practiceId,
  npi,
}: Props) => {
  const { t } = useTranslation();
  const loggedInMember = getLoggedInMember();
  const currentMember = getCurrentMember();
  const hiddenSubmit = createRef<HTMLButtonElement>();
  const [encryptedPayload, setEncryptedPayload] = useState<string>('');
  const dataTestId: string = 'schedule online now';
  const featureFlags = useLagoon('feature-flags')();
  const allConfigs = useLagoon('config');
  const availabilityText = getAvailabilityText(availabilityDates);
  const [memberId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MEMBER_ID,
    0
  );
  const [, getPayload] = useAnnexEncryptedPayload({
    onCompleted: (result) => {
      setEncryptedPayload(result?.data?.annexEncryptedPayload?.payload || '');
    },
  });
  const [, getPingFederatePayload] = usePingFederateEncryptedPayload({
    onCompleted: (result) => {
      setEncryptedPayload(result?.data?.pingFederate?.ref || '');
    },
  });
  const docAsapUrl = getLagoonConfigValue(allConfigs, 'DOCASAP_URL');
  const docAsapPartnerCode = getLagoonConfigValue(
    allConfigs,
    'DOCASAP_PARTNER_CODE'
  );
  const docAsapCredentialCode = getLagoonConfigValue(
    allConfigs,
    'DOCASAP_CREDENTIAL_CODE'
  );
  const docAsapChannelId = getLagoonConfigValue(
    allConfigs,
    'DOCASAP_CHANNEL_ID'
  );
  const partnerSpId = getLagoonConfigValue(allConfigs, 'PARTNER_SP_ID');
  const pfidAdapterId = getLagoonConfigValue(allConfigs, 'PFID_ADAPTER_ID');
  const callPingFederate = getFeatureFlag(featureFlags, 'PING_FEDERATE');
  const vendorId = getLagoonConfigValue(allConfigs, 'DOCASAP_VENDOR_ID');
  const ssoOutUrl = callPingFederate
    ? getLagoonConfigValue(allConfigs, 'PF_SSOOUT_URL')
    : getLagoonConfigValue(allConfigs, 'FPC_SSOOUT_URL');
  const ssoOutTargetUrl = getDocAsapTargetUrl(
    docAsapUrl,
    docAsapPartnerCode,
    docAsapCredentialCode,
    docAsapChannelId,
    npi,
    practiceId
  );
  const schedulingDataTestId = 'scheduling-online';
  const handleScheduleClick = () => {
    adobeLinkTrackEvent({
      name: Constants.DETAILS_CHIP_NAMES.Schedule,
      location: 'modal:schedule a visit:schedule',
      type: 'external',
      destinationUrl: ssoOutTargetUrl,
    });
    //Added feature flag check to call the correct hook
    callPingFederate
      ? getPingFederatePayload({
          variables: {
            pingFederatePayload: {
              subject: loggedInMember?.rallyId,
              firstName: currentMember?.demographics?.name.firstName,
              lastName: currentMember?.demographics?.name.lastName,
              dob: currentMember?.demographics?.dateOfBirth,
              groupNumber: currentMember?.policyNumber,
              memberId: memberId.toString(),
              gender: currentMember?.demographics?.gender,
              state: currentMember?.demographics?.address?.[0]?.state,
              zipCode: currentMember?.demographics?.address?.[0]?.zipCode,
              city: currentMember?.demographics?.address?.[0]?.city,
              address1: currentMember?.demographics?.address?.[0]?.address1,
            },
          },
        })
      : getPayload();
  };

  useEffect(() => {
    if (encryptedPayload) {
      hiddenSubmit.current?.click();
    }
  }, [encryptedPayload]);

  return (
    <>
      <Layout.Stack
        alignItems="left"
        data-auto-testid={schedulingDataTestId}
        data-testid={schedulingDataTestId}
      >
        <Heading
          css={{
            '@screen < $md': {
              '&.abyss-heading-root': {
                fontSize: '16px !important',
              },
            },
          }}
          data-auto-testid="online-scheduling-header"
          data-testid="online-scheduling-header"
          display="h6"
          offset={1}
        >
          {t('SCHEDULE_MODAL.ONLINE')}
        </Heading>
        <InteractButton
          after={
            <IconMaterial
              color="$primary2"
              data-testid="schedule-online-link"
              icon="open_in_new"
            />
          }
          aria-label={dataTestId.toLowerCase()}
          data-auto-testid="schedule-online-now-button"
          data-testid="schedule-online-now-button"
          name="schedule_online_now"
          onClick={() => handleScheduleClick()}
        >
          {t('SCHEDULE_MODAL.SCHEDULE_ONLINE_NOW')}
        </InteractButton>
        {availabilityText ? (
          <AvailabilityContainer>
            <IconMaterial color="#007000" icon="event" size="16px" />
            <AvailabilityText data-testid="availability-text-for-upcoming-days">
              {t(`PROVIDER_LOCATIONS_DETAILS.${availabilityText}`)}
            </AvailabilityText>
          </AvailabilityContainer>
        ) : null}
      </Layout.Stack>

      <HiddenFormSubmission
        action={ssoOutUrl}
        data-testid="docasap-form"
        method={callPingFederate ? 'get' : 'post'}
        target="_blank"
      >
        <ScheduleModalHiddenForm
          {...{
            callPingFederate,
            encryptedPayload,
            partnerSpId,
            pfidAdapterId,
            ssoOutTargetUrl,
            ssoOutUrl,
            vendorId,
          }}
        />
        <button ref={hiddenSubmit} type="submit" />
      </HiddenFormSubmission>
    </>
  );
};
