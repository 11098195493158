import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Card } from '@abyss/web/ui/Card';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';

import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { Survey } from '../../../../models/Healthgrades';
import { mobileOnly } from '../../../ConstantsStyles';
import { Rating } from '../../../SnackCard/Rating';
import { dateTimeFormatToDate } from '../../../Utils/datesUtils/formatDate';
import {
  DateTextStyles,
  verbatimCard,
  verbatimTextStyles,
} from './PatientReviews.styled';

type Props = {
  survey: Survey | null;
  setReviewModal: (a: Survey) => void;
};

export const PatientVerbatimCard = ({ survey, setReviewModal }: Props) => {
  const submittedDate = () => {
    if (survey && survey.submittedDate)
      return dateTimeFormatToDate(survey.submittedDate);
    return '';
  };
  if (!survey) return null;

  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const mobileScreen = useMediaQuery(mobileOnly) || isWidget;

  return (
    <Card
      css={verbatimCard(mobileScreen)}
      data-auto-testid={`review-card-${survey.id}`}
      data-testid={`review-card-${survey.id}`}
      key={survey.id}
      onClick={() => setReviewModal(survey)}
    >
      <Flex direction="column">
        <Flex alignItems="end" direction="row" justify="space-between">
          <Rating
            hideNumber
            outOf={5}
            rating={survey.averageRating}
            size={mobileScreen ? 16.67 : 20}
          />
          <Text css={DateTextStyles}>{submittedDate()}</Text>
        </Flex>

        <Flex
          alignContent="stretch"
          css={{
            'abyss-flex-root': {
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              '-webkit-line-clamp': mobileScreen ? '3' : '4',
              '-webkit-box-orient': 'vertical',
              overflow: 'hidden',
              marginTop: '12px',
              lineHeight: '16px',
            },
          }}
          direction="column"
        >
          <Text css={verbatimTextStyles(mobileScreen)}>
            "{survey.comment?.commentText}"
          </Text>
        </Flex>
      </Flex>
    </Card>
  );
};
