export const showUpcomingAvailabilityOption = (
  resultSet: any,
  isPreEffective: boolean
): boolean => {
  const providersWithDocASAPSchedule = resultSet?.filter(
    (provider) => provider.isDocasapScheduled === true
  );

  return providersWithDocASAPSchedule?.length > 0 && !isPreEffective;
};
