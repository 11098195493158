import { styled } from '@abyss/web/tools/styled';
import { Card } from '@abyss/web/ui/Card';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';

import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';
import { AdditionalResourcesType } from './additionalResourcesType';

const CardContainer = styled('div', {
  height: '100%',
  textAlign: 'left',
  '.abyss-card-root': {
    borderRadius: '12px',
    border: '1px solid #E5E5E6',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.12)',
    minHeight: '124px',
    '@screen < $lg': {
      minHeight: 'unset',
    },
    '@screen >= $lg': {
      maxWidth: '696px',
    },
    height: 'calc(100% - 32px)',
    '@screen < $md': {
      fontSize: '12.64px',
      marginTop: '5px',
      lineHeight: '16px',
      minHeight: '82px',
    },
  },
  '.abyss-card-section': {
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: '16px',
    gap: '16px',
    background: '#FFFFFF',
    borderRadius: '12px',
    '@screen < $sm': {
      padding: '6px 0 6px 16px',
    },
  },
  '.box-container': {
    padding: '0',
  },
});

const BoxContainer = styled('div', {
  height: '100%',
  '@screen < $md': {
    height: 'unset',
  },
  '.abyss-box-root': {
    height: '100%',
    backgroundColor: 'transparent',
    padding: '0',
    display: 'flex',
    justifyContent: 'center',
  },
});
const ContentContainer = styled('div', {
  display: 'flex',
  height: '100%',
  '@screen < $md': {
    width: 'unset',
    alignItems: 'flex-start',
  },
});
const TextContainer = styled('div', {
  '@screen < $md': {
    maxWidth: '380px',
  },
});

export const LinkContainer = styled('div', {
  display: 'flex',
  height: '30px',
  zIndex: '2',
  padding: '8px 0px 0px 0px',
});

type Props = {
  resource: AdditionalResourcesType;
};

export const AdditionalResourcesCard = ({ resource }: Props) => (
  <CardContainer>
    <BoxContainer>
      <Card
        css={{
          width: '406px',
          height: '100%',
          '@screen < $sm': {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
          },
        }}
      >
        <Card.Section
          css={{
            '&.abyss-card-section': {
              padding: '8px 16px 8px 16px',
              '@screen < $md': {
                padding: '8px 16px ',
              },
            },
          }}
        >
          <Flex
            css={{
              height: '100%',
            }}
            justify="space-between"
          >
            <Heading
              color="$primary1"
              css={{
                '&.abyss-heading-root': {
                  textAlign: 'left',
                  fontSize: '$mediumText',
                  lineHeight: '$lg',
                  '@screen < $md': {
                    fontSize: '16px',
                    lineHeight: '$md !important',
                  },

                  color: '#323333 !important',
                },
              }}
              data-auto-testid="additional-resource-card-heading"
              data-testid="additional-resource-card-heading"
              display="h6"
              offset={2}
            >
              {resource.title}
            </Heading>
            <ContentContainer>
              <TextContainer>
                <Text
                  color="$gray7"
                  css={{
                    'abyss-text-root': {
                      color: '#222',
                      '@screen < $md': {
                        fontSize: '14.22px',
                        lineHeight: '20px',
                      },
                    },
                  }}
                  data-auto-testid="additional-resource-card-description"
                  data-testid="additional-resource-card-description"
                  display="-webkit-box"
                >
                  {resource.description}
                </Text>
              </TextContainer>
            </ContentContainer>

            <LinkContainer>
              <LinkWithTracking
                after={
                  <IconMaterial
                    icon="open_in_new"
                    size="18px"
                    style={{ marginTop: '1px' }}
                  />
                }
                analyticsInfo={{
                  location: 'body:additional resources',
                  destinationUrl: resource.linkUrl,
                }}
                aria-label={`${resource.linkText} ${resource.description}`}
                css={{
                  '.abyss-insert-element-root': {
                    '@screen < $sm': {
                      alignItems: 'center',
                    },
                  },
                  '.abyss-insert-element-after': {
                    '@screen < $sm': {
                      alignItems: 'center',
                      marginLeft: '0px !important',
                    },
                  },
                }}
                data-auto-testid={`my-benefits-${resource.title}`}
                data-testid={`my-benefits-${resource.title
                  .replace(/ /g, '-')
                  .toLowerCase()}`}
                hideIcon
                href={resource.linkUrl}
                isStandardAnchor={false}
              >
                <Text
                  css={{
                    'abyss-text-root': {
                      color: '$info1',
                      '@screen >= $sm': {
                        fontWeight: '$bold',
                        fontSize: '16px',
                        display: 'flex',
                        height: '26px',
                        justifyContent: 'center',
                        width: 'auto',
                        alignItems: 'center',
                        gap: '4px',
                      },
                      '@screen < $sm': {
                        display: 'flex',
                        height: '18px',
                        fontSize: '12.64px',
                        fontWeight: '$bold',
                        width: 'auto',
                        alignItems: 'center',
                        gap: '4px',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      },
                    },
                  }}
                >
                  {resource.linkText}
                </Text>
              </LinkWithTracking>
            </LinkContainer>
          </Flex>
        </Card.Section>
      </Card>
    </BoxContainer>
  </CardContainer>
);
