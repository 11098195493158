import { Button } from '@abyss/web/ui/Button';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import { Text } from '@abyss/web/ui/Text';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { PSXIcon } from '../../../../../../common/PSXIcon';
import { StoreKeys } from '../../../../../../hooks/useStore/state';
import { useStore } from '../../../../../../hooks/useStore/useStore';
import { CompareProvider } from '../../../../../../models/Provider';
import { compareModalButtonStyle } from '../../CompareDrawer/utility/compareDrawer.styled';

type Props = {
  setOpenShare: (a: boolean) => void;
  setSelectedItems: (a: CompareProvider[]) => void;
  setSelectedCheckbox: (a: { checked: {} }) => void;
  setOpenBoxContents: (a: boolean) => void;
  setIsOpen: (a: boolean) => void;
  isOpen: boolean;
};

export const ConfirmationModalShare = ({
  setOpenShare,
  setSelectedCheckbox,
  setOpenBoxContents,
  setIsOpen,
  setSelectedItems,
  isOpen,
}: Props) => {
  const { t } = useTranslation();
  const locationForAnalytics: string = 'modal:close confirmation';

  // begin: a11y keyboard navigation: move focus to last  selected provider index
  const setUIstate = useStore(StoreKeys.SET_UI_STATE);
  const uiState = useStore(StoreKeys.UI_STATE);
  const { shareProvidersFlow } = useStore(StoreKeys.UI_STATE);
  const { indexOflastProviderSelected } = shareProvidersFlow;

  const handleFocusLastIndex = () => {
    setUIstate({
      ...uiState,
      shareProvidersFlow: {
        indexOflastProviderSelected,
        shouldFocusLastIndex: true,
      },
    });
  };
  // end

  return (
    <Modal
      closeOnClickOutside={false}
      css={{
        '.abyss-modal-header-container': { paddingTop: '0px' },
        '.abyss-modal-content-container': {
          width: '320px',
          height: '262px',
          borderRadius: '8px',
        },
        '.abyss-modal-content-title': {
          marginTop: '10px',
        },
        '.abyss-modal-close-button': {
          marginRight: '5px',
          marginTop: '5px',
          svg: {
            width: '18px',
            height: '18px',
            color: '#1F2937',
            fill: '#1F2937',
          },
        },
        '.abyss-modal-overlay': {
          background: 'rgba(0, 0, 0, 0.7)',
        },
      }}
      data-auto-testid="x-button-close-share-modal"
      data-testid="x-button-close-share-modal"
      isOpen={isOpen}
      onClose={() => {
        adobeLinkTrackEvent({
          name: 'close',
          location: locationForAnalytics,
          type: 'internal',
        });
        setIsOpen(false);
      }}
      size="320px"
      title={<PSXIcon icon="real_time_clock" size={84} />}
      titleAlign="center"
    >
      <Modal.Section
        css={{
          'abyss-modal-section-root': {
            textAlign: 'center',
            paddingBottom: '28px',
            paddingTop: 0,
          },
        }}
      >
        <Text fontWeight="bold" size="14px">
          {t('COMPARE_DRAWER_CONFIRMATION_MODAL.MODAL_SECTION_PART_ONE')}
        </Text>
        <br />
        <Text size="14px">
          {t('COMPARE_DRAWER_CONFIRMATION_MODAL.MODAL_SECTION_PART_TWO')}
        </Text>
      </Modal.Section>
      <Modal.Section>
        <Layout.Group alignLayout="center" space={16}>
          <Button
            css={compareModalButtonStyle}
            data-auto-testid="confirmation-button-to-close-share-drawer"
            data-testid="confirmation-button-to-close-share-drawer"
            onClick={() => {
              adobeLinkTrackEvent({
                name: 'done',
                location: locationForAnalytics,
                type: 'internal',
              });
              setOpenBoxContents(false);
              setOpenShare(false);
              setSelectedCheckbox({ checked: {} });
              setSelectedItems([]);
            }}
            size="30px"
          >
            {t('COMPARE_DRAWER_CONFIRMATION_MODAL.CLOSE_BUTTON')}
          </Button>
          <Button
            css={compareModalButtonStyle}
            data-auto-testid="confirmation-button-to-keep-working"
            data-testid="confirmation-button-to-keep-working"
            onClick={() => {
              handleFocusLastIndex();
              adobeLinkTrackEvent({
                name: 'keep working',
                location: locationForAnalytics,
                type: 'internal',
              });
              setIsOpen(false);
            }}
            size="30px"
            variant="outline"
          >
            {t('COMPARE_DRAWER_CONFIRMATION_MODAL.CONTINUE_WORKING_BUTTON')}
          </Button>
        </Layout.Group>
      </Modal.Section>
    </Modal>
  );
};
