import { useTranslation } from 'react-i18next';

export const parseSuggestionResults = (results: {
  features: [{ place_name: string }];
}) => {
  const { features } = results;
  const placeSuggestions = features.map((feature: { place_name: string }) => {
    const trimmedPlaceName = feature.place_name.slice(0, -15);
    return trimmedPlaceName;
  });
  return placeSuggestions || [];
};

export const refactorPlaceName = (placeName: string) =>
  placeName.split(',').slice(0, -1).join(',');

export const getLocationSearchSuggestions = (
  header: string,
  suggestions: any = []
) => {
  const { t } = useTranslation();
  let searchSuggestionHeaders: any = {};
  if (header === 'LOCATION_SEARCH_HEADER') {
    searchSuggestionHeaders = {
      section: t('LOCATION_SEARCH.HEADER'),
      items: [...suggestions],
    };
  } else if (header === 'LOCATION_SEARCH_ERROR') {
    searchSuggestionHeaders = {
      section: t('LOCATION_SEARCH.ERROR'),
      items: [...suggestions],
    };
  }
  return searchSuggestionHeaders;
};

export const abbreviateLocation = (searchLocation, stateCode, zipCode) => {
  if (!searchLocation) {
    return '';
  }
  const locationSplit = searchLocation.split(',');
  const locationArrayLength = locationSplit.length;
  const stateArray = ['', stateCode];

  if (zipCode !== '') {
    stateArray.push(zipCode);
  }

  if (locationSplit[locationArrayLength - 1].includes('United States')) {
    locationSplit[locationArrayLength - 1] = ' USA';
    locationSplit[locationArrayLength - 2] = stateArray.join(' ');
  } else {
    locationSplit[locationArrayLength - 1] = stateArray.join(' ');
  }

  const abbreviatedSearchLocation = locationSplit.join(',').trim();
  return abbreviatedSearchLocation;
};
