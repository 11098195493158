import { styled } from '@abyss/web/tools/styled';
import { SelectInput } from '@abyss/web/ui/SelectInput';
import { Text } from '@abyss/web/ui/Text';

export const searchInputStyle = {
  'abyss-text-input-root': {
    width: '460px',
    '@screen < $lg': {
      width: '430px',
    },
    '@screen < $md': {
      width: '100%',
    },
  },
  'abyss-text-input': {
    width: '100%',
    background: '#F2F2F2',
    border: '1px solid $neutralGray2',
    borderRadius: '100px',
    fontStyle: 'italic',
    '&::-webkit-input-placeholder': { color: '#4B4D4F' },
    paddingLeft: '35px',
  },
  'abyss-text-input-label': {
    width: 'auto',
    fontWeight: '$medium',
    fontSize: '18px',
    lineHeight: '24px',
    color: ' $neutralGray7',
    marginBottom: '$sm',
    '@screen < $lg': {
      fontSize: '$md',
      lineHeight: '20px',
    },
  },
};

export const providerSearchContainer = {
  '@screen >= $md': {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  '@screen < $md': {
    flexDirection: 'column',
  },
};

export const selectInputContainerStyle = {
  width: '250px',
  flexDirection: 'row',
  marginRight: '32px',
  '@screen < $md': {
    width: '100%',
    marginRight: '0',
    marginBottom: '8px',
  },
};

export const SelectInputButton = styled(SelectInput, {
  '&.abyss-select-input-label': {
    fontSize: '$md',
    '@screen >= $md': {
      fontSize: '$lg',
    },
  },
  '&.abyss-select-input-input': {
    fontWeight: '$medium',
    fontSize: '$md !important',
  },
});

export const ErrorTextContainer = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: '500',
    '@screen < $sm': {
      fontSize: '14.22px',
      lineHeight: '20px',
    },
  },
});
