export const MockFalseProvider = {
  phones: {
    phone: ['12345678900'],
    emergency: [''],
    appointment: [''],
    fax: [''],
  },
  distance: '5',
  facilityType: '',
  gender: 'Male',
  latitude: '',
  locationId: '',
  longitude: '',
  networkStatus: '',
  practitionerRoleId: '',
  professionalDesignation: '',
  providerId: '',
  providerName: 'Mock False Provider',
  specialities: [],
  primaryDegrees: [],
  providerType: '',
  acceptingNewPatients: false,
  efficiencyCode: '',
  languagesSpoken: ['This_Is_The_Maximum_Length_A Language Can Be, French'],
  hoursOfOperation: {},
  qualityCode: '',
  virtualCareOffered: false,
  speciality: '',
  healthGradeRating: '1.1',
  premiumCareProvider: 'NO',
  hasPCPServiceAvailability: false,
  isTieredProvider: false,
  educationInfo: [
    {
      schoolName: '',
      degrees: [
        {
          degreeCode: '',
          isPrimary: '',
        },
      ],
    },
  ],
  providerEthnicityExpress: 'Caucasion',
  accessProvider: false,
  hasEveningAppointments: false,
  hasWeekendAppointment: false,
  practiceLimitations: {
    endAge: '125',
    startAge: '0',
  },

  servicesOffered: ['? Can there be no services offered ?'],
  costIndicator: '$',
};
