import { styled } from '@abyss/web/tools/styled';
import { DropdownMenu } from '@abyss/web/ui/DropdownMenu';
import { Flex } from '@abyss/web/ui/Flex';

export const DropdownMenuButton = styled(DropdownMenu, {
  '&.abyss-dropdown-menu-trigger': {
    color: '$descriptionBlack',
    fontWeight: '$semibold',
    borderRadius: '24px',
    height: '1.525em',
    fontSize: '$md',
    padding: '$sm $md $sm $lg',
    border: '1px solid $gray4',
    '@screen < $sm': {
      color: '$primary1',
      border: '1px solid $primary1',
    },
  },
  '&.abyss-dropdown-menu-radio-group-label': {
    padding: '$lg $lg $sm $lg',
  },
  '&.abyss-dropdown-menu-content': {
    height: 300,
    width: 300,
    borderRadius: 12,
    boxShadow: '0px 4px 16px 0px $descriptionBlack',
  },
  '&.abyss-dropdown-menu-radio': {
    '&[data-state="checked"]': {
      color: '$neutralGray7',
      fontWeight: '$bold',
      paddingLeft: 26,
    },
    fontSize: '$lg',
    paddingLeft: 26,
    paddingTop: 24,
  },
});

export const ChipMenuIcon = styled('div', {
  display: 'inline-flex',
  '@screen < $md': {
    marginRight: '8px',
  },
  '@screen >= $md': {
    marginRight: '12px',
  },
});

export const ChipFiltersContainer = styled('div', {
  variants: {
    showAll: {
      true: {
        '&.hide-scrollbar': {
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
        },
      },
      false: {
        '&.hide-scrollbar': {
          display: 'inline-flex',
          overflow: 'auto',
          scrollBehavior: 'smooth',
        },
      },
    },
  },
  '@screen < $md': {
    gap: '8px',
  },
  '@screen >= $md': {
    gap: '12px',
  },
  scrollbarColor: 'transparent transparent' /* Hide scrollbar for Firefox */,
  /**
   * This CSS class override div styled + add pseudo class to hide the horizontal scrollbar,
   * but keep its functionality.
   * Only for this div container.
   * */
  '&.hide-scrollbar::-webkit-scrollbar': {
    display: 'none' /* Hide scrollbar for Chrome, Safari and Opera */,
  },
  button: {
    overflow: 'initial',
  },
});

export const Wrapper = styled(Flex, {
  display: 'flex',
  flexWrap: 'nowrap',
  alignItems: 'flex-start',
  '&.abyss-flex-content-root	': {
    padding: '0 !important',
    marginBottom: '0 !important',
  },
});
