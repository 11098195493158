import { useToast } from '@abyss/web/hooks/useToast';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';

import { toastSuccessStyle } from './Toast.style';

export const successToast = (message: string) => {
  const { toast } = useToast();

  toast.show({
    message: (
      <Text
        color="$white"
        data-auto-testid="success-message"
        data-testid="success-message"
      >
        {message}
      </Text>
    ),
    variant: 'success',
    icon: <IconMaterial color="$white !important" icon="check_circle" />,
    css: toastSuccessStyle,
  });
};
