import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useTranslation } from 'react-i18next';

import { StoreKeys } from '../../../hooks/useStore/state';
import { useStore } from '../../../hooks/useStore/useStore';
import { phoneOnly } from '../../ConstantsStyles';
import { NavigationButton } from './NavigationButton';
import {
  DesktopVerticalDivider,
  MobileVerticalDivider,
  NavCloseButton,
  NavCloseButtonContainer,
  NavigationButtonContainer,
  NavigationButtonGroup,
} from './NavigationButton.styled';

type Props = {
  handleCloseNav(): void;
  navType: string;
  setNavType(navType: string): void;
  locationForAnalytics?: string;
};

export const NavigationButtonsRow = ({
  handleCloseNav,
  navType,
  setNavType,
  locationForAnalytics,
}: Props) => {
  const { t } = useTranslation();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = useMediaQuery(phoneOnly) && isWidget;
  return (
    <NavigationButtonContainer
      alignLayout="center"
      css={{
        display: isWidget ? 'block' : '',
      }}
      cssProps={{ phoneScreen }}
      space="0"
    >
      <NavigationButtonGroup cssProps={{ isWidget }}>
        <NavigationButton
          buttonNavType="driving-traffic"
          curNavType={navType}
          label={t('LOCATION_DIALOG_BOX.DISTANCE_TRAFFIC')}
          locationForAnalytics={locationForAnalytics}
          setNavType={setNavType}
        />
        {!isWidget ? <MobileVerticalDivider /> : null}
        <NavigationButton
          buttonNavType="driving"
          curNavType={navType}
          label={t('LOCATION_DIALOG_BOX.DRIVING_DISTANCE')}
          locationForAnalytics={locationForAnalytics}
          setNavType={setNavType}
        />
        {!isWidget ? <MobileVerticalDivider /> : null}
        <NavigationButton
          buttonNavType="walking"
          curNavType={navType}
          label={t('LOCATION_DIALOG_BOX.WALKING_DISTANCE')}
          locationForAnalytics={locationForAnalytics}
          setNavType={setNavType}
        />
        {!isWidget ? <MobileVerticalDivider /> : null}
        <NavigationButton
          buttonNavType="cycling"
          curNavType={navType}
          label={t('LOCATION_DIALOG_BOX.CYCLING_DISTANCE')}
          locationForAnalytics={locationForAnalytics}
          setNavType={setNavType}
        />
      </NavigationButtonGroup>
      {!isWidget ? (
        <NavCloseButtonContainer>
          <DesktopVerticalDivider />
          <NavCloseButton
            aria-label={t('LOCATION_DIALOG_BOX.CLOSE_DIALOG')}
            data-auto-testid="navigation-close"
            data-testid="navigation-close"
            onClick={handleCloseNav}
          >
            <IconMaterial
              color="$black"
              css={{ margin: 'auto' }}
              icon="close"
            />
          </NavCloseButton>
        </NavCloseButtonContainer>
      ) : null}
    </NavigationButtonContainer>
  );
};
