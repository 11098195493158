import { Layout } from '@abyss/web/ui/Layout';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { t } from 'i18next';
import React, { useRef } from 'react';

import { useIsOverflow } from '../../../../../../../../../../hooks/useIsOverflow';
import { EducationInformation } from '../../../../../../../../../../models/Provider';
import {
  StyledEducationDegreeDiv,
  StyledEducationUniversityDiv,
} from '../../../../../utility/compareDrawer.styled';
import { NULL_ATTRIBUTE_STATE } from '../../../../../utility/compareDrawerConstants';
import { getEducation } from '../../../../../utility/getEducationText';

type Props = {
  educationInfo: EducationInformation;
};

export const EducationAttributeDesktop = ({ educationInfo }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);

  const education = getEducation(educationInfo);
  const isUniversityEmpty =
    education.schoolName === NULL_ATTRIBUTE_STATE.EDUCATION_SCHOOLS;
  const schoolNameToDisplay = isUniversityEmpty
    ? t(education.schoolName)
    : education.schoolName;
  const isDegreeEmpty =
    education.degreeCode === NULL_ATTRIBUTE_STATE.EDUCATION_DEGREES;

  const UniversityAttribute = (
    <StyledEducationUniversityDiv
      cssProps={{ isOverflow, isUniversityEmpty }}
      ref={containerRef}
    >
      {schoolNameToDisplay}
    </StyledEducationUniversityDiv>
  );

  return (
    <Layout.Stack alignItems="left" space={0}>
      {/* UNIVERSITY */}
      {isOverflow ? (
        <Tooltip
          align="center"
          content={schoolNameToDisplay}
          position="top"
          positionOffset={8}
        >
          {UniversityAttribute}
        </Tooltip>
      ) : (
        UniversityAttribute
      )}

      {/* DEGREE */}
      <StyledEducationDegreeDiv cssProps={{ isOverflow, isDegreeEmpty }}>
        {isDegreeEmpty ? t(education.degreeCode) : education.degreeCode}
      </StyledEducationDegreeDiv>
    </Layout.Stack>
  );
};
