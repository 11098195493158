/* eslint-disable no-param-reassign */
export type MergeType = Record<string | number, any>;

export const merge = (
  current: MergeType = {},
  override: MergeType = {}
): MergeType => {
  if (current === null || typeof current !== 'object') {
    return override;
  }

  if (override === null || typeof override !== 'object') {
    return current;
  }

  const overrideKeys = Object.keys(override);
  if (overrideKeys.length === 0) {
    return current;
  }

  return overrideKeys.reduce(
    (merged: MergeType, key: string) => {
      if (
        override[key] !== null &&
        typeof override[key] === 'object' &&
        merged[key] !== null &&
        typeof merged[key] === 'object'
      ) {
        merged[key] = {
          ...(merged[key] as object),
          ...(override[key] as object),
        };
      } else {
        merged[key] = override[key];
      }
      return merged;
    },
    { ...current }
  );
};
