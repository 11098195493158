import { config } from '@abyss/web/tools/config';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import get from 'lodash/get';

import { useCustomQuery } from '../useCustomQuery';
import GET_MAP_KEY from './MapKey.graphql';

const decodeMapKey = (result) => {
  const mapKey = get(result, 'mapKey', '');

  const parsedKey = tokenizer.parse(mapKey);

  return parsedKey;
};

export const useMapKey = (options) => {
  const [queryResult, queryAction] = useCustomQuery(GET_MAP_KEY, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'mapKey',
    onCompleted: (result) => {
      if (options?.onCompleted) {
        options.onCompleted(decodeMapKey(result?.data));
      }
    },
  });

  const parsedResult = {
    ...queryResult,
    data: decodeMapKey(queryResult?.data),
  };

  return [parsedResult, queryAction];
};
