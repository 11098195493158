import { Box } from '@abyss/web/ui/Box';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';
import { find } from 'lodash';
import React from 'react';

import { ConstantsLagoon } from '../../../common/ConstantsLagoon';
import { useLagoon } from '../../../hooks/useLagoon';
import {
  headerStyleSubTitle,
  textStyle,
} from './DetailsWidgetNoDetails.styled';

export const DetailsWidgetNoDetails = () => {
  const data = useLagoon('ui-messaging')();
  const widgetTitle = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.DETAILS_WIDGET_NO_DETAILS_TITLE,
  });
  const widgetSubTitle = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.DETAILS_WIDGET_NO_DETAILS_SUB_TITLE,
  });
  const widgetInfo = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.DETAILS_WIDGET_NO_DETAILS_INFO,
  });

  return (
    <React.Fragment>
      <Box color="transparent">
        <Heading display="h4" offset={1}>
          <span
            data-auto-testid="widget-no-provider-details-title"
            data-testid="widget-no-provider-details-title"
          >
            {widgetTitle?.message ?? ''}
          </span>
        </Heading>

        <Heading css={headerStyleSubTitle} display="h5" offset={1}>
          <span
            data-auto-testid="widget-no-provider-details-sub-title"
            data-testid="widget-no-provider-details-sub-title"
          >
            {widgetSubTitle?.message ?? ''}
          </span>
        </Heading>

        <Text css={textStyle}>
          <span
            data-auto-testid="widget-no-provider-details-info"
            data-testid="widget-no-provider-details-info"
          >
            {widgetInfo?.message ?? ''}
          </span>
        </Text>
      </Box>
    </React.Fragment>
  );
};
