import { getModuleOrDefault } from '@optum-fpc-psx-mobile-apps/mapping-files-translation';

import { ReverseCoverageTypesCodes } from '../common/Constants';
import { ConstantsLagoon } from '../common/ConstantsLagoon';
import { getFeatureFlag } from '../common/Utils';
import { ProviderType } from '../models/ProviderDetails';

const virtualCareEnglishTranslations = getModuleOrDefault(
  'en',
  'virtualCareTranslations'
);

const virtualCareSpanishTranslations = getModuleOrDefault(
  'es',
  'virtualCareTranslations'
);

export const showVirtualIndicatorForCards = (
  providerType: ProviderType,
  location: { virtualCareOffered: string[] },
  featureFlags: { key: string; active: boolean }[]
): boolean => {
  const virtualCareIndicatorEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.VIRTUAL_CARE_INDICATOR_ENABLED
  );

  if (
    !virtualCareIndicatorEnabled ||
    providerType !== ProviderType.PRACTITIONER
  ) {
    return false;
  }

  return isVirtualCareAvailableInLocation(location);
};

export const isVirtualCareAvailableInLocation = (location: {
  virtualCareOffered: string[];
}) => {
  // If virtual care property does not exist at location level.
  const virtualCareOffered = location?.virtualCareOffered || [];

  if (
    virtualCareOffered.length == 0 ||
    virtualCareOffered[0] === 'N' ||
    virtualCareOffered[0] === virtualCareEnglishTranslations['N'] ||
    virtualCareOffered[0] === virtualCareSpanishTranslations['N']
  ) {
    return false;
  }

  return true;
};

export const isVirtualCareIconFeatureEnabledOnDetailsHeader = (
  featureFlags: { key: string; active: boolean }[],
  coverageType: string | undefined
) => {
  const virtualCareIndicatorEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.VIRTUAL_CARE_INDICATOR_ENABLED
  );

  return (
    virtualCareIndicatorEnabled &&
    (coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] ||
      coverageType === ReverseCoverageTypesCodes['BEHAVIORAL CARVE-OUT'] ||
      coverageType === ReverseCoverageTypesCodes.MEDICAL)
  );
};
