import { styled } from '@abyss/web/tools/styled';
import { Icon } from '@abyss/web/ui/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';

type AlertProps = {
  county: string;
  isOutOfCounty: boolean;
};
export const CountyText = styled('span', {
  color: '#C24E14',
  fontSize: '13px',
  lineHeight: '16px',
  padding: '10px 0px',
});
export const IconContainer = styled(Icon, {
  paddingTop: '10px',
  'abyss-icon': { width: '16px', height: '16px' },
});

export const CountyAlertContainer = styled('div', {
  height: '24px !important',
  display: 'inline-flex',
  marginTop: '-7px',
});

export const OutsideCountyAlert = ({ county, isOutOfCounty }: AlertProps) => {
  const { t } = useTranslation();

  return (
    <CountyAlertContainer>
      {isOutOfCounty ? (
        <React.Fragment>
          <IconContainer
            color="#C24E14"
            data-auto-testid="out-of-county-icon"
            data-testid="out-of-county-icon"
            title="out-of-county"
          >
            <svg xmlns="http://www.w3.org/2000/svg">
              <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
            </svg>
          </IconContainer>
          <CountyText
            aria-label={`Outside of ${county}`}
            data-testid="out-of-county-text"
          >
            {`${t('Outside of')} `}
            {county}
          </CountyText>{' '}
        </React.Fragment>
      ) : (
        <React.Fragment />
      )}
    </CountyAlertContainer>
  );
};
