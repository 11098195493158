import { Constants, CoverageTypesCodes } from '../common/Constants';
import {
  Eligibility,
  MemberInfo,
} from '../common/PSXHeader/ObapiDemo/memberProfile';
import { dateFormatUTC } from '../common/Utils/datesUtils/formatDate';

export const getInitials = (name: string): string => {
  if (!name) {
    return '';
  }
  const [firstName, lastName] = name.split(' ');

  if (firstName && lastName) {
    return `${firstName.charAt(0)}${lastName.charAt(0)}`.toUpperCase();
  }
  return firstName.charAt(0).toUpperCase();
};

export const getFirstName = (name: string): string => {
  if (!name) {
    return '';
  }
  const [firstName, lastName] = name.split(' ');

  if (firstName && lastName) {
    return firstName;
  }
  return firstName;
};

export const getFullName = (member: MemberInfo) => {
  const firstName = member?.demographics?.name?.firstName;
  const lastName = member?.demographics?.name?.lastName;
  return `${firstName} ${lastName}`;
};

export const getChipsByCoverages = (
  MOCK_CARE_CATEGORIES: {
    label: string;
    value: string;
    id: string;
    coverageType: string;
  }[],
  eligibility: Eligibility[] = []
): { label: string; value: string; id: string; coverageType: string }[] => {
  const coverageTypeCodes: boolean[] = eligibility
    .flatMap((coverage) => coverage?.memberHealthCoverage?.coverageType)
    .map(
      (coverageType) =>
        (coverageType?.typeCode?.desc ===
          CoverageTypesCodes.BEHAVIORAL_HEALTH &&
          coverageType?.typeCode?.coverageTypeCode === 'B') ||
        (coverageType?.typeCode?.desc ===
          CoverageTypesCodes.BEHAVIORAL_CARVE_OUT &&
          coverageType?.typeCode?.coverageTypeCode === 'OBH')
    );

  if (coverageTypeCodes.includes(true))
    Constants.COVERAGE_TYPECODE_TO_CHIP.B =
      CoverageTypesCodes.BEHAVIORAL_HEALTH;
  else Constants.COVERAGE_TYPECODE_TO_CHIP.B = '';

  const memberCoverage: string[] = eligibility
    .flatMap((coverage) => coverage?.memberHealthCoverage?.coverageType)
    .map(
      (coverageType) =>
        Constants.COVERAGE_TYPECODE_TO_CHIP[
          coverageType?.typeCode?.coverageTypeCode || ''
        ]
    );
  const categories = MOCK_CARE_CATEGORIES.filter(
    (chip) =>
      memberCoverage.includes(chip.coverageType) || chip?.coverageType === 'All'
  );

  return categories;
};

export const getMemberLocation = (member: MemberInfo): string => {
  if (
    !member?.demographics?.address ||
    member.demographics?.address?.length === 0
  ) {
    return '';
  }
  const { city, state, zipCode } = member.demographics?.address[0];
  const formattedAddress = `${city}, ${state} ${zipCode}`;
  return formattedAddress;
};

export const menuItems = (memberProfiles: MemberInfo[]) => [
  {
    label: 'Subscribers:',
    model: 'active-member',
    radios: memberProfiles.map((person, index) => {
      const name = getFullName(person);

      const dob = person.demographics?.dateOfBirth || '';
      const formattedDate = dateFormatUTC(dob);
      const relationshipTypecode =
        person.demographics?.relationshipTypeCode?.code || '';

      return {
        label: name,
        value: index.toString(),
        dob: formattedDate,
        eligibility: person.eligibility,
        relationshipTypecode,
      };
    }),
  },
];
