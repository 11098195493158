import { Card } from '@abyss/web/ui/Card';
import React from 'react';

import {
  DisclaimerContainer,
  StyledVisionDisclaimer,
} from './VisionDisclaimer.styled';

type Props = {
  visionDirectoryDisclaimerMessage: string;
  visionDirectoryDisclaimerAddlContentMessage: string;
  page?: Boolean;
};
export const VisionDirectoryDataDisclaimer = ({
  visionDirectoryDisclaimerMessage,
  visionDirectoryDisclaimerAddlContentMessage,
  page = true,
}: Props) => (
  <StyledVisionDisclaimer data-testid="vision-disclaimer">
    <DisclaimerContainer page={page}>
      <Card>
        <Card.Section>{visionDirectoryDisclaimerMessage ?? ''}</Card.Section>
      </Card>
      <Card>
        <Card.Section>
          {visionDirectoryDisclaimerAddlContentMessage ?? ''}
        </Card.Section>
      </Card>
    </DisclaimerContainer>
  </StyledVisionDisclaimer>
);
