import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';
import { useTranslation } from 'react-i18next';

import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { HealthGradesBulkInfo } from '../../../../models/Healthgrades';
import { mobileOnly } from '../../../ConstantsStyles';
import { Rating } from '../../../SnackCard/Rating';
import {
  OverallRatingContainer,
  OverallRatingNumberStyles,
  RatingOverviewSection,
  RatingSectionHeader,
  numberOfReviewsTextStyles,
} from './PatientReviews.styled';

type Props = {
  healthgradesReview: HealthGradesBulkInfo;
};

export const PatientReviewsOverviewSection = ({
  healthgradesReview,
}: Props) => {
  if (!healthgradesReview) return null;
  const showSubmit = false;
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const mobileScreen = useMediaQuery(mobileOnly) || isWidget;
  const headerSize = mobileScreen ? '$xs' : '$sm';
  const { aggregatedSurvey } = healthgradesReview;
  const { t } = useTranslation();
  const averageRating = aggregatedSurvey?.averageRating?.toFixed(1);
  return (
    <RatingOverviewSection alignItems="flex-start" justify="flex-start">
      <Grid
        css={{
          flex: averageRating ? 'unset' : 1,
        }}
      >
        <Grid.Col
          span={
            isWidget
              ? '50%'
              : { xs: '50%', sm: '33%', md: '33%', lg: '33%', xl: '33%' }
          }
        >
          <Flex
            direction="column"
            style={{
              minWidth: '125px',
            }}
          >
            <RatingSectionHeader size={headerSize}>
              {t(
                'PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.OVERALL_EXPERIENCE'
              )}
            </RatingSectionHeader>
            {averageRating ? (
              <Flex alignItems="baseline" css={OverallRatingContainer}>
                <Heading
                  css={OverallRatingNumberStyles}
                  data-auto-testid="overall-rating"
                  data-testid="overall-rating"
                >
                  {averageRating}
                </Heading>
                <Text
                  css={numberOfReviewsTextStyles(mobileScreen)}
                  data-auto-testid="review-count"
                  data-testid="review-count"
                >
                  (
                  {aggregatedSurvey?.commentCount
                    ? aggregatedSurvey?.commentCount
                    : 0}{' '}
                  {t('PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.REVIEWS')})
                </Text>
              </Flex>
            ) : null}
            <Rating
              hideNumber
              outOf={5}
              rating={Number(averageRating)}
              size={24}
            />
          </Flex>
        </Grid.Col>
        {showSubmit ? (
          <Button
            color="$primary1"
            data-auto-testid="submit-a-review-button"
            data-testid="submit-a-review-button"
            size="$sm"
            variant="outline"
          >
            <Text color="$primary1" fontWeight="$semibold">
              {t('PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.SUBMIT_A_REVIEW')}
            </Text>
          </Button>
        ) : null}
      </Grid>
    </RatingOverviewSection>
  );
};
