export interface PCPSearchState {
  choosePCP?: boolean;
  choosePCPId?: string;
  dependentSeqNbr?: string;
  pcpIndicator?: boolean;
  selectedProviderType?: string;
  formattedProviderName?: string;
  providerType?: string;
  selectedLocation?: string | null;
  primarySpeciality?: string;
  imageLocation?: string;
  pcpEffectiveDate?: string;
}

export interface PCPSearchStore {
  pcpSearchState: PCPSearchState;
  setPCPSearchState: (values: PCPSearchState) => void;
  clearPCPSearchState: (defaultValues?: PCPSearchState) => void;
}

export enum StoreKeys {
  PCP_SEARCH_STATE = 'pcpSearchState',
  SET_PCP_SEARCH_STATE = 'setPCPSearchState',
  CLEAR_PCP_SEARCH_STATE = 'clearPCPSearchState',
}
