import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Modal } from '@abyss/web/ui/Modal';
import { Text } from '@abyss/web/ui/Text';

import { mobileOnly } from '../../../ConstantsStyles';

export const StyledButton = styled(Button, {
  '&.abyss-button-root': {
    display: 'flex',
    height: '48px',
    padding: '10px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    flex: '1 0 0',
    borderRadius: '24px',
    alignSelf: 'stretch',
    [`@media ${mobileOnly}`]: {
      padding: '20px 24px',
    },
  },
});

export const StyledModalFooter = styled(Modal.Section, {
  '&.abyss-modal-section-root': {
    display: 'flex',
    padding: '12px 16px 16px 16px',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '8px',
    alignSelf: 'stretch',
    [`@media ${mobileOnly}`]: {
      flexDirection: 'column',
      padding: '16px',
    },
  },
});

export const StyledSupportingText = styled(Modal.Section, {
  '&.abyss-modal-section-root': {
    display: 'flex',
    padding: '16px 24px',
    flexDirection: 'column',
    gap: '12px',
    [`@media ${mobileOnly}`]: {
      height: '350px',
      paddingTop: '0px',
    },
  },
});

export const StyledIcon = styled('div', {
  textAlign: 'center',
  paddingTop: '40px',
  [`@media ${mobileOnly}`]: {
    paddingTop: 'unset',
    height: '45px',
  },
});

export const StyledModalBody = styled('div', {
  display: 'flex',
  paddingBottom: '0px',
  flexDirection: 'column',
  gap: '12px',
});

export const StyledSectionContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
  alignSelf: 'stretch',
});

export const StyledText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '16px',
    [`@media ${mobileOnly}`]: {
      fontSize: '14px',
    },
  },
});

export const GoodToKnowText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '20.25px',
    [`@media ${mobileOnly}`]: {
      fontSize: '14px',
    },
  },
});
