import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsOverflow } from '../../../../../../../../../hooks/useIsOverflow';
import { getStatusText } from '../../../../../ShareDrawer/utils';
import {
  StyledAvoidWrappingDiv,
  popoverStyle,
} from '../../../../utility/compareDrawer.styled';
import {
  ATTRIBUTE_STATUS_TEXT,
  NULL_ATTRIBUTE_STATE,
} from '../../../../utility/compareDrawerConstants';

type Props = {
  acceptingNewPatients: boolean;
  mobile: boolean;
};

export const AcceptingNewPatientsAttribute = ({
  acceptingNewPatients,
  mobile,
}: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);

  const acceptingNewPatientsText = t(
    getStatusText(
      acceptingNewPatients,
      ATTRIBUTE_STATUS_TEXT.ACCEPTING_NEW_PATIENTS.TRUE,
      ATTRIBUTE_STATUS_TEXT.ACCEPTING_NEW_PATIENTS.FALSE,
      NULL_ATTRIBUTE_STATE.ACCEPTING_NEW_PATIENTS
    )
  );

  const isAttributeEmpty =
    acceptingNewPatientsText === NULL_ATTRIBUTE_STATE.ACCEPTING_NEW_PATIENTS;

  let icon = 'do_not_disturb';
  if (acceptingNewPatients === true) icon = 'check';
  if (acceptingNewPatients === false) icon = 'close';

  const AcceptingNewPatients = (
    <StyledAvoidWrappingDiv
      cssProps={{ isOverflow, isAttributeEmpty }}
      data-auto-testid="accepting-new-patients-text"
      data-testid="accepting-new-patients-text"
      ref={containerRef}
    >
      <Layout.Group space={4}>
        <IconMaterial color="$primary1" icon={icon} size={20} />
        {acceptingNewPatientsText}
      </Layout.Group>
    </StyledAvoidWrappingDiv>
  );

  if (mobile) {
    return (
      <React.Fragment>
        {isOverflow ? (
          <Popover
            content={acceptingNewPatientsText}
            css={popoverStyle}
            showClose={false}
            tabIndex="-1"
            width={150}
          >
            {AcceptingNewPatients}
          </Popover>
        ) : (
          AcceptingNewPatients
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {isOverflow ? (
        <Tooltip
          content={acceptingNewPatientsText}
          position="top"
          positionOffset={8}
        >
          {AcceptingNewPatients}
        </Tooltip>
      ) : (
        AcceptingNewPatients
      )}
    </React.Fragment>
  );
};
