import { IconMaterial } from '@abyss/web/ui/IconMaterial';

import {
  SidePaneCloseButtonStyle,
  SidePaneCloseButtonWrap,
} from './MapView.styled';

type Props = {
  sidePanelArrowIcon: string;
  toggleSidePanel: any;
};
export const SidePaneCloseButton = ({
  sidePanelArrowIcon,
  toggleSidePanel,
}: Props) => (
  <SidePaneCloseButtonWrap>
    <SidePaneCloseButtonStyle
      alignItems="center"
      className="side-btn"
      justify="center"
    >
      <IconMaterial
        color="#323334"
        css={{
          padding: '3px 0px 0px 1px',
        }}
        data-auto-testid="sidepanel-close-icon"
        data-testid="sidepanel-close-icon"
        icon={sidePanelArrowIcon}
        onClick={toggleSidePanel}
        size="$md"
      />
    </SidePaneCloseButtonStyle>
  </SidePaneCloseButtonWrap>
);
