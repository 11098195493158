import { styled } from '@abyss/web/tools/styled';
import { Pagination } from '@abyss/web/ui/Pagination';
import { Text } from '@abyss/web/ui/Text';

import { PSXIcon } from '../../../../PSXIcon';

export const Container = styled('div', {
  border: '1px solid #E5E5E6',
  borderLeft: 'none',
  borderRight: 'none',
  marginBottom: '0px',
  boxSizing: 'border-box',
});

export const StyledIcon = styled(PSXIcon, {
  height: '28px !important',
});

export const RatingSectionContainerStyles = {
  width: '100%',
};

export const sortButtonMobileStyles = {
  'abyss-button-root': {
    borderWidth: '2px',
    padding: '$sm $lg $sm $md',
    width: '98px',
  },
};

export const viewAllVerbatimFlexStyles = {
  '&.abyss-flex-root': {
    marginBottom: '$lg',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  '&.abyss-flex-content-root': {
    alignItems: 'center',
  },
};

export const TextSubHeadingWhatPatientsAreSaying = styled(Text, {
  '&.abyss-text-root': {
    color: '#002677',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '20px',
    fontSize: '16px',
  },
});

export const PaginationComponentStyled = styled(Pagination, {
  '&.abyss-pagination-root': {
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: '32px',
  },
});

export const AllPatientReviewsContainer = styled('div', {
  maxWidth: '1200px',
  marginLeft: '24px',
  marginRight: '24px',
  padding: '$lg 0',
  '@screen < $md': {
    marginLeft: '16px',
    marginRight: '16px',
    padding: '16px 0',
  },
});

export const OuterContainer = styled('div', {
  borderBottom: '1px solid $cardBorder',
});
