import { styled } from '@abyss/web/tools/styled';

export const hamburgerButton = {
  'abyss-button-root': {
    padding: '0px 0px 0px 0px',
    border: 'none',
    outline: 'none',
    marginBottom: '-4px',
    '&:hover, &:focus, &:active': {
      backgroundColor: '#FBFCFE',
      border: 'none',
      outline: 'none',
    },
    '@screen < $sm': {
      paddingTop: '12px',
    },
  },
};

export const tabsCategory = {
  color: '$primary1',
  fontWeight: '$bold',
  lineHeight: '28px',
  fontSize: '22.78px',
  paddingBottom: '8px',
  '@screen < $sm': {
    color: '#003C71',
    fontWeight: '600',
    lineHeight: '20px',
    fontSize: '16px',
  },
};

export const tabFlexStyle = {
  'abyss-flex-root': {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    borderColor: '$gray3',
    borderWidth: '0px 0px 1px 0px ',
    borderStyle: 'solid',
  },
};

export const browseFlexStyle = {
  borderStyle: 'solid',
  borderColor: '$gray3',
  borderWidth: '1px 0px 0px 0px ',
  padding: '24px 0px 0px 0px',
  '@screen < $md': {
    borderWidth: '0px 0px 0px 0px ',
  },
  '@screen < $sm': {
    padding: '16px 0px 0px 0px',
  },
  '.abyss-button-root': {
    marginBottom: '-4px !important',
  },
};

export const tabsLayoutStyles = {
  '.abyss-layout-stack': {
    margin: '0px',
  },
  '.abyss-flex-root': {
    paddingLeft: 'calc((100% - 1200px) / 2)',
    paddingRight: 'calc((100% - 1200px) / 2)',
    '@media (max-width: 1248px)': {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    '@screen < $md': {
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
};

export const TabsContainer = styled('div', {
  display: 'inline-flex',
  overflow: 'auto',
  '.abyss-c-esCbFz': {
    borderStyle: 'solid',
    borderColor: '$cardBorder',
    borderWidth: '0px 0px 0px 0px ',
  },
  '.abyss-tabs-content-container': {
    borderWidth: '0px 0px 0px 0px',
    padding: '0px',
  },
  '.abyss-tabs-tab-content': {
    fontSize: '16px',
    fontWeight: '$bold',
    lineHeight: '20px',
    textAlign: 'center',
    '@screen < $sm': {
      fontSize: '18px',
      fontWeight: '$bold',
      lineHeight: '24px',
      textAlign: 'center',
    },
  },
  '.abyss-tabs-tab-active-line': {
    borderColor: '$interactive1',
    '@screen < $sm': {
      borderColor: '$gray7',
      borderRadius: '6px 6px 0px 0px',
    },
  },
  '.abyss-tabs-list': {
    gap: '16px',
    textWrap: 'nowrap',
    scrollBehavior: 'smooth',
    scrollbarColor: 'transparent transparent',
    overflowX: 'auto',
    border: 'none',
    button: {
      minWidth: 'unset',
      padding: '8px 16px',
      '@screen < $sm': {
        padding: '10px 12px',
      },
    },
    '@screen < $md': {
      scrollbarWidth: 'none',
      scrollBehavior: 'smooth',
      scrollbarColor: 'transparent transparent',
      '&.hide-scrollbar': {
        overflow: 'auto',
        scrollBehavior: 'smooth',
        '&.hide-scrollbar::-webkit-scrollbar': {
          display: 'none',
        },
      },
    },
    '@screen < $sm': {
      display: 'flex',
    },
    '.abyss-c-jekBZS-kGZVKw-display-row': {
      minWidth: 'fit-content',
    },
    '.abyss-tabs-tab': {
      minHeight: '30px',
    },
  },
});
