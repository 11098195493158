import { styled } from '@abyss/web/tools/styled';

export const StyledVisionDisclaimer = styled('div', {
  '.abyss-card-root': {
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
    '@screen < $md': {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
  '.abyss-card-section': {
    padding: 0,
    paddingBottom: '10px',
    color: '$gray8 !important',
    fontSize: '12.64px !important',
    lineHeight: '16px',
  },
  b: {
    fontWeight: 800,
  },
});
export const DisclaimerContainer = styled('div', {
  div: {
    paddingTop: 0,
    paddingBottom: 0,
    fontWeight: '500',
  },
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '1200px',
});
