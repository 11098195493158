import { config } from '@abyss/web/tools/config';
import get from 'lodash/get';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import {
  AUTO_INCREMENT_SEARCH_RADIUS,
  Constants,
  CoverageTypesCodes,
  DEFAULT_SEARCH_RADIUS,
  FilterCollectionModel,
  OPTUM_CORRELATION_ID_HEADER,
  ProviderTypes,
  ReverseCoverageTypesCodes,
  SortOptionValues,
} from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../common/Utils';
import { SearchFilterContext } from '../../context/SearchFilterContext';
import { useCurrentMemberIsPreEffective } from '../../hooks/useCurrentMemberIsPreEffective';
import { CustomPreferredPolicy } from '../../models/Lagoon';
import { ProviderType } from '../../models/ProviderDetails';
import { ProviderSearchRequestParams } from '../../models/ProviderSearch';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { usePCPStore } from '../../store/usePCPStore';
import { PCPSearchStore } from '../../store/usePCPStore/pcpStore';
import { useSearchStore } from '../../store/useSearchStore';
import { SearchState } from '../../store/useSearchStore/searchStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import {
  getProviderType,
  getProviderResults as parseProviderResults,
  resolveIncludeSpecialityRollupCodes,
} from '../../utils/providerDetails.utils';
import {
  getCurrentMemberData,
  getLob,
  getLocale,
  getMemberHealthCoverageType,
  getOrgTypeCodes,
  getSpecialityRollupCodes,
  isValidPlanVariationCode,
  parseProviderSearchFilters,
} from '../../utils/providerSearch.utils';
import {
  getClaimOrEligibilitySystemTypeCode,
  getCoverageEffDateRange,
  getCoverageTypes,
  getCurrentMember,
  getCurrentPlanYear,
  getDependentInfo,
  getIsCustomPreferredPolicy,
  getIsOxfordAndUSP,
  getNetworkIdsForPES,
  getOnlineRetailers,
  getPlanVariationCode,
  getRulesPackageKey,
  getSitusState,
} from '../../utils/user.utils';
import { useCustomQuery } from '../useCustomQuery';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import { useLagoon } from '../useLagoon';
import { getBehavioralFacilityPrograms } from '../useProviderFilterCount/useProviderFilterCount';
import { useTypeaheadPseudoRollup } from '../useTypeaheadPseudoRollup';
import GET_PROVIDER_SEARCH_QUERY from './ProviderSearch.graphql';

export const memberCatRPK = (
  currentMember,
  uspTierOneFlag,
  uspToggleFlag,
  tierBenefits,
  providerType
) => {
  const membershipCategory = uspToggleFlag
    ? currentMember?.membershipCategory || ''
    : null;
  return {
    membershipCategory,
    rulesPackageKey: getRulesPackageKey(
      currentMember,
      tierBenefits,
      providerType,
      uspTierOneFlag
    ),
  };
};

export const getFirstNumber = (str: string) => str?.split(',')[0];

export const filterSearchResults = (searchResult) => {
  const providerSearchResult = get(searchResult, 'providerSearch', {});

  const providersData = providerSearchResult.providers;

  const practitionersFiltered = providersData?.filter(
    (data) => data?.providerType === ProviderType.PRACTITIONER
  );

  const facilitiesFiltered = providersData?.filter(
    (data) => data?.providerType === ProviderType.ORGANIZATION
  );

  const medicalGroup = providersData?.filter(
    (data) => data?.providerType === ProviderType.MEDICAL_GROUP
  );

  return {
    totalCountPractitioners: providerSearchResult.totalCountPractitioners,
    totalCountOrganizations: providerSearchResult.totalCountOrganizations,
    totalCountMedicalGroup: providerSearchResult.totalCountMedicalGroup,
    providers: practitionersFiltered || [],
    facilities: facilitiesFiltered || [],
    medicalGroup: medicalGroup || [],
    practitionerSearchRadius: providerSearchResult.practitionerSearchRadius,
    organizationSearchRadius: providerSearchResult.organizationSearchRadius,
    medicalGroupSearchRadius: providerSearchResult.medicalGroupSearchRadius,
    networkStatus: providerSearchResult.networkStatus,
    isTieredPlan: providerSearchResult.isTieredPlan,
  };
};
export const getBehavioralFacilityProgramsProviderSearch = (
  hasQueryTypeBhfpOrg,
  hasQueryTypeBhfpMedGrp,
  searchType,
  coverageType,
  providerType,
  behavioralFacilityPrograms
): string => {
  if (
    hasQueryTypeBhfpOrg &&
    searchType === Constants.SEARCH_TYPES.SPECIALTY &&
    coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] &&
    (providerType === ProviderTypes.ORGANIZATION ||
      providerType === ProviderTypes.FACILITY)
  ) {
    return behavioralFacilityPrograms;
  } else if (
    hasQueryTypeBhfpMedGrp &&
    searchType === Constants.SEARCH_TYPES.SPECIALTY &&
    coverageType === ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] &&
    providerType === ProviderTypes.MEDICAL_GROUP
  ) {
    return behavioralFacilityPrograms;
  }
  return '';
};

const getIncludeSpecialityRollupCodes = (
  searchParams,
  includeSpecialityRollupCodesFromParams
) =>
  searchParams.behavioralFacilityPrograms === '' &&
  searchParams.searchType === Constants.SEARCH_TYPES.SPECIALTY
    ? resolveIncludeSpecialityRollupCodes(
        includeSpecialityRollupCodesFromParams
      )
    : [];

export const useProviderSearch = (options) => {
  const [filteredResult, setFilteredResult] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [headers, setHeaders] = useState({});
  const [queryResult, queryAction] = useCustomQuery(GET_PROVIDER_SEARCH_QUERY, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'providerSearch',
    onCalled: () => {
      setIsLoading(true);
    },
    /* istanbul ignore next */
    onCompleted: (result) => {
      const data = filterSearchResults(result?.data);
      if (options?.onCompleted) {
        options.onCompleted(data);
      }
      setFilteredResult(data);
      setIsLoading(false);
      setHeaders({
        correlationId: result.headers[OPTUM_CORRELATION_ID_HEADER],
      });
    },
    onError: () => {
      setIsLoading(false);
    },
  });
  const parsedResult = {
    ...queryResult,
    isLoading,
    data: filteredResult,
    headers,
  };

  return [parsedResult, queryAction];
};

export const useProviderResults = (customParams: any) => {
  const { coverageType, storeLob } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
      storeLob: state.lob,
    }))
  );
  const { dependentSeqNbr } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
    }))
  );
  const { bhProgramFacilityAgg, hasQueryTypeBhfpOrg, hasQueryTypeBhfpMedGrp } =
    useTypeaheadStore(
      useShallow((state: TypeaheadState) => ({
        bhProgramFacilityAgg: state.typeaheadSearchStore.bhProgramFacilityAgg,
        hasQueryTypeBhfpOrg: state.typeaheadSearchStore.hasQueryTypeBhfpOrg,
        hasQueryTypeBhfpMedGrp:
          state.typeaheadSearchStore.hasQueryTypeBhfpMedGrp,
      }))
    );
  const { longitude, latitude, stateCode } = useGeoLocationStorage();

  const currentMember = dependentSeqNbr
    ? getDependentInfo(dependentSeqNbr)
    : getCurrentMember();

  const medicalCoverage = getMemberHealthCoverageType(
    currentMember?.eligibility,
    CoverageTypesCodes.MEDICAL
  );
  const productCode = medicalCoverage?.productCode;
  const marketType = medicalCoverage?.marketType;

  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const useMedicalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_MEDICAL_NEW_ROLLUP_CODES
  );
  const useBHNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_BH_NEW_ROLLUP_CODES
  );
  const useVisionNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_VISION_NEW_ROLLUP_CODES
  );
  const useDentalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_DENTAL_NEW_ROLLUP_CODES
  );
  const llmFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LLM
  );

  const uspTierOneFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_TIER1_ENABLE
  );

  const uspToggleFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_ENABLE
  );

  const isFutureTerminationDemo: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.FUTURE_TERMINATION_DEMO
  );

  const allConfigs = useLagoon('config')();
  const terminationProviderIdsDemo = allConfigs?.find(
    (row) => row.key === 'TERMINATION_PROVIDER_IDS_DEMO'
  )?.value;

  const customPreferredPolicies: CustomPreferredPolicy[] = useLagoon(
    'custom-preferred-policies'
  )();
  const rollupCodeMapLagoonData = useLagoon(
    Constants.LAGOON_TABLE.ROLLUPCODE_MAPPING
  )();
  const tierBenefits = useLagoon('tier-benefit')();

  const rollupCodeMap = getRollupCodeMap(rollupCodeMapLagoonData);

  const {
    ddpCode,
    demographics,
    lineOfBusiness: memberLob,
    population,
    policyNumber: policyID,
  } = currentMember;
  const dateOfBirth = demographics?.dateOfBirth;
  const gender = demographics?.gender;

  const reciprocityId = getNetworkIdsForPES(
    currentMember,
    coverageType,
    featureFlags
  );
  const isCustomPreferredPolicy: boolean = getIsCustomPreferredPolicy(
    customPreferredPolicies,
    policyID,
    reciprocityId
  );

  const isPreEffective = useCurrentMemberIsPreEffective();

  const variables = {
    lob: getLob(memberLob, storeLob),
    population,
    coverages: getCoverageTypes(currentMember),
    situsState: getSitusState(currentMember, getLob(memberLob, storeLob)),
    planYear: getCurrentPlanYear(),
    memberEffectiveDate: isPreEffective
      ? getCoverageEffDateRange(currentMember).startDate
      : null,
    planVariationCode: getPlanVariationCode(currentMember, coverageType),
    claimSystemTypeCode: getClaimOrEligibilitySystemTypeCode(
      currentMember,
      coverageType
    )?.claimSystemTypeCode,
    eligibilitySystemTypeCode: getClaimOrEligibilitySystemTypeCode(
      currentMember,
      coverageType
    )?.eligibilitySystemTypeCode,
    policyID,
    reciprocityId: getNetworkIdsForPES(
      currentMember,
      customParams?.coverageType,
      featureFlags
    ),
    rollupCodeMap,
    snpType:
      coverageType == ReverseCoverageTypesCodes.MEDICAL
        ? medicalCoverage?.snpType
        : '',
    locale: getLocale(),
    latitude,
    longitude,
    stateCode,
    memberDemographics: {
      gender,
      dateOfBirth,
    },
    coverageType: customParams?.coverageType,
    isExtendedSearchEnabled: customParams?.isExtendedSearchEnabled,
    isFutureTerminationDemo,
    terminationProviderIdsDemo,
    useMedicalNewRollUpCodes,
    useBHNewRollUpCodes,
    useVisionNewRollUpCodes,
    useDentalNewRollUpCodes,
    ddpCode,
    isCustomPreferredPolicy,
    cesProductCode: productCode,
    memberMarketType: marketType,
  };

  // to store all the search variables including default variables and selected filter params
  const [queryParams, setQueryParams] = useState(variables);

  const [providerResults, getProviders] = useProviderSearch({});

  const getProviderResults = (params) => {
    const planVariationCodeNew = getPlanVariationCode(
      currentMember,
      params?.coverageType
    );
    const { membershipCategory, rulesPackageKey } = memberCatRPK(
      currentMember,
      uspTierOneFlag,
      uspToggleFlag,
      tierBenefits,
      params?.providerType
    );
    const searchParams = {
      ...variables,
      ...params,
      planVariationCode: variables?.planVariationCode || planVariationCodeNew,
      llmFlag: params?.pseudoRollupCode ? false : llmFlag,
      membershipCategory,
      rulesPackageKey,
      searchRadius:
        customParams?.coverageType ===
          ReverseCoverageTypesCodes['BEHAVIORAL HEALTH'] &&
        customParams?.virtualCare === true
          ? -1
          : customParams?.searchRadius,
      behavioralFacilityPrograms: getBehavioralFacilityProgramsProviderSearch(
        hasQueryTypeBhfpOrg,
        hasQueryTypeBhfpMedGrp,
        params?.searchType,
        coverageType,
        params.providerType,
        bhProgramFacilityAgg
      ),
    };

    const {
      includeSpecialityRollupCodes: includeSpecialityRollupCodesFromParams,
    } = searchParams;
    const includeSpecialityRollupCodes = getIncludeSpecialityRollupCodes(
      searchParams,
      includeSpecialityRollupCodesFromParams
    );

    const validPlanVariationCode = isValidPlanVariationCode(
      searchParams.eligibilitySystemTypeCode,
      searchParams.claimSystemTypeCode,
      searchParams.planVariationCode,
      searchParams.lob,
      searchParams.population
    );
    if (JSON.stringify(queryParams) !== JSON.stringify(searchParams)) {
      setQueryParams(searchParams);
      if (!validPlanVariationCode) {
        throw new Error('Plan Variation Code is required');
      }
      getProviders({
        variables: {
          ...searchParams,
          includeSpecialityRollupCodes,
        },
      });
    }
  };

  return [providerResults, getProviderResults];
};
const getRollupCodeMap = (rollupCodeMapLagoonData: any) =>
  rollupCodeMapLagoonData?.reduce((acc, item) => {
    if (item.source && item.destination) {
      acc[item.source] = item.destination;
    }
    return acc;
  }, {});
const validateRadius = (providerSearchRadius, autoSearchRadius) =>
  providerSearchRadius && autoSearchRadius !== providerSearchRadius;

const validateSourceAndDestination = (source, destination) =>
  source && destination;

const getUpdatedFilterState = (
  includeOrgTypeCodes: any,
  excludeOrgTypeCodes: any,
  acceptingNewPatients: boolean | undefined,
  validPlanVariationCode: string
) => {
  const updatedParams: ProviderSearchRequestParams = {
    pageNumber: 1,
    pageSize: 10,
    includeOrgTypeCodes: getOrgTypeCodes(includeOrgTypeCodes),
    excludeOrgTypeCodes: getOrgTypeCodes(excludeOrgTypeCodes),
    searchRadius: DEFAULT_SEARCH_RADIUS,
    sortBy: SortOptionValues.BEST_MATCH,
  };
  // reset filters
  const defaultFilters: FilterCollectionModel[] = [
    FilterCollectionModel.AUTO_SEARCH_RADIUS,
  ];

  if (acceptingNewPatients) {
    updatedParams.acceptingNewPatients = true;
    defaultFilters.push(FilterCollectionModel.ACCEPTING_NEW_PATIENTS);
  }

  if (!validPlanVariationCode) {
    throw new Error('Plan Variation Code is required');
  }

  return { updatedParams, defaultFilters };
};

const canGetProviderResults = (
  providerType: string,
  searchParams: any,
  queryParams: any
) =>
  providerType && JSON.stringify(searchParams) !== JSON.stringify(queryParams);

const getRollupCodes = (bhFacilityPrograms, rollupCodes) =>
  bhFacilityPrograms === '' ? [...new Set(rollupCodes)] : [];

export const useFilterProviderResults = (params) => {
  const { coverageType, storeLob } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
      storeLob: state.lob,
    }))
  );
  const { pcpIndicator = false, dependentSeqNbr } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      pcpIndicator: state.pcpSearchState.pcpIndicator,
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
    }))
  );

  const {
    useMockData = false,
    search = '',
    providerType = '',
    category,
    searchType,
    sectionType = '',
    specialtyCode,
    includeSpecialityRollupCodes,
    acceptingNewPatients,
    keyword = '',
    isUserTextSearch,
    pseudoVerticals = '',
    suggestion,
    bhProgramFacilityAgg,
    hasQueryTypeBhfpOrg,
    hasQueryTypeBhfpMedGrp,
  } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      useMockData: state.typeaheadSearchStore.useMockData,
      search: state.typeaheadSearchStore.search,
      providerType: state.typeaheadSearchStore.providerType,
      category: state.typeaheadSearchStore.category,
      searchType: state.typeaheadSearchStore.searchType,
      sectionType: state.typeaheadSearchStore.sectionType,
      specialtyCode: state.typeaheadSearchStore.specialtyCode,
      includeSpecialityRollupCodes:
        state.typeaheadSearchStore.includeSpecialityRollupCodes,
      acceptingNewPatients: state.typeaheadSearchStore.acceptingNewPatients,
      keyword: state.typeaheadSearchStore.keyword,
      isUserTextSearch: state.typeaheadSearchStore.isUserTextSearch,
      pseudoVerticals: state.typeaheadSearchStore.pseudoVerticals,
      suggestion: state.typeaheadSearchStore.suggestion,
      bhProgramFacilityAgg: state.typeaheadSearchStore.bhProgramFacilityAgg,
      hasQueryTypeBhfpOrg: state.typeaheadSearchStore.hasQueryTypeBhfpOrg,
      hasQueryTypeBhfpMedGrp: state.typeaheadSearchStore.hasQueryTypeBhfpMedGrp,
    }))
  );
  const {
    includeOrgTypeCodes,
    excludeOrgTypeCodes,
    requestType = '',
    onlineRetailers,
    behavioralFacilityPrograms,
  } = useSearchStore(
    useShallow((state: SearchState) => ({
      includeOrgTypeCodes: state.searchStore.includeOrgTypeCodes,
      excludeOrgTypeCodes: state.searchStore.excludeOrgTypeCodes,
      requestType: state.searchStore.requestType,
      onlineRetailers: state.searchStore.onlineRetailers,
      behavioralFacilityPrograms: state.searchStore.behavioralFacilityPrograms,
    }))
  );

  const { longitude, latitude, stateCode } = useGeoLocationStorage();
  const {
    pageNumber = 1,
    pageSize = 10,
    selectedFilters = {},
    shouldGetHGData,
  } = params;
  const currentMember = getCurrentMemberData(dependentSeqNbr);
  const medicalCoverage = getMemberHealthCoverageType(
    currentMember?.eligibility,
    CoverageTypesCodes.MEDICAL
  );
  const productCode = medicalCoverage?.productCode;
  const marketType = medicalCoverage?.marketType;

  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const useMedicalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_MEDICAL_NEW_ROLLUP_CODES
  );
  const useBHNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_BH_NEW_ROLLUP_CODES
  );
  const useVisionNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_VISION_NEW_ROLLUP_CODES
  );
  const useDentalNewRollUpCodes: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_DENTAL_NEW_ROLLUP_CODES
  );

  const uspTierOneFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_TIER1_ENABLE
  );

  const uspToggleFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_ENABLE
  );

  const llmFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LLM
  );

  const customPreferredPolicies: CustomPreferredPolicy[] = useLagoon(
    'custom-preferred-policies'
  )();
  const rollupCodeMapLagoonData = useLagoon(
    Constants.LAGOON_TABLE.ROLLUPCODE_MAPPING
  )();
  const tierBenefits = useLagoon('tier-benefit')();

  const rollupCodeMap = rollupCodeMapLagoonData?.reduce((acc, item) => {
    if (validateSourceAndDestination(item.source, item.destination)) {
      acc[item.source] = item.destination;
    }
    return acc;
  }, {});

  const rollupCodes = useMemo(() => {
    const codes = getSpecialityRollupCodes(
      specialtyCode,
      includeSpecialityRollupCodes,
      category
    );
    return codes.split(',');
  }, [specialtyCode, includeSpecialityRollupCodes, category]);

  const {
    ddpCode,
    demographics,
    lineOfBusiness: memberLob,
    population,
    policyNumber: policyID,
  } = currentMember;
  const dateOfBirth = demographics?.dateOfBirth;
  const gender = demographics?.gender;

  const reciprocityId = getNetworkIdsForPES(
    currentMember,
    coverageType,
    featureFlags
  );
  const isCustomPreferredPolicy: boolean = getIsCustomPreferredPolicy(
    customPreferredPolicies,
    policyID,
    reciprocityId
  );

  const { membershipCategory, rulesPackageKey } = memberCatRPK(
    currentMember,
    uspTierOneFlag,
    uspToggleFlag,
    tierBenefits,
    providerType
  );

  const allConfigs = useLagoon('config')();
  const terminationProviderIdsDemo = allConfigs?.find(
    (row) => row.key === 'TERMINATION_PROVIDER_IDS_DEMO'
  )?.value;
  const isFutureTerminationDemo: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.FUTURE_TERMINATION_DEMO
  );

  const { isOXMember } = getIsOxfordAndUSP(
    currentMember,
    coverageType,
    featureFlags
  );

  const pseudoVerticalsDetails = useTypeaheadPseudoRollup(
    pseudoVerticals,
    suggestion,
    isOXMember
  );

  const isPseudoRollupSearch = pseudoVerticalsDetails?.isPseudoRollupSearch;
  const isPreEffective = useCurrentMemberIsPreEffective();
  const bhFacilityPrograms = getBehavioralFacilityProgramsProviderSearch(
    hasQueryTypeBhfpOrg,
    hasQueryTypeBhfpMedGrp,
    searchType,
    coverageType,
    providerType,
    bhProgramFacilityAgg
  );

  const variables = {
    lob: getLob(memberLob, storeLob),
    population,
    membershipCategory,
    coverages: getCoverageTypes(currentMember),
    planYear: getCurrentPlanYear(),
    situsState: getSitusState(currentMember, getLob(memberLob, storeLob)),
    memberEffectiveDate: isPreEffective
      ? getCoverageEffDateRange(currentMember).startDate
      : null,
    planVariationCode: getPlanVariationCode(currentMember, coverageType),
    claimSystemTypeCode: getClaimOrEligibilitySystemTypeCode(
      currentMember,
      coverageType
    )?.claimSystemTypeCode,
    eligibilitySystemTypeCode: getClaimOrEligibilitySystemTypeCode(
      currentMember,
      coverageType
    )?.eligibilitySystemTypeCode,
    policyID,
    reciprocityId: getNetworkIdsForPES(
      currentMember,
      coverageType,
      featureFlags
    ),
    rulesPackageKey,
    rollupCodeMap,
    snpType:
      coverageType == ReverseCoverageTypesCodes.MEDICAL
        ? medicalCoverage?.snpType
        : '',
    locale: getLocale(),
    shouldGetHGData,
    latitude,
    longitude,
    stateCode,
    memberDemographics: {
      gender,
      dateOfBirth,
    },
    coverageType,
    providerType: getProviderType(providerType, sectionType),
    search,
    includeSpecialityRollupCodes: getRollupCodes(
      bhFacilityPrograms,
      rollupCodes
    ),
    requestType,
    searchType,
    pcpIndicator,
    llmFlag: isPseudoRollupSearch ? false : llmFlag,
    keyword,
    onlineRetailers: getOnlineRetailers(onlineRetailers),
    isFutureTerminationDemo,
    terminationProviderIdsDemo,
    useMedicalNewRollUpCodes,
    useBHNewRollUpCodes,
    useVisionNewRollUpCodes,
    useDentalNewRollUpCodes,
    ddpCode,
    isCustomPreferredPolicy,
    cesProductCode: productCode,
    memberMarketType: marketType,
    behavioralFacilityPrograms:
      bhFacilityPrograms || behavioralFacilityPrograms,
    isUserTextSearch,
  };

  const { searchFilters, setSearchFilters, setDefaultSearchFilters } =
    useContext(SearchFilterContext);
  const { AutoSearchRadius: autoSearchRadius } = searchFilters;
  const filterParams = parseProviderSearchFilters(selectedFilters);
  const queryParams = {
    pageNumber,
    pageSize,
    includeOrgTypeCodes: getOrgTypeCodes(includeOrgTypeCodes),
    excludeOrgTypeCodes: getOrgTypeCodes(excludeOrgTypeCodes),
    // remove distance limit for Virtual Care filter for BH providers (US6465032),
    // backend logic will handle -1 for no distance limit search
    searchRadius:
      coverageType === 'B' && filterParams?.virtualCare === true
        ? -1
        : autoSearchRadius,
    ...filterParams,
    behavioralFacilityPrograms:
      bhFacilityPrograms ||
      getBehavioralFacilityPrograms(filterParams, behavioralFacilityPrograms),
  };

  const [defaultVariables, setDefaultVariables] = useState(variables);
  const [searchParams, setSearchParams] = useState(filterParams);
  const [practitionersResult, getPractitionersResult] = useProviderSearch({});
  const validPlanVariationCode = isValidPlanVariationCode(
    variables.eligibilitySystemTypeCode,
    variables.claimSystemTypeCode,
    variables.planVariationCode,
    variables.lob,
    variables.population
  );

  useEffect(() => {
    if (!providerType) {
      return;
    }

    const { updatedParams, defaultFilters } = getUpdatedFilterState(
      includeOrgTypeCodes,
      excludeOrgTypeCodes,
      acceptingNewPatients,
      validPlanVariationCode
    );

    // auto increment search and reset filters on change of location, current member, etc
    if (JSON.stringify(defaultVariables) !== JSON.stringify(variables)) {
      getPractitionersResult({
        variables: {
          autoIncrementRadius: AUTO_INCREMENT_SEARCH_RADIUS,
          ...variables,
          ...updatedParams,
        },
      });
      setDefaultSearchFilters(defaultFilters);
      setSearchParams(updatedParams);
      setDefaultVariables(variables);
    }
  }, [JSON.stringify(variables)]);

  useEffect(() => {
    if (canGetProviderResults(providerType, searchParams, queryParams)) {
      setSearchParams(queryParams);
      getPractitionersResult({
        variables: {
          ...variables,
          ...queryParams,
        },
      });
    }
  }, [JSON.stringify(queryParams)]);

  const { results, totalResultsCount, providerSearchRadius, isLoading, error } =
    parseProviderResults(sectionType, practitionersResult, useMockData);

  useEffect(() => {
    if (validateRadius(providerSearchRadius, autoSearchRadius)) {
      setSearchFilters({
        ...searchFilters,
        AutoSearchRadius: providerSearchRadius,
      });
    }
  }, [providerSearchRadius]);

  return {
    results,
    totalResultsCount,
    searchRadius: autoSearchRadius,
    isLoading,
    isTieredPlan: practitionersResult?.data?.isTieredPlan,
    error,
    headers: practitionersResult?.headers,
  };
};

export const useProviderSummaryResult = ({ providerId, isOHBS }) => {
  const { coverageType, storeLob } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
      storeLob: state.lob,
    }))
  );
  const currentMember = getCurrentMember();
  const medicalCoverage = getMemberHealthCoverageType(
    currentMember?.eligibility,
    CoverageTypesCodes.MEDICAL
  );
  const productCode = medicalCoverage?.productCode;
  const marketType = medicalCoverage?.marketType;
  const { longitude, latitude, stateCode } = useGeoLocationStorage();

  const featureFlags: [{ key: string; active: boolean }] = useLagoon(
    Constants.LAGOON_TABLE.FEATURE_FLAGS
  )();
  const tierBenefits = useLagoon('tier-benefit')();

  const uspTierOneFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_TIER1_ENABLE
  );

  const uspToggleFlag = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_ENABLE
  );

  const { membershipCategory, rulesPackageKey } = memberCatRPK(
    currentMember,
    uspTierOneFlag,
    uspToggleFlag,
    tierBenefits,
    ''
  );

  const isPreEffective = useCurrentMemberIsPreEffective();

  const variables = {
    longitude,
    latitude,
    stateCode,
    providerId,
    providerType: '',
    lob: getLob(currentMember?.lineOfBusiness, storeLob),
    population: currentMember?.population,
    membershipCategory,
    coverages: getCoverageTypes(currentMember),
    situsState: getSitusState(
      currentMember,
      getLob(currentMember?.lineOfBusiness, storeLob)
    ),
    planYear: getCurrentPlanYear(),
    memberEffectiveDate: isPreEffective
      ? getCoverageEffDateRange(currentMember).startDate
      : null,
    planVariationCode: getPlanVariationCode(currentMember, coverageType),
    claimSystemTypeCode: getClaimOrEligibilitySystemTypeCode(
      currentMember,
      coverageType
    )?.claimSystemTypeCode,
    eligibilitySystemTypeCode: getClaimOrEligibilitySystemTypeCode(
      currentMember,
      coverageType
    )?.eligibilitySystemTypeCode,
    policyID: currentMember?.policyNumber,
    reciprocityId: isOHBS
      ? '100'
      : getNetworkIdsForPES(currentMember, coverageType, featureFlags),
    rulesPackageKey,
    snpType:
      coverageType == ReverseCoverageTypesCodes.MEDICAL
        ? medicalCoverage?.snpType
        : '',
    coverageType,
    locale: getLocale(),
    ddpCode: currentMember?.ddpCode,
    cesProductCode: productCode,
    memberMarketType: marketType,
  };

  const [providerResult, getProviderResult] = useProviderSearch({});
  const validPlanVariationCode = isValidPlanVariationCode(
    variables.eligibilitySystemTypeCode,
    variables.claimSystemTypeCode,
    variables.planVariationCode,
    variables.lob,
    variables.population
  );

  useEffect(() => {
    if (providerId) {
      if (!validPlanVariationCode) {
        throw new Error('Plan Variation Code is required');
      }

      getProviderResult({
        variables,
      });
    }
  }, [providerId]);

  return providerResult;
};
