import { config } from '@abyss/web/tools/config';

import { useCustomQuery } from '../useCustomQuery';
import EVENTCAPTURE from './EventCapture.graphql';

export const useEventCapture = (options) =>
  useCustomQuery(EVENTCAPTURE, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    requestPolicy: 'no-cache',
    accessor: 'eventCapture',
  });
