import { createContext, useState } from 'react';

import { Constants } from '../common/Constants';

export const ChipCategoryContext = createContext({
  value: Constants.CHIPS_CATEGORIES.ALL,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setChipValue: (val: string) => {},
});

export const ChipCategoryContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [value, setChipValue] = useState('');
  return (
    <ChipCategoryContext.Provider value={{ value, setChipValue }}>
      {children}
    </ChipCategoryContext.Provider>
  );
};
