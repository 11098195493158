import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';

import { mobileOnly } from '../../../ConstantsStyles';
import { ParagraphSkeleton } from '../../../Skeleton';

interface IProps {
  testId?: string;
}

export const LocationDropdownSkeleton = ({ testId }: IProps) => {
  const isMobileView = useMediaQuery(mobileOnly);
  const desktopWidths = [310, 261, 287, 201];
  const mobileWidths = [164, 133, 164];
  const widths = isMobileView ? mobileWidths : desktopWidths;
  const lineSpacing = isMobileView ? 11 : 12;

  return (
    <ParagraphSkeleton
      lineSpacing={lineSpacing}
      testId={testId}
      widths={widths}
    />
  );
};
