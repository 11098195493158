import { Chip } from '../Chip';
import { replaceSpaceToUpperCase } from '../Utils/replaceString';

type Props = {
  dentalTerm?: string;
  id?: string;
  icon?: string;
  DNT_dentalTerm?: string;
  psxKeyword?: string;
  url?: string;
  updatedToken(item: any): void;
  item?: any;
};

export const CommonlySearchedDental = ({
  dentalTerm,
  id,
  icon,
  DNT_dentalTerm,
  psxKeyword,
  updatedToken,
  url,
  item,
}: Props) =>
  dentalTerm ? (
    <Chip
      adobeChipName={DNT_dentalTerm}
      data-auto-testid={`dental-searched-${replaceSpaceToUpperCase(
        dentalTerm
      )}`}
      data-testid={`dental-searched-${replaceSpaceToUpperCase(dentalTerm)}`}
      icon={icon}
      isStandardAnchor={false}
      key={`dental-search-${id}`}
      text={dentalTerm}
      url={url.toString()}
    />
  ) : (
    <Chip
      adobeChipName={DNT_dentalTerm}
      data-auto-testid={`dental-searched-${replaceSpaceToUpperCase(
        psxKeyword
      )}`}
      data-testid={`dental-searched-${replaceSpaceToUpperCase(psxKeyword)}`}
      icon={icon}
      key={`dental-search-${id}`}
      onClick={() => updatedToken(item)}
      text={psxKeyword}
    />
  );
