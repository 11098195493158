export const MockTrueProvider = {
  address: {
    line: ['785 Fake Really Long Street Name for testing'],
    city: 'North Pole',
    district: '',
    state: 'CA',
    postalCode: '92804',
  },
  phones: {
    phone: ['12345678900'],
    emergency: [''],
    appointment: [''],
    fax: [''],
  },
  distance: '',
  facilityType: '',
  gender: 'Male',
  latitude: '',
  locationId: '',
  longitude: '',
  networkStatus: '',
  practitionerRoleId: '',
  professionalDesignation: '',
  providerId: '',
  providerName: 'Mock True Provider',
  primaryDegrees: [],
  providerType: '',
  acceptingNewPatients: true,
  efficiencyCode: '',
  languagesSpoken: [
    'English',
    'French',
    'Italian',
    'Chinese',
    'Klingon',
    'Zoomer',
    'Hippie',
  ],
  hoursOfOperation: {},
  qualityCode: '',
  virtualCareOffered: true,
  speciality:
    'Longest specialty in the world that this would probably not happen, but you never know',
  healthGradeRating: '4.4',
  premiumCareProvider: 'YES',
  hasPCPServiceAvailability: true,
  isTieredProvider: true,
  educationInfo: [
    {
      degrees: [
        { degreeCode: 'MD', isPrimary: false },
        { degreeCode: 'DD', isPrimary: false },
      ],
      schoolName: 'Fake University of Northern Southern Californiaa',
    },
    {
      degrees: [
        { degreeCode: 'MD', isPrimary: false },
        { degreeCode: 'CD', isPrimary: false },
      ],
      schoolName: 'UC Berkeley; Berkeley, CA',
    },
    {
      degrees: [
        { degreeCode: 'MD', isPrimary: false },
        { degreeCode: 'DD', isPrimary: false },
      ],
      schoolName: 'University of California Davis; Davis, CA',
    },
  ],
  providerEthnicityExpress: 'Latino',
  accessProvider: true,
  hasEveningAppointments: true,
  hasWeekendAppointment: true,
  practiceLimitations: {
    endAge: '75',
    startAge: '18',
  },
  servicesOffered: ['CONTACTS', 'EYEWARE', 'EYE_EXAMS'],
  costIndicator: '$$$',
};
