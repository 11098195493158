import { useForm } from '@abyss/web/hooks/useForm';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import find from 'lodash/find';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../../../../common/ConstantsLagoon';
import { LinkWithTracking } from '../../../../../../common/LinkWithTracking/LinkWithTracking';
import { useLagoon } from '../../../../../../hooks/useLagoon';
import { adobeShareProviders, emailInput } from '../utils';
import {
  AddAnotherEmailLink,
  EmailBarContainer,
} from './ShareDrawerContents.styled';

type Props = {
  emailOne: string;
  setEmailOne: React.Dispatch<React.SetStateAction<string>>;
  emailTwo: string;
  setEmailTwo: React.Dispatch<React.SetStateAction<string>>;
  emailThree: string;
  setEmailThree: React.Dispatch<React.SetStateAction<string>>;
  emailFour: string;
  setEmailFour: React.Dispatch<React.SetStateAction<string>>;
  emailFive: string;
  setEmailFive: React.Dispatch<React.SetStateAction<string>>;
};

export const ShareDrawerEmailInputs = ({
  emailOne,
  setEmailOne,
  emailTwo,
  setEmailTwo,
  emailThree,
  setEmailThree,
  emailFour,
  setEmailFour,
  emailFive,
  setEmailFive,
}: Props) => {
  const [numberOfEmails, setNumberOfEmails] = useState(1);
  const { t } = useTranslation();
  const form = useForm();
  const data = useLagoon('ui-messaging')();
  const validEmail = find(data, {
    key: ConstantsLagoon.VALID_EMAIL_ADDRESS.VALID_EMAIL_ADDRESS_MESSAGE,
  })?.message;

  const handleOnBlur = (model) => {
    form.trigger(model);
  };

  return (
    <React.Fragment>
      <EmailBarContainer
        data-auto-testid="email-bar-container"
        data-testid="email-bar-container"
      >
        {emailInput(
          setEmailOne,
          emailOne,
          'Email Address 1',
          validEmail,
          handleOnBlur
        )}
        {numberOfEmails > 1
          ? emailInput(
              setEmailTwo,
              emailTwo,
              'Email Address 2',
              validEmail,
              handleOnBlur
            )
          : null}
        {numberOfEmails > 2
          ? emailInput(
              setEmailThree,
              emailThree,
              'Email Address 3',
              validEmail,
              handleOnBlur
            )
          : null}
        {numberOfEmails > 3
          ? emailInput(
              setEmailFour,
              emailFour,
              'Email Address 4',
              validEmail,
              handleOnBlur
            )
          : null}
        {numberOfEmails > 4
          ? emailInput(
              setEmailFive,
              emailFive,
              'Email Address 5',
              validEmail,
              handleOnBlur
            )
          : null}
        {numberOfEmails < 5 ? (
          <LinkWithTracking
            after={<IconMaterial icon="add" size="18px" />}
            analyticsInfo={{
              location: `modal:${adobeShareProviders}`,
            }}
            css={AddAnotherEmailLink}
            data-auto-testid="link-email-addition-share-drawer"
            data-testid="link-email-addition-share-drawer"
            isStandardAnchor
            onClick={() => {
              setNumberOfEmails(numberOfEmails + 1);
            }}
          >
            {t('SHARE_INNER_DRAWER_TEXT.ADD_EMAIL')}
          </LinkWithTracking>
        ) : null}
      </EmailBarContainer>
    </React.Fragment>
  );
};
