import { FilterCollectionModel } from '../../../common/Constants';
import {
  Option,
  ProviderFiltersType,
  ProviderSearchFilters,
} from '../../../models/ProviderSearch';

const splitArrayValues = (array) => {
  const newArray: String[] = [];
  array.map((x) => {
    if (x.includes(',')) {
      const parts = x.split(',');

      parts.map((part) => {
        newArray.push(part);
      });
    } else {
      newArray.push(x);
    }
  });

  return newArray;
};

const valuePresent = (x, selectedAOEFilterArraySplit) => {
  const xValueSplit = splitArrayValues([x.value]);
  let aoeCodePresent = false;

  xValueSplit.map((value) => {
    if (selectedAOEFilterArraySplit.includes(value)) {
      aoeCodePresent = true;
    }
  });

  return aoeCodePresent;
};

export const setTranslationsForPreSelectedFilters = (
  filters: ProviderFiltersType[],
  selectedFilters: ProviderSearchFilters
): ProviderSearchFilters => {
  // For Aoe Filters, the lagoon will not have the translated content for the filters. Hence update the options with the translated filter response.
  if (selectedFilters[FilterCollectionModel.AREA_OF_EXPERTISE] === undefined) {
    return selectedFilters;
  }

  let aoeTranslatedOptions: Option[] = [];

  const translatedAOEFilers = filters.find(
    (x) => x.model === FilterCollectionModel.AREA_OF_EXPERTISE
  );
  if (translatedAOEFilers) {
    const selectedAOEFilterSet = new Set(
      selectedFilters[FilterCollectionModel.AREA_OF_EXPERTISE]?.map((x) =>
        String(x.value)
      ) || []
    );

    const selectedAOEFilterArray = Array.from(selectedAOEFilterSet);
    const selectedAOEFilterArraySplit = splitArrayValues(
      selectedAOEFilterArray
    );

    aoeTranslatedOptions =
      translatedAOEFilers?.options
        ?.filter((x: Option) => valuePresent(x, selectedAOEFilterArraySplit))
        .map((x) => ({ ...x })) || [];
  }

  return { ...selectedFilters, AreaOfExpertise: aoeTranslatedOptions };
};
