export interface SessionCookie {
  sessionId: string | null;
  lastAccessedTime: number | null;
  sessionDuration: number | null;
}

export const emptyCookie: SessionCookie = {
  sessionId: null,
  lastAccessedTime: null,
  sessionDuration: null,
};
