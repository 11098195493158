/* eslint-disable @optum-fpc-psx-mobile-apps/no-missing-testid */
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import { useTranslation } from 'react-i18next';

import { isLocationActiveSuggetion } from './LocationDropdown.styled';

export const LocationItem = ({
  location,
  onClick,
  index,
  activeSuggestion,
  suggestions,
}) => {
  const { t } = useTranslation();

  return (
    <li
      aria-posinset={index}
      aria-selected={activeSuggestion === index}
      id={
        activeSuggestion === index ? 'location-search-result-suggestions' : ''
      }
      key={index}
      role="option"
      style={activeSuggestion === index ? isLocationActiveSuggetion : {}}
    >
      <Link
        before={
          index === suggestions.length - 1 && (
            <IconMaterial icon="my_location" />
          )
        }
        css={
          index === suggestions.length - 1
            ? {
                '&.abyss-link-root': {
                  height: '42px',
                  marginTop: '7px',
                  padding: '8px 0px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '4px',
                },
              }
            : {
                '&.abyss-link-root': {},
              }
        }
        data-auto-testid={
          index === suggestions.length - 1
            ? `changeLocation_currentLocationAction`
            : `suggested-location-item${
                activeSuggestion === index ? '-active-suggestion' : ''
              }`
        }
        data-testid={
          index === suggestions.length - 1
            ? `changeLocation_currentLocationAction`
            : `suggested-location-item${
                activeSuggestion === index ? '-active-suggestion' : ''
              }`
        }
        fontWeight={index === suggestions.length - 1 ? 600 : '$bold'}
        id={`option-${index}`}
        onClick={(event) => {
          event.preventDefault();
          onClick(location, index);
        }}
        tabIndex={-1}
      >
        {index === suggestions.length - 1
          ? t('Find my location')
          : location?.place_name}
      </Link>
    </li>
  );
};
