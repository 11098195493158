import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';

import { getMonthAndYear } from '../../../../common/Utils/datesUtils/formatDate';
import { Survey } from '../../../../models/Healthgrades';
import { Rating } from '../../../SnackCard/Rating';
import { DateTextStyles } from './PatientReviews.styled';
import { PatientReviewSuggeston } from './RatingDescriptionSuggestion';

type Props = {
  review: Survey | null;
};

export const PatientReviewsModal = ({ review }: Props) => {
  const submittedDate = () => {
    if (review && review.submittedDate)
      return getMonthAndYear(review.submittedDate);
    return '';
  };
  if (!review) return null;
  return (
    <Flex css={{ gap: '$lg' }} direction="column">
      <Flex alignItems="end" direction="row" justify="space-between">
        <Rating hideNumber outOf={5} rating={review?.averageRating} size={24} />

        <Text
          css={DateTextStyles}
          data-testid="review-submitted-date"
          style={{
            paddingRight: '25px',
          }}
        >
          {submittedDate()}
        </Text>
      </Flex>
      <Text data-testid="review-comments" size="$lg">
        "{review?.comment?.commentText}"
      </Text>
      <PatientReviewSuggeston answers={review?.comment?.answers} />
    </Flex>
  );
};
