import { Heading } from '@abyss/web/ui/Heading';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Constants } from '../../../../common/Constants';
import { PhoneNumbers } from '../../../../models/ProviderDetails';
import {
  getFormattedPhoneNumber,
  getPrimaryPhone,
} from '../../../../utils/phone.utils';
import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import {
  CallContainer,
  callPhoneStyle,
  ttyStyle,
} from '../ProviderLocation.style';

type Props = {
  phoneNumbers?: PhoneNumbers | null;
};

export const ByPhoneScheduling = ({ phoneNumbers }: Props) => {
  const { t } = useTranslation();
  const schedulingPhoneDataTestId = 'scheduling-by-phone';
  const { phone, appointment } = phoneNumbers ?? {};
  const primaryPhones = getPrimaryPhone(phone, appointment);
  const phoneNumber = `${t('SCHEDULE_MODAL.CALL')} ${getFormattedPhoneNumber(
    primaryPhones
  )}`;
  const { TTY: ttyText } = Constants.PROVIDER_DETAILS.LOCATIONS_TAB;
  const onClick = (event, name) => {
    adobeLinkTrackEvent({
      name,
      location: `body:${schedulingPhoneDataTestId}`,
      type: 'phone',
    });
  };

  return (
    <Layout.Stack
      alignItems="left"
      data-auto-testid={schedulingPhoneDataTestId}
      data-testid={schedulingPhoneDataTestId}
    >
      <Heading
        css={{
          '@screen < $md': {
            '&.abyss-heading-root': {
              fontSize: '16px !important',
            },
          },
        }}
        data-auto-testid="byPhone-scheduling-header"
        data-testid="byPhone-scheduling-header"
        display="h6"
        offset={1}
      >
        {t('SCHEDULE_MODAL.BYPHONE')}
      </Heading>
      <CallContainer>
        <IconMaterial
          color="#196ECF"
          data-testid="call-icon"
          icon="call"
          size={24}
        />
        <div>
          <a
            area-role="link"
            data-auto-testid="phone-scheduling"
            data-testid="phone-scheduling"
            href={`tel:${primaryPhones}`}
            onClick={(event) => onClick(event, 'phone primary')}
            style={callPhoneStyle}
          >
            {phoneNumber}
            <span style={ttyStyle}>{ttyText}</span>
          </a>
        </div>
      </CallContainer>
    </Layout.Stack>
  );
};
