import { event } from '@abyss/web/tools/event';

export const adobeSearchModifiedEvent = (input) => {
  const { customAttributesBlock } = input;

  event('SEARCH_MODIFIED_EVENT', {
    search: {
      term: input?.term?.toLowerCase() ?? '',
      type: 'provider',
      filters: input?.filters ?? '',
      results: String(input?.numberOfResults) ?? '',
      method: input?.method?.toLowerCase() ?? '',
    },
    link: {
      name: input?.linkName?.toLowerCase().replaceAll('-', ' '),
    },
    component: {
      name: '',
    },
    ...(customAttributesBlock && { customAttributes: customAttributesBlock }),
  });
};
