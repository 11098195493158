import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import {
  convertUrlParamsToObject,
  getOnlyDefinedValuesFromObject,
} from '../../common/Utils';
import { PCPSearchState, PCPSearchStore, StoreKeys } from './pcpStore';

const store = (set) => ({
  [StoreKeys.PCP_SEARCH_STATE]: {},
  [StoreKeys.SET_PCP_SEARCH_STATE]: (values: PCPSearchState) =>
    set((state) => ({
      pcpSearchState: { ...state.pcpSearchState, ...values },
    })),
  [StoreKeys.CLEAR_PCP_SEARCH_STATE]: (defaultValues = {}) =>
    set({ pcpSearchState: { ...defaultValues } }),
});

const pcpStore = persist(store, {
  name: 'pcpStore',
  storage: createJSONStorage(() => sessionStorage),
  merge: (
    persistedState: PCPSearchStore,
    currentState: PCPSearchStore
  ): PCPSearchStore => {
    const urlParams = new URLSearchParams(window.location.search);
    const {
      choosePCP,
      choosePCPId,
      dependentSeqNbr,
      pcpIndicator,
      selectedProviderType,
      formattedProviderName,
      providerType,
      selectedLocation,
      primarySpeciality,
      imageLocation,
      pcpEffectiveDate,
    }: any = convertUrlParamsToObject(urlParams.entries());

    const values = getOnlyDefinedValuesFromObject({
      choosePCP,
      choosePCPId,
      dependentSeqNbr,
      pcpIndicator,
      selectedProviderType,
      formattedProviderName,
      providerType,
      selectedLocation,
      primarySpeciality,
      imageLocation,
      pcpEffectiveDate,
    });

    return {
      ...currentState,
      ...persistedState,
      pcpSearchState: {
        ...currentState?.pcpSearchState,
        ...persistedState?.pcpSearchState,
        ...values,
      },
    };
  },
}) as any;

export const usePCPStore = create(devtools(pcpStore));
