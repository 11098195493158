import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Markdown } from '@abyss/web/ui/Markdown';
import { Modal } from '@abyss/web/ui/Modal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { returnSuppressFlag } from '../../utils/featureSuppress';
import { Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import { ModalWithTracking } from '../ModalWithTracking/ModalWithTracking';
import {
  StyledDisclaimerText,
  StyledIcon,
  disclaimerLinkStyles,
} from './DisclaimersContainer.styled';

const LinkOverride = ({ children, ...props }: any) => (
  <span {...props}>{children}</span>
);

export const HealthGradesDisclaimers = ({
  modalContent,
  disclaimerContent,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const mobile = useMediaQuery(phoneOnly);

  const suppressHealthGradesDisclaimer = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );

  if (!suppressHealthGradesDisclaimer) {
    return (
      <Layout.Stack alignItems="left" space="0">
        <StyledIcon
          icon="healthgrades"
          isScreenReadable
          size={`${mobile ? 132 : 165}`}
          title={t('HEALTH_GRADES_LOGO_TITLE')}
        />
        <StyledDisclaimerText data-testid="patient-reviews-disclaimer">
          {`${disclaimerContent} ` ?? ''}
          <Link
            css={disclaimerLinkStyles}
            data-auto-testid="healthgrades-link-text"
            data-testid="healthgrades-link-text"
            href={null}
            onClick={(e) => {
              e.preventDefault();
              setIsOpen(true);
            }}
          >
            {t('HEALTHGARDE_DISCLAIMER.LINK_TEXT')}
            <IconMaterial icon="chevron_right" size="$xs" />
          </Link>
        </StyledDisclaimerText>
        <ModalWithTracking
          analyticsInfo={{
            linkName: 'healthgrades disclaimer',
            modalName: 'healthgrades disclaimer',
            location: `body:healthgrades`,
          }}
          data-auto-testid="healthgrades-modal"
          data-testid="healthgrades-modal"
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          title={`${t('HEALTHGARDE_DISCLAIMER.HEALTHGRADES_INFORMATION')}`}
          trackingType={Constants.ADOBE_TRACKING.MODAL_WITH_LINK_TRACKING}
        >
          <Modal.Section>
            <Markdown
              options={{
                overrides: {
                  Link: {
                    component: Link,
                  },
                  a: {
                    component: LinkOverride,
                    props: { href: null, target: null },
                  },
                },
              }}
            >
              {modalContent}
            </Markdown>
          </Modal.Section>
        </ModalWithTracking>
      </Layout.Stack>
    );
  }

  return '';
};
