import { event } from '@abyss/web/tools/event';

export const adobeFuncErrorEvent = (input) => {
  event('FUNC_ERROR_EVENT', {
    error: {
      message: input.message?.toLowerCase().replaceAll('-', ' ') ?? '',
      source: input.source?.toLowerCase() ?? '',
      code: input.code,
      subtype: '',
    },
    impression: {
      type: 'error',
      uiSection: input.uiSection?.toLowerCase().replaceAll('-', ' ') ?? '',
    },
  });
};
