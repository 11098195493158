import { styled } from '@abyss/web/tools/styled';
import { Box } from '@abyss/web/ui/Box';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { CheckboxGroup } from '@abyss/web/ui/CheckboxGroup';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';

export const SuggestEditProviderCardContainer = styled('div', {
  '@screen < $sm': {
    marginRight: '4px',
  },

  '.abyss-card-root': {
    boxShadow: '0px 2px 6px rgba(25, 25, 26, 0.16)',
    border: '1px solid #E5E5E6',
    borderRadius: '8px',
  },

  '.abyss-card-section': {
    borderRadius: '12px',
    backgroundColor: '$white',
    minWidth: '217px',
    padding: '16px 12px',

    '@screen < $sm': {
      padding: '12px 0 12px 16px',
      width: '212px',
    },
  },
});

export const SuggestEditProviderAvatar = styled(Flex, {
  width: '48px',
  height: '48px',
  minWidth: '48px',
  minHeight: '48px',
});

export const SuggestEditProviderName = styled(Text, {
  fontWeight: '$bold',
});

export const SuggestEditProviderSpeciality = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '12.64px',
    lineHeight: '16px',
  },
});

export const SuggestEditChooseLocationContainer = styled(Flex, {
  justifyContent: 'space-between',
  flexDirection: 'column',
  width: '100%',
  height: 'fit-content',
  marginBottom: '30px',
});

export const SuggestEditNextBoxContainer = styled(Box, {
  '&.abyss-box-root': {
    Padding: '16px, 24px, 16px, 24px',
    backgroundColor: '$white',
  },
});

export const SuggestEditLocationText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '$sm',
    color: '$interactive1',
    fontWeight: '$semibold',
    position: 'relative',
    marginTop: '$md',
  },
});
export const SuggestEditCheckBoxGroup = styled(CheckboxGroup, {
  '&.abyss-checkbox-group-root': {
    width: '100%',
  },
});

export const CheckBoxContainer = styled('div', {
  backgroundColor: '$gray2',
});

export const SuggestEditCheckBox = styled(Checkbox, {
  '&.abyss-checkbox-label': {
    fontSize: '$sm',
    lineHeight: '18px',
    color: '$gray8',
    fontWeight: '$medium',
  },
  '&.abyss-checkbox-root': {
    padding: '6px 8px',
    marginTop: 0,
  },
});
export const NextButtonSection = styled('div', {
  display: 'flex',
  gap: '4px',
  overflow: 'auto',
  direction: 'row',
  justify: 'center',
  alignItems: 'center',
  width: '100px',
  height: '42px',
});
