import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ProviderType } from '../../models/ProviderDetails';

const TitleContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

const TitleStyle = styled('span', {
  marginRight: '10px',
  fontSize: '12.64px',
  fontWeight: '700',
  lineHeight: '16px',
  color: '#323334',
});

const TitleBorder = styled('hr', {
  flexGrow: 1,
  border: 'none',
  borderTop: '1px solid #CBCCCD',
});

const mockArray = [
  {
    current: 'Lauren Ebre',
    correction: 'Lauren Evt',
    id: 1,
    name: "Provider's name",
  },
  {
    current: 'In-network',
    correction: 'Out-of-Network',
    id: 2,
    name: 'In-network status',
  },
  {
    current: '555 Oak Street N.E. San Fransico, CA, 55555',
    correction: '325 Dale St, San Fransico, CA, 54555',
    id: 3,
    name: 'Address',
  },
  {
    current: 'edwardlbare.com',
    correction: 'edwardlabreclick.com',
    id: 4,
    name: 'Website',
  },
];
const mockFacilityArray = [
  {
    current: 'Fair View Health',
    correction: 'Fairview Health',
    id: 1,
    name: 'Facility name',
    modified: true,
  },
  {
    current: 'Out-of-Network',
    correction: 'In-network',
    id: 2,
    name: 'In-network status',
    modified: true,
  },
  {
    current: '555 Oak Street N.E. San Fransico, CA, 55555',
    correction: '325 Dale St, San Fransico, CA, 54555',
    id: 3,
    name: 'Address',
    modified: true,
  },
  {
    current: [
      { day: 'Mon', hours: '8 am - 6pm' },
      { day: 'Tues', hours: '8 am - 6pm' },
      { day: 'Wed', hours: '8 am - 6pm' },
      { day: 'Thurs', hours: '8 am - 6pm' },
      { day: 'Fri', hours: '8 am - 6pm' },
      { day: 'Sat', hours: 'Closed' },
      { day: 'Sun', hours: 'Closed' },
    ],
    correction: [
      { day: 'Mon', hours: '8 am - 6pm' },
      { day: 'Tues', hours: '8 am - 6pm' },
      { day: 'Wed', hours: '8 am - 6pm' },
      { day: 'Thurs', hours: '8 am - 6pm' },
      { day: 'Fri', hours: '8 am - 6pm' },
      { day: 'Sat', hours: 'Closed' },
      { day: 'Sun', hours: 'Closed' },
    ],
    id: 4,
    name: 'Hours',
    modified: false,
  },
  {
    current: '612-555-5555',
    correction: '612-555-5555',
    id: 5,
    name: 'Phone Number',
    modified: false,
  },
  {
    current: 'fairview.com',
    correction: 'fairviewhealth.com',
    id: 6,
    name: 'Website',
    modified: true,
  },
  {
    current: 'Accepting new patients',
    correction: 'Not accepting new patients',
    id: 7,
    name: 'Accepting patient status',
    modified: true,
  },
  {
    current: 'Open',
    correction: 'Open',
    id: 8,
    name: 'Open for business',
    modified: false,
  },
];

export type Props = {
  providerType: ProviderType;
};
export const ConfirmSuggestions = ({ providerType }: Props) => {
  const { t } = useTranslation();
  const currentArray =
    providerType === ProviderType.ORGANIZATION ? mockFacilityArray : mockArray;
  return (
    <Flex
      alignContent="flex-start"
      css={{
        'abyss-flex-root': {
          padding: 0,
          flexWrap: 'nowrap',
        },
      }}
      direction="column"
    >
      <Flex
        alignContent="flex-start"
        css={{
          'abyss-flex-root': {
            gap: '24px',
            padding: 0,
            width: '100%',
            alignSelf: 'stretch',
            flexWrap: 'nowrap',
            marginBottom: '12px',
            marginTop: '24px',
          },
        }}
        direction="row"
      >
        <Flex
          alignContent="flex-start"
          css={{
            'abyss-flex-root': {
              gap: '8px',
              width: '50%',
            },
          }}
          direction="column"
        >
          <Text
            color="#6E7072"
            css={{
              'abyss-text-root': {
                fontSize: '12.64px',
                fontWeight: '$medium',
                fontStyle: 'normal',
                lineHeight: '16px',
                textTransform: 'uppercase',
              },
            }}
          >
            {t('SUGGEST_EDIT.CURRENT')}
          </Text>
        </Flex>
        <Flex
          alignContent="flex-start"
          css={{
            'abyss-flex-root': {
              flexgrow: '1',
              gap: '8px',
              width: '50%',
            },
          }}
          direction="column"
        >
          <Text
            color="#6E7072"
            css={{
              'abyss-text-root': {
                fontSize: '12.64px',
                fontWeight: '$medium',
                lineHeight: '16px',
                fontStyle: 'normal',
                textTransform: 'uppercase',
              },
            }}
          >
            {t('SUGGEST_EDIT.YOUR_CORRECTION')}
          </Text>
        </Flex>
      </Flex>

      {currentArray?.map((item) => (
        <Flex
          alignContent="flex-start"
          css={{
            'abys-flex-root': {
              flexWrap: 'wrap',
            },
          }}
          direction="row"
        >
          <Flex
            alignContent="flex-start"
            css={{
              'abyss-flex-root': {
                gap: '12px',
                padding: 0,
                width: '100%',
                marginBottom: '24px',
              },
            }}
            direction="column"
          >
            <TitleContainer>
              <TitleStyle>{item.name}</TitleStyle>
              <TitleBorder />
            </TitleContainer>
            <Flex
              alignContent="flex-start"
              css={{
                'abyss-flex-root': {
                  gap: '24px',
                  padding: 0,
                  width: '100%',
                  alignSelf: 'stretch',
                  flexWrap: 'nowrap',
                },
              }}
              direction="row"
            >
              <Flex
                alignContent="flex-start"
                css={{
                  'abyss-flex-root': {
                    gap: '8px',
                    width: '50%',
                  },
                }}
                direction="column"
              >
                {item.name === 'Hours' ? (
                  item.current.map((val) => (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '80%',
                      }}
                    >
                      <Text
                        color="$gray7"
                        css={{
                          'abyss-text-root': {
                            fontSize: '14.22px',
                            fontWeight: '$semibold',
                            fontStyle: 'normal',
                            lineHeight: '20px',
                          },
                        }}
                      >
                        {val.day}:
                      </Text>
                      <Text
                        color="$gray7"
                        css={{
                          'abyss-text-root': {
                            fontSize: '14.22px',
                            fontWeight: '$semibold',
                            fontStyle: 'normal',
                            lineHeight: '20px',
                          },
                        }}
                      >
                        {val.hours}
                      </Text>
                    </div>
                  ))
                ) : (
                  <React.Fragment>
                    <Text
                      color="$gray7"
                      css={{
                        'abyss-text-root': {
                          fontSize: '14.22px',
                          fontWeight: '$semibold',
                          fontStyle: 'normal',
                          lineHeight: '20px',
                        },
                      }}
                    >
                      {item.current}
                    </Text>
                  </React.Fragment>
                )}
              </Flex>
              <Flex
                alignContent="flex-start"
                css={{
                  'abyss-flex-root': {
                    flexgrow: '1',
                    gap: '8px',
                    width: '50%',
                  },
                }}
                direction="column"
              >
                {item.name === 'Hours' ? (
                  item.current.map((val) => (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '80%',
                      }}
                    >
                      <Text
                        color={item.modified ? '#$primary1' : '#323334'}
                        css={{
                          'abyss-text-root': {
                            fontSize: '14.22px',
                            lineHeight: '20px',
                            fontStyle: 'normal',
                          },
                        }}
                      >
                        {val.day}:
                      </Text>
                      <Text
                        color={item.modified ? '$primary1' : '#323334'}
                        css={{
                          'abyss-text-root': {
                            fontSize: '14.22px',
                            lineHeight: '20px',
                            fontStyle: 'normal',
                          },
                        }}
                      >
                        {val.hours}
                      </Text>
                    </div>
                  ))
                ) : (
                  <React.Fragment>
                    <Text
                      color={item.modified ? '$primary1' : '#323334'}
                      css={{
                        'abyss-text-root': {
                          fontSize: '14.22px',
                          lineHeight: '20px',
                          fontStyle: 'normal',
                        },
                      }}
                    >
                      {item.correction}
                    </Text>
                  </React.Fragment>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      ))}
    </Flex>
  );
};
