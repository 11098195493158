import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import React from 'react';

import { LinkWithTracking } from '../LinkWithTracking/LinkWithTracking';

type AnalyticsInfo = {
  destinationUrl?: string;
  location: string;
  name?: string;
  type?: string;
};

type Props = {
  analyticsInfo?: AnalyticsInfo;
  'aria-label'?: string;
  css?: any;
  'data-auto-testid': string;
  'data-testid': string;
  fontWeight?: string;
  href?: string | null;
  icon?: string;
  iconSize?: string;
  onClick: () => any;
  size?: number | string;
  text: any;
};

export const LinkContainer = ({
  analyticsInfo,
  'aria-label': ariaLabel = '',
  css,
  'data-testid': testId = '',
  'data-auto-testid': dataAutoTestId = '',
  fontWeight = '$bold',
  href = null,
  icon = 'chevron_right',
  iconSize = '$xs',
  onClick,
  size = 12.64,
  text,
}: Props) => (
  <React.Fragment>
    <LinkWithTracking
      after={
        <IconMaterial
          data-auto-testid={testId}
          data-testid={testId}
          icon={icon}
          size={iconSize}
        />
      }
      analyticsInfo={analyticsInfo}
      aria-label={ariaLabel}
      css={css}
      data-auto-testid={dataAutoTestId}
      data-testid={testId}
      fontWeight={fontWeight}
      href={href}
      isStandardAnchor
      onClick={onClick}
      size={size}
    >
      {text}
    </LinkWithTracking>
  </React.Fragment>
);
