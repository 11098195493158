import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';

export const VideoIcon = styled('div', {
  backgroundColor: '$primary1',
  padding: '2px',
  borderRadius: '25px',
  display: 'flex',
  justifyContent: 'center',
});
export const HeaderContainer = styled('div', {
  paddingBottom: '24px',
  paddingLeft: '84px',
  paddingRight: '84px',
  border: '1px solid $gray3',
  '@screen < $sm': {
    paddingLeft: '$md',
    paddingRight: '$md',
    paddingBottom: '$md',
  },
});

export const DetailsSection = styled('div', {
  marginTop: '$md',
});

export const Heading = styled('div', {
  width: '338px',
  height: '32px',
  fontWeight: '$bold',
  fontSize: '25.63px',
  lineHeight: '32px',
  color: '$primary1',
  marginBottom: '12px',
  '@screen < $sm': {
    height: '24px',
    fontSize: '20.25px',
    lineHeight: '24px',
    marginBottom: 'initial',
  },
});

export const SectionContainer = styled(Flex, {
  '@screen < $md': {
    padding: '0',
  },
});

export const VirtualProviderContent = styled('div', {
  borderWidth: '1px 0',
  paddingTop: '12px',
  paddingBottom: '20px',
  borderStyle: 'solid',
  borderColor: '$cardBorder',

  marginTop: '8px',
  paddingLeft: 'calc((100% - 1200px) / 2)',
  paddingRight: 'calc((100% - 1200px) / 2)',
  '&>:not(:last-child)': {
    marginBottom: '20px',
  },
  '@screen < $md': {
    padding: '$md',
  },
});

export const Dot = styled('div', {
  height: '5px',
  width: '5px',
  margin: '0 10px',
  backgroundColor: '$gray5',
  borderRadius: '15px',
});
export const InteractButton = styled(Button, {
  'abyss-button-root': {
    marginTop: '16px',
    '@screen < $sm': {
      padding: '24px 6px 16px !important',
    },
  },
  overflow: 'visible',
  '.abyss-insert-element-root': {
    fontWeight: '$semibold',
    color: '$primary1 !important',
  },
  '.abyss-insert-element-after': {
    paddingLeft: '2px !important',
  },
});

export const AvatarDetailsPage = styled('div', {
  img: {
    width: '303px',
    marginLeft: '-24px',
    marginBottom: '-24px',
    '@screen < $sm': {
      width: '250px',
      marginLeft: '-20px',
      marginBottom: '-18px',
    },
  },
  marginBottom: '16px',
  '@screen < $sm': {
    marginBottom: '10px',
  },
});
