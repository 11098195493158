import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { ProgressBar } from '@abyss/web/ui/ProgressBar';
import { Text } from '@abyss/web/ui/Text';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'usehooks-ts';

import { phoneOnly } from '../../../../common/ConstantsStyles';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { RatingReviewsPercentageSection } from './PatientReviews.styled';

type Props = {
  healthgradesReview: any;
};

export const PatientRatingReviewsPercentage = ({
  healthgradesReview,
}: Props) => {
  if (!healthgradesReview) return null;
  const {
    fiveStarCount,
    fourStarCount,
    threeStarCount,
    twoStarCount,
    oneStarCount,
  } = healthgradesReview?.aggregatedSurvey;
  const totalRating =
    fiveStarCount +
    fourStarCount +
    threeStarCount +
    twoStarCount +
    oneStarCount;
  const fiveStarPercentage = Math.round((fiveStarCount / totalRating) * 100);
  const fourStarPercentage = Math.round((fourStarCount / totalRating) * 100);
  const threeStarPercentage = Math.round((threeStarCount / totalRating) * 100);
  const twoStarPercentage = Math.round((twoStarCount / totalRating) * 100);
  const oneStarPercentage = Math.round((oneStarCount / totalRating) * 100);
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const mobileScreen = useMediaQuery(phoneOnly);

  const { t } = useTranslation();
  const stars = [
    { star: 5, percentage: fiveStarPercentage },
    { star: 4, percentage: fourStarPercentage },
    { star: 3, percentage: threeStarPercentage },
    { star: 2, percentage: twoStarPercentage },
    { star: 1, percentage: oneStarPercentage },
  ];
  return (
    <RatingReviewsPercentageSection
      alignItems="flex-start"
      justify="flex-start"
    >
      <Grid
        css={{
          flex: healthgradesReview.providerExperienceRating ? 'unset' : 1,
        }}
      >
        <Grid.Col
          span={
            isWidget
              ? '75%'
              : { xs: '100%', sm: '100%', md: '65%', lg: '55%', xl: '43%' }
          }
        >
          {stars.map((star) => (
            <Flex
              direction="row"
              justify="space-between"
              key={star.star}
              style={{ marginBottom: '8px' }}
            >
              <Text
                fontWeight="700"
                size="sm"
                style={{
                  width: mobileScreen ? '22%' : isWidget ? '18%' : '15%',
                }}
              >
                {star.star}{' '}
                {t('PROVIDER_DETAILS.ABOUT_TAB.REVIEW_SECTION.stars')}
              </Text>
              <div
                style={{
                  marginTop: '1px',
                  width: mobileScreen ? '70%' : isWidget ? '75%' : '78%',
                }}
              >
                <ProgressBar
                  fillColor="$secondary2"
                  height="14px"
                  percentage={star.percentage}
                />
              </div>
              <Text
                fontWeight="700"
                size="sm"
                style={{
                  width: '5%',
                  textAlign: 'right',
                }}
              >
                {star.percentage ? star.percentage : 0}%
              </Text>
            </Flex>
          ))}
        </Grid.Col>
      </Grid>
    </RatingReviewsPercentageSection>
  );
};
