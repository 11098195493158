import React from 'react';

import { LocateMe } from './LocateMe';
import { MapControlContainer } from './MapDisplay.styled';

type Props = {
  map: any;
  controlChildren?: React.ReactNode;
  mobileRouteView?: boolean;
};

export const MapControlMobile = ({
  map,
  controlChildren,
  mobileRouteView = false,
}: Props) => (
  <MapControlContainer
    alignItems="end"
    css={{ top: mobileRouteView ? '260px' : '$md' }}
    direction="column"
  >
    {controlChildren}
    <LocateMe map={map} />
  </MapControlContainer>
);
