import { styled } from '@abyss/web/tools/styled';

const baseFontSize = '14.22px';

export const CostIndicatorDrawerStyles = {
  'abyss-modal-content-container': {
    borderRadius: '20px 20px 0 0',
    height: 'auto !important',
  },
  'abyss-modal-body': {
    margin: '16px 24px 16px 24px',
    color: '$gray8',
    fontSize: '16px',
    fontWeight: '500',
  },
  'abyss-modal-close-button': { margin: '8px 10px 0 0' },
  'abyss-modal-content-title': {
    paddingTop: '2px',
    fontFamily: '$primary !important',
    fontSize: '20.25px !important',
    color: '#323334 !important',
    lineHeight: '24px !important',
    fontWeight: '700 !important',
  },
  'abyss-modal-header-container': {
    padding: '16px 24px 0px 24px',
  },
};

export const CostIndicatorContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  boxSizing: 'border-box',
  alignItems: 'center',
  gap: '4px',
  '@screen < $sm': {
    gap: '2px',
  },
});

export const CostButton = styled('div', {
  display: 'flex',
});

export const IconMaterialWeb = {
  'abyss-icon': {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginBottom: '1px',
  },
};

export const StyledNoCostIndicatorDiv = styled('div', {
  lineHeight: '16px',
  fontSize: baseFontSize,
  fontStyle: 'italic',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  marginTop: '4px',
  '@screen < $sm': {
    width: '162px',
  },
});
