import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  ResultsContainerStyle,
  StyledResultsContainer,
  styledIcon,
  textContentStyles,
} from '../PhysicianDirectory.styled';
import { SortPopover } from '../SortPopover';

type Props = {
  showPhysicianSpecialities: boolean;
  totalPhysicanCount: any;
  providerNameToSearch: any;
  physicianList: any;
  setSortOrder: any;
  sortOrder: any;
};
const NameSearchText = ({
  totalPhysicanCount,
  showPhysicianSpecialities,
  providerNameToSearch,
  physicianList,
  setSortOrder,
  sortOrder,
}: Props) => {
  const { t } = useTranslation();
  return (
    <StyledResultsContainer
      css={{
        marginBottom:
          totalPhysicanCount === '0' && showPhysicianSpecialities ? '' : '$md',
      }}
    >
      <ResultsContainerStyle>
        {totalPhysicanCount === '0' && showPhysicianSpecialities ? null : (
          <IconMaterial
            color="$success1"
            css={styledIcon}
            icon="check_circle"
          />
        )}
        <Text
          color="$neutralGray7"
          css={textContentStyles}
          fontWeight={500}
          size="$lg"
        >
          {totalPhysicanCount === '0' && showPhysicianSpecialities
            ? `${t(
                'FACILITY_DETAILS.PHYSICIAN_DIRECTORY_TAB.NO_PROVIDERS_FOUND'
              )}`
            : `${totalPhysicanCount} ${t(
                'PROVIDER_DETAILS.PHYSICIAN_TAB.IN_NETWORK_PROVIDERS'
              )}`}
          <Text color="$neutralGray7" fontWeight={700} size="$lg">
            {totalPhysicanCount === '0' && showPhysicianSpecialities
              ? providerNameToSearch
              : null}
          </Text>
        </Text>
      </ResultsContainerStyle>
      {physicianList?.length >= 2 && (
        <SortPopover setSortOrder={setSortOrder} sortOrder={sortOrder} />
      )}
    </StyledResultsContainer>
  );
};

export default NameSearchText;
