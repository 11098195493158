import { styled } from '@abyss/web/tools/styled';

export const suggestEditProviderCardStyle = {
  'abyss-flex-root': {
    alignSelf: 'stretch',
    border: '1px solid $neutralGray2',
    boxShadow: '0px 2px 6px rgba(25, 25, 26, 0.16)',
    borderRadius: '12px',
    background: '#FFFFFF',
    boxSizing: 'border-box',
    gap: '8px',
    padding: '12px 0px',
    width: '100%',
  },
};

export const dropdownCss = {
  'abyss-dropdown-menu-content': {
    padding: '$md 12px',
    border: '1px solid #D0D0D0',
    borderRadius: '5px',
    width: '300px',
  },
  'abyss-dropdown-menu-trigger': {
    color: '$info1',
    padding: '0',
    marginTop: '$md',
    width: '157px',
    height: 'auto',
  },
  'abyss-dropdown-menu-item': {
    height: '70px',
    marginLeft: '12px',
  },
  'abyss-insert-element-root': {
    justifyContent: 'flex-start',
    fontSize: '$extraSmallText',
  },
};

export const PopoverContentSelected = {
  'abyss-radio-root': { marginLeft: '10px', marginTop: '10px' },
  'abyss-radio-label': {
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#323334',
    order: '1',
    alignSelf: 'stretch',
    marginBottom: '10px',
    whiteSpace: 'pre-line',
  },
};

export const PopoverContent = {
  'abyss-radio': {
    borderColor: '#196ECF',
  },
  'abyss-radio-root': { marginLeft: '10px', marginTop: '10px' },
  'abyss-radio-label': {
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '20px',
    color: '#4B4D4F',
    order: '1',
    alignSelf: 'stretch',
    marginBottom: '10px',
    whiteSpace: 'pre-line',
  },
};

export const LocationBox = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  width: '157px',
  borderRadius: '4px',
});

export const LocationsDropdownOption = styled('div', {
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '$extraSmallText',
  lineHeight: '16px',
  color: '$info1',
  textAlign: 'left',
  marginTop: '8px',
  marginBottom: '8px',
});
