import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import React, { MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { FacilityLocationsResponse } from '../../models/FacilityDetails';
import { ProviderLocation } from '../../models/ProviderDetails';
import { getDistance } from '../../utils/card.utils';
import {
  getAcceptingNewPatientsStatus,
  shortenZipCode,
} from '../../utils/providerDetails.utils';
import { capitalizeFirstLetters } from '../../utils/providerNames.utils';
import { getCurrentMember } from '../../utils/user.utils';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import {
  BenefitsBadge,
  BenefitsBadgeType,
} from '../DetailsPageHeader/BenefitsBadge';
import {
  AcceptingNewPatientsBadge,
  LocationBadgeRow,
  LocationCardGroup,
  LocationCardWrapper,
  LocationDetailsAddress,
  LocationDetailsStack,
  MilesButton,
  PreferredBadgeWrapper,
  StyledCheckIcon,
  UpdateButtonMobileView,
  UpdateButtonMobileViewContainer,
} from './MapViewSelectLocationCard.style';

export type Props = {
  className?: string;
  location: ProviderLocation | FacilityLocationsResponse;
  selectLocation: () => void;
  highlightRefs: MutableRefObject<any>;
  handleDirectionClick: () => void;
  selected?: boolean;
  setIsMapView: (isMapView: boolean) => void;
  showBadgeRow?: boolean;
  showRadioSelect?: boolean;
  handleUpdateLocation: () => void;
};

export const MapViewSelectLocationCard = ({
  location,
  showBadgeRow,
  showRadioSelect,
  selected,
  selectLocation,
  highlightRefs,
  handleDirectionClick,
  className = '',
  setIsMapView,
  handleUpdateLocation,
}: Props) => {
  const phoneScreen = useMediaQuery(phoneOnly);
  const { t } = useTranslation();
  const {
    acceptingNewPatients,
    address,
    distance,
    isGreenDiamondProvider,
    locationId,
    providerGroupsAffiliations,
  } = location;
  const { city, line, state, postalCode } = address;

  const [includeProviderGroupName] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.INCLUDE_PROVIDER_GROUP_NAME,
  ]);

  const currentMember = getCurrentMember();
  const isCns = currentMember?.isCnSMember;
  const displayCityOrState = includeProviderGroupName ? city : state;
  const addressString = `${line.join(
    ', '
  )}, ${displayCityOrState}, ${shortenZipCode(postalCode)}`;

  const wrapperSelectedStyle = {
    border: '3px solid $interactive2',
  };

  const { icon, acceptingPatientInfo } =
    getAcceptingNewPatientsStatus(acceptingNewPatients);

  return (
    <LocationCardWrapper
      aria-pressed={selected ? 'true' : 'false'}
      className={`location-card-wrap ${className}`}
      css={selected ? wrapperSelectedStyle : ''}
      data-auto-testid="select-location-card"
      data-testid="select-location-card"
      onClick={selectLocation}
    >
      {!phoneScreen && isGreenDiamondProvider && !isCns ? (
        <PreferredBadgeWrapper>
          <BenefitsBadge
            badgeType={BenefitsBadgeType.GREEN_DIAMOND}
            showInfoIcon={false}
          />
        </PreferredBadgeWrapper>
      ) : null}
      <LocationCardGroup>
        {showRadioSelect ? (
          <StyledCheckIcon>
            {selected ? (
              <IconMaterial
                data-auto-testid="icon-location-selected"
                data-testid="icon-location-selected"
                icon="check_circle"
                size={24}
              />
            ) : (
              <IconMaterial
                color="$neutralGray5"
                data-auto-testid="icon-location-unselected"
                data-testid="icon-location-unselected"
                icon="radio_button_unchecked"
                size={24}
              />
            )}
          </StyledCheckIcon>
        ) : null}
        <LocationDetailsStack
          alignItems="left"
          className="location-details"
          data-testid="location-details-stack"
          grow
        >
          {phoneScreen && isGreenDiamondProvider && !isCns ? (
            <PreferredBadgeWrapper>
              <BenefitsBadge
                badgeType={BenefitsBadgeType.GREEN_DIAMOND}
                showInfoIcon={false}
              />
            </PreferredBadgeWrapper>
          ) : null}
          {includeProviderGroupName && providerGroupsAffiliations?.[0] && (
            <LocationDetailsAddress
              color="#4B4D4F"
              data-testid="location-provider-group"
              fontWeight={selected ? '$bold' : '$medium'}
            >
              {capitalizeFirstLetters(providerGroupsAffiliations[0]?.value)}
            </LocationDetailsAddress>
          )}
          <LocationDetailsAddress
            aria-label={addressString}
            color="#4B4D4F"
            fontWeight={'$medium'}
          >
            {addressString}
          </LocationDetailsAddress>
          {showBadgeRow ? (
            <LocationBadgeRow
              data-auto-testid="select-location-badge-row"
              data-testid="select-location-badge-row"
              direction="row"
              justify="space-between"
            >
              <MilesButton
                data-auto-testid="select-location-miles-button"
                data-testid="select-location-miles-button"
                onClick={(event) => {
                  event.stopPropagation();
                  handleDirectionClick();
                }}
                ref={(el: HTMLButtonElement) => {
                  if (highlightRefs) {
                    // eslint-disable-next-line no-param-reassign
                    highlightRefs.current[locationId] = el;
                  }
                }}
              >
                <IconMaterial icon="directions" size="$sm" />
                <Text
                  color="$interactive1"
                  css={{ 'abyss-text-root': { fontSize: '12.64px' } }}
                  fontWeight="$bold"
                >
                  {getDistance(distance)} {t('miles')}
                </Text>
              </MilesButton>
              <AcceptingNewPatientsBadge
                icon={<IconMaterial color="$grey8" icon={icon} size="sm" />}
                variant="neutral"
              >
                {t(acceptingPatientInfo)}
              </AcceptingNewPatientsBadge>
            </LocationBadgeRow>
          ) : null}
          <UpdateButtonMobileViewContainer
            css={{
              marginTop: providerGroupsAffiliations?.[0] ? '0px' : '28px',
            }}
          >
            {phoneScreen ? (
              <UpdateButtonMobileView
                data-auto-testid="choose-location"
                data-testid="choose-location"
                isDisabled={!selected}
                onClick={() => {
                  handleUpdateLocation();
                  setIsMapView(false);
                }}
              >
                {t('UPDATE_LOCATION')}
              </UpdateButtonMobileView>
            ) : null}
          </UpdateButtonMobileViewContainer>
        </LocationDetailsStack>
      </LocationCardGroup>
    </LocationCardWrapper>
  );
};
