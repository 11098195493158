import { styled } from '@abyss/web/tools/styled';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { SelectInput } from '@abyss/web/ui/SelectInput';

export const StyledSelectInput = styled(SelectInput, {
  '.abyss-select-input-label': {
    color: '$primary1',
  },
});

export const DemoProviderSearchContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  fontFamily: '$primary',
  backgroundColor: '$bgHeaderColor',
  paddingTop: '1em',
  paddingBottom: '1.1em',
  marginRight: '24px',
  '@screen < $md': {
    marginRight: '16px',
  },
});

export const CollapseIconContainer = styled('div', {
  position: 'relative',
});

export const CollapseIcon = styled(IconMaterial, {
  position: 'absolute',
  right: '24px',
  top: '-42px',
});
