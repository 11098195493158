import ContentLoader from 'react-content-loader';

interface ISkeletonContainerProps {
  children?: JSX.Element | JSX.Element[];
  height: number;
  testId?: string;
  width: number;
}

export const SkeletonContainer = ({
  width,
  height,
  children,
  testId,
}: ISkeletonContainerProps) => (
  <ContentLoader
    backgroundColor="#f3f3f3"
    data-testid={testId}
    foregroundColor="#ecebeb"
    height={height}
    speed={1}
    width={width}
  >
    {children}
  </ContentLoader>
);
