import { styled } from '@abyss/web/tools/styled';

export const popoverMobileStyle = {
  'abyss-modal-body': {
    color: '$neutralGray',
    '.abyss-modal-section-root': {
      padding: '16px 24px',
    },
  },
  'abyss-modal-close-button': { margin: '8px 10px 0 0' },
  'abyss-modal-header-container': {
    padding: '16px 24px 0px 24px',
    '.abyss-modal-content-title': {
      paddingTop: '2px',
      fontSize: '$mediumText',
      color: '$neutralGray7',
      lineHeight: '24px',
      fontWeight: '$bold',
    },
  },
  'abyss-modal-content-container': {
    height: 'auto !important',
    borderRadius: '20px 20px 0 0',
  },
  'abyss-modal-content-title': {
    fontFamily: '$primary !important',
  },
};

export const IconMaterialMobile = {
  'abyss-icon': {
    marginLeft: '2px',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
};

export const IconMaterialWeb = {
  'abyss-icon': {
    display: 'inline-block',
    verticalAlign: 'middle',
    marginBottom: '1px',
  },
};

export const MilesContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  boxSizing: 'border-box',
  alignItems: 'center',
  gap: '4px',
  '@screen < $sm': {
    gap: '2px',
  },
});
export const MobileMilesContainer = styled('button', {
  display: 'flex',
  flexWrap: 'wrap',
  boxSizing: 'border-box',
  alignItems: 'center',
  gap: '4px',
  '@screen < $sm': {
    gap: '2px',
  },
});
