import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { useTranslation } from 'react-i18next';

import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { phoneOnly } from '../ConstantsStyles';
import { ProviderIndicator } from './DetailsPageHeader.styled';

type Props = {
  inNetwork: string;
  iconColor?: string;
  inNetworkTextColor?: string;
};

export const InNetworkStatusLabel = ({
  inNetwork,
  iconColor = '$success1',
  inNetworkTextColor = '$primary1',
}: Props) => {
  const { t } = useTranslation();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const mobileScreen = useMediaQuery(phoneOnly) || isWidget;

  /**
   * Note:
   * There are only two possible values for inNetwork according to getNetworkStatus() utils Fn (Source of this data)
   *  INN = 'In-network',
   *  OON = 'Out-of-network',
   *
   * */
  if (inNetwork === 'In-network') {
    return (
      <Layout.Group
        css={{
          marginRight: '24px',
          '@screen < $sm': {
            marginRight: '16px',
          },
        }}
        space={0}
      >
        <IconMaterial
          color={iconColor}
          icon="check_circle"
          size={mobileScreen ? '20px' : '$md'}
        />
        <ProviderIndicator
          color={inNetworkTextColor}
          fontWeight="$medium"
          size={mobileScreen ? '$md' : '$lg'}
        >
          {t(inNetwork)}
        </ProviderIndicator>
      </Layout.Group>
    );
  }
  return null;
  // TODO: Else case when provider with selected location is OON = 'Out-of-network' OR Not Available. We need to know Figma or UX case details if any/
};
