import { RefObject } from 'react';

export const scrollWithRef = (
  listRef: RefObject<HTMLDivElement>,
  index: number
) => {
  if (listRef?.current?.children) {
    const listItems = (Array.from(listRef.current.children) as HTMLElement[])[
      index
    ];
    if (listItems) {
      listItems.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
};
