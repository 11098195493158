import { styled } from '@abyss/web/tools/styled';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { t } from 'i18next';
import React, { useState } from 'react';

import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../Constants';
import { ChangeButton } from './ChipsCategory.styles';

interface ChipObject {
  label: string;
  value: string;
  id: string;
  enabled: boolean;
}

type Props = {
  chips: ChipObject[];
  setCareCategory: Function;
  careCategory: string;
  onChipClicked(
    category: string,
    index: number,
    isResponsiveView: boolean
  ): void;
  setIsOpen: Function;
};

const RadioButtonsGroup = styled(RadioGroup, {
  '.abyss-radio-group-container': {
    paddingBottom: '20px',
  },
  '.abyss-radio-group-label': {
    display: 'none',
  },
});

const RadioInput = styled(RadioGroup.Radio, {
  '.abyss-radio': {
    borderColor: '$info1',
  },
  '.abyss-radio-label': {
    fontWeight: '$medium',
  },
  'input:checked ~ label': {
    fontWeight: '$bold',
  },
});

export const CategoryChipsDrawer = ({
  chips,
  setCareCategory,
  careCategory,
  onChipClicked,
  setIsOpen,
}: Props) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const handleChange = (value) => {
    const index = chips.findIndex((item) => item.value === value);
    setSelectedIndex(index);
    setCareCategory(value);
  };

  return (
    <React.Fragment>
      <RadioButtonsGroup
        data-auto-testid="chip-menu-container"
        data-testid="chip-menu-container"
        label={t(Constants.CHIPS_CATEGORIES.TYPES_OF_CARE)}
        onChange={(event) => {
          adobeLinkTrackEvent({
            name: event?.target.value,
            location: `modal:${Constants.CHIPS_CATEGORIES.TYPES_OF_CARE}`,
            type: 'radio',
          });
          handleChange(event?.target.value);
        }}
        value={careCategory}
      >
        {chips.map((item: ChipObject) => (
          <RadioInput
            data-auto-testid={`${item.id}-menu-chip`}
            data-testid={`${item.id}-menu-chip`}
            isDisabled={!item.enabled}
            key={`${item.id}-menu-chip`}
            label={t(item.value)}
            value={item.value}
          />
        ))}
      </RadioButtonsGroup>
      <ChangeButton
        data-auto-testid="change-button"
        data-testid="change-button"
        onClick={() => {
          const isResponsiveView: boolean = true;
          onChipClicked(careCategory, selectedIndex, isResponsiveView);
          setIsOpen(false);
        }}
      >
        {t(Constants.CHANGE)}
      </ChangeButton>
    </React.Fragment>
  );
};
