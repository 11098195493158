import isEmpty from 'lodash/isEmpty';

import { NULL_ATTRIBUTE_STATE } from '../../../../../utility/compareDrawerConstants';

export const isAddressPresent = (address) =>
  !isEmpty(address) && address.line[0] !== '';

export const formatAddress = (add) =>
  `${add?.line[0]}, ${add?.city}, ${add?.state}`;

export const getDisplayedAddressText = (address, isPresent) =>
  isPresent ? formatAddress(address) : NULL_ATTRIBUTE_STATE.ADDRESS;
