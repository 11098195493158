import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';
import { Markdown } from '@abyss/web/ui/Markdown';
import find from 'lodash/find';
import React from 'react';

import { useLagoon } from '../../hooks/useLagoon';
import { isOHCNSMember } from '../../utils/user.utils';
import { Constants } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { ContentWrapper } from '../ContentWrapper';
import { DisclaimerContainer } from '../ProviderDetailTabs/ProviderDetailTabs.styled';
import {
  CNSOHDisclaimerLinkStyles,
  HealthGradesStyled,
} from './DisclaimersContainer.styled';
import { HealthGradesDisclaimers } from './HealthGradesDisclaimers';

interface Props {
  sectionType?: string;
  search?: string;
}

export const CNSOHDisclaimersContainer = ({ sectionType, search }: Props) => {
  const lagoonData = useLagoon('ui-messaging')();
  const OHCNSpolicyIds = find(lagoonData, {
    key: ConstantsLagoon.UI_MESSAGING.STATE_SPECIFIC_DISCLAIMER,
  });

  const isOHCNS = isOHCNSMember(OHCNSpolicyIds?.policyIDs);

  const modalDisclaimerShort = find(lagoonData, {
    key: ConstantsLagoon.HEALTHGRADES.MODAL_DISCLAIMER_SHORT,
  });

  const modalDisclaimerFull = find(lagoonData, {
    key: ConstantsLagoon.HEALTHGRADES.MODAL_DISCLAIMER_FULL,
  });

  const renderHealthGradesDisclaimer = () => {
    const isFacility = sectionType === Constants.FACILITY;
    const isOnlineProvider =
      search?.toLocaleLowerCase() ===
      Constants.ONLINE_PROVIDERS.toLocaleLowerCase();

    if (isFacility && !isOnlineProvider) return null;
    return (
      <HealthGradesDisclaimers
        disclaimerContent={modalDisclaimerShort?.message}
        modalContent={modalDisclaimerFull?.message}
      />
    );
  };

  if (!isOHCNS) return null;

  return (
    <DisclaimerContainer>
      <HealthGradesStyled
        data-auto-testid="standard-disclaimer-content"
        data-testid="standard-disclaimer-content"
      >
        <ContentWrapper>
          <DisclaimerContainer cssProps={{ ratingSection: true }}>
            <Flex css={CNSOHDisclaimerLinkStyles} direction="column">
              <Markdown>{OHCNSpolicyIds?.message}</Markdown>
            </Flex>
          </DisclaimerContainer>
          <Layout.Stack alignItems="left" space="0">
            {renderHealthGradesDisclaimer()}
          </Layout.Stack>
        </ContentWrapper>
      </HealthGradesStyled>
    </DisclaimerContainer>
  );
};
