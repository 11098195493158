import { CostIndicators } from '../Constants';
import {
  AboveAverageTextColor,
  AverageTextColor,
  BelowAverageTextColor,
  DefaultTextColor,
  TextCostIndicator,
} from './CostIndicator.styles';

type Props = {
  indicatorData: string;
};

export const CostIndicator = ({ indicatorData }: Props) => {
  const getCostRateIndacators = (costIndicator: string) => {
    switch (costIndicator) {
      case CostIndicators.$:
        // First '$' in green ramaining '$' in gray color
        return (
          <div data-testid="cost-rate-indicator">
            <TextCostIndicator
              css={BelowAverageTextColor}
              data-testid="below-average-indicator"
            >
              $
            </TextCostIndicator>
            <TextCostIndicator
              css={DefaultTextColor}
              data-testid="default-average-indicator"
            >
              $$
            </TextCostIndicator>
          </div>
        );

      case CostIndicators.$$:
        // First and Second '$$' in blue ramaining '$' in gray color
        return (
          <div data-testid="cost-rate-indicator">
            <TextCostIndicator
              css={AverageTextColor}
              data-testid="average-indicator"
            >
              $$
            </TextCostIndicator>
            <TextCostIndicator
              css={DefaultTextColor}
              data-testid="default-average-indicator"
            >
              $
            </TextCostIndicator>
          </div>
        );

      case CostIndicators.$$$:
        // All the '$' in red color
        return (
          <div data-testid="cost-rate-indicator">
            <TextCostIndicator
              css={AboveAverageTextColor}
              data-testid="above-average-indicator"
            >
              $$$
            </TextCostIndicator>
          </div>
        );

      default:
        // All the '$' in gray color
        return (
          <div data-testid="cost-rate-indicator">
            <TextCostIndicator
              css={DefaultTextColor}
              data-testid="default-average-indicator"
            >
              $$$
            </TextCostIndicator>
          </div>
        );
    }
  };

  return getCostRateIndacators(indicatorData);
};
