import { styled } from '@abyss/web/tools/styled';
import { Alert } from '@abyss/web/ui/Alert';
import { Flex } from '@abyss/web/ui/Flex';
import { Layout } from '@abyss/web/ui/Layout';

export const CostTopSection = styled(Flex, {
  boxSizing: 'border-box',
  border: '1px solid $cardBorder',
  marginTop: '$sm',
  borderLeft: 'none',
  borderRight: 'none',
  padding: '$lg calc((100% - 1200px) / 2)',
  width: '100%',
  gap: '24px',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justify: 'flex-start',
  flex: 'none',
  order: '1',
  alignSelf: 'stretch',
  flexGrow: '0',
  '@media (max-width: 1248px)': {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  '@screen < $md': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '$md',
    gap: '8px',
    flex: 'none',
    order: '1',
    alignSelf: 'stretch',
    flexGrow: 0,
  },
});

export const CostHeader = {
  'abyss-heading-root': {
    fontSize: '20.25px !important',
    lineHeight: '24px !important',
    color: '$primary1 !important',
    fontWeight: '$bold',
    '@screen < $md': {
      fontSize: '$md !important',
      lineHeight: '20px !important',
      marginBottom: '0px !important',
    },
  },
};
export const InNetworkTextContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '4px',
});
export const SectionText = styled('p', {
  fontSize: '$lg',
  margin: '0px',
  padding: '0px',
  color: '$gray7',
  fontWeight: '$medium',
  '@screen < $md': {
    fontSize: '$md',
  },
});
export const InNetworkContainer = styled(Layout.Group, {
  flex: 1,

  '@screen < $md': {
    margin: '8px 0px!important',
    width: '100%',
  },
});

export const CommonProceduresAndCostContainer = styled('div', {
  padding: '$lg calc((100% - 1200px) / 2)',
  borderBottom: '1px solid $cardBorder',
  borderTop: '1px solid $cardBorder',
  gap: '24px',
  width: '100%',
  margin: '$sm 0',
  '@media (max-width: 1248px)': {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  '@screen < $md': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: '$cardBorder',
    padding: '$md',
    gap: '20px',
    flex: 'none',
    order: 4,
    alignSelf: 'stretch',
    flexGrow: 0,
  },
});

export const CommonCostProceduresHeader = {
  'abyss-heading-root': {
    maxWidth: '839px',
    fontSize: '25.63px !important',
    fontWeight: '$bold',
    lineHeight: '32px!important',
    '@screen < $md': {
      margin: '0px !important',
      lineHeight: '24px!important',
      fontWeight: '$bold',
      fontSize: '20.25px !important',
    },
  },
};
export const CommonCostProceduresTextSection = styled('div', {
  maxWidth: '839px',
  p: {
    fontSize: '$lg',
    margin: '0px',
    padding: '0px',
    color: '$gray7',
    fontWeight: '$medium',
    '@screen < $md': {
      fontSize: '$md',
    },
  },
  // margin: '$sm 0px',
});

export const CostEstimateNotificationAlert = styled(Alert, {
  '&.abyss-alert-root': {
    '.abyss-alert-container': {
      '.abyss-alert-icon-container': {
        width: '20px',
        height: '20px',
      },
      '.abyss-alert-title': {
        color: '$gray8',
        lineHeight: '20px',
      },
      '.abyss-alert-children-container': {
        color: '$gray8',
        lineHeight: '18px',
      },
    },
  },
});
