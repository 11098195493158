import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Box } from '@abyss/web/ui/Box';
import { Layout } from '@abyss/web/ui/Layout';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Text } from '@abyss/web/ui/Text';
import mapboxgl from 'mapbox-gl';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { CardSection } from '../../../../../common/CardSection/CardSection';
import { Constants, PopulationType } from '../../../../../common/Constants';
import { ConstantsRoutes } from '../../../../../common/ConstantsRoutes';
import { phoneOnly } from '../../../../../common/ConstantsStyles';
import { ContentWrapper } from '../../../../../common/ContentWrapper';
import { ImageComponent } from '../../../../../common/ImageComponent';
import { Breadcrumb } from '../../../../../common/PSXHeader/Breadcrumb';
import { convertObjectToUrlParams } from '../../../../../common/Utils';
import {
  isCnsMember,
  isMnrCosmosMember,
} from '../../../../../common/Utils/memberUtils/memberUtils';
import { useAdobeBrowserBackButtonTrack } from '../../../../../hooks/adobeHook/useAdobeBrowserBackButtonTrack';
import { useAdobePageTrackEvent } from '../../../../../hooks/adobeHook/useAdobePageTrackEvent';
import {
  useClearPrimaryCareCache,
  usePrimaryCare,
  useUpdatePrimaryCare,
} from '../../../../../hooks/usePrimaryCare';
import { useProviderDetails } from '../../../../../hooks/useProviderDetails';
import { BreadcrumbSessionStorage } from '../../../../../models/BreadcrumbSessionStorage';
import { ProviderDetails } from '../../../../../models/ProviderDetails';
import { useChipStore } from '../../../../../store/useChipStore';
import { ChipState } from '../../../../../store/useChipStore/chipStore';
import { usePCPStore } from '../../../../../store/usePCPStore';
import { PCPSearchStore } from '../../../../../store/usePCPStore/pcpStore';
import { useTypeaheadStore } from '../../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../../store/useTypeaheadStore/typeaheadStore';
import { searchResultBreadcrumbTitle } from '../../../../../utils/common';
import { clearRoute } from '../../../../../utils/map.utils';
import { getPrimaryPhone } from '../../../../../utils/phone.utils';
import {
  getPCPGetParams,
  getPCPUpdateParams,
} from '../../../../../utils/primaryCare.utils';
import { getProviderLocationWithProviderGroup } from '../../../../../utils/providerDetails.utils';
import {
  getNameAttrs,
  parseProviderName,
} from '../../../../../utils/providerNames.utils';
import {
  getLoggedInMember,
  getMemberByDependentSeqNbr,
} from '../../../../../utils/user.utils';
import {
  ButtonRowBox,
  ChoosePCPLocationAvatar,
  PCPCardWrapper,
  PCPHeaderContainer,
  SelectedPCPCard,
  StyledProviderName,
} from './ChoosePCPLocation.styled';
import { ChoosePCPLocationButtonRow } from './ChoosePCPLocationButtonRow';
import { ChoosePCPLocationHeading } from './ChoosePCPLocationHeading';
import { ConfirmPCPLocation } from './ConfirmPCPLocation';
import { LocationsMapViewWrap } from './LocationsMapViewWrap';
import { UpdateErrorModal } from './UpdateErrorModal';
import { UpdatePCPErrorModal } from './UpdatePCPErrorModal';

export const ChoosePCPLocation = () => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const map = React.useRef<mapboxgl.Map | null>(null);
  const { t } = useTranslation();
  const { navigate } = useRouter();

  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { sectionType } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      sectionType: state.typeaheadSearchStore.sectionType,
    }))
  );
  const {
    setPCPSearchState,
    choosePCPId,
    dependentSeqNbr,
    selectedProviderType,
  } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      setPCPSearchState: state.setPCPSearchState,
      choosePCPId: state.pcpSearchState.choosePCPId,
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
      selectedProviderType: state.pcpSearchState.selectedProviderType,
    }))
  );

  const loggedInMember = getLoggedInMember();
  const memberByDependentSeqNbr = getMemberByDependentSeqNbr(dependentSeqNbr);

  // @ts-ignore
  const { data: primaryCareData } = usePrimaryCare();
  const { primaryCare } = primaryCareData;
  const curPCP = primaryCare?.find((pcp) => pcp.depSeqNbr === dependentSeqNbr);

  const [, updatePCP] = useUpdatePrimaryCare({});
  const [, clearPCPCache] = useClearPrimaryCareCache({});
  const imgRef = useRef<HTMLImageElement>(null);

  const [isMobileMapView, setIsMobileMapView] = useState<boolean>(false);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<number>(0);
  const [isUpdatingPCP, setIsUpdatingPCP] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [showButtonVariant, setShowButtonVariant] = useState<boolean>(false);
  const [mobileRouteView, setMobileRouteView] = useState<boolean>(false);
  const [mobileRouteList, setMobileRouteList] = useState<boolean>(false);
  const [routeEndCoords, setRouteEndCoords] = useState<
    [number | null, number | null]
  >([null, null]);
  const [errorCode, setErrorCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const { USE_PRE_PROD } = Constants.STORAGE_KEYS.SESSION;

  const [enablePreProd] = useState(storage.session.get(USE_PRE_PROD, false));
  useEffect(() => {
    storage.session.set(USE_PRE_PROD, enablePreProd);
  }, [enablePreProd]);

  const choosePcpLocation: string = 'body:choose pcp location';

  const { adobePageTrackEvent } = useAdobePageTrackEvent({
    pageName: 'choose location',
    sitesectionLevel1: 'pcp',
  });

  const { adobeBrowserBackButtonTrack } = useAdobeBrowserBackButtonTrack();

  const [memberId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MEMBER_ID,
    0
  );
  const [selectedLocationId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    null
  );

  // @ts-ignore
  const { data, isLoading } = useProviderDetails({
    providerType: selectedProviderType,
    providerId: choosePCPId,
  });

  useEffect(() => {
    adobeBrowserBackButtonTrack();
  }, []);

  useEffect(() => {
    if (!mobileScreen) setIsMobileMapView(mobileScreen);
  }, [mobileScreen]);

  useEffect(() => {
    adobePageTrackEvent();
  }, []);

  const [breadcrumbSessionStorage] =
    useSessionStorage<BreadcrumbSessionStorage>(
      Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
      {}
    );

  const parseBreadcrumbs = (urls) => {
    const breadcrumbs = [
      {
        title: t('Select location'),
        href: '',
      },
    ];
    if (urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key]) {
      const breadcrumbToken =
        tokenizer.parse(
          urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key].replace(
            '/results/mapview/',
            ''
          )
        ) || {};
      breadcrumbs.unshift({
        title: t(searchResultBreadcrumbTitle(breadcrumbToken.sectionType)),
        href: `${urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key]}`,
      });
    }
    if (urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key]) {
      breadcrumbs.unshift({
        title: t('BC Results'),
        href: `${urls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key]}`,
      });
    }
    return breadcrumbs;
  };

  const breadcrumbs = parseBreadcrumbs(breadcrumbSessionStorage);

  const isErrorButtonVariant = (code) => {
    switch (code) {
      case '50085':
      case '50126':
      case '50128':
      case '50135':
        return true;
      default:
        return false;
    }
  };

  const providerDetails =
    data?.facilityDetails?.providerDetails ||
    data?.providerDetails?.providerDetails;

  if (!providerDetails) return null;

  if (isLoading) {
    return (
      <CardSection>
        <LoadingSpinner
          ariaLoadingLabel="loading results"
          isLoading={isLoading}
          size="$sm"
        />
      </CardSection>
    );
  }

  const provider = providerDetails as ProviderDetails;

  const {
    providerName,
    professionalDesignation,
    specialties,
    primaryDegrees,
    providerType,
    imageLocation,
  } = provider;

  const nameAttrs = getNameAttrs(provider);

  const formattedProviderName = parseProviderName(
    providerName,
    providerType,
    professionalDesignation,
    nameAttrs
  );

  const fullProviderName = primaryDegrees
    ? `${formattedProviderName}, ${primaryDegrees?.[0]}`
    : formattedProviderName;

  const providerLocations = getProviderLocationWithProviderGroup(
    provider?.providerLocations
  );

  const hasMultipleLocations = providerLocations?.length > 1;

  const isLocationSelected = !hasMultipleLocations || selectedLocationId;
  const useStickyButtonRow = hasMultipleLocations && mobileScreen;

  const isSuperUser = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.SUPER_USER
  );

  const handleBackButtonClick = () => {
    if (isMobileMapView) {
      setIsMobileMapView(false);
      setMobileRouteView(false);
      setMobileRouteList(false);
      clearRoute(map.current, setRouteEndCoords);
    } else {
      navigate(-1);
    }
  };

  const handleCancel = () => {
    adobeLinkTrackEvent({
      name: 'cancel',
      location: choosePcpLocation,
      type: 'internal',
    });

    navigate(-1);
  };
  const handleCloseModal = () => setShowErrorModal(false);
  const selectedLocation = providerLocations[selectedAddressIndex];
  const phone = selectedLocation?.phones?.phone;
  const appointment = selectedLocation?.phones?.appointment;

  const primaryPhoneNumber = getPrimaryPhone(phone, appointment);
  const population = loggedInMember?.population;
  const lob = loggedInMember?.lineOfBusiness;

  const doShowConfirmModal = () =>
    !showConfirm && (isMnrCosmosMember(lob, population) || isCnsMember(lob));

  const handleSaveLocation = () => {
    if (doShowConfirmModal()) {
      setShowConfirm(true);
      return;
    }

    adobeLinkTrackEvent({
      name: 'save location',
      location: choosePcpLocation,
      type: 'internal',
    });

    const selectedLocationString = selectedLocation
      ? JSON.stringify(selectedLocation)
      : null;

    if (!isSuperUser) {
      setIsUpdatingPCP(true);
      const selectedLocation = providerLocations[selectedAddressIndex];
      const updateVariables = getPCPUpdateParams(
        dependentSeqNbr,
        loggedInMember,
        memberByDependentSeqNbr,
        memberId.toString(),
        selectedLocation.pcpId,
        providerType,
        providerName
      );

      // @ts-ignore
      updatePCP({
        variables: updateVariables,
      }).then((result) => {
        setIsUpdatingPCP(false);
        if (result?.data?.updatePCP?.success) {
          const clearVariables = getPCPGetParams(
            enablePreProd,
            loggedInMember,
            memberId.toString()
          );

          // @ts-ignore
          clearPCPCache({
            variables: clearVariables,
          });

          setPCPSearchState({
            formattedProviderName,
            providerType,
            selectedLocation: selectedLocationString,
            primarySpeciality: specialties[0],
            imageLocation,
            pcpEffectiveDate: result?.data?.updatePCP?.pcpEffectiveDate,
          });

          const urlParams = convertObjectToUrlParams(chipStore, {
            formattedProviderName,
            providerType,
            selectedLocation: selectedLocationString,
            primarySpeciality: specialties[0],
            imageLocation,
            pcpEffectiveDate: result?.data?.updatePCP?.pcpEffectiveDate,
          });
          navigate(`${ConstantsRoutes.CHOOSE_PCP_SUCCESS.path}${urlParams}`);
        } else {
          setShowConfirm(false);
          setShowErrorModal(true);
          setErrorCode(result.data.updatePCP.code);
          setErrorMessage(result.data.updatePCP.message);
          setShowButtonVariant(
            isErrorButtonVariant(result.data.updatePCP.code)
          );
        }
      });
    } else {
      // Bypass the OBAPI call so that super user does not make PCP updates for the member's profile/account.
      // so, navigating to PCP success without talking to OBAPI
      const urlParams = convertObjectToUrlParams(chipStore, {
        sectionType,
        choosePCPId,
        dependentSeqNbr,
        selectedProviderType,
      });
      navigate(`${ConstantsRoutes.CHOOSE_PCP_SUCCESS.path}${urlParams}`);
    }
  };

  const saveAndCancelButtons = (
    <ChoosePCPLocationButtonRow
      handleCancel={handleCancel}
      handleSaveLocation={handleSaveLocation}
      isLocationSelected={isLocationSelected}
      isUpdatingPCP={isUpdatingPCP}
    />
  );
  const imgSrc = imageLocation;

  if (showConfirm) {
    return (
      <ConfirmPCPLocation
        breadcrumbs={breadcrumbs}
        curProviderId={curPCP?.pcpInfo?.providerID}
        curProviderLocationId={''}
        curProviderType={curPCP?.pcpInfo?.providerType}
        handleBackButtonClick={handleBackButtonClick}
        handleSaveLocation={handleSaveLocation}
        newProvider={providerDetails}
        newProviderLocation={selectedLocation}
        setShowConfirm={setShowConfirm}
      />
    );
  }

  return (
    <React.Fragment>
      {population === PopulationType.UNET ? (
        <UpdateErrorModal
          handleClose={handleCloseModal}
          isOpen={showErrorModal}
          primaryPhoneNumber={primaryPhoneNumber}
          searchButtonVariant={showButtonVariant}
        />
      ) : (
        <UpdatePCPErrorModal
          dependentSeqNbr={dependentSeqNbr}
          errorCode={errorCode}
          handleClose={handleCloseModal}
          isOpen={showErrorModal}
          populationType={population}
          primaryPhoneNumber={primaryPhoneNumber}
          resultsErrorMessage={errorMessage}
        />
      )}
      {!(mobileRouteView && mobileScreen) ? (
        <PCPHeaderContainer
          data-auto-testid="choose-pcp-location-container"
          data-testid="choose-pcp-location-container"
        >
          <ContentWrapper>
            <PCPCardWrapper>
              <Breadcrumb
                breadcrumbs={breadcrumbs}
                onBackButtonClick={handleBackButtonClick}
              />
              <SelectedPCPCard height="66px" padding="10px">
                <Layout.Group
                  css={{
                    'abyss-layout-group': {
                      display: 'flex',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <ChoosePCPLocationAvatar>
                    {ImageComponent({ imgRef, providerType, imgSrc })}
                  </ChoosePCPLocationAvatar>
                  <Layout.Stack alignItems="left" space={0}>
                    <Text
                      // @ts-ignore
                      css={{
                        color: '#323334',
                        '@screen < $md': { color: '#FFFFFF' },
                      }}
                      fontWeight="bold"
                      size="lg"
                    >
                      <StyledProviderName>
                        {fullProviderName}
                      </StyledProviderName>
                    </Text>
                    <Text
                      // @ts-ignore
                      css={{
                        fontSize: '12.64px',
                        fontWeight: '500',
                        color: '#4B4D4F',
                        '@screen < $md': { color: '#FFFFFF' },
                      }}
                      size="xs"
                    >
                      {specialties?.[0]}
                    </Text>
                  </Layout.Stack>
                </Layout.Group>
              </SelectedPCPCard>
            </PCPCardWrapper>
          </ContentWrapper>
        </PCPHeaderContainer>
      ) : null}
      <ContentWrapper>
        <Box color="white" height="unset" padding="0px">
          <ChoosePCPLocationHeading
            formattedProviderName={formattedProviderName}
            hasMultipleLocations={hasMultipleLocations}
            isMobileMapView={isMobileMapView}
          />
          <LocationsMapViewWrap
            handleBackButtonClick={handleBackButtonClick}
            hasMultipleLocations={hasMultipleLocations}
            isMobileMapView={isMobileMapView}
            map={map}
            mobileRouteList={mobileRouteList}
            mobileRouteView={mobileRouteView}
            mobileScreen={mobileScreen}
            providerLocations={providerLocations}
            routeEndCoords={routeEndCoords}
            saveAndCancelButtons={saveAndCancelButtons}
            setIsMobileMapView={setIsMobileMapView}
            setMobileRouteList={setMobileRouteList}
            setMobileRouteView={setMobileRouteView}
            setRouteEndCoords={setRouteEndCoords}
            setSelectedAddressIndex={setSelectedAddressIndex}
            useStickyButtonRow={useStickyButtonRow}
          />
        </Box>
      </ContentWrapper>
      {useStickyButtonRow && !isMobileMapView ? (
        <ButtonRowBox color="$white" padding="0">
          {saveAndCancelButtons}
        </ButtonRowBox>
      ) : null}
    </React.Fragment>
  );
};
