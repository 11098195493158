import { Popover } from '@abyss/web/ui/Popover';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import isEmpty from 'lodash/isEmpty';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsOverflow } from '../../../../../../../../../../hooks/useIsOverflow';
import {
  StyledOverflowAttributeDiv,
  popoverStyle,
} from '../../../../../utility/compareDrawer.styled';
import { NULL_ATTRIBUTE_STATE } from '../../../../../utility/compareDrawerConstants';

type Props = {
  providerEthnicity: string;
  mobile: boolean;
};

export const ProviderEthnicity = ({ providerEthnicity, mobile }: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);

  const isAttributeEmpty = isEmpty(providerEthnicity);

  const providerEthnicityText = isAttributeEmpty
    ? NULL_ATTRIBUTE_STATE.PROVIDER_ETHNICITY
    : providerEthnicity;

  const ProviderEthnicity = (
    <StyledOverflowAttributeDiv
      cssProps={{ isOverflow, isAttributeEmpty }}
      ref={containerRef}
    >
      {t(providerEthnicityText)}
    </StyledOverflowAttributeDiv>
  );

  if (mobile) {
    return (
      <React.Fragment>
        {isOverflow ? (
          <Popover
            content={providerEthnicityText}
            css={popoverStyle}
            showClose={false}
            tabIndex="-1"
            width={150}
          >
            {ProviderEthnicity}
          </Popover>
        ) : (
          ProviderEthnicity
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {isOverflow ? (
        <Tooltip
          content={providerEthnicityText}
          position="top"
          positionOffset={8}
        >
          {ProviderEthnicity}
        </Tooltip>
      ) : (
        ProviderEthnicity
      )}
    </React.Fragment>
  );
};
