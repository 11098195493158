import { styled } from '@abyss/web/tools/styled';

export const TerminationBannerWrapper = styled('div', {
  '.abyss-badge-root': {
    maxWidth: '300px',
  },

  '.abyss-badge-content': {
    '@screen < $sm': {
      fontSize: '$xs',
    },
  },
});
