import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getCurrentMemberIsPreEffective } from '../../utils/user.utils';
import { useFeatureFlag } from '../useFeatureFlag';

export const useCurrentMemberIsPreEffective = (): boolean => {
  const [isSuppressPreEffectiveFF] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.IS_SUPPRESS_PREEFFECTIVE,
  ]);
  return getCurrentMemberIsPreEffective() && !isSuppressPreEffectiveFF;
};
