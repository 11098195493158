import { config } from '@abyss/web/tools/config';
import { Icon } from '@abyss/web/ui/Icon';

const PSX_ASSET_CDN = `${config('CDN_BASE_URL')}/cdn/assets`;

type Props = {
  isScreenReadable?: boolean;
  icon: string;
  title?: string;
  [x: string]: any;
};
export const PSXIcon = ({ isScreenReadable, icon, title, ...props }: Props) => {
  if (!icon) {
    return null;
  }

  return (
    <Icon
      {...props}
      icon={icon}
      isScreenReadable={isScreenReadable}
      spriteSheetId={icon}
      spriteSheetSrc={`${PSX_ASSET_CDN}/psx_icons.svg`}
      title={isScreenReadable ? title || icon : null}
    />
  );
};
