import { config } from '@abyss/web/tools/config';
import { storage } from '@abyss/web/tools/storage';
import { useEffect, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';

import { Constants } from '../../common/Constants';
import { getPCPGetParams } from '../../utils/primaryCare.utils';
import { getLoggedInMember } from '../../utils/user.utils';
import { useCustomQuery } from '../useCustomQuery';
import CLEAR_PRIMARY_CARE from './ClearPCPCache.graphql';
import GET_PRIMARY_CARE from './PrimaryCare.graphql';
import UPDATE_PRIMARY_CARE from './UpdatePCP.graphql';

export const useClearPrimaryCareCache = (options) =>
  useCustomQuery(CLEAR_PRIMARY_CARE, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    clearCache: [
      {
        key: 'PrimaryCare',
        variables: [
          'dependentSeqNbr',
          'dateOfBirth',
          'enablePreProd',
          'familyId',
          'firstName',
          'groupNumber',
          'isGatedUser',
          'isNonGatedUSPUser',
          'lastName',
          'memberId',
          'id',
          'portalSource',
          'lob',
          'population',
          'membershipCategory',
          'claimSystemTypeCode',
          'eligibilitySystemTypeCode',
          'planVariationCode',
          'reciprocityId',
          'userName',
        ],
      },
    ],
    requestPolicy: 'no-cache',
    accessor: 'clearPCPCache',
  });

const usePrimaryCareQuery = (options) => {
  const [primaryCare, setPrimaryCare] = useState({});
  const [queryResult, queryAction] = useCustomQuery(GET_PRIMARY_CARE, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'primaryCare',
    onCompleted: (result) => {
      if (options?.onCompleted) {
        options.onCompleted(result?.data);
      }
      setPrimaryCare(result?.data);
    },
  });

  const parsedResult = {
    ...queryResult,
    data: primaryCare,
  };

  return [parsedResult, queryAction];
};

export const usePrimaryCare = () => {
  const [memberId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MEMBER_ID,
    0
  );

  const [primaryCare, getPrimaryCare] = usePrimaryCareQuery({});

  const loggedInMember = getLoggedInMember();

  const { USE_PRE_PROD } = Constants.STORAGE_KEYS.SESSION;

  const [enablePreProd] = useState(storage.session.get(USE_PRE_PROD));
  useEffect(() => {
    storage.session.set(USE_PRE_PROD, enablePreProd);
  }, [enablePreProd]);

  useEffect(() => {
    if (loggedInMember) {
      const variables = getPCPGetParams(
        enablePreProd,
        loggedInMember,
        memberId.toString()
      );
      getPrimaryCare({ variables });
    }
  }, [loggedInMember]);

  return primaryCare;
};

export const useUpdatePrimaryCare = (options) =>
  useCustomQuery(UPDATE_PRIMARY_CARE, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    requestPolicy: 'no-cache',
    accessor: 'updatePCP',
  });
