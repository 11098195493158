import { styled } from '@abyss/web/tools/styled';
import { Divider } from '@abyss/web/ui/Divider';
import { Text } from '@abyss/web/ui/Text';

export const PrintViewAddress = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '9px',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '11px',
  },
});

export const PrintViewDivider = styled(Divider, {
  '&.abyss-divider-root': {
    border: '0.5px solid #CCCCCC',
    height: 0,
    marginTop: '$sm !important',
    marginBottom: '$md !important',
    transform: 'matrix(1, 0, 0, 1, 0, 0)',
  },
});

export const PrintViewProviderMediumText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '14px',
    fontWeight: '600',
    fontStyle: 'normal',
    lineHeight: '17px',
    marginBottom: '5px',
  },
});

export const PrintViewProviderBoldText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '10px',
    fontWeight: '700',
    fontStyle: 'normal',
    lineHeight: '11.87px',
  },
});

export const PrintViewProviderNormalText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '10px',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: '11.87px',
  },
});

export const PrintViewProviderDetailsXSText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '11px',
    fontWeight: '400',
    lineHeight: '14px',
    textOverflow: 'ellipsis',
  },
});
