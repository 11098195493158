import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Text } from '@abyss/web/ui/Text';
import { useTranslation } from 'react-i18next';

const ShareButtonStyled = styled(Button, {
  variants: {
    view: {
      mobile: {
        '&.abyss-button-root': {
          width: '110px',
          color: 'gray6',
        },
      },
      desktop: {
        '&.abyss-button-root': {
          paddingRight: 0,
        },
      },
      none: {
        display: 'none',
      },
    },
  },
});

type Props = {
  view: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
};

export const ShareButton = ({ view, onClick }: Props) => {
  const { t } = useTranslation();

  return (
    <ShareButtonStyled
      data-auto-testid="mapview-share-button"
      data-testid="mapview-share-button"
      onClick={onClick}
      variant="tertiary"
      view={view}
    >
      <IconMaterial
        css={{
          'abyss-icon': {
            marginRight: '$xs',
          },
        }}
        icon="share"
      />
      <Text color="$gray7" fontWeight="500" size="$md">
        {t('Share')}
      </Text>
    </ShareButtonStyled>
  );
};
