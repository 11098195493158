import { useMemo } from 'react';

import { Constants } from '../../common/Constants';
import { getFeatureFlag } from '../../common/Utils';
/* eslint-disable no-sequences */
import { useLagoon } from '../useLagoon';

export const useFeatureFlag = (flagKey: string[] = []) => {
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const flag: boolean[] = useMemo(
    () => flagKey.map((flagItem) => getFeatureFlag(featureFlags, flagItem)),
    [flagKey]
  );

  return flag;
};
