import validUrl from 'valid-url';

export const isExternalDomain = (urlLink: string): boolean => {
  if (validUrl.isUri(urlLink)) {
    const internalHostName: string = window.location.hostname;
    const { protocol: linkProtocols, hostname: linkHostName } = new URL(
      urlLink
    );
    return (
      !(linkProtocols === 'https:' || linkProtocols === 'http:') ||
      internalHostName !== linkHostName
    );
  }
  return false;
};
