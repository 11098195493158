import { event } from '@abyss/web/tools/event';

export const adobeSearchFilterEvent = (input) => {
  event('SEARCH_FILTER_EVENT', {
    search: {
      term: input.term?.toLowerCase() ?? '',
      type: 'provider',
      filters: input?.filters || '',
    },
    component: {
      name: '',
    },
  });
};
