import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ChoosePCPAlert } from '../../../../common/Alert/ChoosePCPAlert';
import { Constants } from '../../../../common/Constants';
import { EAPCode } from '../../../../common/EAPCode';
import { CompareProvider } from '../../../../models/Provider';
import { DownloadResultsButton } from '../ViewAll/DownloadResultsButton';
import { ShareCompareButton } from '../ViewAll/ShareCompareButton';
import { FiltersContainerDesktop } from './FiltersContainerDesktop';
import { EAPStyle } from './MapView.styled';

type Props = {
  pageHeader: string;
  sectionType: string;
  mobileScreen: boolean;
  eapCodeFlag;
  isBHCare;
  isPageTracked: boolean;
  openCompare?: boolean;
  setOpenCompare: (a: boolean) => void;
  setOpenShare: (a: boolean) => void;
  setSelectedCheckbox: (a: { checked: {} }) => void;
  setSelectedItems: (a: CompareProvider[]) => void;
  setOpenBoxContents: (a: boolean) => void;
  openShare: boolean;
  resultSet: any;
};

export const MapViewHeader = ({
  pageHeader,
  sectionType,
  mobileScreen,
  eapCodeFlag,
  isBHCare,
  isPageTracked,
  openCompare,
  setOpenCompare,
  setOpenShare,
  setSelectedCheckbox,
  setSelectedItems,
  openShare,
  setOpenBoxContents,
  resultSet,
}: Props) => {
  const { t } = useTranslation();
  const [isClicked, setIsClicked] = useState<boolean>(false);

  return (
    <React.Fragment>
      <Layout.Stack
        alignItems="left"
        css={{ '@screen < $sm': { display: 'none' } }}
        grow="true"
        space="0"
      >
        <Layout.Group
          css={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
          }}
        >
          <Heading
            css={{
              'abyss-heading-root': {
                '@screen < $md': {
                  fontSize: '$mediumText !important',
                  lineHeight: '$lg !important',
                },
                marginBottom: '$sm',
              },
            }}
            data-auto-testid="results-heading"
            data-testid="results-heading"
            display="h4"
            offset={1}
            textAlign="left"
          >
            {pageHeader}
          </Heading>
          <Flex>
            <Layout.Group space="15">
              <Layout.Group space="15">
                <React.Fragment>
                  {sectionType === Constants.RESULT_SECTION.PROVIDER && (
                    <ShareCompareButton
                      isShareButton={false}
                      openCompare={openCompare}
                      sectionType={sectionType}
                      setIsClicked={setIsClicked}
                      setOpenCompare={setOpenCompare}
                      setOpenShare={setOpenShare}
                      setSelectedCheckbox={setSelectedCheckbox}
                      setSelectedItems={setSelectedItems}
                      text={t('COMPARE_BUTTONS.COMPARE')}
                      view="desktop"
                    />
                  )}
                  <ShareCompareButton
                    openShare={openShare}
                    sectionType={sectionType}
                    setIsClicked={setIsClicked}
                    setOpenCompare={setOpenCompare}
                    setOpenShare={setOpenShare}
                    setSelectedCheckbox={setSelectedCheckbox}
                    setSelectedItems={setSelectedItems}
                    text={t('COMPARE_BUTTONS.SHARE')}
                    view="desktop"
                  />
                  <DownloadResultsButton
                    isClicked={isClicked}
                    setIsClicked={setIsClicked}
                    setOpenBoxContents={setOpenBoxContents}
                    setOpenCompare={setOpenCompare}
                    setOpenShare={setOpenShare}
                    setSelectedCheckbox={setSelectedCheckbox}
                    setSelectedItems={setSelectedItems}
                    text={t('COMPARE_BUTTONS.DOWNLOAD_RESULTS')}
                    view="desktop"
                  />
                </React.Fragment>
              </Layout.Group>
            </Layout.Group>
          </Flex>
        </Layout.Group>
        <ChoosePCPAlert isMapView />
        <FiltersContainerDesktop
          resultSet={resultSet}
          sectionType={sectionType}
        />
      </Layout.Stack>
      {!mobileScreen && isPageTracked && eapCodeFlag && isBHCare && (
        <EAPStyle>
          <EAPCode />
        </EAPStyle>
      )}
    </React.Fragment>
  );
};
