import { styled } from '@abyss/web/tools/styled';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';

export const CollapseCardHeaderStyle = {
  backgroundColor: 'white',
  borderRadius: 'unset',
  borderLeft: 'none',
  borderRight: 'none',
  boxShadow: 'none',
  color: '$primary1',
  fontWeight: '$bold',
  fontSize: '25.63px',
  lineHeight: '32px',
  marginBottom: '$sm',
  minHeight: 'unset',
  padding: '0',
  '.abyss-card-root': {
    justifyContent: 'left',
  },
  '.abyss-card-title': {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
  },
  '@screen < $md': {
    fontSize: '20.25px',
    lineHeight: '24px',
    paddingLeft: '$md',
    paddingRight: '$md',
    paddingTop: '$md',
    borderColor: '$gray3',
  },
};

export const CollapseSection = styled(Layout.Group, {
  '&.abyss-layout-group': {
    width: '100%',
    paddingLeft: '0',
    marginTop: '$sm',
    marginBottom: '$sm',
    display: 'block',
    marginRight: '0',
    '@screen < $md': {
      display: 'block',
      width: '100%',
    },

    '& > div': {
      marginRight: '-4px',
    },
    '& .abyss-button-root': {
      width: '24px',
      height: '24px',
      fontSize: '24px',
      '& span': {
        padding: 0,
      },
    },
  },
});

export const TextContainer = styled('div', {
  maxWidth: '841px',
  fontWeight: '$medium',
  fontSize: '$lg',
  lineHeight: '24px',
  color: '$gray7',
  overflow: 'auto',
  marginBottom: '10px',
  '@screen < $sm': {
    maxWidth: '338px',
    fontSize: '$md',
    lineHeight: '20px',
    marginBottom: '0',
  },
});

export const StyledHeadingText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '$lg',
    lineHeight: '24px',
    fontWeight: '500',
    color: '$gray7',

    '@screen < $sm': {
      height: '24px',
      fontSize: '$md',
      lineHeight: '20px',
      display: 'block',
      marginBottom: '12px',
    },
  },
});

export const StyledHeadingTextDate = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '$lg',
    lineHeight: '24px',
    fontWeight: '700',
    color: '$black',
    '@screen < $sm': {
      marginBottom: '2%',
      height: '24px',
      fontSize: '$md',
      fontWeight: '500',
      lineHeight: '20px',
      color: '$gray6',
    },
  },
});

export const StyledContentText = styled('p', {
  fontWeight: '500',
  fontSize: '$lg',
  color: '$gray7',
  lineHeight: '24px',
  marginBottom: '$md',
  '@screen < $sm': {
    height: '24px',
    fontSize: '$md',
    lineHeight: '20px',
  },
});

export const SectionContainer = styled('div', {
  borderWidth: '1px 0',
  borderStyle: 'solid',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'start',
  borderColor: '$cardBorder',
  marginRight: 0,
  padding: '$lg calc((100% - 1200px) / 2)',
  '@media (max-width: 1248px)': {
    padding: '$lg',
  },
  '@screen < $md': {
    padding: '$md',
  },
});
export const HeadingContainer = styled('div', {
  width: '338px',
  height: '32px',
  fontWeight: '$bold',
  fontSize: '25.63px',
  lineHeight: '32px',
  color: '$primary1',
  marginBottom: '12px',
  '@screen < $sm': {
    height: '24px',
    fontSize: '20.25px',
    lineHeight: '24px',
    marginBottom: 'initial',
  },
});
