import { styled } from '@abyss/web/tools/styled';

export const TextStyled = {
  fontWeight: 500,
  color: '#4B4D4F',
  lineHeight: '16px',
  fontSize: '14.22px',
};

export const NullPageContainerStyled = styled('div', {
  backgroundColor: '$white',
});

export const ContainerForNullSections = styled('div', {
  dynamic: ({ cssProps: { display = 'block' } }) => ({
    display,
  }),
  borderTop: '1px solid $cardBorder',
  borderLeft: 'none',
  borderRight: 'none',
  marginBottom: '$sm',
});
export const ContentContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: '8px',
});
export const BoxStyles = {
  'abyss-box-root': {
    height: 'auto',
    minHeight: '189px',
    padding: '0px',
  },
};

export const HeadingStyles = {
  'abyss-heading-root': {
    fontSize: '28.83px !important',
    fontWeight: '$semibold !important',
    lineHeight: '36px !important',
    '@screen < $sm': {
      fontSize: '22.78px !important',
      lineHeight: '28px !important',
    },
    marginBottom: 0,
  },
};

export const ButtonStyles = {
  marginTop: '16px',
  fontWeight: '600',
  lineHeight: '20px',
  height: '42px',
  padding: ' 8px 16px 8px 24px',
  'abyss-button-content-container': {
    height: '20px',
  },
};

export const IconStyles = {
  'abyss-icon-material': {
    marginLeft: '4px',
  },
};
