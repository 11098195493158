import {
  bhSpecificDefaultItems,
  defaultItems,
  dentalSpecificDefaultItems,
  visionSpecificDefaultItems,
} from '../../MapView/CompareDrawer/utility/compareDrawerConstants';

export const getDefaultCompareAttributes = (coverageType: string) => {
  let compareAttributeItems;
  switch (coverageType) {
    case 'B':
      compareAttributeItems = bhSpecificDefaultItems;
      break;
    case 'V':
      compareAttributeItems = visionSpecificDefaultItems;
      break;
    case 'D':
      compareAttributeItems = dentalSpecificDefaultItems;
      break;
    default:
      compareAttributeItems = defaultItems;
      break;
  }

  return compareAttributeItems;
};
