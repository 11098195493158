export const findAllMatches = (search, text): Array<any> => {
  const regex1 = RegExp(search, 'gi');
  let match;
  const chunks: Array<Object> = [];
  let index = 0;
  match = regex1.exec(text);
  while (match !== null) {
    chunks.push({
      isMatch: false,
      text: text.substr(index, match.index - index),
    });
    chunks.push({
      isMatch: true,
      text: text.substr(match.index, regex1.lastIndex - match.index),
    });
    index = regex1.lastIndex;
    match = regex1.exec(text);
  }
  chunks.push({
    isMatch: false,
    text: text.substr(index, text.length - index),
  });
  return chunks;
};
