import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Text } from '@abyss/web/ui/Text';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../../../common/ConstantsLagoon';
import { phoneOnly } from '../../../../../common/ConstantsStyles';
import { ContentWrapper } from '../../../../../common/ContentWrapper';
import { ImageComponent } from '../../../../../common/ImageComponent';
import { Breadcrumb } from '../../../../../common/PSXHeader/Breadcrumb';
import { capitalizeFirstLetter } from '../../../../../common/Utils/capitalizeFirstLetter';
import { useFeatureFlag } from '../../../../../hooks/useFeatureFlag';
import { useProviderDetails } from '../../../../../hooks/useProviderDetails';
import {
  ProviderDetails,
  ProviderLocation,
  ProviderType,
} from '../../../../../models/ProviderDetails';
import {
  getFormattedPhoneNumber,
  getPrimaryPhone,
} from '../../../../../utils/phone.utils';
import { getFormattedAddress } from '../../../../../utils/providerDetails.utils';
import {
  capitalizeFirstLetters,
  getNameAttrs,
  parseProviderName,
} from '../../../../../utils/providerNames.utils';
import { removeLeadingZeroes } from '../../../../../utils/string.utils';
import {
  ChoosePCPLocationAvatar,
  PCPCardWrapper,
  PCPHeaderContainer,
} from './ChoosePCPLocation.styled';
import {
  StyledConfirmButton,
  StyledConfirmPCPAddressCardText,
  StyledConfirmPCPContainer,
  StyledConfirmPCPHeading,
  StyledConfirmPCPInfo,
  StyledConfirmPCPInfoHeading,
  StyledConfirmPCPNameCardText,
  StyledNewPCPCard,
  StyledPreviousPCPCard,
} from './ConfirmPCPLocation.styled';

type Props = {
  breadcrumbs: any;
  curProviderId: string;
  curProviderLocationId: string;
  curProviderType: ProviderType;
  handleBackButtonClick: () => void;
  handleSaveLocation: () => void;
  newProvider: ProviderDetails;
  newProviderLocation: ProviderLocation;
  setShowConfirm: (showConfirm: boolean) => void;
};

const getFullProviderName = (provider) => {
  const {
    primaryDegrees,
    professionalDesignation,
    providerName,
    providerType,
  } = provider;

  const nameAttrs = getNameAttrs(provider);
  const formattedProviderName = parseProviderName(
    providerName,
    providerType,
    professionalDesignation,
    nameAttrs
  );

  return primaryDegrees
    ? `${formattedProviderName}, ${primaryDegrees?.[0]}`
    : formattedProviderName;
};

export const ConfirmPCPLocation = ({
  breadcrumbs,
  curProviderId,
  curProviderLocationId,
  curProviderType,
  handleBackButtonClick,
  handleSaveLocation,
  newProvider,
  newProviderLocation,
  setShowConfirm,
}: Props) => {
  const phoneScreen = useMediaQuery(phoneOnly);
  const { t } = useTranslation();
  const curImgRef = useRef<HTMLImageElement>(null);
  const newImgRef = useRef<HTMLImageElement>(null);

  const [isLoading, setIsLoading] = useState<boolean>(!!curProviderId);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const [includeProviderGroupName] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.INCLUDE_PROVIDER_GROUP_NAME,
  ]);

  const { data: curProviderData } = useProviderDetails({
    providerId: curProviderId,
    providerType: capitalizeFirstLetter(curProviderType?.toLowerCase()),
    locationId: curProviderLocationId,
  });

  useEffect(() => {
    if (curProviderData?.providerDetails) {
      setIsLoading(false);
    }
  }, [curProviderData?.providerDetails]);

  if (isLoading) {
    return (
      <ContentWrapper>
        <LoadingSpinner
          ariaLoadingLabel="loading results"
          isLoading={isLoading}
          size="$md"
        />
      </ContentWrapper>
    );
  }

  const handleCloseConfirm = () => {
    setShowConfirm(false);
  };

  const doSaveLocation = () => {
    setIsSaving(true);
    handleSaveLocation();
  };

  const curProvider = curProviderData?.providerDetails?.providerDetails;
  const isChangingLocation =
    removeLeadingZeroes(curProviderId) ===
    removeLeadingZeroes(newProvider.providerId);
  const textSize = phoneScreen ? '$sm' : '$md';

  const PCPNameCard = ({ imgRef, provider }) => {
    const { imageLocation: imgSrc, providerType, specialties } = provider;
    const fullProviderName = getFullProviderName(provider);
    return (
      <StyledConfirmPCPInfo
        css={{
          width: isChangingLocation && !phoneScreen ? '407px' : '',
        }}
      >
        <Layout.Group>
          <ChoosePCPLocationAvatar>
            {ImageComponent({ imgRef, providerType, imgSrc })}
          </ChoosePCPLocationAvatar>
          <Layout.Stack alignItems="left" space={0}>
            <Text fontWeight="bold" size={textSize}>
              <StyledConfirmPCPNameCardText>
                {fullProviderName}
              </StyledConfirmPCPNameCardText>
            </Text>
            <Text size={textSize}>
              <StyledConfirmPCPNameCardText>
                {specialties?.[0]}
              </StyledConfirmPCPNameCardText>
            </Text>
          </Layout.Stack>
        </Layout.Group>
      </StyledConfirmPCPInfo>
    );
  };

  const PCPInfoCard = ({ imgRef, isCurPCP = false, provider, location }) => {
    const { address, phones, providerGroupsAffiliations } = location;

    const formattedAddress = getFormattedAddress(
      address,
      includeProviderGroupName
    );
    const primaryPhoneNumber = getPrimaryPhone(
      phones?.phone,
      phones?.appointment
    );

    const prevOrNew = isCurPCP ? t('Previous') : t('New');
    const pcpOrLocation = isChangingLocation ? t('location') : t('PCP');

    return (
      <Layout.Stack alignItems="left" grow space="8">
        <Text
          color="$primary1"
          fontWeight="bold"
          size={phoneScreen ? 18 : 20}
        >{`${prevOrNew} ${pcpOrLocation}`}</Text>
        {!isChangingLocation ? (
          <Layout.Stack alignItems="left" space="8">
            <StyledConfirmPCPInfoHeading size="18px">
              {t('PCP')}
            </StyledConfirmPCPInfoHeading>
            {PCPNameCard({ imgRef, provider })}
            <StyledConfirmPCPInfoHeading size="18px">
              {t('PRIMARY_CARE_PROVIDER.PCP_LOCATION')}
            </StyledConfirmPCPInfoHeading>
          </Layout.Stack>
        ) : null}
        <StyledConfirmPCPInfo>
          <Layout.Stack alignItems="left" grow space="4">
            {includeProviderGroupName && providerGroupsAffiliations?.[0] && (
              <Text
                data-testid="pcp-confirmation-provider-group"
                fontWeight="$bold"
                size={textSize}
              >
                <StyledConfirmPCPAddressCardText>
                  {capitalizeFirstLetters(providerGroupsAffiliations[0]?.value)}
                </StyledConfirmPCPAddressCardText>
              </Text>
            )}
            <Text size={textSize}>
              <StyledConfirmPCPAddressCardText>
                {formattedAddress}
              </StyledConfirmPCPAddressCardText>
            </Text>
            <Link
              before={<IconMaterial icon="phone" />}
              data-testid="pcp-confirmation-phone-number"
              href={`tel:+1-${primaryPhoneNumber}`}
              size={textSize}
            >
              {getFormattedPhoneNumber(primaryPhoneNumber)}
            </Link>
          </Layout.Stack>
        </StyledConfirmPCPInfo>
      </Layout.Stack>
    );
  };

  return (
    <React.Fragment>
      <PCPHeaderContainer
        data-auto-testid="confirm-pcp-location-container"
        data-testid="confirm-pcp-location-container"
      >
        <ContentWrapper>
          <PCPCardWrapper>
            <Breadcrumb
              breadcrumbs={breadcrumbs}
              onBackButtonClick={handleBackButtonClick}
            />
            <StyledConfirmPCPHeading display={'h3'}>
              {t('PRIMARY_CARE_PROVIDER.CONFIRM_NEW_PCP')}
            </StyledConfirmPCPHeading>
          </PCPCardWrapper>
        </ContentWrapper>
      </PCPHeaderContainer>
      <ContentWrapper>
        <StyledConfirmPCPContainer
          alignItems="left"
          grow={phoneScreen}
          space={phoneScreen ? 16 : 32}
        >
          {isChangingLocation ? (
            <Layout.Stack
              alignItems="left"
              css={{ marginBottom: phoneScreen ? '4px' : '-4px' }}
              data-testid="confirm-pcp-location-change"
              grow
              space="8"
            >
              <StyledConfirmPCPInfoHeading size="18px">
                {t('PCP')}
              </StyledConfirmPCPInfoHeading>
              {PCPNameCard({ imgRef: curImgRef, provider: curProvider })}
            </Layout.Stack>
          ) : null}
          <Flex>
            <StyledNewPCPCard data-testid="confirm-pcp-new-pcp">
              {PCPInfoCard({
                imgRef: newImgRef,
                provider: newProvider,
                location: newProviderLocation,
              })}
            </StyledNewPCPCard>
            {curProvider ? (
              <StyledPreviousPCPCard data-testid="confirm-pcp-previous-pcp">
                {PCPInfoCard({
                  isCurPCP: true,
                  imgRef: curImgRef,
                  provider: curProvider,
                  location: curProvider.providerLocations?.[0],
                })}
              </StyledPreviousPCPCard>
            ) : null}
          </Flex>
          <Layout.Group alignLayout={phoneScreen ? 'right' : 'left'} space="12">
            <Button
              data-testid="pcp-confirmation-back-button"
              onClick={handleCloseConfirm}
              variant="outline"
            >
              {t('BACK')}
            </Button>
            {isSaving ? (
              <LoadingSpinner
                ariaLoadingLabel="loading results"
                isLoading={isSaving}
                size="$sm"
              />
            ) : (
              <StyledConfirmButton
                after={<IconMaterial color="$white" icon="chevron_right" />}
                data-testid="pcp-confirmation-submit-button"
                onClick={doSaveLocation}
              >
                {t('Yes, confirm')}
              </StyledConfirmButton>
            )}
          </Layout.Group>
        </StyledConfirmPCPContainer>
      </ContentWrapper>
    </React.Fragment>
  );
};
