import { Address, PhoneNumbers } from './ProviderDetails';

export const enum FacilityDDPCode {
  L = 'L',
  R = 'R',
  LR = 'L, R',
}

export interface Provider {
  address?: Address;
  website?: string;
  phones?: PhoneNumbers;
  distance?: string;
  facilityType?: string;
  gender?: string;
  latitude?: string;
  locationId?: string;
  longitude?: string;
  networkStatus?: string;
  practitionerRoleId?: string;
  primaryDegrees?: string[];
  providerId?: string;
  providerName?: string;
  sectionType?: string;
  speciality?: string;
  providerType?: string;
  imageLocation?: string;
  healthGradeRating?: string;
  organizationType?: [string];
  organizationCode?: [string];
  isTieredProvider?: boolean;
  isOxPreferredLab?: boolean;
  isPreferredLab?: boolean;
  isFreeStandingFacility?: boolean;
  ddpCode?: FacilityDDPCode;
  isPremiumCarePhysician?: string;
  isGreenDiamondProvider?: boolean;
  specialities?: any;
  virtualCareOffered?: string[];
  isACOProvider?: boolean;
  superPremium?: string;
  lastName?: string;
  qualityCode?: string;
  efficiencyCode?: string;
  isPlatinumProvider?: boolean;
  tin?: string;
  npi?: string;
  isDocasapScheduled?: boolean;
  docasapPracticeId?: string;
  firstName?: string;
  middleName?: string;
  costIndicator?: string | null;
  llmAffinity?: string | null;
  tierType?: string | null;
  surveyCount?: number;
  networkEndDate?: string;
  providerRecommendationScore?: string;
}

export interface Physicians {
  providerId: string;
  providerName: string;
  primaryDegrees: string[];
  specialities: string[];
  npi: string;
}

export interface PhysiciansDirectoryList {
  totalCount: string;
  physicianDirectoryList: Physicians[];
}

export interface CompareProvider {
  address: Address;
  phones: PhoneNumbers;
  distance: string;
  facilityType: string;
  gender: string;
  healthGradeRating: string;
  latitude: string;
  locationId: string;
  longitude: string;
  networkStatus: string;
  practitionerRoleId: string;
  professionalDesignation?: string;
  providerId: string;
  providerName: string;
  specialities: string[];
  primaryDegrees: string[];
  providerType: string;
  acceptingNewPatients: boolean;
  efficiencyCode: string;
  languagesSpoken: string[];
  hoursOfOperation: HoursOfOperation;
  preferredProvider: boolean;
  qualityCode: string;
  virtualCareOffered?: string[];
  speciality: string;
  imageLocation: string;
  educationInfo: EducationInformation;
  premiumCareProvider: string;
  hasPCPServiceAvailability: boolean;
  isTieredProvider: boolean;
  providerEthnicityExpress: string;
  accessProvider: boolean;
  isEap: boolean;
  hasEveningAppointments: boolean;
  hasWeekendAppointment: boolean;
  practiceLimitations: PracticeLimitation;
  servicesOffered: string[];
  firstName?: string;
  middleName?: string;
  lastName?: string;
  costIndicator?: string;
  surveyCount?: number;
}

export type PracticeLimitation = { endAge: string; startAge: string };

type Degree = {
  degreeCode: string;
  isPrimary: boolean;
}[];

export type EducationInformation = {
  degrees: Degree;
  schoolName: string;
}[];

export type HoursOfOperation = {
  sunday: String;
  monday: String;
  tuesday: String;
  wednesday: String;
  thursday: String;
  friday: String;
  saturday: String;
};

export type SelectedProviderList = {
  providerName: string;
  providerType: string;
  providerId: string;
  primaryDegrees: string[];
  speciality: string;
  address: {
    line: string[];
  };
  phones: {
    phone: string[];
  };
  locationId: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
}[];
export interface GetHealthGradesBulkInfoType {
  healthgradesGetBulkInfo: {
    firstName?: string;
    lastName?: string;
    npi?: string;
    media?: {
      hasDisplayImage?: boolean;
      photoUrls?: {
        fullUrl?: string;
        type?: string;
      }[];
    };
    surveys?: {
      averageRating?: number;
      comment?: {
        answers?: {
          officeEnvironmentSatisfaction: boolean | null;
          officeSchedulingSatisfaction: boolean | null;
          providerAnswersSatisfaction: boolean | null;
          providerExplanationSatisfaction: boolean | null;
          providerStaffSatisfaction: boolean | null;
          providerTimeAllocationSatisfaction: boolean | null;
          providerTrustSatisfaction: boolean | null;
          waitTimeSatisfaction: boolean | null;
        };
        commentText?: string;
        approvedDate?: string;
        commentTitle?: string;
        helpfulCount?: number;
        id?: any;
        notHelpfulCount?: number;
      };
      displayName?: string;
      id?: string;
      location?: string;
      submittedDate?: string;
    }[];
    aboutMe?: {
      autoBiography?: string;
    };
    aggregatedSurvey?: {
      answers?: {
        negative?: {
          count?: number;
          title?: string;
        }[];
        positive?: {
          count?: number;
          title?: string;
        }[];
      };
      averageRating?: number;
      commentCount?: number;
      fiveStarCount?: number;
      fourStarCount?: number;
      oneStarCount?: number;
      surveyCount?: number;
      threeStarCount?: number;
      twoStarCount?: number;
    };
  }[];
}
export interface GetHealthGradesBulkInfoVariables {
  npi: string[];
}
