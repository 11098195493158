import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Carousel, Slide } from '@abyss/web/ui/Carousel';
import { useTranslation } from 'react-i18next';

import { benefitsCarouselScreen } from '../../ConstantsStyles';
import { slideContainerStyle } from '../../MyBenefits/MyBenefits.style';
import { makeAllSlidesAriaHidden } from '../../Utils/a11yUtils/a11yUtils';
import { carouselStyle } from '../WhereToGetHelp.Style';
import { WhereToGetHelpContainer } from '../WhereToGetHelpContainer/WhereToGetHelpContainer';

interface WhereToGetHelpDataProps {
  id: string;
  key: string;
  helpTitle: string;
  helpDescription: string;
  helpCTA: string;
  helpLinkText: string;
  linkUrl: string;
  cardColor: string;
  icon: string;
  accessibilityText: string;
  dntHelpKey: any;
}
interface Props {
  whereToGetHelpContent: WhereToGetHelpDataProps[];
}

interface BoxContentProps {
  whereToGetHelpData: WhereToGetHelpDataProps;
  index: number;
}

export const WhereToGetHelpCard = ({ whereToGetHelpContent }: Props) => {
  const displayCarousel = useMediaQuery(benefitsCarouselScreen);
  const BoxContent = ({ whereToGetHelpData, index }: BoxContentProps) => {
    const { t } = useTranslation();
    const {
      helpTitle,
      helpDescription,
      icon,
      linkUrl,
      accessibilityText,
      helpLinkText,
    } = whereToGetHelpData;

    const cardKeyValues = {
      cardsBackgrounds: {
        0: '$whereToGetHelpCardBg1',
        1: '$whereToGetHelpCardBg2',
        2: '$whereToGetHelpCardBg3',
      },
    };

    return (
      <WhereToGetHelpContainer
        accessibilityText={`${t(accessibilityText)}`}
        buttonLabel={`${t(helpLinkText)}`}
        dntHelpKey={whereToGetHelpContent}
        cardBGColor={cardKeyValues.cardsBackgrounds[index % 3]}
        cardContent={helpDescription}
        cardIcon={icon}
        cardTitle={helpTitle}
        url={linkUrl}
      />
    );
  };

  const slides = whereToGetHelpContent?.map((whereToGetHelpData, i) => (
    <Slide>
      <Slide.Container css={slideContainerStyle}>
        <BoxContent index={i} whereToGetHelpData={whereToGetHelpData} />
      </Slide.Container>
    </Slide>
  ));

  return (
    <Carousel
      css={
        (displayCarousel || whereToGetHelpContent.length > 2) &&
        whereToGetHelpContent.length !== 1
          ? carouselStyle
          : {
              ...carouselStyle,
              'abyss-carousel-minimal-controls': {
                display: 'none',
              },
            }
      }
      minimal
      nextSlideOnClick={() => makeAllSlidesAriaHidden()}
      noLoop
      prevSlideOnClick={() => makeAllSlidesAriaHidden()}
      slideIndexOnClick={() => makeAllSlidesAriaHidden()}
      slides={slides}
      slidesPerView={1}
      variant="none"
    />
  );
};
