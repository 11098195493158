export const educationalContentModalStyles = {
  'abyss-modal-content-container': {
    width: '624px',
    padding: '16px 0px 8px 0px',
  },
  '&.abyss-modal-content-title': {
    padding: '16px 0px 0px 0px',
  },
};
export const educationalContentModalInfoStyles = {
  'abyss-modal-section-root': {
    color: '#333333',
    padding: '0px 16px 8px 16px',
  },
};
