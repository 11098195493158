import { config } from '@abyss/web/tools/config';
import { useState } from 'react';

import { useCustomQuery } from '../useCustomQuery';
import GET_PHYSICIAN_DIRECTORY_QUERY from './PhysicianDirectory.graphql';

export const usePhysicianDirectory = (options) => {
  const [search, setSearch] = useState();
  const [queryResult, queryAction] = useCustomQuery(
    GET_PHYSICIAN_DIRECTORY_QUERY,
    {
      ...options,
      url: config('GRAPHQL_API_URL'),
      accessor: 'physicianDirectory',
    }
  );
  const customQueryAction = (params) => {
    queryAction(params);
    setSearch(params.variables.providerName);
  };

  const parsedResult = {
    ...queryResult,
    providerNameToSearch: search,
  };
  return [parsedResult, customQueryAction];
};
