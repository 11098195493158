import { Button } from '@abyss/web/ui/Button';
import { Divider } from '@abyss/web/ui/Divider';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import includes from 'lodash/includes';
import toUpper from 'lodash/toUpper';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Constants } from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { LoadingSpinnerComp } from '../../../../common/Loader';
import { MemberInfo } from '../../../../common/PSXHeader/ObapiDemo/memberProfile';
import {
  getGeoLocationFromStorage,
  setGeoLocationToStorage,
} from '../../../../common/PSXHeader/SearchBar/utils';
import { getFeatureFlag } from '../../../../common/Utils';
import { findLoggedInMember } from '../../../../common/Utils/memberUtils/memberUtils';
import { CountySearchContext } from '../../../../context/CountySearchContext';
import { useDeviceLocation } from '../../../../hooks/useDeviceLocation';
import { useLagoon } from '../../../../hooks/useLagoon';
import { useLocation } from '../../../../hooks/useLocation';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { CustomLocation, Location } from '../../../../models/Location';
import { getMemberLocation } from '../../../../utils/psxHeader.utils';
import { LocationInput } from './LocationInput';

interface Props {
  children?: React.ReactNode;
  handleOnClose?: () => void;
  initialLocationStatus?: string;
  isOpenLocationChange?: Boolean;
}

export const SearchLocation = ({
  children,
  handleOnClose,
  initialLocationStatus = '',
  isOpenLocationChange = false,
}: Props) => {
  const featureFlags = useLagoon('feature-flags')();
  const enableSearchLocation = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_SEARCH_LOCATION
  );

  if (!enableSearchLocation) {
    return <React.Fragment>{children}</React.Fragment>;
  }
  const { t } = useTranslation();
  const heading = `${Constants.SET_LOCATION.LOCATION}`;
  const obapimembers = useStore(StoreKeys.OBAPI_MEMBERS);
  const big5 = useStore(StoreKeys.PLAN_SELECTOR);
  const [memberProfiles] = useState<MemberInfo[]>(obapimembers);
  const loggedInMemberIndex = findLoggedInMember(memberProfiles, big5.bigN);
  const memberLocation = getMemberLocation(memberProfiles[loggedInMemberIndex]);
  const { setIsCountySearch } = useContext(CountySearchContext);
  const [isDevLocLoading, setIsDevLocLoading] = useState(false);
  const locationStates = {
    HAS_LOCATION: 'HAS_LOCATION',
    LOADING: 'LOADING',
    LOCATION_NOT_FOUND: 'LOCATION_NOT_FOUND',
  };
  const geoLocationName =
    getGeoLocationFromStorage()?.name &&
    getGeoLocationFromStorage()?.stateCode?.trim()
      ? getGeoLocationFromStorage()?.name
      : '';
  const [currentLocationStatus, setCurrentLocationStatus] = useState<string>(
    geoLocationName ? locationStates.HAS_LOCATION : initialLocationStatus
  );
  const [customLocation, setCustomLocation] = useState<CustomLocation>();

  const [, getLocation] = useLocation({
    onCompleted: (result) => {
      const targetLocation: Location =
        result?.data?.location?.features?.[0] || {};
      const {
        id,
        center = [],
        place_name: placeName = '',
        stateCode = '',
        zipCode = '',
      } = targetLocation;
      const [long, lat] = center;
      // set geo coords
      if (includes(toUpper(id), toUpper(Constants.DISTRICT)))
        setIsCountySearch(true);
      else setIsCountySearch(false);

      const location = {
        id,
        name: placeName,
        longitude: long,
        latitude: lat,
        stateCode,
        zipCode,
      };
      setGeoLocationToStorage(location);
      setCurrentLocationStatus(locationStates.HAS_LOCATION);
    },
  });
  const { getDeviceLocation } = useDeviceLocation(() => {}, setIsDevLocLoading);

  useEffect(() => {
    if (isOpenLocationChange) {
      setCurrentLocationStatus(locationStates.LOCATION_NOT_FOUND);
    }
  }, [isOpenLocationChange]);
  useEffect(() => {
    if (!isDevLocLoading && currentLocationStatus === locationStates.LOADING) {
      const loc =
        getGeoLocationFromStorage()?.name &&
        getGeoLocationFromStorage()?.stateCode?.trim();
      if (loc) {
        setCurrentLocationStatus(locationStates.HAS_LOCATION);
      } else if (memberLocation && !isOpenLocationChange) {
        getLocation({
          variables: {
            address: memberLocation,
            countySearchEnabled: true,
          },
        });
      } else {
        setCurrentLocationStatus(locationStates.LOCATION_NOT_FOUND);
      }
    }
  }, [isDevLocLoading, currentLocationStatus]);
  useEffect(() => {
    if (!currentLocationStatus) {
      setCurrentLocationStatus(locationStates.LOADING);
      setIsDevLocLoading(true);
      getDeviceLocation();
    }
  }, [currentLocationStatus]);

  return (
    <React.Fragment>
      {(() => {
        switch (currentLocationStatus) {
          case locationStates.LOADING:
            return <LoadingSpinnerComp isLoading />;
          case locationStates.HAS_LOCATION:
            return children;
          case locationStates.LOCATION_NOT_FOUND:
            return (
              <Flex
                alignContent="stretch"
                alignItems="center"
                data-auto-testid="search-location-view"
                data-testid="search-location-view"
                direction="column"
              >
                <Layout.Stack alignLayout="center">
                  <IconBrand
                    css={{
                      marginTop: '52px',
                    }}
                    data-auto-testid="location-icon"
                    data-testid="location-icon"
                    icon="location_pin"
                    size="84px"
                    variant="twotone"
                  />
                </Layout.Stack>
                <Layout.Stack
                  alignItems="center"
                  css={{
                    display: 'flex',
                    width: '472px',
                    textAlign: 'center',
                    padding: '16px',
                  }}
                >
                  <Heading
                    css={{
                      '@screen < $md': {
                        '&.abyss-heading-root': {
                          alignSelf: 'stretch',
                          fontSize: '36.49px !important',
                          lineHeight: '44px !important',
                          fontWeight: '600',
                        },
                      },
                    }}
                    data-auto-testid="heading-location"
                    data-testid="heading-location"
                  >
                    {t(heading)}
                  </Heading>
                  <Text
                    css={{
                      color: '$neutralGray7 !important',
                      '@screen < $md': {
                        '&.abyss-heading-root': {
                          fontSize: '18px !important',
                          fontWeight: '500',
                          lineHeight: '24px !important',
                        },
                      },
                    }}
                    data-auto-testid="location-Title-info-text"
                    data-testid="location-Title-info-text"
                  >
                    {t('LocationTitleInfoText')}
                  </Text>
                </Layout.Stack>
                <Layout.Stack
                  className="full-width-layout-stack"
                  css={{
                    width: '375px',
                    padding: '12px 16px 16px 16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    gap: '16px',
                    '&.full-width-layout-stack': {
                      alignItems: 'flex-start',
                      'div:nth-child(2)': {
                        width: '100%',
                      },
                    },
                  }}
                >
                  <Text
                    css={{
                      color: '$primary1',
                      fontWeight: '600 !important',
                      '@screen < $md': {
                        '&.abyss-heading-root': {
                          fontSize: '18px !important',
                          lineHeight: '24px !important',
                          textAlign: 'left !important',
                        },
                      },
                    }}
                    data-auto-testid="location-input-info-text"
                    data-testid="location-input-info-text"
                  >
                    {t('LocationInputInfoText')}
                  </Text>
                  <LocationInput
                    findUserLocation={getDeviceLocation}
                    setCustomLocation={setCustomLocation}
                  />
                </Layout.Stack>
                <Divider height="1px" />
                <Layout.Stack
                  css={{
                    width: '375px',
                    padding: '12px 16px 16px 16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '16px',
                    'div:nth-of-type(1)': {
                      width: '100%',
                    },
                  }}
                >
                  <Button
                    css={{
                      width: '100%',
                    }}
                    data-auto-testid="submit-button"
                    data-testid="submit-button"
                    isDisabled={!customLocation?.place_name}
                    onClick={() => {
                      if (
                        includes(
                          toUpper(customLocation?.id),
                          toUpper(Constants.DISTRICT)
                        )
                      )
                        setIsCountySearch(true);
                      const location: CustomLocation = {
                        id: customLocation?.id,
                        name: customLocation?.place_name,
                        longitude: customLocation?.center
                          ? customLocation.center[0]
                          : customLocation?.longitude,
                        latitude: customLocation?.center
                          ? customLocation.center[1]
                          : customLocation?.latitude,
                        stateCode: customLocation?.stateCode,
                        zipCode: customLocation?.zipCode,
                      };
                      setGeoLocationToStorage(location);
                      setCurrentLocationStatus(locationStates.HAS_LOCATION);

                      if (handleOnClose) {
                        handleOnClose();
                      }
                    }}
                    size="$md"
                    type="submit"
                  >
                    {t('Apply')}
                  </Button>
                </Layout.Stack>
              </Flex>
            );
          default:
            return null;
        }
      })()}
    </React.Fragment>
  );
};
