export const BrokenLinkSvg = (
  <svg
    fill="none"
    height="173"
    viewBox="0 0 175 173"
    width="175"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_15059_247745)">
      <path
        d="M32.8375 173C26.3439 173 19.9961 171.096 14.5967 167.53C9.1973 163.963 4.98869 158.895 2.50292 152.964C0.0171548 147.034 -0.634166 140.508 0.6313 134.212C1.89677 127.915 5.0222 122.131 9.61249 117.591L30.775 96.6699C36.9512 90.669 45.2752 87.3229 53.9349 87.3599C62.5947 87.397 70.8891 90.8141 77.0125 96.8676C83.1359 102.921 86.5926 111.121 86.6301 119.681C86.6675 128.242 83.2827 136.471 77.2125 142.577L56.05 163.497C53.0082 166.519 49.3905 168.915 45.4062 170.546C41.4218 172.177 37.1499 173.011 32.8375 173ZM54 99.4873C51.3248 99.4801 48.6747 99.9974 46.203 101.009C43.7313 102.021 41.487 103.507 39.6 105.382L18.4375 126.302C16.3688 128.126 14.6986 130.348 13.5292 132.833C12.3598 135.317 11.7158 138.012 11.6367 140.751C11.5575 143.49 12.045 146.216 13.0691 148.762C14.0932 151.309 15.6324 153.622 17.5924 155.559C19.5524 157.497 21.892 159.018 24.4678 160.031C27.0436 161.043 29.8013 161.525 32.5721 161.447C35.3428 161.369 38.0683 160.732 40.5814 159.576C43.0946 158.42 45.3425 156.769 47.1875 154.724L68.35 133.803C71.1783 130.988 73.1022 127.41 73.8799 123.518C74.6577 119.626 74.2546 115.593 72.7214 111.927C71.1882 108.26 68.5931 105.123 65.2623 102.91C61.9314 100.697 58.0132 99.5061 54 99.4873Z"
        fill="#002677"
      />
      <path
        d="M121 85.8327C114.507 85.8299 108.161 83.9245 102.764 80.3574C97.3664 76.7902 93.1598 71.7213 90.6756 65.7913C88.1913 59.8614 87.5411 53.3365 88.8069 47.0413C90.0728 40.7461 93.198 34.9631 97.7875 30.4233L118.95 9.50262C125.126 3.50177 133.45 0.155656 142.11 0.192687C150.77 0.229718 159.064 3.6469 165.188 9.70035C171.311 15.7538 174.768 23.9534 174.805 32.5142C174.843 41.075 171.458 49.3038 165.388 55.4094L144.225 76.33C141.182 79.3535 137.562 81.7501 133.575 83.3812C129.589 85.0124 125.315 85.8456 121 85.8327ZM142.163 12.3571C139.486 12.3516 136.836 12.8708 134.364 13.8847C131.892 14.8985 129.649 16.387 127.763 18.2638L106.6 39.1474C103.139 42.9833 101.294 47.9827 101.442 53.1205C101.591 58.2583 103.722 63.1453 107.398 66.7798C111.075 70.4143 116.018 72.5208 121.215 72.6675C126.413 72.8142 131.47 70.99 135.35 67.5688L156.513 46.6482C159.326 43.83 161.238 40.2543 162.007 36.3682C162.777 32.4821 162.37 28.458 160.839 24.7989C159.308 21.1398 156.72 18.0081 153.398 15.7952C150.076 13.5823 146.168 12.3865 142.163 12.3571Z"
        fill="#00BED5"
      />
      <path
        d="M67.2625 69.2371L70.7875 65.7524L58.25 53.3582L53.15 55.2859L67.2625 69.2371Z"
        fill="#FF681F"
      />
      <path
        d="M77.575 61.3285L82.1875 59.4502L75.425 43.2376L69.9625 43.0894L77.575 61.3285Z"
        fill="#FF681F"
      />
      <path
        d="M62.2375 76.4907L60.3375 81.0505L43.95 74.3653L43.7875 68.9652L62.2375 76.4907Z"
        fill="#FF681F"
      />
      <path
        d="M105.212 106.753L108.737 103.269L121.275 115.663L119.325 120.705L105.212 106.753Z"
        fill="#FF681F"
      />
      <path
        d="M113.212 96.5587L115.112 91.9989L131.512 98.6841L131.662 104.084L113.212 96.5587Z"
        fill="#FF681F"
      />
      <path
        d="M97.875 111.721L93.2625 113.599L100.025 129.812L105.488 129.96L97.875 111.721Z"
        fill="#FF681F"
      />
      <path
        d="M82.2375 82.9906L60.5625 104.418C59.6598 105.608 59.2216 107.079 59.3282 108.563C59.4349 110.047 60.0793 111.442 61.1431 112.494C62.2069 113.546 63.6188 114.183 65.1195 114.288C66.6201 114.393 68.1089 113.96 69.3125 113.068L87.0625 95.5207L82.2375 82.9906Z"
        fill="#FF681F"
      />
      <path
        d="M115.1 59.2154C113.928 58.0609 112.342 57.4126 110.688 57.4126C109.033 57.4126 107.447 58.0609 106.275 59.2154L88.4625 76.8367L93.35 89.4286L115.1 67.9643C115.682 67.3904 116.144 66.7085 116.459 65.9579C116.774 65.2073 116.936 64.4026 116.936 63.5899C116.936 62.7772 116.774 61.9725 116.459 61.2218C116.144 60.4712 115.682 59.7894 115.1 59.2154Z"
        fill="#FF681F"
      />
    </g>
    <defs>
      <clipPath id="clip0_15059_247745">
        <rect fill="white" height="173" width="175" />
      </clipPath>
    </defs>
  </svg>
);
