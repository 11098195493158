import {
  Address,
  Coverage,
  CoverageEffDateRange,
  Demographics,
  Eligibility,
  MemberCoverage,
  MemberInfo,
  Name,
  PortalMemberInfo,
  RelationshipTypeCode,
  TypeCode
} from './PortalMemberInfo';
import { GuestMemberInfo } from './GuestPortalMemberInfo';
// PSX Data

const address: Address = {
  addressType: 'PERMANENT',
  address1: '20 W 34th St',
  city: 'New York',
  state: 'NY',
  zipCode: '10001',
  zip4: '1234',
};

const relationshipTypeCode: RelationshipTypeCode = {
  code: 'SUBSCRIBER',
};

const name: Name = {
  firstName: 'TERESA',
  lastName: 'ZAPIEN HIMOJOS',
};

const demographics: Demographics = {
  dateOfBirth: '09-01-2000',
  dependentSeqNbr: '00',
  address: [address],
  relationshipTypeCode,
  name,
  gender: '',
};

const coverageEffDateRange: CoverageEffDateRange = {
  startDate: '2024-01-01',
  endDate: '2099-12-31',
};

const typeCode: TypeCode = {
  desc: 'BEHAVIORAL CARVE-OUT',
  coverageTypeCode: 'OBH',
};

const coverageType: Coverage = {
  groupNumber: '0915181',
  policyNumber: '0915181',
  coverageEffDateRange,
  typeCode,
  reciprocityId: '100',
};

const memberHealthCoverage: MemberCoverage = {
  coverageType: [coverageType],
};

const eligibility: Eligibility = {
  memberHealthCoverage,
};

export const memberInfo: MemberInfo = {
  demographics,
  eligibility: [eligibility],
  lineOfBusiness: 'OBH',
  policyNumber: '0915181',
};

export const mockGuestMemberInfo: GuestMemberInfo = {
  eligibility: [eligibility],
  lineOfBusiness: 'OBH',
  policyNumber: '100',
};

export const mockPortalMemberInfo: PortalMemberInfo = {
  memberInfo: [memberInfo],
};
