import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import {
  convertUrlParamsToObject,
  getOnlyDefinedValuesFromObject,
} from '../../common/Utils';
import { ErrorState, ErrorStore, StoreKeys } from './errorStore';

const store = (set) => ({
  [StoreKeys.ERROR_STATE]: {},
  [StoreKeys.SET_ERROR_STORE]: (values: ErrorState) =>
    set((state) => ({
      [StoreKeys.ERROR_STATE]: { ...state[StoreKeys.ERROR_STATE], ...values },
    })),
  [StoreKeys.CLEAR_ERROR_STORE]: (defaultValues = {}) =>
    set({ [StoreKeys.ERROR_STATE]: { ...defaultValues } }),
});

const errorStore = persist(store, {
  name: 'errorStore',
  storage: createJSONStorage(() => sessionStorage),
  merge: (persistedState: ErrorStore, currentState: ErrorStore): ErrorStore => {
    const urlParams = new URLSearchParams(window.location.search);
    const { message, code, source, uiSection }: any = convertUrlParamsToObject(
      urlParams.entries()
    );

    const values = getOnlyDefinedValuesFromObject({
      message,
      code,
      source,
      uiSection,
    });

    return {
      ...currentState,
      ...persistedState,
      errorState: {
        ...currentState?.errorState,
        ...persistedState?.errorState,
        ...values,
      },
    };
  },
}) as any;

export const useErrorStore = create(devtools(errorStore));
