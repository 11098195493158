import { config } from '@abyss/web/tools/config';

import { useCustomQuery } from '../useCustomQuery';
import SubmitProviderReview from './SubmitProviderReview.graphql';

export const useHealthgradesSubmitProviderReview = (options) =>
  useCustomQuery(SubmitProviderReview, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'healthgradesSubmitProviderReview',
  });
