import { SnackInfoCard } from './SnackCard.styles';
import { SnackCardSkeleton } from './SnackCardSkeleton';

export type Props = {
  isLoading: boolean;
  mobileScreen: boolean;
};
export const SnackCardLoader = ({ isLoading, mobileScreen }: Props) => (
  <SnackInfoCard
    alignContent="center"
    alignItems="center"
    direction="column"
    isLoading={isLoading}
    justify="space-between"
  >
    <SnackCardSkeleton mobileScreen={mobileScreen} />
  </SnackInfoCard>
);
