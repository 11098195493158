export const MockNullProvider = {
  address: {
    line: [''],
    city: '',
    district: '',
    state: '',
    postalCode: '',
  },
  phones: {
    phone: [''],
    emergency: [''],
    appointment: [''],
    fax: [''],
  },
  distance: '',
  facilityType: '',
  gender: '',
  latitude: '',
  locationId: '',
  longitude: '',
  networkStatus: '',
  practitionerRoleId: '',
  professionalDesignation: '',
  providerId: '',
  providerName: 'Mock Null Provider for QA',
  specialities: [],
  primaryDegrees: [],
  providerType: '',
  acceptingNewPatients: null,
  efficiencyCode: '',
  languagesSpoken: [''],
  hoursOfOperation: {
    sunday: '',
    monday: '',
    tuesday: '',
    wednesday: '',
    thursday: '',
    friday: '',
    saturday: '',
  },
  qualityCode: '',
  virtualCareOffered: null,
  speciality: '',
  healthGradeRating: '',
  premiumCareProvider: null,
  hasPCPServiceAvailability: null,
  isTieredProvider: null,
  educationInfo: [
    {
      degrees: [
        { degreeCode: '', isPrimary: false },
        { degreeCode: '', isPrimary: false },
      ],
      schoolName: '',
    },
    {
      degrees: [
        { degreeCode: '', isPrimary: false },
        { degreeCode: '', isPrimary: false },
      ],
      schoolName: '',
    },
  ],
  providerEthnicityExpress: '',
  accessProvider: null,
  hasEveningAppointments: null,
  hasWeekendAppointment: null,
  practiceLimitations: {},
  servicesOffered: [],
  costIndicator: null,
};
