import { getDistance } from '../../common/Utils/dataCardUtils';
import {
  CompareCheckboxInfo,
  NULL_ATTRIBUTE_STATE,
} from '../../frontends/ProviderSearch/routes/MapView/CompareDrawer/utility/compareDrawerConstants';
import {
  getNetworkStatus,
  getProviderGender,
  getProviderLanguages,
  getProviderSpecialty,
  getStatusText,
} from '../../frontends/ProviderSearch/routes/MapView/ShareDrawer/utils';

const getIsChecked = (
  editCheckItems: string,
  providerInformation,
  compareCheckboxes: CompareCheckboxInfo[]
) =>
  compareCheckboxes.find((item) => item.name === editCheckItems)?.isChecked
    ? `${providerInformation}`
    : '';

type ProviderList = {
  acceptingNewPatients;
  distance: string;
  gender: string;
  languagesSpoken: string[];
  networkStatus: string;
  providerName: string;
  providerId: string;
  preferredProvider: boolean;
  primaryDegrees: string[];
  speciality: string;
  virtualCareOffered: boolean;
  address: {
    line: string[];
    city: string;
    state: string;
  };
  phones: {
    phone: string[];
  };
  locationId: string;
}[];

export const getEmailBodyHTML = (
  shareTitle: string,
  selectedProviderList: ProviderList,
  compareCheckboxes: CompareCheckboxInfo[]
): string => {
  let emailBodyHtml = `<div><div><b>
     ${shareTitle}</b>
     </div>`;
  selectedProviderList.forEach((provider) => {
    const gender: string = getProviderGender(provider.gender);
    const address: string = `${provider?.address?.line?.[0]}, ${provider?.address?.city}, ${provider?.address?.state}`;
    const speciality: string = getProviderSpecialty(provider.speciality);
    const languages = getProviderLanguages(provider?.languagesSpoken);
    const acceptingNewPatients: string = getStatusText(
      provider.acceptingNewPatients,
      'Accepting new patients',
      'Not accepting new patients',
      NULL_ATTRIBUTE_STATE.ACCEPTING_NEW_PATIENTS
    );
    const virtualCare: string = getStatusText(
      provider.virtualCareOffered,
      'Virtual care offered',
      'Virtual care not offered',
      NULL_ATTRIBUTE_STATE.VIRTUAL_CARE_OFFERED
    );
    const preferredProvider: string = getStatusText(
      provider.preferredProvider,
      'Preferred provider',
      'Not a preferred provider',
      NULL_ATTRIBUTE_STATE.PREFERRED_PROVIDER
    );
    const networkStatus: string = getNetworkStatus(provider.networkStatus);

    emailBodyHtml += `<div style="margin-top:15px"><b>${
      provider.providerName
    }</b></div>
       <div>${provider.phones?.phone[0]}</div>
       <div>${networkStatus}</div>
       ${getIsChecked(
         'specialties',
         `<div>${speciality}</div>`,
         compareCheckboxes
       )}
       ${getIsChecked(
         'reviews',
         `<div>${'Rating - 4'}</div>`,
         compareCheckboxes
       )}
       ${getIsChecked(
         'distance',
         `<div>${address}\n${getDistance(
           provider.distance
         )} ${'miles away'}</div>`,
         compareCheckboxes
       )}
       ${getIsChecked('gender', `<div>${gender}</div>`, compareCheckboxes)}
       ${getIsChecked(
         'languagesSpoken',
         `<div>${languages}</div>`,
         compareCheckboxes
       )}
       ${getIsChecked(
         'acceptingNewPatients',
         ` <div>${acceptingNewPatients}</div>`,
         compareCheckboxes
       )}
       ${getIsChecked(
         'virtualCareOffered',
         `<div>${virtualCare}</div>`,
         compareCheckboxes
       )}
       ${getIsChecked(
         'preferredProvider',
         `<div>${preferredProvider}</div>`,
         compareCheckboxes
       )}
       ${
         // Cost to be included near future
         getIsChecked(
           'hoursOfOperation',
           `<div>${'TBD'}</div>`,
           compareCheckboxes
         )
       }
   <div style="margin-top:10px"></div>
   </div>`;
  });
  emailBodyHtml += `<div>[${window.location.href}]</div>`;
  return emailBodyHtml;
};
