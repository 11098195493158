import { Constants } from '../../../../common/Constants';

export const modifyCategory = (
  category,
  search,
  isPseudoRollupSearch,
  isConditionSearch
) => {
  if (
    (category.chipCategory === Constants.VISION &&
      search === Constants.ONLINE_PROVIDERS) ||
    isPseudoRollupSearch ||
    isConditionSearch
  ) {
    return {
      ...category,
      name: '',
      includeSpecialityRollupCodes: [''],
    };
  } else {
    return {
      ...category,
      name: search,
      includeSpecialityRollupCodes: category.includeSpecialityRollupCodes,
    };
  }
};
