export const FillBar = {
  width: '96px',
  height: '6px',
  padding: 0,
};

export const ratingContainerStyle = (phoneScreen: boolean) => ({
  'abyss-flex-root': {
    marginBottom: '$lg',
    marginRight: '$lg',
    ...(phoneScreen && {
      marginRight: '0',
    }),
  },
});

export const RatingHeader = {
  'abyss-text-root': {
    fontSize: '$md',
    fontWeight: '$bold',
    lineHeight: '$md',
    maxWidth: '343px',
    color: '$neutralGray',
    marginBottom: '$sm',
  },
};
export const RatingText = {
  'abyss-text-root': {
    fontSize: '$extraSmallText',
    fontWeight: '$medium',
    lineHeight: '$sm',
    maxWidth: '343px',
    color: '$neutralGray',
    marginTop: '$sm',
    textTransform: 'uppercase',
  },
};
