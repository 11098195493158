import { styled } from '@abyss/web/tools/styled';
import { Heading } from '@abyss/web/ui/Heading';

export const AboutHeading = styled(Heading, {
  '&.abyss-heading-root': {
    fontSize: '20.25px !important',
    lineHeight: '24px !important',
  },
});

export const ReviewsContainer = styled('div', {
  border: '1px solid #E5E5E6',
  borderLeft: 'none',
  borderRight: 'none',
  marginBottom: '0px',
  boxSizing: 'border-box',
});

export const ReviewsContentContainer = styled('div', {
  gap: '12px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  width: '100%',
  height: 'auto',
  background: '$white',
  flex: 'none',
  order: '3',
  flexGrow: '0',
});
