import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { Heading } from '@abyss/web/ui/Heading';
import { Text } from '@abyss/web/ui/Text';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeFuncErrorEvent } from '../../common/AdobeTagging/adobeFuncErrorEvent';
import { ContentWrapper } from '../../common/ContentWrapper';
import { PSXHeader } from '../../common/PSXHeader';
import { StillNeedHelp } from '../../common/StillNeedHelp';
import { BodyWrapper, DefaultErrorWrapper, textBody } from './Page500.styled';

export const Page500 = () => {
  const { t } = useTranslation();
  const breadcrumbs = [{ title: t('ERROR_PAGE.500.title'), href: '' }];

  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = tokenizer.parse(token) || {};
  const { message, code, source, uiSection } = tokenData;

  useEffect(() => {
    adobeFuncErrorEvent({
      message,
      source,
      code: code ?? '500',
      uiSection,
    });
  }, []);

  return (
    <React.Fragment>
      <PSXHeader
        breadcrumbs={breadcrumbs}
        dataTestId="error-500-search-form"
        showChips={false}
        showChoosePCPHeader={false}
        showDemoHeader={false}
        showMemberSelection={false}
      />
      <DefaultErrorWrapper>
        <ContentWrapper>
          <Text
            css={{
              'abyss-text-root': {
                marginBottom: '1.25rem',
                color: '#D14600',
                fontSize: '12.64px',
                fontWeight: '$bold',
              },
            }}
          >
            {t('ERROR_PAGE.500.type')}
          </Text>
          <Heading
            css={{
              'abyss-heading-root': {
                marginBottom: '1rem',
                marginTop: '1.25rem',
              },
            }}
            offset={0}
          >
            {t('ERROR_PAGE.500.heading')}
          </Heading>
          <Heading
            css={{
              'abyss-heading-root': {
                marginBottom: '1.5rem',
              },
            }}
            offset={5}
          >
            {t('ERROR_PAGE.500.subHeading')}
          </Heading>
          <BodyWrapper>
            <Text fontWeight={500}>{t('ERROR_PAGE.500.body1')}</Text>
            <Text
              css={textBody}
              dangerouslySetInnerHTML={{ __html: t('ERROR_PAGE.500.body2') }}
              fontWeight={500}
            />
            <Text fontWeight={500}>{t('ERROR_PAGE.500.body3')}</Text>
          </BodyWrapper>
        </ContentWrapper>
      </DefaultErrorWrapper>
      <StillNeedHelp />
    </React.Fragment>
  );
};
