/* eslint-disable no-nested-ternary */
import { Carousel } from '@abyss/web/ui/Carousel';
import { useTranslation } from 'react-i18next';

import { HealthGradesBulkInfo, Survey } from '../../../../models/Healthgrades';
import { makeAllSlidesAriaHidden } from '../../../Utils/a11yUtils/a11yUtils';
import { PatientVerbatimCard } from './PatientVerbatimCard';

const MAX_REVIEWS = 4;
type CardContentProps = {
  item: Survey;
};
type Props = {
  healthgradesReview: HealthGradesBulkInfo;
  setReviewModal: (a: Survey) => void;
};
const carouselStyles = {
  'abyss-carousel-root': {
    width: '100%',
  },
  'abyss-carousel-swiper-container': {
    display: 'flex',
    willChange: 'transform',
  },
  'abyss-carousel-slide-container': {
    position: 'relative',
    width: '312px',
    marginRight: '20px',
  },
  'abyss-carousel-minimal-controls': {
    maxHeight: '20px',
    marginTop: '12px',
    paddingBottom: '0',
    'button.abyss-carousel-minimal-pagination-container': {
      padding: '10px',
      display: 'block',
    },
  },
  'abyss-carousel-minimal-pagination-container': {
    paddingLeft: '$md',
    paddingRight: '$md',
  },
  'abyss-carousel-minimal-nav-container-right': {
    width: '20px',
    height: '20px',
    padding: '0px',
    top: '-3px',
  },
  'abyss-carousel-minimal-nav-container-left': {
    width: '20px',
    height: '20px',
    padding: '0px',
    top: '-3px',
  },
};

export const PatientReviewMobileCarousel = ({
  healthgradesReview,
  setReviewModal,
}: Props) => {
  const { t } = useTranslation();
  const CardContent = ({ item }: CardContentProps) => (
    <PatientVerbatimCard
      key={`provider-reviews-${item.id}`}
      setReviewModal={setReviewModal}
      survey={item}
    />
  );

  const slides = healthgradesReview?.surveys
    ?.slice(0, MAX_REVIEWS)
    .map((item, index, arr) => (
      <div
        aria-hidden="false"
        aria-label={`${t('slide')} ${index + 1} ${t('of')} ${arr.length}`}
        key={`provider-review-slide-${item?.id}`}
        role="group"
      >
        <CardContent item={item} />
      </div>
    ));

  return (
    <Carousel
      compact
      css={carouselStyles}
      minimal
      nextSlideOnClick={() => makeAllSlidesAriaHidden()}
      noLoop
      prevSlideOnClick={() => makeAllSlidesAriaHidden()}
      slideIndexOnClick={() => makeAllSlidesAriaHidden()}
      slides={slides}
      slidesPerView={1}
    />
  );
};
