export const PuzzleSvg = (
  <svg
    fill="none"
    height="176"
    viewBox="0 0 175 176"
    width="175"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_12737_194485)">
      <path
        d="M35.5197 137.298C39.7894 138.951 44.61 137.023 46.2627 132.753C47.9155 128.483 45.9873 123.663 41.7176 122.01L49.0174 104.105L100.116 124.902L79.3183 175.863L28.2199 155.065L35.5197 137.298Z"
        fill="#FF681F"
      />
      <path
        d="M84.2756 47.3592L74.6344 30.6937C70.6402 33.0351 65.6819 31.6578 63.3404 27.6636C60.999 23.6694 62.3763 18.711 66.3705 16.3696L56.7293 -0.295898L9.07422 26.9749L36.4828 74.7678L84.2756 47.3592Z"
        fill="#00BED5"
      />
      <path
        d="M125.731 40.8857L139.918 53.9702C136.888 57.2758 137.025 62.5096 140.331 65.6774C143.637 68.7075 148.87 68.5698 152.038 65.2642L166.225 78.3487L153.14 92.535C156.446 95.5651 156.721 100.799 153.553 104.242C150.523 107.548 145.289 107.823 141.846 104.655L128.762 118.842L114.713 105.62C117.743 102.314 117.605 97.0802 114.3 93.9124C110.994 90.8823 105.76 91.02 102.593 94.3256L88.4062 81.2411L101.491 67.0547C98.1852 64.0246 97.9097 58.7908 101.078 55.3475C104.108 52.042 109.341 51.7665 112.785 54.9343L125.731 40.8857Z"
        fill="#002677"
      />
    </g>
    <defs>
      <clipPath id="clip0_12737_194485">
        <rect fill="white" height="176" width="175" />
      </clipPath>
    </defs>
  </svg>
);
