import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import mapboxgl from 'mapbox-gl';
import React, { useEffect, useRef, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { useConfig } from '../../frontends/ProviderSearch/context/Analytics';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useMapKey } from '../../hooks/useMapKey';
import { StoreKeys } from '../../hooks/useStore/state';
import { useStore } from '../../hooks/useStore/useStore';
import { FacilityLocationsResponse } from '../../models/FacilityDetails';
import { Provider } from '../../models/Provider';
import { ProviderLocation } from '../../models/ProviderDetails';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { Directions } from '../../models/RouteDirections';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import {
  getDistanceString,
  getDurationString,
  getNavTypeString,
  getStepDistanceString,
  getStepIcon,
} from '../../utils/generalMap.utils';
import {
  clearRoute,
  escapeRegExp,
  getProviderResults,
  getRoute,
  loadMapPins,
  loadRoute,
  setMapboxKey,
} from '../../utils/map.utils';
import { Constants } from '../Constants';
import { phoneOnly } from '../ConstantsStyles';
import { ClusterCarousel } from './ClusterCarousel';
import { MapControlDesktop } from './MapControlDesktop';
import { MapControlMobile } from './MapControlMobile';
import { MapDirections } from './MapDirections';
import { MapContainer, NavigationContainer } from './MapDisplay.styled';

type Props = {
  closeSidePanel?: boolean;
  coords: { latitude: string; longitude: string };
  disabledPinAction?: boolean;
  headers?: ResponseHeaders;
  locationResults?: ProviderLocation[] | FacilityLocationsResponse[];
  map: any;
  isOpenCompareShare?: boolean;
  providerResults?: Provider[];
  routeEndCoords?: [number | null, number | null];
  setRouteEndCoords?(coords: [number | null, number | null]): void;
  selectedItems?: any[];
  disableCost?: boolean;
  mobileMapControlChildren?: React.ReactNode;
  mobileRouteView?: boolean;
  setPopupContent?(
    content: ProviderLocation | FacilityLocationsResponse | null
  ): void;
  refToBeFocusedOnDirectionsClose?: HTMLElement;
  updatePin?(
    content: ProviderLocation | FacilityLocationsResponse | null
  ): void;
  selectLocation?: (locationId, locationLng, locationLat) => void;
  directions?: Directions;
};

export const MapDisplay = ({
  coords,
  disabledPinAction = false,
  isOpenCompareShare = false,
  providerResults = [],
  locationResults = [],
  map,
  closeSidePanel,
  routeEndCoords = [null, null],
  setRouteEndCoords,
  selectedItems,
  disableCost,
  mobileMapControlChildren,
  mobileRouteView,
  setPopupContent,
  refToBeFocusedOnDirectionsClose,
  updatePin,
  selectLocation,
  directions,
  headers,
}: Props) => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const mapContainerTestId: string = 'map-view-container';
  const { longitude, latitude } = coords;
  const [navType, setNavType] = useState<string>('driving-traffic');
  const mapContainerRef = React.useRef<any>(null);
  const navigationContainerRef = useRef<HTMLElement>();
  const [highlightId, setHighlightId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_HIGHLIGHT_ID,
    { providerId: '', from: '' }
  );
  const [mapboxOnpremEnabled] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_ONPREM_MAPBOX,
  ]);
  const [, setSelectedId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    null
  );
  const { search, sectionType } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      search: state.typeaheadSearchStore.search,
      sectionType: state.typeaheadSearchStore.sectionType,
    }))
  );

  const [, setmapPinCoords] = useSessionStorage<
    (string[] | (string | undefined)[])[]
  >(Constants.STORAGE_KEYS.SESSION.MAP_PIN_COORDS, []);
  const [clusterStatus, setClusterStatus] = useState(false);
  const [currentRoute, setCurrentRoute] = useState<number>(0);
  const [showStepInstructions, setShowStepInstructions] =
    useState<boolean>(false);
  const mapOnPremKey = useConfig('MAPBOX_ONPREM_KEY');
  const mapKey = useConfig('MAPBOX_KEY');
  const mapOnPremURL = useConfig('MAPBOX_ONPREM_URL');

  const [mapDirections, setMapDirections] = useState<Directions>(
    directions || {
      userPlaceName: '',
      endPlaceName: '',
      routes: [],
    }
  );

  const hasRoute = routeEndCoords[0];

  useEffect(() => {
    if (directions) {
      setMapDirections(directions);
      setShowStepInstructions(false);
      setCurrentRoute(0);
    }
  }, [directions]);

  useEffect(() => {
    if (!highlightId.from) {
      setClusterStatus(false);
    }
  }, [highlightId]);

  const handlePinSelect = (newEndCoords, locationId?) => {
    if (setRouteEndCoords) {
      setRouteEndCoords(newEndCoords);
    }

    if (locationId) {
      setSelectedId(locationId);
    }
  };

  const [, getMapBoxKey] = useMapKey({
    onCompleted: (result) => {
      const { mapKey } = result;
      setMapboxKey(mapKey);
      let style = 'mapbox://styles/mapbox/streets-v12';
      if (mapboxOnpremEnabled) {
        mapboxgl.baseApiUrl = mapOnPremURL;
        const baseApiUrlEscaped = escapeRegExp(mapboxgl.baseApiUrl);
        Object.defineProperty(mapboxgl.config, 'API_URL_REGEX', {
          value: new RegExp(`^${baseApiUrlEscaped}`),
        });
        style = 'mapbox://styles/mapbox/streets-v11';
      }
      map.current = new mapboxgl.Map({
        accessToken: mapboxOnpremEnabled ? mapOnPremKey : mapKey,
        container: mapContainerRef.current,
        style,
        center: [longitude, latitude],
        zoom: Constants.DEVICE_LOCATION.ZOOM,
      });
      clearRoute(map.current, setRouteEndCoords);
      setHighlightId({ providerId: '', from: '' });
      map.current.on('load', async () => {
        const providerFeatures = getProviderResults(providerResults);
        const locationFeatures = locationResults.map(
          (location: ProviderLocation | FacilityLocationsResponse, index) => ({
            type: 'Feature',
            id: index.toString(),
            properties: {
              description: JSON.stringify(location),
              providerId: location.locationId,
              type: 'location',
            },
            geometry: {
              type: 'Point',
              coordinates: [location.longitude, location.latitude],
            },
          })
        );

        const featuresAr = [...providerFeatures, ...locationFeatures].filter(
          (featureItem) =>
            featureItem.geometry.coordinates[0] !== '00.00000' &&
            featureItem.geometry.coordinates[1] !== '00.00000'
        );

        const coordinates = [
          ...featuresAr.map((item) => item.geometry.coordinates),
          [longitude, latitude],
        ];

        const uniqueCoordinates: any[] = [];

        coordinates.forEach((item, index) => {
          const currentCoordinate = item;
          const addToLatitude = `00.0000${index}`;
          uniqueCoordinates.forEach((uniqueCoordinatesItem) => {
            if (
              uniqueCoordinatesItem.every(
                (value, index) => value === currentCoordinate[index]
              )
            ) {
              currentCoordinate[1] = (
                parseFloat(currentCoordinate[1] || '00.00000') +
                parseFloat(addToLatitude)
              ).toString();
            }
          });
          uniqueCoordinates.push(currentCoordinate);
        });

        setmapPinCoords(uniqueCoordinates);

        await map.current.isStyleLoaded();
        await loadMapPins(
          map.current,
          mapKey,
          longitude,
          latitude,
          featuresAr,
          uniqueCoordinates,
          setHighlightId,
          handlePinSelect,
          disabledPinAction,
          setPopupContent,
          updatePin
        );
      });
      // Clean up on unmount
      return () => map?.current?.remove();
    },
  });

  // Initialize map when component mounts
  useEffect(() => {
    getMapBoxKey({});
  }, [
    JSON.stringify(providerResults),
    JSON.stringify(locationResults),
    selectedItems,
    isOpenCompareShare,
  ]);

  useEffect(() => {
    const getMapRoute = async () => {
      const routeDirections = await getRoute(
        map.current,
        longitude,
        latitude,
        routeEndCoords[0],
        routeEndCoords[1],
        false,
        navType,
        false,
        0,
        mapboxOnpremEnabled,
        mapOnPremKey,
        mapKey,
        mapOnPremURL
      );
      if (routeDirections) setMapDirections(routeDirections);
    };
    if (routeEndCoords[0]) {
      setCurrentRoute(0);
      getMapRoute();
    }
  }, [navType]);

  useEffect(() => {
    setNavType('driving-traffic');
    if (hasRoute === null) {
      refToBeFocusedOnDirectionsClose?.focus();
    } else {
      navigationContainerRef?.current?.focus();
    }
  }, [hasRoute]);

  useEffect(() => {
    if (map.current) map.current.resize();
  }, [closeSidePanel, mobileRouteView]);

  const handleCloseNav = () => {
    clearRoute(map.current, setRouteEndCoords, highlightId.providerId);
    setSelectedId(null);
  };

  const mapControl = () => {
    if (mobileScreen)
      return (
        <MapControlMobile
          controlChildren={mobileMapControlChildren}
          map={map.current}
          mobileRouteView={!!routeEndCoords[0]}
        />
      );
    if (!mobileScreen) return <MapControlDesktop map={map.current} />;
    return null;
  };
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  return (
    <React.Fragment>
      <MapContainer
        cssProps={{
          clusterStatus,
          isOpenCompareShare,
          mobileRouteView,
          hasRoute,
          isWidget,
        }}
      >
        <div
          className="map-container"
          data-auto-testid={mapContainerTestId}
          data-testid={mapContainerTestId}
          ref={mapContainerRef}
        />
        {mapControl()}
        <NavigationContainer
          cssProps={{ hasRoute }}
          id="navigation-container-id"
          ref={navigationContainerRef}
          tabIndex="-1"
        >
          {hasRoute && mapDirections?.routes?.length && !isWidget ? (
            <MapDirections
              currentRoute={currentRoute}
              getDistanceString={getDistanceString}
              getDurationString={getDurationString}
              getNavTypeString={getNavTypeString}
              getStepDistanceString={getStepDistanceString}
              getStepIcon={getStepIcon}
              handleCloseNav={handleCloseNav}
              latitude={latitude}
              loadRoute={loadRoute}
              longitude={longitude}
              map={map}
              mapContainerRef={mapContainerRef}
              mapContainerTestId={mapContainerTestId}
              mapDirections={mapDirections}
              mobileScreen={mobileScreen}
              navType={navType}
              routeEndCoords={routeEndCoords}
              setCurrentRoute={setCurrentRoute}
              setNavType={setNavType}
              setShowStepInstructions={setShowStepInstructions}
              showStepInstructions={showStepInstructions}
            />
          ) : null}
        </NavigationContainer>
      </MapContainer>
      <ClusterCarousel
        data={[]}
        disableCost={disableCost}
        headers={headers}
        search={search}
        sectionType={sectionType}
        selectLocation={selectLocation}
      />
    </React.Fragment>
  );
};
