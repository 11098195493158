import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Popover } from '@abyss/web/ui/Popover';
import find from 'lodash/find';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { getMemberHealthCoverageType } from '../../utils/providerSearch.utils';
import { getCurrentMember } from '../../utils/user.utils';
import { CoverageTypesCodes } from '../Constants';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { landingDrawerIcon, phoneOnly } from '../ConstantsStyles';
import { dateFormatToDate } from '../Utils/datesUtils/formatDate';
import { InteractButton } from './DetailsPageHeader.styled';

export const DetailsPageSchedulePopover = () => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const [isOpen, setIsOpen] = useState(false);
  const data = useLagoon('ui-messaging')();
  const popoverContent = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS
      .PRE_EFFECTIVE_ONLINE_SCHEDULE_POPOVER_CONTENT,
  });
  const currentMember = getCurrentMember();
  const medicalCoverage = getMemberHealthCoverageType(
    currentMember?.eligibility,
    CoverageTypesCodes.MEDICAL
  );
  if (mobileScreen) {
    return (
      <Flex>
        <Drawer
          css={landingDrawerIcon}
          data-auto-testid="provider-scheduling-popover"
          data-testid="provider-scheduling-popover"
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          position="bottom"
          title={popoverContent?.title}
          titleAlign="left"
        >
          {`${popoverContent?.message}${dateFormatToDate(
            medicalCoverage?.coverageEffDateRange?.startDate
          )}.`}
        </Drawer>
        <InteractButton
          after={
            <IconMaterial color="$primary1" icon="info" variant="outlined" />
          }
          data-auto-testid="provider-details-chips-schedule"
          data-testid="provider-details-chips-schedule"
          key="provider-details-chips-schedule"
          onClick={() => setIsOpen(!isOpen)}
          variant="outline"
        >
          {t('DETAILS_CHIP_NAMES.Schedule')}
        </InteractButton>
      </Flex>
    );
  }
  return (
    <Popover
      aria-label={`${t('INFORMATION_ABOUT')} ${popoverContent?.title}`}
      content={`${popoverContent?.message}${dateFormatToDate(
        medicalCoverage?.coverageEffDateRange?.startDate
      )}.`}
      css={{
        'abyss-popover-content': {
          zIndex: '9',
          width: '220px',
          height: 'auto',
        },
      }}
      data-auto-testid="provider-scheduling-popover"
      data-testid="provider-scheduling-popover"
      onOpenChange={() => {
        setIsOpen(!isOpen);
      }}
      open={isOpen}
      title={popoverContent?.title}
    >
      <InteractButton
        after={
          <IconMaterial color="$primary1" icon="info" variant="outlined" />
        }
        data-auto-testid="provider-details-chips-schedule"
        data-testid="provider-details-chips-schedule"
        key="provider-details-chips-schedule"
        onClick={() => setIsOpen(!isOpen)}
        variant="outline"
      >
        {t('DETAILS_CHIP_NAMES.Schedule')}
      </InteractButton>
    </Popover>
  );
};
