import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { useState } from 'react';

import SpecialityDirectory from './SpecialityDirectory';
import {
  CollapseName,
  SpecialityCollapseHeaderProvider,
  SpecialityCollapseProvider,
} from './SpecialitySearch.styled';

type Props = {
  speciality: any;
  providerId: string;
  providerType: string;
  specialityName: any;
};

const SpecialityDetails = ({
  speciality,
  providerId,
  providerType,
  specialityName,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const clickHandler = () => {
    setIsOpen(!isOpen);
  };
  return (
    <SpecialityCollapseProvider>
      <SpecialityCollapseHeaderProvider
        data-testid="speciality-details"
        onClick={() => clickHandler()}
      >
        <CollapseName>{specialityName}</CollapseName>
        <IconMaterial
          icon={isOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
        />
      </SpecialityCollapseHeaderProvider>
      {isOpen && (
        <SpecialityDirectory
          providerId={providerId}
          providerType={providerType}
          speciality={speciality}
        />
      )}
    </SpecialityCollapseProvider>
  );
};

export default SpecialityDetails;
