import { Popover } from '@abyss/web/ui/Popover';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsOverflow } from '../../../../../../../../../../hooks/useIsOverflow/useIsOverflow';
import { Address as AdressModel } from '../../../../../../../../../../models/ProviderDetails';
import {
  AddressDiv,
  popoverStyle,
} from '../../../../../utility/compareDrawer.styled';
import { getDisplayedAddressText, isAddressPresent } from './addressUtility';

type Props = {
  address: AdressModel;
  mobile: boolean;
};

export const Address = ({ address, mobile }: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);
  const isAttributePresent = isAddressPresent(address);
  const displayedAddress = t(
    getDisplayedAddressText(address, isAttributePresent)
  );

  const ProviderAddress = (
    <AddressDiv
      cssProps={{ isOverflow, isAttributePresent }}
      data-auto-testid="provider-address"
      data-testid="provider-address"
      ref={containerRef}
    >
      {displayedAddress}
    </AddressDiv>
  );

  if (mobile) {
    return (
      <React.Fragment>
        {isOverflow ? (
          <Popover
            content={displayedAddress}
            css={popoverStyle}
            showClose={false}
            tabIndex="-1"
            width={150}
          >
            {ProviderAddress}
          </Popover>
        ) : (
          ProviderAddress
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {isOverflow ? (
        <Tooltip content={displayedAddress} position="top" positionOffset={8}>
          {ProviderAddress}
        </Tooltip>
      ) : (
        ProviderAddress
      )}
    </React.Fragment>
  );
};
