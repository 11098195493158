import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';

import {
  carouselScreen,
  fourCardscarouselScreen,
  sixCardscarouselScreen,
  threeCardscarouselScreen,
  twoCardscarouselScreen,
} from '../common/ConstantsStyles';

export const getSnackCardDisplayValByLength = (reviewCardsCount): boolean => {
  const bSixCardDisplayCarousel = useMediaQuery(sixCardscarouselScreen);
  const bFiveCardsDisplayCarousel = useMediaQuery(carouselScreen);
  const bFourCardDisplayCarousel = useMediaQuery(fourCardscarouselScreen);
  const bThreeCardDisplayCarousel = useMediaQuery(threeCardscarouselScreen);
  const bTwoCardDisplayCarousel = useMediaQuery(twoCardscarouselScreen);
  const b = false;
  if (reviewCardsCount === 2) {
    return bTwoCardDisplayCarousel;
  }
  if (reviewCardsCount === 3) {
    return bThreeCardDisplayCarousel;
  }
  if (reviewCardsCount === 4) {
    return bFourCardDisplayCarousel;
  }
  if (reviewCardsCount === 5) {
    return bFiveCardsDisplayCarousel;
  }
  if (reviewCardsCount > 5) {
    return bSixCardDisplayCarousel;
  }
  return b;
};
