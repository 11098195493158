/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Button } from '@abyss/web/ui/Button';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { Divider } from '@abyss/web/ui/Divider';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { RadioGroup } from '@abyss/web/ui/RadioGroup';
import { TextInput } from '@abyss/web/ui/TextInput';
import { ProviderDetailsWidget } from 'parcels/src/frontends/ProviderDetailsWidget';
import { ProviderType } from 'parcels/src/models/ProviderDetails';
import { UserDDPCode } from 'parcels/src/utils/providerDetails.utils';
import React, { useEffect, useState } from 'react';

export const DetailsDrawer = () => {
  const { navigate } = useRouter();
  const [input, setInput] = useState<string>('005847827');
  const [policyId, setPolicyId] = useState<string>('0704201');
  const [providerId, setProviderId] = useState<string>('');
  const [locationId, setLocationId] = useState<string>('');
  const [includeCost, setIncludeCost] = useState<boolean>(true);
  const [population, setPopulation] = useState<string>('UNET');
  const [claimSystemTypeCode, setClaimSystemTypeCode] = useState<string | null>(
    null
  );
  const [eligibilitySystemTypeCode, setEligibilitySystemTypeCode] = useState<
    string | null
  >(null);
  const [planVariationCode, setPlanVariationCode] = useState<string | null>(
    '0001'
  );
  const [providerType, setProviderType] = useState<ProviderType>(
    ProviderType.PRACTITIONER
  );
  const [userDDP, setUserDDP] = useState<UserDDPCode>(UserDDPCode.L);
  const [coverageType, setCoverageType] = useState<string>('M');

  useEffect(() => {
    if (population === 'USP') {
      setClaimSystemTypeCode('CR');
      setEligibilitySystemTypeCode('CR');
      setPlanVariationCode(null);
    } else {
      setClaimSystemTypeCode(null);
      setEligibilitySystemTypeCode(null);
      setPlanVariationCode('0001');
    }
  }, [population]);

  const [isOpen, setIsOpen] = useState(false);
  const onSubmit = () => {
    setProviderId(input);
    setPolicyId(policyId);
    setIsOpen(true);
    window.scrollTo(0, 0);
  };

  const handleClick = (e) => {
    setInput(e.target.innerHTML);
  };

  if (process.env.APP_ENV === 'prod') {
    navigate('/');
  }

  const getSampleVals = () => {
    switch (coverageType) {
      case 'M':
        switch (providerType) {
          case ProviderType.ORGANIZATION:
            return {
              providerIds: [
                '002787576',
                '007212473',
                '000731852',
                '006609285',
                '000576590',
              ],
              reciprocityId: '52',
            };
          default:
            return {
              providerIds: ['005847827', '00001938905', '003645503'],
              reciprocityId: '52',
            };
        }
      case 'B':
        switch (providerType) {
          case ProviderType.ORGANIZATION:
            return {
              providerIds: ['FAC000011600'],
              reciprocityId: 'Employer%20Guest',
            };
          default:
            return {
              providerIds: ['000768779', '001071641'],
              reciprocityId: 'Employer%20Guest',
            };
        }
      case 'V':
        switch (providerType) {
          case ProviderType.ORGANIZATION:
            return {
              providerIds: ['00L22500O'],
              reciprocityId: '1',
            };
          default:
            return {
              providerIds: ['00089597P', '00017135P'],
              reciprocityId: '1',
            };
        }
      case 'D':
        return {
          providerIds: ['000007109100', '000000101572'],
          reciprocityId: 'PPO000000001',
        };
      default:
        return {};
    }
  };

  const sampleVals = getSampleVals();

  return (
    <Flex>
      <Drawer
        hideClose
        isOpen={isOpen}
        onClose={() => {
          setIsOpen(false);
          setProviderId('');
        }}
        size="600px"
      >
        <ProviderDetailsWidget
          claim-system-type-code={claimSystemTypeCode}
          coverage-type={coverageType}
          ddp-code={userDDP}
          eligibility-system-type-code={eligibilitySystemTypeCode}
          latitude="41.88169"
          lob="E&I"
          location-id={locationId}
          longitude="-87.6228"
          out-of-pocket-cost={includeCost ? '109' : ''}
          plan-variation-code={planVariationCode}
          plan-year="2024"
          policy-id={policyId}
          provider-id={providerId}
          provider-type={providerType}
          reciprocity-id={sampleVals.reciprocityId}
          total-cost={includeCost ? '3600' : ''}
        />
      </Drawer>
      <div style={{ margin: '24px' }}>
        <RadioGroup
          data-testid="details-widget-coverage-type"
          display="row"
          label="Coverage type"
          onChange={(e) => {
            setCoverageType(e.target.value);
            setInput('');
          }}
          value={coverageType}
        >
          <RadioGroup.Radio label="Medical" value="M" />
          <RadioGroup.Radio label="Behavioral" value="B" />
          <RadioGroup.Radio label="Vision" value="V" />
          <RadioGroup.Radio label="Dental" value="D" />
        </RadioGroup>
        <RadioGroup
          data-testid="details-widget-population"
          display="row"
          label="Population"
          onChange={(e) => setPopulation(e.target.value)}
          value={population}
        >
          <RadioGroup.Radio label="UNET" value="UNET" />
          <RadioGroup.Radio label="USP" value="USP" />
        </RadioGroup>
        <RadioGroup
          data-testid="details-widget-provider-type"
          display="row"
          label="Provider type"
          onChange={(e) => {
            // @ts-ignore
            setProviderType(e.target.value);
            setInput('');
          }}
          value={providerType}
        >
          <RadioGroup.Radio
            label="Practitioner"
            value={ProviderType.PRACTITIONER}
          />
          {coverageType !== 'D' && (
            <RadioGroup.Radio
              data-testid={`details-widget-${ProviderType.ORGANIZATION}`}
              label="Facility"
              value={ProviderType.ORGANIZATION}
            />
          )}
        </RadioGroup>
        <RadioGroup
          data-testid="details-widget-user-ddp-code"
          display="row"
          label="User DDP Code"
          onChange={(e) => {
            // @ts-ignore
            setUserDDP(e.target.value);
          }}
          size="18"
          value={userDDP}
        >
          <RadioGroup.Radio
            data-testid={`details-widget-${UserDDPCode.L}`}
            label="L"
            value={UserDDPCode.L}
          />
          <RadioGroup.Radio
            data-testid={`details-widget-${UserDDPCode.R}`}
            label="R"
            value={UserDDPCode.R}
          />
          <RadioGroup.Radio
            data-testid={`details-widget-${UserDDPCode.A}`}
            label="A"
            value={UserDDPCode.A}
          />
          <RadioGroup.Radio
            data-testid={`details-widget-${UserDDPCode.N}`}
            label="N"
            value={UserDDPCode.N}
          />
        </RadioGroup>
        <Checkbox
          data-testid="details-widget-include-cost"
          isChecked={includeCost}
          label="Include cost details"
          onChange={(e) => setIncludeCost(e.target.checked)}
          size="$xs"
        />
        <Divider />
        <div>
          <strong>Sample IDs</strong>
        </div>
        {sampleVals?.providerIds?.map((id) => (
          <div
            key={id}
            data-testid={`details-widget-id-${id}`}
            onClick={handleClick}
          >
            {id}
          </div>
        ))}
        <Divider />
        <TextInput
          data-testid="details-drawer-input"
          isClearable
          label="Set provider ID"
          onChange={(e) => setInput(e.target.value)}
          onClear={() => setInput('')}
          value={input}
        />

        <TextInput
          data-testid="details-drawer-policy-input"
          isClearable
          label="Set policy ID"
          onChange={(e) => setPolicyId(e.target.value)}
          onClear={() => setPolicyId('')}
          value={policyId}
        />

        <TextInput
          data-testid="details-drawer-location-id"
          isClearable
          label="Set location ID (optional)"
          onChange={(e) => setLocationId(e.target.value)}
          onClear={() => setLocationId('')}
          value={locationId}
        />
        <Divider />
        <Button
          data-testid="details-drawer-policy-submit-button"
          onClick={onSubmit}
        >
          Load Details
        </Button>
      </div>
    </Flex>
  );
};
