export const mockProviders = () => {
  let providers = '';
  for (let x = 3; x < 12; x++) {
    providers = providers.concat(
      JSON.stringify({
        firstName: `Provider Name ${x}`,
        practice: `Specialty code ${x}`,
        address: `Street ${x}`,
        suite: `Suite ${x}`,
        phone: `(61${x})-000-0000`,
        premium: true,
        accepting: true,
        network: true,
        type: 'provider',
        providerId: `${x}`,
      })
    );
    if (x <= 11) {
      providers = providers.concat(',');
    }
  }
  return providers;
};