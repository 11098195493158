import { Popover } from '@abyss/web/ui/Popover';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { isEmpty } from 'lodash';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { capitalizeFirstLetter } from '../../../../../../../../../../common/Utils/capitalizeFirstLetter';
import { useIsOverflow } from '../../../../../../../../../../hooks/useIsOverflow';
import {
  StyledAvoidWrappingDiv,
  popoverStyle,
} from '../../../../../utility/compareDrawer.styled';
import { NULL_ATTRIBUTE_STATE } from '../../../../../utility/compareDrawerConstants';

type Props = {
  servicesOffered: string[];
  mobile: boolean;
};

export const ServicesOffered = ({ servicesOffered, mobile }: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);
  const isAttributeEmpty = isEmpty(servicesOffered);

  function formatAndTranslateScreamingSnakeCase(value: string) {
    return t(
      capitalizeFirstLetter(value.replaceAll('_', ' ').toLocaleLowerCase())
    );
  }

  const servicesOfferedText = isAttributeEmpty
    ? t(NULL_ATTRIBUTE_STATE.SERVICES_OFFERED)
    : servicesOffered
        .map((value) => formatAndTranslateScreamingSnakeCase(value))
        .join(', ');

  const servicesOfferedAttribute = (
    <StyledAvoidWrappingDiv
      cssProps={{ isOverflow, isAttributeEmpty }}
      ref={containerRef}
    >
      {t(servicesOfferedText)}
    </StyledAvoidWrappingDiv>
  );

  if (mobile) {
    return (
      <React.Fragment>
        {isOverflow ? (
          <Popover
            content={servicesOfferedText}
            css={popoverStyle}
            showClose={false}
            tabIndex="-1"
            width={150}
          >
            {servicesOfferedAttribute}
          </Popover>
        ) : (
          servicesOfferedAttribute
        )}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      {isOverflow ? (
        <Tooltip
          content={servicesOfferedText}
          position="top"
          positionOffset={8}
        >
          {servicesOfferedAttribute}
        </Tooltip>
      ) : (
        servicesOfferedAttribute
      )}
    </React.Fragment>
  );
};
