import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { config } from '@abyss/web/tools/config';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Tabs } from '@abyss/web/ui/Tabs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CardWrapperDetailsPage } from '../../../../common/CardWrapper';
import { Constants } from '../../../../common/Constants';
import { mobileOnly, phoneOnly } from '../../../../common/ConstantsStyles';
import {
  HeaderFlex,
  HeaderIcon,
  NavAndShareButton,
  ProviderIndicator,
  ProviderSubtitle,
} from '../../../../common/DetailsPageHeader/DetailsPageHeader.styled';
import { ReferralBannerBox } from '../../../../common/ProviderDetailTabs/About/ReferralBannerBox';
import {
  Container,
  TabContainer,
  TabsContainer,
} from '../../../../common/ProviderDetailTabs/ProviderDetailTabs.styled';
import { AllOtherInfos } from '../../../../common/ProviderDetailTabs/ProviderLocationsDetails/AllOtherInfos/AllOtherInfos';
import { InfoProviderContent } from '../../../../common/ProviderDetailTabs/ProviderLocationsDetails/InfoProviderContent';
import { OpsHours } from '../../../../common/ProviderDetailTabs/ProviderLocationsDetails/OpsHours';
import { PhoneNumberList } from '../../../../common/ProviderDetailTabs/ProviderLocationsDetails/PhoneNumberList';
import { VirtualProviderGroupDetailExpertise } from '../../../../common/ProviderGroupDetailTabs/VirtualProviderGroupDetailExpertise';
import { Breadcrumb } from '../../../../common/PSXHeader/Breadcrumb';
import { PhoneNumbers } from '../../../../models/ProviderDetails';
import { mockProviderDetails } from './mockProviderDetails';
import {
  AvatarDetailsPage,
  DetailsSection,
  Dot,
  HeaderContainer,
  InteractButton,
  SectionContainer,
  VideoIcon,
  VirtualProviderContent,
} from './VirtualProviderGroupDetails.styled';

export const VirtualProviderGroupDetails = () => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const breadcrumbs = [
    {
      title: t('BC Results'),
      href: ``,
    },
    {
      title: 'Virtual provider group details',
      href: '',
    },
  ];
  const displayTabs = Constants.PROVIDER_DETAILS.TABS_LIST.filter(
    (tab) => tab === 'About'
  );

  const mobile = useMediaQuery(mobileOnly);

  const phoneNumbers: PhoneNumbers = {
    phone: ['609-569-7071'],
    appointment: ['609-569-7073'],
    emergency: ['609-569-7072'],
  };

  return (
    <React.Fragment>
      <CardWrapperDetailsPage backgroundColor="#FBFCFE">
        <HeaderContainer>
          <HeaderFlex alignItems="end" justify="space-between">
            <Breadcrumb
              backgroundColor="$white"
              breadcrumbs={breadcrumbs}
              color="$info1"
              currentColor="$gray7"
            />
            <NavAndShareButton
              data-auto-testid="virtual-provider-group-share-button"
              data-testid="virtual-provider-group-share-button"
              variant="tertiary"
            >
              <HeaderIcon
                color="$primary1"
                data-auto-testid="virtual-provider-group-share-button"
                data-testid="virtual-provider-group-share-button"
                icon="share"
                isScreenReadable
                name="share-button"
              />
              {t('Share')}
            </NavAndShareButton>
          </HeaderFlex>
          <DetailsSection>
            <AvatarDetailsPage>
              <img
                alt="oshi health"
                src={`${config(
                  'CDN_BASE_URL'
                )}/cdn/assets/images/oshi_health.png`}
              />
            </AvatarDetailsPage>
            <ProviderSubtitle
              color="$gray7"
              fontWeight="$medium"
              size={mobileScreen ? '$md' : '$lg'}
            >
              Virtual GI (Gastroenterology) care
            </ProviderSubtitle>
            <Flex
              alignItems="center"
              css={{
                'abyss-flex-root': {
                  marginTop: '6px',
                },
              }}
            >
              <Layout.Group space={0}>
                <VideoIcon>
                  <IconMaterial color="$white" icon="videocam" size="16px" />
                </VideoIcon>
                <ProviderIndicator
                  color="$gray7"
                  fontWeight="$medium"
                  size={mobileScreen ? '$md' : '$lg'}
                >
                  Virtual visits
                  <ProviderIndicator
                    color="$gray7"
                    fontWeight="$bold"
                    size={mobileScreen ? '$md' : '$lg'}
                  >
                    only
                  </ProviderIndicator>
                </ProviderIndicator>
              </Layout.Group>
              <Dot />
              <Layout.Group space={0}>
                <IconMaterial
                  color="$success1"
                  icon="check_circle"
                  size="16px"
                />
                <ProviderIndicator
                  color="$gray7"
                  fontWeight="$medium"
                  size={mobileScreen ? '$md' : '$lg'}
                >
                  {t('In-Network')}
                </ProviderIndicator>
              </Layout.Group>
            </Flex>
            <DetailsSection>
              <InteractButton
                after={<IconMaterial color="$primary1" icon="open_in_new" />}
                data-auto-testid="virtual-provider-group-get-started"
                data-testid="virtual-provider-group-get-started"
                size={mobileScreen ? '$sm' : '$md'}
                variant="outline"
              >
                {t('DONT_KNOW_WHERE_TO_START_CARD.GET_STARTED')}
              </InteractButton>
            </DetailsSection>
          </DetailsSection>
        </HeaderContainer>
      </CardWrapperDetailsPage>
      <CardWrapperDetailsPage>
        <Container>
          <SectionContainer>
            {displayTabs.map((tab) => (
              <TabsContainer
                active
                grow={false}
                key="provider-detail-tabs-list"
                title={t('PROVIDER_DETAILS.TAB_TITLE')}
                variant="line"
              >
                <Tabs.Tab label={t(tab)}>
                  <TabContainer>
                    {t(tab) === 'About' && (
                      <React.Fragment>
                        <ReferralBannerBox
                          data-auto-testid="referral-banner-box"
                          data-testid="referral-banner-box"
                          pcpData={{}}
                        />
                        <VirtualProviderContent>
                          <InfoProviderContent
                            icon="done"
                            infoTitle={t(
                              'VIRTUAL_GROUP_PROVIDER_ABOUT.ACCEPTING_PATIENTS'
                            )}
                            showSubTitle={false}
                          />

                          <OpsHours
                            selectedLocation={
                              mockProviderDetails.providerLocations[0]
                            }
                          />

                          <PhoneNumberList
                            isFromVirtualProviderGroup
                            phoneNumbers={phoneNumbers}
                          />

                          <AllOtherInfos
                            detailType="{detailType}"
                            facilityDetails="facility"
                            isFromVirtualProviderGroup
                            providerDetails={mockProviderDetails}
                            selectedLocation={
                              mockProviderDetails.providerLocations[0]
                            }
                          />
                        </VirtualProviderContent>
                        <VirtualProviderContent
                          css={{ padding: mobile ? 0 : '24px 84px' }}
                        >
                          <VirtualProviderGroupDetailExpertise />
                        </VirtualProviderContent>
                      </React.Fragment>
                    )}
                  </TabContainer>
                </Tabs.Tab>
              </TabsContainer>
            ))}
          </SectionContainer>
        </Container>
      </CardWrapperDetailsPage>
    </React.Fragment>
  );
};
