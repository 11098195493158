import React, { Component, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  error?: any;
}

interface State {
  error: any;
}
export class ErrorBoundary extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = { error: '' };
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch = (error) => {
    this.setState({ error });
  };

  render() {
    const { error } = this.state;
    const { children, error: passedError } = this.props;
    if (error || passedError) {
      return <p>{passedError}</p>;
    }
    return children;
  }
}
