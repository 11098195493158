import React, { useEffect, useState } from 'react';

import { useHealthgradesGetBulkInfo } from '../../../../../hooks/useHealthgrades';
import { HealthGradesBulkInfo } from '../../../../../models/Healthgrades';
import { Education, Identifiers } from '../../../../../models/ProviderDetails';
import { returnSuppressFlag } from '../../../../../utils/featureSuppress';
import { ConstantsLagoon } from '../../../../ConstantsLagoon';
import { BiographySection } from '../../../../ProviderDetailTabs/About/BiographySection';
import { CollapseDetailsSectionRoot } from '../../../../ProviderDetailTabs/About/CollapseProviderDetailsSection/CollapseDetailsSectionRoot';
import { PatientReviews } from './PatientReviews';
import { PatientReviewsAll } from './PatientReviewsAll';

type Props = {
  areaOfExpertise: string[] | undefined;
  biographicData: string;
  culturalCompetencies: string[] | undefined;
  educationInfo: Education[];
  ethnicity: string;
  gender: string;
  hospitalAffiliations: Identifiers[];
  languagesSpoken: string[];
  licenseNumber: string[];
  npi: string;
  providerName: string;
  primaryDegree?: string;
  specialties: string[];
  userState: string | undefined;
};

export const About = ({
  areaOfExpertise,
  biographicData,
  culturalCompetencies,
  educationInfo,
  ethnicity,
  gender,
  hospitalAffiliations,
  languagesSpoken,
  licenseNumber,
  npi,
  providerName,
  primaryDegree,
  specialties,
  userState,
}: Props) => {
  const [viewAllReviews, setViewAllReviews] = useState<boolean>(false);
  const [isReviewsLoading, setIsReviewsLoading] = useState<boolean>(false);
  const [isReviewError, setIsReviewError] = useState<boolean>(false);
  const [healthgradesReview, setHealthgradesReview] =
    useState<HealthGradesBulkInfo>({});
  const suppressReviews = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );

  const [, healthgradesGetBulkInfo] = useHealthgradesGetBulkInfo({
    onCompleted: (result) => {
      setIsReviewsLoading(false);
      if (result?.data?.healthgradesGetBulkInfo) {
        setHealthgradesReview(result.data.healthgradesGetBulkInfo[0]);
      }
    },
    onError: (error) => {
      setIsReviewsLoading(false);
      setIsReviewError(true);
      throw error;
    },
  });

  useEffect(() => {
    if (npi) {
      setIsReviewsLoading(true);
      healthgradesGetBulkInfo({
        variables: {
          npis: [npi],
        },
      });
    }
  }, [npi]);

  return (
    <React.Fragment>
      {viewAllReviews ? (
        <PatientReviewsAll
          healthgradesReview={healthgradesReview}
          setViewAllReviews={setViewAllReviews}
        />
      ) : (
        <React.Fragment>
          <div style={{ marginTop: '8px' }}>
            <BiographySection
              content={biographicData}
              data-auto-testid="about-tab-biography-section"
              data-testid="about-tab-biography-section"
              ethnicity={ethnicity}
              gender={gender}
              heading="BIOGRAPHY_SECTION.HEADING"
              language={languagesSpoken}
            />
            {!suppressReviews ? (
              <PatientReviews
                healthgradesReview={healthgradesReview}
                isLoading={isReviewsLoading}
                isReviewError={isReviewError}
                primaryDegree={primaryDegree}
                providerName={providerName}
                setViewAllReviews={setViewAllReviews}
              />
            ) : null}
            <div style={{ marginTop: suppressReviews ? '-8px' : '' }}>
              <CollapseDetailsSectionRoot
                areaOfExpertise={areaOfExpertise}
                culturalCompetencies={culturalCompetencies}
                educationInfo={educationInfo}
                hospitalAffiliations={hospitalAffiliations}
                licenseNumber={licenseNumber}
                npi={npi}
                specialties={specialties}
                userState={userState}
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
