import { useRouter } from '@abyss/web/hooks/useRouter';
import { tokenizer } from '@abyss/web/tools/tokenizer';
import { useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';

import {
  CareCategories,
  ReverseCoverageTypesCodes,
} from '../../common/Constants';
import { ConstantsRoutes } from '../../common/ConstantsRoutes';
import {
  convertUrlParamsToObject,
  hasValue,
  isDetailsPage,
} from '../../common/Utils';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../store/useDetailsStore';
import { DetailsStore } from '../../store/useDetailsStore/detailsStore';
import { usePCPStore } from '../../store/usePCPStore';
import { PCPSearchStore } from '../../store/usePCPStore/pcpStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { getPortalDefaultSearchParams } from '../../utils/common';

export const useGetTokenOrUrlParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();

  let params;
  // if params exist it means its url params
  if (urlParams.size > 0) {
    params = convertUrlParamsToObject(urlParams.entries());
  } else {
    // else it is token based url
    params = tokenizer.parse(token) || {};
  }
  return params;
};

const setStoreValues = (urlParams, values, store, setStore) => {
  const storeValues: any = {};
  let hasChanges = false;
  values.forEach((value) => {
    if (hasValue(urlParams[value]) && urlParams[value] !== store[value]) {
      storeValues[value] = urlParams[value];
      hasChanges = true;
    }
  });
  if (hasChanges) {
    setStore(storeValues);
  }
};

const isValidChipValue = (chipValue): boolean =>
  Object.values(CareCategories).includes(chipValue);

const isValidCoverageTypeValue = (CoverageType): boolean =>
  Object.values(ReverseCoverageTypesCodes).includes(CoverageType);

const getChipAndCoverageTypeValues = (searchParams: any) => {
  // Fetch valid chip and coverage type values from search params if exists
  const searchParamsChip = searchParams?.chipValue;
  const searchParamsCoverageType = searchParams?.coverageType;
  if (
    isValidChipValue(searchParamsChip) &&
    isValidCoverageTypeValue(searchParamsCoverageType)
  ) {
    return {
      chipValue: searchParamsChip,
      coverageType: searchParamsCoverageType,
    };
  }

  // Or Fetch valid chip and coverage type values from Portal Configs if exists
  const portalSearchParams: any = getPortalDefaultSearchParams();
  const portalConfigChip = portalSearchParams?.chipValue;
  const portalConfigCoverageType = portalSearchParams?.coverageType;
  if (
    isValidChipValue(portalConfigChip) &&
    isValidCoverageTypeValue(portalConfigCoverageType)
  ) {
    return {
      chipValue: portalConfigChip,
      coverageType: portalConfigCoverageType,
    };
  }

  // Otherwise return default chip and coverage type values
  return {
    chipValue: CareCategories.ALL,
    coverageType: ReverseCoverageTypesCodes.MEDICAL,
  };
};

// useInitializeStore hook must be used only for main routes like landing page, search results page, details page, map view page
export const useInitializeStore = (page: string) => {
  const { setChipValue, setCoverageType } = useChipStore(
    useShallow((state: ChipState) => ({
      setChipValue: state.setChipValue,
      setCoverageType: state.setCoverageType,
    }))
  );

  const { typeaheadSearchStore, setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      typeaheadSearchStore: state.typeaheadSearchStore,
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );

  const { pcpSearchState, setPCPSearchState } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      pcpSearchState: state.pcpSearchState,
      setPCPSearchState: state.setPCPSearchState,
    }))
  );

  const { setDetailsStore, detailsState } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
      detailsState: state.detailsState,
    }))
  );

  const searchParams = useGetTokenOrUrlParams();

  const { chipValue, coverageType } =
    getChipAndCoverageTypeValues(searchParams);

  let urlParams: any = {};
  // search results page params
  if (page === ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key) {
    urlParams = {
      search: searchParams.search,
      searchType: searchParams.searchType,
      providerType: searchParams.providerType || '',
      pesKeyword: searchParams.pesKeyword,
      includeSpecialityRollupCodes: searchParams.includeSpecialityRollupCodes,
      llmFlag: searchParams.llmFlag,
      virtualCare: searchParams.virtualCare,
      dependentSeqNbr: searchParams.dependentSeqNbr,
      choosePCP: searchParams.choosePCP,
      pcpIndicator: searchParams.pcpIndicator,
      isUserTextSearch: searchParams.isUserTextSearch,
    };
  }
  // details page params
  if (isDetailsPage(page)) {
    urlParams = {
      providerId: searchParams.providerId,
      locationId: searchParams.locationId || '',
      source: searchParams.source || undefined,
    };
  }
  // mapview page params
  if (page === ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key) {
    urlParams = {
      search: searchParams.search,
      searchType: searchParams.searchType,
      providerType: searchParams.providerType || '',
      sectionType: searchParams.sectionType,
      includeSpecialityRollupCodes: searchParams.specialtyCode,
      llmFlag: searchParams.llmFlag,
      virtualCare: searchParams.virtualCare,
      dependentSeqNbr: searchParams.dependentSeqNbr,
      choosePCP: searchParams.choosePCP,
      pcpIndicator: searchParams.pcpIndicator,
    };
  }

  useEffect(() => {
    if (page === ConstantsRoutes.LANDING_PAGE.key) {
      setChipValue(chipValue);
      setCoverageType(coverageType);
    } else if (page === ConstantsRoutes.PROVIDER_SEARCH_RESULTS.key) {
      const searchResultsPageParams = [
        'search',
        'searchType',
        'providerType',
        'pesKeyword',
        'includeSpecialityRollupCodes',
        'llmFlag',
        'virtualCare',
        'isUserTextSearch',
      ];
      setStoreValues(
        urlParams,
        searchResultsPageParams,
        typeaheadSearchStore,
        setTypeaheadSearchStore
      );
      const pcpParams = ['dependentSeqNbr', 'choosePCP', 'pcpIndicator'];
      setStoreValues(urlParams, pcpParams, pcpSearchState, setPCPSearchState);
    } else if (page === ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key) {
      const mapViewPageParams = [
        'search',
        'searchType',
        'providerType',
        'sectionType',
        'includeSpecialityRollupCodes',
        'pesKeyword',
        'llmFlag',
        'virtualCare',
      ];
      setStoreValues(
        urlParams,
        mapViewPageParams,
        typeaheadSearchStore,
        setTypeaheadSearchStore
      );
      const pcpParams = ['dependentSeqNbr', 'choosePCP', 'pcpIndicator'];
      setStoreValues(urlParams, pcpParams, pcpSearchState, setPCPSearchState);
    } else if (isDetailsPage(page)) {
      const detailsPageParams = ['providerId', 'locationId', 'source'];
      setStoreValues(
        urlParams,
        detailsPageParams,
        detailsState,
        setDetailsStore
      );
    }
  }, [JSON.stringify(urlParams)]);

  return {
    chipValue,
    coverageType,
    ...urlParams,
  };
};
