import { useCollapse } from '@abyss/web/hooks/useCollapse';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { CollapseProvider } from '@abyss/web/ui/CollapseProvider';
import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CUSTOMN_MESSAGE_TRUNCATE_CHARACTERS } from '../../Constants';

const ReadMoreOrLessButton = styled(Button, {
  '&.abyss-button-root': {
    paddingLeft: '0px',
  },
});
export const CtaTextLink = styled(Link, {
  '&.abyss-link-root': {
    borderColor: '$info1',
    backgroundColor: '#FFFFFF',
    border: '1px solid $info1',
    borderRadius: '4px',
    padding: '8px',
    marginTop: '4px',
  },
});
type ExpandCollapseProps = {
  text: string;
  linkText?: string | null;
  linkUrl?: string | null;
  truncateLength?: number;
};

export const ExpandCollapse = ({
  text,
  linkText,
  linkUrl,
  truncateLength = 100,
}: ExpandCollapseProps) => {
  const { t } = useTranslation();

  const { collapseProps, buttonProps, isOpen } = useCollapse({
    defaultIsOpen: false,
    duration: 1000,
  });
  const isTextLengthy = text?.length >= truncateLength;
  return (
    <CollapseProvider defaultIsOpen={false}>
      <React.Fragment>
        <Flex {...collapseProps}>
          {isOpen ? (
            <Layout.Stack alignItems="left">
              <Text
                color="$gray8"
                data-auto-testid="custom-message-text-full"
                data-testid="custom-message-text-full"
              >
                {text}
              </Text>
              {linkText && !isEmpty(linkText) && (
                <CtaTextLink
                  data-auto-testid="custom-message-link"
                  data-testid="custom-message-link"
                  href={linkUrl}
                >
                  {linkText}
                </CtaTextLink>
              )}
            </Layout.Stack>
          ) : (
            <Text
              color="$gray8"
              data-auto-testid="custom-message-text-ellipsis"
              data-testid="custom-message-text-ellipsis"
            >
              {isTextLengthy
                ? `${text.substring(
                    0,
                    CUSTOMN_MESSAGE_TRUNCATE_CHARACTERS
                  )} ...`
                : text}
            </Text>
          )}
          {isTextLengthy && (
            <ReadMoreOrLessButton
              after={
                isOpen ? (
                  <IconMaterial icon="keyboard_arrow_up" />
                ) : (
                  <IconMaterial icon="keyboard_arrow_down" />
                )
              }
              variant="tertiary"
              {...buttonProps}
            >
              {isOpen ? t('READ_LESS') : t('READ_MORE')}
            </ReadMoreOrLessButton>
          )}
        </Flex>
      </React.Fragment>
    </CollapseProvider>
  );
};
