import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';

import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { phoneOnly } from '../../../ConstantsStyles';
import { Dot } from '../ProviderLocationsDetails.style';

type Props = {
  text: string;
  value: string;
};

export const ListItem = ({ text, value }: Props) => {
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const phoneScreen = useMediaQuery(phoneOnly) || isWidget;

  return (
    <Flex
      css={{
        gap: '8px',
        flexWrap: 'nowrap',
        alignItems: 'center',
        marginLeft: '12px',
      }}
    >
      <Dot />
      <Text css={phoneScreen}>
        {text} - <Text css={phoneScreen}>{value}</Text>
      </Text>
    </Flex>
  );
};
