/* eslint-disable no-nested-ternary */
import { useTranslation } from 'react-i18next';

import { HoursOfOperation } from '../../../../../../models/Provider';
import { convertTimeToAmPm } from '../../../../../../utils/providerDetails.utils';

export const getHoursOfOperaion = (hours: HoursOfOperation) => {
  const { t } = useTranslation();
  let daysWithTime = '';
  const possibleHours: string[] = [];
  const hoursValues = [''];
  const days = ['Sun', 'M', 'T', 'W', 'Th', 'F', 'S'];
  const dayStart = ['', '', '', '', '', '', ''];
  const dayEnd = ['', '', '', '', '', '', ''];
  dayStart[0] = hours?.sunday?.slice(0, 5);
  dayStart[1] = hours?.monday?.slice(0, 5);
  dayStart[2] = hours?.tuesday?.slice(0, 5);
  dayStart[3] = hours?.wednesday?.slice(0, 5);
  dayStart[4] = hours?.thursday?.slice(0, 5);
  dayStart[5] = hours?.friday?.slice(0, 5);
  dayStart[6] = hours?.saturday?.slice(0, 5);
  dayEnd[0] = hours?.sunday?.slice(9, 14);
  dayEnd[1] = hours?.monday?.slice(9, 14);
  dayEnd[2] = hours?.tuesday?.slice(9, 14);
  dayEnd[3] = hours?.wednesday?.slice(9, 14);
  dayEnd[4] = hours?.thursday?.slice(9, 14);
  dayEnd[5] = hours?.friday?.slice(9, 14);
  dayEnd[6] = hours?.saturday?.slice(9, 14);

  let currentDay = 0;
  dayStart.forEach((day) => {
    const tempDay = convertTimeToAmPm(day);
    if (tempDay !== '') {
      daysWithTime += `${t(
        `COMPARE_DRAWER_TEXT.HOURS_OF_OPERATION.${days[currentDay]}`
      )}, `;
      hoursValues[currentDay] = tempDay;
    }

    currentDay += 1;
  });
  daysWithTime = daysWithTime.slice(0, daysWithTime.lastIndexOf(','));

  currentDay = 0;
  dayEnd.forEach((day) => {
    const tempDay = convertTimeToAmPm(day);
    if (tempDay !== '') {
      hoursValues[currentDay] += ` - ${tempDay}`;
    }
    currentDay += 1;
  });

  hoursValues.forEach((day) => {
    if (
      day !== '' &&
      !possibleHours.includes(day) &&
      !possibleHours.includes(`, ${day}`)
    ) {
      if (possibleHours.length === 0) {
        possibleHours.push(day);
      } else {
        possibleHours.push(`, ${day}`);
      }
    }
  });

  return { daysWithTime, possibleHours };
};
