import { Flex } from '@abyss/web/ui/Flex';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import capitalize from 'lodash/capitalize';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { findAllMatches } from '../../../Utils/findAllMatches/findAllMatches';
import { textContentStyles } from '../PhysicianDirectory.styled';
import SpecialityDetails from './SpecialityDetails';

type Props = {
  specialityToSearch: string;
  allSpecialities: any[];
  providerId: string;
  providerType: string;
  resetSpeciality: () => void;
};

export const matchSearchText = (searchText, list, providerId, providerType) =>
  list.map((item) => {
    const name = item?.name;
    const search = searchText.trim();

    if (name.toLocaleLowerCase().includes(search.toLocaleLowerCase())) {
      return (
        <React.Fragment key={item.internalId ? item.internalId : item.name}>
          <SpecialityDetails
            providerId={providerId}
            providerType={providerType}
            speciality={item}
            specialityName={findAllMatches(search, capitalize(name)).map((x) =>
              x.isMatch ? <strong>{x.text}</strong> : x.text
            )}
          />
        </React.Fragment>
      );
    }
    return null;
  });

const SpecialitySearch = ({
  specialityToSearch,
  allSpecialities,
  providerId,
  providerType,
  resetSpeciality,
}: Props) => {
  const { t } = useTranslation();
  const [speciality, setspeciality] = useState<string>(specialityToSearch);
  const [specialityFound, setspecialityFound] = useState<number>();
  useEffect(() => {
    setspeciality(specialityToSearch);
    const count = allSpecialities.filter((x) =>
      x.name
        .toLocaleLowerCase()
        .includes(specialityToSearch.toLocaleLowerCase())
    );
    setspecialityFound(count.length);
  }, [specialityToSearch]);

  const doMatchSearchText = () =>
    matchSearchText(
      specialityToSearch,
      allSpecialities,
      providerId,
      providerType
    );

  const doResetSpecialty = (e) => {
    e.preventDefault();
    resetSpeciality();
  };

  return (
    <div>
      <Flex
        // @ts-ignore
        css={{
          marginBottom: specialityFound === 0 ? '' : '32px',
          '@screen < $sm': {
            justifyContent: 'space-between',
          },
        }}
      >
        <Text
          color="$neutralGray7"
          css={textContentStyles}
          fontWeight={500}
          size="$lg"
        >
          {specialityFound === 0
            ? ` ${t(
                'FACILITY_DETAILS.PHYSICIAN_DIRECTORY_TAB.NO_PROVIDERS_FOUND'
              )}`
            : specialityFound === 1
              ? `${specialityFound} ${t('Specialty found for')} `
              : `${specialityFound} ${t('Specialties found for')} `}
          <Text
            color="$neutralGray7"
            css={textContentStyles}
            fontWeight={700}
            size="$lg"
          >
            {speciality}
          </Text>
        </Text>
        {specialityFound === 0 ? null : (
          <Link
            data-auto-testid="browse-common-specialities-link-all"
            data-testid="browse-common-specialities-link-all"
            onClick={doResetSpecialty}
          >
            {t('Reset')}
          </Link>
        )}
      </Flex>
      {specialityToSearch.trim().length > 0 ? doMatchSearchText() : null}
    </div>
  );
};

export default SpecialitySearch;
