import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { styled } from '@abyss/web/tools/styled';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { SyntheticEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Provider } from '../../models/Provider';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { getFormattedAndValidatedPhoneNumber } from '../../utils/phone.utils';
import { adobeSearchResultClickEvent } from '../AdobeTagging/adobeSearchResultClickEvent';
import { phoneOnly } from '../ConstantsStyles';
import {
  convertProviderTypeToAdobeType,
  formatProviderId,
} from '../Utils/adobeTrackUtils/adobeTrackUtils';
import { CallModal } from './CallModal';
import { ResponsiveText } from './DataCard.style';

const PhoneTag = styled('a', {
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  '&:hover,&:active': {
    textDecoration: 'none',
  },
});

export const CallButton = styled('button', {
  gap: '4px',
  display: 'flex',
  alignItems: 'center',
});

export const phoneTextStyles = {
  fontSize: '14.22px !important',
  lineHeight: '16px',
  fontWeight: '600',
  padding: '0 4px !important',
  '@screen < $sm': {
    fontSize: '$extraSmallText !important',
    fontWeight: '700',
  },
  cursor: 'pointer',
};

type Props = {
  headers?: ResponseHeaders;
  id?: string;
  phone?: string;
  sectionType?: string;
  practitioner?: Provider;
  providerType?: string;
  locationForAnalytics?: string;
  indexForAnalytics?: number;
  selectedFilters?: string;
  searchTerm?: string;
  isCTADisplayLogic?: boolean;
  doShowDocASAP?: boolean | string;
  choosePCP?: boolean;
};

export const Phone = ({
  headers = { correlationId: '' },
  id,
  phone,
  sectionType,
  locationForAnalytics,
  practitioner = {},
  providerType,
  indexForAnalytics,
  selectedFilters,
  searchTerm,
  isCTADisplayLogic = false,
  doShowDocASAP = false,
  choosePCP = false,
}: Props) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const [isOpen, setIsOpen] = useState(false);

  const phoneNumber: string = getFormattedAndValidatedPhoneNumber(phone);
  const phoneNumberDataTestId: string = `phone-card-${sectionType}-tag`;
  const linkTrackLocation: string = phoneNumberDataTestId
    .slice(0, phoneNumberDataTestId.indexOf(`-${sectionType}-`))
    .concat(' tag');

  const handleOnClickCall = () => {
    if (phone) {
      adobeSearchResultClickEvent({
        filters: selectedFilters,
        linkName: linkTrackLocation,
        linkPosition: indexForAnalytics,
        linkLocation: locationForAnalytics,
        customAttributesBlock: {
          correlationId: headers.correlationId,
          providerId: formatProviderId(practitioner),
          providerType: convertProviderTypeToAdobeType(providerType),
        },
        term: searchTerm ?? '',
      });
    }
  };

  const phoneNumberWithIcon = useMemo(
    () => (
      <PhoneTag
        data-auto-testid={phoneNumberDataTestId}
        data-testid={phoneNumberDataTestId}
        href={`tel:${phoneNumber}`}
        onClick={(e: SyntheticEvent) => e.stopPropagation()}
      >
        <IconMaterial
          data-auto-testid={`data-card-phone-icon-${sectionType}-${id}`}
          data-testid={`data-card-phone-icon-${sectionType}-${id}`}
          icon="phone"
          size={mobileScreen ? 18 : 24}
        />
        <ResponsiveText
          color="$interactive1"
          css={phoneTextStyles}
          data-auto-testid={`data-card-phone-number-${sectionType}-${id}`}
          data-testid={`data-card-phone-number-${sectionType}-${id}`}
          fontWeight="$bold"
          onClick={handleOnClickCall}
        >
          {phone ? phoneNumber : t('not_available')}
        </ResponsiveText>
      </PhoneTag>
    ),
    []
  );

  return (
    <>
      {!isCTADisplayLogic ? (
        phoneNumberWithIcon
      ) : !choosePCP ? (
        <>
          <CallButton
            css={{
              maxWidth: doShowDocASAP ? '150px' : '400px',
              width: '100%',
              justifyContent: 'center',
            }}
            data-testid={`data-card-cta-container-${sectionType}-${id}`}
            onClick={() => setIsOpen(true)}
          >
            <IconMaterial
              css={{ cursor: 'pointer' }}
              data-auto-testid={`data-card-call-icon-${sectionType}-${id}`}
              data-testid={`data-card-call-icon-${sectionType}-${id}`}
              icon="phone"
              size={mobileScreen ? 18 : 24}
            />
            <ResponsiveText
              color="$interactive1"
              css={{ ...phoneTextStyles, padding: '4px 0!important' }}
              data-auto-testid={`data-card-call-cta-${sectionType}-${id}`}
              data-testid={`data-card-call-cta-${sectionType}-${id}`}
              fontWeight="$bold"
            >
              {`${t('Call')}`}
            </ResponsiveText>
          </CallButton>
        </>
      ) : (
        phoneNumberWithIcon
      )}
      {isOpen ? (
        <CallModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          title="Snack Card Call Modal"
        >
          {phoneNumberWithIcon}
        </CallModal>
      ) : null}
    </>
  );
};
