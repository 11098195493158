import { useRouter } from '@abyss/web/hooks/useRouter';
import { config } from '@abyss/web/tools/config';
import { find } from 'lodash';
import { useTranslation } from 'react-i18next';

import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../../../common/Utils';
import { useLagoon } from '../../../../hooks/useLagoon';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { FacilityLocationsResponse } from '../../../../models/FacilityDetails';
import { FacilityDDPCode } from '../../../../models/Provider';
import { ProviderLocation } from '../../../../models/ProviderDetails';
import {
  UserDDPCode,
  getSuppressFacilityFlags,
  isShowPreferredFacilityBadge,
  isUserDDPEligible,
} from '../../../../utils/providerDetails.utils';
import {
  getCurrentMember,
  getIsOxfordAndUSP,
  getLoggedInMember,
} from '../../../../utils/user.utils';
import { getTokenData } from '../../../Utils/tokenUtils/tokenUtils';
import { InfoProviderContent } from '../InfoProviderContent';
import { ListItemPopover } from './ListItemPopover';

type Props = {
  selectedLocation: ProviderLocation | FacilityLocationsResponse;
  detailType: String;
};

export const PREFERRED_LAB_ICON = `${config(
  'CDN_BASE_URL'
)}/cdn/assets/images/preferred_lab_network.svg`;

export const FREESTANDING_FACILITY_ICON = `${config(
  'CDN_BASE_URL'
)}/cdn/assets/images/freestanding_facility.svg`;

export const CHECK_CIRCLE_ICON = `${config(
  'CDN_BASE_URL'
)}/cdn/assets/images/check-circle.svg`;

export const PreferredFacilityInfo = ({ selectedLocation }: Props) => {
  const { t } = useTranslation();
  const { getRouteParams } = useRouter();
  const { token } = getRouteParams();
  const tokenData = getTokenData(token);
  const { coverageType } = tokenData;

  const messaging = useLagoon('ui-messaging')();
  const featureFlags = useLagoon('feature-flags')();
  const storeDdpCodeUser = useStore(StoreKeys.USER_DDP_CODE);

  const designatedDiagnosticProviderContent = find(messaging, {
    key: ConstantsLagoon.PREFERRED_FACILITY
      .DESIGNATED_DIAGNOSTIC_PROVIDER_CONTENT,
  });

  const preferredLabNetworkContent = find(messaging, {
    key: ConstantsLagoon.PREFERRED_FACILITY.PREFERRED_LAB_NETWORK_CONTENT,
  });

  const freestandingFacilityContent = find(messaging, {
    key: ConstantsLagoon.PREFERRED_FACILITY.FREESTANDING_FACILITY_CONTENT,
  });

  const oxfordPreferredLabContent = find(messaging, {
    key: ConstantsLagoon.PREFERRED_FACILITY.OXFORD_PREFERRED_LAB_CONTENT,
  });

  const preferredFacilityFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.PREFERRED_FACILITY_IDENTIFICATION
  );

  const suppressFacilityFlags = getSuppressFacilityFlags();
  const {
    suppressPreferredFacilityPLN,
    suppressPreferredFacilityDDP,
    suppressPreferredFacilityFSF,
  } = suppressFacilityFlags;

  const loggedInMember = getLoggedInMember();
  const currentMember = getCurrentMember();
  const ddpCodeUser = loggedInMember?.ddpCode || storeDdpCodeUser;

  const { ddpCode, facilityProviderSpecialties } = selectedLocation || {};
  const { isPreferredLabNetwork, isFreeStandingFacility } =
    facilityProviderSpecialties || {};

  const { isOXMember } = getIsOxfordAndUSP(
    currentMember,
    coverageType,
    featureFlags
  );

  const oxfordPlnFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.OXFORD_PLN
  );

  const showPreferredContent = isShowPreferredFacilityBadge(
    suppressFacilityFlags,
    preferredFacilityFlag,
    ddpCode as FacilityDDPCode,
    ddpCodeUser,
    isPreferredLabNetwork,
    selectedLocation?.isOxPreferredLab,
    isOXMember,
    isFreeStandingFacility,
    oxfordPlnFlag
  );

  const showPLN = !suppressPreferredFacilityPLN && isPreferredLabNetwork;
  const showDDP =
    !suppressPreferredFacilityDDP &&
    isUserDDPEligible(ddpCodeUser as UserDDPCode) &&
    !!ddpCode;
  const showFSF = !suppressPreferredFacilityFSF && isFreeStandingFacility;
  const showOxford =
    oxfordPlnFlag && selectedLocation?.isOxPreferredLab && isOXMember;

  if (!showPreferredContent) {
    return null;
  }

  return (
    <InfoProviderContent
      icon="apartment"
      infoTitle={t('PREFERRED_FACILITY_BENEFITS.HEADING')}
    >
      {showDDP && (
        <ListItemPopover
          content={`${designatedDiagnosticProviderContent?.message}` ?? ''}
          icon={CHECK_CIRCLE_ICON}
          title={t(
            'PREFERRED_FACILITY_BENEFITS.DESIGNATED_DIAGNOSTIC_PROVIDER'
          )}
          urlInfo={{
            url: `${designatedDiagnosticProviderContent?.url}` ?? '',
            title: t('PREFERRED_FACILITY_BENEFITS.DDP_LINK_TEXT'),
          }}
        />
      )}
      {showFSF && (
        <ListItemPopover
          content={freestandingFacilityContent?.message ?? ''}
          icon={FREESTANDING_FACILITY_ICON}
          title={t('PREFERRED_FACILITY_BENEFITS.FREESTANDING_FACILITY')}
        />
      )}
      {showOxford ? (
        <ListItemPopover
          content={oxfordPreferredLabContent?.message ?? ''}
          icon={PREFERRED_LAB_ICON}
          title={t('PREFERRED_FACILITY_BENEFITS.OXFORD_PREFERRED_LAB')}
        />
      ) : (
        showPLN && (
          <ListItemPopover
            content={preferredLabNetworkContent?.message ?? ''}
            icon={PREFERRED_LAB_ICON}
            title={t('PREFERRED_FACILITY_BENEFITS.PREFERRED_LAB_NETWORK')}
          />
        )
      )}
    </InfoProviderContent>
  );
};
