import { Button } from '@abyss/web/ui/Button';
import { Flex } from '@abyss/web/ui/Flex';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { adobeShareProviders } from '../utils';
import { CancelButton, SendEmailButton } from './ShareDrawerContents.styled';

type Props = {
  openShareContents: boolean;
  setOpenShareContents: (a: boolean) => void;
  handleSendEmailDesktop: () => Promise<void>;
};

export const ShareDrawerButtons = ({
  openShareContents,
  setOpenShareContents,
  handleSendEmailDesktop,
}: Props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Flex>
        <Button
          css={SendEmailButton}
          data-auto-testid="send-email"
          data-testid="send-email"
          onClick={() => {
            adobeLinkTrackEvent({
              name: 'send email',
              location: `modal:${adobeShareProviders}`,
              type: 'internal',
            });
            handleSendEmailDesktop();
          }}
          type="submit"
        >
          {t('SHARE_INNER_DRAWER_TEXT.SEND_EMAIL')}
        </Button>
        <Button
          css={CancelButton}
          data-auto-testid="recipient-drawer-cancel-button"
          data-testid="recipient-drawer-cancel-button"
          onClick={() => {
            adobeLinkTrackEvent({
              name: 'cancel',
              location: `modal:${adobeShareProviders}`,
              type: 'internal',
            });
            setOpenShareContents(!openShareContents);
          }}
          type="cancel"
          variant="outline"
        >
          {t('SHARE_INNER_DRAWER_TEXT.CANCEL')}
        </Button>
      </Flex>
    </React.Fragment>
  );
};
