import { styled } from '@abyss/web/tools/styled';
import { Card } from '@abyss/web/ui/Card';
import { Flex } from '@abyss/web/ui/Flex';
import { Text } from '@abyss/web/ui/Text';

export const DataContainer = styled('div', {
  display: 'grid',
  width: '100%',
});

export const CardContainer = styled(Card, {
  boxShadow: '0px 2px 6px rgba(25, 25, 26, 0.16)',
  flex: 'none',
  borderRadius: '12px',
  border: '1px solid $neutralGray2',
  marginTop: '0 !important',
  height: '198px',
  '@screen < $sm': {
    height: '176px',
  },
  '&.active': {
    border: '2px solid $tint3',
  },
  '&.abyss-card-root': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  '.account-icon': {
    width: '48px',
    height: '48px',
  },
  '.abyss-heading-root': {
    fontSize: '$lg',
    fontWeight: '$bold',
    lineHeight: '$lg',
    paddingLeft: '$sm',
    '@screen < $md': {
      fontSize: '$sm',
    },
  },
  '.text': {
    fontSize: '$sm',
    lineHeight: '$md',
  },
  '.arrow_forward': {
    width: '12px',
  },
  '.check_circle, .star-icon ': {
    height: '13.33px',
    width: '13.33px',
  },
  '@screen < $md': {
    '.location-icon': {
      width: '20px',
    },
    '.bottom-section': {
      padding: '$sm $sm',
      '.abyss-text-root': {
        fontSize: '$xs',
      },
    },
    '.text': {
      fontSize: '$extraSmallText',
      fontWeight: '$medium',
    },
  },
});

export const displayVirtualVisitStyle = {
  'abyss-flex-root': {
    display: 'flex',
    borderRadius: '100px',
    background: '$primary1',
    alignItems: 'center',
    padding: '4px',
    width: '24px',
    height: '24px',
  },
};

export const VirtualProviderGroupImage = styled(Flex, {
  alignItems: 'flex-start',
  alignContent: 'center',
});

export const ProviderImage = styled('img', {
  width: '344px',
  height: '49px',
  '@screen < $sm': {
    width: '256px',
    height: '36px',
    display: 'flex',
    alignItems: 'center',
  },
});

export const HeadingWrapper = styled(Flex, {
  cursor: 'pointer',
  justifyContent: 'space-between',
  direction: 'row',
  alignItems: 'flex-start',
  gap: '16px',
});

export const ResponsiveText = styled(Text, {
  '&.abyss-text-root': {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    '@screen < $sm': {
      fontSize: '12.64px',
      lineHeight: '16px',
    },
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-line-clamp': '2',
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    maxWidth: '250px',
  },
  wordBreak: 'break-word',
});

export const BottomWrapper = styled(Flex, {
  borderTop: '1px solid #CBCCCD',
  padding: '8px 0 6px 12px',
  flexWrap: 'nowrap',
  height: '47px',
  span: {
    padding: '$xs 0',
  },
  '.icon-text-container': {
    alignItems: 'center',
    gap: '4px',
  },
});

export const VirtualVisitWrapper = styled('div', {
  gap: '4px',
  alignItems: 'center',
  paddingTop: '2px',
  '@screen < $sm': {
    padding: '0px',
  },
});
