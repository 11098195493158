import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';

export const Input = styled('input', {
  border: 'none !important',
  fontSize: 18,
  outlineWidth: '0',
  padding: '0px !important',
  width: '100%',
  '&:focus': {
    boxShadow: 'none',
    textOverflow: 'ellipsis',
  },
  '&.allyFocusWithin:focus': {
    boxShadow: 'none',
  },
  textOverflow: 'ellipsis',
});

export const ButtonContainer = styled(Button, {
  justifyContent: 'center',
  alignItem: 'center',
  '&.abyss-button-root': {
    padding: '4px 16px ',
    height: '32px',
    lineHeight: '20px',
    width: '83px',
    display: 'flex',
    justifyContent: 'center',
    alignItem: 'center',
  },
});

export const LayoutGroupStyle = {
  'abyss-layout-group': {
    width: '83px',
    height: '32px',
    marginTop: '4px',
    marginRight: '2px',
  },
};
