import { Box } from '@abyss/web/ui/Box';
import { Button } from '@abyss/web/ui/Button';
import { Card } from '@abyss/web/ui/Card';
import { Flex } from '@abyss/web/ui/Flex';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import {
  NEW_TAB_CONFIRMATION,
  PRACTITIONER_NAME_FOR_ANALYTICS,
} from '../../../../../common/Constants';
import { CustomAttributesBlock } from '../../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { SelectedProviderList } from '../../../../../models/Provider';
import {
  getNameAttrs,
  parseProviderName,
} from '../../../../../utils/providerNames.utils';
import { ConfirmationModal } from '../CompareDrawer/Components/ConfirmationModal/ConfirmationModal';
import {
  ButtonCss,
  LocationIconMobile,
  PlaceHolderText,
  RemoveButtonIcon,
  RemoveButtonText,
  ShareBoxStyledMobile,
  ShareBoxStyledSelectedMobile,
  ShareBoxTextAddressMobile,
  ShareBoxTextNameMobile,
} from './ShareDrawer.styled';

type Props = {
  selectedProviderList: SelectedProviderList;
  selected: number;
  modal: any;
  removeItem: any;
  handleProviderNameAndCarrotClick: any;
  clickedIndex: number;
  customAttributesBlock: CustomAttributesBlock;
  handleClickCb: any;
  index: number;
};
export const CarouselSelectedProviderSlide = ({
  selectedProviderList,
  selected,
  modal,
  removeItem,
  handleProviderNameAndCarrotClick,
  clickedIndex,
  customAttributesBlock,
  handleClickCb,
  index,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Card
      css={{
        'abyss-card-root': {
          border: 'none',
          boxShadow: 'none',
        },
      }}
    >
      <Card.Section css={{ padding: '10px' }}>
        {selectedProviderList[index] ? (
          <div>
            <Box
              align="start"
              css={
                selected ? ShareBoxStyledSelectedMobile : ShareBoxStyledMobile
              }
              key={selectedProviderList[index].locationId}
            >
              <IconBrand
                css={{
                  '&.abyss-icon-root': {
                    width: '146px',
                    height: '112px',
                  },
                }}
                icon="heart"
                size="$xl"
              />
              <Flex direction="column">
                <Tooltip
                  align="center"
                  content={parseProviderName(
                    selectedProviderList[index].providerName,
                    selectedProviderList[index].providerType,
                    selectedProviderList[index].primaryDegrees?.[0],
                    getNameAttrs(selectedProviderList[index])
                  )}
                  position="top"
                  positionOffset={8}
                >
                  <ShareBoxTextNameMobile
                    data-auto-testid="share-provider-name"
                    data-testid="share-provider-name"
                    onClick={() => handleProviderNameAndCarrotClick(index)}
                  >
                    {parseProviderName(
                      selectedProviderList[index].providerName,
                      selectedProviderList[index].providerType,
                      selectedProviderList[index].primaryDegrees?.[0],
                      {
                        firstName: selectedProviderList[index].firstName,
                        middleName: selectedProviderList[index].middleName,
                        lastName: selectedProviderList[index].lastName,
                      }
                    )}
                  </ShareBoxTextNameMobile>
                </Tooltip>
                <Flex>
                  <IconMaterial css={LocationIconMobile} icon="location_on" />
                  <Tooltip
                    align="center"
                    content={t(selectedProviderList[index].address.line[0])}
                    position="top"
                    positionOffset={8}
                  >
                    <ShareBoxTextAddressMobile>
                      {t(selectedProviderList[index]?.address?.line?.[0])}
                    </ShareBoxTextAddressMobile>
                  </Tooltip>
                </Flex>
              </Flex>
              <ConfirmationModal
                closeButtonText={
                  t('COMPARE_DRAWER_CONFIRMATION_MODAL.VIEW_PROVIDER_BUTTON') ||
                  ''
                }
                dataForAnalytics={{
                  linkName: PRACTITIONER_NAME_FOR_ANALYTICS,
                  linkLocation: `modal:share card:position ${clickedIndex}`,
                  modalName: NEW_TAB_CONFIRMATION,
                  linkType: undefined,
                  customAttributesBlock,
                }}
                handleClickCb={() => handleClickCb()}
                isViewProvider
                locationForAnalytics={`modal:${NEW_TAB_CONFIRMATION}`}
                modal={modal}
                modalName="view-provider-modal"
              />
            </Box>
            <Layout.Group css={ButtonCss}>
              <Button
                aria-label={`${t(
                  'SHARE_DRAWER_TEXT.REMOVE'
                )} ${parseProviderName(
                  selectedProviderList[index]?.providerName,
                  selectedProviderList[index]?.providerType,
                  selectedProviderList[index]?.primaryDegrees?.[0],
                  {
                    firstName: selectedProviderList[index].firstName,
                    middleName: selectedProviderList[index].middleName,
                    lastName: selectedProviderList[index].lastName,
                  }
                )}`}
                data-auto-testid="button-remove"
                data-testid="button-remove"
                onClick={() => {
                  adobeLinkTrackEvent({
                    name: 'remove button',
                    location: `modal:share card:position ${index + 1}`,
                    type: 'internal',
                  });
                  removeItem(selectedProviderList[index].locationId);
                }}
                variant="ghost"
              >
                <IconMaterial
                  css={RemoveButtonIcon}
                  icon="remove_circle_outline"
                />
                <Text css={RemoveButtonText}>
                  {t('SHARE_DRAWER_TEXT.REMOVE')}
                </Text>
              </Button>
            </Layout.Group>
          </div>
        ) : (
          <Box
            css={ShareBoxStyledMobile}
            index={index}
            key={`share-card-skeleton${index}`}
          >
            <Text css={PlaceHolderText}>
              {t('SHARE_DRAWER_TEXT.CARD_PLACEHOLDER')}
            </Text>
          </Box>
        )}
      </Card.Section>
    </Card>
  );
};
