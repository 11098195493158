import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Divider } from '@abyss/web/ui/Divider';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { adobeLinkTrackEvent } from '../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../../../common/Constants';
import { ConstantsRoutes } from '../../../../../common/ConstantsRoutes';
import { phoneOnly } from '../../../../../common/ConstantsStyles';
import { PSXIcon } from '../../../../../common/PSXIcon';
import { convertObjectToUrlParams } from '../../../../../common/Utils';
import { useChipStore } from '../../../../../store/useChipStore';
import { ChipState } from '../../../../../store/useChipStore/chipStore';
import {
  StyledContactUsButton,
  StyledContactUsLink,
  StyledLowerFlex,
  StyledLowerModalSection,
  StyledMiddleModalSection,
  StyledRestartSearchButton,
  StyledTopModalSection,
  StyledUpdateErrorIcon,
  StyledUpdateErrorModal,
} from './ChoosePCPLocation.styled';

type Props = {
  handleClose(): void;
  isOpen: boolean;
  searchButtonVariant: boolean;
  primaryPhoneNumber?: string;
};

function onClick(url, phone) {
  adobeLinkTrackEvent({
    name: `call ${phone}`,
    location: 'body: error modal',
    type: 'phone',
    destinationUrl: url,
  });
}

export const UpdateErrorModal = ({
  handleClose,
  isOpen,
  searchButtonVariant,
  primaryPhoneNumber,
}: Props) => {
  const mobileScreen = useMediaQuery(phoneOnly);
  const { navigate } = useRouter();
  const { t } = useTranslation();
  const chipStore = useChipStore(useShallow((state: ChipState) => state));

  const handleBackToSearch = (e) => {
    e.preventDefault();
    const urlParams = convertObjectToUrlParams(chipStore, {});
    navigate(`${ConstantsRoutes.PROVIDER_SEARCH_RESULTS.path}${urlParams}`);
  };

  const alignVal = mobileScreen ? 'left' : 'center';

  return (
    <StyledUpdateErrorModal
      isOpen={isOpen}
      onClose={handleClose}
      size={mobileScreen ? 320 : 624}
    >
      <StyledTopModalSection>
        <Layout.Stack alignLayout="center" space={0}>
          <StyledUpdateErrorIcon icon="alert" size={84} variant="twotone" />
        </Layout.Stack>
        <Layout.Stack alignItems={alignVal} alignLayout={alignVal} space={0}>
          <Text fontWeight="bold" size="lg">
            {t('CHOOSE_PCP_FLOW.ERROR_MODAL.PROVIDER_NOT_AVAILABLE')}
          </Text>
          <Text size="sm">
            {t('CHOOSE_PCP_FLOW.ERROR_MODAL.SELECT_DIFFERENT_PROVIDER')}
          </Text>
        </Layout.Stack>
      </StyledTopModalSection>
      {searchButtonVariant ? (
        <React.Fragment>
          <StyledMiddleModalSection>
            <Layout.Stack alignLayout={alignVal} space={0}>
              <StyledRestartSearchButton
                data-auto-testid="restart-search-button"
                data-testid="restart-search-button"
                onClick={handleBackToSearch}
                size="$md"
              >
                {t('CHOOSE_PCP_FLOW.ERROR_MODAL.RETURN_TO_SEARCH')}
              </StyledRestartSearchButton>
            </Layout.Stack>
          </StyledMiddleModalSection>
          <Divider height="1px" margin={0} />
          <StyledLowerModalSection>
            <Layout.Stack alignItems="left" space="16">
              <Text color="$primary1" fontWeight="bold" size="md">
                {t('CHOOSE_PCP_FLOW.ERROR_MODAL.MORE_HELP')}
              </Text>
              <div id={Constants.LIVE_PERSON_EMBEDDED_CHAT_DIV_ID} />
              <StyledContactUsLink
                before={<PSXIcon icon="call_us" size={20} />}
                data-testid="error-modal-call-us"
                fontWeight="bold"
                href={`tel:${primaryPhoneNumber}`}
                onClick={() =>
                  onClick(`tel:${primaryPhoneNumber}`, primaryPhoneNumber)
                }
                size="$sm"
              >
                {t('CHOOSE_PCP_FLOW.ERROR_MODAL.CALL_US')}
              </StyledContactUsLink>
            </Layout.Stack>
          </StyledLowerModalSection>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <StyledMiddleModalSection>
            <Layout.Stack alignLayout={alignVal} space={0}>
              <Link
                before={
                  <IconMaterial color="#0C55B8" icon="refresh" size="sm" />
                }
                color="#0C55B8"
                data-auto-testid="restart-search-link"
                data-testid="restart-search-link"
                fontWeight="bold"
                onClick={handleBackToSearch}
                size="$sm"
              >
                {t('CHOOSE_PCP_FLOW.ERROR_MODAL.RETURN_TO_SEARCH')}
              </Link>
            </Layout.Stack>
          </StyledMiddleModalSection>
          {!mobileScreen ? <Divider height="1px" margin={0} /> : null}
          <StyledLowerModalSection>
            <Layout.Stack alignItems="left" space={mobileScreen ? 10 : 16}>
              <Text color="$primary1" fontWeight="bold" size="md">
                {t('CHOOSE_PCP_FLOW.ERROR_MODAL.MORE_HELP')}
              </Text>
              <StyledLowerFlex>
                <div id={Constants.LIVE_PERSON_EMBEDDED_CHAT_DIV_ID} />
                <StyledContactUsButton
                  before={<PSXIcon icon="call_us" size={20} />}
                  data-testid="error-modal-call-us"
                  href={`tel:${primaryPhoneNumber}`}
                  onClick={() =>
                    onClick(`tel:${primaryPhoneNumber}`, primaryPhoneNumber)
                  }
                  variant="outline"
                >
                  {t('CHOOSE_PCP_FLOW.ERROR_MODAL.CALL_US')}
                </StyledContactUsButton>
              </StyledLowerFlex>
            </Layout.Stack>
          </StyledLowerModalSection>
        </React.Fragment>
      )}
    </StyledUpdateErrorModal>
  );
};
