import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Accordion } from '@abyss/web/ui/Accordion';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { capitalizeEachWord } from '../../../../utils/providerNames.utils';
import { Constants } from '../../../Constants';
import { mobileOnly } from '../../../ConstantsStyles';
import {
  CollapseCardHeaderStyle,
  CollapseSubSection,
  collapseSection,
} from '../../../ProviderDetailTabs/About/About.styled';
import { CopyToClipBoard } from '../../../ProviderDetailTabs/ProviderLocationsDetails/CopyToClipBoard';

export interface ProviderListItem {
  title: string;
  value: string | string[] | JSX.Element[];
  translation?: {
    title: string;
  };
  canCopy?: boolean;
}

export type Props = {
  collapseHeader: string;
  collapseContent?: string[] | null;
  providerGroupDetail?: Array<ProviderListItem> | null;
  accordionItemValue: string;
};

export const CollapseProviderDetailsSection = ({
  collapseHeader,
  collapseContent,
  providerGroupDetail,
  accordionItemValue,
}: Props) => {
  const { t } = useTranslation();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const mobile = useMediaQuery(mobileOnly);
  const listProviderDetail = () => {
    const fontSize = mobile ? '$md' : '$lg';
    return (
      <CollapseSubSection>
        {providerGroupDetail?.map((item) => (
          <Flex direction="column">
            <Text
              color="$gray7"
              data-auto-testid={`${item.title
                .replace(/ /g, '-')
                .toLocaleLowerCase()}`}
              data-testid={`${item.title
                .replace(/ /g, '-')
                .toLocaleLowerCase()}`}
              fontWeight={mobileOnly ? '$semibold' : '$bold'}
              size={fontSize}
            >
              {item?.translation?.title}
            </Text>
            <Layout.Stack alignItems="left">
              {(typeof item.value === 'string' ? [item.value] : item.value).map(
                (value) => (
                  <Flex
                    css={{
                      'abyss-icon-material': {
                        cursor: 'pointer',
                      },
                      gap: '4px',
                    }}
                  >
                    <Text
                      color={item?.canCopy ? '$info1' : '$gray7'}
                      data-auto-testid={`${item.title
                        .replace(/ /g, '-')
                        .toLocaleLowerCase()}`}
                      data-testid={
                        item?.value?.[0] ===
                        t('PROVIDER_LOCATIONS_DETAILS.NOT_AVAILABLE')
                          ? `${item.title
                              .replace(/ /g, '-')
                              .toLocaleLowerCase()}-not-available`
                          : `${item.title
                              .replace(/ /g, '-')
                              .toLocaleLowerCase()}-subtext`
                      }
                      fontWeight={item?.canCopy ? '$bold' : '$medium'}
                      size={fontSize}
                    >
                      {value}
                    </Text>
                    {item?.value?.[0] !==
                      Constants.PROVIDER_GROUP_DETAILS.ABOUT_TAB.DETAILS_SECTION
                        .NOT_AVAILABLE &&
                      item?.canCopy && (
                        <CopyToClipBoard
                          label={`Copy ${item.title} to clipboard`}
                          text={value}
                          title={item.title}
                        />
                      )}
                  </Flex>
                )
              )}
            </Layout.Stack>
          </Flex>
        ))}
      </CollapseSubSection>
    );
  };

  return (
    <React.Fragment>
      <Layout.Group css={collapseSection(false, false, mobile)}>
        {mobile ? (
          <Accordion
            css={CollapseCardHeaderStyle(isWidget)}
            defaultValue={accordionItemValue}
            isCollapsible
          >
            <Accordion.Item value={accordionItemValue}>
              <Accordion.Trigger>
                <Accordion.Header>{t(collapseHeader)}</Accordion.Header>
              </Accordion.Trigger>
              <Accordion.Content>
                {collapseHeader ===
                t(
                  'PROVIDER_GROUP_DETAILS.ABOUT_TAB.PROVIDER_GROUP_DETAILS_TITLE'
                )
                  ? listProviderDetail()
                  : collapseContent?.map((item) => capitalizeEachWord(item))}
              </Accordion.Content>
            </Accordion.Item>
          </Accordion>
        ) : (
          <Layout.Stack alignItems="left" space={12}>
            <Heading
              data-auto-testid={`${collapseHeader
                .replace(/ /g, '-')
                .toLocaleLowerCase()}-heading`}
              data-testid={`${collapseHeader
                .replace(/ /g, '-')
                .toLocaleLowerCase()}-heading`}
              display="h4"
              offset={1}
            >
              {collapseHeader}
            </Heading>
            {collapseHeader ===
            t('PROVIDER_GROUP_DETAILS.ABOUT_TAB.PROVIDER_GROUP_DETAILS_TITLE')
              ? listProviderDetail()
              : collapseContent?.map((item) => (
                  <Text
                    color="#4B4D4F"
                    data-auto-testid="specialty-type"
                    data-testid="specialty-type"
                    fontWeight="$medium"
                    key={`expand-section-${item}`}
                    size="$lg"
                  >
                    {capitalizeEachWord(item)}
                  </Text>
                ))}
          </Layout.Stack>
        )}
      </Layout.Group>
    </React.Fragment>
  );
};
