import { t } from 'i18next';
import { Fragment } from 'react';
import React from 'react';

import { PatientReviewSuggestionCard } from './RatingSuggestionList';

type Props = {
  answers: any;
};
export const PatientReviewSuggeston = ({ answers }: Props) => (
  <Fragment>
    <PatientReviewSuggestionCard
      answersType="Positive"
      heading={t('What went well')}
      showItem={true}
      surveyAnswers={answers}
    />
    <PatientReviewSuggestionCard
      answersType="Negative"
      heading={t('What could be improved')}
      showItem={false}
      surveyAnswers={answers}
    />
  </Fragment>
);
