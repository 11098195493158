import { config } from '@abyss/web/tools/config';

import { useCustomQuery } from '../useCustomQuery';
import GetLocation from './Location.graphql';

export const useLocation = (options) =>
  useCustomQuery(GetLocation, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'location',
  });
