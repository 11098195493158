import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { config } from '@abyss/web/tools/config';
import { Button } from '@abyss/web/ui/Button';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import find from 'lodash/find';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { phoneOnly } from '../ConstantsStyles';
import {
  MedicalAvatar,
  ModalContent,
  ModalDataContent,
  ModalHeading,
  ModalSubTitle,
  ThingsToConsiderMobileModalStyle,
  ThingsToConsiderModalStyle,
} from './ThingsToConsider.style';

export const ThingsToConsiderModal = ({ isOpen, onClose, onContinue }) => {
  if (!isOpen) return null;
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const uiMessaging = useLagoon('ui-messaging')();
  const memberIdContent = find(uiMessaging, {
    key: ConstantsLagoon.THINGS_TO_CONSIDER
      .THINGS_TO_CONSIDER_MEMBER_ID_CONTENT,
  });

  const waitingPeriodContent = find(uiMessaging, {
    key: ConstantsLagoon.THINGS_TO_CONSIDER
      .THINGS_TO_CONSIDER_WAITING_PERIOD_CONTENT,
  });

  const memberIdTitle = find(uiMessaging, {
    key: ConstantsLagoon.THINGS_TO_CONSIDER.THINGS_TO_CONSIDER_MEMBER_ID_TITLE,
  });

  const waitingPeriodTitle = find(uiMessaging, {
    key: ConstantsLagoon.THINGS_TO_CONSIDER
      .THINGS_TO_CONSIDER_WAITING_PERIOD_TITLE,
  });

  const changePCPTitle = find(uiMessaging, {
    key: ConstantsLagoon.THINGS_TO_CONSIDER.THINGS_TO_CONSIDER_TITLE,
  });

  const thingsToConsiderSubTitle = find(uiMessaging, {
    key: ConstantsLagoon.THINGS_TO_CONSIDER.THINGS_TO_CONSIDER_SUBTITLE,
  });

  return (
    <Modal
      css={
        mobileScreen
          ? ThingsToConsiderMobileModalStyle
          : ThingsToConsiderModalStyle
      }
      data-testid="things-to-consider-modal"
      footer={
        <Modal.Section>
          <Layout.Group alignLayout="right">
            <Button
              data-testid="cancel-button"
              onClick={onClose}
              variant="outline"
            >
              {t('THINGS_TO_CONSIDER_MODAL.CANCEL_BUTTON')}
            </Button>
            <Button data-testid="continue-button" onClick={onContinue}>
              {t('THINGS_TO_CONSIDER_MODAL.CONTINUE_BUTTON')}
            </Button>
          </Layout.Group>
        </Modal.Section>
      }
      isOpen={isOpen}
      onClose={onClose}
      title={changePCPTitle?.message}
      titleAlign="center"
    >
      <ModalContent data-testid="things-to-consider-content">
        <MedicalAvatar style={mobileScreen ? { left: '40%' } : { left: '45%' }}>
          <img
            alt=""
            src={`${config(
              'CDN_BASE_URL'
            )}/cdn/assets/images/default_Practitioner.jpg`}
          />
        </MedicalAvatar>
        <Modal.Section data-testid="things-to-consider-section">
          <ModalSubTitle>{thingsToConsiderSubTitle?.message}</ModalSubTitle>
          <ModalHeading css={{ marginTop: '26px' }}>
            {memberIdTitle?.message}
          </ModalHeading>
          <ModalDataContent>{memberIdContent?.message}</ModalDataContent>
          <ModalHeading>{waitingPeriodTitle?.message}</ModalHeading>
          <ModalDataContent>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  waitingPeriodContent?.message ??
                  waitingPeriodContent?.message,
              }}
            />
          </ModalDataContent>
        </Modal.Section>
      </ModalContent>
    </Modal>
  );
};
