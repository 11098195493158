import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';

export const CollapseCardHeaderStyle = {
  backgroundColor: 'white',
  borderRadius: 'unset',
  border: 'none',
  borderLeft: 'none',
  borderRight: 'none',
  boxShadow: 'none',
  color: '$primary1',
  fontSize: '20.25px',
  fontWeight: '$bold',
  lineHeight: '24px',
  marginBottom: '16px',
  minHeight: 'unset',
  padding: '0',
  '.abyss-card-title': {
    margin: '$md 0',
    maxHeight: '24px',
  },
};

export const SpecialityCollapseProvider = styled('div', {
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
});
export const SpecialityCollapseHeaderProvider = styled('button', {
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  padding: '12px $md',
  borderTop: '1px solid $gray4',
  textAlign: 'left',
});
export const CollapseName = styled('div', {
  fontSize: '$lg',
  color: '$gray8',
});
export const CollapseIconContainer = styled('div', {});

export const SectionContainer = styled(Flex, {
  alignItems: 'start',
  padding: '$md',
  '@media (max-width: 1248px)': {
    padding: '$md $lg',
  },
  '@screen < $md': {
    padding: '$md',
  },
});

export const NoProviderText = styled('p', {
  color: '$gray8',
  marginBottom: '$md',
});
