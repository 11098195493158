import { styled } from '@abyss/web/tools/styled';
import { Heading } from '@abyss/web/ui/Heading';

export const WelcomeMessageContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'noWrap',
  paddingBottom: '12px',
  fontFamily: '$primary',
  marginRight: '24px',
  '@screen < $md': {
    marginRight: '16px',
  },
});

export const WelcomeMessage = styled('div', {
  marginRight: '$xs',
  color: '$primary1',
  fontWeight: '$bold',
  fontFamily: '$primary',
  '.abyss-text-root': {
    background: 'none',
    color: '$primary1',
    fontSize: '36.48px',
    fontWeight: '600',
    lineHeight: '48px',
    letterSpacing: '0px',
    textAlign: 'left',
    '@screen < $sm': {
      fontFamily: 'AbyssUHCSans',
      fontWeight: '700',
      fontStyle: 'normal',
      fontSize: '16px',
      lineHeight: '20px',
      paddingLeft: 0,
    },
  },
  '.abyss-icon-material': {
    verticalAlign: 'middle',
  },
});

export const WelcomeMessageName = styled(Heading, {
  fontSize: '22px',
  color: '$primary1',
  fontWeight: '$bold',
  fontFamily: '$heading',
  '@screen < $md': {
    '&.abyss-heading-root': {
      lineHeight: '1rem',
    },
  },
  '.abyss-popover-trigger ~ div': {
    zIndex: '9 !important',
  },
  '.abyss-button-root': {
    fontSize: 'md',
  },
  '.abyss-dropdown-menu-trigger': {
    paddingLeft: '$xs',
    paddingRight: 0,
  },
  'div:first-child': {
    '@media (min-width: 464px)': {
      display: 'inline-flex',
      flexWrap: 'wrap',
      columnGap: '7px',
    },
  },
});

export const WelcomeMessageNameContainer = styled('div', {
  display: 'block',
  textAlign: 'left',
  paddingLeft: '16px',
  alignItems: 'center',
  p: {
    marginBottom: 0,
  },
});

export const Avatar = styled('div', {
  width: '40px',
  height: '40px',
  cursor: 'pointer',
  borderRadius: '50%',
  backgroundColor: '$memeberInitialsBgColor',
  display: 'inline-block',
  boxSizing: 'border-box',
  div: {
    color: '$memeberInitialsTextColor',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    fontWeight: '$memeberInitialsFW',
  },
});
