import { config } from '@abyss/web/tools/config';

import { useCustomQuery } from '../useCustomQuery';
import GET_EDUCATIONAL_CONTENT_STATUS from './EducationalContentStatus.graphql';
import SAVE_EDUCATIONAL_CONTENT_STATUS from './SaveEducationalContentStatus.graphql';

export const useEducationalContentStatus = (options) =>
  useCustomQuery(GET_EDUCATIONAL_CONTENT_STATUS, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'educationalContentStatus',
  });

export const useSaveEducationalContentStatus = (options) =>
  useCustomQuery(SAVE_EDUCATIONAL_CONTENT_STATUS, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'saveEducationalContentStatus',
  });
