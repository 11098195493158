import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Link } from '@abyss/web/ui/Link';
import { Markdown } from '@abyss/web/ui/Markdown';
import { Modal } from '@abyss/web/ui/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { ConstantsLagoon } from '../ConstantsLagoon';
import {
  HealthGradesStyled,
  StyledIcon,
} from './HealthGradesDisclaimer.styled';

export const HealthGradesDisclaimer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const lagoonData = useLagoon('ui-messaging')();
  const healthGradesDisclaimerContentFull = lagoonData?.find(
    (item) => item.key === ConstantsLagoon.HEALTHGRADES.MODAL_DISCLAIMER_FULL
  );
  const { t } = useTranslation();

  return (
    <HealthGradesStyled>
      <StyledIcon
        icon="healthgrades"
        isScreenReadable
        size={111}
        title={t('HEALTH_GRADES_LOGO_TITLE')}
      />
      <Link
        css={{
          'abyss-link-root': {
            fontSize: '$extraSmallText',
            lineHeight: '$sm',
            height: '13px',
            width: 'fit-content',
          },
        }}
        data-auto-testid="healthgrades-link-text"
        data-testid="healthgrades-link-text"
        fontWeight="$semibold"
        onClick={(ev) => {
          ev.stopPropagation();
          ev.preventDefault();
          setIsOpen(true);
        }}
      >
        {t('HEALTHGARDE_DISCLAIMER.LINK_TEXT')}
        <IconMaterial icon="chevron_right" size="$xs" />
      </Link>
      <Modal
        css={{
          'abyss-modal-header-container': { marginTop: '$md' },
          'abyss-modal-overlay': { zIndex: 3000 },
        }}
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={t('HEALTHGARDE_DISCLAIMER.HEALTHGRADES_INFORMATION')}
      >
        <Modal.Section>
          <Markdown
            options={{
              overrides: {
                Link: {
                  component: Link,
                },
              },
            }}
          >
            {healthGradesDisclaimerContentFull?.message ?? ''}
          </Markdown>
        </Modal.Section>
      </Modal>
    </HealthGradesStyled>
  );
};
