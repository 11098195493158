import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DefaultErrorWrapper,
  ErrorMsgStyles,
  LayoutStackStyles,
  ProviderDetailsStyles,
} from './ErrorHandlingWidget.styled';

type Props = {
  handleRetry: Function;
};

export const ErrorHandlingWidget = ({ handleRetry }: Props) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <DefaultErrorWrapper>
        <LayoutStackStyles alignItems="normal" space={24}>
          <ProviderDetailsStyles
            color="$primary1"
            fontWeight="$bold"
            size="22.78px"
          >
            {t('DETAILS_WIDGET.PROVIDER_DETAILS')}
          </ProviderDetailsStyles>
          <ErrorMsgStyles color="$primary1" fontWeight="$bold" size="20.25px">
            {t('DETAILS_WIDGET.PROVIDER_DETAILS_ERROR_INFO')}
          </ErrorMsgStyles>
          <Text>{t('DETAILS_WIDGET.PROVIDER_DETAILS_ERROR_MSG')}</Text>
          <Link
            color="$info1"
            data-testid="error-widget-button"
            fontWeight="$bold"
            onClick={handleRetry}
          >
            Try again
          </Link>
        </LayoutStackStyles>
      </DefaultErrorWrapper>
    </React.Fragment>
  );
};
