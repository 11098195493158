import { Popover } from '@abyss/web/ui/Popover';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsOverflow } from '../../../../../../../../../../hooks/useIsOverflow';
import {
  StyledLanguagesSpokenDiv,
  popoverStyle,
} from '../../../../../utility/compareDrawer.styled';
import { NULL_ATTRIBUTE_STATE } from '../../../../../utility/compareDrawerConstants';

type Props = {
  languagesSpoken: string[];
};

export const LanguagesSpokenAttributeMobile = ({ languagesSpoken }: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);

  const formattedLanguage =
    languagesSpoken?.length > 0 && languagesSpoken.filter(Boolean).join(', ');

  const languages = !formattedLanguage
    ? NULL_ATTRIBUTE_STATE.LANGUAGES_SPOKEN
    : formattedLanguage;

  const isAttributeEmpty = languages === NULL_ATTRIBUTE_STATE.LANGUAGES_SPOKEN;

  const LanguageAttribute = (
    <StyledLanguagesSpokenDiv
      cssProps={{ isOverflow, isAttributeEmpty }}
      ref={containerRef}
    >
      {t(languages)}
    </StyledLanguagesSpokenDiv>
  );

  return (
    <React.Fragment>
      {isOverflow ? (
        <Popover
          content={languages}
          css={popoverStyle}
          showClose={false}
          tabIndex="-1"
          width={150}
        >
          {LanguageAttribute}
        </Popover>
      ) : (
        LanguageAttribute
      )}
    </React.Fragment>
  );
};
