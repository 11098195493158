import { styled } from '@abyss/web/tools/styled';
import { Box } from '@abyss/web/ui/Box';
import { Flex } from '@abyss/web/ui/Flex';
import { IconBrand } from '@abyss/web/ui/IconBrand';

export const EAPCodeBoxContainer = styled(Box, {
  '&.abyss-box-root': {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    border: '1px solid $info1',
    alignSelf: 'stretch',
    background: '$tint2',
    overflow: 'hidden',
    padding: '16px',
  },
});

export const EAPIcon = styled(IconBrand, {
  '&.abyss-icon-brand': {},
});

export const EAPPopoverStyling = {
  'abyss-popover-trigger-icon': {
    width: '16px',
    height: '16px',
    marginTop: '5px',
    marginBottom: '2px',
    '@screen < $sm': {
      marginTop: '2px',
    },
  },
};

export const EAPMixResultsContainer = styled('div', {
  paddingBottom: '$md',
});

export const EAPFlexContainer = styled(Flex, {
  '&.abyss-flex-root': {
    display: 'flex',
    flex: '1 0',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    flexWrap: 'nowrap',
    gap: '12px',
    lineHeight: '24px',
    color: '$primary1',
  },
});

export const EAPCodeNeeded = styled(Flex, {
  '&.abyss-flex-root': {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    gap: '12px',
    lineHeight: '24px',
    color: '$primary1',
    '@screen < $md': {
      gap: '8px',
    },
  },
});

export const EAPGetMyCodeLink = {
  'abyss-link-root': {
    color: '$info1',
    fontWeight: '700',
    cursor: 'pointer',
    fontSize: '14.22px',
  },
};

export const EAPCodeText = styled('div', {
  fontSize: '14px',
  fontWeight: 400,
  lineHeigth: '18px',
  color: '$black',
  minWidth: '247px',
  '@screen < $sm': {
    fontSize: '14.22px',
  },
});
