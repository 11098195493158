import { FloatingSection } from '@abyss/web/ui/FloatingSection';
import React from 'react';

import {
  providerFloatDesktopOpen,
  providerFloatMobile,
} from '../../../../common/ConstantsStyles';
import { CompareDrawer } from '../MapView/CompareDrawer';
import { CompareDrawerMobile } from '../MapView/CompareDrawer/CompareDrawerMobile';

type Props = {
  openCompare: boolean;
  mobileScreen: boolean;
  selectedItems: any;
  items: any;
  removeItem: any;
  map: any;
  headers: any;
  setOpenCompare: any;
  setSelectedCheckbox: any;
  setSelectedItems: any;
  setItems: any;
  containerRef: React.RefObject<HTMLDivElement>;
  selectedCheckbox: any;
};

export const ShowCompareFloatingSection = ({
  removeItem,
  selectedCheckbox,
  setSelectedCheckbox,
  openCompare,
  containerRef,
  mobileScreen,
  setOpenCompare,
  headers,
  items,
  selectedItems,
  setItems,
  setSelectedItems,
  map,
}: Props) =>
  openCompare ? (
    <FloatingSection
      containerRef={containerRef}
      css={mobileScreen ? providerFloatMobile : providerFloatDesktopOpen}
      data-auto-testid="mapview-compare-drawer"
      data-testid="mapview-compare-drawer"
      isOpen={openCompare}
      onClose={() => {
        setOpenCompare(false);
        setSelectedCheckbox({ ...selectedCheckbox });
      }}
      position="bottom"
      size={mobileScreen ? window.innerHeight - 104 : 464}
    >
      {mobileScreen ? (
        <CompareDrawerMobile
          data-auto-testid="drawer-compare"
          data-testid="drawer-compare"
          headers={headers}
          items={items}
          removeItem={removeItem}
          selectedCount={selectedItems.length}
          selectedProviders={selectedItems}
          setItems={setItems}
          setOpenCompare={setOpenCompare}
          setSelectedCheckbox={setSelectedCheckbox}
          setSelectedProviders={setSelectedItems}
          total={5}
        />
      ) : (
        <CompareDrawer
          data-auto-testid="drawer-compare"
          data-testid="drawer-compare"
          headers={headers}
          items={items}
          map={map}
          removeItem={removeItem}
          selectedCount={selectedItems.length}
          selectedProviders={selectedItems}
          setItems={setItems}
          setOpenCompare={setOpenCompare}
          setSelectedCheckbox={setSelectedCheckbox}
          setSelectedProviders={setSelectedItems}
          total={5}
        />
      )}
    </FloatingSection>
  ) : null;
