import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import find from 'lodash/find';
import React from 'react';

import { ConstantsLagoon } from '../../../common/ConstantsLagoon';
import { useLagoon } from '../../../hooks/useLagoon';
import {
  NonTierOneLocationCSS,
  NonTierOneLocationContentCSS,
  StyledNonTierOneLocationHeader,
  StyledNonTierOneLocationText,
} from './PopOver.style';

type Props = {
  showNonTierLocationBadge: boolean | undefined;
  areAllProviderLocationsNonTiered: boolean;
  isCns: boolean;
};

export const NonTierOneLocation = ({
  showNonTierLocationBadge,
  areAllProviderLocationsNonTiered,
  isCns,
}: Props) => {
  const data = useLagoon('ui-messaging')();
  const nonTierOneLocation = find(data, {
    key: ConstantsLagoon.CONTENT_FILTERS.NONTIER1_LOCATION_LABEL,
  });

  if (areAllProviderLocationsNonTiered) {
    return null;
  }

  return !isCns && showNonTierLocationBadge ? (
    <Layout.Group
      css={NonTierOneLocationCSS}
      data-testid={'non_tier_one_location_group'}
    >
      <IconMaterial color="#C24E14" icon="warning" size="20px" />
      <Layout.Group css={NonTierOneLocationContentCSS}>
        <StyledNonTierOneLocationHeader
          data-testid={'non_tier_one_location_header'}
        >
          {nonTierOneLocation?.title || ''}
        </StyledNonTierOneLocationHeader>
        <StyledNonTierOneLocationText
          data-testid={'non_tier_one_location_content'}
        >
          {nonTierOneLocation?.message || ''}
        </StyledNonTierOneLocationText>
      </Layout.Group>
    </Layout.Group>
  ) : null;
};
