import React from 'react';

import { FacilityLocationsResponse } from '../../../../../models/FacilityDetails';
import {
  ProviderLocation,
  ProviderType,
} from '../../../../../models/ProviderDetails';
import { Constants } from '../../../../Constants';
import { FacilityQuality } from '../../../../FacilityDetailsTabs/FacilityQuality/FacilityQuality';
import { Quality as ProviderQuality } from '../../../../ProviderDetailTabs/Quality/Quality';

type Props = {
  boardCertifications: string[];
  providerName: string;
  providerType: ProviderType;
  selectedLocation: ProviderLocation | FacilityLocationsResponse;
  organizationCode?: string[];
};
export const Quality = ({
  boardCertifications,
  providerName,
  providerType,
  selectedLocation,
  organizationCode,
}: Props) => {
  if (providerType === ProviderType.ORGANIZATION) {
    return (
      <FacilityQuality
        organizationCode={organizationCode}
        providerName={providerName}
        providerType={providerType}
        selectedLocation={selectedLocation as FacilityLocationsResponse}
        tabTitle={Constants.PROVIDER_DETAILS.QUALITY}
      />
    );
  }

  return (
    <ProviderQuality
      boardCertifications={boardCertifications}
      providerName={providerName}
      providerType={providerType}
      selectedLocation={selectedLocation as ProviderLocation}
      tabTitle={Constants.PROVIDER_DETAILS.QUALITY}
    />
  );
};
