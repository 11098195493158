import { styled } from '@abyss/web/tools/styled';
import { Heading } from '@abyss/web/ui/Heading';
import { Markdown } from '@abyss/web/ui/Markdown';

export const unOrderedList = {
  listStyle: 'disc',
  paddingLeft: '20px',
};
const mobilePadding = {
  '@screen < $md': {
    padding: '16px 0',
  },
};
const nullPagePadding = {
  padding: '24px 0',
  borderBottom: '1px solid #E5E5E6',
  ...mobilePadding,
};

export const WhereToGetHelpContainer = styled('div', {
  margin: '8px 0 8px 0',
  backgroundColor: '$white',
});

export const TopRecommendedStyled = styled('div', {
  margin: '8px 0 8px 0',
  borderTop: '1px solid #E5E5E6',
  backgroundColor: '$white',
  borderBottom: '1px solid #E5E5E6',
});

export const TopRecomendedChipsStyled = styled('div', {
  paddingTop: '16px',
});

export const NullPageContainerStyled = styled('div', {
  backgroundColor: '#FBFCFE',
});

export const CommonlySearchedStyled = styled('div', {
  backgroundColor: '$white',
  marginTop: '8px',
});

export const StyledMarkdown = styled(Markdown, {
  fontSize: '14.22px',
  lineHeight: '16px',
  color: '$gray7',
  fontWeight: '$medium',
});

export const SubHeader = styled(Heading, {
  marginTop: '16px',
  marginBottom: '16px',
});

export const Wrapper = styled('div', {
  maxWidth: '1200px',
  marginLeft: 'auto',
  marginRight: 'auto',
  '@media (max-width: 1248px)': {
    marginLeft: '24px',
    marginRight: '24px',
  },
  '@screen < $md': {
    marginLeft: '16px',
    marginRight: '16px',
  },
});

export const NullResultSectionStyled = styled('div', {
  backgroundColor: '$white',
  borderTop: '1px solid #E5E5E6',
  marginBottom: '8px',
  ...nullPagePadding,
});
