import { Heading } from '@abyss/web/ui/Heading';
import React, { useEffect, useState } from 'react';

import { CssProps } from '../Types/CssProps';
import { CardHeaderOffset, HeaderProps } from '../Types/HeaderProps';
import { merge } from '../Utils/mergeUtil/merge';

const headerCss: CssProps = {
  'abyss-heading-root': {
    margin: '0 0 $sm 0',
    fontSize: '$largeText !important',
    '@screen < $md': {
      fontSize: '$mediumText !important',
    },
  },
};

const subHeaderCss: CssProps = {
  'abyss-heading-root': {
    margin: '0 0 $sm 0',
    '@screen < $md': {
      fontSize: '$md !important',
    },
  },
};

export const CardHeader = ({
  children,
  'data-testid': testId = '',
  color = '',
  offset = CardHeaderOffset.Header,
  css = {},
}: HeaderProps) => {
  const cssToUse =
    offset === CardHeaderOffset.Header ? headerCss : subHeaderCss;
  const [mergedCss, setMergedCss] = useState(cssToUse);
  useEffect(() => {
    setMergedCss(merge(cssToUse, css));
  }, [css]);

  return (
    <Heading
      color={color}
      css={mergedCss}
      data-auto-testid={testId}
      data-testid={testId}
      offset={offset}
    >
      {children}
    </Heading>
  );
};
