import { useTranslation } from 'react-i18next';

import {
  ATTRIBUTE_STATUS_TEXT,
  NULL_ATTRIBUTE_STATE,
} from '../../CompareDrawer/utility/compareDrawerConstants';
import { PrintViewProviderDetailsXSText } from './PrintExpandedCard.styled';

type Props = {
  premiumCareProvider: string;
};

export const PrintPremiumCareProviderText = ({
  premiumCareProvider,
}: Props) => {
  const { t } = useTranslation();

  let attributeStatusText;
  if (premiumCareProvider === 'YES') {
    attributeStatusText = ATTRIBUTE_STATUS_TEXT.PREMIUM_CARE_PROVIDER.TRUE;
  } else if (premiumCareProvider === 'NO') {
    attributeStatusText = ATTRIBUTE_STATUS_TEXT.PREMIUM_CARE_PROVIDER.FALSE;
  } else {
    attributeStatusText = NULL_ATTRIBUTE_STATE.PREMIUM_CARE_PROVIDER;
  }

  return (
    <div>
      <PrintViewProviderDetailsXSText>
        {t(attributeStatusText)}
      </PrintViewProviderDetailsXSText>
    </div>
  );
};
