import { useQuery } from '@abyss/web/hooks/useQuery';
import { config } from '@abyss/web/tools/config';

import MISSING_TRANSLATION_LOG_QUERY from './MissingTranslationLog.graphql';

export const useTranslationLog = (options) => {
  const [, queryAction] = useQuery(MISSING_TRANSLATION_LOG_QUERY, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'saveMissingTranslation',
    onError: (e) => {
      // eslint-disable-next-line no-console
      console.error(e);
    },
  });

  return queryAction;
};
