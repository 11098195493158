import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { styled } from '@abyss/web/tools/styled';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import { Link } from '@abyss/web/ui/Link';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../../../store/useDetailsStore';
import { DetailsStore } from '../../../../store/useDetailsStore/detailsStore';
import { useTypeaheadStore } from '../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../store/useTypeaheadStore/typeaheadStore';
import { capitalizeEachWord } from '../../../../utils/providerNames.utils';
import {
  phoneOnly,
  recentActivityDrawerDesktop,
  recentActivityDrawerMobile,
} from '../../../ConstantsStyles';
import { handleHospitalAffiliationOnClick } from './helper';

type Props = {
  hospitalAffiliations: any;
  isOpen: boolean;
  setIsOpen: Function;
};
const ContainerForAllSectionsStyled = styled('div', {
  dynamic: ({ cssProps: { display = 'block' } }) => ({
    display,
  }),
  border: '1px solid $gray2',
  marginTop: '16px',
});

const ListContainer = styled('ul', {
  margin: '15px 0 auto auto',
  listStyleType: 'none',
  counterReset: 'steps',
});

export const HospitalAffiliationDrawer = ({
  hospitalAffiliations,
  isOpen,
  setIsOpen,
}: Props) => {
  const chipStore = useChipStore(useShallow((state: ChipState) => state));

  const { setTypeaheadSearchStore } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      setTypeaheadSearchStore: state.setTypeaheadSearchStore,
    }))
  );

  const { setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );

  const { t } = useTranslation();
  const { navigate } = useRouter();
  const isWidget = useStore(StoreKeys.IS_WIDGET);
  const mobileScreen = useMediaQuery(phoneOnly);
  const handleOnClose = () => {
    setIsOpen(false);
  };

  const hospitalAffiliationsForDrawer = hospitalAffiliations.reduce(
    (acc, affiliation) => {
      const firstChar = affiliation.value.charAt(0).toUpperCase();
      if (!acc[firstChar]) {
        acc[firstChar] = [];
      }
      acc[firstChar].push(affiliation);
      return acc;
    },
    {}
  );

  return (
    <Drawer
      css={
        mobileScreen ? recentActivityDrawerMobile : recentActivityDrawerDesktop
      }
      isOpen={isOpen}
      onClose={handleOnClose}
      position={mobileScreen ? 'bottom' : 'right'}
      size={mobileScreen ? window.innerHeight - 104 : 408}
      title={
        <Heading
          color="$black"
          data-auto-testid="hospital-affiliation-drawer-heading"
          data-testid="hospital-affiliation-drawer-heading"
          fontStyle="normal"
          offset={3}
        >
          {t(
            'PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.HOSPITAL_AFFILIATIONS_DRAWER'
          )}
        </Heading>
      }
    >
      <ListContainer css={{ margin: '16px' }}>
        {Object.entries(hospitalAffiliationsForDrawer).map(
          ([char, affiliations], index) => (
            <React.Fragment key={char}>
              {index !== 0 && <ContainerForAllSectionsStyled />}
              {affiliations.map((item) =>
                isWidget ? (
                  <Text
                    color="#4B4D4F"
                    data-testid="hospital-affiliations-widget"
                    fontWeight="$medium"
                    key={item.id}
                    size={mobileScreen ? '$md' : '18px'}
                  >
                    {capitalizeEachWord(item.value)}
                  </Text>
                ) : (
                  <Flex key={item.id}>
                    <Link
                      css={{
                        marginTop: '16px',
                        width: '300px',
                        fontWeight: '$semibold',
                        textAlign: 'left',
                      }}
                      data-auto-testid="hospital-affiliations-content-drawer"
                      data-testid="hospital-affiliations-content-drawer"
                      fontWeight="$bold"
                      isStandardAnchor
                      onClick={() => {
                        handleHospitalAffiliationOnClick(
                          item.id,
                          item.value,
                          navigate,
                          {
                            chipStore,
                            setTypeaheadSearchStore,
                            setDetailsStore,
                          }
                        );
                      }}
                    >
                      {capitalizeEachWord(item.value)}
                    </Link>
                  </Flex>
                )
              )}
            </React.Fragment>
          )
        )}
      </ListContainer>
    </Drawer>
  );
};
