import { useCoverageType } from '../../hooks/useCoverageType';
import { useLagoon } from '../../hooks/useLagoon';
import {
  getActiveCoverages,
  getCurrentMember,
  getProductCodeForCoverageType,
} from '../../utils/user.utils';
import { ContentWrapper } from '../ContentWrapper';
import { ContainerForAllSectionsStyled } from '../ProviderDetailTabs/ProviderDetailTabs.styled';
import { AdditionalResourcesCard } from './AdditionalResourcesCard';
import { AdditionalResourcesType } from './additionalResourcesType';

const filterAdditionalResources = (
  resources: AdditionalResourcesType[],
  memberCode: string
): AdditionalResourcesType[] =>
  resources
    .filter((x) => x.isActive?.toString() === 'true')
    .filter((resource) => {
      const productCodes =
        resource.productCode?.split(',').map((x) => x?.trim()) || [];
      return productCodes.includes(memberCode);
    });

export const AdditionalResources = () => {
  const currentMember = getCurrentMember();

  const additionalResources = useLagoon('additional-resources')();

  let coverageType = useCoverageType();
  coverageType =
    coverageType !== ''
      ? coverageType
      : getActiveCoverages(currentMember) &&
        getActiveCoverages(currentMember)[0]
      ? getActiveCoverages(currentMember)[0]
      : '';

  const memberCode = getProductCodeForCoverageType(currentMember, coverageType);

  const filteredResources = filterAdditionalResources(
    additionalResources || [],
    memberCode
  );

  if (filteredResources.length === 0) {
    return null;
  }

  return (
    <ContainerForAllSectionsStyled height="auto">
      <ContentWrapper>
        {filteredResources.map((resource) => (
          <AdditionalResourcesCard
            data-testid="resource-card"
            key={resource.id}
            resource={resource}
          />
        ))}
      </ContentWrapper>
    </ContainerForAllSectionsStyled>
  );
};
