import { Flex } from '@abyss/web/ui/Flex';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Constants,
  PopulationType,
  PremiumCareConstants,
  ProviderSummaryTypes,
} from '../../../../../common/Constants';
import { ConstantsLagoon } from '../../../../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../../../../common/Utils';
import { getLanguage } from '../../../../../frontends/ProviderSearch/context/Internationalization/helpers';
import { useCoverageType } from '../../../../../hooks/useCoverageType';
import { useLagoon } from '../../../../../hooks/useLagoon';
import {
  returnSuppressFlag,
  suppressCostEstimatesForPolicies,
} from '../../../../../utils/featureSuppress';
import {
  UserDDPCode,
  getSuppressFacilityFlags,
  isShowPreferredFacilityBadge,
  isUserDDPEligible,
} from '../../../../../utils/providerDetails.utils';
import {
  getCurrentMember,
  getIsOxfordAndUSP,
  getLoggedInMember,
} from '../../../../../utils/user.utils';
import { PreferredProviderLabels } from './PreferredProviderLabels';
import {
  CostText,
  PrintViewProviderDetailsXSText,
  ProviderIdText,
} from './PrintView.style';

type Props = {
  provider: {
    numberOfReviews?: string;
    acceptingNewPatients: boolean;
    providerName: string;
    providerId: string;
    primaryDegrees: string[];
    speciality: string;
    organizationType: string;
    providerType?: string;
    networkStatus: string;
    distance: string;
    isPreferredLab?: boolean;
    isFreeStandingFacility?: boolean;
    ddpCode?: string;
    healthGradeRating?: string;
    isTieredProvider: boolean;
    isPremiumCarePhysician?: string;
    virtualCareOffered?: boolean;
    languagesSpoken: string[];
    website: string;
    address: {
      postalCode: string;
      district: string;
      line: string[];
      state: string;
      city: string;
    };
    firstName?: string;
    middleName?: string;
    lastName?: string;
    costIndicator?: string;
    phones: {
      phone: string[];
    };
  };
};

export const ProviderDetails = ({ provider }: Props) => {
  const { t } = useTranslation();

  const getCost = (cost: string | undefined) => {
    switch (cost) {
      case '$$$':
        return `$$$ - ${t('COST.ABOVE_AVERAGE')}`;
      case '$$':
        return `$$ - ${t('COST.AVERAGE')}`;
      case '$':
        return `$ - ${t('COST.BELOW_AVERAGE')}`;
      default:
        return '';
    }
  };
  const currentMember = getCurrentMember();
  const featureFlags = useLagoon(Constants.LAGOON_TABLE.FEATURE_FLAGS)();
  const costEnableFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.COST_ENABLED
  );

  // suppress premium care for M&R COSMOS
  const suppressPremiumCare =
    (currentMember?.population === PopulationType.COSMOS ||
      currentMember?.isCnSMember) &&
    returnSuppressFlag(ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE);

  const coverageType = useCoverageType();
  const suppressCostEstimates = suppressCostEstimatesForPolicies(
    coverageType,
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.COST_ESTIMATES,
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.COST_ESTIMATES_EMPIRE
  );
  const suppressFacilityFlags = getSuppressFacilityFlags();
  const {
    suppressPreferredFacilityPLN,
    suppressPreferredFacilityDDP,
    suppressPreferredFacilityFSF,
  } = suppressFacilityFlags;
  const preferredFacilityFlag: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.PREFERRED_FACILITY_IDENTIFICATION
  );

  const ddpCodeUser = getLoggedInMember()?.ddpCode;

  const getPreferredFacilities = (provider) => {
    const {
      ddpCode,
      isFreeStandingFacility,
      isPreferredLab,
      isOxPreferredLab,
    } = provider || {};

    const { isOXMember } = getIsOxfordAndUSP(
      currentMember,
      coverageType,
      featureFlags
    );

    const oxfordPlnFlag: boolean = getFeatureFlag(
      featureFlags,
      ConstantsLagoon.FEATURE_FLAGS.OXFORD_PLN
    );

    const showPreferredContent = isShowPreferredFacilityBadge(
      suppressFacilityFlags,
      preferredFacilityFlag,
      ddpCode,
      ddpCodeUser,
      isPreferredLab,
      isOxPreferredLab,
      isOXMember,
      isFreeStandingFacility,
      oxfordPlnFlag
    );

    const showPLN = !suppressPreferredFacilityPLN && !!isPreferredLab;
    const showDDP =
      !suppressPreferredFacilityDDP &&
      !!isUserDDPEligible(ddpCodeUser as UserDDPCode) &&
      !!ddpCode;
    const showFSF = !suppressPreferredFacilityFSF && !!isFreeStandingFacility;
    const showOxford = oxfordPlnFlag && isOxPreferredLab && isOXMember;

    return { showPreferredContent, showPLN, showDDP, showFSF, showOxford };
  };
  const { showPreferredContent, showPLN, showDDP, showFSF, showOxford } =
    getPreferredFacilities(provider);

  const locale = getLanguage().code;
  const premiumCareData = PremiumCareConstants[locale];
  const premiumCarePhysician = premiumCareData?.premiumCarePhysician;

  return (
    <Flex
      css={{
        'abyss-flex-root': {
          alignItems: 'flex-end',
          display: 'flex',
          flex: '1',
          flexWrap: 'nowrap',
          gap: '2px',
        },
      }}
      direction="column"
    >
      <PrintViewProviderDetailsXSText color="#333333">
        {provider.networkStatus === 'INN'
          ? t('SHARE_PDF.In-network')
          : t('SHARE_PDF.Out-of-network')}
      </PrintViewProviderDetailsXSText>
      {provider?.providerType !== ProviderSummaryTypes.ORGANIZATION && (
        <PrintViewProviderDetailsXSText color="#333333">
          {provider?.acceptingNewPatients
            ? t('SHARE_PDF.Accepting new patients')
            : t('SHARE_PDF.Not accepting new patients')}
        </PrintViewProviderDetailsXSText>
      )}
      <PreferredProviderLabels
        providerType={provider?.providerType as ProviderSummaryTypes}
        showDDP={showDDP}
        showFSF={showFSF}
        showOxford={showOxford}
        showPLN={showPLN}
        showPreferredContent={showPreferredContent}
      />
      {!provider?.isTieredProvider &&
      !suppressPremiumCare &&
      provider?.isPremiumCarePhysician === 'YES' ? (
        <Flex style={{ whiteSpace: 'pre' }}>
          <IconMaterial color="black" icon="favorite" size={'12'} />
          <IconMaterial color="black" icon="favorite" size={'12'} />
          <PrintViewProviderDetailsXSText color="#333333">
            {` ${premiumCarePhysician} *`}
          </PrintViewProviderDetailsXSText>
        </Flex>
      ) : null}
      {provider?.virtualCareOffered ? (
        <Flex>
          <PrintViewProviderDetailsXSText color="#333333">
            {t('SHARE_PDF.Offers virtual visits')}
          </PrintViewProviderDetailsXSText>
        </Flex>
      ) : null}
      {costEnableFlag && !suppressCostEstimates && provider.costIndicator ? (
        <CostText color="#333333">{getCost(provider.costIndicator)}</CostText>
      ) : null}
      {provider?.providerId && (
        <Flex style={{ whiteSpace: 'pre' }}>
          <PrintViewProviderDetailsXSText color="#333333">
            {provider?.providerType === ProviderSummaryTypes.ORGANIZATION
              ? t('Facility ID')
              : t('Provider ID')}
          </PrintViewProviderDetailsXSText>
          <ProviderIdText color="#333333">
            {` ${provider?.providerId}`}
          </ProviderIdText>
        </Flex>
      )}
    </Flex>
  );
};
