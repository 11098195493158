export const CostIndicatorDrawerStyles = {
  'abyss-modal-content-container': {
    borderRadius: '20px 20px 0 0',
    height: 'auto !important',
  },
  'abyss-modal-body': {
    margin: '16px 24px 16px 24px',
    color: '$gray8',
    fontSize: '16px',
    fontWeight: '500',
  },
  'abyss-modal-close-button': { margin: '8px 10px 0 0' },
  'abyss-modal-content-title': {
    paddingTop: '2px',
    fontFamily: '$primary !important',
    fontSize: '20.25px !important',
    color: '#323334 !important',
    lineHeight: '24px !important',
    fontWeight: '700 !important',
  },
  'abyss-modal-header-container': {
    padding: '16px 24px 0px 24px',
  },
};

export const CostIndicatorDrawerTextStyles = {
  '.abyss-text-root': {
    lineHeight: '20px',
    fontSize: '16px',
    letterSpacing: '1.5px',
  },
};

export const PopoverCostIndicatorStyles = {
  '.abyss-text-root': {
    lineHeight: '24px',
    fontSize: '18px',
    letterSpacing: '1.5px',
  },
  '.abyss-popover-content-container': {
    lineHeight: '18px',
  },
};
