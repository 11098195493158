export const mapIcons = {
  arrive: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M10,5a2,2,0,1,1,2-2A2,2,0,0,1,10,5Zm4.91284,8.35114L10.00916,7.0083,5.10547,13.35114a0.38659,0.38659,0,0,0,.40942.62354l2.95184-1.34375A0.35542,0.35542,0,0,1,9.00769,13H9v5.50006A0.49992,0.49992,0,0,0,9.49994,19h1.00012A0.49992,0.49992,0,0,0,11,18.50006V13.0083h0.00916a0.35757,0.35757,0,0,1,.54242-0.37738l2.95184,1.34375A0.3866,0.3866,0,0,0,14.91284,13.35114Z"/>
</svg>`,

  arrive_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M3,12a2,2,0,1,1,2-2A2,2,0,0,1,3,12Zm10.00293-.96332a4.05782,4.05782,0,0,1,3.98877,4.07324H17v1.37775A0.51232,0.51232,0,0,0,17.51233,17h0.97534A0.51232,0.51232,0,0,0,19,16.48767V15H18.98615a6.05607,6.05607,0,0,0-5.9834-5.96332l-0.011-.00183,0.00012,0.02008H12V9.04584a0.35757,0.35757,0,0,1-.37738-0.54242l1.34375-2.95184a0.38659,0.38659,0,0,0-.62354-0.40942L6,10.04584l6.34283,4.90369a0.3866,0.3866,0,0,0,.62354-0.40942l-1.34375-2.95184A0.35757,0.35757,0,0,1,12,11.04584v0.00909h1"/>
</svg>`,

  arrive_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M15,10a2,2,0,1,1,2,2A2,2,0,0,1,15,10ZM7,11.05493H8V11.04584a0.35757,0.35757,0,0,1,.37738.54242L7.03363,14.5401a0.3866,0.3866,0,0,0,.62354.40942L14,10.04584,7.65717,5.14215a0.38659,0.38659,0,0,0-.62354.40942L8.37738,8.50342A0.35757,0.35757,0,0,1,8,9.04584V9.05493H7.00818L7.0083,9.03485l-0.011.00183A6.05607,6.05607,0,0,0,1.01385,15H1v1.48767A0.51232,0.51232,0,0,0,1.51233,17H2.48767A0.51232,0.51232,0,0,0,3,16.48767V15.10992H3.0083a4.05782,4.05782,0,0,1,3.98877-4.07324"/>
</svg>`,

  arrive_straight: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M10,5a2,2,0,1,1,2-2A2,2,0,0,1,10,5Zm4.91284,8.35114L10.00916,7.0083,5.10547,13.35114a0.38659,0.38659,0,0,0,.40942.62354l2.95184-1.34375A0.35542,0.35542,0,0,1,9.00769,13H9v5.50006A0.49992,0.49992,0,0,0,9.49994,19h1.00012A0.49992,0.49992,0,0,0,11,18.50006V13.0083h0.00916a0.35757,0.35757,0,0,1,.54242-0.37738l2.95184,1.34375A0.3866,0.3866,0,0,0,14.91284,13.35114Z"/>
</svg>`,

  close: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M17.35551,4.05855L11.76558,9.64848a0.49713,0.49713,0,0,0,0,.703l5.58993,5.58993a0.49713,0.49713,0,0,1,0,.703l-0.711.711a0.49713,0.49713,0,0,1-.703,0l-5.58993-5.58993a0.49713,0.49713,0,0,0-.703,0L4.05855,17.35551a0.49713,0.49713,0,0,1-.703,0l-0.711-.711a0.49713,0.49713,0,0,1,0-.703l5.58993-5.58993a0.49713,0.49713,0,0,0,0-.703L2.64449,4.05855a0.49713,0.49713,0,0,1,0-.703l0.711-.711a0.49713,0.49713,0,0,1,.703,0L9.64848,8.23442a0.49713,0.49713,0,0,0,.703,0l5.58993-5.58993a0.49713,0.49713,0,0,1,.703,0l0.711,0.711A0.49713,0.49713,0,0,1,17.35551,4.05855Z"/>
</svg>`,

  continue: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M14.50342,8.96637L11.55157,7.62262A0.35755,0.35755,0,0,0,11.00916,8v9.49652A0.50346,0.50346,0,0,1,10.50568,18h-0.993a0.50346,0.50346,0,0,1-.50348-0.50348V8a0.35756,0.35756,0,0,0-.54242-0.37738L5.51489,8.96637a0.38659,0.38659,0,0,1-.40942-0.62354L10.00916,2l4.90369,6.34283A0.3866,0.3866,0,0,1,14.50342,8.96637Z"/>
</svg>`,

  continue_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M10,5.97986l0.011,0.00183A6.06019,6.06019,0,0,1,16,12.05493V16H15.99689l0.002,1.50317A0.49614,0.49614,0,0,1,15.50269,18H14.49622A0.49622,0.49622,0,0,1,14,17.50378V12.05493a4.05782,4.05782,0,0,0-3.98877-4.07324H8.01245a0.3576,0.3576,0,0,0-.37738.54248L8.97882,11.476a0.38659,0.38659,0,0,1-.62354.40942L2.0083,7l6.347-4.922a0.38659,0.38659,0,0,1,.62354.40942L7.63507,5.43927a0.35757,0.35757,0,0,0,.37738.54242H10"/>
</svg>`,

  continue_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M9.98877,7.98169A4.05782,4.05782,0,0,0,6,12.05493v5.44885A0.49622,0.49622,0,0,1,5.50378,18H4.49731a0.49614,0.49614,0,0,1-.49615-0.49683L4.00311,16H4V12.05493A6.06019,6.06019,0,0,1,9.989,5.98169L10,5.97986V5.98169h1.98755a0.35757,0.35757,0,0,0,.37738-0.54242L11.02118,2.48743A0.38659,0.38659,0,0,1,11.64471,2.078L17.9917,7l-6.347,4.88544a0.38659,0.38659,0,0,1-.62354-0.40942l1.34375-2.95184a0.3576,0.3576,0,0,0-.37738-0.54248H9.98877Z"/>
</svg>`,

  continue_slight_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M14.9859,14.043v3.46082A0.49621,0.49621,0,0,1,14.48974,18H13.48321a0.49614,0.49614,0,0,1-.49615-0.49683l0.0047-3.60767a5.21819,5.21819,0,0,0-1.665-4.144L8.87854,7.68585a0.35758,0.35758,0,0,0-.6405.16266l-0.91821,3.1106A0.38663,0.38663,0,0,1,6.58044,10.86L5,3l8.00476,0.44965a0.38658,0.38658,0,0,1,.20294.71777L10.25878,5.51758a0.3576,0.3576,0,0,0-.07019.6571l2.45746,2.07385A7.25158,7.25158,0,0,1,14.9859,14.043Z"/>
</svg>`,

  continue_slight_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M7.35395,8.24854L9.81141,6.17468a0.3576,0.3576,0,0,0-.07019-0.6571L6.7923,4.16742a0.38658,0.38658,0,0,1,.20294-0.71777L15,3l-1.58044,7.86a0.38663,0.38663,0,0,1-.73938.09912L11.762,7.84851a0.35758,0.35758,0,0,0-.6405-0.16266L8.67328,9.75146a5.21819,5.21819,0,0,0-1.665,4.144l0.0047,3.60767A0.49614,0.49614,0,0,1,6.51679,18H5.51026a0.49621,0.49621,0,0,1-.49615-0.49622V14.043A7.25157,7.25157,0,0,1,7.35395,8.24854Z"/>
</svg>`,

  continue_straight: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M14.50342,8.96637L11.55157,7.62262A0.35755,0.35755,0,0,0,11.00916,8v9.49652A0.50346,0.50346,0,0,1,10.50568,18h-0.993a0.50346,0.50346,0,0,1-.50348-0.50348V8a0.35756,0.35756,0,0,0-.54242-0.37738L5.51489,8.96637a0.38659,0.38659,0,0,1-.40942-0.62354L10.00916,2l4.90369,6.34283A0.3866,0.3866,0,0,1,14.50342,8.96637Z"/>
</svg>`,

  continue_uturn: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M17.00909,8v9.49652A0.50346,0.50346,0,0,1,16.50562,18h-0.993a0.50346,0.50346,0,0,1-.50348-0.50348V8a3.5,3.5,0,1,0-7,0v4H8a0.35757,0.35757,0,0,0,.54242.37738l2.95184-1.34375a0.3866,0.3866,0,0,1,.40942.62354L7,18,2.09625,11.65717a0.3866,0.3866,0,0,1,.40942-0.62354l2.95184,1.34375A0.3576,0.3576,0,0,0,6,12H6.00909V8A5.5,5.5,0,1,1,17.00909,8Z"/>
</svg>`,

  depart: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M9.98172,19a2,2,0,1,1,2-2A2,2,0,0,1,9.98172,19ZM14.89456,7.34283L9.99087,1,5.08719,7.34283a0.38659,0.38659,0,0,0,.40942.62354L8.44845,6.62262a0.35542,0.35542,0,0,1,.541.36908H8.98172v5.50006a0.49992,0.49992,0,0,0,.49994.49994h1.00012a0.49992,0.49992,0,0,0,.49994-0.49994V7h0.00916a0.35757,0.35757,0,0,1,.54242-0.37738l2.95184,1.34375A0.3866,0.3866,0,0,0,14.89456,7.34283Z"/>
</svg>`,

  depart_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M15,19a2,2,0,1,1,2-2A2,2,0,0,1,15,19ZM10.00293,8.03668a4.05782,4.05782,0,0,1,3.98877,4.07324H14v1.37775A0.51232,0.51232,0,0,0,14.51233,14h0.97534A0.51232,0.51232,0,0,0,16,13.48767V12H15.98615a6.05607,6.05607,0,0,0-5.9834-5.96332l-0.011-.00183L9.99182,6.05493H9V6.04584a0.35757,0.35757,0,0,1-.37738-0.54242L9.96637,2.55157a0.38659,0.38659,0,0,0-.62354-0.40942L3,7.04584l6.34283,4.90369a0.3866,0.3866,0,0,0,.62354-0.40942L8.62262,8.58826A0.35757,0.35757,0,0,1,9,8.04584V8.05493h1"/>
</svg>`,

  depart_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M3,17a2,2,0,1,1,2,2A2,2,0,0,1,3,17Zm7-8.94507h1V8.04584a0.35757,0.35757,0,0,1,.37738.54242L10.03363,11.5401a0.3866,0.3866,0,0,0,.62354.40942L17,7.04584,10.65717,2.14215a0.38659,0.38659,0,0,0-.62354.40942l1.34375,2.95184A0.35757,0.35757,0,0,1,11,6.04584V6.05493H10.00818l0.00012-.02008-0.011.00183A6.05607,6.05607,0,0,0,4.01385,12H4v1.48767A0.51232,0.51232,0,0,0,4.51233,14H5.48767A0.51232,0.51232,0,0,0,6,13.48767V12.10992H6.0083A4.05782,4.05782,0,0,1,9.99707,8.03668"/>
</svg>`,

  depart_straight: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M10,19a2,2,0,1,1,2-2A2,2,0,0,1,10,19ZM14.91284,7.34283L10.00916,1,5.10547,7.34283a0.38659,0.38659,0,0,0,.40942.62354L8.46674,6.62262a0.35542,0.35542,0,0,1,.541.36908H9v5.50006a0.49992,0.49992,0,0,0,.49994.49994h1.00012A0.49992,0.49992,0,0,0,11,12.49176V7h0.00916a0.35757,0.35757,0,0,1,.54242-0.37738l2.95184,1.34375A0.3866,0.3866,0,0,0,14.91284,7.34283Z"/>
</svg>`,

  end_of_road_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M16.50354,6.2A0.29678,0.29678,0,0,1,16.8,6.49646V7.50354A0.29678,0.29678,0,0,1,16.50354,7.8H7.2V6.2h9.30353m0-.2H7V8h9.50354A0.49645,0.49645,0,0,0,17,7.50354V6.49646A0.49645,0.49645,0,0,0,16.50354,6h0ZM10,5.98169H8.01245a0.35757,0.35757,0,0,1-.37738-0.54242L8.97882,2.48743A0.38659,0.38659,0,0,0,8.35529,2.078L2.0083,7l6.347,4.88544A0.38659,0.38659,0,0,0,8.97882,11.476L7.63507,8.52417a0.3576,0.3576,0,0,1,.37738-0.54248h1.99878A4.05782,4.05782,0,0,1,14,12.05493v5.44885A0.49622,0.49622,0,0,0,14.49622,18h1.00647a0.49614,0.49614,0,0,0,.49615-0.49683L15.99689,16H16V12.05493a6.06019,6.06019,0,0,0-5.989-6.07324L10,5.97986"/>
</svg>`,

  end_of_road_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M12.8,6.2V7.8H2.50119A0.30152,0.30152,0,0,1,2.2,7.49882V6.50118A0.30152,0.30152,0,0,1,2.50119,6.2H12.8M13,6H2.50119A0.50119,0.50119,0,0,0,2,6.50118V7.49882A0.50119,0.50119,0,0,0,2.50119,8H13V6h0ZM10,7.98169h1.98755a0.3576,0.3576,0,0,1,.37738.54248L11.02118,11.476a0.38659,0.38659,0,0,0,.62354.40942L17.9917,7l-6.347-4.922a0.38659,0.38659,0,0,0-.62354.40942l1.34375,2.95184a0.35757,0.35757,0,0,1-.37738.54242H10V5.97986l-0.011.00183A6.06019,6.06019,0,0,0,4,12.05493V16H4.00311l-0.002,1.50317A0.49614,0.49614,0,0,0,4.49731,18H5.50378A0.49622,0.49622,0,0,0,6,17.50378V12.05493A4.05782,4.05782,0,0,1,9.98877,7.98169H10Z"/>
</svg>`,

  flag: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M3.5,18h0A0.5,0.5,0,0,1,3,17.5V3.5A0.5,0.5,0,0,1,3.5,3h0a0.5,0.5,0,0,1,.5.5v14A0.5,0.5,0,0,1,3.5,18ZM13.33333,7L17,3H6v8H17Z"/>
</svg>`,

  fork: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M19,3l-1.58044,7.86a0.38663,0.38663,0,0,1-.73938.09912L15.762,7.84851a0.35758,0.35758,0,0,0-.6405-0.16266L12.67328,9.75146a5.21819,5.21819,0,0,0-1.665,4.144l0.0047,3.60767A0.49614,0.49614,0,0,1,10.51678,18H9.48322a0.49614,0.49614,0,0,1-.49615-0.49683l0.0047-3.60767a5.21819,5.21819,0,0,0-1.665-4.144L4.87854,7.68585a0.35758,0.35758,0,0,0-.6405.16266l-0.91821,3.1106A0.38663,0.38663,0,0,1,2.58044,10.86L1,3,9.00476,3.44965a0.38658,0.38658,0,0,1,.20294.71777L6.25879,5.51758a0.3576,0.3576,0,0,0-.07019.6571L8.64606,8.24854A6.64064,6.64064,0,0,1,10,9.89148a6.64064,6.64064,0,0,1,1.35394-1.64294L13.8114,6.17468a0.3576,0.3576,0,0,0-.07019-0.6571L10.7923,4.16742a0.38658,0.38658,0,0,1,.20294-0.71777Z"/>
</svg>`,

  fork_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M17.73611,6.57682a0.30465,0.30465,0,0,1,.23032.10507l0.649,0.74859a0.30457,0.30457,0,0,1-.03369.43226L16.5213,9.60119a5.4089,5.4089,0,0,0-1.73306,4.29459l0.0047,3.60769A0.29612,0.29612,0,0,1,14.4968,17.8H13.49033a0.29657,0.29657,0,0,1-.29623-0.29622l-0.00058-3.47624A6.99129,6.99129,0,0,1,15.463,8.40138l2.077-1.75275a0.30475,0.30475,0,0,1,.1962-0.07182m0-.2a0.50269,0.50269,0,0,0-.32516.119L15.334,8.24854A7.25157,7.25157,0,0,0,12.99411,14.043v3.46082A0.49622,0.49622,0,0,0,13.49033,18H14.4968a0.49614,0.49614,0,0,0,.49615-0.49681l-0.0047-3.60767a5.2182,5.2182,0,0,1,1.665-4.14405L18.71066,8.0156a0.50455,0.50455,0,0,0,.05585-0.71613l-0.649-.74859a0.50345,0.50345,0,0,0-.38147-0.17406h0ZM9.00078,5.98169H7.01323a0.35757,0.35757,0,0,1-.37738-0.54242L7.9796,2.48743A0.38659,0.38659,0,0,0,7.35606,2.078L1.00908,7l6.347,4.88544A0.38659,0.38659,0,0,0,7.9796,11.476L6.63585,8.52417a0.3576,0.3576,0,0,1,.37738-0.54248H9.012a4.05782,4.05782,0,0,1,3.98877,4.07324v5.44885A0.49622,0.49622,0,0,0,13.497,18h1.00647a0.49614,0.49614,0,0,0,.49615-0.49683L14.99766,16h0.00311V12.05493a6.06019,6.06019,0,0,0-5.989-6.07324l-0.011-.00183"/>
</svg>`,

  fork_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M2.27386,6.57682A0.30457,0.30457,0,0,1,2.47,6.64864L4.544,8.39882A7.00218,7.00218,0,0,1,6.81586,14.043v3.46088A0.29651,0.29651,0,0,1,6.51968,17.8H5.51318A0.29613,0.29613,0,0,1,5.217,17.50343L5.22079,13.9146a5.42,5.42,0,0,0-1.73517-4.316L1.42825,7.86274a0.30461,0.30461,0,0,1-.03372-0.43226l0.649-.74859a0.30466,0.30466,0,0,1,.23035-0.10507m0-.2a0.50345,0.50345,0,0,0-.38147.17406l-0.649.74859a0.50458,0.50458,0,0,0,.05588.71613L3.35666,9.75146a5.2182,5.2182,0,0,1,1.665,4.14405L5.017,17.50317A0.49617,0.49617,0,0,0,5.51318,18h1.0065a0.49616,0.49616,0,0,0,.49615-0.49615V14.043A7.25157,7.25157,0,0,0,4.676,8.24854L2.599,6.49579a0.50264,0.50264,0,0,0-.32516-0.119h0ZM11.0083,7.98169h1.98755a0.3576,0.3576,0,0,1,.37738.54248L12.02948,11.476a0.38659,0.38659,0,0,0,.62354.40942L19,7,12.653,2.078a0.38659,0.38659,0,0,0-.62354.40942l1.34375,2.95184a0.35757,0.35757,0,0,1-.37738.54242H11.0083V5.97986l-0.011.00183a6.06019,6.06019,0,0,0-5.989,6.07324V16H5.01141l-0.002,1.50317A0.49614,0.49614,0,0,0,5.50562,18H6.51208a0.49622,0.49622,0,0,0,.49622-0.49622V12.05493a4.05782,4.05782,0,0,1,3.98877-4.07324H11.0083Z"/>
</svg>`,

  fork_slight_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M15.75607,6.57682a0.30471,0.30471,0,0,1,.23035.10507l0.649,0.74859a0.30457,0.30457,0,0,1-.03369.43226L14.54129,9.60119a5.4089,5.4089,0,0,0-1.73306,4.29459l0.0047,3.60769a0.29613,0.29613,0,0,1-.29614.29654H11.51031a0.29657,0.29657,0,0,1-.29623-0.29622L11.2135,14.02754a6.99129,6.99129,0,0,1,2.26944-5.62616l2.077-1.75275a0.30461,0.30461,0,0,1,.19617-0.07182m0-.2a0.50263,0.50263,0,0,0-.32516.119l-2.077,1.75275A7.25157,7.25157,0,0,0,11.0141,14.043v3.46082A0.49622,0.49622,0,0,0,11.51031,18h1.00647a0.49616,0.49616,0,0,0,.49615-0.49681l-0.0047-3.60767a5.2182,5.2182,0,0,1,1.665-4.14405L16.73065,8.0156a0.50458,0.50458,0,0,0,.05585-0.71613l-0.649-.74859a0.50345,0.50345,0,0,0-.38147-0.17406h0Zm-5.11,1.87171L8.18859,6.17468a0.3576,0.3576,0,0,1,.07019-0.6571L11.2077,4.16742a0.38658,0.38658,0,0,0-.20294-0.71777L3,3l1.58044,7.86a0.38663,0.38663,0,0,0,.73938.09912L6.238,7.84851a0.35758,0.35758,0,0,1,.6405-0.16266L9.32672,9.75146a5.21819,5.21819,0,0,1,1.665,4.144l-0.0047,3.60767A0.49614,0.49614,0,0,0,11.48321,18h1.00653a0.49621,0.49621,0,0,0,.49615-0.49622V14.043A7.25158,7.25158,0,0,0,10.64605,8.24854Z"/>
</svg>`,

  fork_slight_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M4.26865,6.57682a0.30457,0.30457,0,0,1,.19617.07182l2.074,1.75018A7.00218,7.00218,0,0,1,8.81064,14.043v3.46088A0.29651,0.29651,0,0,1,8.51447,17.8H7.50793a0.29612,0.29612,0,0,1-.29614-0.29655L7.21558,13.9146a5.42,5.42,0,0,0-1.73517-4.316L3.423,7.86274a0.30454,0.30454,0,0,1-.03372-0.43226l0.649-.74859a0.30466,0.30466,0,0,1,.23035-0.10507m0-.2a0.50345,0.50345,0,0,0-.38147.17406l-0.649.74859a0.50455,0.50455,0,0,0,.05588.71613L5.35144,9.75146a5.2182,5.2182,0,0,1,1.665,4.14405l-0.0047,3.60767A0.49614,0.49614,0,0,0,7.50793,18H8.51447a0.49616,0.49616,0,0,0,.49615-0.49615V14.043A7.25157,7.25157,0,0,0,6.67078,8.24854l-2.077-1.75275a0.50264,0.50264,0,0,0-.32516-0.119h0ZM7.0141,14.043v3.46082A0.49621,0.49621,0,0,0,7.51025,18H8.51678a0.49614,0.49614,0,0,0,.49615-0.49683l-0.0047-3.60767a5.21819,5.21819,0,0,1,1.665-4.144l2.44818-2.06561a0.35758,0.35758,0,0,1,.6405.16266l0.91821,3.1106A0.38663,0.38663,0,0,0,15.41956,10.86L17,3l-8.00476.44965a0.38658,0.38658,0,0,0-.20294.71777l2.94891,1.35016a0.3576,0.3576,0,0,1,.07019.6571L9.35394,8.24854A7.25157,7.25157,0,0,0,7.0141,14.043Z"/>
</svg>`,

  fork_straight: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M17.75607,6.59766a0.30466,0.30466,0,0,1,.23035.10507l0.649,0.74859a0.30457,0.30457,0,0,1-.03369.43226L16.54129,9.622a5.4089,5.4089,0,0,0-1.73306,4.29459l0.0047,3.60769a0.29613,0.29613,0,0,1-.29614.29654H13.51031a0.29657,0.29657,0,0,1-.29623-0.29622L13.2135,14.04838a6.99129,6.99129,0,0,1,2.26944-5.62616l2.077-1.75275a0.30461,0.30461,0,0,1,.19617-0.07182m0-.2a0.50264,0.50264,0,0,0-.32516.119l-2.077,1.75275A7.25157,7.25157,0,0,0,13.0141,14.0638v3.46082a0.49622,0.49622,0,0,0,.49622.49622h1.00647a0.49616,0.49616,0,0,0,.49615-0.49681l-0.0047-3.60767a5.2182,5.2182,0,0,1,1.665-4.14405l2.05737-1.73586A0.50455,0.50455,0,0,0,18.7865,7.3203l-0.649-.74859a0.50345,0.50345,0,0,0-.38147-0.17406h0ZM2.24393,6.57682a0.30457,0.30457,0,0,1,.19617.07182l2.074,1.75018A7.00218,7.00218,0,0,1,6.78592,14.043v3.46088A0.29651,0.29651,0,0,1,6.48975,17.8H5.48322a0.29613,0.29613,0,0,1-.29614-0.29655L5.19086,13.9146a5.42,5.42,0,0,0-1.73517-4.316L1.39832,7.86274a0.30457,0.30457,0,0,1-.03369-0.43226l0.649-.74859a0.30466,0.30466,0,0,1,.23035-0.10507m0-.2a0.50345,0.50345,0,0,0-.38147.17406l-0.649.74859a0.50455,0.50455,0,0,0,.05585.71613L3.32672,9.75146a5.2182,5.2182,0,0,1,1.665,4.14405l-0.0047,3.60767A0.49616,0.49616,0,0,0,5.48322,18H6.48975a0.49616,0.49616,0,0,0,.49615-0.49615V14.043A7.25157,7.25157,0,0,0,4.64606,8.24854l-2.077-1.75275a0.50264,0.50264,0,0,0-.32516-0.119h0Zm12.66891,1.966L10.00916,2,5.10547,8.34283a0.38659,0.38659,0,0,0,.40942.62354L8.46674,7.62262A0.35756,0.35756,0,0,1,9.00916,8v9.49652A0.50346,0.50346,0,0,0,9.51263,18h0.993a0.50346,0.50346,0,0,0,.50348-0.50348V8a0.35755,0.35755,0,0,1,.54242-0.37738l2.95184,1.34375A0.3866,0.3866,0,0,0,14.91284,8.34283Z"/>
</svg>`,

  invalid: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M14.50342,8.96637L11.55157,7.62262A0.35755,0.35755,0,0,0,11.00916,8v9.49652A0.50346,0.50346,0,0,1,10.50568,18h-0.993a0.50346,0.50346,0,0,1-.50348-0.50348V8a0.35756,0.35756,0,0,0-.54242-0.37738L5.51489,8.96637a0.38659,0.38659,0,0,1-.40942-0.62354L10.00916,2l4.90369,6.34283A0.3866,0.3866,0,0,1,14.50342,8.96637Z"/>
</svg>`,

  invalid_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M10,5.97986l0.011,0.00183A6.06019,6.06019,0,0,1,16,12.05493V16H15.99689l0.002,1.50317A0.49614,0.49614,0,0,1,15.50269,18H14.49622A0.49622,0.49622,0,0,1,14,17.50378V12.05493a4.05782,4.05782,0,0,0-3.98877-4.07324H8.01245a0.3576,0.3576,0,0,0-.37738.54248L8.97882,11.476a0.38659,0.38659,0,0,1-.62354.40942L2.0083,7l6.347-4.922a0.38659,0.38659,0,0,1,.62354.40942L7.63507,5.43927a0.35757,0.35757,0,0,0,.37738.54242H10"/>
</svg>`,

  invalid_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M9.98877,7.98169A4.05782,4.05782,0,0,0,6,12.05493v5.44885A0.49622,0.49622,0,0,1,5.50378,18H4.49731a0.49614,0.49614,0,0,1-.49615-0.49683L4.00311,16H4V12.05493A6.06019,6.06019,0,0,1,9.989,5.98169L10,5.97986V5.98169h1.98755a0.35757,0.35757,0,0,0,.37738-0.54242L11.02118,2.48743A0.38659,0.38659,0,0,1,11.64471,2.078L17.9917,7l-6.347,4.88544a0.38659,0.38659,0,0,1-.62354-0.40942l1.34375-2.95184a0.3576,0.3576,0,0,0-.37738-0.54248H9.98877Z"/>
</svg>`,

  invalid_slight_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M14.9859,14.043v3.46082A0.49621,0.49621,0,0,1,14.48974,18H13.48321a0.49614,0.49614,0,0,1-.49615-0.49683l0.0047-3.60767a5.21819,5.21819,0,0,0-1.665-4.144L8.87854,7.68585a0.35758,0.35758,0,0,0-.6405.16266l-0.91821,3.1106A0.38663,0.38663,0,0,1,6.58044,10.86L5,3l8.00476,0.44965a0.38658,0.38658,0,0,1,.20294.71777L10.25878,5.51758a0.3576,0.3576,0,0,0-.07019.6571l2.45746,2.07385A7.25158,7.25158,0,0,1,14.9859,14.043Z"/>
</svg>`,

  invalid_slight_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M7.35395,8.24854L9.81141,6.17468a0.3576,0.3576,0,0,0-.07019-0.6571L6.7923,4.16742a0.38658,0.38658,0,0,1,.20294-0.71777L15,3l-1.58044,7.86a0.38663,0.38663,0,0,1-.73938.09912L11.762,7.84851a0.35758,0.35758,0,0,0-.6405-0.16266L8.67328,9.75146a5.21819,5.21819,0,0,0-1.665,4.144l0.0047,3.60767A0.49614,0.49614,0,0,1,6.51679,18H5.51026a0.49621,0.49621,0,0,1-.49615-0.49622V14.043A7.25157,7.25157,0,0,1,7.35395,8.24854Z"/>
</svg>`,

  invalid_straight: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M14.50342,8.96637L11.55157,7.62262A0.35755,0.35755,0,0,0,11.00916,8v9.49652A0.50346,0.50346,0,0,1,10.50568,18h-0.993a0.50346,0.50346,0,0,1-.50348-0.50348V8a0.35756,0.35756,0,0,0-.54242-0.37738L5.51489,8.96637a0.38659,0.38659,0,0,1-.40942-0.62354L10.00916,2l4.90369,6.34283A0.3866,0.3866,0,0,1,14.50342,8.96637Z"/>
</svg>`,

  invalid_uturn: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M17.00909,8v9.49652A0.50346,0.50346,0,0,1,16.50562,18h-0.993a0.50346,0.50346,0,0,1-.50348-0.50348V8a3.5,3.5,0,1,0-7,0v4H8a0.35757,0.35757,0,0,0,.54242.37738l2.95184-1.34375a0.3866,0.3866,0,0,1,.40942.62354L7,18,2.09625,11.65717a0.3866,0.3866,0,0,1,.40942-0.62354l2.95184,1.34375A0.3576,0.3576,0,0,0,6,12H6.00909V8A5.5,5.5,0,1,1,17.00909,8Z"/>
</svg>`,

  merge_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M7.8,7.2V17.50252A0.2978,0.2978,0,0,1,7.50253,17.8H6.49747A0.2978,0.2978,0,0,1,6.2,17.50252V7.2H7.8M8,7H6V17.50252A0.49747,0.49747,0,0,0,6.49747,18H7.50253A0.49747,0.49747,0,0,0,8,17.50252V7H8ZM7.98169,10V8.01245a0.3576,0.3576,0,0,1,.54248-0.37738L11.476,8.97882a0.38659,0.38659,0,0,0,.40942-0.62354L7,2.0083l-4.922,6.347a0.38659,0.38659,0,0,0,.40942.62354L5.43927,7.63507a0.35757,0.35757,0,0,1,.54242.37738V10H5.97986l0.00183,0.011A6.06019,6.06019,0,0,0,12.05493,16H16V15.99689l1.50317,0.002A0.49614,0.49614,0,0,0,18,15.50269V14.49622A0.49622,0.49622,0,0,0,17.50378,14H12.05493a4.05782,4.05782,0,0,1-4.07324-3.98877V10Z"/>
</svg>`,

  merge_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M13.8,7.2V17.49921a0.30112,0.30112,0,0,1-.30078.3008H12.50079A0.30112,0.30112,0,0,1,12.2,17.49921V7.2h1.6M14,7H12V17.49921A0.50079,0.50079,0,0,0,12.50079,18h0.99841A0.50079,0.50079,0,0,0,14,17.49921V7h0Zm0.01831,3V8.01245a0.35757,0.35757,0,0,1,.54242-0.37738l2.95184,1.34375A0.38659,0.38659,0,0,0,17.922,8.35529L13,2.0083l-4.88544,6.347a0.38659,0.38659,0,0,0,.40942.62354l2.95184-1.34375a0.3576,0.3576,0,0,1,.54248.37738v1.99878A4.05782,4.05782,0,0,1,7.94507,14H2.49622A0.49622,0.49622,0,0,0,2,14.49622v1.00647a0.49614,0.49614,0,0,0,.49683.49615L4,15.99689V16H7.94507a6.06019,6.06019,0,0,0,6.07324-5.989L14.02014,10"/>
</svg>`,

  merge_slight_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M9.82565,9.2v8.29515A0.3052,0.3052,0,0,1,9.52081,17.8H8.53052a0.3052,0.3052,0,0,1-.30484-0.30486V9.2h1.6m0.2-.2h-2v8.49515A0.50484,0.50484,0,0,0,8.53052,18h0.9903a0.50486,0.50486,0,0,0,.50485-0.50485V9h0ZM4.517,7.95947L7.46885,6.61572a0.35757,0.35757,0,0,1,.54242.37738h0.001V8.9585a6.60115,6.60115,0,0,0,3.38232,5.72021L15.70547,17.364a0.50277,0.50277,0,0,0,.69263-0.16089l0.52576-.84369a0.50285,0.50285,0,0,0-.16089-0.69275l-4.30485-2.6818a4.65641,4.65641,0,0,1-2.44824-3.957l0.00165-2.03607a0.35723,0.35723,0,0,1,.54224-0.376l2.95184,1.34375A0.38659,0.38659,0,0,0,13.915,7.33594L9.01127,0.9931,4.10758,7.33594A0.38659,0.38659,0,0,0,4.517,7.95947Z"/>
</svg>`,

  merge_slight_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M11.79221,9.2v8.29515a0.3052,0.3052,0,0,1-.30484.30486h-0.9903a0.3052,0.3052,0,0,1-.30484-0.30486V9.2h1.6m0.2-.2h-2v8.49515A0.50484,0.50484,0,0,0,10.49707,18h0.9903a0.50486,0.50486,0,0,0,.50485-0.50485V9h0Zm3.91806-1.66406L11.00659,0.9931,6.10284,7.33594a0.38659,0.38659,0,0,0,.40942.62354L9.46411,6.61572a0.35723,0.35723,0,0,1,.54224.376L10.008,9.02783a4.65641,4.65641,0,0,1-2.44824,3.957L3.2549,15.66667a0.50285,0.50285,0,0,0-.16089.69275L3.61977,17.2031a0.50277,0.50277,0,0,0,.69263.16089l4.31083-2.68528A6.60115,6.60115,0,0,0,12.00555,8.9585V6.9931h0.001A0.35757,0.35757,0,0,1,12.549,6.61572l2.95184,1.34375A0.38659,0.38659,0,0,0,15.91028,7.33594Z"/>
</svg>`,

  merge_straight: `<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
</svg>`,

  new_name_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M10,5.97986l0.011,0.00183A6.06019,6.06019,0,0,1,16,12.05493V16H15.99689l0.002,1.50317A0.49614,0.49614,0,0,1,15.50269,18H14.49622A0.49622,0.49622,0,0,1,14,17.50378V12.05493a4.05782,4.05782,0,0,0-3.98877-4.07324H8.01245a0.3576,0.3576,0,0,0-.37738.54248L8.97882,11.476a0.38659,0.38659,0,0,1-.62354.40942L2.0083,7l6.347-4.922a0.38659,0.38659,0,0,1,.62354.40942L7.63507,5.43927a0.35757,0.35757,0,0,0,.37738.54242H10"/>
</svg>`,

  new_name_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M9.98877,7.98169A4.05782,4.05782,0,0,0,6,12.05493v5.44885A0.49622,0.49622,0,0,1,5.50378,18H4.49731a0.49614,0.49614,0,0,1-.49615-0.49683L4.00311,16H4V12.05493A6.06019,6.06019,0,0,1,9.989,5.98169L10,5.97986V5.98169h1.98755a0.35757,0.35757,0,0,0,.37738-0.54242L11.02118,2.48743A0.38659,0.38659,0,0,1,11.64471,2.078L17.9917,7l-6.347,4.88544a0.38659,0.38659,0,0,1-.62354-0.40942l1.34375-2.95184a0.3576,0.3576,0,0,0-.37738-0.54248H9.98877Z"/>
</svg>`,

  new_name_sharp_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M15.49771,18a0.49779,0.49779,0,0,1-.49779-0.49779V5.00391l-0.27979-.00635a2.56758,2.56758,0,0,0-2.0957.79L7.22917,10.40041a0.34918,0.34918,0,0,0,.08252.63177l2.92877,1.39331a0.38658,0.38658,0,0,1-.21344.71472l-8.0105.33209,1.69568-7.836a0.38661,0.38661,0,0,1,.74072-0.0882l0.8725,3.12372a0.35757,0.35757,0,0,0,.638.17206L5.9672,8.84835,11.3593,4.23926A4.46634,4.46634,0,0,1,14.74406,2.998L15.00822,3a1.92935,1.92935,0,0,1,1.43408.56885,2.10247,2.10247,0,0,1,.55713,1.46045L16.9999,17.50219A0.49779,0.49779,0,0,1,16.50211,18h-1.0044Z"/>
</svg>`,

  new_name_sharp_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M3.49789,18a0.49779,0.49779,0,0,1-.49779-0.49781L3.00057,5.0293A2.10247,2.10247,0,0,1,3.5577,3.56885,1.92935,1.92935,0,0,1,4.99178,3l0.26416-.002A4.46634,4.46634,0,0,1,8.6407,4.23926L14.0328,8.84835l0.00378-.00446a0.35757,0.35757,0,0,0,.638-0.17206l0.8725-3.12372a0.38661,0.38661,0,0,1,.74072.0882l1.69568,7.836L9.973,13.14022a0.38658,0.38658,0,0,1-.21344-0.71472l2.92877-1.39331a0.34918,0.34918,0,0,0,.08252-0.63177L7.37557,5.7876a2.56758,2.56758,0,0,0-2.0957-.79l-0.27979.00635v12.4983A0.49779,0.49779,0,0,1,4.50229,18H3.49789Z"/>
</svg>`,

  new_name_slight_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M14.9859,14.043v3.46082A0.49621,0.49621,0,0,1,14.48974,18H13.48321a0.49614,0.49614,0,0,1-.49615-0.49683l0.0047-3.60767a5.21819,5.21819,0,0,0-1.665-4.144L8.87854,7.68585a0.35758,0.35758,0,0,0-.6405.16266l-0.91821,3.1106A0.38663,0.38663,0,0,1,6.58044,10.86L5,3l8.00476,0.44965a0.38658,0.38658,0,0,1,.20294.71777L10.25878,5.51758a0.3576,0.3576,0,0,0-.07019.6571l2.45746,2.07385A7.25158,7.25158,0,0,1,14.9859,14.043Z"/>
</svg>`,

  new_name_slight_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M7.35395,8.24854L9.81141,6.17468a0.3576,0.3576,0,0,0-.07019-0.6571L6.7923,4.16742a0.38658,0.38658,0,0,1,.20294-0.71777L15,3l-1.58044,7.86a0.38663,0.38663,0,0,1-.73938.09912L11.762,7.84851a0.35758,0.35758,0,0,0-.6405-0.16266L8.67328,9.75146a5.21819,5.21819,0,0,0-1.665,4.144l0.0047,3.60767A0.49614,0.49614,0,0,1,6.51679,18H5.51026a0.49621,0.49621,0,0,1-.49615-0.49622V14.043A7.25157,7.25157,0,0,1,7.35395,8.24854Z"/>
</svg>`,

  new_name_straight: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M14.50342,8.96637L11.55157,7.62262A0.35755,0.35755,0,0,0,11.00916,8v9.49652A0.50346,0.50346,0,0,1,10.50568,18h-0.993a0.50346,0.50346,0,0,1-.50348-0.50348V8a0.35756,0.35756,0,0,0-.54242-0.37738L5.51489,8.96637a0.38659,0.38659,0,0,1-.40942-0.62354L10.00916,2l4.90369,6.34283A0.3866,0.3866,0,0,1,14.50342,8.96637Z"/>
</svg>`,

  notificaiton_sharp_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M3.49789,17.99542a0.49779,0.49779,0,0,1-.49779-0.49781L3.00057,5.02472A2.10247,2.10247,0,0,1,3.5577,3.56427a1.92935,1.92935,0,0,1,1.43408-.56885l0.26416-.002A4.46634,4.46634,0,0,1,8.6407,4.23468L14.0328,8.84377l0.00378-.00446a0.35757,0.35757,0,0,0,.638-0.17206l0.8725-3.12372a0.38661,0.38661,0,0,1,.74072.0882l1.69568,7.836L9.973,13.13564a0.38658,0.38658,0,0,1-.21344-0.71472l2.92877-1.39331a0.34918,0.34918,0,0,0,.08252-0.63177L7.37557,5.783a2.56758,2.56758,0,0,0-2.0957-.79l-0.27979.00635v12.4983a0.49779,0.49779,0,0,1-.49779.49779H3.49789Z"/>
</svg>`,

  notification_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M10,5.97986l0.011,0.00183A6.06019,6.06019,0,0,1,16,12.05493V16H15.99689l0.002,1.50317A0.49614,0.49614,0,0,1,15.50269,18H14.49622A0.49622,0.49622,0,0,1,14,17.50378V12.05493a4.05782,4.05782,0,0,0-3.98877-4.07324H8.01245a0.3576,0.3576,0,0,0-.37738.54248L8.97882,11.476a0.38659,0.38659,0,0,1-.62354.40942L2.0083,7l6.347-4.922a0.38659,0.38659,0,0,1,.62354.40942L7.63507,5.43927a0.35757,0.35757,0,0,0,.37738.54242H10"/>
</svg>`,

  notification_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M9.98877,7.98169A4.05782,4.05782,0,0,0,6,12.05493v5.44885A0.49622,0.49622,0,0,1,5.50378,18H4.49731a0.49614,0.49614,0,0,1-.49615-0.49683L4.00311,16H4V12.05493A6.06019,6.06019,0,0,1,9.989,5.98169L10,5.97986V5.98169h1.98755a0.35757,0.35757,0,0,0,.37738-0.54242L11.02118,2.48743A0.38659,0.38659,0,0,1,11.64471,2.078L17.9917,7l-6.347,4.88544a0.38659,0.38659,0,0,1-.62354-0.40942l1.34375-2.95184a0.3576,0.3576,0,0,0-.37738-0.54248H9.98877Z"/>
</svg>`,

  notification_sharp_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M15.49771,17.99542a0.49779,0.49779,0,0,1-.49779-0.49779V4.99933L14.72014,4.993a2.56758,2.56758,0,0,0-2.0957.79L7.22917,10.39583a0.34918,0.34918,0,0,0,.08252.63177l2.92877,1.39331a0.38658,0.38658,0,0,1-.21344.71472l-8.0105.33209,1.69568-7.836a0.38661,0.38661,0,0,1,.74072-0.0882l0.8725,3.12372a0.35757,0.35757,0,0,0,.638.17206L5.9672,8.84377,11.3593,4.23468a4.46634,4.46634,0,0,1,3.38477-1.24121l0.26416,0.002a1.92935,1.92935,0,0,1,1.43408.56885,2.10247,2.10247,0,0,1,.55713,1.46045L16.9999,17.49761a0.49779,0.49779,0,0,1-.49779.49781h-1.0044Z"/>
</svg>`,

  notification_slight_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M14.9859,14.043v3.46082A0.49621,0.49621,0,0,1,14.48974,18H13.48321a0.49614,0.49614,0,0,1-.49615-0.49683l0.0047-3.60767a5.21819,5.21819,0,0,0-1.665-4.144L8.87854,7.68585a0.35758,0.35758,0,0,0-.6405.16266l-0.91821,3.1106A0.38663,0.38663,0,0,1,6.58044,10.86L5,3l8.00476,0.44965a0.38658,0.38658,0,0,1,.20294.71777L10.25878,5.51758a0.3576,0.3576,0,0,0-.07019.6571l2.45746,2.07385A7.25158,7.25158,0,0,1,14.9859,14.043Z"/>
</svg>`,

  notification_slight_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M7.35395,8.24854L9.81141,6.17468a0.3576,0.3576,0,0,0-.07019-0.6571L6.7923,4.16742a0.38658,0.38658,0,0,1,.20294-0.71777L15,3l-1.58044,7.86a0.38663,0.38663,0,0,1-.73938.09912L11.762,7.84851a0.35758,0.35758,0,0,0-.6405-0.16266L8.67328,9.75146a5.21819,5.21819,0,0,0-1.665,4.144l0.0047,3.60767A0.49614,0.49614,0,0,1,6.51679,18H5.51026a0.49621,0.49621,0,0,1-.49615-0.49622V14.043A7.25157,7.25157,0,0,1,7.35395,8.24854Z"/>
</svg>`,

  notification_straight: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M14.50342,8.96637L11.55157,7.62262A0.35755,0.35755,0,0,0,11.00916,8v9.49652A0.50346,0.50346,0,0,1,10.50568,18h-0.993a0.50346,0.50346,0,0,1-.50348-0.50348V8a0.35756,0.35756,0,0,0-.54242-0.37738L5.51489,8.96637a0.38659,0.38659,0,0,1-.40942-0.62354L10.00916,2l4.90369,6.34283A0.3866,0.3866,0,0,1,14.50342,8.96637Z"/>
</svg>`,

  off_ramp_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M14.5029,2.2A0.29744,0.29744,0,0,1,14.8,2.49712V17.50288A0.29744,0.29744,0,0,1,14.5029,17.8H13.4971A0.29744,0.29744,0,0,1,13.2,17.50288V2.49712A0.29744,0.29744,0,0,1,13.4971,2.2h1.0058m0-.2H13.4971A0.49711,0.49711,0,0,0,13,2.49712V17.50288A0.49711,0.49711,0,0,0,13.4971,18h1.0058A0.49711,0.49711,0,0,0,15,17.50288V2.49712A0.49711,0.49711,0,0,0,14.5029,2h0ZM9.00078,5.98169H7.01323a0.35757,0.35757,0,0,1-.37738-0.54242L7.9796,2.48743A0.38659,0.38659,0,0,0,7.35606,2.078L1.00908,7l6.347,4.88544A0.38659,0.38659,0,0,0,7.9796,11.476L6.63585,8.52417a0.3576,0.3576,0,0,1,.37738-0.54248H9.012a4.05782,4.05782,0,0,1,3.98877,4.07324v5.44885A0.49622,0.49622,0,0,0,13.497,18h1.00647a0.49614,0.49614,0,0,0,.49615-0.49683L14.99766,16h0.00311V12.05493a6.06019,6.06019,0,0,0-5.989-6.07324l-0.011-.00183"/>
</svg>`,

  off_ramp_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M6.5029,2.2A0.29744,0.29744,0,0,1,6.8,2.49712V17.50288A0.29744,0.29744,0,0,1,6.5029,17.8H5.4971A0.29744,0.29744,0,0,1,5.2,17.50288V2.49712A0.29744,0.29744,0,0,1,5.4971,2.2H6.5029m0-.2H5.4971A0.49711,0.49711,0,0,0,5,2.49712V17.50288A0.49711,0.49711,0,0,0,5.4971,18H6.5029A0.49711,0.49711,0,0,0,7,17.50288V2.49712A0.49711,0.49711,0,0,0,6.5029,2h0Zm4.5054,5.98169h1.98755a0.3576,0.3576,0,0,1,.37738.54248L12.02948,11.476a0.38659,0.38659,0,0,0,.62354.40942L19,7,12.653,2.078a0.38659,0.38659,0,0,0-.62354.40942l1.34375,2.95184a0.35757,0.35757,0,0,1-.37738.54242H11.0083V5.97986l-0.011.00183a6.06019,6.06019,0,0,0-5.989,6.07324V16H5.01141l-0.002,1.50317A0.49614,0.49614,0,0,0,5.50562,18H6.51208a0.49622,0.49622,0,0,0,.49622-0.49622V12.05493a4.05782,4.05782,0,0,1,3.98877-4.07324H11.0083Z"/>
</svg>`,

  off_ramp_slight_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M14.5029,2.2A0.29744,0.29744,0,0,1,14.8,2.49712V17.50288A0.29744,0.29744,0,0,1,14.5029,17.8H13.4971A0.29744,0.29744,0,0,1,13.2,17.50288V2.49712A0.29744,0.29744,0,0,1,13.4971,2.2h1.0058m0-.2H13.4971A0.49711,0.49711,0,0,0,13,2.49712V17.50288A0.49711,0.49711,0,0,0,13.4971,18h1.0058A0.49711,0.49711,0,0,0,15,17.50288V2.49712A0.49711,0.49711,0,0,0,14.5029,2h0ZM12.64606,8.24854L10.1886,6.17468a0.3576,0.3576,0,0,1,.07019-0.6571L13.2077,4.16742a0.38658,0.38658,0,0,0-.20294-0.71777L5,3l1.58044,7.86a0.38663,0.38663,0,0,0,.73938.09912L8.238,7.84851a0.35758,0.35758,0,0,1,.6405-0.16266l2.44818,2.06561a5.21819,5.21819,0,0,1,1.665,4.144l-0.0047,3.60767A0.49614,0.49614,0,0,0,13.48322,18h1.00653a0.49621,0.49621,0,0,0,.49615-0.49622V14.043A7.25157,7.25157,0,0,0,12.64606,8.24854Z"/>
</svg>`,

  off_ramp_slight_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M8.5029,2.2A0.29744,0.29744,0,0,1,8.8,2.49712V17.50288A0.29744,0.29744,0,0,1,8.5029,17.8H7.4971A0.29744,0.29744,0,0,1,7.2,17.50288V2.49712A0.29744,0.29744,0,0,1,7.4971,2.2H8.5029m0-.2H7.4971A0.49711,0.49711,0,0,0,7,2.49712V17.50288A0.49711,0.49711,0,0,0,7.4971,18H8.5029A0.49711,0.49711,0,0,0,9,17.50288V2.49712A0.49711,0.49711,0,0,0,8.5029,2h0ZM7.0141,14.043v3.46082A0.49621,0.49621,0,0,0,7.51025,18H8.51678a0.49614,0.49614,0,0,0,.49615-0.49683l-0.0047-3.60767a5.21819,5.21819,0,0,1,1.665-4.144l2.44818-2.06561a0.35758,0.35758,0,0,1,.6405.16266l0.91821,3.1106A0.38663,0.38663,0,0,0,15.41956,10.86L17,3l-8.00476.44965a0.38658,0.38658,0,0,0-.20294.71777l2.94891,1.35016a0.3576,0.3576,0,0,1,.07019.6571L9.35394,8.24854A7.25157,7.25157,0,0,0,7.0141,14.043Z"/>
</svg>`,

  on_ramp_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M9.00078,5.97986l0.011,0.00183a6.06019,6.06019,0,0,1,5.989,6.07324V16H14.99766l0.002,1.50317A0.49614,0.49614,0,0,1,14.50346,18H13.497a0.49622,0.49622,0,0,1-.49622-0.49622V12.05493A4.05782,4.05782,0,0,0,9.012,7.98169H7.01323a0.3576,0.3576,0,0,0-.37738.54248L7.9796,11.476a0.38659,0.38659,0,0,1-.62354.40942L1.00908,7l6.347-4.922a0.38659,0.38659,0,0,1,.62354.40942L6.63585,5.43927a0.35757,0.35757,0,0,0,.37738.54242H9.00078"/>
</svg>`,

  on_ramp_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M10.99707,7.98169A4.05782,4.05782,0,0,0,7.0083,12.05493v5.44885A0.49622,0.49622,0,0,1,6.51208,18H5.50562a0.49614,0.49614,0,0,1-.49615-0.49683L5.01141,16H5.0083V12.05493a6.06019,6.06019,0,0,1,5.989-6.07324l0.011-.00183V5.98169h1.98755a0.35757,0.35757,0,0,0,.37738-0.54242L12.02948,2.48743A0.38659,0.38659,0,0,1,12.653,2.078L19,7l-6.347,4.88544a0.38659,0.38659,0,0,1-.62354-0.40942l1.34375-2.95184a0.3576,0.3576,0,0,0-.37738-0.54248H10.99707Z"/>
</svg>`,

  on_ramp_sharp_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M15.49771,18a0.49779,0.49779,0,0,1-.49779-0.49779V5.00391l-0.27979-.00635a2.56758,2.56758,0,0,0-2.0957.79L7.22917,10.40041a0.34918,0.34918,0,0,0,.08252.63177l2.92877,1.39331a0.38658,0.38658,0,0,1-.21344.71472l-8.0105.33209,1.69568-7.836a0.38661,0.38661,0,0,1,.74072-0.0882l0.8725,3.12372a0.35757,0.35757,0,0,0,.638.17206L5.9672,8.84835,11.3593,4.23926A4.46634,4.46634,0,0,1,14.74406,2.998L15.00822,3a1.92935,1.92935,0,0,1,1.43408.56885,2.10247,2.10247,0,0,1,.55713,1.46045L16.9999,17.50219A0.49779,0.49779,0,0,1,16.50211,18h-1.0044Z"/>
</svg>`,

  on_ramp_sharp_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M3.49789,17.99542a0.49779,0.49779,0,0,1-.49779-0.49781L3.00057,5.02472A2.10247,2.10247,0,0,1,3.5577,3.56427a1.92935,1.92935,0,0,1,1.43408-.56885l0.26416-.002A4.46634,4.46634,0,0,1,8.6407,4.23468L14.0328,8.84377l0.00378-.00446a0.35757,0.35757,0,0,0,.638-0.17206l0.8725-3.12372a0.38661,0.38661,0,0,1,.74072.0882l1.69568,7.836L9.973,13.13564a0.38658,0.38658,0,0,1-.21344-0.71472l2.92877-1.39331a0.34918,0.34918,0,0,0,.08252-0.63177L7.37557,5.783a2.56758,2.56758,0,0,0-2.0957-.79l-0.27979.00635v12.4983a0.49779,0.49779,0,0,1-.49779.49779H3.49789Z"/>
</svg>`,

  on_ramp_slight_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M12.9859,14.043v3.46082A0.49621,0.49621,0,0,1,12.48975,18H11.48322a0.49614,0.49614,0,0,1-.49615-0.49683l0.0047-3.60767a5.21819,5.21819,0,0,0-1.665-4.144L6.87854,7.68585a0.35758,0.35758,0,0,0-.6405.16266l-0.91821,3.1106A0.38663,0.38663,0,0,1,4.58044,10.86L3,3l8.00476,0.44965a0.38658,0.38658,0,0,1,.20294.71777L8.25879,5.51758a0.3576,0.3576,0,0,0-.07019.6571l2.45746,2.07385A7.25157,7.25157,0,0,1,12.9859,14.043Z"/>
</svg>`,

  on_ramp_slight_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M9.35394,8.24854L11.8114,6.17468a0.3576,0.3576,0,0,0-.07019-0.6571L8.7923,4.16742a0.38658,0.38658,0,0,1,.20294-0.71777L17,3l-1.58044,7.86a0.38663,0.38663,0,0,1-.73938.09912L13.762,7.84851a0.35758,0.35758,0,0,0-.6405-0.16266L10.67328,9.75146a5.21819,5.21819,0,0,0-1.665,4.144l0.0047,3.60767A0.49614,0.49614,0,0,1,8.51678,18H7.51025a0.49621,0.49621,0,0,1-.49615-0.49622V14.043A7.25157,7.25157,0,0,1,9.35394,8.24854Z"/>
</svg>`,

  on_ramp_straight: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M14.50342,8.96637L11.55157,7.62262A0.35755,0.35755,0,0,0,11.00916,8v9.49652A0.50346,0.50346,0,0,1,10.50568,18h-0.993a0.50346,0.50346,0,0,1-.50348-0.50348V8a0.35756,0.35756,0,0,0-.54242-0.37738L5.51489,8.96637a0.38659,0.38659,0,0,1-.40942-0.62354L10.00916,2l4.90369,6.34283A0.3866,0.3866,0,0,1,14.50342,8.96637Z"/>
</svg>`,

  rotary: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M5.5,10.002a0.17879,0.17879,0,0,0,.27124.18866l1.47589-.67187a0.1933,0.1933,0,0,1,.20471.31177L5,13.002,2.54816,9.83051a0.1933,0.1933,0,0,1,.20471-0.31177l1.476,0.67187A0.17876,0.17876,0,0,0,4.5,10.002V10A5.51888,5.51888,0,0,1,7.25293,5.23438l0.5,0.86523A4.51856,4.51856,0,0,0,5.5,10v0.002Zm6.75146-3.89941A4.51948,4.51948,0,0,1,14.5,10h1a5.5223,5.5223,0,0,0-2.74756-4.7627L12.751,5.23724A0.17878,0.17878,0,0,1,12.72321,4.908L14.043,3.96576a0.19332,0.19332,0,0,0-.16766-0.33319l-3.97247.53766,1.52063,3.70911a0.19331,0.19331,0,0,0,.37238-0.02142L11.952,6.24377A0.17945,0.17945,0,0,1,12.25146,6.10254ZM12.74688,14.766a0.17879,0.17879,0,0,1,.299.14053l0.1561,1.61412a0.1933,0.1933,0,0,0,.37235.02141L15.095,12.833l-3.97245-.53766a0.1933,0.1933,0,0,0-.16764.33317l1.31982,0.94225a0.17879,0.17879,0,0,1-.02781.32923l0.00361-.00254a4.57684,4.57684,0,0,1-4.502,0l-0.501.86523a5.50442,5.50442,0,0,0,5.50391,0Z" />
</svg>`,

  rotary_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M13,6.2A3.8,3.8,0,1,1,9.2,10,3.80428,3.80428,0,0,1,13,6.2m0,7A3.2,3.2,0,1,0,9.8,10,3.20362,3.20362,0,0,0,13,13.2M13,6a4,4,0,1,0,4,4,4.00458,4.00458,0,0,0-4-4h0Zm0,7a3,3,0,1,1,3-3,3.00328,3.00328,0,0,1-3,3h0Zm2.82861-5.82812a4.0315,4.0315,0,0,0-.542-0.45459,4.078,4.078,0,0,0-5.11475.4541A3.96842,3.96842,0,0,0,9.03076,9.5H5a0.17879,0.17879,0,0,1-.18866-0.27124L5.48322,7.75287a0.1933,0.1933,0,0,0-.31177-0.20471L2,10l3.17145,2.45184a0.1933,0.1933,0,0,0,.31177-0.20471L4.81134,10.77124A0.17879,0.17879,0,0,1,5,10.5h5V10a2.98063,2.98063,0,0,1,.87891-2.12158,3.06088,3.06088,0,0,1,3.83545-.34082,3.02248,3.02248,0,0,1,.40723.34131A2.99976,2.99976,0,0,1,13,13H12.5v4h1V13.96924A4.00038,4.00038,0,0,0,15.82861,7.17188Z"/>
</svg>`,

  rotary_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M7,6.2A3.8,3.8,0,1,1,3.2,10,3.80428,3.80428,0,0,1,7,6.2m0,7A3.2,3.2,0,1,0,3.8,10,3.20362,3.20362,0,0,0,7,13.2M7,6a4,4,0,1,0,4,4A4.00458,4.00458,0,0,0,7,6H7Zm0,7a3,3,0,1,1,3-3,3.00328,3.00328,0,0,1-3,3H7Zm7.83777-5.45184a0.1933,0.1933,0,0,0-.31177.20471l0.67188,1.47589A0.17881,0.17881,0,0,1,15.00916,9.5H10V10a2.99994,2.99994,0,0,1-3,3H6.5v4h1V13.96924A4.0032,4.0032,0,0,0,10.96924,10.5h4.03992a0.17881,0.17881,0,0,1,.18872.27124L14.526,12.24713a0.1933,0.1933,0,0,0,.31177.20471L18.00916,10Z"/>
</svg>`,

  rotary_sharp_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M10.994,5.2A3.8,3.8,0,1,1,7.194,9a3.80428,3.80428,0,0,1,3.8-3.8m0,7A3.2,3.2,0,1,0,7.794,9a3.20362,3.20362,0,0,0,3.2,3.2m0-7.2a4,4,0,1,0,4,4,4.00458,4.00458,0,0,0-4-4h0Zm0,7a3,3,0,1,1,3-3,3.00328,3.00328,0,0,1-3,3h0Zm2.8291-5.82812a4.03583,4.03583,0,0,0-.54248-0.45459,4.07906,4.07906,0,0,0-5.11475.4541,4.00058,4.00058,0,0,0-.33252,5.28076L4.76758,14.51792a0.17873,0.17873,0,0,1-.325-0.05847L3.874,12.94071a0.19332,0.19332,0,0,0-.36523.07568L3,16.99265l3.97626-.50879a0.19332,0.19332,0,0,0,.07568-0.36523L5.5332,15.55008a0.17879,0.17879,0,0,1-.05835-0.3252l-0.00037-.00037,3.40378-3.40567,0.33838-.353-0.34521-.34619A2.9995,2.9995,0,0,1,8.8729,6.87842a3.06063,3.06063,0,0,1,3.83545-.34082,3.02673,3.02673,0,0,1,.40771.34131A3,3,0,0,1,10.994,12h-0.5v4h1V12.96924A4.00076,4.00076,0,0,0,13.82309,6.17188Z"/>
</svg>`,

  rotary_sharp_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M8.97217,5.2a3.8,3.8,0,1,1-3.8,3.8,3.8043,3.8043,0,0,1,3.8-3.8m0,7a3.2,3.2,0,1,0-3.2-3.2,3.20361,3.20361,0,0,0,3.2,3.2m0-7.2a4,4,0,1,0,4,4,4.00458,4.00458,0,0,0-4-4h0Zm0,7a3,3,0,1,1,3-3,3.00328,3.00328,0,0,1-3,3h0Zm7.491,1.02374a0.19328,0.19328,0,0,0-.36517-0.07568L15.52948,14.4668a0.17883,0.17883,0,0,1-.32526.05835l-0.00018.00018-3.75726-3.75726-0.35352.35352A2.98022,2.98022,0,0,1,8.97217,12h-0.5v4h1V12.96924a3.95621,3.95621,0,0,0,1.95264-.80859l3.07312,3.07275a0.17841,0.17841,0,0,1-.05914.324L12.9201,16.126a0.1933,0.1933,0,0,0,.07568.36523L16.972,17Z"/>
</svg>`,

  rotary_slight_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M10.97473,7.2a3.8,3.8,0,1,1-3.8,3.8,3.80428,3.80428,0,0,1,3.8-3.8m0,7a3.2,3.2,0,1,0-3.2-3.2,3.20362,3.20362,0,0,0,3.2,3.2m0-7.2a4,4,0,1,0,4,4,4.00458,4.00458,0,0,0-4-4h0Zm0,7a3,3,0,1,1,3-3,3.00328,3.00328,0,0,1-3,3h0Zm2.82861-5.82812a4.0315,4.0315,0,0,0-.542-0.45459,4.07671,4.07671,0,0,0-4.73975.12256l-3.04816-3.045A0.17774,0.17774,0,0,1,5.53412,4.473L7.0528,3.90442a0.1933,0.1933,0,0,0-.07568-0.36523L3.00085,3.0304,3.5097,7.00665a0.19328,0.19328,0,0,0,.36517.07568L4.44342,5.5636a0.17879,0.17879,0,0,1,.3252-0.05835L4.7688,5.50507,8.50061,9.23193l0.353-.35352a3.05971,3.05971,0,0,1,3.83545-.34082,3.02248,3.02248,0,0,1,.40723.34131A2.99976,2.99976,0,0,1,10.97473,14h-0.5v4h1V14.96924A4.00038,4.00038,0,0,0,13.80334,8.17188Z"/>
</svg>`,

  rotary_slight_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M8.97128,7.2a3.8,3.8,0,1,1-3.8,3.8,3.80428,3.80428,0,0,1,3.8-3.8m0,7a3.2,3.2,0,1,0-3.2-3.2,3.20362,3.20362,0,0,0,3.2,3.2m0-7.2a4,4,0,1,0,4,4,4.00458,4.00458,0,0,0-4-4h0Zm0,7a3,3,0,1,1,3-3,3.00328,3.00328,0,0,1-3,3h0ZM13.05247,3.45127a0.19328,0.19328,0,0,0-.07568.36517l1.51874,0.5686a0.17878,0.17878,0,0,1,.05835.3252l0.00006,0.00006L10.73885,8.52539l0.35352,0.35352A2.99976,2.99976,0,0,1,8.97127,14h-0.5v3.97917h1V14.96924a4.00123,4.00123,0,0,0,2.66016-6.42236L15.261,5.41734a0.17878,0.17878,0,0,1,.3252.05835L16.1547,6.99443a0.19332,0.19332,0,0,0,.36523-0.07574l0.50879-3.9762Z"/>
</svg>`,

  rotary_straight: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M10,8.2A3.8,3.8,0,1,1,6.2,12,3.80428,3.80428,0,0,1,10,8.2m0,7A3.2,3.2,0,1,0,6.8,12,3.20362,3.20362,0,0,0,10,15.2M10,8a4,4,0,1,0,4,4,4.00458,4.00458,0,0,0-4-4h0Zm0,7a3,3,0,1,1,3-3,3.00328,3.00328,0,0,1-3,3h0Zm2.83057-5.82666A3.97135,3.97135,0,0,0,10.5,8.03076V5.00415a0.17881,0.17881,0,0,1,.27124-0.18872L12.24713,5.4873a0.19329,0.19329,0,0,0,.20471-0.31177L10,2.00415,7.54816,5.17554a0.1933,0.1933,0,0,0,.20471.31177l1.476-.67187A0.17879,0.17879,0,0,1,9.5,5.00415V9H10a3,3,0,1,1,0,6H9.5v3h1V15.96924A4.00076,4.00076,0,0,0,12.83057,9.17334Z"/>
</svg>`,

  roundabout: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M5.5,10.002a0.17879,0.17879,0,0,0,.27124.18866l1.47589-.67188a0.1933,0.1933,0,0,1,.20471.31177L5,13.002,2.54816,9.83051a0.1933,0.1933,0,0,1,.20471-0.31177l1.476,0.67188A0.17876,0.17876,0,0,0,4.5,10.002V10A5.51888,5.51888,0,0,1,7.25293,5.23437l0.5,0.86523A4.51856,4.51856,0,0,0,5.5,10v0.002Zm6.75146-3.89941A4.51948,4.51948,0,0,1,14.5,10h1a5.5223,5.5223,0,0,0-2.74756-4.7627L12.751,5.23724A0.17878,0.17878,0,0,1,12.72321,4.908L14.043,3.96576a0.19332,0.19332,0,0,0-.16766-0.33319l-3.97247.53766,1.52063,3.70911a0.19331,0.19331,0,0,0,.37238-0.02142L11.952,6.24377A0.17945,0.17945,0,0,1,12.25146,6.10254ZM12.74688,14.766a0.17879,0.17879,0,0,1,.299.14053l0.1561,1.61412a0.1933,0.1933,0,0,0,.37235.02141L15.095,12.833l-3.97245-.53766a0.1933,0.1933,0,0,0-.16764.33317l1.31982,0.94225a0.17879,0.17879,0,0,1-.02781.32923l0.00361-.00254a4.57684,4.57684,0,0,1-4.502,0l-0.501.86523a5.50442,5.50442,0,0,0,5.50391,0Z" />
</svg>`,

  roundabout_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M13,6.2A3.8,3.8,0,1,1,9.2,10,3.80428,3.80428,0,0,1,13,6.2m0,7A3.2,3.2,0,1,0,9.8,10,3.20363,3.20363,0,0,0,13,13.2M13,6a4,4,0,1,0,4,4,4.00458,4.00458,0,0,0-4-4h0Zm0,7a3,3,0,1,1,3-3,3.00328,3.00328,0,0,1-3,3h0Zm2.82861-5.82813a4.0315,4.0315,0,0,0-.542-0.45459,4.078,4.078,0,0,0-5.11475.4541A3.96842,3.96842,0,0,0,9.03076,9.5H5a0.17879,0.17879,0,0,1-.18866-0.27124L5.48322,7.75287a0.1933,0.1933,0,0,0-.31177-0.20471L2,10l3.17145,2.45184a0.1933,0.1933,0,0,0,.31177-0.20471L4.81134,10.77124A0.17879,0.17879,0,0,1,5,10.5h5V10a2.98063,2.98063,0,0,1,.87891-2.12158,3.06088,3.06088,0,0,1,3.83545-.34082,3.02248,3.02248,0,0,1,.40723.34131A2.99976,2.99976,0,0,1,13,13H12.5v4h1V13.96924A4.00038,4.00038,0,0,0,15.82861,7.17187Z"/>
</svg>`,

  roundabout_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M7,6.2A3.8,3.8,0,1,1,3.2,10,3.80428,3.80428,0,0,1,7,6.2m0,7A3.2,3.2,0,1,0,3.8,10,3.20363,3.20363,0,0,0,7,13.2M7,6a4,4,0,1,0,4,4A4.00458,4.00458,0,0,0,7,6H7Zm0,7a3,3,0,1,1,3-3,3.00328,3.00328,0,0,1-3,3H7Zm7.83777-5.45184a0.1933,0.1933,0,0,0-.31177.20471l0.67188,1.47589A0.17881,0.17881,0,0,1,15.00916,9.5H10V10a2.99994,2.99994,0,0,1-3,3H6.5v4h1V13.96924A4.0032,4.0032,0,0,0,10.96924,10.5h4.03992a0.17881,0.17881,0,0,1,.18872.27124L14.526,12.24713a0.1933,0.1933,0,0,0,.31177.20471L18.00916,10Z"/>
</svg>`,

  roundabout_sharp_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M10.994,5.2A3.8,3.8,0,1,1,7.194,9a3.80428,3.80428,0,0,1,3.8-3.8m0,7A3.2,3.2,0,1,0,7.794,9a3.20363,3.20363,0,0,0,3.2,3.2m0-7.2a4,4,0,1,0,4,4,4.00458,4.00458,0,0,0-4-4h0Zm0,7a3,3,0,1,1,3-3,3.00328,3.00328,0,0,1-3,3h0Zm2.8291-5.82813a4.03583,4.03583,0,0,0-.54248-0.45459,4.07906,4.07906,0,0,0-5.11475.4541,4.00058,4.00058,0,0,0-.33252,5.28076L4.76758,14.51792a0.17873,0.17873,0,0,1-.325-0.05847L3.874,12.94071a0.19332,0.19332,0,0,0-.36523.07568L3,16.99265l3.97626-.50879a0.19332,0.19332,0,0,0,.07568-0.36523L5.5332,15.55008a0.17879,0.17879,0,0,1-.05835-0.3252l-0.00037-.00037,3.40378-3.40567,0.33838-.353-0.34521-.34619A2.9995,2.9995,0,0,1,8.8729,6.87842a3.06063,3.06063,0,0,1,3.83545-.34082,3.02673,3.02673,0,0,1,.40771.34131A3,3,0,0,1,10.994,12h-0.5v4h1V12.96924A4.00076,4.00076,0,0,0,13.82309,6.17187Z"/>
</svg>`,

  roundabout_sharp_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M8.97217,5.2a3.8,3.8,0,1,1-3.8,3.8,3.8043,3.8043,0,0,1,3.8-3.8m0,7a3.2,3.2,0,1,0-3.2-3.2,3.20362,3.20362,0,0,0,3.2,3.2m0-7.2a4,4,0,1,0,4,4,4.00458,4.00458,0,0,0-4-4h0Zm0,7a3,3,0,1,1,3-3,3.00328,3.00328,0,0,1-3,3h0Zm7.491,1.02374a0.19328,0.19328,0,0,0-.36517-0.07568L15.52948,14.4668a0.17883,0.17883,0,0,1-.32526.05835l-0.00018.00018-3.75726-3.75726-0.35352.35352A2.98022,2.98022,0,0,1,8.97217,12h-0.5v4h1V12.96924a3.95621,3.95621,0,0,0,1.95264-.80859l3.07312,3.07275a0.17841,0.17841,0,0,1-.05914.324L12.9201,16.126a0.1933,0.1933,0,0,0,.07568.36523L16.972,17Z"/>
</svg>`,

  roundabout_slight_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M10.97473,7.2a3.8,3.8,0,1,1-3.8,3.8,3.80428,3.80428,0,0,1,3.8-3.8m0,7a3.2,3.2,0,1,0-3.2-3.2,3.20363,3.20363,0,0,0,3.2,3.2m0-7.2a4,4,0,1,0,4,4,4.00458,4.00458,0,0,0-4-4h0Zm0,7a3,3,0,1,1,3-3,3.00328,3.00328,0,0,1-3,3h0Zm-5.499-9.20184A0.17879,0.17879,0,0,1,5.53408,4.473L7.0528,3.90441a0.1933,0.1933,0,0,0-.0757-0.36521L3.00086,3.03039,3.50967,7.00664a0.1933,0.1933,0,0,0,.36521.0757L4.44342,5.56362a0.17879,0.17879,0,0,1,.32521-0.05835m6.7061,9.464a4.00038,4.00038,0,0,0,2.32861-6.79736,4.0315,4.0315,0,0,0-.542-0.45459,4.07671,4.07671,0,0,0-4.73975.12256l-3.75294-3.749-0.707.708,4.439,4.43311,0.353-.35352a3.05971,3.05971,0,0,1,3.83545-.34082,3.02248,3.02248,0,0,1,.40723.34131A2.99976,2.99976,0,0,1,10.97472,14h-0.5v4h1V14.96924Z"/>
</svg>`,

  roundabout_slight_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M8.97128,7.2a3.8,3.8,0,1,1-3.8,3.8,3.80428,3.80428,0,0,1,3.8-3.8m0,7a3.2,3.2,0,1,0-3.2-3.2,3.20363,3.20363,0,0,0,3.2,3.2m0-7.2a4,4,0,1,0,4,4,4.00458,4.00458,0,0,0-4-4h0Zm0,7a3,3,0,1,1,3-3,3.00328,3.00328,0,0,1-3,3h0ZM13.05247,3.45127a0.19328,0.19328,0,0,0-.07568.36517l1.51874,0.5686a0.17878,0.17878,0,0,1,.05835.3252l0.00006,0.00006L10.73885,8.52539l0.35352,0.35352A2.99976,2.99976,0,0,1,8.97127,14h-0.5v3.97917h1V14.96924a4.00123,4.00123,0,0,0,2.66016-6.42236L15.261,5.41734a0.17878,0.17878,0,0,1,.3252.05835L16.1547,6.99443a0.19332,0.19332,0,0,0,.36523-0.07574l0.50879-3.9762Z"/>
</svg>`,

  roundabout_straight: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M10,8.2A3.8,3.8,0,1,1,6.2,12,3.80427,3.80427,0,0,1,10,8.2m0,7A3.2,3.2,0,1,0,6.8,12,3.20363,3.20363,0,0,0,10,15.2M10,8a4,4,0,1,0,4,4,4.00458,4.00458,0,0,0-4-4h0Zm0,7a3,3,0,1,1,3-3,3.00328,3.00328,0,0,1-3,3h0Zm2.83057-5.82666A3.97135,3.97135,0,0,0,10.5,8.03076V5.00415a0.17881,0.17881,0,0,1,.27124-0.18872L12.24713,5.4873a0.19329,0.19329,0,0,0,.20471-0.31177L10,2.00415,7.54816,5.17554a0.1933,0.1933,0,0,0,.20471.31177l1.476-.67187A0.17879,0.17879,0,0,1,9.5,5.00415V9H10a3,3,0,1,1,0,6H9.5v3h1V15.96924A4.00076,4.00076,0,0,0,12.83057,9.17334Z"/>
</svg>`,

  turn_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M10,5.97986l0.011,0.00183A6.06019,6.06019,0,0,1,16,12.05493V16H15.99689l0.002,1.50317A0.49614,0.49614,0,0,1,15.50269,18H14.49622A0.49622,0.49622,0,0,1,14,17.50378V12.05493a4.05782,4.05782,0,0,0-3.98877-4.07324H8.01245a0.3576,0.3576,0,0,0-.37738.54248L8.97882,11.476a0.38659,0.38659,0,0,1-.62354.40942L2.0083,7l6.347-4.922a0.38659,0.38659,0,0,1,.62354.40942L7.63507,5.43927a0.35757,0.35757,0,0,0,.37738.54242H10"/>
</svg>`,

  turn_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M9.98877,7.98169A4.05782,4.05782,0,0,0,6,12.05493v5.44885A0.49622,0.49622,0,0,1,5.50378,18H4.49731a0.49614,0.49614,0,0,1-.49615-0.49683L4.00311,16H4V12.05493A6.06019,6.06019,0,0,1,9.989,5.98169L10,5.97986V5.98169h1.98755a0.35757,0.35757,0,0,0,.37738-0.54242L11.02118,2.48743A0.38659,0.38659,0,0,1,11.64471,2.078L17.9917,7l-6.347,4.88544a0.38659,0.38659,0,0,1-.62354-0.40942l1.34375-2.95184a0.3576,0.3576,0,0,0-.37738-0.54248H9.98877Z"/>
</svg>`,

  turn_sharp_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M15.49771,17.99542a0.49779,0.49779,0,0,1-.49779-0.49779V4.99933L14.72014,4.993a2.56758,2.56758,0,0,0-2.0957.79L7.22917,10.39583a0.34918,0.34918,0,0,0,.08252.63177l2.92877,1.39331a0.38658,0.38658,0,0,1-.21344.71472l-8.0105.33209,1.69568-7.836a0.38661,0.38661,0,0,1,.74072-0.0882l0.8725,3.12372a0.35757,0.35757,0,0,0,.638.17206L5.9672,8.84377,11.3593,4.23468a4.46634,4.46634,0,0,1,3.38477-1.24121l0.26416,0.002a1.92935,1.92935,0,0,1,1.43408.56885,2.10247,2.10247,0,0,1,.55713,1.46045L16.9999,17.49761a0.49779,0.49779,0,0,1-.49779.49781h-1.0044Z"/>
</svg>`,

  turn_sharp_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M3.49789,17.99542a0.49779,0.49779,0,0,1-.49779-0.49781L3.00057,5.02472A2.10247,2.10247,0,0,1,3.5577,3.56427a1.92935,1.92935,0,0,1,1.43408-.56885l0.26416-.002A4.46634,4.46634,0,0,1,8.6407,4.23468L14.0328,8.84377l0.00378-.00446a0.35757,0.35757,0,0,0,.638-0.17206l0.8725-3.12372a0.38661,0.38661,0,0,1,.74072.0882l1.69568,7.836L9.973,13.13564a0.38658,0.38658,0,0,1-.21344-0.71472l2.92877-1.39331a0.34918,0.34918,0,0,0,.08252-0.63177L7.37557,5.783a2.56758,2.56758,0,0,0-2.0957-.79l-0.27979.00635v12.4983a0.49779,0.49779,0,0,1-.49779.49779H3.49789Z"/>
</svg>`,

  turn_slight_left: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M14.9859,14.043v3.46082A0.49621,0.49621,0,0,1,14.48974,18H13.48321a0.49614,0.49614,0,0,1-.49615-0.49683l0.0047-3.60767a5.21819,5.21819,0,0,0-1.665-4.144L8.87854,7.68585a0.35758,0.35758,0,0,0-.6405.16266l-0.91821,3.1106A0.38663,0.38663,0,0,1,6.58044,10.86L5,3l8.00476,0.44965a0.38658,0.38658,0,0,1,.20294.71777L10.25878,5.51758a0.3576,0.3576,0,0,0-.07019.6571l2.45746,2.07385A7.25158,7.25158,0,0,1,14.9859,14.043Z"/>
</svg>`,

  turn_slight_right: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M7.35395,8.24854L9.81141,6.17468a0.3576,0.3576,0,0,0-.07019-0.6571L6.7923,4.16742a0.38658,0.38658,0,0,1,.20294-0.71777L15,3l-1.58044,7.86a0.38663,0.38663,0,0,1-.73938.09912L11.762,7.84851a0.35758,0.35758,0,0,0-.6405-0.16266L8.67328,9.75146a5.21819,5.21819,0,0,0-1.665,4.144l0.0047,3.60767A0.49614,0.49614,0,0,1,6.51679,18H5.51026a0.49621,0.49621,0,0,1-.49615-0.49622V14.043A7.25157,7.25157,0,0,1,7.35395,8.24854Z"/>
</svg>`,

  turn_straight: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M14.50342,8.96637L11.55157,7.62262A0.35755,0.35755,0,0,0,11.00916,8v9.49652A0.50346,0.50346,0,0,1,10.50568,18h-0.993a0.50346,0.50346,0,0,1-.50348-0.50348V8a0.35756,0.35756,0,0,0-.54242-0.37738L5.51489,8.96637a0.38659,0.38659,0,0,1-.40942-0.62354L10.00916,2l4.90369,6.34283A0.3866,0.3866,0,0,1,14.50342,8.96637Z"/>
</svg>`,

  updown: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M14.91284,12.65717L10.00916,19,5.10547,12.65717a0.38659,0.38659,0,0,1,.40942-0.62354l2.95184,1.34375A0.35757,0.35757,0,0,0,9.00916,13V7.0083a0.35757,0.35757,0,0,0-.54242-0.37738L5.51489,7.97467a0.38659,0.38659,0,0,1-.40942-0.62354L10.00916,1.0083l4.90369,6.34283a0.3866,0.3866,0,0,1-.40942.62354L11.55157,6.63092a0.35757,0.35757,0,0,0-.54242.37738V13a0.35757,0.35757,0,0,0,.54242.37738l2.95184-1.34375A0.3866,0.3866,0,0,1,14.91284,12.65717Z"/>
</svg>`,

  uturn: `<svg id="WORKING_ICONS" data-name="WORKING ICONS" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <title>direction</title>
  <path fill="#000000" d="M17,8v9.49652A0.50346,0.50346,0,0,1,16.49652,18h-0.993A0.50346,0.50346,0,0,1,15,17.49652V8A3.5,3.5,0,0,0,8,8v4H7.99091a0.35757,0.35757,0,0,0,.54242.37738l2.95184-1.34375a0.3866,0.3866,0,0,1,.40942.62354L6.99091,18,2.08716,11.65717a0.3866,0.3866,0,0,1,.40942-0.62354l2.95184,1.34375A0.3576,0.3576,0,0,0,5.99091,12H6V8A5.5,5.5,0,0,1,17,8Z"/>
</svg>`,
};
