import { StatusIndicator } from '@abyss/web/ui/StatusIndicator';

import {
  TerminationDateIndicator,
  TerminationDateIndicatorIcon,
  TerminationDateIndicatorText,
} from './TerminationStatusIndicator.styled';

type Props = {
  message: string;
};

export const TerminationStatusIndicator = ({ message = '' }: Props) => (
  <StatusIndicator
    css={TerminationDateIndicator}
    data-testid="termination-date-container"
    variant="warning"
  >
    <StatusIndicator.Icon css={TerminationDateIndicatorIcon} title="warning" />
    <StatusIndicator.Text css={TerminationDateIndicatorText}>
      {message}
    </StatusIndicator.Text>
  </StatusIndicator>
);
