import { Popover } from '@abyss/web/ui/Popover';
import isEmpty from 'lodash/isEmpty';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GenderAttribute,
  OverflowTooltipCapableDiv,
  popoverStyle,
} from '../../../../utility/compareDrawer.styled';
import { NULL_ATTRIBUTE_STATE } from '../../../../utility/compareDrawerConstants';

type Props = {
  gender: string;
  mobile: boolean;
};

export const GenderText = ({ gender, mobile }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const isAttributeEmpty = isEmpty(gender);
  const providerGender: string = !isAttributeEmpty
    ? gender.charAt(0).toUpperCase().concat(gender.slice(1))
    : NULL_ATTRIBUTE_STATE.GENDER;

  return (
    <React.Fragment>
      {mobile && isAttributeEmpty ? (
        <Popover
          content={providerGender}
          css={popoverStyle}
          showClose={false}
          tabIndex="-1"
          width={150}
        >
          <GenderAttribute
            cssProps={{ isAttributeEmpty }}
            data-auto-testid="provider-gender-text"
            data-testid="provider-gender-text"
          >
            {t(providerGender)}
          </GenderAttribute>
        </Popover>
      ) : (
        <OverflowTooltipCapableDiv
          cssProps={{ isAttributeEmpty }}
          data-auto-testid="provider-gender-text"
          data-testid="provider-gender-text"
          ref={containerRef}
        >
          {t(providerGender)}
        </OverflowTooltipCapableDiv>
      )}
    </React.Fragment>
  );
};
