import { Button } from '@abyss/web/ui/Button';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import { t } from 'i18next';

type Props = {
  openCompare: boolean;
  setOpenMapview: (a: boolean) => void;
  isDisabled: boolean;
};

export const MapButton = ({
  isDisabled,
  openCompare,
  setOpenMapview,
}: Props) => (
  <Button
    css={{
      'abyss-button-root': {
        width: '104px',
        border: 'solid 2px $primary1',
        '&:active, &:focus, &:hover': {
          backgroundColor: '#CCF2F7',
        },
      },
    }}
    data-auto-testid="filter-buttons-map"
    data-testid="filter-buttons-map"
    disabled={openCompare}
    isDisabled={isDisabled}
    onClick={() => setOpenMapview(true)}
    size="$sm"
    variant="outline"
  >
    <Layout.Group>
      <IconMaterial color="$primary1" icon="map" />
      <Text
        color={isDisabled || openCompare ? '$gray5' : '$primary1'}
        fontWeight={600}
      >
        {t('Map')}
      </Text>
    </Layout.Group>
  </Button>
);
