import { config } from '@abyss/web/tools/config';

import { useCustomQuery } from '../useCustomQuery';
import GET_PLAN_SELECTOR from './PlanSelector.graphql';

export const usePlanSelector = (options) =>
  useCustomQuery(GET_PLAN_SELECTOR, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'planSelector',
  });
