import { storage } from '@abyss/web/tools/storage';

import { Constants } from '../../../../common/Constants';
import { i18n } from '../../../../common/Utils/i18n/i18n';
import { DefaultLanguage, LANGUAGES, LanguageObject, Locale } from './types';

const getLanguageFromCookie = () => {
  if (typeof document !== 'undefined') {
    return storage.cookie.get(Constants.PSLOCALE);
  }

  return undefined;
};

export const getLanguage = (langCode?: string): LanguageObject => {
  let lang = langCode ? langCode : getLanguageFromCookie() || Locale.enUS;

  if (lang !== Locale.enUS && lang !== Locale.esUS) {
    lang = Locale.enUS;
  }

  // To change app language to Spanish for local development, uncomment
  //    the next line and comment out the other instance of `const lang`
  // const lang = getLanguageFromCookie() || Locale.esUS;

  const actualLanguageCode =
    lang === Locale.enUS ? DefaultLanguage.code : LANGUAGES.es.code;
  return LANGUAGES[actualLanguageCode];
};

export const setLanguage = (lang: LanguageObject) => {
  const currentLanguage = getLanguage();
  if (lang.locale !== currentLanguage.locale) {
    if (lang.locale === Locale.esUS) {
      i18n.changeLanguage(LANGUAGES.es.code);
    } else {
      i18n.changeLanguage(LANGUAGES.en.code);
    }
  }

  return new Promise<LanguageObject>((resolve, reject) => {
    if (lang.locale !== currentLanguage.locale) {
      if (typeof document !== 'undefined') {
        storage.cookie.set(Constants.PSLOCALE, lang.locale);
        resolve(lang);
      }

      reject('An error occurred when attempting to set the language');
    } else {
      reject(lang);
    }
  });
};

export const initializeLanguage = (portalData?, urlLocale?): LanguageObject => {
  const locale = urlLocale ? urlLocale : portalData?.portalConfig?.language;
  return getLanguage(locale);
};
