/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { config } from '@abyss/web/tools/config';
import { storage } from '@abyss/web/tools/storage';
import { styled } from '@abyss/web/tools/styled';
import { Button } from '@abyss/web/ui/Button';
import { IconBrand } from '@abyss/web/ui/IconBrand';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Modal } from '@abyss/web/ui/Modal';
import { Text } from '@abyss/web/ui/Text';
/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { createRef, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import { PortalContext } from '../../context/PortalContext';
import { useAnnexEncryptedPayload } from '../../hooks/useArcade';
import { useLagoon } from '../../hooks/useLagoon';
import { usePingFederateEncryptedPayload } from '../../hooks/usePingFederate';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import {
  getFormattedAndValidatedPhoneNumber,
  getFormattedPhoneNumber,
} from '../../utils/phone.utils';
import { getLagoonConfigValue } from '../../utils/providerSearch.utils';
import { getDocAsapTargetUrl } from '../../utils/scheduling.utils';
import { getCurrentMember, getLoggedInMember } from '../../utils/user.utils';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../Constants';
import { mobileOnly, tabletOnly } from '../ConstantsStyles';
import { getFeatureFlag } from '../Utils/getFeatureFlag';
import { HiddenFormSubmission } from './ScheduleModal.style';
import { ScheduleModalHiddenForm } from './ScheduleModalHiddenForm';

type Props = {
  isOpen?: boolean;
  npi: string;
  phone: string;
  providerName: string;
  practiceId: string;
  providerId: string;
  locationId: string;
  setIsOpen: (a: boolean) => void;
};

const ModalWrap = styled(Modal, {
  '.abyss-modal-header-container': { padding: '0px' },
  '.abyss-modal-content-container': {
    borderRadius: '8px',
  },
  '.abyss-modal-content-title': {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '52px',
    paddingTop: '14px',
    paddingBottom: '14px',
    lineHeight: '0px',
    '@screen >= $lg': {
      marginTop: '32px',
    },
  },
  '.abyss-modal-close-button': {
    right: '16px',
    top: '16px',
    padding: '0px',
    minWidth: '20px',
    minHeight: '20px',
    '@screen >= $lg': {
      minWidth: '24px',
      minHeight: '24px',
    },
    svg: {
      width: '20px',
      height: '20px',

      color: '#1F2937',
      fill: '#1F2937',
      '@screen >= $lg': {
        width: '24px',
        height: '24px',
      },
    },
  },
  '.abyss-modal-overlay': {
    background: 'rgba(0, 0, 0, 0.7)',
  },
});

export const ScheduleModal = ({
  isOpen,
  npi,
  phone,
  providerName,
  practiceId,
  locationId,
  setIsOpen,
  providerId,
}: Props) => {
  const currentMember = getCurrentMember();
  const loggedInMember = getLoggedInMember();
  const { t } = useTranslation();
  const hiddenSubmit = createRef<HTMLButtonElement>();
  const [memberSchedulingHeader, setmemberSchedulingHeader] =
    useState<string>('');
  const [memberSchedulingOnline, setmemberSchedulingOnline] =
    useState<string>('');
  const [encryptedPayload, setEncryptedPayload] = useState<string>('');
  const mobileScreen = useMediaQuery(mobileOnly);
  const isTab = useMediaQuery(tabletOnly);
  const isSmallScreen = mobileScreen || isTab;
  const screenElementSizes = (isSmallScreen) => {
    if (isSmallScreen) {
      return {
        buttonWidth: '100%',
        iconSize: 16,
        size: '320px',
        paddingTop: '12px',
        paddingBottom: '16px',
      };
    }
    return {
      buttonWidth: '226px',
      iconSize: 24,
      size: '624px',
      paddingTop: '0px',
      paddingBottom: '24px',
    };
  };
  const { buttonWidth, size, iconSize, paddingBottom, paddingTop } =
    screenElementSizes(isSmallScreen);
  const [memberId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MEMBER_ID,
    0
  );
  const featureFlags = useLagoon('feature-flags')();
  const schedulingEnabled = getFeatureFlag(featureFlags, 'ONLINE_SCHEDULING');
  const schedulingPhoneNumber = getFeatureFlag(
    featureFlags,
    'SHOW_SCHEDULING_PHONENUMBER'
  );
  const callPingFederate = getFeatureFlag(featureFlags, 'PING_FEDERATE');

  const allConfigs = useLagoon('config');
  const uiMessaging = useLagoon('ui-messaging')();
  const docAsapUrl = getLagoonConfigValue(allConfigs, 'DOCASAP_URL');
  const docAsapPartnerCode = getLagoonConfigValue(
    allConfigs,
    'DOCASAP_PARTNER_CODE'
  );
  const docAsapCredentialCode = getLagoonConfigValue(
    allConfigs,
    'DOCASAP_CREDENTIAL_CODE'
  );
  const docAsapChannelId = getLagoonConfigValue(
    allConfigs,
    'DOCASAP_CHANNEL_ID'
  );
  const vendorId = getLagoonConfigValue(allConfigs, 'DOCASAP_VENDOR_ID');
  const vsid = getLagoonConfigValue(allConfigs, 'PFID_VS_ID');
  const ssoOutUrl = callPingFederate
    ? getLagoonConfigValue(allConfigs, 'PF_SSOOUT_URL')
    : getLagoonConfigValue(allConfigs, 'FPC_SSOOUT_URL');
  const partnerSpId = getLagoonConfigValue(allConfigs, 'PARTNER_SP_ID');
  const pfidAdapterId = getLagoonConfigValue(allConfigs, 'PFID_ADAPTER_ID');
  const [, getPayload] = useAnnexEncryptedPayload({
    onCompleted: (result) => {
      setEncryptedPayload(result?.data?.annexEncryptedPayload?.payload || '');
    },
  });

  const [, getPingFederatePayload] = usePingFederateEncryptedPayload({
    onCompleted: (result) => {
      setEncryptedPayload(result?.data?.pingFederate?.ref || '');
    },
  });

  const scheduleModelDescription = schedulingPhoneNumber
    ? `${providerName}. ${t('SCHEDULE_MODAL.DOCASAP_CONTENT')}`
    : t('SCHEDULE_MODAL.DESCRIPTION');

  const scheduleModelPhoneNumber =
    schedulingPhoneNumber && phone
      ? `${t('SCHEDULE_MODAL.CALL')} ${getFormattedPhoneNumber(phone)}`
      : `${t('SCHEDULE_MODAL.CALL')}`;

  const CALENDAR_SVG = `${config(
    'CDN_BASE_URL'
  )}/cdn/assets/images/calendar.svg`;

  const CHAT_SVG = `${config(
    'CDN_BASE_URL'
  )}/cdn/assets/images/chat_connection.svg`;

  const schedulingPhoneNumberIcon = schedulingPhoneNumber ? (
    <IconBrand
      data-testid="handshake-icon"
      icon="handshake"
      size="84px"
      variant="twotonelightcircle"
    />
  ) : (
    <img alt="chat" src={CHAT_SVG} />
  );
  const { portalData } = useContext(PortalContext);
  const scheduleModalBtnText = portalData?.portalConfig?.isGuestUser
    ? t('SCHEDULE_MODAL.SCHEDULE_SIGN_IN')
    : t('SCHEDULE_MODAL.SCHEDULE');
  const displayIcon = portalData?.portalConfig?.isGuestUser ? (
    ''
  ) : (
    <IconMaterial css={{ marginLeft: '8px' }} icon="launch" size={iconSize} />
  );

  useEffect(() => {
    if (encryptedPayload) {
      hiddenSubmit.current?.click();
    }
  }, [encryptedPayload]);

  const ssoOutTargetUrl = getDocAsapTargetUrl(
    docAsapUrl,
    docAsapPartnerCode,
    docAsapCredentialCode,
    docAsapChannelId,
    npi,
    practiceId
  );
  const doShowDocASAP = schedulingEnabled && practiceId;
  const { navigate } = useRouter();
  const isGuestUser = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.IS_GUEST
  );
  const targetPath = `&initiateDocasapSession=true&providerId=${providerId}&locationId=${locationId}`;
  const callBackUrl = `${window.location.pathname}${window.location.search}${targetPath}`;
  const dynamicName = isGuestUser
    ? Constants.DETAILS_CHIP_NAMES.SignIn
    : Constants.DETAILS_CHIP_NAMES.Schedule;
  const handleScheduleClick = () => {
    adobeLinkTrackEvent({
      name: dynamicName,
      location: 'modal:schedule a visit:schedule',
      destinationUrl: isGuestUser ? window.location.href : ssoOutTargetUrl,
    });
    const URL =
      window.location.origin +
      portalData?.portalConfig?.signInUrl?.replace('LANDING_PAGE', callBackUrl);
    if (isGuestUser) {
      window.location.href = URL;
    }
    //Added feature flag check to call the correct hook
    if (!isGuestUser) {
      callPingFederate
        ? getPingFederatePayload({
            variables: {
              pingFederatePayload: {
                subject: loggedInMember?.rallyId,
                firstName: currentMember?.demographics?.name.firstName,
                lastName: currentMember?.demographics?.name.lastName,
                dob: currentMember?.demographics?.dateOfBirth,
                groupNumber: currentMember?.policyNumber,
                memberId: memberId.toString(),
                gender: currentMember?.demographics?.gender,
                state: currentMember?.demographics?.address?.[0]?.state,
                zipCode: currentMember?.demographics?.address?.[0]?.zipCode,
                city: currentMember?.demographics?.address?.[0]?.city,
                address1: currentMember?.demographics?.address?.[0]?.address1,
              },
            },
          })
        : getPayload();
    }
  };
  const typeaheadState = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      initiateDocasapSession: state.typeaheadSearchStore.initiateDocasapSession,
    }))
  );
  const handleCallClick = () => {
    const href = `tel:${getFormattedAndValidatedPhoneNumber(phone)}`;
    adobeLinkTrackEvent({
      name: Constants.DETAILS_CHIP_NAMES.Call,
      location: 'modal:schedule a visit:call',
      destinationUrl: href,
    });
    window.location.href = href;
  };

  useEffect(() => {
    if (typeaheadState?.initiateDocasapSession) {
      handleScheduleClick();
    }
  }, [typeaheadState?.initiateDocasapSession]);

  useEffect(() => {
    if (uiMessaging) {
      const MEMBER_SCHEDULING_MODAL_HEADER = uiMessaging.find(
        (x) => x.key === 'MEMBER_SCHEDULING_MODAL_HEADER'
      );
      setmemberSchedulingHeader(MEMBER_SCHEDULING_MODAL_HEADER?.message);
    }
  }, [uiMessaging]);

  useEffect(() => {
    if (uiMessaging) {
      const MEMBER_SCHEDULING_MODAL_ONLINE = uiMessaging.find(
        (x) => x.key === 'MEMBER_SCHEDULING_MODAL_ONLINE'
      );
      setmemberSchedulingOnline(MEMBER_SCHEDULING_MODAL_ONLINE?.message);
    }
  }, [uiMessaging]);

  return (
    <ModalWrap
      closeOnClickOutside={false}
      data-auto-testid="schedule-modal-container"
      data-testid="schedule-modal-container"
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      size={size}
      title={
        doShowDocASAP ? (
          <img alt="calendar" src={CALENDAR_SVG} />
        ) : (
          schedulingPhoneNumberIcon
        )
      }
      titleAlign="center"
    >
      <Modal.Section
        css={{
          'abyss-modal-section-root': {
            textAlign: 'center',
            padding: '16px',
            lineHeight: '18px',
          },
        }}
      >
        <div>
          <Text
            css={{
              '&.abyss-text-root': {
                fontWeight: '700',
                fontSize: '14px',
                '@screen >= $lg': {
                  lineHeight: '20px',
                  fontWeight: '700',
                  fontSize: '16px',
                },
              },
            }}
          >
            {t(memberSchedulingHeader)}
          </Text>
        </div>
        <div>
          <Text
            css={{
              '&.abyss-text-root': {
                fontSize: '14px',
                '@screen >= $lg': {
                  lineHeight: '16px',
                },
              },
            }}
            data-testid="schedule-modal-description"
            fontWeight="500"
            size="14px"
          >
            {doShowDocASAP
              ? t(memberSchedulingOnline)
              : scheduleModelDescription}
          </Text>
        </div>
      </Modal.Section>
      <Modal.Section
        css={{
          display: 'flex',
          justifyContent: 'center',
          '&.abyss-modal-section-root': {
            paddingTop,
            paddingBottom,
          },
        }}
        data-testid="schedule-modal-container"
      >
        {doShowDocASAP ? (
          <React.Fragment>
            <Button
              css={{
                width: buttonWidth,
                borderRadius: '24px',
              }}
              data-auto-testid="schedule-modal-schedule-button"
              data-testid="schedule-modal-schedule-button"
              onClick={handleScheduleClick}
              size="30px"
            >
              {scheduleModalBtnText}
              {displayIcon}
            </Button>
            <HiddenFormSubmission
              action={ssoOutUrl}
              data-testid="docasap-form"
              method={callPingFederate ? 'get' : 'post'}
              target="_blank"
            >
              <ScheduleModalHiddenForm
                {...{
                  callPingFederate,
                  encryptedPayload,
                  partnerSpId,
                  pfidAdapterId,
                  ssoOutTargetUrl,
                  ssoOutUrl,
                  vendorId,
                  vsid,
                }}
              />
              <button ref={hiddenSubmit} type="submit" />
            </HiddenFormSubmission>
          </React.Fragment>
        ) : (
          <Button
            css={{
              width: buttonWidth,
              borderRadius: '24px',
            }}
            data-auto-testid="schedule-modal-call-button"
            data-testid="schedule-modal-call-button"
            onClick={handleCallClick}
            size="30px"
          >
            {!schedulingPhoneNumber && (
              <IconMaterial
                css={{ marginRight: '8px' }}
                icon="phone"
                size={iconSize}
              />
            )}
            {scheduleModelPhoneNumber}
          </Button>
        )}
      </Modal.Section>
    </ModalWrap>
  );
};
