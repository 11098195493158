import { useCallback, useEffect, useRef, useState } from 'react';

import { Constants } from '../../../../common/Constants';
import { useCategoriesCount } from '../../../../hooks/useCategoriesCount';
import { ChipCategory, GetCategoryCountProps } from './categoryCountTypes';
import {
  getPayloadWithRecriprocityId,
  groupSpecialtyRollupCodes,
} from './searchUtils';
import {
  compareAndReturn,
  getModifiedPayloadForCategoriesCount,
} from './utils';

type UseParallelSearchResults = {
  isNameResultsLoaded: boolean;
  isSpecialityResultsLoaded: boolean;
  nameResults: ChipCategory[];
  specialityResults: ChipCategory[];
  corelationIds: string;
};

export const useParallelSearchResultsCategoriesCount: (
  customParams: any
) => [
  results: UseParallelSearchResults,
  getCategoryCountResults: (params: GetCategoryCountProps) => any
] = (customParams: any) => {
  const isNameLoaded = useRef(false);
  const isSpecialityLoaded = useRef(false);
  const shouldLoadName = useRef(false);
  const shouldLoadSpeciality = useRef(false);

  const [nameCategoryCount, getNameResults] = useCategoriesCount({
    onCompleted: () => (isNameLoaded.current = true),
  });
  const [specialityCategoryCount, getSpecialityCategoryCount] =
    useCategoriesCount({
      onCompleted: () => (isSpecialityLoaded.current = true),
    });

  const [results, setResults] = useState({
    isNameResultsLoaded: false,
    isSpecialityResultsLoaded: false,
    nameResults: [],
    specialityResults: [],
    corelationIds: '',
  });

  const {
    data: nameChipCategoryData,
    corelationId: nameCategoryCountCoRelationId,
  } = nameCategoryCount;

  const {
    data: specialityChipCategoryData,
    corelationId: specialityCategoryCountCoRelationId,
  } = specialityCategoryCount;

  const getCategoryCountResults = useCallback(
    ({
      currentMember,
      onlineRetailers,
      featureFlags,
      pseudoVerticalsDetails,
      pseudoVerticals,
      includeSpecialityRollupCodes,
      searchType,
      uesTranslation,
      coverageTypes,
      keyword,
      search,
      searchTerm,
      isMixedSuggestions,
      isUserTextSearch,
      llmFlag,
    }: GetCategoryCountProps) => {
      isNameLoaded.current = false;
      isSpecialityLoaded.current = false;
      const payloadWithRecriprocityId = getPayloadWithRecriprocityId({
        currentMember,
        onlineRetailers,
        featureFlags,
        pseudoVerticalsDetails,
        pseudoVerticals,
      });

      const getNameSearchResults = () => {
        const namesPayload = getModifiedPayloadForCategoriesCount(
          payloadWithRecriprocityId,
          undefined,
          uesTranslation,
          search,
          keyword,
          pseudoVerticalsDetails?.isPseudoRollupSearch,
          isUserTextSearch,
          coverageTypes
        );

        getNameResults({
          ...customParams,
          payloadwithName: namesPayload,
          searchTerm: compareAndReturn(searchTerm, search),
        });
      };

      const getSpecialityCategoryCountResults = () => {
        const codeByGroup = groupSpecialtyRollupCodes(
          includeSpecialityRollupCodes,
          searchType,
          true
        );
        const specialityPayload = getModifiedPayloadForCategoriesCount(
          payloadWithRecriprocityId,
          codeByGroup,
          uesTranslation,
          !isUserTextSearch ? search : '',
          keyword,
          pseudoVerticalsDetails?.isPseudoRollupSearch,
          isUserTextSearch,
          coverageTypes,
          customParams.isConditionSearch
        );

        getSpecialityCategoryCount({
          ...customParams,
          payloadwithName: specialityPayload,
          searchTerm: compareAndReturn(searchTerm, search),
          invokeLlmModel: llmFlag,
          isUserTextSearch,
        });
      };

      const makeSpecialitySearch =
        searchType === Constants.SEARCH_TYPES.SPECIALTY ||
        pseudoVerticalsDetails?.isPseudoRollupSearch ||
        includeSpecialityRollupCodes?.length > 0 ||
        customParams.isConditionSearch;
      const makeNameSearch =
        (isMixedSuggestions || searchType === Constants.SEARCH_TYPES.NAME) &&
        !!search;

      isNameLoaded.current = !makeNameSearch;
      shouldLoadName.current = makeNameSearch;
      if (makeNameSearch) {
        getNameSearchResults();
      }

      isSpecialityLoaded.current = !makeSpecialitySearch;
      shouldLoadSpeciality.current = makeSpecialitySearch;
      if (makeSpecialitySearch) {
        getSpecialityCategoryCountResults();
      }

      setResults({
        isNameResultsLoaded: false,
        isSpecialityResultsLoaded: false,
        nameResults: [],
        specialityResults: [],
        corelationIds: '',
      });
    },
    [getNameResults, getSpecialityCategoryCount]
  );

  useEffect(() => {
    setResults({
      isNameResultsLoaded: isNameLoaded.current,
      isSpecialityResultsLoaded: isSpecialityLoaded.current,
      nameResults: shouldLoadName.current
        ? nameChipCategoryData?.getChipCategoriesCount || []
        : [],
      specialityResults: shouldLoadSpeciality.current
        ? specialityChipCategoryData?.getChipCategoriesCount || []
        : [],
      corelationIds: `${
        shouldLoadName.current ? nameCategoryCountCoRelationId : ''
      }-${
        shouldLoadSpeciality.current ? specialityCategoryCountCoRelationId : ''
      }`,
    });
  }, [
    JSON.stringify(nameChipCategoryData?.getChipCategoriesCount),
    JSON.stringify(specialityChipCategoryData?.getChipCategoriesCount),
    specialityCategoryCountCoRelationId,
    nameCategoryCountCoRelationId,
    shouldLoadName.current,
    shouldLoadSpeciality.current,
    isNameLoaded.current,
    isSpecialityLoaded.current,
  ]);

  return [results, getCategoryCountResults];
};
