import { Icon } from '@abyss/web/ui/Icon';
import React from 'react';

export const PrintPreviewTierOneIcon = () => (
  <Icon size="13px">
    <svg
      fill="none"
      height="14"
      viewBox="0 0 13 14"
      width="13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6.33287" cy="7.04162" fill="black" r="5.13854" />
      <path
        d="M4.76404 4.30029V4.61631H4.24248V6.014H3.86994V4.61631H3.35352V4.30029H4.76404Z"
        fill="white"
      />
      <path d="M5.32979 4.30029V6.014H4.95724V4.30029H5.32979Z" fill="white" />
      <path
        d="M6.94686 4.30029V4.61631H6.03477V4.98372H6.87235V5.27918H6.03477V5.69798H6.96484V6.014H5.66223V4.30029H6.94686Z"
        fill="white"
      />
      <path
        d="M7.23289 4.30029H8.0602C8.24261 4.30029 8.34282 4.32085 8.43788 4.37737C8.58176 4.46216 8.66141 4.60861 8.66141 4.78588C8.66141 4.98372 8.5612 5.14044 8.38906 5.20468C8.57919 5.26377 8.63314 5.37682 8.65884 5.77248C8.66911 5.89324 8.68453 5.9549 8.71022 5.99344L8.72564 6.014H8.33511C8.31199 5.97032 8.30171 5.91893 8.29143 5.78276C8.27345 5.42306 8.20921 5.34085 7.94201 5.34085H7.60544V6.014H7.23289V4.30029ZM7.60544 4.59576V5.06594H7.96256C8.08589 5.06594 8.15526 5.05309 8.20408 5.01712C8.26317 4.97601 8.294 4.91178 8.294 4.82956C8.294 4.6677 8.19637 4.59576 7.97798 4.59576H7.60544Z"
        fill="white"
      />
      <path
        d="M6.73345 6.78931V10.6386H5.95907V8.12327H5.68776H5.41079H5.14513H5.05469V7.56368C5.70471 7.55803 6.04386 7.30933 6.12299 6.78931H6.73345Z"
        fill="white"
      />
    </svg>
  </Icon>
);
