import { useTranslation } from 'react-i18next';

import {
  ATTRIBUTE_STATUS_TEXT,
  NULL_ATTRIBUTE_STATE,
} from '../../CompareDrawer/utility/compareDrawerConstants';
import { getStatusText } from '../../ShareDrawer/utils';
import { PrintViewProviderDetailsXSText } from './PrintExpandedCard.styled';

type Props = {
  virtualCareOffered: boolean;
};

export const PrintVirtualCareOfferedAttribute = ({
  virtualCareOffered,
}: Props) => {
  const { t } = useTranslation();

  const virtualCareOfferredText = getStatusText(
    virtualCareOffered,
    ATTRIBUTE_STATUS_TEXT.VIRTUAL_CARE_OFFERED.TRUE,
    ATTRIBUTE_STATUS_TEXT.VIRTUAL_CARE_OFFERED.FALSE,
    NULL_ATTRIBUTE_STATE.VIRTUAL_CARE_OFFERED
  );

  return (
    <PrintViewProviderDetailsXSText>
      {t(virtualCareOfferredText)}
    </PrintViewProviderDetailsXSText>
  );
};
