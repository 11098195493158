import { config } from '@abyss/web/tools/config';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { Fragment, useEffect } from 'react';
import React from 'react';

import { PopulationType } from '../../../../../../api/src/services/Constants';
import packageJson from '../../../../../package.json';
import { useLagoon } from '../../../../hooks/useLagoon';
import {
  getCoverageTypes,
  getCurrentMember,
} from '../../../../utils/user.utils';
import { IChildrenProp } from '../../types';
import { getPlanSelectorVariables } from '../PlanSelector/helpers';

const defaultDomainExclusions = [
  'lpsnmedia.net(\\/[^]*)?$',
  'iperceptions.com(\\/[^]*)?$',
  'azureedge.net(\\/[^]*)?$',
  'adobedtm.com(\\/[^]*)?$',
  'async-msg[^.]*\\.optum\\.com(\\/[^]*)?$',
  'cloudfront.net(\\/[^]*)?$',
  'doubleclick.net(\\/[^]*)?$',
  'liveperson.net(\\/[^]*)?$',
  'optumtrax\\.optum\\.com(\\/[^]*)?$',
  'qualtrics.com(\\/[^]*)?$',
  'smetrics\\.uhc\\.com(\\/[^]*)?$',
  'omtrdc.net(\\/[^]*)?$',
  'ometrics\\.optum\\.com(\\/[^]*)?$',
  'sessioncam.com(\\/[^]*)?$',
  'facebook.com(\\/[^]*)?$',
  'google.com(\\/[^]*)?$',
  'cloudflare.com(\\/[^]*)?$',
  'abyss-cloud\\.uhc\\.com(\\/[^]*)?$',
  'nextportal-cdn.prod.werally.in(\\/[^]*)?$',
  'mapbox.com(\\/[^]*)?$',
];

export const useConfig = (key: string): string => {
  const allConfigs = useLagoon('config');
  const configs = allConfigs(key);
  return configs ? configs.value : '';
};

export const Analytics = ({ children }: IChildrenProp) => {
  const envVar = config('ENV_VAR');
  const apiGateway = config('AWS_API_GATEWAY');
  const applicationId = useConfig('DATADOG_EXT_APPLICATION_ID');
  const clientToken = useConfig('DATADOG_EXT_CLIENT_TOKEN');
  const service = useConfig('DATADOG_EXT_SERVICE');
  const currentMember = getCurrentMember();
  const { accountId } = getPlanSelectorVariables();

  useEffect(() => {
    if (envVar === 'local') return;

    if (!applicationId || !clientToken || !service) {
      // eslint-disable-next-line no-console, consistent-return
      return console.warn(
        'DataDog init failure due to incomplete receive applicationId, clientToken, service!'
      );
    }

    datadogLogs.init({
      clientToken,
      site: 'datadoghq.com',
      service,
      forwardErrorsToLogs: true,
      sampleRate: 100,
      env: envVar,
      version: packageJson.version,
    });

    const excludedActivityPattern = new RegExp(
      `^https://((www|beta)\\.)?(${defaultDomainExclusions.join('|')})`
    );

    const eniGlobalNavPattern = new RegExp('eni-global-nav');

    datadogRum.init({
      applicationId,
      clientToken,
      site: 'datadoghq.com',
      service,
      env: envVar,
      version: packageJson.version,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      trackFrustrations: true,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      traceSampleRate: 100,
      allowedTracingUrls: [
        `${window.location.protocol}//${window.location.host}`,
        apiGateway,
      ],
      excludedActivityUrls: [
        excludedActivityPattern,
        eniGlobalNavPattern,
        'https://member.uhc.com/findcare/api/graphql?q=PrimaryCare',
        'https://member.uhc.com/ce/api/globalnav/graphql',
        'https://api.member.uhc.com/benefits/eni/graphql?traceName=global-nav',
      ],
      storeContextsAcrossPages: true,
    });
    const isIFPLineOfBusiness =
      currentMember?.population === PopulationType.IFP ||
      currentMember?.population === PopulationType.IFP_BINDER;
    datadogRum.setGlobalContext({
      lineOfBusiness: isIFPLineOfBusiness
        ? PopulationType.IFP
        : currentMember?.lineOfBusiness.replace(/&/g, 'n'),
      groupId: currentMember?.policyNumber,
      hsid: accountId ?? '',
      membershipCategory: currentMember?.membershipCategory || '',
      population: currentMember?.population || '',
      planName:
        currentMember?.planDetail?.eligibility?.benefitSet?.find(
          (benefitSet) => benefitSet.coverageTypeCode.code === 'M'
        )?.planNameType?.brandingLongName || '', // from OBAPI's planDetails response for medical coverage
      coverageTypeCode: getCoverageTypes(currentMember)?.includes('M')
        ? 'M'
        : currentMember?.eligibility?.[0]?.memberHealthCoverage?.coverageType[0]
            .typeCode.coverageTypeCode, // if member has medical coverage, otherwise first coverage returned from obapi's profile
    });
    datadogRum.startSessionReplayRecording();
  }, [applicationId, clientToken, service]);

  return <Fragment>{children}</Fragment>;
};
