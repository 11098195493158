import { stopwatch } from '@abyss/web/tools/stopwatch';
import { Button } from '@abyss/web/ui/Button';
import { Layout } from '@abyss/web/ui/Layout';
import { Modal } from '@abyss/web/ui/Modal';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useHeartbeat } from '../../../../hooks/useHeartbeat';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { logout } from '../../../../utils/session.utils';

export const TimeoutModal = () => {
  const IDLE_MILLIS = 25 * 60 * 1000;
  const LOGOUT_MILLIS = 5 * 60 * 1000;
  const REFRESH_DELAY_SECONDS = 60;

  const ACTIONS = ['mousedown', 'focus', 'keydown'];

  const [isInactive, setIsInactive] = React.useState<boolean>(false);
  const setSessionId = useStore(StoreKeys.SET_SESSION_ID);
  const { t } = useTranslation();
  const [, getHeartbeat] = useHeartbeat({
    onError: () => {
      logout(setSessionId);
    },
  });
  let timer;

  const addEvents = (listener) => {
    ACTIONS.forEach((eventName) => {
      window.addEventListener(eventName, listener);
    });
  };

  const removeEvents = (listener) => {
    ACTIONS.forEach((eventName) => {
      window.removeEventListener(eventName, listener);
    });
  };

  // Don't attempt to refresh session if last attempt was less than REFRESH_DELAY_SECONDS seconds ago
  const shouldDoHeartbeat = () => {
    if (timer?.stop() > REFRESH_DELAY_SECONDS) {
      getHeartbeat();
      timer = stopwatch.start();
    }
  };

  useEffect(() => {
    getHeartbeat();
    timer = stopwatch.start();
  }, []);

  useEffect(() => {
    const idleTimeout = () =>
      setTimeout(() => {
        setIsInactive(true);
      }, IDLE_MILLIS);

    const logoutTimeout = () =>
      setTimeout(() => {
        logout(setSessionId);
      }, LOGOUT_MILLIS);

    let timeout = isInactive ? logoutTimeout() : idleTimeout();

    const listener = () => {
      if (!isInactive) {
        shouldDoHeartbeat();
        clearTimeout(timeout);
        timeout = idleTimeout();
      }
    };

    addEvents(listener);

    return () => {
      removeEvents(listener);
      clearTimeout(timeout);
    };
  }, [isInactive]);

  const handleRenewSession = () => {
    getHeartbeat();
    setIsInactive(false);
  };

  const handleLogout = () => {
    logout(setSessionId);
  };

  if (!isInactive) {
    return null;
  }

  return (
    <Modal
      closeOnClickOutside={false}
      closeOnEscPress={false}
      isOpen
      onClose={handleRenewSession}
      size="md"
      title={t('INACTIVITY_MODAL.TITLE')}
    >
      <Modal.Section>{t('INACTIVITY_MODAL.BODY_LINE_1')}</Modal.Section>
      <Modal.Section
        dangerouslySetInnerHTML={{ __html: t('INACTIVITY_MODAL.BODY_LINE_2') }}
      />
      <Modal.Section>
        <Layout.Group>
          <Button
            data-auto-testid="timeout-modal-renew"
            data-testid="timeout-modal-renew"
            onClick={handleRenewSession}
          >
            {t('INACTIVITY_MODAL.RENEW_SESSION')}
          </Button>
          <Button
            data-auto-testid="timeout-modal-logout"
            data-testid="timeout-modal-logout"
            onClick={handleLogout}
            variant="outline"
          >
            {t('INACTIVITY_MODAL.SIGN_OUT')}
          </Button>
        </Layout.Group>
      </Modal.Section>
    </Modal>
  );
};
