import { Layout } from '@abyss/web/ui/Layout';
import isEmpty from 'lodash/isEmpty';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { CompareProvider } from '../../../../../../models/Provider';
import { NULL_ATTRIBUTE_STATE } from '../../CompareDrawer/utility/compareDrawerConstants';
import { getHoursOfOperaion } from '../../CompareDrawer/utility/getHoursOfOperation';
import { PrintViewProviderDetailsXSText } from './PrintExpandedCard.styled';

type Props = {
  selectedProvider: CompareProvider;
};

export const PrintHoursOfOperation = ({ selectedProvider }: Props) => {
  const { t } = useTranslation();
  const { daysWithTime, possibleHours } = getHoursOfOperaion(
    selectedProvider.hoursOfOperation
  );

  const isAttributeEmpty =
    isEmpty(daysWithTime) ||
    isEmpty(possibleHours) ||
    isEmpty(selectedProvider.hoursOfOperation);

  return (
    <Layout.Group alignItems="top">
      {isAttributeEmpty ? (
        <PrintViewProviderDetailsXSText>
          {t(NULL_ATTRIBUTE_STATE.HOURS_OF_OPERATION)}
        </PrintViewProviderDetailsXSText>
      ) : (
        <React.Fragment>
          <PrintViewProviderDetailsXSText>
            {`${daysWithTime}:`}&nbsp;
            {possibleHours}
          </PrintViewProviderDetailsXSText>
        </React.Fragment>
      )}
    </Layout.Group>
  );
};
