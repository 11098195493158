import { useTranslation } from 'react-i18next';

import { PracticeLimitation } from '../../../../../../models/Provider';
import {
  ATTRIBUTE_STATUS_TEXT,
  NULL_ATTRIBUTE_STATE,
} from '../../CompareDrawer/utility/compareDrawerConstants';
import { getStatusText } from '../../ShareDrawer/utils';
import { PrintViewProviderDetailsXSText } from './PrintExpandedCard.styled';

type Props = {
  practiceLimitations: PracticeLimitation;
};

export const PrintPracticeLimitations = ({ practiceLimitations }: Props) => {
  const { t } = useTranslation();

  const practiceLimitationsDefaultText = t(
    getStatusText(
      !!practiceLimitations?.startAge,
      ATTRIBUTE_STATUS_TEXT.PRACTICE_LIMITATIONS.TRUE,
      ATTRIBUTE_STATUS_TEXT.PRACTICE_LIMITATIONS.FALSE,
      NULL_ATTRIBUTE_STATE.PRACTICE_LIMITATIONS
    )
  );

  const practiceLimitationsDisplayText =
    practiceLimitations?.startAge === '0'
      ? `${practiceLimitationsDefaultText}: All ages`
      : `${practiceLimitationsDefaultText}: ${practiceLimitations?.startAge}+`;

  return (
    <PrintViewProviderDetailsXSText>
      {practiceLimitations?.startAge
        ? practiceLimitationsDisplayText
        : practiceLimitationsDefaultText}
    </PrintViewProviderDetailsXSText>
  );
};
