import { storage } from '@abyss/web/tools/storage';

import { adobeLinkTrackEvent } from '../../../AdobeTagging/adobeLinkTrackEvent';
import { Constants } from '../../../Constants';
import { ConstantsRoutes } from '../../../ConstantsRoutes';
import { convertObjectToUrlParams } from '../../../Utils';

export const handleHospitalAffiliationOnClick = (
  providerId,
  name,
  navigate,
  { chipStore, setTypeaheadSearchStore, setDetailsStore }
) => {
  adobeLinkTrackEvent({
    name,
    location: `body:${Constants.PROVIDER_DETAILS.ABOUT_TAB.DETAILS_SECTION.HOSPITAL_AFFILIATIONS}`,
    type: 'internal',
    destinationUrl: '',
  });
  storage.session.set(
    Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_ID,
    providerId
  );
  const typeaheadStoreValues = {
    sectionType: Constants.RESULT_SECTION.FACILITY,
  };
  const detailsStoreValues = {
    providerId,
  };
  setTypeaheadSearchStore(typeaheadStoreValues);
  setDetailsStore(detailsStoreValues);
  storage.session.set(
    Constants.STORAGE_KEYS.SESSION.SELECTED_PROVIDER_ID,
    providerId
  );
  const urlParams = convertObjectToUrlParams(chipStore, {
    ...detailsStoreValues,
    ...typeaheadStoreValues,
  });
  navigate(`${ConstantsRoutes.FACILITY_DETAILS.path}${urlParams}`);
};
