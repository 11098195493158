import { styled } from '@abyss/web/tools/styled';
import { Flex } from '@abyss/web/ui/Flex';

import { PSXIcon } from '../PSXIcon';

export const HealthGradesStyled = styled(Flex, {
  flexDirection: 'column',
  color: '$white',
  margin: '20px 0 4px $xs',
});
export const StyledIcon = styled(PSXIcon, {
  height: '37px !important',
});
