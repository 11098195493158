import { styled } from '@abyss/web/tools/styled';

export const PreferredProviderMobile = styled('div', {
  backgroundColor: '$success2',
  fontSize: '12.64px',
  display: 'flex',
  width: '140px',
});

export const PreferredProviderWeb = styled('div', {
  backgroundColor: '$success2',
  width: '140px',
  fontSize: '12.64px',
  display: 'flex',
});

export const GreenDiamond = styled('div', {
  marginTop: '5px',
  marginLeft: '4px',
});
