import { styled } from '@abyss/web/tools/styled';
import React, { ReactNode, useEffect, useState } from 'react';

import { LagoonContext } from '../hooks/useLagoon/LagoonContext';
import { lagoon } from './lagoonUtil';

const LagoonLoader = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  height: '90vh',
});

export interface Props {
  app: string;
  lob: string;
  env: string;
  locale: string;
  hostEnv?: string;
  user?: any;
  timeout?: number;
  loadingIcon?: ReactNode;
}

export const LagoonProvider = ({
  app,
  lob,
  env,
  hostEnv,
  user,
  timeout,
  loadingIcon,
  locale,
  ...props
}: Props) => {
  const [lagoonInstance, setLagoonInstance] = useState({});

  useEffect(() => {
    const fallbackLoadTimeout = setTimeout(() => {
      setLagoonInstance((prevState) => prevState || {});
    }, timeout);
    lagoon(app, lob, env, hostEnv, locale).initialize(user, {
      onReady: () => {
        clearTimeout(fallbackLoadTimeout);
      },
      onData: (instance) => {
        setLagoonInstance(instance);
      },
    });
  }, [app, env, user, hostEnv, timeout, locale, lob]);
  if (!Object.keys(lagoonInstance).length) {
    if (loadingIcon) {
      return <LagoonLoader>{loadingIcon}</LagoonLoader>;
    }
    return null;
  }

  return <LagoonContext.Provider {...props} value={lagoonInstance} />;
};

LagoonProvider.displayName = 'psx/ui/LagoonProvider';
