export const headerStyleSubTitle = {
  '&.abyss-heading-root': {
    fontSize: '22px !important',
    margin: '24px 0 !important',
  },
};

export const textStyle = {
  '&.abyss-text-root': {
    fontWeight: '100 !important',
  },
};
