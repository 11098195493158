import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { usePagination } from '@abyss/web/hooks/usePagination';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { storage } from '@abyss/web/tools/storage';
import mapboxgl from 'mapbox-gl';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSessionStorage } from 'usehooks-ts';
import { useShallow } from 'zustand/react/shallow';

import translationsEnJson from '../../../../../public/locale/en/translation.json';
import { adobeModalTrackEvent } from '../../../../common/AdobeTagging/adobeModalTrackEvent';
import { adobeSearchModifiedEvent } from '../../../../common/AdobeTagging/adobeSearchModifiedEvent';
import { adobeStandardSearchEvent } from '../../../../common/AdobeTagging/adobeStandardSearchEvent';
import {
  AdobeEventMethods,
  Constants,
  CoverageTypesCodes,
  EAP_OPTION_LABEL,
  FilterCollectionModel,
  INITIAL_PAGE,
  PAGE_SIZE,
  ReverseCoverageTypesCodes,
  SHOULD_GET_HG_DATA,
} from '../../../../common/Constants';
import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { ConstantsRoutes } from '../../../../common/ConstantsRoutes';
import { phoneOnly } from '../../../../common/ConstantsStyles';
import { ContentWrapper } from '../../../../common/ContentWrapper';
import { PSXHeader } from '../../../../common/PSXHeader';
import { getGeoLocationFromStorage } from '../../../../common/PSXHeader/SearchBar/utils';
import {
  convertProviderTypeToAdobeType,
  getFormattedFilters,
  getSearchLocation,
} from '../../../../common/Utils/adobeTrackUtils/adobeTrackUtils';
import { isMnrMember } from '../../../../common/Utils/memberUtils/memberUtils';
import { SearchFilterContext } from '../../../../context/SearchFilterContext';
import { ErrorBoundary } from '../../../../errors/ErrorBoundary';
import { useCompareProviders } from '../../../../hooks/useCompareProviders';
import { useCurrentMemberIsPreEffective } from '../../../../hooks/useCurrentMemberIsPreEffective';
import { useFeatureFlag } from '../../../../hooks/useFeatureFlag';
import { useGeoLocationStorage } from '../../../../hooks/useGeoLocationStorage';
import {
  useGetTokenOrUrlParams,
  useInitializeStore,
} from '../../../../hooks/useInitializeStore';
import { useLagoon } from '../../../../hooks/useLagoon';
import { useFilterProviderResults } from '../../../../hooks/useProviderSearch';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { BreadcrumbSessionStorage } from '../../../../models/BreadcrumbSessionStorage';
import { ProviderSearchFilters } from '../../../../models/ProviderSearch';
import { Directions } from '../../../../models/RouteDirections';
import { useAnalyticsStore } from '../../../../store/useAnalyticsStore';
import { AnalyticsStore } from '../../../../store/useAnalyticsStore/analyticsStore';
import { useChipStore } from '../../../../store/useChipStore';
import { ChipState } from '../../../../store/useChipStore/chipStore';
import { useDetailsStore } from '../../../../store/useDetailsStore';
import { DetailsStore } from '../../../../store/useDetailsStore/detailsStore';
import { usePCPStore } from '../../../../store/usePCPStore';
import { PCPSearchStore } from '../../../../store/usePCPStore/pcpStore';
import { useTypeaheadStore } from '../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../store/useTypeaheadStore/typeaheadStore';
import { returnSuppressFlag } from '../../../../utils/featureSuppress';
import { getRoute } from '../../../../utils/map.utils';
import { getMemberHealthCoverageType } from '../../../../utils/providerSearch.utils';
import {
  getClaimOrEligibilitySystemTypeCode,
  getCoverageEffDateRange,
  getCurrentMember,
  getCurrentPlanYear,
  getMemberEffectivePlanYear,
  getNetworkIdsForPES,
  getPlanVariationCode,
  getRulesPackageKey,
} from '../../../../utils/user.utils';
import { useConfig } from '../../context/Analytics';
import { getLanguage } from '../../context/Internationalization/helpers';
import { MapView } from '../MapView';
import { AllPaginationProps } from '../MapView/AllPaginationProps';
import { CustomAttributesBlock } from '../MapView/CompareDrawer/Components/ConfirmationModal/ConfirmationModal';
import {
  CHECKBOX_ITEM_NAMES,
  CompareCheckboxInfo,
} from '../MapView/CompareDrawer/utility/compareDrawerConstants';
import { openTabOrNavigateOnClick } from '../MapView/CompareDrawer/utility/handleDetailsOnClick';
import { CompareDrawerProps } from '../MapView/CompareDrawerProps';
import { MobileListView } from './MobileListView';
import { ShowCompareFloatingSection } from './ShowCompareFloatingSection';
import { ShowShareFloatingSection } from './ShowShareFloatingSection';
import { successToast } from './Toast';
import { getDefaultCompareAttributes } from './utils/getDefaultCompareAttributes';
import { parseBreadcrumbs } from './utils/parseBreadcrumbs';
import { MapViewWrapper, PageContainer } from './ViewAll.style';
import { ViewAllDisclaimers } from './ViewAllDisclaimers';

export const ViewAll = () => {
  const { t } = useTranslation();

  const { searchFilters: selectedFilters, setSearchFilters } =
    useContext(SearchFilterContext);
  const urlParams = useGetTokenOrUrlParams();
  const chipStore = useChipStore(useShallow((state: ChipState) => state));
  const { setCoverageType: setStoreCoverageType } = chipStore;
  if (urlParams?.coverageType) {
    setStoreCoverageType(urlParams?.coverageType);
  }
  const [filters, setFilters] =
    useState<ProviderSearchFilters>(selectedFilters);

  const [isFiltersChanged, setIsFiltersChanged] = useState(false);
  const [isPageNav, setIsPageNav] = useState(false);
  const [navigateToDirections, setNavigateToDirections] = useState(false);
  const [lastPageSize, setLastPageSize] = useState(0);
  const map = React.useRef<mapboxgl.Map>(null);
  const mobileScreen = useMediaQuery(phoneOnly);
  const { navigate } = useRouter();

  const { chipValue, coverageType } = useInitializeStore(
    ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key
  );

  const suppressPremiumCare: boolean = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PREMIUM_CARE
  );

  const suppressPatientReviews: boolean = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );

  const setIsTier1Plan = useStore(StoreKeys.SET_TIER1_PLAN_FLAG);

  const tierBenefits = useLagoon('tier-benefit')();
  const [costEnableFlag] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.COST_ENABLED,
  ]);

  const shouldGetHGData = SHOULD_GET_HG_DATA;

  const [mapboxOnpremEnabled] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_ONPREM_MAPBOX,
  ]);

  const isEapEligible = storage.session.get(
    Constants.STORAGE_KEYS.SESSION.IS_BH_EAP_ELIGIBLE
  );

  const uspTierOneFlag = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.USP_TIER1_ENABLE,
  ]);

  const uspToggleFlag = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.USP_ENABLE,
  ]);

  const defaultCompareAttribute = getDefaultCompareAttributes(coverageType);
  const currentMember = getCurrentMember();
  const isCns = currentMember?.isCnSMember;
  const finalCompareAttributeItems = defaultCompareAttribute
    .filter((item) => {
      if (item.name === CHECKBOX_ITEM_NAMES.PREMIUM_CARE_PROVIDER) {
        return !suppressPremiumCare;
      }
      if (item.name === CHECKBOX_ITEM_NAMES.REVIEWS) {
        return !suppressPatientReviews;
      }
      if (item.name === CHECKBOX_ITEM_NAMES.TIER_PROVIDER) {
        return !isCns;
      }
      return true;
    })
    .map((item) => {
      if (!costEnableFlag && item.name === CHECKBOX_ITEM_NAMES.COST) {
        return { ...item, isChecked: false };
      }
      return item;
    });

  const [items, setItems] = useState<CompareCheckboxInfo[]>(
    finalCompareAttributeItems
  );

  useEffect(() => {
    setItems(finalCompareAttributeItems);
  }, [JSON.stringify(finalCompareAttributeItems)]);

  const {
    sectionType = '',
    search = '',
    searchTerm = '',
    searchMethod,
    providerType = '',
    heading = '',
  } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      sectionType: state.typeaheadSearchStore.sectionType,
      search: state.typeaheadSearchStore.search,
      searchTerm: state.typeaheadSearchStore.searchTerm,
      searchMethod: state.typeaheadSearchStore.searchMethod,
      providerType: state.typeaheadSearchStore.providerType,
      heading: state.typeaheadSearchStore.heading,
    }))
  );

  useEffect(() => {
    if (
      urlParams?.virtualCare &&
      !selectedFilters[FilterCollectionModel.VIRTUAL_CARE]?.value
    ) {
      const virtualCare = {
        label: t('FILTER_OPTIONS.VirtualCare.Y'),
        value: true,
        dnt_label: translationsEnJson.FILTER_OPTIONS.VirtualCare.Y,
      };
      setSearchFilters({
        ...selectedFilters,
        [FilterCollectionModel.VIRTUAL_CARE]: virtualCare,
      });
    }
  }, []);

  const { choosePCP = false } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      choosePCP: state.pcpSearchState.choosePCP,
    }))
  );

  const { linkName } = useAnalyticsStore(
    useShallow((state: AnalyticsStore) => ({
      linkName: state.analyticsState.linkName,
    }))
  );

  const { setDetailsStore } = useDetailsStore(
    useShallow((state: DetailsStore) => ({
      setDetailsStore: state.setDetailsStore,
    }))
  );

  const isVisionCare = coverageType === ReverseCoverageTypesCodes.VISION;

  const [openMapview, setOpenMapview] = useState<boolean>(!mobileScreen);
  const [mobileRouteView, setMobileRouteView] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState(
    selectedFilters.PageNumber || INITIAL_PAGE
  );
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const pageSizeOptions = [10, 15, 20];
  const containerRef = useRef<HTMLDivElement>(null);

  const storedLocation = getGeoLocationFromStorage();
  const featureFlags: [{ key: string; active: boolean }] =
    useLagoon('feature-flags')();

  const {
    results = [],
    totalResultsCount,
    isLoading = false,
    isTieredPlan,
    headers,
  } = useFilterProviderResults({
    pageNumber,
    pageSize,
    selectedFilters: filters,
    coverageType,
    shouldGetHGData,
  });

  if (isTieredPlan !== undefined) {
    setIsTier1Plan(isTieredPlan);
  }

  const totalPages = Math.ceil(totalResultsCount / pageSize);
  const { state, gotoPage, nextPage, previousPage, ...paginationProps } =
    usePagination({
      pages: totalPages,
      start: pageNumber,
    });

  const { currentPage } = state;
  const { firstPage } = paginationProps;

  useEffect(() => {
    if (!isEapEligible) {
      const newArray = items.filter((el) => el.name !== 'eapAccepted');
      setItems(newArray);
    }
  }, [isEapEligible]);

  useEffect(() => {
    if (pageNumber > totalPages) {
      const totalPageCount = Math.ceil(totalResultsCount / lastPageSize);
      const prevPercentage = Math.ceil((pageNumber / totalPageCount) * 100);
      const updatedPageNumber = Math.ceil((prevPercentage / 100) * totalPages);
      // abbys pagination starts with 0 that's why -1 is required
      gotoPage(updatedPageNumber - 1);
    }
  }, [pageSize]);

  useEffect(() => {
    if (pageNumber !== currentPage) {
      setIsPageNav(true);
      setPageNumber(currentPage);
    }
  }, [currentPage]);

  useEffect(() => {
    setOpenMapview(!mobileScreen);
  }, [mobileScreen]);

  const serializedSelectedFilters = getFormattedFilters(selectedFilters);
  useEffect(() => {
    if (totalResultsCount === 0) {
      // TODO - add url params
      navigate(`${ConstantsRoutes.NULL_SPECIALTY_RESULTS.path}`, {
        replace: true,
      });
    } else if (totalResultsCount !== undefined) {
      // guided should not have search term
      const searchMethodForAnalytics = searchMethod ?? AdobeEventMethods.Guided;
      const searchTermForAnalytics =
        searchMethodForAnalytics === AdobeEventMethods.Guided ? '' : searchTerm;
      const isFromMixedResults = !!searchTerm;
      const analyticsBody = {
        linkName,
        filters: serializedSelectedFilters,
        numberOfResults: totalResultsCount,
        method: searchMethod ?? AdobeEventMethods.Guided,
        customAttributesBlock: {
          providerType: convertProviderTypeToAdobeType(providerType),
          searchLocation: getSearchLocation(storedLocation),
        },
        term: searchTermForAnalytics,
      };
      if (!isPageNav) {
        // if we are changing pages, there's no change in search really,
        // so we don't want to post standard search or search modified.
        if (isFiltersChanged) {
          adobeSearchModifiedEvent(analyticsBody);
        } else {
          !isFromMixedResults && adobeStandardSearchEvent(analyticsBody);
        }
      } else {
        setIsPageNav(false);
      }
    }
  }, [results]);

  useEffect(() => {
    if (JSON.stringify(selectedFilters) !== JSON.stringify(filters)) {
      firstPage();
      setFilters(selectedFilters);
      setIsFiltersChanged(true);
      setPageNumber(INITIAL_PAGE);
    }
  }, [JSON.stringify(selectedFilters)]);

  const [breadcrumbUrls, setBreadcrumbUrls] =
    useSessionStorage<BreadcrumbSessionStorage>(
      Constants.STORAGE_KEYS.SESSION.BREADCRUMB_URLS,
      {}
    );

  const breadcrumbs = parseBreadcrumbs(breadcrumbUrls, sectionType, t);

  useEffect(() => {
    breadcrumbUrls[ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.key] =
      ConstantsRoutes.PROVIDER_SEARCH_RESULTS_MAP_VIEW.path +
      window.location.search;
    setBreadcrumbUrls(breadcrumbUrls);
  }, []);

  const { IS_BH_EAP_ELIGIBLE } = Constants.STORAGE_KEYS.SESSION;
  urlParams?.eapAccepted &&
    storage.session.set(IS_BH_EAP_ELIGIBLE, urlParams?.eapAccepted);

  useEffect(() => {
    document.title = `${t(sectionType)} ${t('results for')} ${
      t(`${search}`) || ''
    } | ${Constants.SITE_NAME}`;

    if (urlParams?.eapAccepted) {
      const eapOption = {
        label: t(EAP_OPTION_LABEL),
        dnt_label: t(EAP_OPTION_LABEL),
        value: 'A045,E475',
      };
      selectedFilters.AreaOfExpertise = [
        ...(selectedFilters?.AreaOfExpertise || []),
        eapOption,
      ];
    }
  }, []);

  const [selectedCheckbox, setSelectedCheckbox] = useState({ checked: {} });
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [openCompare, setOpenCompare] = useState<boolean>(false);
  const [openShare, setOpenShare] = useState<boolean>(false);
  const [openBoxContents, setOpenBoxContents] = useState<boolean>(
    !!selectedItems.length
  );
  const [isShareAllResults, setIsShareAllResults] = useState<boolean>(false);
  const toastMessage = t('Your results have been shared');
  const [selectedId, setSelectedId] = useSessionStorage(
    Constants.STORAGE_KEYS.SESSION.MAP_PIN_SELECTED_ID,
    null
  );
  const [routeEndCoords, setRouteEndCoords] = useState<
    [number | null, number | null]
  >([null, null]);

  const [directions, setDirections] = useState<Directions>({
    userPlaceName: '',
    endPlaceName: '',
    routes: [],
  });

  const { longitude, latitude } = getGeoLocationFromStorage();

  const closeAllDrawersAndOpenSuccessAlert = () => {
    setOpenCompare(false);
    setOpenShare(false);
    // after integration, check for success here
    successToast(toastMessage);
  };

  // begin: a11y keyboard navigation
  const { compareProvidersFlow } = useStore(StoreKeys.UI_STATE);
  const { indexOflastProviderSelected, shouldFocusLastIndex } =
    compareProvidersFlow;

  useEffect(() => {
    const cards = document.querySelectorAll("input[type='checkbox']");
    if (cards && shouldFocusLastIndex) {
      const lastSelectedCard = cards[
        indexOflastProviderSelected
      ] as HTMLInputElement;
      if (lastSelectedCard) lastSelectedCard?.focus();
    }
  }, [openCompare, shouldFocusLastIndex]);

  const { shareProvidersFlow } = useStore(StoreKeys.UI_STATE);
  const {
    indexOflastProviderSelected: indexOflastShareProviderSelected,
    shouldFocusLastIndex: shouldFocusLastShareProviderIndex,
  } = shareProvidersFlow;

  useEffect(() => {
    const cards = document.querySelectorAll("input[type='checkbox']");
    if (cards && shouldFocusLastShareProviderIndex) {
      const lastSelectedCard = cards[
        indexOflastShareProviderSelected
      ] as HTMLInputElement;
      if (lastSelectedCard) lastSelectedCard?.focus();
    }
  }, [openShare, shouldFocusLastShareProviderIndex]);
  // end: a11y keyboard navigation

  const [, getCompareDetails] = useCompareProviders({
    onCompleted: (result) => result,
    onError: () => {},
  });

  const memCatergory = currentMember?.membershipCategory || '';
  const membershipCategory = uspToggleFlag ? memCatergory : null;

  const { lineOfBusiness, population } = currentMember;

  const { stateCode } = useGeoLocationStorage();
  const isMNR = isMnrMember(lineOfBusiness);
  const isPreEffective = useCurrentMemberIsPreEffective();
  const currentMemberEffectiveDate =
    getCoverageEffDateRange(currentMember).startDate;

  const addSelectedProvider = async (providerData) => {
    const index = selectedItems.findIndex(
      (provider) => provider.locationId === providerData.locationId
    );
    if (index === -1) {
      const locale = getLanguage()?.code || 'en';
      const medicalCoverage = getMemberHealthCoverageType(
        currentMember?.eligibility,
        CoverageTypesCodes.MEDICAL
      );
      const result = await getCompareDetails({
        variables: {
          lob: lineOfBusiness,
          locale,
          membershipCategory,
          providerId: providerData.providerId,
          providerType: providerData.providerType,
          latitude: providerData.latitude || '',
          longitude: providerData.longitude || '',
          stateCode,
          locationId: providerData.locationId || '',
          reciprocityId: getNetworkIdsForPES(
            currentMember,
            coverageType,
            featureFlags
          ),
          snpType:
            coverageType == ReverseCoverageTypesCodes.MEDICAL
              ? medicalCoverage?.snpType
              : '',
          rulesPackageKey: getRulesPackageKey(
            currentMember,
            tierBenefits,
            providerType,
            uspTierOneFlag
          ),
          planVariationCode: getPlanVariationCode(currentMember, coverageType),
          population,
          claimSystemTypeCode: getClaimOrEligibilitySystemTypeCode(
            currentMember,
            coverageType
          )?.claimSystemTypeCode,
          eligibilitySystemTypeCode: getClaimOrEligibilitySystemTypeCode(
            currentMember,
            coverageType
          )?.eligibilitySystemTypeCode,
          planYear: !isPreEffective
            ? getCurrentPlanYear()
            : getMemberEffectivePlanYear(new Date(currentMemberEffectiveDate)),
          memberEffectiveDate: isPreEffective
            ? currentMemberEffectiveDate
            : null,
          policyId: currentMember?.policyNumber,
          coverageType,
        },
      });

      const compareProviderData = result.data.compare.provider;
      let finalData = providerData;
      if (compareProviderData) {
        finalData = { ...providerData, ...compareProviderData };
      }
      setSelectedItems((prevState) => [...prevState, finalData]);
    } else {
      const selected = selectedItems;
      selected.splice(index, 1);
      setSelectedItems([...selected]);
    }
  };

  const removeItem = (locationId: string) => {
    setSelectedCheckbox((prevState) => ({
      checked: {
        ...prevState.checked,
        [locationId]: !prevState.checked[locationId],
      },
    }));
    const selectedProvider = selectedItems.find(
      (provider) => provider.locationId === locationId
    );
    addSelectedProvider(selectedProvider); // To remove selected provider from compare drawer
  };

  const handleDetailsOnClickCb = (
    providerId,
    options,
    customAttributesBlock: CustomAttributesBlock
  ) => {
    openTabOrNavigateOnClick(
      providerId,
      navigate,
      options,
      { chipValue, coverageType },
      setDetailsStore,
      customAttributesBlock
    );
  };

  const compareDrawerProps: CompareDrawerProps = {
    map,
    openCompare,
    setOpenCompare,
    selectedItems,
  };

  const allPaginationProps: AllPaginationProps = {
    pageSize,
    pageSizeOptions,
    paginationProps,
    setPageSize,
    setLastPageSize,
    gotoPage,
    nextPage,
    previousPage,
    pageNumber,
  };

  const mapOnPremKey = useConfig('MAPBOX_ONPREM_KEY');
  const mapKey = useConfig('MAPBOX_KEY');
  const mapOnPremURL = useConfig('MAPBOX_ONPREM_URL');

  const handleClickLocation = async (locationId, locationLng, locationLat) => {
    if (selectedId === locationId && !mobileScreen) return;
    const routeDirections = await getRoute(
      map.current,
      longitude,
      latitude,
      +locationLng,
      +locationLat,
      false,
      'driving-traffic',
      false,
      0,
      mapboxOnpremEnabled,
      mapOnPremKey,
      mapKey,
      mapOnPremURL
    );
    if (routeDirections) setDirections(routeDirections);
    setRouteEndCoords([locationLng, locationLat]);
    setSelectedId(locationId);
    adobeModalTrackEvent(Constants.MAP_VIEW_NAVIGATION_MODAL);
  };

  return (
    <div ref={containerRef} style={{ overflow: 'hidden' }}>
      <ErrorBoundary>
        {!mobileRouteView && (
          <PSXHeader
            breadcrumbs={breadcrumbs}
            dataTestId="view-all-on-submit"
            showChips={false}
            showChoosePCPHeader={choosePCP}
            showMemberSelection={choosePCP ? false : !mobileScreen}
            showSearchInputBackButton={choosePCP ? false : mobileScreen}
          />
        )}
        <PageContainer data-auto-testid="viewall-container">
          <React.Fragment>
            <MapViewWrapper>
              <MapView
                addSelectedProvider={addSelectedProvider}
                allPaginationProps={allPaginationProps}
                compareDrawerProps={compareDrawerProps}
                coverageType={coverageType}
                directions={directions}
                headers={headers}
                heading={heading}
                isLoading={isLoading}
                isMNR={isMNR}
                isPageNav={isPageNav}
                mobileRouteView={mobileRouteView}
                navigateToDirections={navigateToDirections}
                openMapview={openMapview}
                openShare={openShare}
                providerType={providerType}
                results={results}
                routeEndCoords={routeEndCoords}
                search={search}
                searchMethod={searchMethod}
                searchTerm={searchTerm}
                sectionType={sectionType}
                selectedCheckbox={selectedCheckbox}
                selectedFilters={serializedSelectedFilters}
                setDirections={setDirections}
                setMobileRouteView={setMobileRouteView}
                setNavigateToDirections={setNavigateToDirections}
                setOpenBoxContents={setOpenBoxContents}
                setOpenMapview={setOpenMapview}
                setOpenShare={setOpenShare}
                setRouteEndCoords={setRouteEndCoords}
                setSelectedCheckbox={setSelectedCheckbox}
                setSelectedItems={setSelectedItems}
                totalResultsCount={totalResultsCount}
              />
              <ShowShareFloatingSection
                closeAllDrawersAndOpenSuccessAlert={
                  closeAllDrawersAndOpenSuccessAlert
                }
                handleDetailsOnClickCb={handleDetailsOnClickCb}
                isShareAllResults={isShareAllResults}
                items={items}
                map={map}
                mobileScreen={mobileScreen}
                openBoxContents={openBoxContents}
                openShare={openShare}
                removeItem={removeItem}
                selectedItems={selectedItems}
                setIsShareAllResults={setIsShareAllResults}
                setOpenBoxContents={setOpenBoxContents}
                setOpenShare={setOpenShare}
                setSelectedCheckbox={setSelectedCheckbox}
                setSelectedItems={setSelectedItems}
              />
              <ShowCompareFloatingSection
                containerRef={containerRef}
                headers={headers}
                items={items}
                map={map}
                mobileScreen={mobileScreen}
                openCompare={openCompare}
                removeItem={removeItem}
                selectedCheckbox={selectedCheckbox}
                selectedItems={selectedItems}
                setItems={setItems}
                setOpenCompare={setOpenCompare}
                setSelectedCheckbox={setSelectedCheckbox}
                setSelectedItems={setSelectedItems}
              />
            </MapViewWrapper>
            {mobileScreen ? (
              <ContentWrapper>
                <MobileListView
                  addSelectedProvider={addSelectedProvider}
                  allPaginationProps={allPaginationProps}
                  gotoPage={gotoPage}
                  headers={headers}
                  isLoading={isLoading}
                  isMNR={isMNR}
                  map={map}
                  mobileRouteView={mobileRouteView}
                  navigateToDirections={navigateToDirections}
                  nextPage={nextPage}
                  openCompare={openCompare}
                  openMapview={openMapview}
                  openShare={openShare}
                  pageSize={pageSize}
                  paginationProps={paginationProps}
                  previousPage={previousPage}
                  results={results}
                  search={search}
                  searchTerm={searchTerm}
                  sectionType={sectionType}
                  selectLocation={handleClickLocation}
                  selectedCheckbox={selectedCheckbox}
                  selectedFilters={serializedSelectedFilters}
                  setNavigateToDirections={setNavigateToDirections}
                  setOpenBoxContents={setOpenBoxContents}
                  setOpenCompare={setOpenCompare}
                  setOpenMapview={setOpenMapview}
                  setOpenShare={setOpenShare}
                  setSelectedCheckbox={setSelectedCheckbox}
                  setSelectedItems={setSelectedItems}
                  totalResultsCount={totalResultsCount}
                />
              </ContentWrapper>
            ) : null}
          </React.Fragment>
        </PageContainer>
        <ViewAllDisclaimers
          isVisionCare={isVisionCare}
          sectionType={sectionType}
        />
      </ErrorBoundary>
    </div>
  );
};
