type LagoonData = {
  key: string;
  content: string;
};

export const getLagoonContent = (data: LagoonData[], key: string) =>
  data?.filter((row) => row?.key === key)[0]?.content || '';

type LagoonFlag = {
  key: string;
  isActive: boolean;
};
export const getLagoonFlag = (data: LagoonFlag[], key: string) =>
  data?.filter((row) => row?.key === key)[0]?.isActive || false;
