import { useLagoon } from '../../hooks/useLagoon';
import { isEvergreenDate, isSixtyDaysRemaining } from '../../utils/date.utils';
import { ConstantsLagoon } from '../ConstantsLagoon';
import { getFeatureFlag } from './getFeatureFlag';

export const checkTerminationDate = (
  displayTerminationDateEnabled,
  endDate
) =>
  displayTerminationDateEnabled &&
  endDate &&
  isSixtyDaysRemaining(endDate) &&
  !isEvergreenDate(endDate)
    ? true
    : false;

export const useCheckTerminationDate = (endDate) => {
  const featureFlags = useLagoon('feature-flags')();

  const displayTerminationDateEnabled: boolean = getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.DISPLAY_FUTURE_TERMINATION_DATE
  );

  if (displayTerminationDateEnabled) {
    if (endDate && isSixtyDaysRemaining(endDate) && !isEvergreenDate(endDate)) {
      return true;
    }
  }
  return false;
};
