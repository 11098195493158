import { Layout } from '@abyss/web/ui/Layout';
import { Skeleton } from '@abyss/web/ui/Skeleton';

type Props = {
  mobileScreen: boolean;
};

const SnackCardSkeletonDesktop = () => (
  <Layout.Stack
    alignItems="center"
    alignLayout="center"
    data-testid="skeleton-loader-cards"
    space="8"
  >
    <Skeleton height={72} variant="circular" width={72} />
    <Skeleton height={16} variant="rounded" width={159} />
    <Skeleton height={16} variant="rounded" width={159} />
    <Skeleton height={16} variant="rounded" width={159} />
    <Skeleton height={16} variant="rounded" width={159} />
  </Layout.Stack>
);

const SnackCardSkeletonMobile = () => (
  <Layout.Stack
    alignItems="center"
    alignLayout="center"
    data-testid="skeleton-loader-cards-mobile"
    space="8"
  >
    <Skeleton height={60} variant="circular" width={60} />
    <Skeleton height={16} variant="rounded" width={126} />
    <Skeleton height={16} variant="rounded" width={126} />
    <Skeleton height={16} variant="rounded" width={126} />
    <Skeleton height={16} variant="rounded" width={126} />
  </Layout.Stack>
);

export const SnackCardSkeleton = ({ mobileScreen }: Props) => {
  if (mobileScreen) {
    return <SnackCardSkeletonMobile />;
  }
  return <SnackCardSkeletonDesktop />;
};
