import { styled } from '@abyss/web/tools/styled';
import { Layout } from '@abyss/web/ui/Layout';

export const NavigationButtonContainer = styled(Layout.Group, {
  dynamic: ({ cssProps: { phoneScreen } }) => ({
    padding: phoneScreen ? '8px 16px' : '12px 16px',
  }),
  outline: '1px solid #E5E5E6',
});

export const DesktopVerticalDivider = styled('div', {
  background: '$gray4',
  height: '32px',
  margin: '0 32px',
  width: '1px',
  '@screen < $sm': {
    margin: '0',
    display: 'none',
  },
});

export const MobileVerticalDivider = styled('div', {
  display: 'none',
  '@screen < $sm': {
    display: 'unset',
    background: '$gray4',
    height: '24px',
    width: '0.5px',
    margin: 'auto',
    border: '0.5px solid $gray4',
  },
});

export const NavCloseButton = styled('button', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '32px',
  '@screen < $sm': {
    height: '40px',
  },
});

export const NavCloseButtonContainer = styled('div', {
  display: 'flex',
  height: '32px',

  '@screen < $sm': {
    height: '40px',
    marginLeft: '16px',
  },
});

export const NavigationButtonGroup = styled('div', {
  dynamic: ({ cssProps: { isWidget } }) => ({
    display: 'flex',
    justifyContent: isWidget ? 'space-between' : 'center',
    alignItems: 'center',
    '@screen < $sm': {
      background: isWidget ? '$primary2' : '$gray2',
      padding: '0 6px',
      height: '40px',
    },
  }),
  height: '32px',
  borderRadius: '4px',
});

export const NavigationButtonBoxContainer = styled('div', {
  width: '72px',
  '@screen < $sm': {
    width: '74.5px',
    '&:first-child': {
      width: '68.6px',
    },
    '&:last-child': {
      width: '68.6px',
    },
  },
});

export const NavigationButtonBox = styled('button', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '4px',
  margin: 'auto',
  padding: '4px 12px',
  '@screen < $sm': {
    width: '100%',
  },
});
