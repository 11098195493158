import { useTranslation } from 'react-i18next';

import {
  ATTRIBUTE_STATUS_TEXT,
  NULL_ATTRIBUTE_STATE,
} from '../../CompareDrawer/utility/compareDrawerConstants';
import { PrintViewProviderDetailsXSText } from './PrintExpandedCard.styled';

type Props = {
  pcpServicesAvaliable: boolean;
};

export const PrintPcpServiceAvailability = ({
  pcpServicesAvaliable,
}: Props) => {
  const { t } = useTranslation();

  let textToRender;

  if (pcpServicesAvaliable === true) {
    textToRender = t(ATTRIBUTE_STATUS_TEXT.PCP_SERVICES_AVAILABILE.TRUE);
  } else if (pcpServicesAvaliable === false) {
    textToRender = t(ATTRIBUTE_STATUS_TEXT.PCP_SERVICES_AVAILABILE.FALSE);
  } else {
    textToRender = t(NULL_ATTRIBUTE_STATE.PCP_SERVICES_AVAILABILE);
  }

  return (
    <PrintViewProviderDetailsXSText>
      {textToRender}
    </PrintViewProviderDetailsXSText>
  );
};
