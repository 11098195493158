export interface SearchStore {
  behavioralFacilityPrograms?: any;
  totalCount?: number;
  onlineRetailers?: boolean | string | null;
  searchRadius?: number;
  includeOrgTypeCodes?: string;
  excludeOrgTypeCodes?: string;
  requestType?: string;
}

export interface SearchState {
  searchStore: SearchStore;
  setSearchStore: (values: SearchStore) => void;
  clearSearchStore: (defaultValues?: SearchStore) => void;
}

export enum StoreKeys {
  TOTAL_COUNT = 'totalCount',
  ONLINE_RETAILERS = 'onlineRetailers',
  SEARCH_RADIUS = 'searchRadius',
  INCLUDE_ORG_TYPE_CODES = 'includeOrgTypeCodes',
  REQUEST_TYPE = 'requestType',
  SEARCH_STORE = 'searchStore',
  SET_SEARCH_STORE = 'setSearchStore',
  CLEAR_SEARCH_STORE = 'clearSearchStore',
}
