import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Layout } from '@abyss/web/ui/Layout';
import { Skeleton } from '@abyss/web/ui/Skeleton';

import { phoneOnly } from '../../../common/ConstantsStyles';
import {
  WidgetSkeletonContainer,
  WidgetSkeletonContainerHeader,
  WidgetSkeletonLayoutContainer,
  WidgetSkeletonSubContainer,
} from './DetailsWidgetHeader.styled';

const DetailsWidgetSkeletonMobile = () => (
  <WidgetSkeletonContainer>
    <WidgetSkeletonContainerHeader>
      <Layout.Stack data-testid="skeleton-loader-detail-widget-mobile">
        <Layout.Group space={16}>
          <Skeleton height={88} variant="circular" width={88} />
          <Layout.Stack space={16}>
            <Layout.Stack space={4}>
              <Skeleton height={24} width={210} />
              <Skeleton height={16} width={210} />
            </Layout.Stack>
            <Layout.Stack space={4}>
              <Skeleton height={16} width={210} />
              <Skeleton height={16} width={210} />
            </Layout.Stack>
          </Layout.Stack>
        </Layout.Group>
      </Layout.Stack>
    </WidgetSkeletonContainerHeader>
    <Layout.Stack alignLayout="center">
      <WidgetSkeletonLayoutContainer space={24}>
        <Skeleton height={30} width={97} />
        <Skeleton height={30} width={97} />
        <Skeleton height={30} width={97} />
      </WidgetSkeletonLayoutContainer>

      <WidgetSkeletonSubContainer>
        <Skeleton height={65} width={341} />
      </WidgetSkeletonSubContainer>

      <WidgetSkeletonSubContainer>
        <Skeleton height={65} width={341} />
      </WidgetSkeletonSubContainer>

      <WidgetSkeletonSubContainer>
        <Skeleton height={65} width={341} />
      </WidgetSkeletonSubContainer>

      <WidgetSkeletonSubContainer>
        <Skeleton height={65} width={341} />
      </WidgetSkeletonSubContainer>

      <WidgetSkeletonSubContainer>
        <Skeleton height={65} width={341} />
      </WidgetSkeletonSubContainer>

      <WidgetSkeletonSubContainer>
        <Skeleton height={65} width={341} />
      </WidgetSkeletonSubContainer>
    </Layout.Stack>
  </WidgetSkeletonContainer>
);

const DetailsWidgetSkeletonDesktop = () => (
  <WidgetSkeletonContainer>
    <WidgetSkeletonContainerHeader>
      <Layout.Stack data-testid="skeleton-loader-detail-widget">
        <Layout.Group space={16}>
          <Skeleton height={88} variant="circular" width={88} />
          <Layout.Stack space={16}>
            <Layout.Stack space={4}>
              <Skeleton height={24} width={464} />
              <Skeleton height={16} width={464} />
            </Layout.Stack>
            <Layout.Stack space={4}>
              <Skeleton height={16} width={464} />
              <Skeleton height={16} width={464} />
            </Layout.Stack>
          </Layout.Stack>
        </Layout.Group>
      </Layout.Stack>
    </WidgetSkeletonContainerHeader>
    <Layout.Stack alignLayout="center">
      <WidgetSkeletonLayoutContainer space={24}>
        <Skeleton height={30} width={173} />
        <Skeleton height={30} width={173} />
        <Skeleton height={30} width={173} />
      </WidgetSkeletonLayoutContainer>

      <WidgetSkeletonSubContainer>
        <Skeleton height={65} width={568} />
      </WidgetSkeletonSubContainer>

      <WidgetSkeletonSubContainer>
        <Skeleton height={65} width={568} />
      </WidgetSkeletonSubContainer>

      <WidgetSkeletonSubContainer>
        <Skeleton height={65} width={568} />
      </WidgetSkeletonSubContainer>

      <WidgetSkeletonSubContainer>
        <Skeleton height={65} width={568} />
      </WidgetSkeletonSubContainer>

      <WidgetSkeletonSubContainer>
        <Skeleton height={65} width={568} />
      </WidgetSkeletonSubContainer>

      <WidgetSkeletonSubContainer>
        <Skeleton height={65} width={568} />
      </WidgetSkeletonSubContainer>
    </Layout.Stack>
  </WidgetSkeletonContainer>
);

export const DetailsWidgetSkeleton = () => {
  const phoneScreen = useMediaQuery(phoneOnly);
  if (phoneScreen) {
    return <DetailsWidgetSkeletonMobile />;
  }
  return <DetailsWidgetSkeletonDesktop />;
};
