import find from 'lodash/find';
import { useContext } from 'react';

import {
  Constants,
  LAWW_PORTAL_NAME,
  OxfordPLNFilter,
  PLNFilter,
  plnPseudoCode,
} from '../common/Constants';
import { convertUrlParamsToObject } from '../common/Utils';
import { PortalContext } from '../context/PortalContext';
import { useLagoon } from '../hooks/useLagoon';

export const sleep = (delay: number) => {
  let sleepTimeout: NodeJS.Timeout;
  return new Promise((resolve) => {
    sleepTimeout = setTimeout(resolve, delay);
  }).finally(() => {
    if (sleepTimeout) {
      clearTimeout(sleepTimeout);
    }
  });
};

export const conditionalValueRender = (
  condition,
  renderforDefined,
  renderforUnDefined
) => (condition ? renderforDefined : renderforUnDefined);

export const searchResultBreadcrumbTitle = (providerTypeLocal) => {
  let titleAssigned;
  switch (providerTypeLocal) {
    case Constants.RESULT_SECTION.FACILITY:
      titleAssigned = 'Facility results';
      break;
    case Constants.RESULT_SECTION.PROVIDER_GROUPS:
      titleAssigned = 'Provider group results';
      break;
    default:
      titleAssigned = 'Provider results';
      break;
  }
  return titleAssigned;
};

export type LagoonData = {
  id: string;
  key: string;
  pseudoRollupCode: string;
  dntDescription: string;
  action: string;
  filterName: string;
  linkTarget: string;
  providerType: string;
  categoryChips: string;
  coverageType: string;
  isPseudoRollupSearch: boolean;
};

export const getValueFromPseudoRollupCodeTable = (
  pseudoRollupCode: string,
  LagoonData: LagoonData[],
  dntDescription: string,
  isOXMember?: boolean,
  oxfordPlnFlag?: boolean
): LagoonData | undefined => {
  const lagoonData = LagoonData.filter(
    (item: any) => item.pseudoRollupCode === pseudoRollupCode
  );

  if (pseudoRollupCode === plnPseudoCode) {
    if (oxfordPlnFlag && isOXMember) {
      return lagoonData.find(
        (item: any) => item.filterName === OxfordPLNFilter
      );
    } else {
      return lagoonData.find((item: any) => item.filterName === PLNFilter);
    }
  }

  if (lagoonData?.length > 1) {
    return lagoonData.find(
      (item: any) => item.dntDescription === dntDescription
    );
  }
  return lagoonData?.[0];
};

export const getPortalDefaultSearchParams = () => {
  const { portalData } = useContext(PortalContext);
  const portalConfig = portalData.portalConfig;

  if (!portalConfig) {
    return {};
  }

  const defaultPortalSearchParams = portalConfig?.homePage
    ?.replace('/', '')
    .replace('?', '');
  const urlParams = new URLSearchParams(defaultPortalSearchParams);
  return convertUrlParamsToObject(urlParams?.entries());
};

export const getUiMessageFromLagoon = (lagoonKey: string): string => {
  if (!lagoonKey?.trim()) return '';

  const lagoonData = useLagoon('ui-messaging')();
  const result = find(lagoonData, {
    key: lagoonKey,
  });
  return result?.message ?? '';
};

export const getBehavioralFacilityProgramsFromLagoon = (
  groupName: string
): any[] => {
  if (!groupName?.trim()) return [];

  const lagoonData = useLagoon('behavioral-facility-programs')();
  const result = (lagoonData ?? []).filter((item) => item.group === groupName);
  return result ?? [];
};

export const getIntersectionFromArrays = (arrays: any[]) => {
  if (!arrays || arrays.length === 0) return [];

  let intersectionResult = arrays[0];
  // Iterate through all arrays
  for (let i = 1; i < arrays.length; i++) {
    // Iterate through the current array
    intersectionResult = intersectionResult.filter((value: any) =>
      // Check if the value is in the next array
      arrays[i].includes(value)
    );
  }

  return intersectionResult || [];
};
const directoryLevelSwitch = {
  M: 'useMedicalNewRollUpCodes',
  B: 'useBHNewRollUpCodes',
  V: 'useVisionNewRollUpCodes',
  D: 'useDentalNewRollUpCodes',
};

export const supportNewCodes = (args) => {
  const { coverageType } = args;
  return args[directoryLevelSwitch[coverageType]];
};
