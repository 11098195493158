import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Card } from '@abyss/web/ui/Card';
import { CollapseProvider } from '@abyss/web/ui/CollapseProvider';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { mobileOnly } from '../../ConstantsStyles';
import {
  CollapseCardHeaderStyle,
  CollapseSection,
  HeadingContainer,
  StyledContentText,
  StyledHeadingText,
  TextContainer,
} from '../../FacilityDetailsTabs/FacilityDetailsAboutSection/FacilityDetailsAboutSection.styled';
import {
  InfoProviderContentContainer,
  SectionExpertiseContainer,
} from '../../ProviderDetailTabs/ProviderLocationsDetails/ProviderLocationsDetails.style';
import { capitalizeFirstLetter } from '../../Utils/capitalizeFirstLetter';

export const VirtualProviderGroupDetailExpertise = () => {
  const { t } = useTranslation();
  const mobile = useMediaQuery(mobileOnly);
  const expertiseTypes = [
    'Primary virtual care',
    'Behavioral virtual care',
    'Urgent virtual care',
  ].map((organization) =>
    capitalizeFirstLetter(organization?.toString().toLowerCase())
  );
  const providerSpecialities = [
    'Primary care',
    'Allergies',
    'Another specialty',
  ].map((organization) =>
    capitalizeFirstLetter(organization?.toString().toLowerCase())
  );
  return (
    <React.Fragment>
      {mobile ? (
        <InfoProviderContentContainer style={{ rowGap: 0 }}>
          <CollapseSection style={{ marginBottom: 0 }}>
            <CollapseProvider>
              <Card
                collapse
                css={CollapseCardHeaderStyle}
                header={t('VIRTUAL_GROUP_PROVIDER_ABOUT.AREAS_OF_EXPERTISE')}
                style={{ borderTop: 0 }}
              >
                <Card.Section
                  css={{
                    'abyss-card-section': {
                      paddingLeft: '0px',
                      paddingTop: '0px',
                      paddingBottom: '$md',
                    },
                  }}
                >
                  <TextContainer>
                    {expertiseTypes?.map((organizationType) => (
                      <StyledHeadingText
                        key={`organization_${organizationType}`}
                      >
                        {organizationType}
                      </StyledHeadingText>
                    ))}
                  </TextContainer>
                </Card.Section>
              </Card>
            </CollapseProvider>
          </CollapseSection>

          <CollapseSection style={{ marginTop: 0 }}>
            <CollapseProvider>
              <Card
                collapse
                css={CollapseCardHeaderStyle}
                header={t('VIRTUAL_GROUP_PROVIDER_ABOUT.PROVIDER_SPECIALITIES')}
                style={{
                  marginBottom: '12px',
                  borderBottom: 0,
                }}
              >
                <Card.Section
                  css={{
                    'abyss-card-section': {
                      paddingLeft: '0px',
                      paddingTop: '0px',
                      paddingBottom: '$md',
                    },
                  }}
                >
                  <TextContainer>
                    {providerSpecialities?.map((organizationType) => (
                      <StyledHeadingText
                        key={`organization_${organizationType}`}
                      >
                        {organizationType}
                      </StyledHeadingText>
                    ))}
                  </TextContainer>
                </Card.Section>
              </Card>
            </CollapseProvider>
          </CollapseSection>
        </InfoProviderContentContainer>
      ) : (
        <InfoProviderContentContainer>
          <SectionExpertiseContainer>
            <HeadingContainer>
              {t('VIRTUAL_GROUP_PROVIDER_ABOUT.AREAS_OF_EXPERTISE')}
            </HeadingContainer>
            {expertiseTypes?.map((organizationType) => (
              <StyledContentText key={`organization_${organizationType}`}>
                {organizationType}
              </StyledContentText>
            ))}
          </SectionExpertiseContainer>

          <SectionExpertiseContainer>
            <HeadingContainer>
              {t('VIRTUAL_GROUP_PROVIDER_ABOUT.PROVIDER_SPECIALITIES')}
            </HeadingContainer>
            {providerSpecialities?.map((organizationType) => (
              <StyledContentText key={`organization_${organizationType}`}>
                {organizationType}
              </StyledContentText>
            ))}
          </SectionExpertiseContainer>
        </InfoProviderContentContainer>
      )}
    </React.Fragment>
  );
};
