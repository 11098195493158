import { config } from '@abyss/web/tools/config';

import { useCustomQuery } from '../useCustomQuery';
import SHARE_ALL_RESULTS from './ShareAllResults.graphql';

export const useShareAllResults = (options) => {
  const [queryResult, queryAction] = useCustomQuery(SHARE_ALL_RESULTS, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'shareAllResults',
    onCompleted: (result) => {
      if (options?.onCompleted) {
        options.onCompleted(result?.data);
      }
    },
  });

  const parsedResult = {
    ...queryResult,
    data: queryResult?.data,
  };

  return [parsedResult, queryAction];
};
