import { Popover } from '@abyss/web/ui/Popover';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import isEmpty from 'lodash/isEmpty';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsOverflow } from '../../../../../../../../../hooks/useIsOverflow';
import {
  StyledAvoidWrappingDiv,
  popoverStyle,
} from '../../../../utility/compareDrawer.styled';
import { NULL_ATTRIBUTE_STATE } from '../../../../utility/compareDrawerConstants';

type Props = {
  specialty: string;
  mobile: boolean;
};

export const SpecialtyAttribute = ({ specialty, mobile }: Props) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);

  const isAttributeEmpty: boolean = isEmpty(specialty);

  const providerSpecialty: string = isAttributeEmpty
    ? t(NULL_ATTRIBUTE_STATE.SPECIALTY)
    : specialty;

  const Specialty = (
    <StyledAvoidWrappingDiv
      cssProps={{ isOverflow, isAttributeEmpty }}
      ref={containerRef}
    >
      {providerSpecialty}
    </StyledAvoidWrappingDiv>
  );

  if (mobile) {
    return (
      <React.Fragment>
        {isOverflow ? (
          <Popover
            content={providerSpecialty}
            css={popoverStyle}
            showClose={false}
            tabIndex="-1"
            width={150}
          >
            {Specialty}
          </Popover>
        ) : (
          Specialty
        )}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {isOverflow ? (
        <Tooltip content={providerSpecialty} position="top" positionOffset={8}>
          {Specialty}
        </Tooltip>
      ) : (
        Specialty
      )}
    </React.Fragment>
  );
};
