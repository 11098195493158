import { BhProviderIcons } from '../models/Lagoon';

const filterBhSpecialtyIds = (
  bhSpecialtyCodes: any,
  specialitiesIds: string[]
) => {
  const allSpecialtyCodes: string[] = [];
  bhSpecialtyCodes.filter((allSpecialties) => {
    specialitiesIds?.filter((item) => {
      if (allSpecialties?.specialtyCode?.includes(item)) {
        allSpecialtyCodes.push(allSpecialties?.codes);
      }
    });
  });

  return [...new Set(allSpecialtyCodes.flat())];
};

const filterBhSpecialtyNames = (
  bhSpecialtyCodes: any,
  specialitiesNames: string[]
) => {
  const allSpecialtyCodes: string[] = [];
  bhSpecialtyCodes.filter((allSpecialties) => {
    specialitiesNames?.filter((item) => {
      if (allSpecialties?.name?.toLowerCase() === item?.toLowerCase()) {
        allSpecialtyCodes.push(allSpecialties?.codes);
      }
    });
  });

  return [...new Set(allSpecialtyCodes.flat())];
};

export const getBhIcon = (
  specialitiesIds: string[] = [],
  specialitiesNames: string[] = [],
  bhProviderIcons: BhProviderIcons[] = [],
  bhSpecialtyCodes: any[] = []
) => {
  const stethoscopeIds: string[] = [];
  let iconType = 'mental_health';

  bhProviderIcons.filter((item) => {
    if (item?.icon?.toLowerCase() === 'stethoscope' && item?.isActive) {
      const codesRemoveWhitespace = item?.specialtyCode?.replace(/\s/g, '');
      codesRemoveWhitespace
        ?.split(',')
        .map((item) => stethoscopeIds.push(item));
    }
  });

  const allSpecialtyCodes =
    specialitiesIds.length !== 0
      ? filterBhSpecialtyIds(bhSpecialtyCodes, specialitiesIds)
      : filterBhSpecialtyNames(bhSpecialtyCodes, specialitiesNames);

  allSpecialtyCodes.map((item) => {
    if (stethoscopeIds?.includes(item)) {
      iconType = 'stethoscope';
    }
  });

  return iconType;
};
