import { config } from '@abyss/web/tools/config';

import { useCustomQuery } from '../useCustomQuery';
import GET_ENCRYPTED_PAYLOAD from './AnnexEncryptedPayload.graphql';

export const useAnnexEncryptedPayload = (options) =>
  useCustomQuery(GET_ENCRYPTED_PAYLOAD, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    requestPolicy: 'no-cache',
    accessor: 'annexEncryptedPayload',
  });
