import { styled } from '@abyss/web/tools/styled';

export const PageContainer = styled('div', {
  padding: '0px',
  backgroundColor: '$mapViewContainerBgColor'
});

export const MapViewWrapper = styled('div', {
  maxWidth: '1440px',
  marginLeft: 'calc((100vw - 1200px)/2) ',
  marginRight: '24px',
  padding: '24px 0',

  '@media (max-width: 1248px)': {
    marginLeft: '24px',
    marginRight: '24px',
  },
  '@screen < $md': {
    marginLeft: '16px',
    marginRight: '16px',
    padding: '16px 0',
  },
  '@screen < $sm': {
    padding: '0',
    margin: '0',
  },
});
