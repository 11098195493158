import {
  GuestMemberInfo,
  GuestPortalMemberInfo,
} from './GuestPortalMemberInfo';
import { Coverage, CoverageEffDateRange, Demographics, Eligibility, MemberCoverage, MemberInfo, PortalMemberInfo } from './PortalMemberInfo';
import { Name, TypeCode } from './PortalMemberInfo';
// PSX Data

//Tier 1 mock member info
const name: Name = {
  firstName: 'OL\' JONESY',
  lastName: 'McGEE',
};

const demographics: Demographics = {
  name,
};

const coverageEffDateRange: CoverageEffDateRange = {
  startDate: '',
  endDate: '2099-12-31',
};

const typeCode: TypeCode = {
  desc: 'BEHAVIORAL CARVE-OUT',
  coverageTypeCode: 'OBH',
};

const coverageType: Coverage = {
  coverageEffDateRange,
  typeCode,
  reciprocityId: '100'
};

const memberHealthCoverage: MemberCoverage = {
  coverageType: [coverageType],
};

const eligibility: Eligibility = {
  memberHealthCoverage,
};

export const tier1MemberInfo: MemberInfo = {
  demographics,
  eligibility: [eligibility],
  lineOfBusiness: 'OBH',
  policyNumber: '100',
};

export const mockTier1PortalMemberInfo: PortalMemberInfo = {
  memberInfo: [tier1MemberInfo],
};

//Tier 0 Mock Member Info
export const guestMemberInfo: GuestMemberInfo = {
  eligibility: [eligibility],
  lineOfBusiness: 'OBH',
  policyNumber: '100',
};

export const mockGuestPortalMemberInfo: GuestPortalMemberInfo = {
  memberInfo: [guestMemberInfo],
};
