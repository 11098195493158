import { Button } from '@abyss/web/ui/Button';
import { Text } from '@abyss/web/ui/Text';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { adobeCompareDrawerLinkLocation } from '../../utility/compareDrawerConstants';

type Props = {
  isExpandedCardOpen: boolean;
  selectedCount: number;
  setIsExpandedCardOpen: Function;
  setIsCompareViewOpen: Function;
  isCompareViewOpen: Boolean;
};
export const CompareButton = ({
  isExpandedCardOpen,
  selectedCount,
  setIsExpandedCardOpen,
  setIsCompareViewOpen,
  isCompareViewOpen,
}: Props) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedCount < 2) {
      setIsCompareViewOpen(false);
    }
  }, [selectedCount]);

  const handleCompareOrUpdateClick = () => {
    adobeLinkTrackEvent({
      name: `${
        isCompareViewOpen && isExpandedCardOpen
          ? 'update button'
          : 'compare button'
      }`,
      location: `modal:${adobeCompareDrawerLinkLocation}`,
      type: 'internal',
    });

    if (isCompareViewOpen && isExpandedCardOpen) {
      setIsCompareViewOpen(false);
      setIsExpandedCardOpen(false);
    } else {
      setIsExpandedCardOpen(true);
      if (setIsCompareViewOpen) {
        setIsCompareViewOpen(true);
      }
    }
  };

  return (
    <Button
      align="start"
      css={{
        'abyss-button-root': {
          '@screen < $sm': {
            width: '146px',
            padding: '12px 15px',
            margin: '13px auto 16px auto',
            backgroundColor: selectedCount > 0 ? '$primary1' : '$gray2',
            minWidth: '343px',
            height: '42px',
            borderRadius: '24px',
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex',
          },
          '@screen >= $sm': {
            marginTop: '12px',
            marginLeft: '24px',
          },
        },
      }}
      data-auto-testid="compare-drawer-compare-button"
      data-testid="compare-drawer-compare-button"
      disabled={selectedCount < 2}
      onClick={handleCompareOrUpdateClick}
      variant="solid"
    >
      <Text
        css={{
          'abyss-text-root': {
            fontSize: '$md',
            paddingLeft: '5px',
            fontWeight: '$semibold',
            lineHeight: '18px',
            textAlign: 'center',
            color: selectedCount > 1 ? '$primary2' : '$gray5',
          },
        }}
      >
        {isCompareViewOpen && isExpandedCardOpen
          ? t('Update provider selections')
          : `${t('COMPARE_BUTTONS.COMPARE')} (${selectedCount})`}
      </Text>
    </Button>
  );
};
