import { styled } from '@abyss/web/tools/styled';
import { Drawer } from '@abyss/web/ui/Drawer';

export const TieredHeader = {
  color: '$primary1',
  fontSize: '25.63px !important',
  fontStyle: 'normal',
  lineHeight: '32px !important',
  '@screen < $sm': {
    fontSize: '20.25px !important',
    lineHeight: '24px !important',
    marginTop: '3px',
  },
};

export const TieredContentStyle = {
  color: '#4B4D4F',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '24px',
  '@screen < $sm': {
    fontSize: '16px',
    lineHeight: '20px',
  },
};
export const TextWrapper = styled('div', {
  maxWidth: '840px',
});

export const HeadingWrapper = styled('div', {
  display: 'inline-flex',
  marginBottom: '4px',
  '.abyss-icon': {
    margin: '2px 8px 0 0',
    '@screen < $sm': {
      margin: '0 4px 0 0',
    },
  },
});
export const TieredLinkWrapper = styled('div', {
  padding: '8px 0 8px 0',
  '@screen < $sm': {
    padding: '16px 0 0 0 !important',
  },
});

export const TieredLinkStyle = {
  color: '#196ECF',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '700',
  lineHeight: '20px',
  '@screen < $sm': {
    fontSize: '14.22px !important',
    lineHeight: '18px',
  },
};

export const TieredDrawerStyles = {
  'abyss-modal-content-container': {
    borderRadius: '20px 20px 0 0',
    height: 'auto !important',
  },
  'abyss-modal-body': {
    margin: '16px 24px 16px 24px',
    color: '$gray8',
    whiteSpace: 'pre-line',
    fontSize: '16px',
    fontWeight: '500',
  },
  'abyss-modal-close-button': { margin: '8px 10px 0 0' },
  'abyss-modal-content-title': {
    paddingTop: '2px',
    fontFamily: '$primary !important',
    fontSize: '20.25px !important',
    color: '#323334 !important',
    lineHeight: '24px !important',
    fontWeight: '700 !important',
  },
  'abyss-modal-header-container': {
    padding: '16px 24px 0px 24px',
  },
};

export const BenefitsBadgeDrawerStyles = styled(Drawer, {
  '.abyss-modal-content-container': {
    borderRadius: '20px 20px 0 0',
    height: 'auto !important',
  },
  '.abyss-modal-body': {
    borderTop: '1px solid $gray4',
    padding: '$md',
    color: '$gray8',
  },
  '.abyss-modal-close-button': {
    margin: '8px 10px 0 0',
  },
  '.abyss-modal-content-title': {
    fontFamily: '$primary !important',
    fontSize: '20px !important',
    color: '$primary1',
    lineHeight: '24px !important',
    fontWeight: '$bold !important',
  },
  '.abyss-modal-header-container': {
    padding: '0 16px 16px 16px',
  },
});
