import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Constants } from '../../../../common/Constants';
import { mobileOnly } from '../../../../common/ConstantsStyles';
import { ContentWrapper } from '../../../../common/ContentWrapper';
import { ContainerForAllSectionsStyled } from '../../../../common/ProviderDetailTabs/ProviderDetailTabs.styled';
import {
  getClaimOrEligibilitySystemTypeCode,
  getCurrentMember,
} from '../../../../utils/user.utils';
import {
  StyledVisionCareBox,
  StyledVisionCareButton,
} from './ExternalVisionCard.styled';

export const ExternalVisionCard = () => {
  const currentMember = getCurrentMember();
  const mobileScreen = useMediaQuery(mobileOnly);
  const { t } = useTranslation();

  const visionCoverage = getClaimOrEligibilitySystemTypeCode(
    currentMember,
    'V'
  );
  const isArizona = visionCoverage?.situsState === 'AZ';

  const visionUrl = isArizona
    ? Constants.CNS_VISION_URL.ARIZONA
    : Constants.CNS_VISION_URL.DEFAULT;
  const providerName = isArizona
    ? t('CNS_VISION_CARD.PROVIDER_NAME_AZ')
    : t('CNS_VISION_CARD.PROVIDER_NAME');

  const boxWidth = mobileScreen ? '100%' : '588px';

  return (
    <ContainerForAllSectionsStyled>
      <ContentWrapper>
        <StyledVisionCareBox
          color="$tint2"
          padding="16px 16px 16px 24px"
          width={boxWidth}
        >
          <Layout.Stack alignItems="left" space={0}>
            <Text
              color="$primary1"
              fontWeight="$bold"
              size={mobileScreen ? '$md' : 20.25}
            >
              {t('CNS_VISION_CARD.HEADING')}
            </Text>
            <Text
              color="$gray7"
              fontWeight={mobileScreen ? '$normal' : '$medium'}
              size={mobileScreen ? '$sm' : '$md'}
            >
              {t('CNS_VISION_CARD.CONTENT')} {providerName}
            </Text>
            <StyledVisionCareButton
              after={<IconMaterial color="$white" icon="open_in_new" />}
              data-testid="cns-vision-redirect-button"
              href={visionUrl}
            >
              {t('CNS_VISION_CARD.BUTTON_CONTENT')} {providerName}
            </StyledVisionCareButton>
          </Layout.Stack>
        </StyledVisionCareBox>
      </ContentWrapper>
    </ContainerForAllSectionsStyled>
  );
};
