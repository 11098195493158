import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Text } from '@abyss/web/ui/Text';
import cloneDeep from 'lodash/cloneDeep';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import update from 'lodash/update';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { adobeLinkTrackEvent } from '../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { useLocation } from '../../../../hooks/useLocation';
import { refactorPlaceName } from '../../../../utils/locationSearch.util';
import {
  FindUserLocationContainer,
  StyledLocationError,
  StyledLocationHeader,
  StyledLocationItems,
  StyledLocationSearch,
} from './LocationInput.styled';

type Props = {
  findUserLocation?: Function;
  setCustomLocation?: (a: object) => void;
};

export const LocationInput = ({
  findUserLocation = () => {},
  setCustomLocation = () => {},
}: Props) => {
  const { t } = useTranslation();
  const locationSearchInputDataTestId: string = 'location-search-input';
  const locationForAnalytics: string = 'change search location';

  const [isLoading, setIsLoading] = useState(false);
  const [gqlSuggestions, setgqlSuggestions] = useState<object[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  const [, getLocation] = useLocation({
    onCompleted: (result: {
      data: {
        location: {
          features: [{ place_name: string; id: string }];
        };
      };
    }) => {
      const resultSuggestions = cloneDeep(result?.data?.location?.features);
      resultSuggestions?.map((loc) =>
        update(loc, 'place_name', () => refactorPlaceName(loc.place_name))
      );
      const findSuggestion =
        resultSuggestions.length > 0
          ? resultSuggestions.concat({
              place_name: 'find',
              id: 'find',
            })
          : [];

      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      !isEmpty(resultSuggestions)
        ? (setgqlSuggestions([{ items: findSuggestion, section: 'Search' }]),
          setErrorMessage(''))
        : (setgqlSuggestions([]),
          setErrorMessage(t('LOCATION_SEARCH.ERR_DEC_RELEASE')));
    },
  });

  const handleLocationInputChange = (inputValue) => {
    if (inputValue?.trim().length) {
      setIsLoading(true);
    } else {
      setgqlSuggestions([]);
      setErrorMessage('');
    }
  };

  const handleLocationChange = async (loc: string) => {
    await getLocation({
      variables: {
        address: loc,
        countySearchEnabled: true,
      },
    });
  };

  const debounceApiFilteringSearch = useCallback(
    debounce(async (inputValue, selectedItem) => {
      if (inputValue && !selectedItem && inputValue.trim().length) {
        await handleLocationChange(inputValue);
      }
      setIsLoading(false);
    }, 300),
    []
  );

  const adobeLinkTrackEventFindLocation = () =>
    adobeLinkTrackEvent({
      name: 'find my location',
      location: `modal:${locationForAnalytics}`,
      type: 'internal',
    });

  const adobeLinkTrackEventSuggestedLocation = () =>
    adobeLinkTrackEvent({
      name: 'suggested location',
      location: `modal:${locationForAnalytics}`,
      type: 'internal',
    });

  return (
    <Layout.Stack
      className="full-width-layout-stack-search-input"
      css={{
        '&.full-width-layout-stack-search-input': {
          'div:first-child': {
            width: '100%',
          },
        },
      }}
    >
      <StyledLocationSearch
        apiFiltering={debounceApiFilteringSearch}
        customRender={(suggestion) =>
          // eslint-disable-next-line no-nested-ternary
          suggestion?.section === 'Search' ? (
            <StyledLocationHeader>
              {t(`LOCATION_SEARCH.DID_YOU_MEAN`)}
            </StyledLocationHeader>
          ) : suggestion?.place_name === 'find' ? (
            <StyledLocationItems>
              <FindUserLocationContainer width="100%">
                <Layout.Group>
                  <IconMaterial
                    css={{ marginRight: '10px' }}
                    icon="my_location"
                  />
                  <Text color="$interactive1" fontWeight="$bold" size="sm">
                    {t('Find my location')}
                  </Text>
                </Layout.Group>
              </FindUserLocationContainer>
            </StyledLocationItems>
          ) : (
            <StyledLocationItems grow space={0}>
              <Text color="$accent1" fontWeight="$bold" size="$sm">
                {suggestion.place_name}
              </Text>
            </StyledLocationItems>
          )
        }
        data-auto-testid={locationSearchInputDataTestId}
        data-testid={locationSearchInputDataTestId}
        hideLabel
        isLoading={isLoading}
        keys={['value']}
        onClear={() => {
          setgqlSuggestions([]);
          setErrorMessage('');
          setCustomLocation({});
        }}
        onInputChange={handleLocationInputChange}
        onSearch={(newLocation) => {
          if (newLocation.place_name === 'find') {
            adobeLinkTrackEventFindLocation();
            findUserLocation();
          } else {
            adobeLinkTrackEventSuggestedLocation();
            setCustomLocation(newLocation);
          }
        }}
        options={gqlSuggestions}
        placeholder={t('LOCATION_SEARCH.PLACEHOLDER')}
        rounded
      />
      {errorMessage ? (
        <StyledLocationError alignItems="top" className="error-message">
          <IconMaterial
            className="error-icon"
            color="$error1"
            icon="error"
            size="$sm"
          />
          <Text color="$error1" size="14.22px">
            {errorMessage}
          </Text>
        </StyledLocationError>
      ) : (
        ''
      )}
    </Layout.Stack>
  );
};
