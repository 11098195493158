/* eslint-disable no-nested-ternary */
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { storage } from '@abyss/web/tools/storage';
import { Text } from '@abyss/web/ui/Text';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { isMnrMember } from '../../../common/Utils/memberUtils/memberUtils';
import { PortalContext } from '../../../context/PortalContext';
import {
  getFullName,
  getInitials,
  menuItems,
} from '../../../utils/psxHeader.utils';
import { getCurrentMember } from '../../../utils/user.utils';
import { Constants } from '../../Constants';
import { phoneOnly } from '../../ConstantsStyles';
import { showMemberSelector } from '../../Utils';
import { MemberSelection } from '../MemberSelection';
import { WelcomeMessageScreen } from '../MemberSelection/MemberSelection.styled';
import { MemberInfo } from '../ObapiDemo/memberProfile';
import {
  Avatar,
  WelcomeMessage,
  WelcomeMessageContainer,
  WelcomeMessageName,
  WelcomeMessageNameContainer,
} from './MemberSelectionHeader.styled';

type Props = {
  activeUser: MemberInfo;
  form: any;
  memberProfiles: MemberInfo[];
  setActiveMemberIndex: (a: number) => void;
};

export const MemberSelectionHeader = ({
  activeUser,
  form,
  memberProfiles,
  setActiveMemberIndex,
}: Props) => {
  const { t } = useTranslation();
  const { LOGGED_IN_MEMBER_INDEX } = Constants.STORAGE_KEYS.SESSION;
  const loggedInMemberIndex = storage.session.get(LOGGED_IN_MEMBER_INDEX);
  const loggedInMember = memberProfiles[loggedInMemberIndex];
  const activeUserFullName = getFullName(activeUser);
  const mobileScreen = useMediaQuery(phoneOnly);
  const { portalData } = useContext(PortalContext);

  const welcomeMessageText = portalData?.portalConfig?.isGuestUser
    ? t("Let's find care")
    : t("Let's find care for");

  const loggedInMemberRelationshipTypecode =
    loggedInMember?.demographics?.relationshipTypeCode?.code;

  return (
    <WelcomeMessageContainer
      backgroundColor="$bgSubHeaderColor"
      data-auto-testid="search-home-message-container"
      data-testid="search-home-message-container"
    >
      {!portalData?.portalConfig?.isGuestUser ? (
        <Avatar>
          <div>{getInitials(activeUserFullName)}</div>
        </Avatar>
      ) : null}
      <WelcomeMessageNameContainer
        data-auto-testid="search-home-name-container"
        data-testid="search-home-name-container"
      >
        <WelcomeMessage
          data-auto-testid="search-home-welcome-message"
          data-testid="search-home-welcome-message"
        >
          <WelcomeMessageName
            data-auto-testid="search-home-message-name"
            data-testid="search-home-message-name"
          >
            <div>
              <Text>{welcomeMessageText}</Text>
              {showMemberSelector(
                loggedInMemberRelationshipTypecode,
                memberProfiles,
                isMnrMember(getCurrentMember()?.lineOfBusiness)
              ) ? (
                <MemberSelection
                  data-auto-testid="member-selection-modal"
                  data-testid="member-selection-modal"
                  form={form}
                  menuItems={menuItems(memberProfiles)}
                  name={activeUser?.demographics?.name?.firstName}
                  setActiveMemberIndex={setActiveMemberIndex}
                />
              ) : mobileScreen ? (
                <WelcomeMessageScreen>
                  {activeUser?.demographics?.name?.firstName}
                </WelcomeMessageScreen>
              ) : (
                <Text>{activeUser?.demographics?.name?.firstName}</Text>
              )}
            </div>
          </WelcomeMessageName>
          <span id="memberSelectionButton" style={{ display: 'none' }}>
            Change member name
          </span>
        </WelcomeMessage>
      </WelcomeMessageNameContainer>
    </WelcomeMessageContainer>
  );
};
