import {
  CareCategories,
  CoverageTypesCodes,
  ReverseCoverageTypesCodes,
} from '../common/Constants';
import { isCnsMember } from '../common/Utils/memberUtils/memberUtils';

const validateBenefitEligibility = (
  benefitEligibility: string | undefined,
  memberVals: string | string[]
): boolean => {
  if (!benefitEligibility) {
    return true;
  }
  const requirementsAr = benefitEligibility.split(',');
  return (
    requirementsAr.filter((tabReq) => memberVals?.includes(tabReq.trim()))
      ?.length > 0
  );
};

const validatePlanPurchaseProvisions = (
  benefitPlanPurchaseProvisions: string | undefined,
  eapPlanPurchaseIds
) => {
  if (!benefitPlanPurchaseProvisions) {
    return true;
  }
  const requirementsAr = benefitPlanPurchaseProvisions?.split(',');
  return requirementsAr.some((e) => eapPlanPurchaseIds?.includes(e));
};

const isBenefitSuppressed = (
  benefitEligibility: string | undefined,
  memberVals: string
): boolean =>
  !!benefitEligibility
    ?.split(',')
    ?.map((x) => x.trim())
    ?.includes(memberVals);

const validateNumberOfVisits = (
  eapVisits: string,
  noOfVisits: string
): boolean => {
  if (!eapVisits) {
    return true;
  }
  if (!noOfVisits) {
    return false;
  }
  const pendingVisits = parseInt(noOfVisits);
  const visits = parseInt(eapVisits);
  if (isNaN(pendingVisits) || isNaN(visits)) {
    return false;
  }
  return pendingVisits >= visits;
};

const constructNumberOfVisits = (
  noOfVisits: string,
  benefitDesc: string
): string => {
  if (benefitDesc && benefitDesc.includes('[x]') && noOfVisits !== '0') {
    return benefitDesc.replaceAll('[x]', noOfVisits);
  }
  return benefitDesc;
};

export const getBenefitsForTab = (
  benefits,
  activeChip,
  currentMember,
  memberCoverages,
  memberMarkets,
  memberPolicy,
  memberPVRC,
  eapPlanPurchaseProvisions,
  numberOfVisits,
  memberHPBP
) => {
  const eapPlanPurchaseIds = eapPlanPurchaseProvisions?.map((e) => e?.id) || [];
  const isCNS = isCnsMember(currentMember?.lineOfBusiness);

  const checkBasedOnLob = (benefit) => {
    if (isCNS) {
      return benefit.groupId?.trim()
        ? validateBenefitEligibility(benefit.groupId?.trim(), memberPolicy)
        : true;
    }

    return (
      validateBenefitEligibility(benefit.coverageTypes, memberCoverages) &&
      validateBenefitEligibility(benefit.marketCodes, memberMarkets) &&
      (benefit.policyNumber?.trim() &&
      benefit.policyNumber?.trim().toLowerCase() !== 'all'
        ? validateBenefitEligibility(benefit.policyNumber, memberHPBP) ||
          validateBenefitEligibility(benefit.policyNumber?.trim(), memberPolicy)
        : true) &&
      (memberPVRC &&
      benefit.pvrcCodes?.trim() &&
      benefit.pvrcCodes?.trim().toLowerCase() !== 'all'
        ? validateBenefitEligibility(benefit.pvrcCodes, memberPVRC)
        : true) &&
      validatePlanPurchaseProvisions(
        benefit.planPurchaseProvisions,
        eapPlanPurchaseIds
      ) &&
      validateNumberOfVisits(benefit.eapVisits, numberOfVisits) &&
      !isBenefitSuppressed(benefit.suppressedPolicyIds, memberPolicy)
    );
  };

  return benefits
    .filter(
      (benefit) =>
        benefit.activeTabs
          ?.split(',')
          .find(
            (activeTab) =>
              activeTab?.trim().toLowerCase() === activeChip.toLowerCase()
          ) &&
        benefit.isActive &&
        checkBasedOnLob(benefit)
    )
    .map((benefit) => {
      if (!isCNS && benefit.eapVisits) {
        benefit.benefitDescription = constructNumberOfVisits(
          numberOfVisits,
          benefit.benefitDescription
        );
      }

      return benefit;
    });
};

export const suppressBHForPlans = (
  benefits,
  selectedChipValue,
  eligibility
) => {
  if (
    [CareCategories.BEHAVIORAL_HEALTH, CareCategories.ALL].includes(
      selectedChipValue
    )
  ) {
    const memberCoverage = eligibility
      .flatMap((coverage) => coverage?.memberHealthCoverage?.coverageType)
      .find(
        (coverageType) =>
          coverageType?.typeCode?.coverageTypeCode ===
          ReverseCoverageTypesCodes[CoverageTypesCodes.BEHAVIORAL_HEALTH]
      );

    return benefits.filter(
      (benefit) =>
        !benefit.suppressedProductIds
          ?.split(',')
          ?.includes(memberCoverage?.productID)
    );
  }

  return benefits;
};

export const isMnrTieredPlan = (tierBenefits, rulesPackageKey) => {
  const hpbpCodes = tierBenefits?.map(({ hpbpCode }) => hpbpCode);
  return hpbpCodes?.includes(rulesPackageKey) ? true : false;
};

export const validateMnrTieredBenefitEligibility = (
  currentMember,
  tierBenefits,
  providerType
): boolean => {
  const { rulesPackageKey = '' } = currentMember || {};
  return !!tierBenefits?.find(
    ({ hpbpCode, providerType: eligibleProviderType }) =>
      hpbpCode === rulesPackageKey &&
      eligibleProviderType
        ?.toLowerCase()
        ?.replaceAll('_', '')
        ?.split(',')
        ?.includes(
          providerType?.toLowerCase()?.replaceAll(' ', '')?.replaceAll('_', '')
        )
  );
};
