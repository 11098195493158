import { event } from '@abyss/web/tools/event';

export const adobeSearchResultClickEvent = (input) => {
  const { customAttributesBlock } = input;
  event('SEARCH_RESULT_CLICK_EVENT', {
    search: {
      term: input.term?.toLowerCase() ?? '',
      type: 'provider',
      filters: input.filters ?? '',
      linkName: input.linkName?.toLowerCase().replaceAll('-', ' '),
      linkPosition: input.linkPosition,
      linkLocation: input.linkLocation?.toLowerCase().replaceAll('-', ' '),
    },
    component: {
      name: '',
    },
    ...(customAttributesBlock && { customAttributes: customAttributesBlock }),
  });
};
