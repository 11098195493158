import { styled } from '@abyss/web/tools/styled';
import { Text } from '@abyss/web/ui/Text';

export const TextCostIndicator = styled(Text, {
  '&.abyss-text-root': {
    fontStyle: 'normal',
    fontWeight: '700',
    letterSpacing: '1.5px',
    '@screen >= $md': {
      fontSize: '18px',
      lineHeight: '24px' /* 133.333% */,
    },
    '@screen < $md': {
      fontSize: '12.64px',
      lineHeight: '16px' /* 126.582% */,
    },
  },
});

export const BelowAverageTextColor = {
  color: '$success1', // Hex: #007000
};

export const AverageTextColor = {
  color: '#0248AC', // Blue
};

export const AboveAverageTextColor = {
  color: '#AA2E23', // red
};

export const PDFTextColor = {
  color: '#333333', // #929496
};

export const DefaultTextColor = {
  color: '$gray5', // #929496
};
