import { useForm } from '@abyss/web/hooks/useForm';
import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { Heading } from '@abyss/web/ui/Heading';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProviderLocation, ProviderType } from '../../models/ProviderDetails';
import { phoneOnly } from '../ConstantsStyles';
import { CorrectInformation } from './CorrectInformation';
import { SuggestEditFooter } from './SuggestEditFooter';
import { SuggestEditProviderCard } from './SuggestEditProviderCard';
import { ConfirmSuggestions } from '.';

type Props = {
  suggestEditDrawer: boolean;
  setSuggestEditDrawer: Function;
  providerLocations: ProviderLocation[];
  providerType: ProviderType;
  providerName: string;
  inNetwork: string;
  avatar: string | undefined;
};

export const SuggestEditDrawerCard = ({
  suggestEditDrawer,
  setSuggestEditDrawer,
  providerLocations,
  providerType,
  providerName,
  inNetwork,
  avatar,
}: Props) => {
  const { t } = useTranslation();
  const mobileScreen = useMediaQuery(phoneOnly);
  const [page, setPage] = useState(0);
  const [isChecked, setChecked] = useState(false);

  const [selectedLocationValue, setSelectedLocationValue] = useState(
    providerLocations[0]
  );

  const form = useForm();

  const componentsList = [
    <CorrectInformation
      inNetwork={inNetwork}
      providerName={providerName}
      providerType={providerType}
      selectedLocationValue={selectedLocationValue}
    />,
    <ConfirmSuggestions providerType={providerType} />,
  ];

  const handleDrawerClose = () => {
    setSuggestEditDrawer(false);
    setPage(0);
    setChecked(false);
  };

  return (
    <FormProvider
      data-auto-testid="suggest-edit-form"
      data-testid="suggest-edit-form"
      descriptorsDisplay="column"
      state={form}
    >
      <Drawer
        css={{
          'abyss-modal-close-button': {
            marginTop: '$md',
            marginRight: '$sm',
            top: '8px',
          },
        }}
        isOpen={suggestEditDrawer}
        onClose={handleDrawerClose}
        position={mobileScreen ? 'bottom' : 'right'}
        size={mobileScreen ? window.innerHeight - 104 : 408}
        title={
          <Heading
            color="$neutralGray7"
            css={{
              'abyss-heading-root': {
                width: '177px',
              },
            }}
            fontStyle="normal"
            offset={3}
          >
            {t('SUGGEST_EDIT.HEADING')}
          </Heading>
        }
      >
        <Drawer.Section>
          <Flex
            alignContent="flex-start"
            css={{
              'abyss-flex-root': {
                padding: 0,
                width: '100%',
              },
            }}
            direction="column"
          >
            <SuggestEditProviderCard
              avatar={avatar}
              providerLocations={providerLocations}
              providerName={providerName}
              providerType={providerType}
              updateLocation={setSelectedLocationValue}
            />
            <Heading
              css={{
                'abyss-heading-root': {
                  marginTop: '24px',
                },
              }}
              offset={6}
            >
              {page === 0
                ? t('SUGGEST_EDIT.INCORRECT_ITEMS')
                : t('SUGGEST_EDIT.CONFIRM_SUGGESTIONS')}
            </Heading>
            {componentsList[page]}
          </Flex>
        </Drawer.Section>
        <SuggestEditFooter
          isChecked={isChecked}
          page={page}
          providerType={providerType}
          setChecked={setChecked}
          setPage={setPage}
        />
      </Drawer>
    </FormProvider>
  );
};
