import { RelationshipTypeCodes } from '../Constants';
import { MemberInfo } from '../PSXHeader/ObapiDemo/memberProfile';

export const showMemberSelector = (
  loggedInMemberRelationshipTypecode: string | undefined,
  memberProfiles: MemberInfo[],
  isMnr: boolean
) => {
  if (
    loggedInMemberRelationshipTypecode === RelationshipTypeCodes.DEPENDENT ||
    loggedInMemberRelationshipTypecode === RelationshipTypeCodes.SPOUSE ||
    memberProfiles.length <= 1 ||
    isMnr
  ) {
    return false;
  }
  return true;
};
