import { config } from '@abyss/web/tools/config';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useShallow } from 'zustand/react/shallow';

import {
  Constants,
  CoverageTypesCodes,
  ReverseCoverageTypesCodes,
} from '../../common/Constants';
import { ConstantsLagoon } from '../../common/ConstantsLagoon';
import { getFeatureFlag } from '../../common/Utils';
import { SearchFilterContext } from '../../context/SearchFilterContext';
import { useCurrentMemberIsPreEffective } from '../../hooks/useCurrentMemberIsPreEffective';
import { CustomPreferredPolicy } from '../../models/Lagoon';
import { useChipStore } from '../../store/useChipStore';
import { ChipState } from '../../store/useChipStore/chipStore';
import { usePCPStore } from '../../store/usePCPStore';
import { PCPSearchStore } from '../../store/usePCPStore/pcpStore';
import { useSearchStore } from '../../store/useSearchStore';
import { SearchState } from '../../store/useSearchStore/searchStore';
import { useTypeaheadStore } from '../../store/useTypeaheadStore';
import { TypeaheadState } from '../../store/useTypeaheadStore/typeaheadStore';
import { getProviderType } from '../../utils/providerDetails.utils';
import {
  getLob,
  getMemberHealthCoverageType,
  getProviderFilterOptions,
  isValidPlanVariationCode,
} from '../../utils/providerSearch.utils';
import {
  getCurrentMemberData,
  getLocale,
  getOrgTypeCodes,
  getSpecialityRollupCodes,
  parseProviderSearchFilters,
} from '../../utils/providerSearch.utils';
import {
  getClaimOrEligibilitySystemTypeCode,
  getCoverageEffDateRange,
  getCoverageTypes,
  getCurrentPlanYear,
  getIsCustomPreferredPolicy,
  getIsOxfordAndUSP,
  getMemberEffectivePlanYear,
  getNetworkIdsForPES,
  getOnlineRetailers,
  getPlanVariationCode,
  getRulesPackageKey,
  getSitusState,
} from '../../utils/user.utils';
import { useCustomQuery } from '../useCustomQuery';
import { useGeoLocationStorage } from '../useGeoLocationStorage';
import { useLagoon } from '../useLagoon';
import { getBehavioralFacilityProgramsProviderSearch } from '../useProviderSearch/useProviderSearch';
import { useTypeaheadPseudoRollup } from '../useTypeaheadPseudoRollup';
import GET_PROVIDER_FILTER_COUNT from './ProviderFilterCount.graphql';

const getFeatureFlagValues = (featureFlags: any) => ({
  useMedicalNewRollUpCodes: getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_MEDICAL_NEW_ROLLUP_CODES
  ),
  useBHNewRollUpCodes: getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_BH_NEW_ROLLUP_CODES
  ),
  useVisionNewRollUpCodes: getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_VISION_NEW_ROLLUP_CODES
  ),
  useDentalNewRollUpCodes: getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USE_DENTAL_NEW_ROLLUP_CODES
  ),
  uspTierOneFlag: getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_TIER1_ENABLE
  ),
  uspToggleFlag: getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.USP_ENABLE
  ),
  llmFlag: getFeatureFlag(
    featureFlags,
    ConstantsLagoon.FEATURE_FLAGS.ENABLE_LLM
  ),
});

export const getBehavioralFacilityPrograms = (
  filteredParams: any,
  behavioralFacilityPrograms: string
) => filteredParams.behavioralFacilityPrograms || behavioralFacilityPrograms;

const getRollupCodes = (bhFacilityPrograms, rollupCodes) =>
  bhFacilityPrograms === '' ? rollupCodes : [];

export const useProviderFilterCount = (options) => {
  const [filterOptions, setFilterOptions] = useState({});
  const [queryResult, queryAction] = useCustomQuery(GET_PROVIDER_FILTER_COUNT, {
    ...options,
    url: config('GRAPHQL_API_URL'),
    accessor: 'providerSearch',
    /* istanbul ignore next */
    onCompleted: (result) => {
      const data = result?.data?.providerFilterCount;
      if (options?.onCompleted) {
        options.onCompleted(data);
      }
      setFilterOptions(data);
    },
  });

  const parsedResult = {
    ...queryResult,
    data: filterOptions,
  };

  return [parsedResult, queryAction];
};

export const useParsedProviderFilters = (params) => {
  const { coverageType, storeLob } = useChipStore(
    useShallow((state: ChipState) => ({
      coverageType: state.coverageType,
      storeLob: state.lob,
    }))
  );
  const { pcpIndicator = false, dependentSeqNbr } = usePCPStore(
    useShallow((state: PCPSearchStore) => ({
      pcpIndicator: state.pcpSearchState.pcpIndicator,
      dependentSeqNbr: state.pcpSearchState.dependentSeqNbr,
    }))
  );
  const {
    search = '',
    providerType = '',
    category,
    searchType,
    sectionType = '',
    specialtyCode,
    includeSpecialityRollupCodes,
    isUserTextSearch,
    pseudoVerticals = '',
    suggestion,
    bhProgramFacilityAgg,
    hasQueryTypeBhfpOrg,
    hasQueryTypeBhfpMedGrp,
  } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      search: state.typeaheadSearchStore.search,
      providerType: state.typeaheadSearchStore.providerType,
      category: state.typeaheadSearchStore.category,
      searchType: state.typeaheadSearchStore.searchType,
      sectionType: state.typeaheadSearchStore.sectionType,
      specialtyCode: state.typeaheadSearchStore.specialtyCode,
      includeSpecialityRollupCodes:
        state.typeaheadSearchStore.includeSpecialityRollupCodes,
      isUserTextSearch: state.typeaheadSearchStore.isUserTextSearch,
      pseudoVerticals: state.typeaheadSearchStore.pseudoVerticals,
      suggestion: state.typeaheadSearchStore.suggestion,
      bhProgramFacilityAgg: state.typeaheadSearchStore.bhProgramFacilityAgg,
      hasQueryTypeBhfpOrg: state.typeaheadSearchStore.hasQueryTypeBhfpOrg,
      hasQueryTypeBhfpMedGrp: state.typeaheadSearchStore.hasQueryTypeBhfpMedGrp,
    }))
  );
  const {
    includeOrgTypeCodes,
    excludeOrgTypeCodes,
    requestType = '',
    onlineRetailers,
    behavioralFacilityPrograms,
  } = useSearchStore(
    useShallow((state: SearchState) => ({
      includeOrgTypeCodes: state.searchStore.includeOrgTypeCodes,
      excludeOrgTypeCodes: state.searchStore.excludeOrgTypeCodes,
      requestType: state.searchStore.requestType,
      onlineRetailers: state.searchStore.onlineRetailers,
      behavioralFacilityPrograms: state.searchStore.behavioralFacilityPrograms,
    }))
  );

  const { selectedFilters = {} } = params;
  const { longitude, latitude, stateCode } = useGeoLocationStorage();
  const currentMember = getCurrentMemberData(dependentSeqNbr);
  const medicalCoverage = getMemberHealthCoverageType(
    currentMember?.eligibility,
    CoverageTypesCodes.MEDICAL
  );
  const productCode = medicalCoverage?.productCode;
  const marketType = medicalCoverage?.marketType;
  const featureFlags: [{ key: string; active: boolean }] = useLagoon(
    Constants.LAGOON_TABLE.FEATURE_FLAGS
  )();

  const rollupCodes = useMemo(() => {
    const codes = getSpecialityRollupCodes(
      specialtyCode,
      includeSpecialityRollupCodes,
      category
    );
    return codes.split(',');
  }, [specialtyCode, includeSpecialityRollupCodes, category]);

  const { searchFilters } = useContext(SearchFilterContext);
  const { AutoSearchRadius: autoSearchRadius } = searchFilters;
  const filterParams = parseProviderSearchFilters(selectedFilters);

  const {
    useMedicalNewRollUpCodes,
    useBHNewRollUpCodes,
    useVisionNewRollUpCodes,
    useDentalNewRollUpCodes,
    uspTierOneFlag,
    uspToggleFlag,
    llmFlag,
  } = getFeatureFlagValues(featureFlags);

  const customPreferredPolicies: CustomPreferredPolicy[] = useLagoon(
    'custom-preferred-policies'
  )();
  const tierBenefits = useLagoon('tier-benefit')();
  const rollupCodeMapLagoonData = useLagoon(
    Constants.LAGOON_TABLE.ROLLUPCODE_MAPPING
  )();

  const rollupCodeMap = rollupCodeMapLagoonData?.reduce((acc, item) => {
    if (item.source && item.destination) {
      acc[item.source] = item.destination;
    }
    return acc;
  }, {});

  const {
    ddpCode,
    demographics,
    lineOfBusiness: memberLob,
    population,
    policyNumber: policyID,
  } = currentMember;
  const dateOfBirth = demographics?.dateOfBirth;
  const gender = demographics?.gender;

  const reciprocityId = getNetworkIdsForPES(
    currentMember,
    coverageType,
    featureFlags
  );
  const isCustomPreferredPolicy: boolean = getIsCustomPreferredPolicy(
    customPreferredPolicies,
    policyID,
    reciprocityId
  );

  const memCatergory = currentMember?.membershipCategory || '';
  const membershipCategory = uspToggleFlag ? memCatergory : null;
  const filteredParams = {
    ...filterParams,
    hospitalAffiliationProviderIds:
      filterParams['hospitalAffiliationProviderIds']?.join(', '),
  };

  const { isOXMember } = getIsOxfordAndUSP(
    currentMember,
    coverageType,
    featureFlags
  );

  const pseudoVerticalsDetails = useTypeaheadPseudoRollup(
    pseudoVerticals,
    suggestion,
    isOXMember
  );

  const isPseudoRollupSearch = pseudoVerticalsDetails?.isPseudoRollupSearch;
  const isPreEffective = useCurrentMemberIsPreEffective();

  const bhFacilityPrograms = getBehavioralFacilityProgramsProviderSearch(
    hasQueryTypeBhfpOrg,
    hasQueryTypeBhfpMedGrp,
    searchType,
    coverageType,
    providerType,
    bhProgramFacilityAgg
  );

  const currentMemberEffectiveDate =
    getCoverageEffDateRange(currentMember).startDate;

  const variables = {
    lob: getLob(memberLob, storeLob),
    population,
    coverages: getCoverageTypes(currentMember),
    situsState: getSitusState(currentMember, getLob(memberLob, storeLob)),
    planYear: !isPreEffective
      ? getCurrentPlanYear()
      : getMemberEffectivePlanYear(new Date(currentMemberEffectiveDate)),
    memberEffectiveDate: isPreEffective ? currentMemberEffectiveDate : null,
    planVariationCode: getPlanVariationCode(currentMember, coverageType),
    claimSystemTypeCode: getClaimOrEligibilitySystemTypeCode(
      currentMember,
      coverageType
    )?.claimSystemTypeCode,
    eligibilitySystemTypeCode: getClaimOrEligibilitySystemTypeCode(
      currentMember,
      coverageType
    )?.eligibilitySystemTypeCode,
    policyID,
    reciprocityId: getNetworkIdsForPES(
      currentMember,
      coverageType,
      featureFlags
    ),
    locale: getLocale(),
    latitude,
    longitude,
    stateCode,
    membershipCategory,
    memberDemographics: {
      gender,
      dateOfBirth,
    },
    coverageType,
    providerType: getProviderType(providerType, sectionType),
    search,
    includeSpecialityRollupCodes: getRollupCodes(
      bhFacilityPrograms,
      rollupCodes
    ),
    requestType,
    snpType:
      coverageType == ReverseCoverageTypesCodes.MEDICAL
        ? medicalCoverage?.snpType
        : '',
    rollupCodeMap,
    searchType,
    pcpIndicator,
    onlineRetailers: getOnlineRetailers(onlineRetailers),
    includeOrgTypeCodes: getOrgTypeCodes(includeOrgTypeCodes),
    excludeOrgTypeCodes: getOrgTypeCodes(excludeOrgTypeCodes),
    // remove distance limit for Virtual Care filter for BH providers (US6465032),
    // backend logic will handle -1 for no distance limit search
    searchRadius:
      coverageType === 'B' && filterParams?.virtualCare === true
        ? -1
        : autoSearchRadius,
    ...filteredParams,
    useMedicalNewRollUpCodes,
    useBHNewRollUpCodes,
    useVisionNewRollUpCodes,
    useDentalNewRollUpCodes,
    llmFlag: isPseudoRollupSearch ? false : llmFlag,
    ddpCode,
    isCustomPreferredPolicy,
    rulesPackageKey: getRulesPackageKey(
      currentMember,
      tierBenefits,
      providerType,
      uspTierOneFlag
    ),
    cesProductCode: productCode,
    memberMarketType: marketType,
    behavioralFacilityPrograms:
      bhFacilityPrograms ||
      getBehavioralFacilityPrograms(filteredParams, behavioralFacilityPrograms),
    isUserTextSearch,
  };

  const [providerFilters, getProviderFilters] = useProviderFilterCount({});

  const validPlanVariationCode = isValidPlanVariationCode(
    variables.eligibilitySystemTypeCode,
    variables.claimSystemTypeCode,
    variables.planVariationCode,
    variables.lob,
    variables.population
  );

  useEffect(() => {
    if (!providerType) {
      return;
    }
    if (!validPlanVariationCode) {
      throw new Error('Plan Variation Code is required');
    }

    getProviderFilters({ variables });
  }, [JSON.stringify(variables)]);

  return {
    error: providerFilters?.error,
    isLoading: providerFilters?.isLoading,
    totalCount: providerFilters?.data?.totalCount || 0,
    providerFilterList: getProviderFilterOptions(
      providerFilters?.data?.providerFilterList,
      featureFlags
    ),
  };
};
