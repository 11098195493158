import { config } from '@abyss/web/tools/config';
import { storage } from '@abyss/web/tools/storage';
import React, { useEffect, useRef } from 'react';

import { MemberType, SIGN_OUT_TEST_IDS } from '../../../../common/Constants';
import { StoreKeys } from '../../../../hooks/useStore/state';
import { useStore } from '../../../../hooks/useStore/useStore';
import { getMemberType } from '../../../../utils/user.utils';
import { IChildrenProp } from '../../types';

const GLOBAL_NAV_CHECK_INTERVAL = 500;

export const GlobalNav = ({ children }: IChildrenProp) => {
  const globalNavIsLoaded = useRef<boolean>(false);
  const logoutHandler = useRef<(event: Event) => void>(() => {});
  const members = useStore(StoreKeys.OBAPI_MEMBERS);

  const globalNavUrlMap: Record<MemberType, string> = {
    [MemberType.ENI]: 'GLOBAL_NAV_ENI_URL',
    [MemberType.MNR]: 'GLOBAL_NAV_MNR_URL',
    [MemberType.IFP]: 'GLOBAL_NAV_ENI_URL',
    [MemberType.CNS]: 'GLOBAL_NAV_CNS_URL',
  };

  useEffect(() => {
    // GLOBAL NAV HEADER & FOOTER
    const memberType = getMemberType(members[0]);
    const globalNavUrl: string = config(globalNavUrlMap[memberType]);

    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = globalNavUrl;
    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    const timerID = window.setInterval(() => {
      const globalNavShadowRoot =
        document.querySelector('#global-header div')?.shadowRoot;
      if (globalNavShadowRoot && !globalNavIsLoaded.current) {
        globalNavIsLoaded.current = true;

        logoutHandler.current = (event: Event) => {
          if (
            event.target instanceof HTMLAnchorElement &&
            SIGN_OUT_TEST_IDS.includes(event.target.dataset.testId || '')
          ) {
            event.stopPropagation();
            event.preventDefault();
            storage.session.clear();
            window.location.assign(config('UHG_LOGOUT_URL'));
          }
        };

        const myAccountElement = globalNavShadowRoot?.querySelector(
          '[aria-label="My Account"] div div'
        );

        const mobileSideNavElement = globalNavShadowRoot?.querySelector(
          '[data-test-id="mobile-side-nav"]'
        );

        myAccountElement?.addEventListener('click', logoutHandler.current);
        mobileSideNavElement?.addEventListener('click', logoutHandler.current);
      }
    }, GLOBAL_NAV_CHECK_INTERVAL);

    return () => {
      if (timerID) {
        window.clearInterval(timerID);
      }

      if (globalNavIsLoaded.current) {
        const myAccountElement = document
          .querySelector('#global-header div')
          ?.shadowRoot?.querySelector('[aria-label="My Account"] div div');

        const mobileSideNavElement = document
          .querySelector('#global-header div')
          ?.shadowRoot?.querySelector('[data-test-id="mobile-side-nav"]');

        myAccountElement?.removeEventListener('click', logoutHandler.current);
        mobileSideNavElement?.removeEventListener(
          'click',
          logoutHandler.current
        );
      }
    };
  }, []);

  return <React.Fragment>{children}</React.Fragment>;
};
