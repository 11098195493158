import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Text } from '@abyss/web/ui/Text';
import React from 'react';

import { mobileOnly } from '../../../ConstantsStyles';
import { ParagraphSkeleton } from '../../../Skeleton';
import {
  Divider,
  DropdownContainer,
  ParagraphSkeletonContainer,
} from './KeywordSearchDropdown.styled';

interface IProps {
  text?: string;
}

export const Skeleton = ({ text }: IProps) => {
  const isMobileView = useMediaQuery(mobileOnly);
  const desktopWidths = [310, 261, 287, 201];
  const mobileWidths = [164, 133, 164];
  const widths = isMobileView ? mobileWidths : desktopWidths;
  const lineSpacing = isMobileView ? 11 : 12;
  const shouldShowTopText = text === '' || !!text;

  return (
    <React.Fragment>
      <Divider />
      <DropdownContainer data-testid="primary-dropdown-container-loading">
        {shouldShowTopText && (
          <Text fontWeight={700} size="$sm">
            {text}
          </Text>
        )}
        <ParagraphSkeletonContainer>
          <ParagraphSkeleton
            lineSpacing={lineSpacing}
            testId="primary-dropdown-container-skeleton"
            widths={widths}
          />
        </ParagraphSkeletonContainer>
      </DropdownContainer>
    </React.Fragment>
  );
};
