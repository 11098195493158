import React from 'react';
import { useTranslation } from 'react-i18next';

import { LinkContainer } from '../../../LinkContainer/LinkContainer';

type Props = {
  displayViewAll: boolean;
  handleViewAllOnClick: () => void;
  locationForAnalytics: string;
};

export const PatientReviewsViewAll = ({
  displayViewAll,
  handleViewAllOnClick,
  locationForAnalytics,
}: Props) => {
  const { t } = useTranslation();

  if (displayViewAll) {
    return (
      <LinkContainer
        analyticsInfo={{
          location: `body:${locationForAnalytics}`,
        }}
        data-auto-testid="view-all-patient-reviews"
        data-testid="view-all-patient-reviews"
        onClick={() => handleViewAllOnClick()}
        text={t('View all')}
      />
    );
  }
  return null;
};
