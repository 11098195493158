import { isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { capitalizeFirstLetter } from '../../../../../../common/Utils/capitalizeFirstLetter';
import { NULL_ATTRIBUTE_STATE } from '../../CompareDrawer/utility/compareDrawerConstants';
import { PrintViewProviderDetailsXSText } from './PrintExpandedCard.styled';

type Props = {
  servicesOffered: string[];
};

export const PrintServicesOffered = ({ servicesOffered }: Props) => {
  const { t } = useTranslation();
  const isAttributeEmpty = isEmpty(servicesOffered);

  const formatAndTranslateScreamingSnakeCase = (value: string) =>
    t(capitalizeFirstLetter(value.replaceAll('_', ' ').toLocaleLowerCase()));

  const servicesOfferedText = isAttributeEmpty
    ? t(NULL_ATTRIBUTE_STATE.SERVICES_OFFERED)
    : servicesOffered
        .map((value) => formatAndTranslateScreamingSnakeCase(value))
        .join(', ');

  return (
    <React.Fragment>
      <PrintViewProviderDetailsXSText>
        {t(servicesOfferedText)}
      </PrintViewProviderDetailsXSText>
    </React.Fragment>
  );
};
