import { Drawer } from '@abyss/web/ui/Drawer';
import { Flex } from '@abyss/web/ui/Flex';
import { Heading } from '@abyss/web/ui/Heading';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';

import { adobeLinkTrackEvent } from '../../../../../../../common/AdobeTagging/adobeLinkTrackEvent';
import { getDistance } from '../../../../../../../common/Utils/dataCardUtils';
import { CompareProvider } from '../../../../../../../models/Provider';
import { useTypeaheadStore } from '../../../../../../../store/useTypeaheadStore';
import { TypeaheadState } from '../../../../../../../store/useTypeaheadStore/typeaheadStore';
import { successToast } from '../../../../ViewAll/Toast';
import { PrintButton } from '../../../PrintButton';
import { ShareButton } from '../../../ShareButton';
import { displayFloatingSection } from '../../../ShareDrawer/ShareDrawer';
import {
  getNetworkStatus,
  getProviderGender,
  getProviderLanguages,
  getProviderSpecialty,
  getStatusText,
} from '../../../ShareDrawer/utils';
import {
  CompareCheckboxInfo,
  NULL_ATTRIBUTE_STATE,
} from '../../utility/compareDrawerConstants';

type Props = {
  items?: CompareCheckboxInfo[];
  setIsMenuOpen: Dispatch<SetStateAction<boolean>>;
  isMenuOpen: boolean;
  isDisplayShare?: boolean;
  selectedProviders?: CompareProvider[];
  shareTitle?: string;
  locationForAnalytics: string;
};

export const DrawerMenu = ({
  items,
  setIsMenuOpen,
  isMenuOpen,
  isDisplayShare = true,
  selectedProviders,
  shareTitle,
  locationForAnalytics,
}: Props) => {
  const [isShare, setIsShare] = useState(false);
  const [isCloseShareContents, setIsCloseShareContents] = useState(true);
  const { t } = useTranslation();
  const { search, sectionType, specialtyCode } = useTypeaheadStore(
    useShallow((state: TypeaheadState) => ({
      search: state.typeaheadSearchStore.search,
      sectionType: state.typeaheadSearchStore.sectionType,
      specialtyCode: state.typeaheadSearchStore.specialtyCode,
    }))
  );

  const getIsChecked = (editCheckItems: string, providerInformation: string) =>
    items?.find((item) => item.name === editCheckItems)?.isChecked
      ? `${providerInformation}`
      : '';

  const handleShareButtonMobile = () => {
    let shareContent = `${shareTitle}\n`;
    selectedProviders?.forEach((provider) => {
      const gender: string = getProviderGender(provider?.gender);
      const address: string = `${provider?.address?.line?.[0]}, ${provider?.address?.city}, ${provider?.address?.state}`;
      const speciality: string = getProviderSpecialty(provider.speciality);
      const languages = getProviderLanguages(provider?.languagesSpoken);
      const acceptingPatient: string = getStatusText(
        provider.acceptingNewPatients,
        'Accepting new patients',
        'Not accepting new patients',
        NULL_ATTRIBUTE_STATE.ACCEPTING_NEW_PATIENTS
      );
      const virtualCare: string = getStatusText(
        provider.virtualCareOffered,
        'Virtual care offered',
        'Virtual care not offered',
        NULL_ATTRIBUTE_STATE.VIRTUAL_CARE_OFFERED
      );
      const networkStatus: string = getNetworkStatus(provider.networkStatus);

      shareContent += `\n${provider.providerName}\n${
        provider?.phones?.phone[0]
      }\n${networkStatus}${getIsChecked(
        'specialties',
        `\n${speciality}`
      )}${getIsChecked('reviews', `\n${'Rating - 4'}`)}${getIsChecked(
        'distance',
        `\n${address}\n${getDistance(provider.distance)} miles away`
      )}${getIsChecked('gender', `\n${gender}`)}${getIsChecked(
        'acceptingNewPatients',
        `\n${acceptingPatient}`
      )}${getIsChecked('languagesSpoken', `\n${languages}`)}${getIsChecked(
        'virtualCareOffered',
        `\n${virtualCare}`
      )}${
        // Cost to be included near future
        getIsChecked('hoursOfOperation', `\n${'TBD'}`)
      }
      `;
    });
    shareContent += `\n[${window.location.href}]`;
    try {
      navigator.share({
        text: shareContent,
        title: shareTitle,
      });
    } catch (error) {
      if (error) {
        setIsShare(true);
        setIsCloseShareContents(true);
      }
    }
  };

  const shareAllResults = false;
  const isOriginCompareProviders = true;

  const toastMessage = t('Your results have been shared');
  const closeAllDrawersAndOpenSuccessAlert = () => {
    setIsShare(false);
    // after integration, check for success here
    successToast(toastMessage);
  };

  return (
    <React.Fragment>
      <Drawer
        css={{
          'abyss-modal-close-button': {
            top: '25px',
          },
          'abyss-modal-content-container': {
            height: 'auto !important',
          },
        }}
        isOpen={isMenuOpen}
        onClose={() => {
          adobeLinkTrackEvent({
            name: 'close',
            location: 'modal:more actions',
            type: 'internal',
          });
          setIsMenuOpen(false);
        }}
        position="bottom"
        size="$xs"
        title={
          <Heading color="$gray8" display="h6" offset={3}>
            {t('COMPARE_DRAWER_TEXT.MORE_ACTIONS')}
          </Heading>
        }
      >
        <Flex
          css={{ 'abyss-flex-root': { gap: '$sm', paddingBottom: '20px' } }}
          direction="column"
        >
          <PrintButton
            locationForAnalytics={locationForAnalytics}
            view="mobile"
          />
          {isDisplayShare && (
            <ShareButton
              data-auto-testid="compare-drawer-share-menu-icon"
              data-testid="compare-drawer-share-menu-icon"
              onClick={handleShareButtonMobile}
              view="none"
            />
          )}

          {isShare &&
            selectedProviders?.length > 0 &&
            displayFloatingSection(
              items,
              isCloseShareContents,
              setIsCloseShareContents,
              closeAllDrawersAndOpenSuccessAlert,
              shareAllResults,
              sectionType,
              search,
              specialtyCode,
              selectedProviders,
              isOriginCompareProviders
            )}
        </Flex>
      </Drawer>
    </React.Fragment>
  );
};
