export interface TypeaheadSearchState {
  search?: string | null;
  category?: string;
  searchType?: string;
  pesKeyword?: string;
  resultType?: string;
  keyword?: string;
  providerType?: string;
  acceptingNewPatients?: boolean;
  includeSpecialityRollupCodes?: string | string[];
  searchMethod?: string;
  searchTerm?: string;
  llmFlag?: boolean;
  specialtyCode?: string;
  sectionType?: string;
  drawerName?: string;
  medicalSpeciality?: boolean;
  fromViewAll?: boolean;
  userZip?: string;
  zipcode?: string;
  virtualCare?: boolean;
  gender?: string;
  useMockData?: boolean;
  previous?: boolean;
  isMixedSuggestions?: boolean;
  searchFromTypeAhead?: boolean;
  coverageTypes?: string | string[];
  pseudoVerticals?: string;
  suggestion?: string;
  isUserTextSearch?: boolean;
  specialtyCategory?: string;
  heading?: string;
  condition?: string;
  bhProgramFacilityAgg?: string;
  initiateViewEstimate?: boolean;
  carePathId?: string;
  initiateDocasapSession?: string;
  linkName?: string;
  userSearchTerm?: string;
  typeaheadLinkName?: string;
  lob?: string;
  hasQueryTypeBhfpOrg?: boolean;
  hasQueryTypeBhfpMedGrp?: boolean;
}

export interface TypeaheadState {
  typeaheadSearchStore: TypeaheadSearchState;
  setTypeaheadSearchStore: (values: TypeaheadSearchState) => void;
  clearTypeaheadSearchStore: (defaultValues?: TypeaheadSearchState) => void;
}

export enum StoreKeys {
  TYPEAHEAD_SEARCH_STORE = 'typeaheadSearchStore',
  SET_TYPEAHEAD_SEARCH_STORE = 'setTypeaheadSearchStore',
  CLEAR_TYPEAHEAD_SEARCH_STORE = 'clearTypeaheadSearchStore',
}
