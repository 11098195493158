export const mockFacilities = () => {
  let facilities = '';
  for (let y = 2; y < 12; y++) {
    facilities = facilities.concat(
      JSON.stringify({
        firstName: `Facility  Name ${y}`,
        practice: 'Type of Facility',
        address: `Street ${y}`,
        suite: `Suite ${y}`,
        phone: `(61${y})-000-0000`,
        premium: true,
        accepting: true,
        network: true,
        type: 'Facility',
        facilityId: `${y}`,
      })
    );
    if (y <= 11) {
      facilities = facilities.concat(',');
    }
  }

  return facilities;
};