import { createContext, useState } from 'react';

import { PortalData } from '../common/Portal';

interface PortalContextType {
  portalData: PortalData;
  setPortalData: (val: PortalData) => void;
}

export const PortalContext = createContext<PortalContextType>({
  portalData: {} as any,
  setPortalData: () => {},
});

export const PortalContextProvider = ({
  children,
  portalDataArgs,
}: {
  children: React.ReactNode;
  portalDataArgs: PortalData;
}) => {
  const [portalData, setPortalData] = useState<PortalData>(portalDataArgs);
  const updatePortalData = (data: PortalData) => {
    setPortalData(data);
  };

  return (
    <PortalContext.Provider
      value={{ portalData, setPortalData: updatePortalData }}
    >
      {children}
    </PortalContext.Provider>
  );
};
