import { Heading } from '@abyss/web/ui/Heading';

import { ConstantsLagoon } from '../../../../common/ConstantsLagoon';
import { ContentWrapper } from '../../../../common/ContentWrapper';
import { getLagoonContent } from '../../../../common/LagoonContent';
import {
  ContainerForAllSectionsStyled,
  VisionCareHeader,
} from '../../../../common/ProviderDetailTabs/ProviderDetailTabs.styled';
import { VisionCare } from '../../../../common/VisionCare/VisionCare';
import { useAdobeProvidersImpressionBlock } from '../../../../hooks/adobeHook/useAdobeProvidersImpressionBlock';
import { useLagoon } from '../../../../hooks/useLagoon';
import { getCurrentMember } from '../../../../utils/user.utils';
import { ExternalVisionCard } from './ExternalVisionCard';

export const VisionCareProviders = () => {
  // Getting info from lagoon.
  // useLagoon pull from translated content.
  // For adobe analytics, we need the heading name to stay the same regardless of language.
  // So we have to pull the heading from untranslated lagoon content and merge them.
  const lagoonTableName = 'vision-care';
  const browseVisionCare = useLagoon(lagoonTableName)();
  const [onProvidersLoad, onError] = useAdobeProvidersImpressionBlock({
    numberOfApiCalls: browseVisionCare?.length,
    skipCostIndicator: true,
  });

  const lagoonData: [{ key: string; content: string }] = useLagoon('vision')();
  const title = getLagoonContent(lagoonData, ConstantsLagoon.VISION.TITLE_KEY);

  const currentMember = getCurrentMember();

  if (currentMember.isCnSMember) {
    return <ExternalVisionCard />;
  }

  return (
    <ContainerForAllSectionsStyled>
      <ContentWrapper>
        <Heading
          css={VisionCareHeader}
          data-auto-testid="vision-care-section-main-heading"
          data-testid="vision-care-section-main-heading"
          display="h4"
          offset={1}
        >
          {title}
        </Heading>
        {browseVisionCare?.map((section) => (
          <VisionCare
            key={section?.specialtyCode}
            onCompleted={onProvidersLoad}
            onError={onError}
            section={section}
          />
        ))}
      </ContentWrapper>
    </ContainerForAllSectionsStyled>
  );
};
