import { useMediaQuery } from '@abyss/web/hooks/useMediaQuery';
import { Drawer } from '@abyss/web/ui/Drawer';
import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Layout } from '@abyss/web/ui/Layout';
import { Popover } from '@abyss/web/ui/Popover';
import find from 'lodash/find';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useLagoon } from '../../hooks/useLagoon';
import { Provider } from '../../models/Provider';
import { ResponseHeaders } from '../../models/ResponseHeaders';
import { adobeLinkTrackEvent } from '../AdobeTagging/adobeLinkTrackEvent';
import { adobeModalTrackEvent } from '../AdobeTagging/adobeModalTrackEvent';
import { CostIndicators } from '../Constants';
import { phoneOnly } from '../ConstantsStyles';
import { CostIndicator } from '../CostIndicator/CostIndicator';
import {
  convertProviderTypeToAdobeType,
  formatProviderId,
  handleAdobeSearchResultClickAndModalEvent,
} from '../Utils/adobeTrackUtils/adobeTrackUtils';
import {
  CostIndicatorDrawerStyles,
  CostIndicatorDrawerTextStyles,
  PopoverCostIndicatorStyles,
} from './CostIndicatorPopover.style';

type Props = {
  costIndicator?: string;
  practitioner?: Provider;
  displayCostIndicator?: boolean;
  callFrom?: string;
  indexForAnalytics?: number;
  locationForAnalytics?: string;
  providerType?: string;
  selectedFilters?: string;
  headers?: ResponseHeaders;
  searchTerm?: string;
};

export const CostIndicatorPopover = ({
  costIndicator,
  displayCostIndicator = true,
  callFrom,
  indexForAnalytics,
  locationForAnalytics,
  selectedFilters,
  headers = { correlationId: '' },
  practitioner,
  providerType,
  searchTerm,
}: Props) => {
  const { t } = useTranslation();
  const [toggleCost, setToggleCost] = useState(false);
  const mobileScreen = useMediaQuery(phoneOnly);
  const [CostIndicatorDrawerToggle, setCostIndicatorDrawerToggle] =
    useState(false);
  let label;

  let id;

  const lagoonCostIndicatorData = useLagoon('cost-indicator-popover')();
  const costIndicatorData = find(lagoonCostIndicatorData, { key: '1' });
  const costIndicatorContent = costIndicatorData?.costIndicatorPopoverContent;
  switch (costIndicator) {
    case CostIndicators.$:
      label = t('COST_INDICATOR.BELOW_AVERAGE_LABEL');
      id = 'below-average-indicator';
      break;
    case CostIndicators.$$:
      label = t('COST_INDICATOR.AVERAGE_LABEL');
      id = 'average-indicator';
      break;
    case CostIndicators.$$$:
      label = t('COST_INDICATOR.ABOVE_AVERAGE_LABEL');
      id = 'above-average-indicator';
      break;
    default:
      label = '';
      id = '';
      break;
  }
  const costIndicatorTitle = costIndicatorData?.title;

  const locationForAdobe = `body:details header`;

  const costIndicatorTestId: string = mobileScreen
    ? 'cost-indicator-drawer'
    : 'cost-indicator-popover';

  const linkTrack = () => {
    adobeLinkTrackEvent({
      name: 'cost indicator',
      location: locationForAdobe,
      type: 'internal',
    });
    adobeModalTrackEvent('cost indicator');
  };
  const searchTrack = () => {
    handleAdobeSearchResultClickAndModalEvent({
      filters: selectedFilters,
      linkName: costIndicatorTestId,
      linkPosition: indexForAnalytics,
      linkLocation: locationForAnalytics,
      modalName: 'cost indicator',
      customAttributesBlock: {
        correlationId: headers.correlationId,
        providerId: formatProviderId(practitioner),
        providerType: convertProviderTypeToAdobeType(providerType),
      },
      term: searchTerm ?? '',
    });
  };

  const trackAdobeEvent = () => {
    if (!toggleCost) {
      if (callFrom && callFrom === 'datacard') {
        searchTrack();
      } else {
        linkTrack();
      }
    }
    setToggleCost((prevToggle) => !prevToggle);
  };

  return (
    <React.Fragment>
      {!mobileScreen ? (
        <div>
          <Popover
            aria-label={label}
            content={
              <div
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: costIndicatorContent,
                }}
              />
            }
            css={PopoverCostIndicatorStyles}
            data-auto-testid={costIndicatorTestId}
            data-testid={costIndicatorTestId}
            id={id}
            name={label}
            onClick={trackAdobeEvent}
            tabIndex={0}
            title={costIndicatorTitle}
            width={220}
          >
            <Layout.Group space="4px">
              {displayCostIndicator && (
                <CostIndicator indicatorData={costIndicator || ''} />
              )}
              <IconMaterial
                data-auto-testid="cost-indicator-popover-info-icon"
                data-testid="cost-indicator-popover-info-icon"
                icon="info"
                size={16}
                variant="outlined"
              />
            </Layout.Group>
            <p aria-hidden="true" style={{ display: 'none' }}>
              {label}
            </p>
          </Popover>
        </div>
      ) : (
        <React.Fragment>
          <Drawer
            css={CostIndicatorDrawerStyles}
            data-auto-testid={costIndicatorTestId}
            data-testid={costIndicatorTestId}
            isOpen={CostIndicatorDrawerToggle}
            onClose={() => setCostIndicatorDrawerToggle(false)}
            position="bottom"
            size="200"
            title={costIndicatorTitle}
          >
            <div
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: costIndicatorContent,
              }}
            />
          </Drawer>
          <Layout.Group
            aria-label={label}
            css={CostIndicatorDrawerTextStyles}
            data-auto-testid={id}
            id={id}
            name={label}
            space="4px"
            tabIndex={0}
          >
            {displayCostIndicator && (
              <CostIndicator indicatorData={costIndicator || ''} />
            )}
            <IconMaterial
              data-auto-testid="cost-indicator-info-icon"
              data-testid="cost-indicator-info-icon"
              icon="info"
              onClick={() => setCostIndicatorDrawerToggle(true)}
              size={16}
              variant="outlined"
            />
          </Layout.Group>
          <p aria-hidden="true" style={{ display: 'none' }}>
            {label}
          </p>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
