import React from 'react';
import { useTranslation } from 'react-i18next';

import { Constants } from '../../../common/Constants';
import { ConstantsLagoon } from '../../../common/ConstantsLagoon';
import { StyledNullAttributeDiv } from '../../../frontends/ProviderSearch/routes/MapView/CompareDrawer/utility/compareDrawer.styled';
import { NULL_ATTRIBUTE_STATE } from '../../../frontends/ProviderSearch/routes/MapView/CompareDrawer/utility/compareDrawerConstants';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { returnSuppressFlag } from '../../../utils/featureSuppress';
import {
  NoRatingText,
  ProviderCardContent,
  RatingText,
  RatingTextWrapper,
  RatingsText,
  UnderlineRatingText,
  VoiceOnlyText,
} from '../DataCard.style';
import { StarRating } from './StarRating';

type Props = {
  healthGradeRating: string | undefined;
  providerId: string;
  sectionTypeDerived: string;
  surveyCount?: number;
  'data-testid'?: string;
  size: number;
  hideNumber?: boolean;
  underline?: boolean;
};
export const ProviderRating = ({
  healthGradeRating,
  providerId,
  sectionTypeDerived,
  'data-testid': testId = '',
  surveyCount,
  size,
  hideNumber,
  underline,
}: Props) => {
  const suppressRatings = returnSuppressFlag(
    ConstantsLagoon.FEATURE_SUPPRESSION_FLAGS.PATIENT_REVIEWS
  );
  const [providerRatings] = useFeatureFlag([
    ConstantsLagoon.FEATURE_FLAGS.GET_PROVIDER_RATINGS,
  ]);

  const surveyRating = surveyCount ? ` (${surveyCount})` : '';
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <ProviderCardContent data-testid="provider-ratings">
        {healthGradeRating && !suppressRatings && providerRatings ? (
          <RatingTextWrapper
            data-auto-testid="rating-wrap"
            data-testid="rating-wrap"
          >
            <VoiceOnlyText>{`${healthGradeRating} stars`}</VoiceOnlyText>
            <StarRating
              healthGradeRating={healthGradeRating}
              key={`healthgrade-rating-${healthGradeRating}`}
              param={0}
              providerId={providerId}
              ratingIndex={0}
              sectionTypeDerived={sectionTypeDerived}
            />
            {underline ? (
              <UnderlineRatingText
                aria-hidden="true"
                className="text"
                color="$primary1"
                data-auto-testid={testId}
                data-testid={testId}
                size={size}
              >
                {`${healthGradeRating}${surveyRating}`}
              </UnderlineRatingText>
            ) : (
              <RatingText
                aria-hidden="true"
                className="text"
                color="$primary1"
                data-auto-testid={testId}
                data-testid={testId}
                size={size}
              >
                {`${healthGradeRating}${surveyRating}`}
              </RatingText>
            )}
          </RatingTextWrapper>
        ) : (
          healthGradeRating && (
            <RatingTextWrapper
              data-auto-testid="rating-wrap"
              data-testid="rating-wrap"
            >
              {Constants.RATE_LIMITING.map((param, ratingIndex) => (
                <StarRating
                  healthGradeRating={healthGradeRating}
                  key={`healthgrade-rating-${healthGradeRating}`}
                  param={param}
                  providerId={providerId}
                  ratingIndex={ratingIndex}
                  sectionTypeDerived={sectionTypeDerived}
                />
              ))}
              <VoiceOnlyText>{`${healthGradeRating} stars`}</VoiceOnlyText>

              <RatingsText
                aria-hidden="true"
                className="text"
                color="$primary1"
                data-auto-testid={testId}
                data-testid={testId}
                size={size}
              >
                <>{healthGradeRating}</>
              </RatingsText>
            </RatingTextWrapper>
          )
        )}
        {!healthGradeRating && !hideNumber ? (
          <NoRatingText data-testid="provider-no-rating">
            {t('Ratings not available')}
          </NoRatingText>
        ) : (
          !healthGradeRating && (
            <StyledNullAttributeDiv>
              {t(NULL_ATTRIBUTE_STATE.HEALTH_GRADE_RATINGS)}
            </StyledNullAttributeDiv>
          )
        )}
      </ProviderCardContent>
    </React.Fragment>
  );
};
