import { Flex } from '@abyss/web/ui/Flex';
import { t } from 'i18next';
import { Fragment } from 'react';

import {
  SuggestioContentModal,
  SuggestionHeading,
  UnOrderList,
} from './PatientReviews.styled';

type Props = {
  heading: string;
  surveyAnswers: any;
  showItem: boolean;
  answersType: string;
};

export const PatientReviewSuggestionCard = ({
  heading,
  surveyAnswers,
  showItem,
  answersType,
}: Props) => {
  const surveys = Object.entries(surveyAnswers).filter(
    ([, value]) => value === showItem
  );
  return (
    <Flex css={{ gap: '12px' }} direction={'column'}>
      <SuggestionHeading size="$sm">{heading}</SuggestionHeading>
      {surveys.length ? (
        <UnOrderList>
          {surveys.map(([keyName, value], i) => (
            <Fragment>
              {showItem === value ? (
                <li key={i}>
                  <SuggestioContentModal size={'$sm'}>
                    {t(keyName + answersType)}
                  </SuggestioContentModal>
                </li>
              ) : null}
            </Fragment>
          ))}
        </UnOrderList>
      ) : (
        <SuggestioContentModal size="$md">
          {t('No Results')}
        </SuggestioContentModal>
      )}
    </Flex>
  );
};
