import { IconMaterial } from '@abyss/web/ui/IconMaterial';
import { Tooltip } from '@abyss/web/ui/Tooltip';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useIsOverflow } from '../../../../../../../../../../hooks/useIsOverflow';
import { Address } from '../../../../../../../../../../models/ProviderDetails';
import {
  StyledMiniCompareCardAddressCotainer,
  StyledMiniCompareCardAddressDiv,
  StyledMiniCompareCardAddressText,
} from '../../../../../utility/compareDrawer.styled';
import { getDisplayedAddressText, isAddressPresent } from './addressUtility';

type Props = {
  address: Address;
};

export const MiniCompareCardAddress = ({ address }: Props) => {
  const { t } = useTranslation();
  const isAttributePresent = isAddressPresent(address);
  const displayedAddress = t(
    getDisplayedAddressText(address, isAttributePresent)
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const isOverflow = useIsOverflow(containerRef);

  const MiniCompareCareAddressAttribute = (
    <StyledMiniCompareCardAddressDiv
      cssProps={{ isOverflow }}
      ref={containerRef}
    >
      <StyledMiniCompareCardAddressText
        data-auto-testid="data-card-address-text"
        data-testid="data-card-address-text"
      >
        {displayedAddress}
      </StyledMiniCompareCardAddressText>
    </StyledMiniCompareCardAddressDiv>
  );

  return (
    <React.Fragment>
      <StyledMiniCompareCardAddressCotainer>
        <IconMaterial
          className="location-icon"
          data-auto-testid="data-card-location-on-icon"
          data-testid="data-card-location-on-icon"
          icon="location_on"
          size="24px"
        />
        {isOverflow ? (
          <Tooltip
            asChild={false}
            content={displayedAddress}
            position="top"
            positionOffset={8}
            role="tooltip"
          >
            {MiniCompareCareAddressAttribute}
          </Tooltip>
        ) : (
          MiniCompareCareAddressAttribute
        )}
      </StyledMiniCompareCardAddressCotainer>
    </React.Fragment>
  );
};
